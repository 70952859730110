import { useEffect, useState } from 'react';
import { Select } from 'antd';
import UserContextProvider from './UserContext';

const { Option } = Select;

/**
 * Custom hook for retrieving and managing technique-related fields for user data.
 *
 * @hook
 * @returns {object} An object containing technique fields and loading state
 * @property {Array} techniqueFields - An array of technique-related fields
 * @property {boolean} isFieldsLoading - A boolean indicating whether the fields are loading
 */

const useTechniqueFields = () => {
  const { psMacro, software } = UserContextProvider();
  const [isFieldsLoading, setIsFieldsLoading] = useState(true);

  useEffect(() => {
    if (psMacro && software) setIsFieldsLoading(false);
  }, []);

  const techniqueFields = [
    {
      name: ['macro_ps'],
      input: (
        <Select loading={isFieldsLoading} mode="multiple" allowClear>
          {(psMacro || []).map((macro) => (
            <Option key={macro.title} value={macro._id}>
              {macro.title}
            </Option>
          ))}
        </Select>
      )
    },
    {
      name: ['used_software'],
      input: (
        <Select loading={isFieldsLoading} mode="multiple" allowClear>
          {(software || []).map((soft) => (
            <Option key={soft.title} value={soft._id}>
              {soft.title}
            </Option>
          ))}
        </Select>
      )
    }
  ];
  return { techniqueFields: techniqueFields.map((f) => f), isFieldsLoading };
};

export default useTechniqueFields;
