import { Button, Form, Row, Modal, InputNumber } from 'antd';
import { CloseOutlined, CheckOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import {
  formItemLayout,
  tailFormItemLayout
} from '../../../utils/constants/formLayout';

/**
 * Represents an overpayment modal component using Ant Design's Modal and Form components.
 * Allows users to input or update an overpayment amount for a customer.
 *
 * @component
 * @param {Object} props - Component props.
 * @param {boolean} props.isOverPaymentModalOpen - Determines if the modal is open.
 * @param {function} props.closeOverPaymentModal - Function to close the modal. It accepts an optional number (the overpayment value) or null to indicate cancellation.
 * @param {number|null} props.overPayment - Initial value of the overpayment to be edited. Null if creating a new entry.
 * @returns {JSX.Element} - A modal component for entering or updating overpayment amounts.
 */
const OverPaymentModal = ({
  isOverPaymentModalOpen,
  closeOverPaymentModal,
  overPayment
}) => {
  const { t } = useTranslation();

  const handleSubmit = (value) => {
    closeOverPaymentModal(value);
  };

  return (
    <Modal
      centered
      open={isOverPaymentModalOpen}
      onCancel={() => closeOverPaymentModal(null)}
      width={700}
      footer={false}
      title={t('customers.overpayment_modal.title')}
    >
      <Form {...formItemLayout} onFinish={handleSubmit}>
        <Form.Item
          {...tailFormItemLayout}
          name={['overPayment']}
          label={t('customers.overpayment_modal.overpayment')}
          initialValue={overPayment}
          rules={[{ required: true }]}
        >
          <InputNumber />
        </Form.Item>
        <Form.Item {...tailFormItemLayout}>
          <Row justify="end">
            <Button
              style={{ margin: '0 8px' }}
              type="link"
              danger
              onClick={() => closeOverPaymentModal(null)}
            >
              {`${t('buttons.cancel')} `}
              <CloseOutlined />
            </Button>
            <Button type="primary" htmlType="submit">
              {`${t('buttons.save')} `}
              <CheckOutlined />
            </Button>
          </Row>
        </Form.Item>
      </Form>
    </Modal>
  );
};

OverPaymentModal.propTypes = {
  isOverPaymentModalOpen: PropTypes.bool,
  closeOverPaymentModal: PropTypes.func.isRequired,
  overPayment: PropTypes.number
};

OverPaymentModal.defaultProps = {
  isOverPaymentModalOpen: false,
  overPayment: null
};

export default OverPaymentModal;
