import PropTypes from 'prop-types';
import { Col, Tabs } from 'antd';
import { useTranslation } from 'react-i18next';
import { ListQuittances } from './ListQuittances';
import { ListContracts } from './ListContracts';

/**
 * Component for displaying contracts and quittances informations of a customer in a card format of 2 datatable.
 *
 * @component
 * @returns {JSX.Element} Component JSX
 */

export const QuittancesContracts = ({ id }) => {
  const { t } = useTranslation();

  const items = [
    {
      label: t('contracts.title'),
      key: '1',
      children: <ListContracts id={id} />
    },
    {
      label: t('quittances.title'),
      key: '2',
      children: <ListQuittances id={id} withCreateButton={false} />
    }
  ];

  return (
    <Col span="24">
      <Tabs defaultActiveKey="1" type="card" size="large" items={items} />
    </Col>
  );
};

QuittancesContracts.propTypes = {
  id: PropTypes.string
};

QuittancesContracts.defaultProps = {
  id: undefined
};
