import { useTranslation } from 'react-i18next';
import { Typography } from 'antd';
import useProductivityFields from './fields/productivitiesFields';
import { useGenerateFormItem } from '../../utils/generateFormItem';
import useConversionRateFields from './fields/conversionRateFields';
import useSoftSkillsFields from './fields/softSkillsFields';
import useTestFields from './fields/testFields';
import useCommentField from './fields/commentField';

/**
 * Custom hook for generating form fields organized by categories in a collapsible layout.
 *
 * @hook
 * @param {string} purpose - Purpose of the form (e.g., 'create', 'edit')
 * @returns {object} Object containing categories, base64, and loading status
 * @property {Array} categories - Array of category objects with label and children
 * @property {string} base64 - Base64 encoded image data for user photo
 * @property {boolean} isFieldsLoading - Loading status for field data
 */
const useCollapseByCategories = (purpose) => {
  const { t } = useTranslation();
  const generateFields = useGenerateFormItem();

  const { productivitiesFields } = useProductivityFields(t);
  const { testFields } = useTestFields(purpose, t);
  const { softSkillsFields } = useSoftSkillsFields();
  const { conversionRateFields } = useConversionRateFields();
  const { commentField } = useCommentField();

  const categories = [
    {
      key: '1',
      label: (
        <Typography.Title
          level={4}
          style={{
            color: 'var(--userColor)',
            margin: 0
          }}
          strong={false}
        >
          {t('quality.form.productivities.title')}
        </Typography.Title>
      ),
      children: productivitiesFields.map((field) =>
        generateFields('quality', field)
      )
    },
    {
      key: '2',
      label: (
        <Typography.Title
          level={4}
          style={{
            color: 'var(--userColor)',
            margin: 0
          }}
          strong={false}
        >
          {t('quality.form.test.title')}
        </Typography.Title>
      ),
      children: testFields.map((field) => generateFields('quality', field))
    },
    {
      key: '3',
      label: (
        <Typography.Title
          level={4}
          style={{
            color: 'var(--userColor)',
            margin: 0
          }}
          strong={false}
        >
          {t('quality.form.soft_skills.title')}
        </Typography.Title>
      ),
      children: softSkillsFields.map((field) =>
        generateFields('quality', field)
      )
    },
    {
      key: '4',
      label: (
        <Typography.Title
          level={4}
          style={{
            color: 'var(--userColor)',
            margin: 0
          }}
          strong={false}
        >
          {t('quality.form.conversion_rate.title')}
        </Typography.Title>
      ),
      children: conversionRateFields.map((field) =>
        generateFields('quality', field)
      )
    }
  ];

  return { categories, commentField: generateFields('quality', commentField) };
};

export default useCollapseByCategories;
