import { useTranslation } from 'react-i18next';
import numeral from 'numeral';
import useFeesContext from './FeesContext';
import { formatValue } from '../../utils/formatNumber';

export const useFeesColumns = () => {
  const { t } = useTranslation();
  const { enums, ps_micro } = useFeesContext();

  return [
    {
      title: t('fees.form.service'),
      dataIndex: 'service',
      key: 'service',
      sorter: true,
      render: (value) => t(`fees.enums.${value}`),
      filterSearch: true,
      filters: enums?.services?.map((service) => ({
        text: t(`fees.enums.${service}`),
        value: service
      })),
      onFilter: (value, record) => record.service.includes(value)
    },
    {
      title: t('fees.form.fee_code'),
      dataIndex: 'fee_code',
      key: 'fee_code',
      sorter: true,
      filterSearch: true,
      filters: enums?.codes?.map((fee_code) => ({
        text: fee_code,
        value: fee_code
      })),
      onFilter: (value, record) => record.fee_code.includes(value)
    },
    {
      title: t('fees.form.amount'),
      dataIndex: 'amount',
      key: 'amount',
      render: (amount) => `${numeral(amount).format('0,0.00')}€`,
      sorter: true
    },
    {
      title: t('fees.form.micro_ps'),
      dataIndex: 'micro_ps',
      key: 'micro_ps',
      sorter: true,
      render: (value) => value?.title,
      filterSearch: true,
      filters: ps_micro
        ?.sort((a, b) => a.title.localeCompare(b.title))
        .map((ps) => ({
          text: ps.title,
          value: ps._id
        })),
      onFilter: (value, record) => record.micro_ps._id.includes(value)
    },
    {
      title: t('fees.form.discount'),
      dataIndex: 'discount',
      key: 'discount',
      render: (discount) => `${discount}%`,
      sorter: true
    },
    {
      title: t('fees.form.start_date'),
      dataIndex: 'start_date',
      key: 'start_date',
      sorter: true,
      render: (value) => formatValue(value, 'date')
    },
    {
      title: t('fees.form.created_by'),
      dataIndex: 'created_by',
      key: 'created_by',
      sorter: true,
      render: (value) =>
        `${value?.first_name} ${value?.usage_last_name || value?.last_name}`
    }
  ];
};
