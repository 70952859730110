import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { Select, DatePicker, Form, Input, Button } from 'antd';
import { CloseCircleOutlined } from '@ant-design/icons';
import UserContextProvider from './UserContext';

const { Option } = Select;

/**
 * Custom hook for generating administration fields data for user administration form.
 *
 * @hook
 * @returns {object} Object containing administration fields and loading status
 * @property {Array} administrationFields - Array of field objects for administration form
 * @property {boolean} isFieldsLoading - Loading status for field data
 */

const useAdministrationFields = () => {
  const { t } = useTranslation();
  const { enums } = UserContextProvider();
  const [isFieldsLoading, setIsFieldsLoading] = useState(true);
  const format = 'DD-MM-YYYY';

  const handleKeyPress = (e) => {
    const { value } = e.target;
    if (!/[0-9]/.test(e.key) || (value.length === 0 && e.key === '0')) {
      e.preventDefault();
    }
  };

  useEffect(() => {
    if (enums) setIsFieldsLoading(false);
  }, []);

  const administrationFields = [
    {
      label: 'last_name',
      name: ['last_name']
    },
    {
      label: 'address.number',
      name: ['address', 'number']
    },
    {
      label: 'address.street',
      name: ['address', 'street']
    },
    {
      label: 'address.additional',
      name: ['address', 'additional']
    },
    {
      label: 'address.postal_code',
      name: ['address', 'postal_code']
    },
    {
      label: 'address.city',
      name: ['address', 'city']
    },
    {
      name: ['personal_email'],
      rules: [{ required: false }, { type: 'email' }]
    },
    {
      name: ['social_security_number']
    },
    {
      name: ['iban']
    },
    {
      name: ['date_of_birth'],
      input: (
        <DatePicker
          format={format}
          disabledDate={(current) => current > moment()}
        />
      )
    },
    {
      name: ['family_status'],
      input: (
        <Select loading={isFieldsLoading}>
          {(enums?.familyStatus || []).map((family) => (
            <Option key={family} value={family}>
              {t(`users.familyStatus.${family}`)}
            </Option>
          ))}
        </Select>
      )
    },
    {
      name: ['children'],
      input: (
        <Form.List name="children" initialValue={[]}>
          {(fields, { add, remove }) => (
            <>
              {fields.map((field) => (
                <div key={field.name}>
                  <Form.Item
                    {...field}
                    key={[field.name, 'name']}
                    name={[field.name, 'name']}
                    label={t('users.form.children_v.name')}
                  >
                    <Input />
                  </Form.Item>
                  <CloseCircleOutlined
                    style={{
                      position: 'absolute',
                      top: '5px',
                      right: '5px',
                      fontSize: '16px',
                      color: 'red',
                      cursor: 'pointer'
                    }}
                    onClick={() => remove(field.name)}
                  />
                  <Form.Item
                    {...field}
                    key={[field.name, 'dob']}
                    name={[field.name, 'dob']}
                    label={t('users.form.children_v.dob')}
                    rules={[{ required: true }]}
                  >
                    <DatePicker
                      format={format}
                      style={{ width: 200 }}
                      disabledDate={(current) => {
                        const after = current >= moment();
                        return after;
                      }}
                    />
                  </Form.Item>
                </div>
              ))}
              <Form.Item>
                <Button type="primary" onClick={() => add()}>
                  {t('users.form.buttons.addChildren')}
                </Button>
              </Form.Item>
            </>
          )}
        </Form.List>
      )
    },

    {
      name: ['emergency_contact', 'first_name'],
      startWithDivider: {
        orientation: 'center',
        plain: true,
        title: `${t('users.form.emergency.title')}`
      }
    },
    {
      name: ['emergency_contact', 'last_name']
    },
    {
      name: ['emergency_contact', 'phone_number'],
      input: (
        <Input.Group compact>
          <Form.Item
            noStyle
            name={['emergency_contact', 'phone_number', 'country_code']}
            initialValue="+33"
          >
            <Select>
              <Select.Option value="+33">+33</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item
            noStyle
            name={['emergency_contact', 'phone_number', 'number']}
            rules={[
              {
                pattern: /^[1-9]\d{8}$/,
                message: t(`messages.phone_number`)
              }
            ]}
          >
            <Input onKeyPress={handleKeyPress} maxLength={9} />
          </Form.Item>
        </Input.Group>
      )
    }
  ];
  return {
    administrationFields: administrationFields.map((f) => f),
    isFieldsLoading
  };
};

export default useAdministrationFields;
