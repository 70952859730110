import { message as antdMessage } from 'antd';
import i18next from 'i18next';

export const showSuccessMessage = (t, resourceName, purpose) => {
  const translatedResourceName = i18next.exists(
    `messages.success.${resourceName}`
  )
    ? t(`messages.success.${resourceName}`)
    : resourceName;

  const translatedPurpose = t(`messages.success.${purpose}`);

  antdMessage.success(
    t('messages.success.action', {
      resourceName: translatedResourceName,
      purpose: translatedPurpose
    })
  );
};
