import { Tag, Popconfirm, Divider } from 'antd';
import { Link } from 'react-router-dom';
import {
  WarningOutlined,
  EditOutlined,
  ContainerOutlined,
  EyeOutlined,
  CheckOutlined
} from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import {
  quittanceStatus,
  userCompanies
} from '../../utils/constants/tagColors';
import QuittanceContextProvider from './QuittanceContext';
import { routes, subRoutes } from '../../utils/constants/adminRoutes';
import { formatValue } from '../../utils/formatNumber';

/**
 * Custom hook for generating table columns for displaying quittances data.
 *
 * @hook
 * @param {object} enums - Enumerations data for contract properties
 * @param {Array} psMicro - List of micro ps data for popover
 * @param {Array} psMacro - List of macro ps data for popover
 * @param {Array} customerEnums - List of customerEnums data for popover
 * @returns {Array} Array of table column objects
 */

export const useColumns = (hasAccess) => {
  const { t } = useTranslation();
  const {
    quittanceEnums,
    microPs,
    macroPs,
    onArchived,
    isAdmin,
    openModalStatus,
    setButtonClicked,
    setIdFromOverlay
  } = QuittanceContextProvider();
  return [
    {
      title: t('quittances.columns.status'),
      key: 'status',
      width: '8%',
      dataIndex: ['status'],
      render: (status) => (
        <Tag color={quittanceStatus[status]}>
          {t(`quittances.form.enums.${status}`)}
        </Tag>
      ),
      sorter: true,
      filters: quittanceEnums?.status?.map((c) => ({
        text: t(`quittances.form.enums.${c}`),
        value: c
      }))
    },
    {
      title: t('quittances.columns.company'),
      key: 'company',
      dataIndex: ['company'],
      render: (company) => (
        <Tag color={userCompanies[company]}>
          {t(`employments.tags.${company}`)}
        </Tag>
      ),
      sorter: true,
      filters: quittanceEnums?.companies?.map((p) => ({
        text: p,
        value: p
      }))
    },
    {
      title: t('quittances.columns.code'),
      key: 'code',
      dataIndex: 'code',
      sorter: true
    },
    {
      title: t('quittances.columns.ps_number'),
      key: 'ps_number',
      dataIndex: ['ps_number'],
      sorter: true
    },
    {
      title: t('quittances.columns.company_name'),
      key: 'customer.company_name',
      dataIndex: 'customer',
      width: '9%',
      render: (_, data) => data?.customer?.company_name,
      sorter: true
    },
    {
      title: t('contracts.columns.macro_ps'),
      dataIndex: ['macro_ps'],
      key: 'macro_ps.title',
      width: '8%',
      render: (value) => value?.title,
      filterSearch: true,
      filters: macroPs
        ?.sort((a, b) => a.title.localeCompare(b.title))
        ?.map((ps) => ({
          text: ps.title,
          value: ps._id
        })),
      sorter: true
    },
    {
      title: t('quittances.columns.micro_ps'),
      dataIndex: 'micro_ps',
      key: 'micro_ps.title',
      width: '8%',
      render: (value) => value?.title,
      filterSearch: true,
      filters: microPs
        ?.sort((a, b) => a.title.localeCompare(b.title))
        ?.map((ps) => ({
          text: ps.title,
          value: ps._id
        })),
      sorter: true
    },
    {
      title: t('quittances.columns.total'),
      key: 'total',
      dataIndex: 'total',
      render: (total) => `${formatValue(total, 'currency', true)}`
    },
    {
      title: t('quittances.columns.total_with_taxes'),
      key: 'total_with_taxes',
      dataIndex: 'total_with_taxes',
      render: (total_with_taxes) =>
        `${formatValue(total_with_taxes, 'currency', true)}`
    },
    {
      title: t('quittances.columns.transfer_date'),
      key: 'transfer_date',
      dataIndex: 'transfer_date',
      render: (transfer_date) =>
        transfer_date ? formatValue(transfer_date, 'date') : '-'
    },
    {
      title: t('quittances.columns.payment_choice'),
      key: 'payment_choice',
      dataIndex: 'payment_choice',
      render: (payment_choice) => t(`quittances.form.enums.${payment_choice}`)
    },
    {
      title: t('quittances.columns.total_with_taxes_vs_paid'),
      key: 'payments',
      render: ({ payments, total_with_taxes }) => {
        const totalPaid = (payments || []).reduce(
          (acc, p) => acc + (p?.amount || 0),
          0
        );
        const total_due =
          Number(total_with_taxes.toFixed(2)) - Number(totalPaid.toFixed(2));
        return `${formatValue(total_due, 'currency', true)}`;
      }
    },
    {
      title: t('quittances.columns.paid_amount'),
      key: 'payments',
      render: ({ payments }) => {
        const totalPaid = (payments || []).reduce(
          (acc, p) => acc + (p?.amount || 0),
          0
        );
        return `${formatValue(totalPaid, 'currency', true)}`;
      }
    },
    {
      key: 'action',
      align: 'right',
      fixed: 'right',
      width: 120,
      render: (record) => (
        <>
          {hasAccess('show_invoice') && (
            <Link
              to={`${routes.BILLING}${subRoutes.BILLING.QUITTANCES}/show/${record._id}`}
            >
              <EyeOutlined style={{ fontSize: 18 }} />
            </Link>
          )}
          {record?.status === 'IN_PROGRESS' && isAdmin && (
            <>
              <Divider type="vertical" />
              <Popconfirm
                title={t('datatable.column.action.validate_quittance.title')}
                okText={t('datatable.column.action.validate_quittance.ok')}
                okButtonProps={{ type: 'danger' }}
                cancelText={t(
                  'datatable.column.action.validate_quittance.cancel'
                )}
                onConfirm={() => {
                  setButtonClicked('statut');
                  setIdFromOverlay(record._id);
                  openModalStatus();
                }}
                icon={<WarningOutlined />}
              >
                <CheckOutlined style={{ fontSize: 18 }} />
              </Popconfirm>
              <Divider type="vertical" />
              <Link
                to={`${routes.BILLING}${subRoutes.BILLING.QUITTANCES}/edit/${record._id}`}
              >
                <EditOutlined style={{ fontSize: 18 }} />
              </Link>
            </>
          )}
          {hasAccess('archive') && (
            <>
              <Divider type="vertical" />
              <Popconfirm
                title={t('datatable.column.action.delete.title')}
                okText={t('datatable.column.action.delete.ok')}
                okButtonProps={{ type: 'danger' }}
                cancelText={t('datatable.column.action.delete.cancel')}
                onConfirm={() => onArchived(record._id)}
                icon={<WarningOutlined />}
              >
                <ContainerOutlined
                  style={{ fontSize: 18, color: 'red' }}
                  type="delete"
                />
              </Popconfirm>
            </>
          )}
        </>
      )
    }
  ];
};
