import { Route, Routes } from 'react-router-dom';
import { Exception } from '../../components';
import { CustomerContextProvider } from './CustomerContext';
import { ListCustomer } from './ListCustomer';
import { CreateUpdateCustomer } from './CreateUpdateCustomer';
import { ShowCustomer } from './ShowCustomer';

export const CustomerRouter = () => (
  <CustomerContextProvider>
    <Routes>
      <Route
        path="/create"
        element={<CreateUpdateCustomer purpose="create" />}
      />
      <Route
        path="/edit/:id"
        element={<CreateUpdateCustomer purpose="edit" />}
      />
      <Route path="/show/:id" element={<ShowCustomer />} />
      <Route index element={<ListCustomer />} />
      <Route path="*" element={<Exception />} />
    </Routes>
  </CustomerContextProvider>
);
