export const userRoles = {
  'SUPER-ADMIN': 'magenta',
  ADMIN: 'red',
  USER: 'orange',
  'SALES-USER': 'purple',
  'LEADER-USER': 'geekblue',
  GUEST: 'green',
  GROUP: 'blue',
  'ADMIN-RH': 'purple',
  'ADMIN-ACCOUNTANT': 'green',
  'SALES-PROSPECTOR': 'red',
  'ADMIN-PROD': 'yellow'
};

export const userStatus = {
  ACTIVE: 'green',
  INACTIVE: 'red'
};

export const userAdmin = {
  true: 'green',
  false: 'red'
};

export const userEmployer = {
  TPA: 'purple',
  TPG: 'MediumPurple',
  TPG2: 'DarkViolet',
  SPS: 'Violet'
};

export const userCompanies = {
  TPA: 'purple',
  SPS: 'MediumPurple'
};

export const userLevels = {
  DEB: 'green',
  INT1: 'lightblue',
  INT2: 'blue',
  EXP1: 'purple',
  EXP2: 'MediumPurple',
  EXP3: 'DarkViolet'
};

export const userMaterials = {
  HEADSET: 'DarkViolet',
  MOUSE: 'grey',
  KEYBOARD: 'Black'
};

export const userPositions = {
  'SUPER-ADMIN': 'black',
  ADMIN: 'grey',
  'ADMIN-RH': 'purple',
  'ADMIN-ACCOUNTANT': 'green',
  'SALES-USER': 'geekblue',
  'SALES-PROSPECTOR': 'red',
  'LEADER-USER': 'orange',
  'ADMIN-PROD': 'MediumPurple',
  USER: 'darkgreen',
  GUEST: 'magenta',
  GROUP: 'blue'
};

export const pendingTasksCat = {
  POST: 'green',
  PATCH: 'blue',
  DELETE: 'red'
};

export const WFLStatus = {
  INITIATED: 'grey',
  PENDING: 'orange',
  VALIDATED: 'green'
};

export const customerStatus = {
  PROPSECT: 'darkred',
  ACTIVE: 'blue',
  TO_TRANSFORM: 'orange',
  NON_PS_CUSTOMER: 'green',
  INACTIVE: 'grey'
};

export const levelEmployees = {
  DEB: '#E0F2F1',
  INT1: '#B2DFDB',
  INT2: '#80CBC4',
  EXP1: '#4DB6AC',
  EXP2: '#26A69A',
  EXP3: '#00897B'
};

export const yesNoSwitch = {
  true: 'black',
  false: 'red'
};

export const eventStatus = {
  PENDING: 'yellow',
  APPROVED: 'orange',
  REJECTED: 'purple',
  ARCHIVED: 'red'
};

export const ServicesStatusColor = {
  PENDING: 'yellow',
  TERMINATED: 'orange',
  ACTIVE: 'green',
  ARCHIVED: 'red'
};

export const quoteStatus = {
  CREATED: 'grey',
  QUOTE_SENT: 'orange',
  ODM_VALID: 'green',
  ODM_INVALID: 'red',
  ACCEPTED: '#26A69A',
  REFUSED: 'volcano'
};

export const contractStatus = {
  ACTIVE: 'green',
  ARCHIVED: 'red',
  TERMINATED: 'black'
};

export const quittancesStatus = {
  IN_PROGRESS: 'grey',
  NOT_PAID: 'lightblue',
  PAID: 'green',
  DEBIT_PENDING: 'blue',
  FOLLOW_UP: 'yellow',
  DEBT_RECOVERY: 'volcano',
  LOST: 'black'
};

export const interventionsStatus = {
  PROVISIONAL: 'grey',
  PLANNED: 'lightblue',
  OPEN: 'green',
  CLOSED: 'red',
  SUSPENDED: 'orange'
};

export const newsStatus = {
  CREATED: '#ABABAB',
  SENT: '#F7AC18',
  ARCHIVED: 'red'
};

export const eventsColor = {
  PAID_VACATION: '#02B0F1',
  UNPAID_LEAVE: '#FFC003',
  MATERNITY_LEAVE: '#FFCCFF',
  PARENTAL_LEAVE: '#FF00FF',
  SICK_LEAVE: '#996633',
  ABSENCE: '#FF0200',
  PTO: '#06B050',
  BANK_HOLIDAY: '#800080',
  OTHER: '#808080',
  SOLIDARITY: '#26A69A',
  ADDITIONAL_HOUR: '#000000',
  INTERVENTION: '#FF6633',
  OCCUPATIONAL_DESEASE: '#0000FF'
};

export const servicesColor = {
  MANAGEMENT: '#99B0F1',
  LIABILITIES: '#FFC003',
  FINANCIAL: '#FFCCFF',
  CONFIGURATION: '#FF00FF',
  OPTIMISATION: '#996633',
  CONFIG: '#06B050',
  SEO: '#26A69A',
  MISSION: '#80CBC4',
  OTHER: '#ABABAB'
};

export const customerServicesColor = {
  NOT_READ: 'orange',
  READ: 'green',
  TO_PROCESSED: 'red',
  PROCESSED: 'green',
  ARCHIVED: 'orange'
};
export const reconciliationStatementColor = {
  NOT_SEND: 'orange',
  SEND: 'green',
  TO_DO: 'gray'
};

export const actionStatus = {
  URGENT: '#F81B02',
  UPCOMING: '#06B050',
  PENDING: 'blue',
  DONE: 'green',
  ARCHIVED: 'orange'
};

export const templateStatus = {
  DRAFT: '#FADB14',
  VALIDATED: '#2DE180'
};

export const quittanceStatus = {
  IN_PROGRESS: '#ABABAB',
  NOT_PAID: '#629AD2',
  PAID: '#5FCB29',
  PARTIALY_PAID: 'orange',
  DEBIT_PENDING: '#314375',
  FOLLOW_UP: '#F7AC18',
  DEBT_RECOVERY: '#F30107',
  LOST: '#000000',
  REJECTED: 'red'
};

export const paymentStatuses = {
  TO_RECONCILE: '#F39155',
  RECONCILIED: '#9FC7A3',
  REJECTED: '#F79CA7'
};

export const paymentTypes = {
  CREDIT: '#F39155',
  CLASSIC: '#9FC7A3'
};

export const qualityControlStatus = {
  ON_GOING: '#f8ac18',
  VALID: '#30972e',
  INVALID: '#ff0003',
  CLOSED: '#eb5b25',
  ARCHIVED: '#ababab'
};

export const graphcolors = [
  '#0088FE',
  '#00C49F',
  '#FFBB28',
  '#FF8042',
  '#FF6384',
  '#36A2EB',
  '#FFCE56',
  '#4BC0C0',
  '#9966FF',
  '#FF9F40',
  '#FF6384',
  '#E7E9ED',
  '#FF5733',
  '#C70039',
  '#900C3F',
  '#581845',
  '#DAF7A6',
  '#FFC300',
  '#C0C0C0',
  '#FFD700'
];
