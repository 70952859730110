import { Input, Form, Button, Select, Divider, Checkbox } from 'antd';
import { CloseCircleOutlined, RedoOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import useCustomerContext from '../CustomerContext';
import { useAuthContext } from '../../../contexts/AuthContext';
import { generateUniqueFakeMail } from '../../../utils/generateUniqueFakeMail';

const { Option } = Select;

/**
 * useFields is a custom hook that provides an array of field configurations for customer data input.
 *
 * @hook
 * @returns {Array<Object>} An array of field configurations.
 */

const useFields = (formValues, form) => {
  const { enums } = useCustomerContext();
  const { t } = useTranslation();
  const { dispatchAPI } = useAuthContext();

  const generateMail = async (index) => {
    const email = await generateUniqueFakeMail(dispatchAPI);
    form.setFields([
      {
        name: ['collaborators', index, 'email'],
        value: email
      }
    ]);
  };

  const addRequiredLabel = () => <span style={{ color: 'red' }}> * </span>;

  const handleKeyPress = (e) => {
    const { value } = e.target;
    if (!/[0-9]/.test(e.key) || (value.length === 0 && e.key === '0')) {
      e.preventDefault();
    }
  };
  return [
    {
      name: ['collaborators'],
      input: (
        <Form.List name="collaborators">
          {(fields, { add, remove }) => (
            <>
              {fields.map((field, index) => (
                <div key={field?.name}>
                  <Divider>
                    {t('customers.form.collaborator.title')} {index + 1}
                  </Divider>
                  <Form.Item
                    {...field}
                    key={[field?.name, 'operator_code']}
                    name={[field?.name, 'operator_code']}
                    label={`${t('customers.form.collaborator.operator_code')}`}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    {...field}
                    key={[field?.name, 'gender']}
                    name={[field?.name, 'gender']}
                    label={
                      <>
                        {addRequiredLabel()}{' '}
                        {t('customers.form.collaborator.gender')}
                      </>
                    }
                  >
                    <Select>
                      {(enums?.genders || []).map((gender) => (
                        <Option key={gender} value={gender}>
                          {t(`users.genders.${gender}`)}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                  <Form.Item
                    {...field}
                    key={[field?.name, 'usage_last_name']}
                    name={[field?.name, 'usage_last_name']}
                    label={
                      <>
                        {addRequiredLabel()}{' '}
                        {t('customers.form.collaborator.usage_last_name')}
                      </>
                    }
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    {...field}
                    key={[field?.name, 'first_name']}
                    name={[field?.name, 'first_name']}
                    label={
                      <>
                        {addRequiredLabel()}{' '}
                        {t('customers.form.collaborator.first_name')}
                      </>
                    }
                  >
                    <Input />
                  </Form.Item>
                  <Input.Group compact>
                    <Form.Item
                      {...field}
                      key={[field?.name, 'email']}
                      name={[field?.name, 'email']}
                      label={
                        <>
                          {addRequiredLabel()}{' '}
                          {t('customers.form.collaborator.email')}
                        </>
                      }
                    >
                      <Input
                        type="email"
                        disabled={!!formValues?.collaborators?.[index]?._id}
                        required
                      />
                    </Form.Item>
                    <Form.Item noStyle>
                      <RedoOutlined
                        onClick={() => {
                          if (!formValues?.collaborators?.[index]?._id)
                            generateMail(field.name);
                        }}
                      />
                    </Form.Item>
                  </Input.Group>
                  <Form.Item
                    {...field}
                    key={[field?.name, 'phone_number']}
                    name={[field?.name, 'phone_number']}
                    label={`${t(
                      'customers.form.collaborator.phone_number.number'
                    )}`}
                  >
                    <Input.Group compact>
                      <Form.Item
                        noStyle
                        name={[field?.name, 'country_code']}
                        initialValue="+33"
                      >
                        <Select>
                          <Select.Option value="+33">+33</Select.Option>
                        </Select>
                      </Form.Item>
                      <Form.Item
                        noStyle
                        name={[field?.name, 'phone_number', 'number']}
                        rules={[
                          {
                            pattern: /^[1-9]\d{8}$/,
                            message: t(`messages.phone_number`)
                          }
                        ]}
                      >
                        <Input onKeyPress={handleKeyPress} maxLength={9} />
                      </Form.Item>
                    </Input.Group>
                  </Form.Item>
                  <Form.Item
                    {...field}
                    key={[field?.name, 'local_phone_number']}
                    name={[field?.name, 'local_phone_number']}
                    label={`${t(
                      'customers.form.collaborator.local_phone_number.number'
                    )}`}
                  >
                    <Input.Group compact>
                      <Form.Item
                        noStyle
                        name={[field?.name, 'country_code']}
                        initialValue="+33"
                      >
                        <Select>
                          <Select.Option value="+33">+33</Select.Option>
                        </Select>
                      </Form.Item>
                      <Form.Item
                        noStyle
                        name={[field?.name, 'local_phone_number', 'number']}
                        rules={[
                          {
                            pattern: /^[1-9]\d{8}$/,
                            message: t(`messages.phone_number`)
                          }
                        ]}
                      >
                        <Input onKeyPress={handleKeyPress} maxLength={9} />
                      </Form.Item>
                    </Input.Group>
                  </Form.Item>
                  <Form.Item
                    {...field}
                    key={[field?.name, 'invoiced']}
                    name={[field?.name, 'invoiced']}
                    label={`${t('customers.form.collaborator.invoiced')}`}
                    valuePropName="checked"
                  >
                    <Checkbox />
                  </Form.Item>
                  <Form.Item
                    {...field}
                    key={[field?.name, 'report_recipient']}
                    name={[field?.name, 'report_recipient']}
                    label={`${t(
                      'customers.form.collaborator.report_recipient'
                    )}`}
                    valuePropName="checked"
                  >
                    <Checkbox />
                  </Form.Item>

                  <Form.Item
                    {...field}
                    key={[field?.name, '_id']}
                    name={[field?.name, '_id']}
                    label={`${t('customers.form.collaborator._id')}`}
                    hidden
                  >
                    <Input />
                  </Form.Item>

                  <CloseCircleOutlined
                    style={{
                      position: 'absolute',
                      top: '5px',
                      right: '5px',
                      fontSize: '16px',
                      color: 'red',
                      cursor: 'pointer'
                    }}
                    onClick={() => remove(field?.name)}
                  />
                </div>
              ))}
              <Form.Item>
                <Button type="primary" onClick={() => add()}>
                  {t('customers.form.collaborator.addcollaborator')}
                </Button>
              </Form.Item>
            </>
          )}
        </Form.List>
      )
    }
  ];
};

export default useFields;
