import { Col, Steps, Card } from 'antd';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { CloseCircleFilled } from '@ant-design/icons';

const { Step } = Steps;

/**
 * Component for displaying principal steps status info of the quote.
 *
 * @component
 * @param {object} props - Component props
 * @param {object} props.data - Quote data containing principal details
 * @returns {JSX.Element} Component JSX
 */

export const StepsCard = ({ data, steps }) => {
  const { t } = useTranslation();

  return (
    <Col span="24">
      <Card bordered={false}>
        <Steps
          current={
            (steps.indexOf(
              data?.status !== 'VALIDATE' ? data?.status : 'ODM_VALID'
            ) ?? -1) + 1
          }
        >
          {steps.map((step, index) => {
            const isCurrent = index === (steps.indexOf(data?.status) ?? -1);
            return (
              <Step
                key={step}
                title={t(`quotes.show.steps.${step}`)}
                icon={
                  isCurrent &&
                  (data?.status === 'REFUSED' ||
                    data?.status === 'ODM_INVALID') ? (
                    <CloseCircleFilled style={{ color: 'red' }} />
                  ) : undefined
                }
              />
            );
          })}
        </Steps>
      </Card>
    </Col>
  );
};

StepsCard.propTypes = {
  data: PropTypes.shape({
    status: PropTypes.string
  }),
  steps: PropTypes.arrayOf(PropTypes.string)
};

StepsCard.defaultProps = {
  data: undefined,
  steps: undefined
};
