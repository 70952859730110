import { Typography } from 'antd';
import { formatValue } from '../../utils/formatNumber';

export const getListContent = (t, customerService) => {
  const { recipients, created_by, customer } = customerService;

  const listContent = [
    {
      label: t('customerServices.labels.created_by'),
      span: 8,
      content: (
        <Typography.Text>
          {created_by?.first_name}{' '}
          {created_by?.usage_last_name || created_by?.last_name}
        </Typography.Text>
      )
    },
    {
      label: t('customerServices.labels.ps_number'),
      span: 8,
      content: <Typography.Text>{customer?.ps_number}</Typography.Text>
    },
    {
      label: t('customerServices.labels.company_name'),
      span: 8,
      content: <Typography.Text>{customer?.company_name}</Typography.Text>
    },
    {
      label: t('customerServices.labels.recipients'),
      span: 8,
      content: (
        <Typography.Text>
          {recipients
            ?.map(
              (recipient) =>
                `${recipient?.first_name} ${
                  recipient?.usage_last_name || recipient?.last_name
                }`
            )
            .join(' - ')}
        </Typography.Text>
      )
    },
    {
      label: t('customerServices.labels.leader'),
      span: 8,
      content: (
        <Typography.Text>
          {customer?.leader?.first_name}{' '}
          {customer?.leader?.usage_last_name || customer?.leader?.last_name}
        </Typography.Text>
      )
    },
    {
      label: t('customerServices.labels.recorded_time'),
      span: 8,
      content: (
        <Typography.Text>
          {t(`customerServices.form.enums.${customerService?.recorded_time}`)}
        </Typography.Text>
      )
    },
    {
      label: t('customerServices.labels.completion_date'),
      span: 8,
      content: (
        <Typography.Text>
          {customerService?.completion_date &&
            formatValue(customerService?.completion_date, 'date')}
        </Typography.Text>
      )
    },
    {
      label: t('customerServices.labels.origin'),
      span: 8,
      content: (
        <Typography.Text>
          {t(`customerServices.form.enums.${customerService?.origin}`)}
        </Typography.Text>
      )
    }
  ];
  return listContent;
};
