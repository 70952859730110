export const customerServicesHeaders = [
  {
    label: 'status',
    key: 'status'
  },
  {
    label: 'created_by',
    key: 'created_by.first_name'
  },
  {
    label: 'recipients',
    key: 'recipients.first_name'
  },
  {
    label: 'completion_date',
    key: 'completion_date'
  },
  {
    label: 'origin',
    key: 'origin'
  },
  {
    label: 'ps_number',
    key: 'customer.ps_number'
  },
  {
    label: 'company_name',
    key: 'customer.company_name'
  },
  {
    label: 'recorded_time',
    key: 'recorded_time'
  }
];
