import { Route, Routes } from 'react-router-dom';
import { subRoutes } from '../../utils/constants/adminRoutes';
import { Exception } from '../../components';
import { QuittanceContextProvider } from './QuittanceContext';
import { ListQuittances } from './ListQuittances';
import { CreateUpdateQuittance } from './CreateUpdateQuittance';
import { ShowQuittance } from './ShowQuittance';
import { ListPayments } from '../payments/ListPayments';
import ListTransfers from '../transfers/ListTransfers';
import CreateUpdateTransfer from '../transfers/CreateUpdateTransfer';
import ShowTransfer from '../transfers/ShowTransfer';

export const QuittanceRouter = () => (
  <QuittanceContextProvider>
    <Routes>
      <Route
        path={`${subRoutes.BILLING.QUITTANCES}/create/`}
        element={<CreateUpdateQuittance purpose="create" />}
      />
      <Route
        path={`${subRoutes.BILLING.QUITTANCES}/edit/:id`}
        element={<CreateUpdateQuittance purpose="edit" />}
      />
      <Route
        path={`${subRoutes.BILLING.QUITTANCES}/*`}
        element={<ListQuittances />}
      />
      <Route
        path={`${subRoutes.BILLING.QUITTANCES}/show/:id`}
        element={<ShowQuittance />}
      />
      <Route
        path={`${subRoutes.BILLING.PAYMENTS}/*`}
        element={<ListPayments />}
      />
      <Route
        path={`${subRoutes.BILLING.TRANSFERS}/create/`}
        element={<CreateUpdateTransfer purpose="create" />}
      />
      <Route
        path={`${subRoutes.BILLING.TRANSFERS}/*`}
        element={<ListTransfers />}
      />
      <Route
        path={`${subRoutes.BILLING.TRANSFERS}/show/:id`}
        element={<ShowTransfer />}
      />
      <Route path="*" element={<Exception />} />
    </Routes>
  </QuittanceContextProvider>
);
