import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import { useLocation } from 'react-router-dom';
import { CreateUpdateContainer } from '../../components/CreateUpdateContainer/CreateUpdateContainer';
import QuoteContextProvider from './QuoteContext';
import useInformationsFields from './informationsFields';
import useServicesFields from './servicesFields';

/**
 * Component for creating or updating quote information.
 *
 * @component
 * @param {string} purpose - Purpose of the form ('create' or 'update')
 * @returns {JSX.Element} CreateUpdateContainer component with quote creation or update functionality
 */

export const CreateUpdateQuote = ({ purpose }) => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const psNumber = queryParams.get('ps_number');
  const { setFormValues, formValues, customers, user } = QuoteContextProvider();
  const [forceOnValuesChange, setForceOnValuesChange] = useState(null);

  const { informationsFields } = useInformationsFields(
    purpose,
    setFormValues,
    formValues,
    psNumber,
    user
  );

  const { isFieldsLoading, servicesFields } = useServicesFields(purpose);

  useEffect(() => {
    if (psNumber && customers.length > 0)
      setForceOnValuesChange({
        ps_number: psNumber,
        customer: customers.find((c) => c.ps_number === psNumber)
      });
  }, [psNumber, customers]);

  const config = {
    onGetResource: {
      setFields: (data) => ({
        ...data,
        services: (data?.services || []).map((service) => ({
          ...service,
          start_date: service?.start_date && dayjs(service?.start_date),
          end_date: service?.end_date && dayjs(service?.end_date),
          discounts: (service?.discounts || []).map((discount) => ({
            ...discount,
            periods: [
              dayjs(discount?.periods[0]),
              dayjs(discount?.periods[1]).subtract(1, 'day')
            ]
          }))
        }))
      })
    },
    onUpdateResource: {
      setBody: (data) => ({
        ...data,
        status: 'CREATED',
        services: (data?.services || []).map((service) => ({
          ...service,
          start_date:
            service?.start_date &&
            dayjs(service.start_date)
              .startOf('day')
              .format('YYYY-MM-DDTHH:mm:ss.SSS[Z]'),
          end_date:
            service?.end_date &&
            dayjs(service?.end_date)
              .endOf('day')
              .format('YYYY-MM-DDTHH:mm:ss.SSS[Z]'),
          discounts: (service?.discounts || []).map((discount) => ({
            ...discount,
            periods: discount?.periods?.[0] && [
              dayjs(discount?.periods?.[0])
                .startOf('month')
                .format('YYYY-MM-DDTHH:mm:ss.SSS[Z]'),
              dayjs(discount?.periods?.[1])
                .endOf('month')
                .format('YYYY-MM-DDTHH:mm:ss.SSS[Z]')
            ]
          }))
        }))
      })
    },
    onCreateResource: {
      setBody: (data) => ({
        ...data,
        services: (data?.services || []).map((service) => ({
          ...service,
          start_date:
            service?.start_date &&
            dayjs(service.start_date)
              .startOf('day')
              .format('YYYY-MM-DDTHH:mm:ss.SSS[Z]'),
          end_date:
            service?.end_date &&
            dayjs(service?.end_date)
              .endOf('day')
              .format('YYYY-MM-DDTHH:mm:ss.SSS[Z]'),
          discounts: (service?.discounts || []).map((discount) => ({
            ...discount,
            periods: discount?.periods?.[0] && [
              dayjs(discount?.periods?.[0])
                .startOf('month')
                .format('YYYY-MM-DDTHH:mm:ss.SSS[Z]'),
              dayjs(discount?.periods?.[1])
                .endOf('month')
                .format('YYYY-MM-DDTHH:mm:ss.SSS[Z]')
            ]
          }))
        }))
      })
    }
  };

  return (
    <CreateUpdateContainer
      loadingFields={isFieldsLoading}
      withCards={[
        {
          fields: informationsFields,
          title: 'general_informations',
          bordered: false
        },
        ...(formValues?.ps_number
          ? [
              {
                fields: servicesFields,
                title: 'services',
                bordered: false
              }
            ]
          : [])
      ]}
      purpose={purpose}
      populate="services"
      baseUrl="quotes"
      resource="quotes"
      config={config}
      setFormValues={setFormValues}
      rejection={{
        condition: ['CREATED', 'QUOTE_SENT'],
        key: 'status'
      }}
      forceOnValuesChange={forceOnValuesChange}
    />
  );
};

CreateUpdateQuote.propTypes = {
  purpose: PropTypes.string.isRequired
};
