import React, { createContext, useContext, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import { useAuthContext } from '../../contexts/AuthContext';
import { useErrorMessage } from '../../utils/errorMessage';
import { showSuccessMessage } from '../../utils/showSuccessMessage';

const CustomerAnalysisContext = createContext({});

export const CustomerAnalysisContextProvider = ({ children }) => {
  const { dispatchAPI, user } = useAuthContext();
  const { t } = useTranslation();
  const { message } = useErrorMessage();
  const [reconcilitionStatementEnums, setReconcilitionStatementEnums] =
    useState({});
  const [psMicro, setPsMicro] = useState();
  const [customers, setCustomers] = useState();
  const [leaders, setLeaders] = useState([]);
  const [users, setUsers] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [totalCount, setTotalCount] = useState(0);

  const getTotalCount = async (startDate, endDate) => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/rejections?reject_rate=null&period_concerned>=${startDate}&period_concerned<=${endDate}`
      });
      setTotalCount(data.length);

      setRefresh(!refresh);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getReconcilitionStatementEnums = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/reconciliationstatements/enums`
      });
      setReconcilitionStatementEnums(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getCustomers = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: '/customers'
      });
      setCustomers(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getMicroPs = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/Psmicro?sort=title`
      });
      setPsMicro(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getLeaders = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: 'users?role=users:LEADER-USER'
      });
      setLeaders(data);
    } catch (error) {
      message(error);
    }
  };
  const getUsers = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/users?noFilterByRole=${true}`
      });
      setUsers(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const editReconciliation = async (record, status) => {
    try {
      const actionDates = record?.action_date || [];
      const doneBy = record?.done_by || [];

      await dispatchAPI('PATCH', {
        url: `/reconciliationstatements/${record?._id}`,
        body: {
          status,
          action_date: [...actionDates, dayjs().toDate()],
          done_by: [...doneBy, user._id]
        }
      });

      setRefresh(!refresh);
      showSuccessMessage(t, 'status', 'update');
    } catch (e) {
      message(e);
    }
  };

  const editRejection = async (id, values) => {
    try {
      await dispatchAPI('PATCH', {
        url: `/rejections/${id}`,
        body: values
      });
      setRefresh(!refresh);
      showSuccessMessage(t, 'reject', 'update');
    } catch (e) {
      message(e);
    }
  };

  useEffect(() => {
    (async () => {
      await getLeaders();
      await getCustomers();
      await getReconcilitionStatementEnums();
      await getMicroPs();
      await getUsers();
    })();
  }, []);

  return (
    <CustomerAnalysisContext.Provider
      value={{
        customers,
        psMicro,
        users,
        leaders,
        reconcilitionStatementEnums,
        refresh,
        setRefresh,
        editReconciliation,
        totalCount,
        getTotalCount,
        editRejection
      }}
    >
      {children}
    </CustomerAnalysisContext.Provider>
  );
};

CustomerAnalysisContextProvider.propTypes = {
  children: PropTypes.element.isRequired
};

export default () => useContext(CustomerAnalysisContext);
