// Main nav menuItems
export const routes = {
  HOME: '/',
  CUSTOMERS: '/customers',
  INTERVENTIONS: '/interventions',
  CUSTOMERSERVICES: '/customerServices',
  CALENDAR: '/calendar',
  ACTUALITIES: '/actualities',
  QUALITY_CONTROL: '/quality_control',
  OCCUPANCY_CHART: '/occupancy_chart',
  USERS: '/users',
  RH: '/rh',
  QUOTE: '/quote',
  CUSTOMER_ANALYSIS: '/customer-analysis',
  STATISTICS: '/statistics'
};

// Main nav subMenuItems
export const subRoutes = {
  RH: {
    ABSENCE_VACATION: '/absence-vacation'
  },
  CUSTOMER_ANALYSIS: {
    REJECTION: '/rejection',
    RECONCILIATION_STATEMENT: '/reconciliation-statement'
  },
  OCCUPANCY_CHART: {
    OCCUPANCY: '/occupancy',
    PLANNING: '/planning'
  }
};

// Other routes
export const outOfNavRoutes = {
  LOGIN: '/login',
  PROFILE: '/profile',
  SETTINGS: '/settings',
  TEMPLATE_DOCS: '/template-docs'
};

// url search parameters
export const pathSearches = {
  QUOTE: '?s=-created_at',
  INTERVENTIONS: '?s=-scheduled_start_date'
};
