import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Card,
  Row,
  Col,
  Button,
  Typography,
  Tag,
  Modal,
  Table,
  Popconfirm
} from 'antd';
import { useParams, Link, useNavigate } from 'react-router-dom';
import {
  ContainerOutlined,
  EditOutlined,
  ShareAltOutlined,
  LikeOutlined,
  WarningOutlined,
  CopyOutlined,
  EyeOutlined,
  DislikeOutlined,
  LikeFilled,
  DislikeFilled
} from '@ant-design/icons';
import { Pie } from '@ant-design/plots';
import {
  ContentCustom,
  Exception,
  PageHeaderCustom
} from '../../components/index';
import { useErrorMessage } from '../../utils/errorMessage';
import useArticleContext from './ArticleContext';
import { DescriptionList } from '../../components/DescriptionList/DescriptionList';
import { CustomSpin } from '../../components/CustomSpin/CustomSpin';
import { getListContent } from './getListContent';
import { FilesManagement } from '../../components/FilesManagement';
import { useAuthContext } from '../../contexts/AuthContext';
import generateHistoric from '../../utils/generateHistoric';
import getChartConfig from './getChartConfig';
import { useColumns } from './unreadColumns';
import { newsStatus } from '../../utils/constants/tagColors';
import { formattedContent } from '../../utils/constants/modulesReactQuill';
import { isAuthorized } from '../../utils/constants/authorizedMenu';
import { routes } from '../../utils/constants/adminRoutes';
import { showSuccessMessage } from '../../utils/showSuccessMessage';

/**
 * A component for displaying details of an article, including its status, user information,
 * and related content.
 *
 * @component
 * @returns {JSX.Element} The JSX element representing the article details.
 */

const ShowArticle = () => {
  const { user, dispatchAPI } = useAuthContext();
  const { id } = useParams();
  const { t } = useTranslation();
  const { message } = useErrorMessage();
  const navigate = useNavigate();
  const {
    getDataFromId,
    changeStatus,
    refresh,
    addSeen,
    unsee,
    likeDislike,
    getUsersByRole
  } = useArticleContext();

  const [isLoading, setIsLoading] = useState(true);
  const [article, setArticle] = useState();
  const [listContent, setListContent] = useState([]);
  const [visible, setVisible] = useState(false);
  const [users, setUsers] = useState([]);

  const hasAccess = (purpose) =>
    isAuthorized(`${routes.ACTUALITIES}/show`, user?.role, purpose);

  const duplicateResource = async () => {
    try {
      await dispatchAPI('POST', { url: `/news/duplicate/${id}` });
      showSuccessMessage(t, 'news', 'duplicate');

      navigate(-1);
    } catch (e) {
      message(e);
    }
  };

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      try {
        const data = await getDataFromId(id);
        setArticle(data);
        setListContent(getListContent(t, data));
        if (!data?.read_by?.includes(user._id)) {
          await addSeen(id);
        }
        if (data?.status === 'SENT' && hasAccess('stats')) {
          const res = await getUsersByRole(data?.recipients);
          setUsers(res || []);
        }
      } catch (e) {
        message(e);
      }
      setIsLoading(false);
    })();
  }, [refresh, id]);

  const config = getChartConfig(article);
  const columns = useColumns();

  let content;

  if (isLoading)
    content = (
      <ContentCustom>
        <CustomSpin />
      </ContentCustom>
    );
  else if (!article) content = <Exception />;
  else
    content = (
      <ContentCustom>
        <PageHeaderCustom
          title={
            <Row gutter={[16, 16]}>
              <Col>
                <Typography.Text className="ant-page-header-heading-title">
                  {article?.title}
                </Typography.Text>
              </Col>
              <Col>
                <Tag color={newsStatus[article?.status]}>
                  {t(`news.status.${article?.status}`)}
                </Tag>
              </Col>
            </Row>
          }
          extra={
            <>
              {(article?.status !== 'SENT' ||
                user?.role.startsWith('admins:')) && (
                <>
                  <Button
                    type="primary"
                    icon={<ShareAltOutlined size={24} />}
                    onClick={() => changeStatus(id, 'SENT')}
                  >
                    {t('news.buttons.send')}
                  </Button>
                  <Link to={`/actualities/edit/${id}`}>
                    <Button type="primary" icon={<EditOutlined size={24} />}>
                      {t('news.buttons.edit')}
                    </Button>
                  </Link>
                </>
              )}
              <Modal
                title={t('news.modal.title')}
                open={visible}
                onOk={() => setVisible(false)}
                onCancel={() => setVisible(false)}
              >
                <Row gutter={[24, 24]} justify="center">
                  <Button
                    type="link"
                    icon={
                      article?.likes?.includes(user._id) ? (
                        <LikeFilled size={40} />
                      ) : (
                        <LikeOutlined size={40} />
                      )
                    }
                    onClick={() => {
                      likeDislike(id, true);
                      setVisible(false);
                    }}
                  />
                  <Button
                    type="link"
                    icon={
                      article?.dislikes?.includes(user._id) ? (
                        <DislikeFilled size={40} />
                      ) : (
                        <DislikeOutlined size={40} />
                      )
                    }
                    onClick={() => {
                      likeDislike(id, false);
                      setVisible(false);
                    }}
                  />
                </Row>
              </Modal>
              {article?.status === 'SENT' && (
                <>
                  {hasAccess('duplicate') && (
                    <Popconfirm
                      title={t('datatable.column.action.duplicate.title')}
                      okText={t('datatable.column.action.duplicate.ok')}
                      okButtonProps={{ type: 'danger' }}
                      cancelText={t('datatable.column.action.duplicate.cancel')}
                      onConfirm={duplicateResource}
                      icon={<WarningOutlined />}
                    >
                      <Button type="primary" icon={<CopyOutlined size={24} />}>
                        {t('news.buttons.mail')}
                      </Button>
                    </Popconfirm>
                  )}
                  <Button
                    type="primary"
                    icon={<LikeOutlined size={24} />}
                    onClick={() => setVisible(true)}
                  >
                    {t('news.buttons.rate')}
                  </Button>
                  <Button
                    type="primary"
                    icon={<EyeOutlined size={24} />}
                    onClick={() => {
                      unsee(id);
                      navigate(-1);
                    }}
                  >
                    {t('news.buttons.unsee')}
                  </Button>
                </>
              )}
              {hasAccess('archive') && (
                <Popconfirm
                  title={t('datatable.column.action.delete.title')}
                  okText={t('datatable.column.action.delete.ok')}
                  okButtonProps={{ type: 'danger' }}
                  cancelText={t('datatable.column.action.delete.cancel')}
                  onConfirm={() => {
                    changeStatus(id, 'ARCHIVED');
                    navigate(-1);
                  }}
                  icon={<WarningOutlined />}
                >
                  <Button
                    type="link"
                    danger
                    icon={<ContainerOutlined size={24} />}
                  >
                    {t('news.buttons.archive')}
                  </Button>
                </Popconfirm>
              )}
            </>
          }
        />

        <Row gutter={[24, 24]}>
          <Col span={24}>
            <Card>
              <DescriptionList data={listContent} translate columns={24} />
            </Card>
          </Col>
          <Col span={24}>
            <Card title={t('news.labels.description')}>
              <Typography.Text>
                {formattedContent(article?.description)}
              </Typography.Text>
            </Card>
          </Col>
          <Col xs={24} lg={12}>
            <Card title={t('news.labels.history')}>
              <Typography.Text>
                {generateHistoric(article?.historic, t)}
              </Typography.Text>
            </Card>
          </Col>
          <Col xs={24} lg={12}>
            <FilesManagement
              purpose="show"
              documents={article?.file || []}
              forceRefresh={refresh}
              border
            />
          </Col>
          {article?.status === 'SENT' && hasAccess('stats') && (
            <>
              <Col xs={24} lg={12}>
                <Card title={t('news.labels.unread')}>
                  <Table
                    dataSource={users.filter(
                      (use) => !article?.read_by?.includes(use._id)
                    )}
                    columns={columns}
                    scroll={{ x: true }}
                  />
                </Card>
              </Col>
              <Col xs={24} lg={12}>
                <Card title={t('news.labels.rating')}>
                  <Pie {...config} />
                </Card>
              </Col>
            </>
          )}
        </Row>
      </ContentCustom>
    );

  return content;
};

export default ShowArticle;
