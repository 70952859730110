export const headers = [
  {
    label: 'status',
    key: 'status'
  },
  {
    label: 'legal_name',
    key: 'legal_name'
  },
  {
    label: 'company',
    key: 'company'
  },
  {
    label: 'ps_number',
    key: 'ps_number'
  },
  {
    label: 'company_name',
    key: 'company_name'
  },
  {
    label: 'macro_ps',
    key: 'macro_ps.title'
  },
  {
    label: 'micro_ps',
    key: 'micro_ps.title'
  },
  {
    label: 'leader',
    key: 'leader.first_name'
  },
  {
    label: 'leader',
    key: 'leader.last_name'
  },
  {
    label: 'address.postal_code',
    key: 'address.postal_code'
  },
  {
    label: 'address.number',
    key: 'address.number'
  },
  {
    label: 'address.street',
    key: 'address.street'
  },
  {
    label: 'address.city',
    key: 'address.city'
  },
  {
    label: 'date_of_birth',
    key: 'date_of_birth'
  },
  {
    label: 'used_software',
    key: 'used_software.title'
  },
  {
    label: 'business_introducer',
    key: 'business_introducer.title'
  },
  {
    label: 'email',
    key: 'email'
  },
  {
    label: 'phone_number.country_code',
    key: 'phone_number.country_code'
  },
  {
    label: 'phone_number.number',
    key: 'phone_number.number'
  },
  {
    label: 'type_ps_id',
    key: 'type_ps_id'
  },
  {
    label: 'billing_address_is_different',
    key: 'billing_address_is_different'
  },
  {
    label: 'company_mail',
    key: 'company_mail'
  },
  {
    label: 'social_reason',
    key: 'social_reason'
  },
  {
    label: 'contacts.gender',
    key: 'contacts.gender'
  },
  {
    label: 'contacts.employment_role',
    key: 'contacts.employment_role'
  },
  {
    label: 'contacts.first_name',
    key: 'contacts.first_name'
  },
  {
    label: 'contacts.last_name',
    key: 'contacts.last_name'
  },
  {
    label: 'contacts.email',
    key: 'contacts.email'
  },
  {
    label: 'installation_date',
    key: 'installation_date'
  },
  {
    label: 'optimization_date',
    key: 'optimization_date'
  },
  {
    label: 'financial_statement',
    key: 'financial_statement'
  },
  {
    label: 'sales',
    key: 'sales.first_name'
  },
  {
    label: 'sales',
    key: 'sales.last_name'
  },
  {
    label: 'accountant',
    key: 'accountant'
  },
  {
    label: 'accountant_mail',
    key: 'accountant_mail'
  },
  {
    label: 'siret_number',
    key: 'siret_number'
  },
  {
    label: 'start_activity_date',
    key: 'start_activity_date'
  },
  {
    label: 'generic_product_producer',
    key: 'generic_product_producer'
  },
  {
    label: 'distributor',
    key: 'distributor'
  },
  {
    label: 'invoice_per_month',
    key: 'invoice_per_month'
  },
  {
    label: 'third_party_payment_turnover',
    key: 'third_party_payment_turnover'
  },
  {
    label: 'rib_tp',
    key: 'rib_tp'
  },
  {
    label: 'iban_tp',
    key: 'iban_tp'
  },
  {
    label: 'courrier',
    key: 'courrier'
  },
  {
    label: 'on_site_billing',
    key: 'on_site_billing'
  },
  {
    label: 'min_amount_customer',
    key: 'min_amount_customer'
  },
  {
    label: 'max_amount_customer',
    key: 'max_amount_customer'
  },
  {
    label: 'customer_comments.title',
    key: 'customer_comments.title'
  },
  {
    label: 'customer_comments.date',
    key: 'customer_comments.date'
  },
  {
    label: 'overPayment',
    key: 'overPayment'
  },
  {
    label: 'websites',
    key: 'websites'
  },
  {
    label: 'link_to_opportunity',
    key: 'link_to_opportunity'
  },
  {
    label: 'prospect_comment',
    key: 'prospect_comment'
  },
  {
    label: 'user_id',
    key: 'user_id'
  },
  {
    label: 'scor',
    key: 'scor'
  },
  {
    label: 'vital_sesam',
    key: 'vital_sesam'
  },
  {
    label: 'pro_card_type',
    key: 'pro_card_type'
  },
  {
    label: 'pro_card_code',
    key: 'pro_card_code'
  },
  {
    label: 'pro_card_number',
    key: 'pro_card_number'
  },
  {
    label: 'rejection_rate_entry',
    key: 'rejection_rate_entry'
  },
  {
    label: 'input_in_progress',
    key: 'input_in_progress'
  },
  {
    label: 'monthly_reconciliation_statement',
    key: 'monthly_reconciliation_statement'
  },
  {
    label: 'ps_security_code',
    key: 'ps_security_code'
  },
  {
    label: 'connection_period',
    key: 'connection_period'
  },
  {
    label: 'insured_contact_type',
    key: 'insured_contact_type'
  },
  {
    label: 'electronic_invoice',
    key: 'electronic_invoice'
  },
  {
    label: 'email_invoice',
    key: 'email_invoice'
  },
  {
    label: 'billing_address.postal_code',
    key: 'billing_address.postal_code'
  },
  {
    label: 'billing_address.number',
    key: 'billing_address.number'
  },
  {
    label: 'billing_address.street',
    key: 'billing_address.street'
  },
  {
    label: 'billing_address.city',
    key: 'billing_address.city'
  },
  {
    label: 'legal_form',
    key: 'legal_form'
  },
  {
    label: 'vat',
    key: 'vat'
  }
];
