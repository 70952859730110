import { useTranslation } from 'react-i18next';
import { Col, Flex, Row, Select } from 'antd';
import dayjs from 'dayjs';
import StatisticsContextProvider from './StatisticsContext';

const { Option } = Select;

/**
 * Component for displaying statistics filters.
 * @module StatisticsFilters
 * @returns {JSX.Element} React component
 */

const StatisticsFilters = () => {
  const { t } = useTranslation();
  const {
    indexTab,
    isFiltersLoading,
    selectedYear,
    setSelectedYear,
    gtps,
    setSelectedGtp,
    selectedGtp,
    leaders,
    setSelectedLeader,
    selectedLeader,
    setSelectedService,
    selectedService,
    psMicros,
    setSelectedPsMicro,
    selectedPsMicro,
    psMacros,
    setSelectedPsMacro,
    selectedPsMacro,
    softwares,
    setSelectedSoftware,
    selectedSoftware,
    setSelectedType,
    selectedType,
    forceRefresh,
    setForceRefresh,
    chartDatas
  } = StatisticsContextProvider();

  const onSearch = (input, option) =>
    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;

  const generateYearOptions = () => {
    const currentYear = dayjs().year();
    const years = Array.from({ length: 11 }, (_, i) => currentYear - i);
    return years.map((year) => (
      <Option key={year} value={String(year)}>
        {year}
      </Option>
    ));
  };

  return (
    <Row gutter={[12, 12]} style={{ marginBottom: 16 }}>
      <Col xs={24} md={18}>
        {indexTab && (
          <Flex wrap gap={12}>
            {indexTab === '2' && (
              <Select
                style={{ width: 250 }}
                showSearch
                loading={isFiltersLoading}
                placeholder={t('statistics.placeholder.leader')}
                filterOption={(input, option) => onSearch(input, option)}
                value={selectedLeader}
                onSelect={(value) => {
                  setSelectedLeader(value);
                  setForceRefresh(!forceRefresh);
                }}
              >
                {(leaders || []).map((leader) => (
                  <Option key={leader?._id} value={leader?._id}>
                    {`${leader?.first_name} ${
                      leader?.usage_last_name || leader?.last_name
                    }`}
                  </Option>
                ))}
              </Select>
            )}
            {indexTab === '1' && (
              <Select
                style={{ width: 250 }}
                showSearch
                loading={isFiltersLoading}
                placeholder={t('statistics.placeholder.gtp')}
                filterOption={(input, option) => onSearch(input, option)}
                value={selectedGtp}
                onSelect={(value) => {
                  setSelectedGtp(value);
                  setForceRefresh(!forceRefresh);
                }}
              >
                {(gtps || []).map((gtp) => (
                  <Option key={gtp?._id} value={gtp?._id}>
                    {`${gtp?.first_name} ${
                      gtp?.usage_last_name || gtp?.last_name
                    }`}
                  </Option>
                ))}
              </Select>
            )}
            {(indexTab === '3' || indexTab === '4') && (
              <Select
                allowClear
                style={{ width: 250 }}
                showSearch
                mode="multiple"
                loading={isFiltersLoading}
                placeholder={t('statistics.placeholder.ps_macro')}
                filterOption={(input, option) => onSearch(input, option)}
                value={selectedPsMacro}
                onSelect={(value) => {
                  setSelectedPsMacro([...selectedPsMacro, value]);
                  setForceRefresh(!forceRefresh);
                }}
                onDeselect={(value) => {
                  setSelectedPsMacro((prevStatus) =>
                    prevStatus.filter((item) => item !== value)
                  );
                  setForceRefresh(!forceRefresh);
                }}
                onClear={() => {
                  setSelectedPsMacro([]);
                  setForceRefresh(!forceRefresh);
                }}
              >
                {(psMacros || []).map((macro) => (
                  <Option key={macro?._id} value={macro?._id}>
                    {macro.title}
                  </Option>
                ))}
              </Select>
            )}
            {indexTab === '3' && (
              <Select
                allowClear
                style={{ width: 250 }}
                showSearch
                mode="multiple"
                loading={isFiltersLoading}
                placeholder={t('statistics.placeholder.ps_micro')}
                filterOption={(input, option) => onSearch(input, option)}
                value={selectedPsMicro}
                onSelect={(value) => {
                  setSelectedPsMicro(
                    selectedPsMicro?.length > 0
                      ? [...selectedPsMicro, value]
                      : [value]
                  );
                  setForceRefresh(!forceRefresh);
                }}
                onDeselect={(value) => {
                  setSelectedPsMicro((prevStatus) =>
                    prevStatus.filter((item) => item !== value)
                  );
                  setForceRefresh(!forceRefresh);
                }}
                onClear={() => {
                  setSelectedPsMicro([]);
                  setForceRefresh(!forceRefresh);
                }}
              >
                {(psMicros || []).map((micro) => (
                  <Option key={micro?._id} value={micro?._id}>
                    {micro.title}
                  </Option>
                ))}
              </Select>
            )}
            {indexTab === '4' && (
              <Select
                allowClear
                style={{ width: 250 }}
                showSearch
                mode="multiple"
                loading={isFiltersLoading}
                placeholder={t('statistics.placeholder.software')}
                filterOption={(input, option) => onSearch(input, option)}
                value={selectedSoftware}
                onSelect={(value) => {
                  setSelectedSoftware(
                    selectedSoftware?.length > 0
                      ? [...selectedSoftware, value]
                      : [value]
                  );
                  setForceRefresh(!forceRefresh);
                }}
                onDeselect={(value) => {
                  setSelectedSoftware((prevStatus) =>
                    prevStatus.filter((item) => item !== value)
                  );
                  setForceRefresh(!forceRefresh);
                }}
                onClear={() => {
                  setSelectedSoftware([]);
                  setForceRefresh(!forceRefresh);
                }}
              >
                {(softwares || []).map((software) => (
                  <Option key={software.title} value={software?._id}>
                    {software.title}
                  </Option>
                ))}
              </Select>
            )}
            {(indexTab === '1' || indexTab === '2') && (
              <Select
                style={{ width: 250 }}
                showSearch
                loading={isFiltersLoading}
                placeholder={t('statistics.placeholder.macro')}
                filterOption={(input, option) => onSearch(input, option)}
                value={selectedType}
                onSelect={(value) => {
                  setSelectedType(value);
                  setForceRefresh(!forceRefresh);
                }}
              >
                {['Macro', 'Micro'].map((bool) => (
                  <Option key={bool} value={bool}>
                    {bool}
                  </Option>
                ))}
              </Select>
            )}
            <Select
              allowClear
              style={{ width: 250 }}
              showSearch
              mode="multiple"
              loading={isFiltersLoading}
              placeholder={t('statistics.placeholder.service')}
              filterOption={(input, option) => onSearch(input, option)}
              value={selectedService}
              onSelect={(value) => {
                setSelectedService(
                  selectedService?.length > 0
                    ? [...selectedService, value]
                    : [value]
                );
                setForceRefresh(!forceRefresh);
              }}
              onDeselect={(value) => {
                setSelectedService((prevStatus) =>
                  prevStatus.filter((item) => item !== value)
                );
                setForceRefresh(!forceRefresh);
              }}
              onClear={() => {
                setSelectedService([]);
                setForceRefresh(!forceRefresh);
              }}
            >
              {(chartDatas?.filteredServices || []).map((service) => (
                <Option key={service} value={service}>
                  {t(`services.form.enums.${service}`)}
                </Option>
              ))}
            </Select>
          </Flex>
        )}
      </Col>
      <Col xs={24} md={6}>
        <Select
          placeholder={t('calendar.placeholder.psMicro')}
          onSelect={(value) => {
            setSelectedYear(value);
            setForceRefresh(!forceRefresh);
          }}
          defaultValue={selectedYear}
          style={{ width: 180, float: 'right' }}
        >
          {generateYearOptions()}
        </Select>
      </Col>
    </Row>
  );
};
export default StatisticsFilters;
