import React, { useState, useEffect } from 'react';
import { Col, Form, Input, Select, Checkbox, Card } from 'antd';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { ContentCustom } from '../../../components';
import { useAuthContext } from '../../../contexts/AuthContext';
import { useErrorMessage } from '../../../utils/errorMessage';

const { Option } = Select;

const FormInfo = ({
  status,
  configurations,
  setPickedConfiguration,
  setPickedConfigurationMailTypes,
  formValues
}) => {
  const { t } = useTranslation();
  const { dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();
  const [macroPs, setMacroPs] = useState();

  const getMacroPs = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/Psmacro?sort=title`
      });
      setMacroPs(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  useEffect(() => {
    getMacroPs();
  }, []);

  const handleUseCaseChange = (useCase) => {
    const selectedConfig = configurations.find(
      (config) => config.use_case === useCase
    );

    if (setPickedConfiguration) {
      setPickedConfiguration(selectedConfig?.use_case);
      setPickedConfigurationMailTypes(null);
    }
  };

  const handleMailTypesChange = (mailTypes) => {
    setPickedConfigurationMailTypes(mailTypes);
  };

  return (
    <ContentCustom>
      <Col span={24}>
        <Card title={t('template-documents.form.formInfoTitle')}>
          <Form.Item label={t(`template-documents.form.title`)} name="title">
            <Input />
          </Form.Item>

          <Form.Item label={t(`template-documents.form.status`)} name="status">
            <Select>
              {(status || []).map((item) => (
                <Option key={item} value={item}>
                  {t(`template-documents.form.${item}`)}
                </Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item
            label={t(`template-documents.form.useCase`)}
            name="configuration"
          >
            <Select onChange={handleUseCaseChange}>
              {(configurations || []).map((item) => (
                <Option key={item.use_case} value={item.use_case}>
                  {t(`template-documents.form.${item.use_case}`)}
                </Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item
            label={t(`template-documents.form.mailTypes`)}
            name="mailTypes"
          >
            <Select onChange={handleMailTypesChange}>
              {(configurations || [])
                .find((config) => config.use_case === formValues?.configuration)
                ?.mailTypes?.map((mailType) => (
                  <Option key={mailType} value={mailType}>
                    {t(`template-documents.form.${mailType}`)}
                  </Option>
                ))}
            </Select>
          </Form.Item>

          <Form.Item
            label={t(`template-documents.form.macro_ps`)}
            name="macro_ps"
          >
            <Select allowClear mode="multiple">
              {Array.isArray(macroPs) &&
                (macroPs || []).map((item) => (
                  <Option key={item._id} value={item._id}>
                    {item.title}
                  </Option>
                ))}
            </Select>
          </Form.Item>

          <Form.Item
            label={t(`template-documents.form.header`)}
            name="header"
            valuePropName="checked"
          >
            <Checkbox />
          </Form.Item>
          {formValues.header && (
            <Form.Item
              label={t(`template-documents.form.header_size`)}
              name="header_size"
            >
              <Input type="number" suffix="px" />
            </Form.Item>
          )}
          <Form.Item
            label={t(`template-documents.form.footer`)}
            name="footer"
            valuePropName="checked"
          >
            <Checkbox />
          </Form.Item>
          {formValues.footer && (
            <Form.Item
              label={t(`template-documents.form.footer_size`)}
              name="footer_size"
            >
              <Input type="number" suffix="px" />
            </Form.Item>
          )}
        </Card>
      </Col>
    </ContentCustom>
  );
};

FormInfo.propTypes = {
  status: PropTypes.arrayOf(PropTypes.string).isRequired,
  configurations: PropTypes.arrayOf(
    PropTypes.shape({
      use_case: PropTypes.string.isRequired,
      collections: PropTypes.arrayOf(PropTypes.string).isRequired,
      mailTypes: PropTypes.arrayOf(PropTypes.string).isRequired
    })
  ).isRequired,
  formValues: PropTypes.shape({
    header: PropTypes.bool,
    footer: PropTypes.bool,
    configuration: PropTypes.string // Add configuration to keep track of the selected use_case
  }),
  setPickedConfiguration: PropTypes.func,
  setPickedConfigurationMailTypes: PropTypes.func
};

FormInfo.defaultProps = {
  formValues: null,
  setPickedConfiguration: null,
  setPickedConfigurationMailTypes: null
};

export default FormInfo;
