import { Tag, Button, Popconfirm, Col, Divider, Row } from 'antd';
import {
  EditOutlined,
  ContainerOutlined,
  WarningOutlined
} from '@ant-design/icons';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { actionStatus } from '../../../utils/constants/tagColors';
import { formatValue } from '../../../utils/formatNumber';

/**
 * Custom hook for generating table columns for displaying actions reminder data.
 *
 * @hook
 * @param {object} actionEnums - Enumerations data for actions reminder properties
 * @returns {Array} Array of table column objects
 */

export const useColumns = (
  actionEnums,
  rejectionReasons,
  options,
  hasAccess
) => {
  const { t } = useTranslation();

  return [
    {
      title: t('interventions.show.actions_reminder.columns.status'),
      key: 'action_reminder',
      dataIndex: ['action_reminder'],
      render: (action_reminder) => (
        <Tag
          color={
            actionStatus[
              dayjs(action_reminder).isBefore(dayjs()) ||
              dayjs(action_reminder).isSame(dayjs())
                ? 'URGENT'
                : 'UPCOMING'
            ]
          }
        >
          {t(
            `interventions.show.actions_reminder.enums.${
              dayjs(action_reminder).isBefore(dayjs()) ||
              dayjs(action_reminder).isSame(dayjs())
                ? 'URGENT'
                : 'UPCOMING'
            }`
          )}
        </Tag>
      ),
      sorter: true,
      filters: ['URGENT', 'UPCOMING'].map((s) => ({
        text: t(`interventions.show.actions_reminder.enums.${s}`),
        value: s
      }))
    },
    {
      title: t('interventions.show.actions_reminder.columns.fse_number'),
      key: 'fse_number',
      dataIndex: 'fse_number',
      sorter: true,
      render: (fse_number, data) => fse_number || data?.task_number?.title
    },
    {
      title: t('interventions.show.actions_reminder.columns.created_at'),
      key: 'created_at',
      dataIndex: 'created_at',
      render: (created_at) => formatValue(created_at, 'date'),
      sorter: true
    },
    {
      title: t('interventions.show.actions_reminder.columns.created_by'),
      key: 'created_by',
      dataIndex: 'created_by',
      sorter: true,
      render: (created_by) =>
        `${created_by?.first_name} ${
          created_by?.usage_last_name || created_by?.last_name
        }`
    },
    {
      title: t('interventions.show.actions_reminder.columns.action_reminder'),
      key: 'action_reminder',
      dataIndex: 'action_reminder',
      render: (action_reminder) => formatValue(action_reminder, 'date'),
      sorter: true
    },
    {
      title: t('interventions.show.actions_reminder.columns.part'),
      key: 'part',
      dataIndex: 'part',
      render: (part) =>
        part ? t(`interventions.show.actions_reminder.enums.${part}`) : '-',
      sorter: true,
      filters: actionEnums?.parts?.map((p) => ({
        text: t(`interventions.show.actions_reminder.enums.${p}`),
        value: p
      }))
    },
    {
      title: t('interventions.show.actions_reminder.columns.amount'),
      key: 'amount',
      dataIndex: 'amount',
      render: (amount) => (amount ? `${formatValue(amount, 'currency')}` : '-'),
      sorter: true
    },
    {
      title: t('interventions.show.actions_reminder.columns.rejection_reason'),
      key: 'rejection_reason',
      dataIndex: 'rejection_reason',
      render: (rejection_reason) => rejection_reason?.title,
      sorter: true,
      filters: rejectionReasons?.map((rejectionReason) => ({
        text: rejectionReason?.title,
        value: rejectionReason._id
      }))
    },
    {
      title: t('interventions.show.actions_reminder.columns.comment'),
      key: 'comment',
      dataIndex: 'comment'
    },
    {
      key: 'action',
      align: 'right',
      fixed: 'right',
      render: (record) => (
        <Row
          wrap={false}
          align="middle"
          gutter={12}
          justify="end"
          style={{ width: 90 }}
        >
          <Col span={12}>
            {hasAccess('edit') ? (
              <Button
                type="ghost"
                onClick={() => options.onEdit(record._id, true)}
                className="no-padding"
              >
                <EditOutlined style={{ fontSize: 18 }} />
              </Button>
            ) : (
              <EditOutlined style={{ fontSize: 18, visibility: 'hidden' }} />
            )}
          </Col>
          <Divider type="vertical" />
          <Col span={12}>
            {hasAccess('archive') ? (
              <Popconfirm
                title={t('datatable.column.action.delete.title')}
                okText={t('datatable.column.action.delete.ok')}
                okButtonProps={{ type: 'danger' }}
                cancelText={t('datatable.column.action.delete.cancel')}
                onConfirm={() => options.onDelete(record._id)}
                icon={<WarningOutlined />}
              >
                <ContainerOutlined
                  style={{
                    fontSize: 18,
                    color: 'var(--errorColor)',
                    float: 'left'
                  }}
                  type="delete"
                />
              </Popconfirm>
            ) : (
              <ContainerOutlined
                style={{
                  fontSize: 18,
                  color: 'var(--errorColor)',
                  visibility: 'hidden'
                }}
                type="delete"
              />
            )}
          </Col>
        </Row>
      )
    }
  ];
};
