import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { DocEditor } from './DocEditor/DocEditor';
import { Exception } from '../../components';
import { ShowTemplate } from './ShowTemplate';
import ListTemplateDocs from './ListTemplateDocs';

/**
 * `TemplateDocRouter` is a component that defines a set of routes for handling
 * document templates within a React application. It leverages the `Routes` and
 * `Route` components from `react-router-dom` to define various paths and their
 * corresponding components.
 *
 * @component
 * @returns {React.Element} A set of routes to handle document template-related operations such as creation, editing, viewing, and listing.
 * @example
 *
 * return (
 *   <TemplateDocRouter />
 * )
 */
export const TemplateDocRouter = () => (
  <Routes>
    <Route index element={<ListTemplateDocs />} />
    <Route path="/create" element={<DocEditor purpose="create" />} />
    <Route path="/edit/:id" element={<DocEditor purpose="edit" />} />
    <Route path="/show/:id" element={<ShowTemplate />} />
    <Route path="*" element={<Exception />} />
  </Routes>
);
