export const headers = [
  {
    label: 'status',
    key: 'status'
  },
  {
    label: 'company',
    key: 'company'
  },
  {
    label: 'code',
    key: 'code'
  },
  {
    label: 'ps_number',
    key: 'ps_number'
  },
  {
    label: 'company_name',
    key: 'customer.company_name'
  },
  {
    label: 'macro_ps',
    key: 'macro_ps.title'
  },
  {
    label: 'micro_ps',
    key: 'micro_ps.title'
  },
  {
    label: 'total',
    key: 'total'
  },
  {
    label: 'total_with_taxes',
    key: 'total_with_taxes'
  },
  {
    label: 'transfer_date',
    key: 'transfer_date'
  },
  {
    label: 'payment_choice',
    key: 'payment_choice'
  }
];
