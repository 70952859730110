import { useTranslation } from 'react-i18next';
import { formatValue } from '../../../utils/formatNumber';

/**
 * useAdministrationContent is a custom hook that generates an array of content configurations for displaying administrative customer data.
 *
 * @hook
 * @param {Object} data - Customer data object containing administrative information.
 * @returns {Array<Object>} An array of content configurations for displaying administrative customer data.
 */
export const useAdministrationContent = (data) => {
  const { t } = useTranslation();
  const {
    date_of_birth,
    installation_date,
    optimization_date,
    business_introducer,
    business_introducer_id,
    sales,
    leader,
    financial_statement,
    accountant,
    accountant_mail,
    siret_number,
    iban_tp,
    rib_tp,
    start_activity_date,
    generic_product_producer,
    distributor,
    invoice_per_month,
    third_party_payment_turnover,
    global_turnover,
    third_party_payment_percent,
    target_rejection_rate,
    outstanding_balance_target,
    initial_balance,
    ongoing_startup_phase,
    start_of_balance_tracking
  } = data;
  return [
    {
      label: 'customers.form.date_of_birth',
      span: 5,
      content: formatValue(date_of_birth, 'date') || '-'
    },
    {
      label: 'customers.form.installation_date',
      span: 5,
      content: formatValue(installation_date, 'date') || '-'
    },
    {
      label: 'customers.form.optimization_date',
      span: 5,
      content: formatValue(optimization_date, 'date') || '-'
    },
    {
      label: 'customers.form.business_introducer',
      span: 5,
      content: business_introducer?.title || '-'
    },
    {
      label: 'customers.form.business_introducer_id',
      span: 5,
      content: business_introducer_id || '-'
    },
    {
      label: 'customers.form.sales',
      span: 5,
      content:
        `${sales?.first_name || ''} ${
          sales?.usage_last_name || sales?.last_name || ''
        }` || '-'
    },
    {
      label: 'customers.form.leader',
      span: 5,
      content:
        `${leader?.first_name || ''} ${
          leader?.usage_last_name || leader?.last_name || ''
        }` || '-'
    },
    {
      label: 'customers.form.financial_statement',
      span: 5,
      content: `${financial_statement?.day ? financial_statement?.day : ''} ${
        financial_statement?.month
          ? t(`reconciliationStatements.months.${financial_statement?.month}`)
          : ''
      }`
    },
    {
      label: 'customers.form.accountant',
      span: 5,
      content: accountant || '-'
    },
    {
      label: 'customers.form.accountant_mail',
      span: 5,
      content: accountant_mail || '-'
    },
    {
      label: 'customers.form.siret_number',
      span: 5,
      content: formatValue(siret_number, 'siret') || '-'
    },
    {
      label: 'customers.form.iban',
      span: 10,
      content: formatValue(iban_tp, 'iban') || '-'
    },
    {
      label: 'customers.form.rib',
      span: 10,
      content: formatValue(rib_tp, 'rib') || '-'
    },
    {
      label: 'customers.form.start_activity_date',
      span: 5,
      content: formatValue(start_activity_date, 'date') || '-'
    },
    {
      label: 'customers.form.generic_product_producer',
      span: 5,
      content: generic_product_producer || '-'
    },
    {
      label: 'customers.form.distributor',
      span: 5,
      content: distributor || '-'
    },
    {
      label: 'customers.form.invoice_per_month',
      span: 5,
      content: invoice_per_month || '-'
    },
    {
      label: 'customers.form.third_party_payment_turnover',
      span: 5,
      content: third_party_payment_turnover
        ? `${formatValue(third_party_payment_turnover, 'currency')}`
        : '-'
    },
    {
      label: 'customers.form.global_turnover',
      span: 5,
      content: global_turnover
        ? `${formatValue(global_turnover, 'currency')}`
        : '-'
    },
    {
      label: 'customers.form.third_party_payment_percent',
      span: 5,
      content: third_party_payment_percent || '-'
    },
    {
      label: 'customers.form.target_rejection_rate',
      span: 5,
      content: target_rejection_rate || '-'
    },
    {
      label: 'customers.form.outstanding_balance_target',
      span: 5,
      content: outstanding_balance_target
        ? `${formatValue(outstanding_balance_target, 'currency')}`
        : '-'
    },
    {
      label: 'customers.form.initial_balance',
      span: 5,
      content: formatValue(initial_balance, 'currency') || '-'
    },
    {
      label: 'customers.form.ongoing_startup_phase',
      span: 5,
      content:
        ongoing_startup_phase && ongoing_startup_phase.length > 0
          ? ongoing_startup_phase
              .map((date) => formatValue(date, 'date'))
              .join(' - ')
          : '-'
    },
    {
      label: 'customers.form.start_of_balance_tracking',
      span: 5,
      content: formatValue(start_of_balance_tracking, 'date') || '-'
    }
  ];
};
