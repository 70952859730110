import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { Card, Empty, Flex } from 'antd';
import { useTranslation } from 'react-i18next';
import { XAxis, YAxis, Tooltip, BarChart, Bar } from 'recharts';
import { useAuthContext } from '../../../contexts/AuthContext';
import { useErrorMessage } from '../../../utils/errorMessage';

/**
 * Card to display the global revenue of the company of year N and year N-1
 *
 * @component
 * @param {number} year - The year to display the global revenue
 * @returns {JSX.Element} - The card to display the global revenue
 */
export const GlobalRevenueCard = ({ year }) => {
  const { t } = useTranslation();
  const { message } = useErrorMessage();
  const { dispatchAPI } = useAuthContext();
  const [revenue, setRevenue] = useState([]);

  const getRevenue = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/quittances/globalRevenue?year=${year}`
      });
      setRevenue(data);
    } catch (error) {
      message(error);
    }
  };

  useEffect(() => {
    (async () => {
      await getRevenue();
    })();
  }, [year]);

  return (
    <Card title={t('home.cards.titles.global_revenue')}>
      <Flex justify="center" style={{ marginTop: 16 }}>
        {revenue.length && revenue.find((r) => r.revenue) ? (
          <BarChart width={400} height={300} data={revenue}>
            <XAxis dataKey="year" />
            <YAxis />
            <Tooltip formatter={(value) => [`${value.toFixed(2)}€`, 'CA']} />
            <Bar dataKey="revenue" fill="var(--primaryColor)" />
          </BarChart>
        ) : (
          <Empty style={{ margin: '24px auto' }} />
        )}
      </Flex>
    </Card>
  );
};

GlobalRevenueCard.propTypes = {
  year: PropTypes.string
};

GlobalRevenueCard.defaultProps = {
  year: new Date().getFullYear()
};
