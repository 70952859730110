import { useEffect, useState } from 'react';
import { useParams, Link, useNavigate, useLocation } from 'react-router-dom';
import { Button, Flex, Tabs, Typography, Tag, Popconfirm } from 'antd';
import { useTranslation } from 'react-i18next';
import {
  CheckOutlined,
  EditOutlined,
  PlusOutlined,
  DeleteOutlined,
  WarningOutlined
} from '@ant-design/icons';
import { useAuthContext } from '../../contexts/AuthContext';
import useCustomerContext from './CustomerContext';
import { ContentCustom, PageHeaderCustom } from '../../components';
import { routes } from '../../utils/constants/adminRoutes';
import { cardRenderer } from './ShowCustomer/CardRenderer';
import {
  useIdentificationContent,
  useContactContent
} from './ShowCustomer/Identification';
import { useAdministrationContent } from './ShowCustomer/Administration';
import { useBillingContent } from './ShowCustomer/billingContent';
import { FilesManagement } from '../../components/FilesManagement';
import { DescriptionList } from '../../components/DescriptionList/DescriptionList';
import { useTechnicalContent } from './ShowCustomer/TechnicalInfos';
import { WebsitesTable } from './ShowCustomer/WebsitesTable';
import { ShowProspect } from './ShowProspect';
import { ContactDrawer } from './ShowCustomer/ContactDrawer';
import { CollaboratorsTable } from './ShowCustomer/CollaboratorsTable';
import { Stats } from './ShowCustomer/Stats';
import { InterventionsRSC } from './ShowCustomer/InterventionsRSC';
import { QuittancesContracts } from './ShowCustomer/QuittancesContracts';
import OverPaymentModal from './ShowCustomer/OverPaymentModal';
import { isAuthorized } from '../../utils/constants/authorizedMenu';
import { isEmptyObject } from '../../utils/objects';

/**
 * ShowCustomer is a React component that displays detailed information about a customer.
 *
 * @component
 * @returns {React.ReactNode} The rendered component displaying customer information.
 */
export const ShowCustomer = () => {
  const { id } = useParams();
  const { t } = useTranslation();
  const {
    changeStatus,
    refresh,
    setRefresh,
    getCustomer,
    customer,
    enums,
    changeContactsStatus,
    patchOverPayment,
    isOverPaymentModalOpen,
    setIsOverPaymentModalOpen,
    overPayment,
    setOverPayment
  } = useCustomerContext();
  const { user } = useAuthContext();
  const location = useLocation();
  const { pathname } = location;
  const [isLoading, setIsLoading] = useState(true);
  const [isDrawerVisible, setIsDrawerVisible] = useState(false);
  const [purpose, setPurpose] = useState('create');
  const [itemId, setItemId] = useState(null);
  const [currentTab, setCurrentTab] = useState('1');

  const hasAccess = (purp) => isAuthorized(pathname, user?.role, purp);

  const handleArchive = async (contactId) => {
    await changeContactsStatus(contactId, 'INACTIVE');
    setRefresh(!refresh);
  };

  const openOverPaymentModal = () => {
    setOverPayment(customer?.overPayment);
    setIsOverPaymentModalOpen(true);
  };

  const closeOverPaymentModal = (value) => {
    if (value) {
      patchOverPayment(id, value);
    } else {
      setOverPayment(null);
      setIsOverPaymentModalOpen(false);
    }
  };
  const navigate = useNavigate();
  const identificationContent = useIdentificationContent(
    customer || {},
    t || {}
  );
  const administrationContent = useAdministrationContent(customer || {});
  const billingContent = useBillingContent(
    customer || {},
    openOverPaymentModal
  );
  const technicalContent = useTechnicalContent(customer || {});

  const items = [
    {
      label: (
        <Typography.Title
          level={5}
          style={{
            color: 'var(--identificationColor)'
          }}
        >
          {t('customers.form.identification')}
        </Typography.Title>
      ),
      key: '1',
      children: (
        <>
          {isDrawerVisible && (
            <ContactDrawer
              purpose={purpose}
              open={isDrawerVisible}
              onClose={() => {
                setItemId(null);
                setIsDrawerVisible(false);
              }}
              enums={enums}
              id={id}
              itemId={itemId}
            />
          )}
          {cardRenderer(
            <DescriptionList
              data={identificationContent || []}
              translate
              columns={20}
            />,
            'var(--identificationColor)',
            t('customers.form.identification')
          )}
          <br />
          {cardRenderer(
            useContactContent(
              customer?.contacts?.filter(
                (contact) => contact.status === 'ACTIVE'
              ) || [],
              setIsDrawerVisible,
              setPurpose,
              setItemId,
              handleArchive
            ),
            'var(--identificationColor)',
            t('customers.form.contacts')
          )}
        </>
      )
    },
    {
      label: (
        <Typography.Title
          level={5}
          style={{ color: 'var(--administrationColor)' }}
        >
          {t('customers.form.administration')}
        </Typography.Title>
      ),
      key: '2',
      children: (
        <>
          {cardRenderer(
            <DescriptionList
              data={administrationContent || []}
              translate
              columns={20}
            />,
            'var(--administrationColor)',
            t('customers.form.administration')
          )}

          <br />
          {cardRenderer(
            <FilesManagement
              purpose="edit"
              documents={customer?.files || []}
              setForceRefresh={setRefresh}
              forceRefresh={refresh}
              uploadURL="/customers/upload"
              modelKey="files"
              showTitle={false}
            />,
            'var(--administrationColor)',
            t('customers.form.files')
          )}
        </>
      )
    },
    {
      label: (
        <Typography.Title level={5} style={{ color: 'var(--technicalColor)' }}>
          {t('customers.form.technical')}
        </Typography.Title>
      ),
      key: '3',
      children: (
        <>
          {cardRenderer(
            <DescriptionList
              data={technicalContent || []}
              translate
              columns={20}
            />,
            'var(--technicalColor)',
            t('customers.form.technical')
          )}
          <br />
          {hasAccess('comment') &&
            cardRenderer(
              <Typography.Text>
                {customer?.customer_comments?.length
                  ? (customer?.customer_comments || []).map((comment) => (
                      <p key={comment.title}>{comment.title}</p>
                    ))
                  : '-'}
              </Typography.Text>,
              'var(--technicalColor)',
              t('customers.form.customer_comments')
            )}
          <br />
          {cardRenderer(
            <CollaboratorsTable
              enums={enums}
              customer={customer}
              id={id}
              refresh={() => setRefresh(!refresh)}
            />,
            'var(--technicalColor)',
            t('customers.form.collaborators')
          )}
          <br />
          {hasAccess('comment') &&
            cardRenderer(
              <Typography.Text>
                {customer?.prospect_comment || '-'}
              </Typography.Text>,
              'var(--technicalColor)',
              t('customers.form.prospect_comment')
            )}
        </>
      )
    },
    ...(!user?.role?.startsWith('users')
      ? [
          {
            label: (
              <Typography.Title
                level={5}
                style={{ color: 'var(--billingColor)' }}
              >
                {t('customers.form.billing')}
              </Typography.Title>
            ),
            key: '4',
            children: (
              <>
                {cardRenderer(
                  <DescriptionList
                    data={billingContent || []}
                    translate
                    columns={20}
                  />,
                  'var(--billingColor)',
                  t('customers.form.billing')
                )}
                <br />
                {cardRenderer(
                  <QuittancesContracts id={customer?._id} />,
                  'var(--billingColor)',
                  t('customers.form.quittances')
                )}
              </>
            )
          }
        ]
      : []),
    {
      label: (
        <Typography.Title level={5} style={{ color: 'var(--websitesColor)' }}>
          {t('customers.form.websites.title')}
        </Typography.Title>
      ),
      key: '5',
      children: (
        <>
          {cardRenderer(
            <WebsitesTable
              data={customer?.websites || []}
              id={customer?._id}
              refresh={() => setRefresh(!refresh)}
            />,
            'var(--websitesColor)',
            t('customers.form.websites.title')
          )}
        </>
      )
    },
    {
      label: (
        <Typography.Title
          level={5}
          style={{ color: 'var(--interventionColor)' }}
        >
          {t('customers.form.interventions_rsc')}
        </Typography.Title>
      ),
      key: '6',
      children: (
        <>
          {cardRenderer(
            <InterventionsRSC id={customer?._id} user={user} />,
            'var(--interventionColor)'
          )}
        </>
      )
    },
    {
      label: (
        <Typography.Title level={5} style={{ color: 'var(--statsColor)' }}>
          {t('customers.form.stats')}
        </Typography.Title>
      ),
      key: '7',
      children: (
        <>
          {cardRenderer(
            <Stats id={customer?._id} currentTab={currentTab} />,
            'var(--statsColor)',
            t('customers.form.stats')
          )}
        </>
      )
    }
  ];

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      await getCustomer(
        id,
        '?populate=micro_ps,macro_ps,files,used_software,websites,contacts,sales,bank_reconciliation,rib,sales,business_introducer,leader,central_hub'
      );
      setIsLoading(false);
    })();
  }, [id, refresh]);

  return (
    <ContentCustom>
      {isOverPaymentModalOpen && (
        <OverPaymentModal
          isOverPaymentModalOpen={isOverPaymentModalOpen}
          closeOverPaymentModal={closeOverPaymentModal}
          overPayment={overPayment}
        />
      )}
      <PageHeaderCustom
        title={
          <Flex align="center" gap={8}>
            {`${customer?.ps_number || ''} - ${customer?.company_name || ''} `}
            {!isEmptyObject(customer) && (
              <Tag>{t(`customers.status.${customer?.status || ''}`)}</Tag>
            )}
          </Flex>
        }
        extra={
          <>
            {hasAccess('validate') && customer?.status === 'PROSPECT' && (
              <>
                <Link
                  to={{
                    pathname: `${routes.QUOTE}/create`,
                    search: `?ps_number=${customer?.ps_number}`
                  }}
                >
                  <Button type="primary">
                    <PlusOutlined />
                    {t('customers.buttons.new_quote')}
                  </Button>
                </Link>
                <Button
                  type="primary"
                  onClick={() => changeStatus(id, 'TO_TRANSFORM')}
                >
                  <CheckOutlined />
                  {t('customers.buttons.to_transform')}
                </Button>
              </>
            )}
            {hasAccess('validate') && customer?.status === 'TO_TRANSFORM' && (
              <Button type="primary" onClick={() => changeStatus(id, 'ACTIVE')}>
                <CheckOutlined />
                {t('customers.buttons.client')}
              </Button>
            )}
            {hasAccess('edit') &&
              customer?.status !== 'ARCHIVED' &&
              (user?.role !== 'users:SALES-USER' ||
                customer?.status === 'PROSPECT') && (
                <Link to={{ pathname: `${routes.CUSTOMERS}/edit/${id}` }}>
                  <Button type="primary" disabled={isEmptyObject(customer)}>
                    <EditOutlined />
                    {`${t('buttons.edit')} `}
                  </Button>
                </Link>
              )}
            {hasAccess('archive') && customer?.status !== 'ARCHIVED' && (
              <Popconfirm
                title={t('datatable.column.action.delete.title')}
                okText={t('datatable.column.action.delete.ok')}
                okButtonProps={{ type: 'danger' }}
                cancelText={t('datatable.column.action.delete.cancel')}
                onConfirm={() => {
                  changeStatus(id, 'ARCHIVED');
                  navigate(-1);
                }}
                icon={<WarningOutlined />}
              >
                <Button type="link" danger disabled={isEmptyObject(customer)}>
                  <DeleteOutlined />
                  {t('buttons.archive')}
                </Button>
              </Popconfirm>
            )}
          </>
        }
      />

      {!isLoading &&
        ['ACTIVE', 'TO_TRANSFORM', 'ARCHIVED', 'NON_PS_CUSTOMER'].includes(
          customer?.status
        ) && (
          <Tabs
            id="tabs-customers-show"
            className="color-tabs"
            items={items}
            onChange={setCurrentTab}
            activeKey={currentTab}
          />
        )}
      {!isLoading && customer?.status === 'PROSPECT' && <ShowProspect />}
    </ContentCustom>
  );
};
