import { createContext, useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import { useAuthContext } from '../../contexts/AuthContext';
import { useErrorMessage } from '../../utils/errorMessage';
import { initFiltersByTab } from './utils';

const StatisticsContext = createContext({});

/**
 * A context provider component for managing statistics data and actions.
 *
 * @context
 * @param {Object} props - Component props
 * @param {ReactNode} props.children - The child components to be wrapped by the context provider
 * @returns {ReactNode} The rendered component
 */
export const StatisticsContextProvider = ({ children }) => {
  const { dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();
  const [isChartsLoading, setIsChartsLoading] = useState(false);
  const [isFiltersLoading, setIsFiltersLoading] = useState(false);
  const [indexTab, setIndexTab] = useState('1');
  const [selectedYear, setSelectedYear] = useState(dayjs().year());
  const [leaders, setLeaders] = useState(null);
  const [selectedLeader, setSelectedLeader] = useState(null);
  const [gtps, setGtps] = useState(null);
  const [selectedGtp, setSelectedGtp] = useState(null);
  const [allMicroPs, setAllMicroPs] = useState(null);
  const [psMicros, setPsMicros] = useState(null);
  const [selectedPsMicro, setSelectedPsMicro] = useState([]);
  const [psMacros, setPsMacros] = useState(null);
  const [selectedPsMacro, setSelectedPsMacro] = useState([]);
  const [softwares, setSoftwares] = useState(null);
  const [selectedSoftware, setSelectedSoftware] = useState([]);
  const [selectedService, setSelectedService] = useState(null);
  const [selectedType, setSelectedType] = useState('Macro');
  const [chartDatas, setchartDatas] = useState([]);
  const [forceRefresh, setForceRefresh] = useState(false);

  const translateIndex = {
    1: 'gtp',
    2: 'gtp',
    3: 'ps',
    4: 'software'
  };

  const onTabChange = (key) => {
    setIndexTab(key);
  };

  const buildURL = () => {
    const startDate = dayjs(new Date(selectedYear, 0, 1))
      .startOf('day')
      .toISOString();
    const endDate = dayjs(new Date(selectedYear, 11, 31))
      .endOf('day')
      .toISOString();

    let url = `/statistics/${translateIndex[indexTab]}?start_date>=${startDate}&end_date<=${endDate}`;

    if (selectedLeader) url += `&leader=${selectedLeader}`;
    if (selectedGtp) url += `&user=${selectedGtp}`;
    if (selectedPsMicro?.length > 0) url += `&micro_ps=${selectedPsMicro}`;
    if (selectedPsMacro?.length > 0) url += `&macro_ps=${selectedPsMacro}`;
    if (selectedSoftware?.length > 0)
      url += `&used_softwares=${selectedSoftware}`;
    if (selectedService?.length > 0) url += `&service_type=${selectedService}`;
    if (indexTab === '1' || indexTab === '2') url += `&PsType=${selectedType}`;

    return url;
  };

  const getStatistics = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: buildURL()
      });
      setchartDatas(data);
    } catch (error) {
      message(error);
    }
  };

  const getLeaders = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: 'users?role=users:LEADER-USER'
      });
      setLeaders(data);
      initFiltersByTab('leader', indexTab, data[0], setSelectedLeader);
    } catch (error) {
      message(error);
    }
  };

  const getUsersGtp = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `users?role=users:LEADER-USER,users:USER`
      });
      setGtps(data);
      initFiltersByTab('gtp', indexTab, data[0], setSelectedGtp);
    } catch (error) {
      message(error);
    }
  };

  const getMicroPs = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/Psmicro?sort=title`
      });
      setPsMicros(data);
      setAllMicroPs(data);
      initFiltersByTab('micro_ps', indexTab, data[0], setSelectedPsMicro);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getMacroPs = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/Psmacro?sort=title`
      });
      setPsMacros(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getSoftwares = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/Software?sort=title`
      });
      setSoftwares(data);
      initFiltersByTab('software', indexTab, data[0], setSelectedSoftware);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  useEffect(async () => {
    setIsChartsLoading(true);
    setIsFiltersLoading(true);
    await getLeaders();
    await getUsersGtp();
    await getMicroPs();
    await getMacroPs();
    await getSoftwares();
    setForceRefresh(!forceRefresh);
  }, [indexTab]);

  useEffect(async () => {
    if (
      selectedLeader ||
      selectedGtp ||
      selectedPsMacro ||
      selectedSoftware?.length > 0
    ) {
      await getStatistics();
      setIsChartsLoading(false);
      setIsFiltersLoading(false);
    }
  }, [forceRefresh]);

  useEffect(() => {
    if (selectedPsMacro?.length > 0) {
      const newPsMicros = allMicroPs.filter((f) =>
        selectedPsMacro.some((macroId) => f.macro_ps.includes(macroId))
      );
      setPsMicros(newPsMicros);
    } else {
      getMicroPs();
    }
  }, [selectedPsMacro]);

  return (
    <StatisticsContext.Provider
      value={{
        onTabChange,
        indexTab,
        selectedYear,
        setSelectedYear,
        isChartsLoading,
        isFiltersLoading,
        gtps,
        leaders,
        psMicros,
        psMacros,
        softwares,
        setSelectedLeader,
        selectedLeader,
        setSelectedGtp,
        selectedGtp,
        setSelectedService,
        selectedService,
        setSelectedPsMicro,
        selectedPsMicro,
        setSelectedPsMacro,
        selectedPsMacro,
        setSelectedSoftware,
        selectedSoftware,
        setSelectedType,
        selectedType,
        chartDatas,
        forceRefresh,
        setForceRefresh
      }}
    >
      {children}
    </StatisticsContext.Provider>
  );
};

StatisticsContext.propTypes = {
  children: PropTypes.element.isRequired
};

export default () => useContext(StatisticsContext);
