import { Route, Routes } from 'react-router-dom';
import { Exception } from '../../components';
import { CustomerContextProvider } from './CustomerContext';
import { ListCustomer } from './ListCustomer';
import { ShowCustomer } from './ShowCustomer';

export const CustomerRouterGuest = () => (
  <CustomerContextProvider>
    <Routes>
      <Route path="/show/:id" element={<ShowCustomer />} />
      <Route index element={<ListCustomer />} />
      <Route path="*" element={<Exception />} />
    </Routes>
  </CustomerContextProvider>
);
