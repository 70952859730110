import { useState } from 'react';
import { Flex, Select, Tag, Typography, Modal } from 'antd';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { ListResource } from '../../components';
import { useColumns } from './columns';
import { headers } from './headers';
import useCustomerContext from './CustomerContext';
import { customerStatus } from '../../utils/constants/tagColors';
import { useAuthContext } from '../../contexts/AuthContext';
import { getStatuses } from './utils';
import { useFormatter } from '../../utils/formatter';

const { Option } = Select;

/**
 * Renders a list of customers with filtering options and a modal for adding new customers.
 *
 * @component
 * @returns {JSX.Element} - A component displaying a list of customers and filtering options.
 */
export const ListCustomer = () => {
  const { t } = useTranslation();
  const { enums, micro_ps, setCreateType, createType, psMacro, hasAccess } =
    useCustomerContext();
  const navigate = useNavigate();
  const { user } = useAuthContext();
  const { formattedData } = useFormatter('customers');
  const [selectedStatus, setSelectedStatus] = useState(createType);
  const [selectedLeader, setSelectedLeader] = useState('');
  const [selectedTypePs, setSelectedTypePs] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [createTypeLocal, setCreateTypeLocal] = useState('ACTIVE');

  const columns = useColumns(enums, psMacro);
  const availableStatuses =
    user.role.split(':')[1] === 'USER'
      ? ['ACTIVE']
      : ['ACTIVE', 'PROSPECT', 'NON_PS_CUSTOMER'];

  return (
    <>
      {isModalOpen && (
        <Modal
          open={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          title={t('customers.add.type_title')}
          onOk={() => {
            setCreateType(createTypeLocal);
            setIsModalOpen(false);
            navigate({ pathname: '/customers/create' });
          }}
          onCancel={() => {
            setCreateTypeLocal('');
            setIsModalOpen(false);
          }}
        >
          <Select
            allowClear
            defaultValue="ACTIVE"
            onChange={(value) => setCreateTypeLocal(value)}
          >
            {(availableStatuses || []).map((status) => (
              <Select.Option key={status} value={status}>
                <Tag color={customerStatus[status]}>
                  {t(`customers.status.${status}`)}
                </Tag>
              </Select.Option>
            ))}
          </Select>
        </Modal>
      )}
      <ListResource
        resourceName="customers"
        columns={columns}
        headers={headers}
        formatter={formattedData}
        onClickAdd={() => setIsModalOpen(true)}
        withImportButton={false}
        withUploadButton={user.role.split(':')[1] !== 'USER'}
        populate="micro_ps contacts used_software sales leader macro_ps business_introducer"
        resourceModelName="Customer"
        extraQuery={`status=${selectedStatus || ''}${
          selectedTypePs ? `&micro_ps=${selectedTypePs || ''}` : ''
        }${selectedLeader ? `&leader=${selectedLeader || ''}` : ''}`}
        deleteAction={false}
        withCreateButtonText={t('buttons.create_custom.customers')}
        flexWrap
        scroll={{ x: 2000 }}
        extraFilters={
          hasAccess('filters') && (
            <Flex align="center" gap={16} wrap>
              {user.role.split(':')[1] !== 'USER' && (
                <Flex align="center" gap={4}>
                  <Typography>{t('customers.select.status')} :</Typography>
                  <Select
                    allowClear
                    onChange={(value) => {
                      setCreateType(value);
                      setSelectedStatus(value);
                    }}
                    defaultValue={selectedStatus}
                    style={{ width: 200 }}
                  >
                    {(getStatuses(enums?.status, user?.role) || []).map(
                      (status) => (
                        <Option key={status} value={status}>
                          <Tag color={customerStatus[status]}>
                            {t(`customers.status.${status}`)}
                          </Tag>
                        </Option>
                      )
                    )}
                  </Select>
                </Flex>
              )}
              <Flex align="center" gap={4}>
                <Typography>{t('customers.select.leader')} :</Typography>
                <Select
                  allowClear
                  onChange={(value) => setSelectedLeader(value)}
                  style={{ width: 200 }}
                >
                  {(enums?.leaders || []).map((leader) => (
                    <Option key={leader._id} value={leader._id}>
                      <Tag color={leader.color}>
                        {`${leader.first_name} ${
                          leader?.usage_last_name || leader?.last_name
                        }`}
                      </Tag>
                    </Option>
                  ))}
                </Select>
              </Flex>
              <Flex align="center" gap={4}>
                <Typography>{t('customers.select.type_ps')} :</Typography>
                {micro_ps?.length > 1 && (
                  <Select
                    allowClear
                    onChange={(value) => setSelectedTypePs(value)}
                    style={{ width: 200 }}
                  >
                    {(
                      micro_ps?.sort((a, b) =>
                        a.title.localeCompare(b.title)
                      ) || []
                    ).map((type_ps) => (
                      <Option key={type_ps._id} value={type_ps._id}>
                        <Tag color={type_ps.color}>{type_ps.title}</Tag>
                      </Option>
                    ))}
                  </Select>
                )}
              </Flex>
            </Flex>
          )
        }
        extraEditCondition={(record) =>
          user?.role !== 'users:SALES-USER' || record?.status === 'PROSPECT'
        }
      />
    </>
  );
};
