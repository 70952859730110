/**
 * Generates a configuration object for creating a chart displaying article likes and dislikes.
 *
 * @hook
 * @param {Object} article - The article object containing like and dislike data.
 * @param {number} article.likes.length - The number of likes for the article.
 * @param {number} article.dislikes.length - The number of dislikes for the article.
 * @returns {Object} A configuration object for creating a chart.
 */
const getChartConfig = (article) => ({
  appendPadding: 10,
  data: [
    {
      type: 'like',
      value: article?.likes?.length
    },
    {
      type: 'dislike',
      value: article?.dislikes?.length
    }
  ],
  angleField: 'value',
  colorField: 'type',
  color: ({ type }) => (type === 'like' ? 'green' : 'orange'),
  radius: 1,
  innerRadius: 0.6,
  label: {
    content: ({ value }) => value.toString(),
    type: 'inner',
    offset: '-50%',
    style: { textAlign: 'center', fontSize: 14 }
  },
  interactions: [
    {
      type: 'element-selected'
    },
    {
      type: 'element-active'
    }
  ],
  statistic: {
    title: false,
    content: {
      style: {
        whiteSpace: 'pre-wrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis'
      }
    }
  },
  legend: {
    itemName: {
      formatter: (text) => (text === 'like' ? '👍' : '👎'),
      style: {
        opacity: 0.65
      }
    }
  }
});

export default getChartConfig;
