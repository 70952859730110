import { Tag } from 'antd';
import { formatValue } from '../../../utils/formatNumber';

/**
 * useComplementaryInfos is a custom hook that generates an array of content configurations for displaying complementary informations prospect data.
 *
 * @hook
 * @param {Object} data - Customer data object containing complementary informations.
 * @returns {Array<Object>} An array of content configurations for displaying complementary informations prospect data.
 */
const useComplementaryInfos = (data) => {
  const {
    business_introducer,
    business_introducer_id,
    used_software,
    siret_number,
    invoice_per_month,
    rejection_rate,
    outstanding_third_party_number,
    third_party_payment_turnover,
    global_turnover,
    link_to_opportunity
  } = data;

  return [
    {
      label: 'customers.form.business_introducer',
      span: 5,
      content: business_introducer?.title || '-'
    },
    {
      label: 'customers.form.business_introducer_id',
      span: 5,
      content: business_introducer_id ? (
        <Tag>{business_introducer_id}</Tag>
      ) : (
        '-'
      )
    },
    {
      label: 'customers.form.used_software',
      span: 10,
      content: used_software?.map((soft) => soft?.title).join(', ') || '-'
    },
    {
      label: 'customers.form.siret_number',
      span: 5,
      content: formatValue(siret_number, 'siret') || '-'
    },
    {
      label: 'customers.form.invoice_per_month',
      span: 5,
      content: invoice_per_month || '-'
    },
    {
      label: 'customers.form.rejection_rate',
      span: 5,
      content: rejection_rate || '-'
    },
    {
      label: 'customers.form.outstanding_third_party_number',
      span: 5,
      content: outstanding_third_party_number || '-'
    },
    {
      label: 'customers.form.third_party_payment_turnover',
      span: 5,
      content: third_party_payment_turnover
        ? `${formatValue(third_party_payment_turnover, 'currency')}`
        : '-'
    },
    {
      label: 'customers.form.global_turnover',
      span: 5,
      content: global_turnover
        ? `${formatValue(global_turnover, 'currency')}`
        : '-'
    },
    {
      label: 'customers.form.link_to_opportunity',
      span: 10,
      content: link_to_opportunity || '-'
    }
  ];
};

export default useComplementaryInfos;
