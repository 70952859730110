import { useTranslation } from 'react-i18next';
import { Tag, Divider, Popconfirm, Button, Flex } from 'antd';

import {
  CloseOutlined,
  MailOutlined,
  WarningOutlined
} from '@ant-design/icons';
import { reconciliationStatementColor } from '../../../utils/constants/tagColors';
import { formatValue } from '../../../utils/formatNumber';

export const useColumns = (
  psMicro,
  users,
  leaders,
  reconcilitionStatementEnums,
  editReconciliation,
  openEmailModal,
  customers
) => {
  const { t } = useTranslation();
  const iconSize = 18;

  return [
    {
      title: t('reconciliationStatements.columns.ps_number'),
      key: 'ps_number',
      dataIndex: 'customer',
      render: (customer) => (
        <Tag>{(customer && customer?.ps_number) || '-'}</Tag>
      ),
      sorter: true
    },
    {
      title: t('reconciliationStatements.columns.company_name'),
      key: 'customer',
      dataIndex: 'customer',
      render: (customer) => (customer && customer?.company_name) || '-',
      filterSearch: true,
      filters: customers?.map((customer) => ({
        text: customer.company_name,
        value: customer._id
      })),
      sorter: true
    },
    {
      title: t('reconciliationStatements.columns.micro_ps'),
      key: 'micro_ps',
      dataIndex: 'micro_ps',
      render: (micro_ps) => (micro_ps && micro_ps?.title) || '-',
      filterSearch: true,
      filters: psMicro?.map((ps) => ({
        text: ps.title,
        value: ps._id
      })),
      sorter: true
    },
    {
      title: t('reconciliationStatements.columns.leader'),
      key: 'leader',
      dataIndex: 'leader',
      render: (leader) =>
        (leader &&
          `${leader?.first_name} ${
            leader?.usage_last_name || leader?.last_name
          }`) ||
        '-',
      sorter: true,
      filters: (leaders || []).map((leader) => ({
        text: `${leader?.first_name} ${
          leader?.usage_last_name || leader?.last_name
        }`,
        value: leader._id
      }))
    },
    {
      title: t('reconciliationStatements.columns.action_date'),
      key: 'action_date',
      dataIndex: ['action_date'],
      render: (action_dates) => (
        <Flex>
          {action_dates?.length > 0
            ? action_dates.map((date) => formatValue(date, 'date')).join('-')
            : '-'}
        </Flex>
      )
    },
    {
      title: t('reconciliationStatements.columns.done_by'),
      key: 'done_by',
      dataIndex: ['done_by'],
      render: (done_by) => (
        <Flex>
          {done_by?.length > 0
            ? done_by
                .map(
                  (user) =>
                    `${user.first_name} ${
                      user.usage_last_name || user.last_name
                    }`
                )
                .join('-')
            : '-'}
        </Flex>
      ),
      filters: (users || []).map((done_by) => ({
        text: `${done_by?.first_name} ${
          done_by?.usage_last_name || done_by?.last_name
        }`,
        value: done_by._id
      }))
    },
    {
      title: t('reconciliationStatements.columns.status'),
      key: 'status',
      dataIndex: ['status'],
      render: (status) => (
        <Tag color={reconciliationStatementColor[status]}>
          {t(`reconciliationStatements.enums.${status}`, {
            defaultValue: status
          })}
        </Tag>
      ),
      sorter: true,
      filters: reconcilitionStatementEnums?.status?.map((p) => ({
        text: t(`reconciliationStatements.enums.${p}`),
        value: p
      }))
    },
    {
      key: 'action',
      align: 'right',
      fixed: 'right',
      render: (record) => (
        <>
          <Divider type="vertical" />
          <Button type="link" onClick={() => openEmailModal(record)}>
            <MailOutlined style={{ fontSize: iconSize, color: '#52c41a' }} />
          </Button>

          <Divider type="vertical" />
          {record?.status === 'TO_DO' ? (
            <>
              <Divider type="vertical" />
              <Popconfirm
                title={t(`reconciliationStatements.action.NOT_SEND.title`)}
                okText={t(`reconciliationStatements.action.NOT_SEND.ok`)}
                okButtonProps={{ type: 'danger' }}
                cancelText={t(
                  `reconciliationStatements.action.NOT_SEND.cancel`
                )}
                onConfirm={() => editReconciliation(record, 'NOT_SEND')}
                icon={<WarningOutlined />}
              >
                <CloseOutlined
                  style={{ color: '#b51010', fontSize: iconSize }}
                  type="delete"
                />
              </Popconfirm>
            </>
          ) : null}
        </>
      )
    }
  ];
};
