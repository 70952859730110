import { Route, Routes } from 'react-router-dom';
import { ListServices } from './ListServices';
import { Exception } from '../../components';
import { ShowService } from './ShowService';
import { ServiceContextProvider } from './ServiceContext';

export const ServiceRouter = () => (
  <ServiceContextProvider>
    <Routes>
      <Route index element={<ListServices />} />
      <Route path="/show/:id" element={<ShowService />} />
      <Route path="*" element={<Exception />} />
    </Routes>
  </ServiceContextProvider>
);
