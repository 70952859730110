import { PropTypes } from 'prop-types';
import dayjs from 'dayjs';
import { CreateUpdateContainer } from '../../components';
import { useFeesFields } from './fields';
import useFeesContext from './FeesContext';

export const CreateUpdateFees = ({ purpose }) => {
  const fields = useFeesFields();

  const { setService } = useFeesContext();

  return (
    <CreateUpdateContainer
      purpose={purpose}
      fields={fields}
      baseUrl="/fees"
      resource="fees"
      config={{
        onGetResource: {
          setFields: (data) => {
            setService(data.service);
            return {
              ...data,
              start_date: data.start_date && dayjs(data.start_date)
            };
          }
        }
      }}
    />
  );
};

CreateUpdateFees.propTypes = {
  purpose: PropTypes.string.isRequired
};
