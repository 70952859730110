import {
  HomeOutlined,
  UserOutlined,
  SettingOutlined,
  CalendarOutlined,
  CalculatorOutlined,
  ContactsOutlined,
  ReconciliationOutlined,
  FileAddOutlined,
  CarryOutOutlined,
  BulbOutlined,
  ScheduleOutlined,
  CommentOutlined,
  MailOutlined,
  FundViewOutlined,
  FileTextOutlined,
  EuroOutlined,
  SolutionOutlined,
  FileSearchOutlined,
  BarChartOutlined
} from '@ant-design/icons';
import { ContractIcon } from './customIcons';

const navMenuLogos = {
  HOME: <HomeOutlined />,
  CUSTOMERS: <ContactsOutlined />,
  INTERVENTIONS: <ReconciliationOutlined />,
  CUSTOMERSERVICES: <CommentOutlined />,
  CALENDAR: <CalendarOutlined />,
  USERS: <UserOutlined />,
  FEES: <CalculatorOutlined />,
  SETTINGS: <SettingOutlined />,
  QUOTE: <FileAddOutlined />,
  CONTRACT: <ContractIcon />,
  RH: <CarryOutOutlined />,
  CUSTOMER_ANALYSIS: <FundViewOutlined />,
  ACTUALITIES: <BulbOutlined />,
  QUALITY_CONTROL: <FileSearchOutlined />,
  OCCUPANCY_CHART: <ScheduleOutlined />,
  MAILS: <MailOutlined />,
  TEMPLATE_DOCS: <FileTextOutlined />,
  BILLING: <EuroOutlined />,
  SERVICES: <SolutionOutlined />,
  STATISTICS: <BarChartOutlined />
};

export default navMenuLogos;
