import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Card, Col, Popover, Descriptions, Typography, Tag, Row } from 'antd';
import { useDrag } from 'react-dnd';
import { servicesColor } from '../../utils/constants/tagColors';
import { useListContent } from '../../routes/occupancy_chart/occupancy/listContentPopover';

export const DraggableCard = ({ content, modelResourceName }) => {
  const { t } = useTranslation();
  const listContent = useListContent(content, t, modelResourceName);
  const {
    reference,
    contract,
    type,
    frequency,
    total_hours,
    diff_in_hours,
    diff_since_start_service,
    service,
    scheduled_time,
    user
  } = content;

  const [{ isDragging }, drag] = useDrag(() => {
    const draggableItem = { ...content };
    return {
      type: 'GRID',
      item: draggableItem,
      collect: (monitor) => ({
        isDragging: monitor.isDragging()
      })
    };
  });

  const border = isDragging
    ? '1px solid var(--primaryColor)'
    : '1px solid var(--borderColor)';
  const cursor = isDragging ? 'grabbing' : 'grab';

  return (
    <Col xs={24} md={12} xl={8} xxl={4}>
      <Popover
        placement="right"
        content={(listContent || []).map((item) => (
          <Descriptions.Item span={item.span || 1} key={item.key}>
            <Col>
              {!item.noLabel && (
                <Typography>
                  {item.label !== ' ' &&
                    `${t(`${item.label}`)}${item.v ? ` ${item.v}` : ''} :`}
                </Typography>
              )}
              <Typography style={{ marginBottom: 8, fontWeight: 600 }}>
                {item.content || '-'}
              </Typography>
            </Col>
          </Descriptions.Item>
        ))}
        title={t(`${modelResourceName}.carousel.popover.title`)}
      >
        <Card
          title={reference}
          ref={drag}
          style={{
            border,
            cursor,
            position: 'relative'
          }}
          className="planning-draggable-card"
        >
          <Row justify="space-between" style={{ marginBottom: 4, gap: 4 }}>
            <Col>
              <Tag color={servicesColor[type || service?.type?.split('_')[0]]}>
                {contract?.ps_number || 'N/A'}
              </Tag>
            </Col>
            <span className="planning-company-name">
              {contract?.company_name || '-'}
            </span>
            <Col />
          </Row>
          {modelResourceName === 'occupancies' ? (
            <Row justify="space-between">
              <Col>
                <Tag
                  color={
                    diff_since_start_service > 0 ? 'var(--errorColor)' : 'green'
                  }
                >{`${
                  diff_since_start_service > 0
                    ? diff_since_start_service
                    : Math.abs(diff_since_start_service)
                }`}</Tag>
              </Col>
              <Col style={{ fontSize: 14 }}>
                {`${total_hours && total_hours.toFixed(0)}h${
                  frequency
                    ? t(`${modelResourceName}.carousel.tags.${frequency}`)
                    : ''
                }`}
              </Col>
              <Col>
                <Tag
                  color={diff_in_hours > 0 ? 'var(--errorColor)' : 'green'}
                >{`${
                  diff_in_hours > 0 ? diff_in_hours : Math.abs(diff_in_hours)
                }`}</Tag>
              </Col>
            </Row>
          ) : (
            <Row
              justify="space-between"
              style={{ gap: 4, alignItems: 'center' }}
            >
              <Col>{`${scheduled_time}h`}</Col>
              <Col style={{ fontSize: 12 }}>{`${user?.first_name} ${
                user?.usage_last_name || user?.last_name
              }`}</Col>
              <Col>
                <Tag
                  color={diff_in_hours > 0 ? 'var(--errorColor)' : 'green'}
                >{`${
                  diff_in_hours > 0 ? diff_in_hours : Math.abs(diff_in_hours)
                }`}</Tag>
              </Col>
            </Row>
          )}
        </Card>
      </Popover>
    </Col>
  );
};

DraggableCard.propTypes = {
  content: PropTypes.shape({
    _id: PropTypes.string.isRequired,
    reference: PropTypes.string,
    type: PropTypes.string,
    total_hours: PropTypes.number,
    diff_in_hours: PropTypes.number,
    diff_since_start_service: PropTypes.number,
    frequency: PropTypes.string,
    contract: PropTypes.shape({
      ps_number: PropTypes.string,
      company_name: PropTypes.string
    }).isRequired,
    user_referent: PropTypes.shape({
      last_name: PropTypes.string
    }).isRequired,
    service: PropTypes.shape({
      type: PropTypes.string,
      total_hours: PropTypes.number,
      frequency: PropTypes.string
    }),
    user: PropTypes.shape({
      first_name: PropTypes.string,
      usage_last_name: PropTypes.string,
      last_name: PropTypes.string
    }),
    scheduled_time: PropTypes.number
  }),
  modelResourceName: PropTypes.string.isRequired
};

DraggableCard.defaultProps = {
  content: PropTypes.shape({
    reference: null,
    type: undefined,
    scheduled_time: undefined,
    service: PropTypes.shape({
      type: null,
      total_hours: null,
      frequency: null
    }),
    user: PropTypes.shape({
      first_name: null,
      usage_last_name: null,
      last_name: null
    })
  })
};
