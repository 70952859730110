export const headers = [
  {
    label: 'micro_ps',
    key: 'micro_ps.title'
  },
  {
    label: 'last_name',
    key: 'last_name'
  },
  {
    label: 'first_name',
    key: 'first_name'
  },
  {
    label: 'trigram',
    key: 'trigram'
  },
  {
    label: 'email',
    key: 'email'
  },
  {
    label: 'role',
    key: 'role'
  }
];
