import { Route, Routes } from 'react-router-dom';
import { ListAbsenceVacation } from './ListAbsenceVacation';
import { Exception } from '../../components';
import { AbsenceVacationContextProvider } from './AbsenceVacationContext';
import { ShowAbsenceVacation } from './ShowAbsenceVacation';

export const AbsenceVacationRouter = () => (
  <AbsenceVacationContextProvider>
    <Routes>
      <Route path="/show/:id" element={<ShowAbsenceVacation />} />
      <Route index element={<ListAbsenceVacation />} />
      <Route path="*" element={<Exception />} />
    </Routes>
  </AbsenceVacationContextProvider>
);
