import React, { useState, useEffect } from 'react';
import { Button, Flex, Row, Col, Typography, Select } from 'antd';
import dayjs from 'dayjs';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { addWeeks, startOfWeek } from 'date-fns';
import { CalendarHeaderProps } from './types';
import { useTranslation } from 'react-i18next';
import { MonthName } from './MonthName';
import { SwitchModes } from '../SwitchModes';

const { Option } = Select;

const generateWeeks = () => {
  const weeks = [];
  const startOfYear = startOfWeek(new Date(new Date().getFullYear(), 0, 1));
  for (let i = 0; i < 52; i++) {
    const weekStart = addWeeks(startOfYear, i);
    const weekEnd = addWeeks(weekStart, 1);
    weeks.push({
      label: `${dayjs(weekStart).add(1, 'days').format('DD/MM')} - ${dayjs(weekEnd).format('DD/MM')}`,
      value: weekStart.toISOString(),
    });
  }
  return weeks;
};

export const CalendarHeader: React.FunctionComponent<CalendarHeaderProps> = ({
  startWeek,
  setStartWeek,
  ISOWeekName,
  modes,
  mode,
  setMode,
  extraHeader,
  switchModes,
  setForceRefresh,
  forceRefresh,
  startWeekDependance,
}) => {
  const { t } = useTranslation();
  const weeks = generateWeeks();
  const [selectedWeek, setSelectedWeek] = useState(startWeek.toISOString());

  useEffect(() => {
    setSelectedWeek(startWeek.toISOString());
  }, [startWeek]);

  const handleWeekChange = (value: string) => {
    setStartWeek(new Date(value));
    setForceRefresh(forceRefresh + 1);
  };

  const handleWeekNavigation = (weeksToAdd: number) => {
    const newStartWeek = addWeeks(startWeek, weeksToAdd);
    setStartWeek(newStartWeek);
    setForceRefresh(forceRefresh + 1);
  };

  return (
    <>
      <Row justify="space-between" align="middle" className="calendar-header" gutter={[16, 16]}>
        <Col>
          {switchModes ? (
            <SwitchModes modes={modes} mode={mode} setMode={setMode} />
          ) : (
            <Typography.Title level={4} className="calendar-period">
              {`Du ${dayjs(startWeek).add(1, 'days').format('DD/MM')} 
                  au ${dayjs(startWeek).add(7, 'days').format('DD/MM')}`}
            </Typography.Title>
          )}
        </Col>
        <Col>{extraHeader}</Col>
        {!startWeekDependance && (
          <Col>
            <Flex align="center" wrap gap={24}>
              <Flex gap={8}>
                <Select
                  value={selectedWeek}
                  onChange={handleWeekChange}
                  style={{ width: 200 }}
                >
                  {weeks.map((week) => (
                    <Option key={week.value} value={week.value}>
                      {week.label}
                    </Option>
                  ))}
                </Select>
                <Button
                  type="primary"
                  onClick={() => {
                    setStartWeek(startOfWeek(new Date()));
                    setForceRefresh(forceRefresh + 1);
                  }}
                >
                  {t('calendar.buttons.today')}
                </Button>
                <Button
                  type="primary"
                  onClick={() => handleWeekNavigation(-1)}
                >
                  <LeftOutlined />
                </Button>
                <Button
                  type="primary"
                  onClick={() => handleWeekNavigation(1)}
                >
                  <RightOutlined />
                </Button>
              </Flex>
              <MonthName startWeek={startWeek} ISOWeekName={ISOWeekName} />
            </Flex>
          </Col>
        )}
      </Row>
    </>
  );
};
