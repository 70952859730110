import { useState } from 'react';
import { Col, Card, Button, Table } from 'antd';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { AddIcon } from '../../../../utils/constants/customIcons';
import { Datatable } from '../../../../components';
import { useColumns } from './columns';
import { useExpandableColumns } from './expandableColumns';
import { CreateUpdateDrawer } from './CreateUpdateEmploymentDrawer';
import { ShowEmploymentDrawer } from './ShowEmploymentDrawer';
import { useAuthContext } from '../../../../contexts/AuthContext';
import { useErrorMessage } from '../../../../utils/errorMessage';
import { showSuccessMessage } from '../../../../utils/showSuccessMessage';
import { isAuthorized } from '../../../../utils/constants/authorizedMenu';

export const ShowEmploymentInfos = ({ data }) => {
  const { t } = useTranslation();
  const { dispatchAPI, user } = useAuthContext();
  const { message } = useErrorMessage();
  const location = useLocation();
  const { pathname } = location;
  const [purpose, setPurpose] = useState('create');
  const [forceRefresh, setForceRefresh] = useState(false);
  const [idFromOverlay, setIdFromOverlay] = useState(null);
  const [isOverlayVisible, setIsOverlayVisible] = useState(false);
  const [isShowOverlayVisible, setShowOverlayVisible] = useState(false);
  const [contractType, setContractType] = useState('');
  const [rootContract, setRootContract] = useState(null);
  const [startDateContract, setStartDateContract] = useState(null);
  const [company, setCompany] = useState(null);

  const hasAccess = (purp) => isAuthorized(pathname, user?.role, purp);

  const customNavigate = () => {
    setCompany(null);
    setContractType(null);
    setStartDateContract(null);
    setIsOverlayVisible(false);
    setIdFromOverlay(null);
    setForceRefresh(!forceRefresh);
  };

  const onCreate = () => {
    setPurpose('create');
    setIsOverlayVisible(true);
    setContractType('CONTRACT');
  };

  const onEdit = (id) => {
    setPurpose('edit');
    setContractType('CONTRACT');
    setIsOverlayVisible(true);
    setIdFromOverlay(id);
  };

  const onShow = (id) => {
    setContractType('CONTRACT');
    setIdFromOverlay(id);
    setShowOverlayVisible(true);
  };

  const onCreateAmendment = (record) => {
    setPurpose('create');
    setRootContract(record._id);
    setStartDateContract(record.start_date);
    setCompany(record.company);
    setContractType('AMENDMENT');
    setIsOverlayVisible(true);
  };

  const onEditAmendment = (id) => {
    setPurpose('edit');
    setContractType('AMENDMENT');
    setIsOverlayVisible(true);
    setIdFromOverlay(id);
  };

  const onShowAmendment = (id) => {
    setIdFromOverlay(id);
    setShowOverlayVisible(true);
    setContractType('AMENDMENT');
  };

  const onDelete = async (id) => {
    try {
      await dispatchAPI('DELETE', { url: `/employment/${id}` });
      setForceRefresh(!forceRefresh);
      showSuccessMessage(t, 'contracts', 'archive');
    } catch (e) {
      message(e);
    }
    const test = id;
    return test;
  };

  const expandableColumns = useExpandableColumns({
    show: onShowAmendment,
    edit: onEditAmendment,
    delete: onDelete
  });

  const columns = useColumns({
    show: onShow,
    edit: onEdit,
    createAmendment: onCreateAmendment
  });

  const expandedRowRender = (record) => (
    <Table
      dataSource={record?.amendments}
      pagination={false}
      columns={expandableColumns}
      rowKey="_id"
    />
  );

  return (
    <Col span="24">
      {isOverlayVisible && (
        <CreateUpdateDrawer
          purpose={purpose}
          isOverlayVisible={isOverlayVisible}
          setIsOverlayVisible={setIsOverlayVisible}
          idFromOverlay={idFromOverlay}
          customNavigate={customNavigate}
          user={data}
          contractType={contractType}
          rootContract={rootContract}
          startDateContract={startDateContract}
          company={company}
        />
      )}
      {isShowOverlayVisible && (
        <ShowEmploymentDrawer
          id={idFromOverlay}
          opened={isShowOverlayVisible}
          setOpen={setShowOverlayVisible}
          contractType={contractType}
        />
      )}
      <Card
        title={t('users.show.contracts')}
        extra={
          hasAccess('create') && (
            <Button type="primary" onClick={onCreate}>
              {`${t('employments.buttons.create')}`}
              <AddIcon />
            </Button>
          )
        }
      >
        {data && (
          <Datatable
            resourceName="employments"
            populate="amendments"
            columns={columns}
            withPageHeader={false}
            extraQuery={`user=${data?._id}&type=CONTRACT`}
            deleteAction={false}
            customActionColumn
            forceRefresh={forceRefresh}
            onDoubleClickAction={false}
            scroll={{ x: 1200 }}
            expandable={{
              expandedRowRender: (record) => expandedRowRender(record),
              rowExpandable: (record) =>
                !!record.amendments && record.amendments.length > 0
            }}
          />
        )}
      </Card>
    </Col>
  );
};

ShowEmploymentInfos.propTypes = {
  data: PropTypes.shape({
    _id: PropTypes.string
  })
};

ShowEmploymentInfos.defaultProps = {
  data: undefined
};
