import { useState, useEffect } from 'react';
import { startOfWeek, endOfWeek } from 'date-fns';
import Calendar from './CalendarBody.tsx';
import { CalendarHeader } from './CalendarHeader.tsx';
import { GenericEvent, CalendarContainerProps } from './types.tsx';
import { daysToWeekObject } from './utils.ts';
import { createEvents } from './createEvents.tsx';

export function WeeklyCalendar<T extends GenericEvent>({
  events,
  onEventClick,
  onSelectDate,
  dayRange = 'no-weekend',
  hourStart = 0,
  hourEnd = 26,
  value,
  ISOWeekName = true,
  modes,
  mode,
  setMode,
  weekCellRender,
  setOpenModal,
  setDate,
  directPatchIntervention,
  setEventToUpdate,
  deleteEvent,
  printResource,
  extraHeader,
  noAllDayRow,
  switchModes = true,
  setForceCalendarRefresh,
  forceCalendarRefresh,
  startWeekDependance,
  resource,
  userConnected
}: CalendarContainerProps<T>) {
  const [startWeek, setStartWeek] = useState(startOfWeek(value || new Date()));
  const weekPeriod = {
    startDate: startWeekDependance || startWeek,
    endDate: endOfWeek(startWeekDependance || startWeek),
    ISOWeekName
  };

  useEffect(() => {
    if (value && startOfWeek(value).getTime() !== startWeek.getTime()) {
      setStartWeek(value);
    }
  }, [value]);

  useEffect(() => {
    onSelectDate && onSelectDate(startWeek);
  }, [startWeek]);

  const createEventsFromRawData = createEvents(
    events,
    weekCellRender,
    setOpenModal,
    setEventToUpdate,
    setDate,
    directPatchIntervention,
    deleteEvent,
    printResource,
    resource,
    userConnected
  );

  const weekObject = daysToWeekObject(createEventsFromRawData, startWeek);

  return (
    <>
      <CalendarHeader
        startWeek={startWeek}
        setStartWeek={setStartWeek}
        ISOWeekName={ISOWeekName}
        modes={modes}
        mode={mode}
        setMode={setMode}
        extraHeader={extraHeader}
        switchModes={switchModes}
        setForceRefresh={setForceCalendarRefresh}
        forceRefresh={forceCalendarRefresh}
        startWeekDependance={startWeekDependance}
      />
      <Calendar
        weekDates={weekPeriod}
        getDayEvents={weekObject}
        onEventClick={onEventClick}
        dayRange={dayRange}
        hourStart={hourStart}
        hourEnd={hourEnd}
        noAllDayRow={noAllDayRow}
        setOpenModal={setOpenModal}
        setEventToUpdate={setEventToUpdate}
        setDate={setDate}
        directPatchIntervention={directPatchIntervention}
      />
    </>
  );
}
