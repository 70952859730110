import { useTranslation } from 'react-i18next';
import { Tag } from 'antd';
import { yesNoSwitch } from '../../../utils/constants/tagColors';
import { formatValue } from '../../../utils/formatNumber';

/**
 * Custom hook for generating health protections content data for displaying user health protections details in a list format.
 *
 * @hook
 * @param {object} data - User data object
 * @returns {Array} Array of objects containing label, span, and content for each field
 */

export const useListHealthProtectionsContent = (data = {}) => {
  const { t } = useTranslation();

  const {
    health_insurance,
    health_insurance_start_date,
    health_insurance_end_date,
    health_insurance_end_comment,
    healthcare_coverage,
    healthcare_coverage_start_date,
    healthcare_coverage_end_date,
    healthcare_coverage_end_comment,
    rdws_periods
  } = data;

  return [
    {
      label: 'users.form.health_insurance',
      span: 1,
      content: (
        <Tag color={yesNoSwitch[health_insurance]}>
          {health_insurance ? t(`switch.${health_insurance}`) : 'N/A'}
        </Tag>
      )
    },
    {
      label: 'users.form.health_insurance_start_date',
      content: health_insurance_start_date
        ? formatValue(health_insurance_start_date, 'date')
        : '-'
    },
    {
      label: 'users.form.health_insurance_end_date',
      content: health_insurance_end_date
        ? formatValue(health_insurance_end_date, 'date')
        : '-'
    },
    {
      label: 'users.form.health_insurance_end_comment',
      span: 3,
      content: health_insurance_end_comment || '-'
    },
    {
      label: 'users.form.healthcare_coverage',
      content: (
        <Tag color={yesNoSwitch[healthcare_coverage]}>
          {healthcare_coverage ? t(`switch.${healthcare_coverage}`) : 'N/A'}
        </Tag>
      )
    },
    {
      label: 'users.form.healthcare_coverage_start_date',
      content: healthcare_coverage_start_date
        ? formatValue(healthcare_coverage_start_date, 'date')
        : '-'
    },
    {
      label: 'users.form.healthcare_coverage_end_date',
      content: healthcare_coverage_end_date
        ? formatValue(healthcare_coverage_end_date, 'date')
        : '-'
    },
    {
      label: 'users.form.healthcare_coverage_end_comment',
      span: 3,
      content: healthcare_coverage_end_comment || '-'
    },
    ...(rdws_periods?.length
      ? rdws_periods.map((period, index) => [
          {
            label: `${t('users.form.rdws_periods')} ${index + 1}`,
            content: `${formatValue(period?.start_date, 'date') || '-'}-${
              formatValue(period?.end_date, 'date') || '-'
            }`
          }
        ])[0]
      : [])
  ];
};
