import React from 'react';
import { Button, Popconfirm, Popover, Row, Col } from 'antd';
import {
  EditOutlined,
  DeleteOutlined,
  WarningOutlined,
  EyeOutlined
} from '@ant-design/icons';
import moment from 'moment';
import { routes, subRoutes } from '../../../utils/constants/adminRoutes';

const translations = {
  PAID_VACATION: 'Congé payé',
  PARENTAL_LEAVE: 'Congé parental',
  SICK_LEAVE: 'Absence maladie',
  ABSENCE: 'Absence',
  PTO: 'Absence rémunérée',
  SOLIDARITY: 'Heure de solidarité',
  ADDITIONAL_HOUR: 'Heures supplémentaires',
  OTHER: 'Autre',
  INTERVENTION: 'Intervention',
  REAL_INTERVENTION: 'Intervention',
  PROVISIONAL: 'Prévisionnel',
  PLANNED: 'Planifié',
  OPEN: 'Ouvert',
  CLOSED: 'Clôturé',
  SUSPENDED: 'Suspendu',
  PENDING: 'en attente',
  APPROVED: 'validé',
  REJECTED: 'rejeté',
  ARCHIVED: 'archivé',
  BANK_HOLIDAY: 'Jour férié',
  MANAGEMENT_MEDICAL: 'Gestion matériel médical',
  MANAGEMENT_DENTAL: 'Gestion dentaire',
  MANAGEMENT_LABORATORY: 'Gestion laboratoire',
  MANAGEMENT_PHARMACIE: 'Gestion pharmacie',
  MANAGEMENT_AUDIO: 'Gestion audioprothésiste',
  MANAGEMENT_OTHERS: 'Gestion autres',
  MANAGEMENT_OPTICAL: 'Gestion optique',
  LIABILITIES_TREATMENT_PHARMACY: 'TT passif pharmacie',
  LIABILITIES_TREATMENT_OPTICAL: 'TT passif optique',
  LIABILITIES_TREATMENT_MEDICAL: 'TT passif matériel médical',
  LIABILITIES_TREATMENT_LABORATORY: 'TT passif laboratoire',
  LIABILITIES_TREATMENT_DENTAL: 'TT passif dentaire',
  LIABILITIES_TREATMENT_AUDIO: 'TT passif audioprothésiste',
  LIABILITIES_TREATMENT_OTHER: 'TT passif autres',
  FINANCIAL_TRACKING: 'Suivi financier',
  CONFIGURATION: 'Configuration',
  OPTIMISATION: 'Optimisation',
  CONFIG_OPTI: 'Configuration et Optimisation',
  SEO: 'Référencement',
  MISSION: 'Mission',
  BILLING_TELEMONITORING: 'Facturation télésurveillance',
  SHIPPING_COSTS: 'Frais de ports',
  REMINDER_FEES: 'Frais de relance',
  COMMUTING: 'Déplacement',
  WEEK: '/sem',
  MONTH: '/mois',
  NONE: '/Ponctuel'
};

export const weeklyPlanningContent = (
  {
    _id,
    startTime,
    endTime,
    type,
    comment,
    status,
    reason,
    scheduled_start_date,
    scheduled_end_date,
    start_date,
    end_date,
    service,
    leader,
    user,
    company_name,
    ps_number,
    code
  },
  handleButtonClick,
  deleteEvent
) => [
  {
    content: (
      <span className="event-timeslot">
        {ps_number} {company_name}
      </span>
    ),
    span: 24
  },
  {
    content: (
      <span className="event-timeslot">
        {`${moment(startTime).format('HH:mm')?.replace(':', 'h')} - ${moment(
          endTime
        )
          .format('HH:mm')
          ?.replace(':', 'h')}`}
      </span>
    ),
    span: 18
  },
  {
    content: (
      <span className="event-duration">
        {moment
          .utc(moment(endTime).diff(moment(startTime), 'minutes', true) * 60000)
          .format('HH:mm')
          ?.replace(':', 'h')}
      </span>
    ),
    span: 6
  },
  {
    content: type && (
      <Popover
        overlayStyle={{ width: '300px' }}
        content={
          type === 'INTERVENTION' || type === 'REAL_INTERVENTION' ? (
            <Row
              style={{
                width: '100%'
              }}
            >
              <Col span="24">{`N° intervention: ${code}`}</Col>
              <Col span="24">{`Nom de l'établissement: ${
                company_name || 'N/A'
              }`}</Col>
              <Col span="24">{`Heure prévue début: ${moment(
                scheduled_start_date
              )
                .format('HH:mm')
                ?.replace(':', 'h')}`}</Col>
              <Col span="24">{`Heure prévue fin: ${moment(scheduled_end_date)
                .format('HH:mm')
                ?.replace(':', 'h')}`}</Col>
              {type === 'REAL_INTERVENTION' && (
                <>
                  <Col span="24">{`Début réel: ${
                    start_date
                      ? moment(start_date).format('HH:mm')?.replace(':', 'h')
                      : 'N/A'
                  }`}</Col>
                  <Col span="24">{`Fin réel: ${
                    end_date
                      ? moment(end_date).format('HH:mm')?.replace(':', 'h')
                      : 'N/A'
                  }`}</Col>
                </>
              )}
              <Col span="24">{`Prestation: ${
                translations[service?.type]
              }`}</Col>
              <Col span="24">{`Leader: ${leader?.first_name || '-'} ${
                leader?.usage_last_name || leader?.last_name
              }`}</Col>
              <Col span="24">{`Gtp: ${user?.first_name || '-'} ${
                user?.usage_last_name || user?.last_name
              }`}</Col>
            </Row>
          ) : (
            <Row>
              <Col span="24">{`Motif: ${reason}`}</Col>
              <Col span="24">{`Commentaire: ${comment}`}</Col>
            </Row>
          )
        }
        placement="right"
      >
        {type !== 'INTERVENTION' || type !== 'REAL_INTERVENTION' ? (
          <span className="event-type">
            {`${translations[type]} ${translations[status]}`}
          </span>
        ) : null}
      </Popover>
    ),
    span: 24
  },
  {
    content: (
      <Button type="link" className="event-btn first">
        <a
          href={`${
            type !== 'INTERVENTION' && type !== 'REAL_INTERVENTION'
              ? `${routes.RH}`
              : ''
          }${
            type !== 'INTERVENTION' && type !== 'REAL_INTERVENTION'
              ? `${subRoutes.RH.ABSENCE_VACATION}`
              : `${routes.INTERVENTIONS}`
          }/show/${_id}`}
          target="_blank"
          rel="noopener noreferrer"
          aria-label="Voir l'intervention ou rh"
        >
          <EyeOutlined />
        </a>
      </Button>
    )
  },
  ...(type === 'INTERVENTION'
    ? [
        {
          content: (
            <Button
              onClick={handleButtonClick}
              type="link"
              className="event-btn last"
            >
              <EditOutlined />
            </Button>
          )
        }
      ]
    : []),
  ...(type !== 'INTERVENTION' && type !== 'REAL_INTERVENTION'
    ? [
        {
          content: (
            <Popconfirm
              title="Voulez-vous vraiment archiver cet évènement ?"
              okText="Valider"
              okButtonProps={{ type: 'danger' }}
              cancelText="Annuler"
              onConfirm={() => deleteEvent(_id)}
              icon={<WarningOutlined />}
            >
              <Button type="link" className="event-btn last">
                <DeleteOutlined />
              </Button>
            </Popconfirm>
          )
        }
      ]
    : []),
  ...(type === 'INTERVENTION' || type === 'REAL_INTERVENTION'
    ? [
        {
          content: `${
            service?.total_hours_per_week &&
            service?.total_hours_per_week.toFixed(0)
          }h${service?.frequency ? translations[service?.frequency] : ''}`,
          span: 24
        }
      ]
    : [])
];
