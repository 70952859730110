import { Tag } from 'antd';
import { formatValue } from '../../../utils/formatNumber';
/**
 * useGeneralInfos is a custom hook that generates an array of content configurations for displaying general informations prospect data.
 *
 * @hook
 * @param {Object} data - Customer data object containing general informations.
 * @returns {Array<Object>} An array of content configurations for displaying general informations prospect data.
 */

const useGeneralInfos = (data, t) => {
  const {
    company,
    ps_number,
    micro_ps,
    company_name,
    legal_name,
    phone_number,
    email,
    address,
    type_ps_id,
    vat
  } = data;

  return [
    {
      label: 'customers.form.company',
      span: 5,
      content: t(`employments.tags.${company}`) || '-'
    },
    {
      label: 'customers.form.ps_number',
      span: 5,
      content: ps_number ? <Tag>{ps_number}</Tag> : '-'
    },
    {
      label: 'customers.form.micro_ps',
      span: 5,
      content: micro_ps?.title || '-'
    },
    {
      label: 'customers.form.company_name',
      span: 5,

      content: company_name || '-'
    },
    {
      label: 'customers.form.legal_name',
      span: 5,

      content: legal_name || '-'
    },
    {
      label: 'customers.form.phone_number.number',
      span: 5,
      content: phone_number?.number
        ? formatValue(
            `${phone_number?.country_code}${phone_number?.number}`,
            'phone'
          )
        : '-'
    },
    {
      label: 'customers.form.email',
      span: 5,
      content: email || '-'
    },
    {
      label: 'customers.form.address.full_address',
      span: 10,
      content: `${address?.number || '-'} ${address?.street || ''} ${
        address?.aditional || ''
      } ${address?.postal_code || ''} ${address?.city || ''}`
    },
    {
      label: 'customers.form.type_ps_id',
      span: 10,
      content: type_ps_id || '-'
    },
    {
      label: 'customers.form.vat',
      span: 10,
      content: vat || '-'
    }
  ];
};

export default useGeneralInfos;
