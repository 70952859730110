import { Tag, Popover } from 'antd';
import { EyeOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import {
  userEmployer,
  customerStatus,
  quoteStatus
} from '../../utils/constants/tagColors';
import { formatValue } from '../../utils/formatNumber';

/**
 * Custom hook for generating table columns for displaying quote data.
 *
 * @hook
 * @param {object} enums - Enumerations data for quote properties
 * @param {Array} psMicro - List of micro ps data for popover
 * @returns {Array} Array of table column objects
 */

export const useColumns = (quoteEnums, customerEnums, psMicro, sales) => {
  const { t } = useTranslation();

  return [
    {
      title: t('quotes.columns.company'),
      key: 'customer.company',

      dataIndex: ['customer'],
      render: (customer) => (
        <Tag color={userEmployer[customer?.company]}>
          {t(`users.employers.${customer?.company || 'N/A'}`)}
        </Tag>
      ),
      filters: customerEnums?.companies?.map((c) => ({
        text: t(`users.employers.${c}`),
        value: c
      }))
    },
    {
      title: t('quotes.columns.customer'),
      key: 'customer.status',
      dataIndex: ['customer'],
      render: (customer) => (
        <Tag color={customerStatus[customer?.status]}>
          {t(`customers.status.${customer?.status || 'N/A'}`, {
            defaultValue: customer?.status
          })}
        </Tag>
      ),
      filters: customerEnums?.status?.map((p) => ({
        text: t(`customers.status.${p}`),
        value: p
      }))
    },
    {
      title: t('quotes.columns.code'),
      key: 'code',
      dataIndex: 'code'
    },
    {
      title: t('quotes.columns.status'),
      key: 'status',
      dataIndex: ['status'],
      render: (status) => (
        <Tag color={quoteStatus[status]}>
          {t(`quotes.tags.${status || 'N/A'}`, {
            defaultValue: status
          })}
        </Tag>
      ),
      sorter: true,
      filters: quoteEnums?.status?.map((s) => ({
        text: t(`quotes.tags.${s}`),
        value: s
      }))
    },
    {
      title: t('quotes.columns.ps_number'),
      key: 'ps_number',
      dataIndex: 'ps_number',
      sorter: true
    },
    {
      title: t('quotes.columns.company_name'),
      key: 'customer',
      dataIndex: 'customer',
      width: '10%',
      render: (customer) => (customer && customer?.company_name) || '-'
    },
    {
      title: t('quotes.columns.contacts'),
      key: 'customer',
      dataIndex: 'customer',
      width: 80,
      render: (customer) => (
        <Popover
          content={(customer?.contacts || []).map((contact) => (
            <p key={contact.email}>{`${contact?.first_name || '-'} ${
              contact?.usage_last_name || contact?.last_name || '-'
            }`}</p>
          ))}
          title={t('quotes.columns.contacts')}
        >
          <EyeOutlined style={{ fontSize: 18 }} />
        </Popover>
      )
    },
    {
      title: t('quotes.columns.micro_ps'),
      dataIndex: 'micro_ps',
      key: 'micro_ps',
      render: (value) => value?.title,
      filterSearch: true,
      filters: psMicro
        ?.sort((a, b) => a.title.localeCompare(b.title))
        ?.map((ps) => ({
          text: ps.title,
          value: ps._id
        }))
    },
    {
      title: t('quotes.columns.created_at'),
      key: 'created_at',
      dataIndex: 'created_at',
      render: (created_at) => created_at && formatValue(created_at, 'date'),
      sorter: true
    },
    {
      title: t('quotes.columns.quote_accepted'),
      key: 'quote_accepted',
      dataIndex: 'quote_accepted',
      render: (quote_accepted) =>
        quote_accepted && formatValue(quote_accepted, 'date'),
      sorter: true
    },
    {
      title: t('quotes.columns.odm_valid'),
      key: 'odm_valid',
      dataIndex: 'odm_valid',
      render: (odm_valid) => odm_valid && formatValue(odm_valid, 'date')
    },
    {
      title: t('quotes.columns.sale'),
      key: 'sale',
      dataIndex: 'sale',
      render: (sale) =>
        (sale &&
          `${sale.first_name} ${sale.usage_last_name || sale.last_name}`) ||
        '-',
      sorter: true,
      filterSearch: true,
      filters: (sales || []).map((user) => ({
        text: `${user?.first_name} ${user?.usage_last_name || user?.last_name}`,
        value: user._id
      }))
    },
    {
      title: t('quotes.columns.services'),
      key: 'services',
      dataIndex: 'services',
      render: (services) => (
        <Popover
          content={(services || []).map((service) => (
            <div key={service._id}>
              <p>
                {`${t(`quotes.tags.${service?.type}`)}: ${`${
                  service?.total_hours
                }h/${t(`quotes.tags.${service?.frequency}`)}`}`}
              </p>
            </div>
          ))}
          title={t('quotes.columns.services')}
        >
          <EyeOutlined style={{ fontSize: 18 }} />
        </Popover>
      )
    }
  ];
};
