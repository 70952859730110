import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Modal } from 'antd';
import { CreateUpdateContainer } from '../../../components/CreateUpdateContainer/CreateUpdateContainer';
import useFieldsStatus from './fieldsStatus';

const StatusModal = ({
  isModalOpen,
  closeModal,
  idFromOverlay,
  customNavigate,
  purpose,
  buttonClicked
}) => {
  const { t } = useTranslation();

  const { fields, isFieldsLoading } = useFieldsStatus(buttonClicked);

  return (
    <Modal
      centered
      open={isModalOpen}
      onCancel={closeModal}
      title={`${t(`quittances.show.validation_modal.title`)}`}
      width={900}
      footer={false}
    >
      <CreateUpdateContainer
        fields={fields}
        loadingFields={isFieldsLoading}
        purpose={purpose}
        baseUrl="quittances"
        resource="quittances"
        withHeader={false}
        isOverlay
        customNavigate={customNavigate}
        idFromOverlay={idFromOverlay}
        drawer
        isDownloadFile
      />
    </Modal>
  );
};

StatusModal.propTypes = {
  isModalOpen: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  purpose: PropTypes.string,
  idFromOverlay: PropTypes.string,
  customNavigate: PropTypes.func,
  quittance: PropTypes.shape({}),
  enums: PropTypes.shape({}),
  buttonClicked: PropTypes.string.isRequired
};

StatusModal.defaultProps = {
  idFromOverlay: null,
  customNavigate: undefined,
  purpose: null,
  quittance: {},
  enums: {}
};

export default StatusModal;
