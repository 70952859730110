import { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { Select, DatePicker, Input, Form, Button, Checkbox, Card } from 'antd';
import { CloseCircleOutlined } from '@ant-design/icons/lib';
import QuoteContextProvider from './QuoteContext';
import { FormListNested } from './FormListNested';

/**
 * Custom hook for retrieving and managing technique-related fields for user data.
 *
 * @hook
 * @returns {object} An object containing technique fields and loading state
 * @property {Array} techniqueFields - An array of technique-related fields
 * @property {boolean} isFieldsLoading - A boolean indicating whether the fields are loading
 */

const { Option } = Select;
const { TextArea } = Input;

const useServicesFields = () => {
  const { t } = useTranslation();
  const { serviceEnums, formValues } = QuoteContextProvider();
  const [isFieldsLoading, setIsFieldsLoading] = useState(true);
  const format = 'DD-MM-YYYY';
  const theoricalHours = [
    'CONFIGURATION',
    'OPTIMISATION',
    'CONFIG_OPTI',
    'SEO'
  ];

  const onsearch = (input, option) => {
    const result = option?.children.toLowerCase();
    return result.includes(input.toLowerCase());
  };

  const noHourTitle = [
    'CONFIGURATION',
    'OPTIMISATION',
    'CONFIG_OPTI',
    'SEO',
    'MISSION',
    'BANK_RECONCILIATION',
    'OTHER'
  ];

  const isFieldVisible = (type, fieldName, specialCase) => {
    const checkFields = {
      MANAGEMENT: { frequency: true, total_hours: true },
      LIABILITIES: {
        end_date: true,
        total_hours: true
      },
      FINANCIAL: { periodicity: true, total_hours: true },
      CONFIGURATION: { end_date: true, total_hours: true },
      OPTIMISATION: { end_date: true, total_hours: true },
      CONFIG: { end_date: true, total_hours: true },
      SEO: { end_date: true, total_hours: true },
      MISSION: { end_date: true, total_hours: true }
    };
    if (!specialCase && type) {
      return checkFields?.[type?.split('_')[0]]?.[fieldName];
    }
    return specialCase;
  };

  useEffect(() => {
    if (serviceEnums) setIsFieldsLoading(false);
  }, [serviceEnums]);

  const servicesFields = [
    {
      name: ['services'],
      input: (
        <Form.List name="services" initialValue={[{}]}>
          {(fields, { add, remove }) => (
            <>
              {fields.map((field, index) => (
                <Card key={field.name} style={{ paddingRight: '16px' }}>
                  <Form.Item
                    {...field}
                    key={[field.name, 'type']}
                    name={[field.name, 'type']}
                    label={`${t('quotes.form.service.type')} ${index + 1}`}
                    rules={[
                      {
                        required: true
                      }
                    ]}
                  >
                    <Select
                      allowClear
                      showSearch
                      loading={isFieldsLoading}
                      filterOption={(input, option) => onsearch(input, option)}
                    >
                      {serviceEnums?.types?.map((type) => (
                        <Option key={type} value={type}>
                          {t(`quotes.tags.${type}`)}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                  <CloseCircleOutlined
                    style={{
                      position: 'absolute',
                      top: '5px',
                      right: '5px',
                      fontSize: '16px',
                      color: 'red',
                      cursor: 'pointer'
                    }}
                    onClick={() => remove(field.name)}
                  />
                  {isFieldVisible(
                    formValues?.services?.[index]?.type,
                    'periodicity'
                  ) && (
                    <Form.Item
                      {...field}
                      key={[field.name, 'periodicity']}
                      name={[field.name, 'periodicity']}
                      label={`${t('quotes.form.service.periodicity')}`}
                      rules={[
                        {
                          required: true
                        }
                      ]}
                    >
                      <Select
                        allowClear
                        showSearch
                        loading={isFieldsLoading}
                        filterOption={(input, option) =>
                          onsearch(input, option)
                        }
                      >
                        {serviceEnums?.periodicities?.map((periodicity) => (
                          <Option key={periodicity} value={periodicity}>
                            {t(`quotes.tags.${periodicity}`)}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  )}
                  <Form.Item
                    {...field}
                    key={[field.name, 'start_date']}
                    name={[field.name, 'start_date']}
                    label={t('quotes.form.service.start_date')}
                  >
                    <DatePicker
                      format={format}
                      disabledDate={(current) =>
                        current && current.endOf('day') < dayjs().endOf('day')
                      }
                    />
                  </Form.Item>
                  {isFieldVisible(
                    formValues?.services?.[index]?.type,
                    'end_date',
                    formValues?.services?.[index]?.type ===
                      'FINANCIAL_TRACKING' &&
                      formValues?.services?.[index]?.periodicity === 'PUNCTUAL'
                      ? true
                      : undefined
                  ) && (
                    <Form.Item
                      {...field}
                      key={[field.name, 'end_date']}
                      name={[field.name, 'end_date']}
                      label={t('quotes.form.service.end_date')}
                    >
                      <DatePicker
                        format={format}
                        disabledDate={(current) =>
                          current && current.endOf('day') < dayjs().endOf('day')
                        }
                      />
                    </Form.Item>
                  )}
                  <Form.Item
                    {...field}
                    key={[field.name, 'amount']}
                    name={[field.name, 'amount']}
                    label={t(
                      `quotes.form.service.amount${
                        noHourTitle.includes(
                          formValues?.services?.[index]?.type
                        )
                          ? '_no_hour'
                          : ''
                      }`
                    )}
                  >
                    <Input type="number" suffix="€" disabled />
                  </Form.Item>
                  {isFieldVisible(
                    formValues?.services?.[index]?.type,
                    'frequency',
                    formValues?.services?.[index]?.type ===
                      'FINANCIAL_TRACKING' &&
                      formValues?.services?.[index]?.periodicity === 'RECURENT'
                      ? true
                      : undefined
                  ) && (
                    <Form.Item
                      {...field}
                      key={[field.name, 'frequency']}
                      name={[field.name, 'frequency']}
                      label={`${t('quotes.form.service.frequency')}`}
                      rules={[
                        {
                          required: true
                        }
                      ]}
                    >
                      <Select
                        allowClear
                        showSearch
                        loading={isFieldsLoading}
                        filterOption={(input, option) =>
                          onsearch(input, option)
                        }
                      >
                        {serviceEnums?.frequencies
                          ?.filter((f) => f !== 'NONE')
                          ?.map((frequencies) => (
                            <Option key={frequencies} value={frequencies}>
                              {t(`quotes.tags.${frequencies}`)}
                            </Option>
                          ))}
                      </Select>
                    </Form.Item>
                  )}
                  {isFieldVisible(
                    formValues?.services?.[index]?.type,
                    'total_hours'
                  ) && (
                    <Form.Item
                      {...field}
                      key={[field.name, 'total_hours']}
                      name={[field.name, 'total_hours']}
                      label={t(
                        `quotes.form.service.total_hours${
                          theoricalHours.includes(
                            formValues?.services?.[index]?.type
                          )
                            ? '_theorical'
                            : ''
                        }`
                      )}
                      rules={[
                        {
                          required: true
                        }
                      ]}
                      tooltip={
                        theoricalHours.includes(
                          formValues?.services?.[index]?.type
                        )
                          ? {
                              title: t(
                                'quotes.form.service.tooltip_total_hours'
                              )
                            }
                          : false
                      }
                    >
                      <Input type="number" min="0.5" step="0.5" />
                    </Form.Item>
                  )}
                  <Form.Item
                    {...field}
                    key={[field.name, 'fee_discount']}
                    name={[field.name, 'fee_discount']}
                    label={t('quotes.form.service.fee_discount')}
                  >
                    <Input type="number" suffix="%" disabled />
                  </Form.Item>
                  <FormListNested
                    serviceEnums={serviceEnums}
                    name={field.name}
                  />
                  <Form.Item
                    {...field}
                    key={[field.name, 'total_discounts']}
                    name={[field.name, 'total_discounts']}
                    label={t('quotes.form.service.total_discounts')}
                  >
                    <Input type="number" suffix="€" disabled />
                  </Form.Item>
                  <Form.Item
                    {...field}
                    key={[field.name, 'monthly_price']}
                    name={[field.name, 'monthly_price']}
                    label={t(
                      `quotes.form.service.monthly_price${
                        formValues?.services?.[index]?.periodicity ===
                          'PUNCTUAL' ||
                        noHourTitle.includes(
                          formValues?.services?.[index]?.type
                        )
                          ? '_punctual'
                          : ''
                      }`
                    )}
                  >
                    <Input type="number" suffix="€" disabled />
                  </Form.Item>
                  <Form.Item
                    {...field}
                    key={[field.name, 'vat']}
                    name={[field.name, 'vat']}
                    label={t('quotes.form.service.vat')}
                    rules={[
                      {
                        required: true
                      }
                    ]}
                  >
                    <Input type="number" suffix="%" disabled />
                  </Form.Item>
                  <Form.Item
                    {...field}
                    key={[field.name, 'monthly_price_vat_included']}
                    name={[field.name, 'monthly_price_vat_included']}
                    label={t(
                      `quotes.form.service.monthly_price_vat_included${
                        formValues?.services?.[index]?.periodicity ===
                          'PUNCTUAL' ||
                        noHourTitle.includes(
                          formValues?.services?.[index]?.type
                        )
                          ? '_punctual'
                          : ''
                      }`
                    )}
                    rules={[
                      {
                        validator(_, value) {
                          if (value > 0) {
                            return Promise.resolve();
                          }
                          return Promise.reject(
                            new Error(t('quotes.form.service.error_message'))
                          );
                        }
                      }
                    ]}
                  >
                    <Input type="number" suffix="€" disabled />
                  </Form.Item>
                  <Form.Item
                    {...field}
                    key={[field.name, 'on_site']}
                    name={[field.name, 'on_site']}
                    label={t('quotes.form.service.on_site')}
                    valuePropName="checked"
                  >
                    <Checkbox />
                  </Form.Item>
                  <Form.Item
                    {...field}
                    key={[field.name, 'comment']}
                    name={[field.name, 'comment']}
                    label={t('quotes.form.service.comment')}
                  >
                    <TextArea rows={5} />
                  </Form.Item>
                  <Form.Item
                    {...field}
                    key={[field.name, 'comment_customer']}
                    name={[field.name, 'comment_customer']}
                    label={t('quotes.form.service.comment_customer')}
                  >
                    <TextArea rows={5} />
                  </Form.Item>
                </Card>
              ))}
              <Form.Item>
                <Button
                  type="primary"
                  onClick={() => add()}
                  style={{ marginTop: '8px' }}
                >
                  {t('quotes.form.service.add_button')}
                </Button>
              </Form.Item>
            </>
          )}
        </Form.List>
      )
    }
  ];
  return { servicesFields, isFieldsLoading };
};

export default useServicesFields;
