import { useState, useEffect, useRef } from 'react';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';
import { Spin, Switch, DatePicker, Flex, Select } from 'antd';
import { useTranslation } from 'react-i18next';
import { useAuthContext } from '../../contexts/AuthContext';
import { useErrorMessage } from '../../utils/errorMessage';
import { ContentCustom, ListResource } from '../../components';
import { useColumns } from '../quittances/ShowCards/paymentsColumns';
import { headers } from './headers';
import PaymentModal from '../quittances/ShowCards/PaymentModal';
import ReconcileCreditModal from './ReconcileCreditModal';
import { showSuccessMessage } from '../../utils/showSuccessMessage';
import { useFormatter } from '../../utils/formatter';

const { RangePicker } = DatePicker;
const { Option } = Select;

/**
 * @component
 * @name ListPayments
 * @description Displays the list of payments
 * @returns {component} ListPayments component
 */
export const ListPayments = ({ quittance }) => {
  const { t } = useTranslation();
  const { formattedData } = useFormatter('payments');
  const { dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();
  const [isLoading, setIsLoading] = useState(false);
  const [enums, setEnums] = useState();
  const [customers, setCustomers] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [purpose, setPurpose] = useState(null);
  const [idFromOverlay, setIdFromOverlay] = useState(null);
  const [bankReconciliations, setBankReconciliations] = useState(null);
  const [forceRefresh, setForceRefresh] = useState(false);
  const [isCredit, setIsCredit] = useState(false);
  const [isReconcileCreditModal, setIsReconcileCreditModal] = useState(false);
  const [filterDate, setFilterDate] = useState([]);
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const isMounted = useRef(true);

  const openModal = () => {
    setPurpose('create');
    setIdFromOverlay();
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setPurpose(null);
    setIsModalOpen(false);
    setIsReconcileCreditModal(false);
  };

  const onEdit = (idPayment) => {
    setPurpose('edit');
    setIdFromOverlay(idPayment);
    setIsModalOpen(true);
  };

  const onArchived = async (idPayment) => {
    try {
      await dispatchAPI('DELETE', {
        url: `/payments/${idPayment}`
      });
      setForceRefresh(!forceRefresh);
      showSuccessMessage(t, 'payements', 'archive');
    } catch (error) {
      message(error);
    }
  };

  const onReconcile = (idCredit) => {
    setPurpose('edit');
    setIdFromOverlay(idCredit);
    setIsReconcileCreditModal(true);
  };

  const columns = useColumns(
    enums,
    'payments',
    {
      onEdit,
      onArchived,
      onReconcile
    },
    isCredit
  );

  const getEnums = async () => {
    try {
      const { data } = await dispatchAPI('GET', { url: '/payments/enums' });
      setEnums(data);
    } catch (e) {
      message(e);
    }
  };

  const getBankReconciliation = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/bankreconciliations`
      });
      setBankReconciliations(data);
    } catch (e) {
      message(e);
    }
  };

  const getCustomers = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/customers?sort=ps_number`
      });
      setCustomers(data);
    } catch (e) {
      message(e);
    }
  };

  const customNavigate = () => {
    setPurpose(null);
    setIdFromOverlay(null);
    setIsModalOpen(false);
    setIsReconcileCreditModal(false);
    setForceRefresh(!forceRefresh);
  };

  const onsearch = (input, option) =>
    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;

  const handleRangeChange = (dates) => {
    if (dates && dates.length === 2) {
      const startOfMonth = dates[0].startOf('month');
      const endOfMonth = dates[1].endOf('month');
      setFilterDate([startOfMonth, endOfMonth]);
    } else {
      setFilterDate(null);
    }
  };

  useEffect(() => {
    isMounted.current = true;
    (async () => {
      setIsLoading(true);
      await getCustomers();
      await getEnums();
      await getBankReconciliation();
      if (isMounted.current) {
        setIsLoading(false);
      }
    })();
    return () => {
      isMounted.current = false;
    };
  }, []);

  return (
    <ContentCustom>
      {isReconcileCreditModal && (
        <ReconcileCreditModal
          isModalOpen={isReconcileCreditModal}
          closeModal={closeModal}
          idFromOverlay={idFromOverlay}
          customNavigate={customNavigate}
          purpose={purpose}
          quittance={quittance}
          enums={enums}
          bankReconciliations={bankReconciliations}
          customers={customers}
        />
      )}
      {isModalOpen && (
        <PaymentModal
          isModalOpen={isModalOpen}
          closeModal={closeModal}
          idFromOverlay={idFromOverlay}
          customNavigate={customNavigate}
          purpose={purpose}
          enums={enums}
          bankReconciliations={bankReconciliations}
          customers={customers}
        />
      )}
      <Spin spinning={isLoading}>
        <ListResource
          resourceName="payments"
          contentCustomStyle={{ margin: 0, padding: 0 }}
          populate="imported_by,quittance,customer"
          columns={columns}
          headers={headers}
          formatter={formattedData}
          resourceModelName="Payments"
          extraQuery={`type=${isCredit ? 'CREDIT' : 'CLASSIC'}${
            filterDate?.length
              ? `&operation_date>=${dayjs(
                  new Date(filterDate[0]).setHours(0, 0, 0, 0)
                ).toISOString()}&operation_date<=${dayjs(
                  new Date(filterDate[1]).setHours(23, 59, 59, 999)
                ).toISOString()}`
              : ''
          }${selectedCustomer ? `&customer=${selectedCustomer}` : ''}`}
          withImportButton={false}
          withCreateButtonText={t('buttons.create_custom.payments')}
          archivedButton={false}
          showAction={false}
          editAction={false}
          onDoubleClickAction={false}
          deleteAction={false}
          onClickAdd={openModal}
          forceRefresh={forceRefresh}
          scroll={{ x: 1700 }}
          flexWrap
          extraButtons={
            <Switch
              value={isCredit}
              onChange={() => setIsCredit(!isCredit)}
              checkedChildren={t(`payments.tags.CREDIT`)}
              unCheckedChildren={t(`payments.tags.CLASSIC`)}
            />
          }
          extraFilters={
            <Flex gap={8}>
              <RangePicker
                format="DD-MM-YYYY"
                picker="month"
                value={filterDate}
                onChange={handleRangeChange}
                placeholder={[
                  'Sélectionner le mois de début',
                  'Sélectionner le mois de fin'
                ]}
              />
              <Select
                style={{ width: 250 }}
                showSearch
                allowClear
                loading={isLoading}
                placeholder="Choisissez un client..."
                filterOption={(input, option) => onsearch(input, option)}
                onSelect={(value) => {
                  setSelectedCustomer(value);
                }}
                onClear={() => {
                  setSelectedCustomer(null);
                }}
              >
                {(customers || [])?.map((customer) => (
                  <Option key={customer._id} value={customer._id}>
                    {`${customer?.company_name} (${customer?.ps_number})`}
                  </Option>
                ))}
              </Select>
            </Flex>
          }
        />
      </Spin>
    </ContentCustom>
  );
};

ListPayments.propTypes = {
  quittance: PropTypes.shape({
    _id: PropTypes.string
  })
};

ListPayments.defaultProps = {
  quittance: undefined
};
