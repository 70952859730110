import { Flex, Select } from 'antd';
import { SwitchModes } from '../SwitchModes.tsx';

export const monthAnnualHeader = (
  { value, onChange },
  extraHeader,
  modes,
  mode,
  setMode
) => {
  const selectStart = 0;
  const selectEnd = 12;
  const monthOptions = [];

  const localeData = value.localeData();
  const months = [];
  for (let i = 0; i < 12; i += 1) {
    const monthDate = value.clone().month(i);
    months.push(localeData.monthsShort(monthDate));
  }

  for (let index = selectStart; index < selectEnd; index += 1) {
    monthOptions.push(
      <Select.Option key={index}>{months[index]}</Select.Option>
    );
  }
  const month = value.month();

  const year = value.year();
  const options = [];
  for (let i = year - 2; i < year + 4; i += 1) {
    options.push(
      <Select.Option key={i} value={i} className="year-item">
        {i}
      </Select.Option>
    );
  }

  return (
    <Flex
      justify="space-between"
      gap="small"
      align="center"
      wrap="wrap"
      style={{ marginBottom: '20px' }}
    >
      {modes && <SwitchModes modes={modes} setMode={setMode} mode={mode} />}
      {extraHeader}
      <Flex justify="end" align="center" gap="small">
        <Select
          showSearch
          optionFilterProp="children"
          filterOption={(input, option) =>
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
          popupMatchSelectWidth={false}
          className="my-year-select"
          onChange={(newYear) => {
            const now = value.clone().year(newYear);
            onChange(now);
          }}
          value={String(year)}
        >
          {options}
        </Select>
        {mode === 'month' && (
          <Select
            showSearch
            optionFilterProp="children"
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            popupMatchSelectWidth={false}
            value={String(month)}
            onChange={(selectedMonth) => {
              let newValue = value.clone();
              newValue = newValue.month(parseInt(selectedMonth, 10));
              onChange(newValue);
            }}
          >
            {monthOptions}
          </Select>
        )}
      </Flex>
    </Flex>
  );
};
