import { Select, Form, Card, Button, Input, Upload } from 'antd';
import { CloseCircleOutlined, InboxOutlined } from '@ant-design/icons';
import { ColorSelector } from '../quality_control/ColorSelector';
import useInterventionContext from './InterventionContext';

const { Dragger } = Upload;
const { TextArea } = Input;

/**
 * Hook for the quality control fields.
 *
 * @hook
 * @param {Object} categories - The categories array
 * @param {Object} t - i18n translate function
 * @returns {Object} The quality control fields
 */
const useQualityControlFields = (categories, t) => {
  const {
    qualityControlFiles,
    setQualityControlFiles,
    selectedCategories,
    setSelectedCategories
  } = useInterventionContext();

  const handleCategoryChange = (value, fieldIndex) => {
    const newSelectedCategories = [...selectedCategories];
    newSelectedCategories[fieldIndex] = value;
    setSelectedCategories(newSelectedCategories);
  };

  const qualityControlFields = [
    {
      name: ['qualities'],
      noLabel: true,
      input: (
        <Form.List name="qualities" initialValue={[{}]}>
          {(fields, { add, remove }) => (
            <>
              {fields.map((field, index) => (
                <Card
                  key={field.name}
                  style={{ marginBottom: '10px', minWidth: 500 }}
                >
                  <Form.Item
                    {...field}
                    key={[field.name, 'category']}
                    name={[field.name, 'category']}
                    label={t('interventions.form.quality_control.category')}
                    rules={[{ required: true }]}
                  >
                    <Select
                      onChange={(value) => handleCategoryChange(value, index)}
                    >
                      {categories
                        ?.filter(
                          (category) =>
                            !selectedCategories.includes(category) ||
                            category === selectedCategories[index]
                        )
                        .map((category) => (
                          <Select.Option key={category}>
                            {t(
                              `interventions.form.quality_control.categories.${category}`
                            )}
                          </Select.Option>
                        ))}
                    </Select>
                  </Form.Item>
                  <CloseCircleOutlined
                    style={{
                      position: 'absolute',
                      top: '5px',
                      right: '5px',
                      fontSize: '16px',
                      color: 'red',
                      cursor: 'pointer'
                    }}
                    onClick={() => remove(field.name)}
                  />
                  <Form.Item
                    {...field}
                    key={[field.name, 'assessment']}
                    name={[field.name, 'assessment']}
                    label={t('interventions.form.quality_control.assessment')}
                  >
                    <ColorSelector isGrade />
                  </Form.Item>
                  <Form.Item
                    {...field}
                    key={[field.name, 'comment']}
                    name={[field.name, 'comment']}
                    label={t('interventions.form.quality_control.comment')}
                  >
                    <TextArea rows={2} />
                  </Form.Item>
                  <Form.Item
                    {...field}
                    key={[field.name, 'files']}
                    name={[field.name, 'files']}
                    label={t('interventions.form.quality_control.files')}
                  >
                    <Dragger
                      onRemove={(file) => {
                        setQualityControlFiles({
                          ...qualityControlFiles,
                          [index]: qualityControlFiles[index]?.filter(
                            (f) => f._id !== file.id
                          )
                        });
                      }}
                      beforeUpload={(file) => {
                        setQualityControlFiles({
                          ...qualityControlFiles,
                          [index]: [...qualityControlFiles[index], file]
                        });
                        return false;
                      }}
                      fileList={qualityControlFiles[index]?.map((file) => {
                        if (file.lastModified) return file;
                        return {
                          uid: file.uid,
                          name: file.metadata.originalName,
                          status: 'done',
                          id: file._id
                        };
                      })}
                    >
                      <p className="ant-upload-drag-icon">
                        <InboxOutlined style={{ color: 'var(--textColor)' }} />
                      </p>
                      <p className="ant-upload-text">
                        {t('files.drag_or_drop')}
                      </p>
                    </Dragger>
                  </Form.Item>
                </Card>
              ))}
              <Form.Item>
                <Button type="primary" onClick={add} block>
                  {t('interventions.form.quality_control.add')}
                </Button>
              </Form.Item>
            </>
          )}
        </Form.List>
      )
    }
  ];
  return {
    qualityControlFields: qualityControlFields.map((f) => f)
  };
};

export default useQualityControlFields;
