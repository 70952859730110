import { useTranslation } from 'react-i18next';
import { Popover, Tag } from 'antd';
import { userPositions } from '../../utils/constants/tagColors';

export const useColumns = (enums) => {
  const { t } = useTranslation();

  const setColumnHeaderStyle = () => ({
    onHeaderCell: () => ({
      style: {
        whiteSpace: 'normal'
      }
    })
  });

  return [
    {
      title: t('internalGestion.form.position'),
      key: 'position',
      fixed: 'left',
      dataIndex: ['position'],
      render: (position) => (
        <Tag color={userPositions[position]}>
          {t(`users.positions.${position || 'N/A'}`)}
        </Tag>
      ),
      sorter: true,
      filters: enums?.positions?.map((p) => ({
        text: t(`users.positions.${p}`),
        value: p
      })),
      ...setColumnHeaderStyle()
    },
    {
      title: t('internalGestion.form.collaborator'),
      key: 'first_name',
      fixed: 'left',
      dataIndex: ['user'],
      sorter: true,
      render: (user) =>
        `${user?.first_name} ${user?.usage_last_name || user?.last_name || ''}`,
      ...setColumnHeaderStyle()
    },
    {
      title: t('internalGestion.form.hebdo_hours'),
      key: 'hebdo_hours',
      dataIndex: 'hebdo_hours',
      align: 'center',
      sorter: true,
      ...setColumnHeaderStyle()
    },
    {
      title: t('internalGestion.form.worked_hours'),
      key: 'worked_hours',
      dataIndex: 'worked_hours',
      align: 'center',
      sorter: true,
      ...setColumnHeaderStyle()
    },
    {
      title: t('internalGestion.form.sup_hours'),
      key: 'sup_hours',
      dataIndex: 'sup_hours',
      align: 'center',
      sorter: true,
      ...setColumnHeaderStyle()
    },
    {
      title: t('internalGestion.form.paid_absences'),
      key: 'paid_absences',
      dataIndex: 'paid_absences',
      align: 'center',
      sorter: true,
      ...setColumnHeaderStyle()
    },
    {
      title: t('internalGestion.form.unpaid_absences'),
      key: 'unpaid_absences',
      dataIndex: 'unpaid_absences',
      align: 'center',
      sorter: true,
      ...setColumnHeaderStyle()
    },
    {
      title: t('internalGestion.form.paid_vacations_taken'),
      key: 'paid_vacations_taken',
      dataIndex: 'paid_vacations_taken',
      align: 'center',
      sorter: true,
      ...setColumnHeaderStyle()
    },
    {
      title: t('internalGestion.form.paid_vacations_left'),
      key: 'paid_vacations_left',
      dataIndex: 'paid_vacations_left',
      align: 'center',
      sorter: true,
      render: (paid_vacations_left, data) =>
        data?.reported_day && data.reported_day !== 0 ? (
          <Popover
            content={`${t('internalGestion.form.reported_day')}${
              data.reported_day
            }`}
          >
            <span>{paid_vacations_left}</span>
          </Popover>
        ) : (
          <span>{paid_vacations_left}</span>
        ),
      ...setColumnHeaderStyle()
    },
    {
      title: t('internalGestion.form.solidarity_hours_done'),
      key: 'solidarity_hours_done',
      dataIndex: 'solidarity_hours_done',
      align: 'center',
      sorter: true,
      ...setColumnHeaderStyle()
    },
    {
      title: t('internalGestion.form.solidarity_hours_left'),
      key: 'solidarity_hours_left',
      dataIndex: 'solidarity_hours_left',
      align: 'center',
      sorter: true,
      ...setColumnHeaderStyle()
    }
  ];
};
