import { useState, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Modal, message as antdMessage } from 'antd';
import InterventionContextProvider from './InterventionContext';
import { CreateUpdateContainer } from '../../components/CreateUpdateContainer/CreateUpdateContainer';
import useFields from './mailFields';
import { useErrorMessage } from '../../utils/errorMessage';
import { useAuthContext } from '../../contexts/AuthContext';

const MailModal = ({
  isMailModalOpen,
  closeMailModal,
  selectedRowKeys,
  customNavigateMail,
  modelName,
  extraQuery,
  useCaseFilter,
  setForceActionsRefresh,
  forceActionsRefresh,
  contacts,
  interventionId,
  company
}) => {
  const { t } = useTranslation();
  const { actions } = InterventionContextProvider();
  const [formValues, setFormValues] = useState(null);
  const [templates, setTemplates] = useState([]);
  const [content, setContent] = useState('');
  const [footer, setFooter] = useState('');
  const { dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();
  const [isLoading, setIsLoading] = useState(false);
  const [isTemplateContentLoading, setIsTemplateContentLoading] =
    useState(false);
  const [customerEnums, setCustomerEnums] = useState([]);
  const [emailType, setEmailType] = useState(false);

  const getTemplates = useCallback(async () => {
    setIsLoading(true);
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/template-documents${extraQuery ? `/?${extraQuery}` : ''}`
      });
      setTemplates(data);
    } catch (e) {
      message(e);
    }
    setIsLoading(false);
  }, []);

  const getCompany = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/customers/enums`
      });
      setCustomerEnums(data);
    } catch (e) {
      message(e);
    }
  };

  const { fields, isFieldsLoading } = useFields(
    actions,
    selectedRowKeys,
    setContent,
    setFooter,
    footer,
    content,
    templates,
    isLoading,
    useCaseFilter,
    contacts,
    interventionId,
    formValues,
    customerEnums,
    company,
    emailType,
    setEmailType,
    isTemplateContentLoading
  );

  const config = {
    onCreateResource: {
      setBody: (data) => ({
        ...data
      })
    }
  };

  const handleSubmit = async () => {
    try {
      await dispatchAPI('PATCH', {
        url: `/actions/generate_document/${formValues?.template}/${formValues?.fses_selected}?modelName=${modelName}`,
        body: formValues
      });
      setForceActionsRefresh(!forceActionsRefresh);
      antdMessage.success(
        t(
          `mail.message.${
            formValues?.mail_or_email ? 'success_email' : 'success_mail'
          }`
        )
      );
      closeMailModal();
    } catch (e) {
      message(e);
    }
  };

  useEffect(() => {
    setIsLoading(true);
    (async () => {
      await getTemplates();
      await getCompany();
    })();
  }, []);

  useEffect(() => {
    if (formValues?.template) {
      setIsTemplateContentLoading(true);
    }
    if (formValues?.content) {
      setIsTemplateContentLoading(false);
    }
    if (formValues?.footer) {
      setIsTemplateContentLoading(false);
    }
  }, [formValues]);

  return (
    <Modal
      centered
      open={isMailModalOpen}
      onCancel={closeMailModal}
      width="80%"
      footer={false}
    >
      <CreateUpdateContainer
        fields={fields}
        loadingFields={isFieldsLoading}
        purpose="create"
        resource="mail"
        isOverlay
        customNavigate={customNavigateMail}
        config={config}
        setFormValues={setFormValues}
        needFormDependencies
        baseUrl="actions/generate_document"
        customSubmit={handleSubmit}
        submitLabel={t(`buttons.${emailType ? 'send' : 'save'}`)}
      />
    </Modal>
  );
};

MailModal.propTypes = {
  isMailModalOpen: PropTypes.bool.isRequired,
  closeMailModal: PropTypes.func.isRequired,
  selectedRowKeys: PropTypes.arrayOf(PropTypes.string),
  intervention: PropTypes.shape({
    _id: PropTypes.string
  }),
  customNavigateMail: PropTypes.func,
  modelName: PropTypes.string,
  extraQuery: PropTypes.string,
  useCaseFilter: PropTypes.string,
  setForceActionsRefresh: PropTypes.func,
  forceActionsRefresh: PropTypes.bool,
  contacts: PropTypes.arrayOf(PropTypes.shape({})),
  interventionId: PropTypes.string.isRequired,
  company: PropTypes.string
};

MailModal.defaultProps = {
  selectedRowKeys: [],
  intervention: null,
  customNavigateMail: null,
  modelName: null,
  extraQuery: null,
  useCaseFilter: null,
  setForceActionsRefresh: null,
  forceActionsRefresh: null,
  contacts: null,
  company: null
};

export default MailModal;
