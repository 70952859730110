import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Divider } from 'antd';
import { useAuthContext } from '../../contexts/AuthContext';
import { useErrorMessage } from '../../utils/errorMessage';
import useColumns from './columns';
import { ListResource } from '../../components';
import QuittanceContext from '../quittances/QuittanceContext';
import { showSuccessMessage } from '../../utils/showSuccessMessage';

/**
 * Transfers component for listing, downloading, and managing transfers.
 *
 * This component uses the `ListResource` to display a list of transfers,
 * with functionality to update, download, and delete transfers.
 *
 * @returns {React.Component} The Transfers component.
 */
const Transfers = () => {
  const { t } = useTranslation();
  const { dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();
  const { deleteTransfer, downloadTransfer } = QuittanceContext();
  const [isDownloading, setIsDownloading] = useState({});
  const [forceRefresh, setForceRefresh] = useState(false);

  const updateTransfer = async (id, body) => {
    try {
      setForceRefresh(false);
      await dispatchAPI('PATCH', { url: `/transfer/${id}`, body });
      setForceRefresh(true);
      showSuccessMessage(t, 'transfer', 'update');
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const downloadContent = (key, record) => (
    <>
      <Button
        onClick={async () => {
          if (!isDownloading[key]) {
            setIsDownloading({ ...isDownloading, [key]: true });
            await downloadTransfer(key, 'csv', () =>
              setIsDownloading({ ...isDownloading, [key]: false })
            );
          }
        }}
      >
        CSV
      </Button>
      <Divider type="vertical" />
      <Button
        onClick={async () => {
          if (!isDownloading[key]) {
            setIsDownloading({ ...isDownloading, [key]: true });
            await downloadTransfer(
              key,
              'xml',
              () => setIsDownloading({ ...isDownloading, [key]: false }),
              record
            );
          }
        }}
      >
        XML
      </Button>
    </>
  );

  const columns = useColumns(
    updateTransfer,
    downloadContent,
    deleteTransfer,
    setForceRefresh,
    isDownloading
  );

  return (
    <ListResource
      resourceName="transfers"
      resourceModelName="Transfers"
      withCreateButtonText={t('buttons.create_custom.transfers')}
      withUploadButton={false}
      columns={columns}
      customActionColumn
      forceRefresh={forceRefresh}
    />
  );
};

export default Transfers;
