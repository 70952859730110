import { ColorSelector } from '../../ColorSelector';

/**
 * Custom hook to get the list content for the test
 *
 * @hook
 * @param {object} test - Test data object
 * @returns {Array} Array of objects containing label, span, and content for each field
 */
export const useListContent = (test) => [
  {
    label: 'quality.form.test.grade',
    span: 12,
    content: test?.grade,
    type: 'test'
  },
  {
    label: 'quality.form.test.assessment',
    span: 12,
    content: <ColorSelector value={test?.assessment} />,
    type: 'test'
  }
];
