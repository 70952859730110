import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import {
  EditOutlined,
  ContainerOutlined,
  WarningOutlined,
  EyeOutlined
} from '@ant-design/icons';
import { Button, Popconfirm, Divider } from 'antd';
import { routes } from '../../../utils/constants/adminRoutes';
import { formatValue } from '../../../utils/formatNumber';

/**
 * Custom hook for generating table columns for displaying quittances data.
 *
 * @hook
 * @param {object} enums - Enumerations data for contract properties
 * @param {Array} psMicro - List of micro ps data for popover
 * @param {Array} psMacro - List of macro ps data for popover
 * @param {Array} customerEnums - List of customerEnums data for popover
 * @returns {Array} Array of table column objects
 */

export const useColumns = (options, status) => {
  const { t } = useTranslation();
  return [
    {
      title: t('quittances.columns.details.type'),
      key: 'type',
      dataIndex: 'type',
      render: (type) => t(`quotes.tags.${type}`)
    },
    {
      title: t('quittances.columns.details.title'),
      key: 'title',
      dataIndex: 'title'
    },
    {
      title: t('quittances.columns.details.total_hours'),
      key: 'total_hours',
      dataIndex: 'total_hours',
      render: (total_hours) =>
        total_hours ? `${parseFloat(Math.round(total_hours * 2) / 2)}h` : 'N/A'
    },
    {
      title: t('quittances.columns.details.total'),
      key: 'total',
      dataIndex: 'total',
      render: (total) => total && `${formatValue(total, 'currency', true)}`
    },
    {
      title: t('quittances.columns.details.total_with_taxes'),
      key: 'total_with_taxes',
      dataIndex: 'total_with_taxes',
      render: (total_with_taxes) =>
        total_with_taxes && `${formatValue(total_with_taxes, 'currency', true)}`
    },
    {
      title: t('quittances.columns.details.fee_discount'),
      key: 'fee_discount',
      dataIndex: 'fee_discount',
      render: (fee_discount) => `${fee_discount}%`
    },
    {
      title: t('quittances.columns.details.discounts'),
      key: 'discounts',
      dataIndex: 'discounts',
      render: (discounts) => {
        const totalDiscount = (discounts || []).reduce(
          (acc, discount) => acc + (discount?.amount || 0),
          0
        );
        return `${formatValue(totalDiscount, 'currency', true)}`;
      }
    },
    {
      title: t('quittances.columns.details.allocation'),
      key: 'allocation'
    },
    {
      title: t('quittances.columns.details.accounting_account'),
      key: 'accounting_account'
    },
    {
      key: 'action',
      align: 'right',
      render: (record) => (
        <>
          {!record?.from_scratch && (
            <Link to={`${routes.SERVICES}/show/${record?.service}`}>
              <EyeOutlined style={{ fontSize: 18 }} />
            </Link>
          )}
          {record?.from_scratch && status === 'IN_PROGRESS' && (
            <>
              <Divider type="vertical" />

              <Button type="ghost" onClick={() => options.onEdit(record)}>
                <EditOutlined style={{ fontSize: 18 }} />
              </Button>
              <Divider type="vertical" />
              <Popconfirm
                title={t('datatable.column.action.delete.title')}
                okText={t('datatable.column.action.delete.ok')}
                okButtonProps={{ type: 'danger' }}
                cancelText={t('datatable.column.action.delete.cancel')}
                onConfirm={() => options.onArchived(record._id)}
                icon={<WarningOutlined />}
              >
                <ContainerOutlined
                  style={{ fontSize: 18, color: 'red' }}
                  type="delete"
                />
              </Popconfirm>
            </>
          )}
        </>
      )
    }
  ];
};
