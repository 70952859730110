import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { Row, Card, Col, Select } from 'antd';
import { useAuthContext } from '../../../contexts/AuthContext';
import { useErrorMessage } from '../../../utils/errorMessage';
import OccupancyChartContextProvider from '../OccupancyChartContext';
import GtpContent from './GtpContent';
import { ContentCustom } from '../../../components';

const { Option } = Select;

const ListGtps = ({ forceCalendarRefresh }) => {
  const { t } = useTranslation();
  const { dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();
  const {
    leaders,
    softwares,
    selectedLeaders,
    setSelectedLeaders,
    occupanciesSelectedGtps,
    setOccupanciesSelectedGtps,
    selectedSoftwares,
    setSelectedSoftwares,
    startWeek,
    forceRefresh
  } = OccupancyChartContextProvider();

  const [gtpsWithInterventions, setGtpsWithInterventions] = useState([]);

  const getGtpsWithInterventions = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `employments/interventions?populate=user&leader=${selectedLeaders}${
          occupanciesSelectedGtps?.length
            ? `&_id=${occupanciesSelectedGtps}`
            : ''
        }${
          selectedSoftwares.length ? `&used_softwares=${selectedSoftwares}` : ''
        }&period=${[
          dayjs(startWeek)
            .add(1, 'day')
            .startOf('day')
            .format('YYYY-MM-DDTHH:mm:ss.SSS[Z]'),
          dayjs(startWeek)
            .add(7, 'day')
            .endOf('day')
            .format('YYYY-MM-DDTHH:mm:ss.SSS[Z]')
        ]}`
      });
      setGtpsWithInterventions(data);
    } catch (error) {
      message(error);
    }
  };

  const onsearch = (input, option) => {
    const result = option?.children.toLowerCase();
    return result.includes(input.toLowerCase());
  };

  useEffect(() => {
    (async () => {
      const shouldFetch =
        selectedLeaders.length ||
        occupanciesSelectedGtps?.length ||
        selectedSoftwares.length ||
        (!selectedLeaders.length &&
          !occupanciesSelectedGtps?.length &&
          !selectedSoftwares.length);
      if (shouldFetch) await getGtpsWithInterventions();
    })();
  }, [
    selectedLeaders,
    occupanciesSelectedGtps,
    selectedSoftwares,
    forceRefresh,
    forceCalendarRefresh
  ]);

  return (
    <ContentCustom style={{ padding: 0, margin: '24px 0' }}>
      <Card bordered={false} className="list-gtp-card">
        <Row gutter={[16, 16]}>
          <Col xs={24} xxl={4}>
            <Row gutter={[8, 16]}>
              <Col xs={24} md={12} lg={8} xxl={24}>
                <Select
                  style={{ width: '100%' }}
                  allowClear
                  showSearch
                  mode="multiple"
                  placeholder={t('occupancies.list.placeholder_leader')}
                  filterOption={(input, option) => onsearch(input, option)}
                  onSelect={(value) => {
                    setSelectedLeaders([...selectedLeaders, value]);
                  }}
                  onDeselect={(value) =>
                    setSelectedLeaders((prevStatus) =>
                      prevStatus.filter((item) => item !== value)
                    )
                  }
                  onClear={() => {
                    setSelectedLeaders([]);
                  }}
                >
                  {(leaders || []).map((leader) => (
                    <Option key={leader._id} value={leader._id}>
                      {`${leader.first_name} ${
                        leader?.usage_last_name || leader?.last_name
                      }`}
                    </Option>
                  ))}
                </Select>
              </Col>
              <Col xs={24} md={12} lg={8} xxl={24}>
                <Select
                  style={{ width: '100%' }}
                  allowClear
                  showSearch
                  mode="multiple"
                  placeholder={t('occupancies.list.placeholder_gtp')}
                  filterOption={(input, option) => onsearch(input, option)}
                  onSelect={(value) => {
                    setOccupanciesSelectedGtps([
                      ...occupanciesSelectedGtps,
                      value
                    ]);
                  }}
                  onDeselect={(value) =>
                    setOccupanciesSelectedGtps((prevStatus) =>
                      prevStatus.filter((item) => item !== value)
                    )
                  }
                  onClear={() => {
                    setOccupanciesSelectedGtps([]);
                  }}
                >
                  {(gtpsWithInterventions || [])
                    .sort((a, b) =>
                      a?.user?.first_name
                        ?.toLowerCase()
                        ?.localeCompare(b?.user?.first_name?.toLowerCase())
                    )
                    .map((gtp) => (
                      <Option key={gtp._id} value={gtp._id}>
                        {`${gtp?.user?.first_name} ${
                          gtp?.user?.usage_last_name || gtp?.user?.last_name
                        }`}
                      </Option>
                    ))}
                </Select>
              </Col>
              <Col xs={24} md={12} lg={8} xxl={24}>
                <Select
                  style={{ width: '100%' }}
                  allowClear
                  showSearch
                  mode="multiple"
                  placeholder={t('occupancies.list.placeholder_software')}
                  filterOption={(input, option) => onsearch(input, option)}
                  onSelect={(value) => {
                    setSelectedSoftwares([...selectedSoftwares, value]);
                  }}
                  onDeselect={(value) =>
                    setSelectedSoftwares((prevStatus) =>
                      prevStatus.filter((item) => item !== value)
                    )
                  }
                  onClear={() => {
                    setSelectedSoftwares([]);
                  }}
                >
                  {(softwares || []).map((sofware) => (
                    <Option key={sofware._id} value={sofware._id}>
                      {`${sofware.title}`}
                    </Option>
                  ))}
                </Select>
              </Col>
            </Row>
          </Col>
          <Col xs={24} xxl={20}>
            <Row gutter={[16, 16]}>
              {(gtpsWithInterventions || []).map((gtp) => (
                <GtpContent key={gtp._id} gtp={gtp} t={t} />
              ))}
            </Row>
          </Col>
        </Row>
      </Card>
    </ContentCustom>
  );
};

export default ListGtps;

ListGtps.propTypes = {
  forceCalendarRefresh: PropTypes.number.isRequired
};
