import { Table, Button, Row, Col } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import useConfigurationContext from './ConfigurationContext';

export const dataFormatter = (
  t,
  items,
  subCollapse,
  subResourceName = '',
  resourceName = '',
  keyprefix = '',
  catIndex = undefined
) => {
  const { setModalTitle, setIsModalOpen, setModalPath } =
    useConfigurationContext();
  if (!items) return [];
  let toMap = [];
  const editableTitleModel = ['psmacro', 'rejectiontype'];

  const isCategory = catIndex !== undefined;

  if (isCategory) toMap = items[resourceName];
  else toMap = items;
  const formattedData = toMap?.map((item, index) => ({
    ...item,
    key: `${keyprefix}${index?.toString()}`,
    id: item._id || item.id || '',
    label: (
      <Row align="middle">
        <Col flex="auto">{item.title}</Col>
        {editableTitleModel.includes(resourceName) && (
          <>
            {resourceName === 'psmacro' && (
              <Col flex="end">(Objectif: {item?.objective || 0})</Col>
            )}
            <Col flex="end">
              <Button
                onClick={(event) => {
                  event.stopPropagation();
                  setModalPath(`${resourceName}/${item._id}`);
                  setModalTitle(resourceName);
                  setIsModalOpen(true);
                }}
                icon={<EditOutlined style={{ fontSize: 18 }} />}
                type="text"
              />
            </Col>
          </>
        )}
      </Row>
    ),
    subCollapse: false,
    resourceName: isCategory ? resourceName : subResourceName,
    subResourceName: isCategory ? subResourceName : '',
    children: subCollapse
      ? dataFormatter(
          t,
          item.children,
          false,
          isCategory ? subResourceName : '',
          '',
          `${keyprefix}${index?.toString()}-`
        )
      : item.children,
    displayLabel: item.label
  }));

  if (isCategory) {
    return {
      key: `CAT-${catIndex?.toString()}`,
      label: t(`configurations.titles.${resourceName}`),
      subCollapse,
      resourceName,
      subResourceName,
      children: formattedData
    };
  }
  return formattedData;
};

export const indexFinder = (array, item) =>
  array.findIndex((el) => el === item);

export const formatToJsx = (item, columns) => (
  <Table dataSource={item} columns={columns} pagination={false} bordered />
);
