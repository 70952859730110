export const headers = [
  {
    label: 'company',
    key: 'customer.company'
  },
  {
    label: 'customer',
    key: 'customer.status'
  },
  {
    label: 'code',
    key: 'code'
  },
  {
    label: 'status',
    key: 'status'
  },
  {
    label: 'ps_number',
    key: 'ps_number'
  },
  {
    label: 'company_name',
    key: 'customer.company_name'
  },
  {
    label: 'micro_ps',
    key: 'micro_ps.title'
  },
  {
    label: 'created_at',
    key: 'created_at'
  },
  {
    label: 'quote_accepted',
    key: 'quote_accepted'
  },
  {
    label: 'odm_valid',
    key: 'odm_valid'
  },
  {
    label: 'sale',
    key: 'sale.first_name'
  },
  {
    label: 'sale',
    key: 'sale.last_name'
  }
];
