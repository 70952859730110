import { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Card,
  Flex,
  Upload,
  Form,
  Row,
  Button,
  List,
  Divider,
  Popconfirm
} from 'antd';
import {
  DownloadOutlined,
  EditOutlined,
  EyeOutlined,
  DeleteOutlined,
  WarningOutlined
} from '@ant-design/icons';
import {
  CameraOutlined,
  CloseOutlined,
  CheckOutlined
} from '@ant-design/icons/lib';
import { useTranslation } from 'react-i18next';
import { useAuthContext } from '../../../contexts/AuthContext';
import { useErrorMessage } from '../../../utils/errorMessage';
import { tailFormItemLayout } from '../../../utils/constants/formLayout';
import InterventionContextProvider from '../InterventionContext';
import { useDownloadDocument } from '../../../utils/downloadDoc';
import { showSuccessMessage } from '../../../utils/showSuccessMessage';

const { Dragger } = Upload;

export const Documents = ({ data, hasAccess }) => {
  const { t } = useTranslation();
  const { message } = useErrorMessage();
  const { downloadDocument, viewDocument } = useDownloadDocument();
  const { dispatchAPI } = useAuthContext();
  const [form] = Form.useForm();
  const [editMode, setEditMode] = useState(false);
  const { forceRefresh, setForceRefresh, deleteFile } =
    InterventionContextProvider();
  const [fileList, setFileList] = useState([]);

  const customNavigateAction = () => {
    setEditMode(false);
    setForceRefresh(!forceRefresh);
  };

  const updateDocuments = async () => {
    try {
      const formData = new FormData();
      fileList.forEach((file) => {
        formData.append('files', file);
      });

      await dispatchAPI('POST', {
        url: `/interventions/upload/${data?._id}`,
        body: formData
      });
      customNavigateAction();
      showSuccessMessage(t, 'document', 'update');
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const handleSubmit = () => {
    updateDocuments();
  };

  const handleCancel = () => {
    setEditMode(false);
    setFileList([]);
  };

  const draggerProps = {
    onRemove: (file) => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);
    },
    beforeUpload: (file) => {
      const fileExtension = file.name.split('.').pop();
      if (
        fileExtension === 'png' ||
        fileExtension === 'PNG' ||
        fileExtension === 'jpg' ||
        fileExtension === 'JPG'
      ) {
        setFileList([...fileList, file]);
      } else {
        message('not_png_jpg');
        return true;
      }
      return false;
    },
    fileList
  };

  return (
    <Card title={t('interventions.show.files.title')}>
      <Flex justify="space-between">
        {!editMode && hasAccess('edit') && (
          <EditOutlined
            style={{
              color: 'var(--userColor)',
              fontSize: 18
            }}
            onClick={() => data?.status === 'OPEN' && setEditMode(true)}
          />
        )}
      </Flex>
      {!editMode ? (
        <List>
          {(data?.files || []).map((file) => (
            <List.Item>
              <Row justify="space-between" style={{ width: '100%' }}>
                <p>{file?.metadata?.originalName}</p>
                <Row>
                  <EyeOutlined onClick={() => viewDocument(file)} />
                  <Divider type="vertical" />
                  <DownloadOutlined onClick={() => downloadDocument(file)} />
                  <Divider type="vertical" />
                  <Popconfirm
                    title={t('files.delete.title')}
                    okText={t('files.delete.ok')}
                    okButtonProps={{ danger: 'true' }}
                    cancelText={t('files.delete.cancel')}
                    onConfirm={() => deleteFile(file._id)}
                    icon={<WarningOutlined />}
                  >
                    <DeleteOutlined />
                  </Popconfirm>
                </Row>
              </Row>
            </List.Item>
          ))}
        </List>
      ) : (
        <Form form={form} onFinish={handleSubmit}>
          <Form.Item name="documents">
            <Dragger {...draggerProps}>
              <p className="ant-upload-drag-icon">
                <CameraOutlined style={{ color: 'var(--textColor)' }} />
              </p>
              <p className="ant-upload-text">{t('files.drag_or_drop')}</p>
            </Dragger>
          </Form.Item>
          <Form.Item {...tailFormItemLayout}>
            <Row justify="end">
              <Button
                style={{ margin: '0 8px' }}
                type="link"
                danger
                onClick={handleCancel}
              >
                {`${t('buttons.cancel')} `}
                <CloseOutlined />
              </Button>
              <Button
                type="primary"
                htmlType="submit"
                disabled={fileList.length === 0}
              >
                {`${t('buttons.save')} `}
                <CheckOutlined />
              </Button>
            </Row>
          </Form.Item>
        </Form>
      )}
    </Card>
  );
};

Documents.propTypes = {
  data: PropTypes.shape({
    files: PropTypes.arrayOf(PropTypes.string),
    _id: PropTypes.string,
    status: PropTypes.string
  }),
  hasAccess: PropTypes.func.isRequired
};

Documents.defaultProps = {
  data: undefined
};
