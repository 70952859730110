/**
 * Get key by value from object
 *
 * @function
 * @param {object} object - Object to search
 * @param {string} value - Value to search
 * @returns {string} Key of the value
 */
export const getKeyByValue = (object, value) =>
  Object.keys(object).find((key) => object[key] === value);

/**
 * Check if an object is empty.
 *
 * @function isEmptyObject
 * @param {Object} obj - The object to check.
 * @returns {boolean} - `true` if the object is empty, otherwise `false`.
 */
export const isEmptyObject = (obj) =>
  obj && Object.keys(obj).length === 0 && obj.constructor === Object;
