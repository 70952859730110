import { Select, Input } from 'antd';
import { useTranslation } from 'react-i18next';
import QuoteContextProvider from './QuoteContext';

/**
 * Custom hook for retrieving and managing technique-related fields for user data.
 *
 * @hook
 * @returns {object} An object containing technique fields and loading state
 * @property {Array} techniqueFields - An array of technique-related fields
 * @property {boolean} isFieldsLoading - A boolean indicating whether the fields are loading
 */

const { Option } = Select;

const useInformationsFields = (
  purpose,
  setFormValues,
  formValues,
  psNumber,
  user
) => {
  const { t } = useTranslation();
  const { customers, psMicro, sales, customerEnums } = QuoteContextProvider();

  const onSearch = (input, option) => {
    const childrenText = Array.isArray(option.children)
      ? option.children.join(' ')
      : option.children;
    return childrenText.toLowerCase().includes(input.toLowerCase());
  };

  const informationsFields = [
    {
      name: ['ps_number'],
      rules: [{ required: true }],
      initialValue: psNumber,
      input: (
        <Select
          allowClear
          showSearch
          filterOption={(input, option) => onSearch(input, option)}
          onSelect={(value) =>
            setFormValues({
              ...formValues,
              customer: customers.find((c) => c.ps_number === value)
            })
          }
        >
          {(customers || []).map((cust) => (
            <Option key={cust._id} value={cust?.ps_number}>
              {cust?.ps_number} {cust?.company_name}
            </Option>
          ))}
        </Select>
      )
    },
    {
      name: ['status_customer'],
      rules: [{ required: true }],
      input: (
        <Select
          showSearch
          disabled
          options={customerEnums?.status?.map((item) => ({
            label: t(`customers.status.${item}`),
            value: item
          }))}
        />
      )
    },
    {
      name: ['company_customer'],
      rules: [{ required: true }],
      input: (
        <Select
          showSearch
          disabled
          options={customerEnums?.status?.map((item) => ({
            label: t(`customers.status.${item}`),
            value: item
          }))}
        />
      )
    },
    {
      name: ['company_name'],
      rules: [{ required: true }],
      input: <Input disabled />
    },
    {
      name: ['social_reason'],
      input: <Input disabled />
    },
    {
      name: ['micro_ps'],
      rules: [{ required: true }],
      input: (
        <Select
          showSearch
          disabled
          options={psMicro?.map((item) => ({
            label: item.title,
            value: item._id
          }))}
        />
      )
    },
    {
      name: ['sale'],
      rules: [{ required: true }],
      initialValue: user._id,
      input: (
        <Select
          showSearch
          disabled
          options={sales?.map((item) => ({
            label: `${item.first_name} ${
              item.usage_last_name || item.last_name
            }`,
            value: item._id
          }))}
        />
      )
    },
    {
      name: ['customer'],
      hidden: true
    }
  ];
  return { informationsFields };
};

export default useInformationsFields;
