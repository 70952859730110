import { Route, Routes } from 'react-router-dom';
import { Configurations } from '../components/Configurations/Configurations';
import { ConfigurationContextProvider } from '../components/Configurations/ConfigurationContext';
import { ConfigurationContextConstProvider } from '../components/Configurations/ConfigurationContextConst';

const ConfigurationRouter = () => (
  <ConfigurationContextConstProvider>
    <ConfigurationContextProvider>
      <Routes>
        <Route index element={<Configurations />} />
      </Routes>
    </ConfigurationContextProvider>
  </ConfigurationContextConstProvider>
);

export default ConfigurationRouter;
