import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Card, DatePicker, Flex } from 'antd';
import moment from 'moment';
import dayjs from 'dayjs';
import { ListResource } from '../../components/ListResource/ListResource';
import useHomeContext from './HomeContext';
import { useColumns } from './columns';
import { selectFormat } from '../customers/CRUDFields/utils';

/**
 * Component to display a list of badging information.
 *
 * @component
 * @returns {JSX.Element} JSX element representing the list of badging data.
 */

export const ListBadging = ({ user }) => {
  const { t } = useTranslation();
  const { positions, leaders, companies, forceRefreshList } = useHomeContext();
  const [date, setDate] = useState(moment().startOf('day'));
  const [leader, setLeader] = useState(null);
  const isAdmin = user.role.split(':')[0] === 'admins';
  const columns = useColumns(positions, companies, date, isAdmin);

  return (
    <Card title={t('home.tabs.titles.badgeuse')}>
      <ListResource
        withPageHeader={false}
        contentCustomStyle={{ margin: 0, padding: 0 }}
        resourceName="workhours"
        dataToFetch="workhours/show"
        forceRefresh={forceRefreshList}
        columns={columns}
        populate="user.employments"
        extraQuery={`date=${date}${leader ? `&leader=${leader}` : ''}`}
        resourceModelName="Workhours"
        deleteAction={false}
        editAction={false}
        showAction={false}
        withCreateButton={false}
        withUploadButton={false}
        scroll={{ x: 1700 }}
        extraFilters={
          <Flex gap={8} align="center">
            <DatePicker
              format="DD-MM-YYYY"
              onChange={(data) =>
                setDate(moment(data?.toString()).startOf('day'))
              }
              defaultValue={dayjs(date)}
              style={{ width: 200 }}
            />
            {selectFormat(t, leaders, {
              allowClear: true,
              onChange: (data) => setLeader(data),
              optionsHaveID: true,
              style: {
                width: 200
              },
              render: (l) =>
                `${l.first_name} ${l.usage_last_name || l.last_name}`
            })}
          </Flex>
        }
      />
    </Card>
  );
};

ListBadging.propTypes = {
  user: PropTypes.shape({
    role: PropTypes.string
  }).isRequired
};
