import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Card, Row, Col, Typography } from 'antd';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer
} from 'recharts';
import StatisticsContextProvider from './StatisticsContext';
import { graphcolors } from '../../utils/constants/tagColors';
import { CustomSpin } from '../../components/CustomSpin/CustomSpin';

const { Title } = Typography;

/**
 * Customized label component for the LineChart.
 * @module CustomizedLabel
 * @param {Object} props - Component props
 * @param {number} props.x - X coordinate of the label.
 * @param {number} props.y - Y coordinate of the label.
 * @param {string} props.stroke - Stroke color of the label.
 * @param {(number|string)} props.value - Value of the label.
 * @returns {JSX.Element} React component
 */
const CustomizedLabel = ({ x, y, stroke, value }) => (
  <text x={x} y={y} dy={-4} fill={stroke} fontSize={10} textAnchor="middle">
    {value}
  </text>
);

/**
 * Customized axis tick component for the LineChart.
 * @module CustomizedAxisTick
 * @param {Object} props - Component props
 * @param {number} props.x - X coordinate of the tick.
 * @param {number} props.y - Y coordinate of the tick.
 * @param {Object} props.payload - Tick payload containing value.
 * @param {string} props.payload.value - Value of the tick, representing month.
 * @returns {JSX.Element} React component
 */
const CustomizedAxisTick = ({ x, y, payload }) => {
  const { t } = useTranslation();
  return (
    <g transform={`translate(${x},${y})`}>
      <text
        x={0}
        y={0}
        dy={16}
        textAnchor="end"
        fill="#666"
        transform="rotate(-35)"
      >
        {t(`statistics.months.${payload.value}`)}
      </text>
    </g>
  );
};

/**
 * React component for rendering GTP (Gestionnaire) statistics chart.
 * @module ChartGtp
 * @returns {JSX.Element} React component
 */

export const ChartGtp = () => {
  const { t } = useTranslation();
  const { chartDatas, isChartsLoading } = StatisticsContextProvider();

  return (
    <Card title={t('statistics.titles.fse')}>
      {isChartsLoading ? (
        <CustomSpin />
      ) : (
        <div>
          {Array.isArray(chartDatas?.filteredAndSortedChartsData)
            ? chartDatas?.filteredAndSortedChartsData
                .reduce((acc, item, index) => {
                  const chunkIndex = Math.floor(index / 3);
                  if (!acc[chunkIndex]) {
                    acc[chunkIndex] = [];
                  }
                  acc[chunkIndex].push(item);
                  return acc;
                }, [])
                .map((group) => (
                  <Row justify="center" gutter={16} key={group}>
                    {group.map((chart) => (
                      <Col span={16} key={chart.title}>
                        <Row justify="center">
                          <Title level={4}>{chart.title}</Title>
                        </Row>
                        <ResponsiveContainer height={300}>
                          <LineChart
                            data={chart.chartData}
                            margin={{
                              top: 20,
                              right: 30,
                              left: 20,
                              bottom: 10
                            }}
                          >
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis
                              dataKey="name"
                              height={60}
                              tick={<CustomizedAxisTick />}
                            />
                            <YAxis />
                            <Tooltip />
                            <Legend />
                            <Line
                              type="monotone"
                              dataKey="actions_per_hour"
                              stroke="#8884d8"
                              label={<CustomizedLabel />}
                              name={t('statistics.gtp.actions_per_hour')}
                            />
                            <Line
                              type="monotone"
                              dataKey="average"
                              stroke="red"
                              label={<CustomizedLabel />}
                              name={t('statistics.gtp.average')}
                            />
                          </LineChart>
                        </ResponsiveContainer>
                      </Col>
                    ))}
                  </Row>
                ))
            : null}
          <Row style={{ marginTop: 20 }}>
            <Col span={24}>
              <Row justify="center">
                <Title level={4}>{t('statistics.gtp.software')}</Title>
              </Row>
              <ResponsiveContainer height={300}>
                <LineChart
                  data={chartDatas?.updatedSoftwares}
                  margin={{
                    top: 20,
                    right: 30,
                    left: 20,
                    bottom: 10
                  }}
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis
                    dataKey="name"
                    height={60}
                    tick={<CustomizedAxisTick />}
                    domain={[1, 12]}
                  />
                  <YAxis />
                  <Tooltip />
                  <Legend />
                  {chartDatas?.updatedSoftwares &&
                    Object.keys(chartDatas?.updatedSoftwares?.[0])?.map(
                      (key, index) => {
                        if (key !== 'name') {
                          return (
                            <Line
                              key={key}
                              type="monotone"
                              dataKey={key}
                              stroke={graphcolors[index % graphcolors.length]}
                              name={key?.replace('_', ' ')}
                            />
                          );
                        }
                        return null;
                      }
                    )}
                </LineChart>
              </ResponsiveContainer>
            </Col>
          </Row>
        </div>
      )}
    </Card>
  );
};

CustomizedLabel.propTypes = {
  x: PropTypes.number.isRequired,
  y: PropTypes.number.isRequired,
  stroke: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired
};

CustomizedAxisTick.propTypes = {
  x: PropTypes.number,
  y: PropTypes.number,
  payload: PropTypes.shape({
    value: PropTypes.number.isRequired
  })
};

CustomizedAxisTick.defaultProps = {
  x: undefined,
  y: undefined,
  payload: undefined
};
