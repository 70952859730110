import PropTypes from 'prop-types';
import { Col, Tabs, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { ListInterventions } from './ListInterventions';
import { ListCustomerServices } from './ListCustomerServices';

/**
 * Component for displaying interventions and rsc informations of a customer in a card format of 2 datatable.
 *
 * @component
 * @returns {JSX.Element} Component JSX
 */

export const InterventionsRSC = ({ id, user }) => {
  const { t } = useTranslation();

  const items = [
    {
      label: t('interventions.title'),
      key: '1',
      children: <ListInterventions id={id} withoutUploadButton />
    },
    ...(user?.role?.startsWith('guests')
      ? []
      : [
          {
            label: (
              <Typography.Title
                level={5}
                style={{
                  fontWeight: 500,
                  color: 'var(--userColor)'
                }}
                strong={false}
              >
                {t('customerServices.title')}
              </Typography.Title>
            ),
            key: '2',
            children: <ListCustomerServices id={id} />
          }
        ])
  ];

  return (
    <Col span="24">
      <Tabs
        defaultActiveKey="1"
        type="card"
        size="large"
        items={items}
        className="card-tabs"
      />
    </Col>
  );
};

InterventionsRSC.propTypes = {
  id: PropTypes.string,
  user: PropTypes.shape({
    role: PropTypes.string
  })
};

InterventionsRSC.defaultProps = {
  id: undefined,
  user: null
};
