import { Col, Flex, Card } from 'antd';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { FilesManagement } from '../../../components/FilesManagement';

/**
 * Component for displaying documents informations of a user in a card format.
 *
 * @component
 * @param {object} props - Component props
 * @param {object} props.data - User data containing documents details
 * @param {number} props.span - Number of columns the card should span
 * @returns {JSX.Element} Component JSX
 */
export const ShowDocuments = ({ data, forceRefresh, setForceRefresh }) => {
  const { t } = useTranslation();
  return (
    <Col xs={24} xl={10}>
      <Flex vertical gap={16}>
        <Card title={t('users.show.indentity_card')}>
          <FilesManagement
            purpose="edit"
            documents={data?.indentity_card || []}
            setForceRefresh={setForceRefresh}
            forceRefresh={forceRefresh}
            uploadURL="/users/upload"
            modelKey="indentity_card"
            showTitle={false}
          />
        </Card>
        <Card title={t('users.show.social_security_card')}>
          <FilesManagement
            purpose="edit"
            documents={data?.social_security_card || []}
            setForceRefresh={setForceRefresh}
            forceRefresh={forceRefresh}
            uploadURL="/users/upload"
            modelKey="social_security_card"
            showTitle={false}
          />
        </Card>
        <Card title={t('users.show.rib')}>
          <FilesManagement
            purpose="edit"
            documents={data?.rib || []}
            setForceRefresh={setForceRefresh}
            forceRefresh={forceRefresh}
            uploadURL="/users/upload"
            modelKey="rib"
            showTitle={false}
          />
        </Card>
        <Card title={t('users.show.family_record_book')}>
          <FilesManagement
            purpose="edit"
            documents={data?.family_record_book || []}
            setForceRefresh={setForceRefresh}
            forceRefresh={forceRefresh}
            uploadURL="/users/upload"
            modelKey="family_record_book"
            showTitle={false}
          />
        </Card>
        <Card title={t('users.show.others')}>
          <FilesManagement
            purpose="edit"
            documents={data?.others || []}
            setForceRefresh={setForceRefresh}
            forceRefresh={forceRefresh}
            uploadURL="/users/upload"
            modelKey="others"
            showTitle={false}
          />
        </Card>
        <Card title={t('users.show.documents')}>
          <FilesManagement
            purpose="edit"
            documents={data?.files || []}
            setForceRefresh={setForceRefresh}
            forceRefresh={forceRefresh}
            uploadURL="/users/upload"
            modelKey="files"
            showTitle={false}
          />
        </Card>
      </Flex>
    </Col>
  );
};

ShowDocuments.propTypes = {
  data: PropTypes.shape({
    indentity_card: PropTypes.arrayOf(PropTypes.shape({})),
    social_security_card: PropTypes.arrayOf(PropTypes.shape({})),
    rib: PropTypes.arrayOf(PropTypes.shape({})),
    family_record_book: PropTypes.arrayOf(PropTypes.shape({})),
    others: PropTypes.arrayOf(PropTypes.shape({})),
    files: PropTypes.arrayOf(PropTypes.shape({}))
  }),
  forceRefresh: PropTypes.bool.isRequired,
  setForceRefresh: PropTypes.func.isRequired
};

ShowDocuments.defaultProps = {
  data: undefined
};
