import { Avatar, Tag } from 'antd';
import { userRoles } from '../../utils/constants/tagColors';
import { formatValue } from '../../utils/formatNumber';

const listContent = (
  { first_name, last_name, role, phone_number, email, address, photo },
  t
) => [
  {
    noLabel: true,
    content: photo && <Avatar src={photo} size={120} />,
    span: 3
  },
  {
    label: 'profile.personalInfo.first_name',
    content: first_name,
    span: 1
  },
  {
    label: 'profile.personalInfo.last_name',
    content: last_name,
    span: 1
  },
  {
    label: 'profile.personalInfo.role',
    content: role && (
      <Tag color={userRoles[role.split(':')[1]]}>
        {t(`users.tags.${role.split(':')[1]}`)}
      </Tag>
    ),
    span: 1
  },
  {
    label: 'profile.form.phone_number',
    span: 1,
    content:
      phone_number && phone_number.number ? (
        <div>
          {formatValue(
            `${phone_number?.country_code || ''}${phone_number?.number || ''}`,
            'phone'
          )}
        </div>
      ) : (
        '-'
      )
  },
  {
    label: 'profile.personalInfo.email',
    content: email,
    span: 2
  },
  {
    label: 'profile.form.address',
    span: 2,
    content: address ? (
      <div>
        <p>
          {address.number}
          {address.street}
          <br />
          {address.additional}
          <br />
          {address.postal_code} {address.city}
        </p>
      </div>
    ) : (
      '-'
    )
  }
];

export default listContent;
