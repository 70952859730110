import { useState } from 'react';
import { Col, Row, Select } from 'antd';
import { RevenueCard } from './RevenueCard';
import useHomeContext from '../HomeContext';
import { RevenueDistributionCard } from './RevenueDistributionCard';
import { GlobalRevenueCard } from './GlobalRevenueCard';

/**
 * Tab to display the financial data
 *
 * @component
 * @returns {JSX.Element} - The financial tab
 */
export const FinancialTab = () => {
  const { psMicro, psMacro } = useHomeContext();
  const currentYear = String(new Date().getFullYear());

  const [selectedYear, setSelectedYear] = useState(currentYear);

  const years = Array.from({ length: 5 }, (v, i) => currentYear - i);
  return (
    <Row gutter={[16, 16]}>
      <Col xs={24}>
        <Row justify="start">
          <Select
            style={{ width: 80 }}
            value={selectedYear}
            onChange={setSelectedYear}
          >
            {years.map((year) => (
              <Select.Option key={year}>{year}</Select.Option>
            ))}
          </Select>
        </Row>
      </Col>
      <Col xs={24} xl={12} xxl={12}>
        <RevenueCard psMicro={psMicro} psMacro={psMacro} year={selectedYear} />
      </Col>
      <Col xs={24} xl={12} xxl={6}>
        <GlobalRevenueCard year={selectedYear} />
      </Col>
      <Col xs={24} xl={12} xxl={6}>
        <RevenueDistributionCard psMacro={psMacro} year={selectedYear} />
      </Col>
    </Row>
  );
};
