import React, { useState } from 'react';
import { Switch, Select, Tag } from 'antd';
import { t } from 'i18next';
import { ListResource } from '../../components';
import { useColumns } from './columns';
import { headers } from './headers';
import UserContextProvider from './UserContext';
import { useAuthContext } from '../../contexts/AuthContext';

const { Option } = Select;

/**
 * Component for listing users with filtering options.
 *
 * @component
 * @returns {JSX.Element} Rendered ListUsers component
 */

export const ListUsers = () => {
  const { enums, psMicro } = UserContextProvider();
  const { user } = useAuthContext();
  const columns = useColumns(enums, psMicro, user);
  const [activeUser, setActiveUser] = useState(true);
  const [selectedEmployer, setSelectedEmployer] = useState(null);

  return (
    <ListResource
      resourceName="users"
      columns={columns}
      headers={headers}
      populate="micro_ps,employments"
      resourceModelName="User"
      deleteAction={false}
      withImportButton={false}
      withSearchBar={user.role.split(':')[1] !== 'USER'}
      withUploadButton={user.role.split(':')[1] !== 'USER'}
      withCreateButtonText={t('buttons.create_custom.users')}
      extraSorter="-created_at"
      withPagination={user.role !== 'users:USER'}
      extraQuery={`status=${activeUser ? 'ACTIVE' : 'INACTIVE'}&employer=${
        selectedEmployer || ''
      }`}
      extraFilters={
        user.role.split(':')[1] !== 'USER' ?? (
          <Select
            allowClear
            filterOption={(input, option) => onsearch(input, option)}
            placeholder={t('users.placeholder.employer')}
            onChange={(value) => setSelectedEmployer(value)}
            style={{ width: '200px', marginLeft: '20px' }}
          >
            {(enums?.employers || []).map((employer) => (
              <Option key={employer} value={employer}>
                <Tag color={employer.color}>{employer}</Tag>
              </Option>
            ))}
          </Select>
        )
      }
      extraButtons={
        user.role.split(':')[1] !== 'USER' ?? (
          <Switch
            onChange={() => setActiveUser(!activeUser)}
            checkedChildren={t(`users.status.${activeUser}`)}
            unCheckedChildren={t(`users.status.${activeUser}`)}
            style={{ marginRight: '20px' }}
          />
        )
      }
    />
  );
};
