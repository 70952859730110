import { useEffect, useState } from 'react';
import moment from 'moment';
import { DatePicker, Input, Select, Tag, Upload, Switch } from 'antd';
import { useTranslation } from 'react-i18next';
import { CameraOutlined } from '@ant-design/icons/lib';
import UserContextProvider from './UserContext';
import { useErrorMessage } from '../../utils/errorMessage';
import {
  userRoles,
  userStatus,
  userEmployer
} from '../../utils/constants/tagColors';
import { phoneNumberField } from '../../utils/phoneNumberField';

const { Option } = Select;
const { Dragger } = Upload;
const { TextArea } = Input;

/**
 * Converts a File object to its corresponding base64 representation.
 *
 * @function
 * @param {File} file - The File object to be converted to base64.
 * @returns {Promise<string>} - A promise that resolves with the base64 representation of the file.
 */

export const fileToBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (e) => reject(e);
  });

/**
 * Custom hook for managing principal user fields and related functionality.
 *
 * @hook
 * @param {string} purpose - The purpose for which the fields are being used (create or edit).
 * @returns {{
 *   principalFields: Array, // Array of principal user fields
 *   isFieldsLoading: boolean, // Whether the fields are loading
 *   base64: string // The base64 representation of a photo
 * }}
 */

const usePrincipalFields = (purpose) => {
  const { t } = useTranslation();
  const { message } = useErrorMessage();
  const { psMicro, enums, formValues, companies } = UserContextProvider();
  const [fileList, setFileList] = useState([]);
  const [isFieldsLoading, setIsFieldsLoading] = useState(true);
  const [base64, setBase64] = useState('');
  const format = 'DD-MM-YYYY';

  const onsearch = (input, option) => {
    const result = option?.children.toLowerCase();
    return result.includes(input.toLowerCase());
  };

  const onSearchCompany = (input, option) => {
    const childrenText = Array.isArray(option.children)
      ? option.children.join(' ')
      : option.children;
    return childrenText.toLowerCase().includes(input.toLowerCase());
  };

  const draggerProps = {
    onRemove: (file) => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);
    },
    beforeUpload: async (file) => {
      const fileExtension = file.name.split('.').pop();
      if (
        fileExtension === 'png' ||
        fileExtension === 'PNG' ||
        fileExtension === 'jpg' ||
        fileExtension === 'JPG'
      ) {
        setFileList([...fileList, file]);
        const base = await fileToBase64(file);
        setBase64(base);
        return false;
      }
      message('not_png_jpg');
      return true;
    },
    fileList
  };

  useEffect(() => {
    if (enums) setIsFieldsLoading(false);
  }, []);

  const principalFields = [
    {
      name: ['photo'],
      input: (
        <Dragger {...draggerProps}>
          <p className="ant-upload-drag-icon">
            <CameraOutlined style={{ color: 'var(--textColor)' }} />
          </p>
          <p className="ant-upload-text">{t('files.create.action')}</p>
        </Dragger>
      )
    },
    {
      name: ['role'],
      rules: [{ required: true }],
      input: (
        <Select loading={isFieldsLoading} allowClear>
          {(enums.roles || [])
            .map((role) => ({
              original: role,
              translated: t(`users.tags.${role.split(':')[1]}`)
            }))
            .sort((a, b) => a.translated.localeCompare(b.translated))
            .map(({ original, translated }) => (
              <Option key={original} value={original} data-label={translated}>
                <Tag color={userRoles[original.split(':')[1]]}>
                  {translated}
                </Tag>
              </Option>
            ))}
        </Select>
      )
    },
    ...(formValues?.role?.startsWith('guest')
      ? [
          ...(formValues?.role !== 'guests:GROUP'
            ? [
                {
                  name: ['company'],
                  rules: [{ required: true }],
                  input: (
                    <Select
                      loading={isFieldsLoading}
                      allowClear
                      showSearch
                      filterOption={(input, option) =>
                        onSearchCompany(input, option)
                      }
                    >
                      {(companies || []).map((company) => (
                        <Option key={company._id} value={company._id}>
                          {company.ps_number} {company.company_name}
                        </Option>
                      ))}
                    </Select>
                  )
                }
              ]
            : []),
          {
            name: ['micro_ps'],
            rules: [{ required: true }],
            input: (
              <Select
                loading={isFieldsLoading}
                allowClear
                showSearch
                filterOption={(input, option) => onsearch(input, option)}
                disabled={formValues?.role !== 'guests:GROUP'}
              >
                {(psMicro || []).map((micro) => (
                  <Option key={micro.title} value={micro._id}>
                    {micro.title}
                  </Option>
                ))}
              </Select>
            )
          }
        ]
      : []),
    {
      name: ['status'],
      rules: [{ required: true }],
      input: (
        <Select loading={isFieldsLoading}>
          {(enums?.status || []).map((status) => (
            <Option key={status} value={status}>
              <Tag color={userStatus[status]}>{t(`users.tags.${status}`)}</Tag>
            </Option>
          ))}
        </Select>
      )
    },
    ...(!formValues?.role?.startsWith('guest')
      ? [
          {
            name: ['employer'],
            rules: [{ required: true }],
            input: (
              <Select loading={isFieldsLoading}>
                {(enums?.employers || []).map((employer) => (
                  <Option key={employer} value={employer}>
                    <Tag color={userEmployer[employer]}>
                      {t(`users.employers.${employer}`)}
                    </Tag>
                  </Option>
                ))}
              </Select>
            )
          }
        ]
      : []),
    {
      name: ['gender'],
      rules: [{ required: true }],
      input: (
        <Select loading={isFieldsLoading}>
          {(enums?.genders || []).map((gender) => (
            <Option key={gender} value={gender}>
              {t(`users.genders.${gender}`)}
            </Option>
          ))}
        </Select>
      )
    },
    {
      name: ['usage_last_name'],
      rules: [{ required: true }]
    },
    {
      name: ['first_name'],
      rules: [{ required: true }]
    },
    {
      ...phoneNumberField,
      rules: [
        {
          required: true
        }
      ]
    },
    {
      name: ['trigram'],
      rules: [{ required: true }]
    },
    {
      name: ['email'],
      rules: [{ required: true }, { type: 'email' }],
      disabled: purpose === 'edit'
    },
    {
      name: ['date_of_employment'],
      input: (
        <DatePicker
          format={format}
          disabledDate={(current) => current > moment()}
        />
      )
    },
    {
      name: ['date_of_employment_adjustement'],
      input: (
        <DatePicker
          format={format}
          disabledDate={(current) => current > moment()}
        />
      )
    },
    {
      name: ['comment'],
      input: <TextArea rows={5} />
    },
    ...(purpose === 'create'
      ? [
          {
            name: ['send_password'],
            initialValue: true,
            input: (
              <Switch
                checkedChildren={t('general.YES')}
                unCheckedChildren={t('general.NO')}
              />
            )
          }
        ]
      : [])
  ];
  return {
    principalFields: principalFields.map((f) => f),
    isFieldsLoading,
    base64
  };
};

export default usePrincipalFields;
