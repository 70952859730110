import { createContext, useContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useAuthContext } from '../../contexts/AuthContext';
import { useErrorMessage } from '../../utils/errorMessage';

const ServiceContext = createContext({});

export const ServiceContextProvider = ({ children }) => {
  const { dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();
  const [serviceEnums, setServiceEnums] = useState({});
  const [forceRefresh, setForceRefresh] = useState(1);
  const [service, setService] = useState();
  const [leaders, setLeaders] = useState(null);
  const [gtps, setGtps] = useState(null);

  const getServiceEnums = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/services/enums`
      });
      setServiceEnums(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getLeaders = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: 'users?role=users:LEADER-USER'
      });
      setLeaders(data);
    } catch (error) {
      message(error);
    }
  };

  const getGtps = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: 'users?role=users:USER'
      });
      setGtps(data);
    } catch (error) {
      message(error);
    }
  };

  useEffect(() => {
    (async () => {
      await getServiceEnums();
      await getLeaders();
      await getGtps();
    })();
  }, []);

  return (
    <ServiceContext.Provider
      value={{
        serviceEnums,
        forceRefresh,
        setForceRefresh,
        setService,
        service,
        leaders,
        gtps
      }}
    >
      {children}
    </ServiceContext.Provider>
  );
};

ServiceContextProvider.propTypes = {
  children: PropTypes.element.isRequired
};

export default () => useContext(ServiceContext);
