import React, { useEffect, useState } from 'react';
import { BellOutlined } from '@ant-design/icons';
import { Badge, Button, Dropdown, Row } from 'antd';
import { useSocketContext } from '../../../../contexts/SocketContext';
import { useAuthContext } from '../../../../contexts/AuthContext';
import { useErrorMessage } from '../../../../utils/errorMessage';
import { NotificationMenu } from './NotificationMenu';

export const NotificationButton = () => {
  const { dispatchAPI } = useAuthContext();
  const { socket } = useSocketContext();

  const [notifications, setNotifications] = useState([]);
  const { message } = useErrorMessage();

  const getNotifications = async () => {
    try {
      const { data } = await dispatchAPI('GET', { url: '/notifications' });
      if (data) setNotifications(data);
    } catch (error) {
      message(error);
    }
  };

  useEffect(() => {
    if (socket) {
      socket.on('notification', getNotifications);
    }
  }, [socket]);

  useEffect(() => {
    getNotifications();
  }, []);

  return (
    <Dropdown
      overlay={
        <NotificationMenu
          notifications={notifications}
          getNotifications={getNotifications}
        />
      }
      placement="bottomRight"
      trigger={['click']}
    >
      <Row style={{ marginRight: 10 }}>
        <Badge count={notifications.filter((n) => !n.isRead).length}>
          <Button
            shape="circle"
            icon={
              <BellOutlined
                style={{
                  fontSize: 18
                }}
              />
            }
          />
        </Badge>
      </Row>
    </Dropdown>
  );
};
