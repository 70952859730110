import { Form, Input, Select } from 'antd';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

/**
 * PhoneNumberInput is a React component that handles the input of a phone number.
 * The input includes a country code selection dropdown and a phone number input.
 *
 * @component
 * @param {Object} props - The props that are passed to the component.
 * @param {boolean} props.disabled - A flag to disable the phone number input.
 * @returns {React.ReactNode} The rendered component for inputting a phone number.
 */
const PhoneNumberInput = ({ disabled }) => {
  const { t } = useTranslation();
  const handleKeyPress = (e) => {
    const { value } = e.target;
    if (!/[0-9]/.test(e.key) || (value.length === 0 && e.key === '0')) {
      e.preventDefault();
    }
  };

  return (
    <Input.Group compact>
      <Form.Item
        noStyle
        name={['phone_number', 'country_code']}
        initialValue="+33"
      >
        <Select disabled={disabled} style={{ width: '20%' }}>
          <Select.Option value="+33">+33</Select.Option>
        </Select>
      </Form.Item>
      <Form.Item
        noStyle
        name={['phone_number', 'number']}
        rules={[
          {
            pattern: /^[1-9]\d{8}$/,
            message: t(`messages.phone_number`)
          }
        ]}
      >
        <Input disabled={disabled} onKeyPress={handleKeyPress} maxLength={9} />
      </Form.Item>
    </Input.Group>
  );
};

export default PhoneNumberInput;

PhoneNumberInput.propTypes = {
  disabled: PropTypes.bool
};

PhoneNumberInput.defaultProps = {
  disabled: false
};
