import { useTranslation } from 'react-i18next';
import { Tag } from 'antd';

import {
  qualityControlStatus,
  userCompanies,
  userPositions
} from '../../utils/constants/tagColors';
import { formatValue } from '../../utils/formatNumber';

/**
 * Custom hook for generating columns data for displaying quality control informations in a list format.
 *
 * @hook
 * @param {object} enums - Enumerations data for quality control statuses
 * @returns {Array} Array of objects containing title, key, dataIndex, render, sorter, and filter for each column
 */
export const useColumns = (enums) => {
  const { t } = useTranslation();

  const getActiveContract = (contracts) => {
    const activeContract = contracts?.find(
      (employment) => employment.status === 'ACTIVE'
    );
    return activeContract;
  };

  return [
    {
      title: t('quality.columns.collaborator'),
      key: 'first_name',
      dataIndex: ['user'],
      render: (collaborator) =>
        `${collaborator?.first_name} ${
          collaborator?.usage_last_name?.toUpperCase() ||
          collaborator?.last_name?.toUpperCase()
        }`,
      sorter: true
    },
    {
      title: t('quality.columns.position'),
      key: 'position',
      dataIndex: ['user', 'employments'],
      render: (employments) => {
        const activeContractPosition = getActiveContract(employments);
        return (
          <Tag color={userPositions[activeContractPosition?.position]}>
            {t(`users.positions.${activeContractPosition?.position || 'N/A'}`)}
          </Tag>
        );
      },
      sorter: true
    },
    {
      title: t('quality.columns.company'),
      key: 'company',
      dataIndex: ['user', 'employments'],
      render: (employments) => {
        const activeContractCompany = getActiveContract(employments);
        return (
          <Tag color={userCompanies[activeContractCompany?.company]}>
            {t(`employments.tags.${activeContractCompany?.company}`)}
          </Tag>
        );
      },
      sorter: true
    },
    {
      title: t('quality.columns.job_requirement'),
      key: 'job_requirement',
      dataIndex: ['user', 'employments'],
      render: (employments) =>
        t(
          `employments.columns.${
            getActiveContract(employments)?.job_requirement
          }`
        ),
      sorter: true
    },
    {
      title: t('quality.columns.quality_control_number'),
      key: 'quality_control_number',
      dataIndex: ['code'],
      sorter: true
    },
    {
      title: t('quality.columns.created_at'),
      key: 'created_at',
      dataIndex: ['created_at'],
      render: (date) => formatValue(date, 'date'),
      sorter: true
    },
    {
      title: t('quality.columns.controlled_interventions_number'),
      key: 'controlled_interventions_number',
      render: (data) => data.interventions.length
    },
    {
      title: t('quality.columns.global_grade'),
      key: 'global',
      dataIndex: ['grades'],
      render: (grades) =>
        grades?.global !== undefined ? `${grades.global} %` : 'N/A',
      sorter: true
    },
    {
      title: t('quality.columns.status'),
      key: 'status',
      dataIndex: ['status'],
      render: (status) => (
        <Tag color={qualityControlStatus[status]}>
          {t(`quality.columns.statuses.${status}`)}
        </Tag>
      ),
      sorter: true,
      filters: enums?.status?.map((s) => ({
        text: t(`quality.columns.statuses.${s}`),
        value: s
      }))
    }
  ];
};
