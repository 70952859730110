/**
 * Hook to get the list content of the intervention
 *
 * @hook
 * @param {object} intervention - Intervention data object
 * @returns {Array} Array of objects containing label, span, and content for each field
 */
export const useListContent = (intervention) => [
  {
    label: 'quality.form.interventions.type_ps',
    span: 8,
    content: intervention?.customer?.macro_ps?.title
  },
  {
    label: 'quality.form.interventions.software',
    span: 8,
    content: intervention?.used_softwares?.map((s) => s.title).join(', ')
  },
  {
    label: 'quality.form.interventions.range',
    span: 8,
    content: intervention?.customer?.connection_period
  }
];
