import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Flex, Tag } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { ListResource } from '../../../../components';
import { useColumns } from './vacationsColumns';
import UserContextProvider from '../../UserContext';
import BalanceModal from './BalanceModal';
import { routes, subRoutes } from '../../../../utils/constants/adminRoutes';

/**
 * Component for listing user vacations with filtering options.
 *
 * @component
 * @returns {JSX.Element} Rendered ListVacations component
 */

export const ListVacations = ({ id }) => {
  const { enums, getAbsenceVacation, absenceVacation, users, user } =
    UserContextProvider();
  const { t } = useTranslation();
  const columns = useColumns(enums);
  const [forceRefresh, setForceRefresh] = useState(false);
  const [modal, setModal] = useState(null);

  useEffect(() => {
    (async () => {
      await getAbsenceVacation(id);
    })();
  }, [forceRefresh]);

  const openModal = () => {
    const modalKey = Date.now();
    setModal(
      <BalanceModal
        key={modalKey}
        setForceRefresh={setForceRefresh}
        absenceVacation={absenceVacation}
        users={users.filter((userr) => userr._id !== absenceVacation.user)}
      />
    );
  };

  return (
    <>
      {modal}
      <ListResource
        resourceName="events/absence-vacation"
        contentCustomStyle={{ margin: 0, padding: 0 }}
        columns={columns}
        resourceModelName="Event"
        editAction={false}
        withCreateButton={false}
        showAction={{
          pathname: (record) =>
            `${routes.RH}${subRoutes.RH.ABSENCE_VACATION}/show/${record._id}`
        }}
        extraFilters={
          <Flex gap={8} align="center" wrap>
            <Tag>
              {absenceVacation?.year1}/{absenceVacation?.year2}
            </Tag>
            <Tag>
              {t('vacations.modal.total_days')} : {absenceVacation?.total_days}
            </Tag>
            <Tag>
              {t('vacations.modal.reported_day')} :{' '}
              {absenceVacation?.reported_day}
            </Tag>
            <Tag>
              {t('vacations.modal.total_days_did')} :{' '}
              {absenceVacation?.total_days_did}
            </Tag>
            <Tag>
              {t('vacations.modal.given')} : {absenceVacation?.given}
            </Tag>
            <Tag>
              {t('vacations.modal.received')} : {absenceVacation?.received}
            </Tag>
            <Tag>
              {t('vacations.modal.balance')} : {absenceVacation?.balance}
            </Tag>
          </Flex>
        }
        withPageHeader={false}
        extraQuery={`type=PAID_VACATION,PARENTAL_LEAVE,SICK_LEAVE&user=${id}`}
        forceRefresh={forceRefresh}
        extraButtons={
          user.role.startsWith('admins') && (
            <Button type="primary" onClick={openModal}>
              <EditOutlined />
              {`${t('vacations.button.edit_cp')} `}
            </Button>
          )
        }
        flexWrap
      />
    </>
  );
};

ListVacations.propTypes = {
  id: PropTypes.string.isRequired
};
