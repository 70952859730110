import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';

export const useFormatter = (modelName) => {
  const { t } = useTranslation();
  const formattedData = (data) => ({
    ...data,
    created_at:
      data?.created_at && dayjs(data?.created_at).format('DD/MM/YYYY'),
    quote_accepted:
      data?.quote_accepted && dayjs(data?.quote_accepted).format('DD/MM/YYYY'),
    odm_valid: data?.odm_valid && dayjs(data?.odm_valid).format('DD/MM/YYYY'),
    startTime: data?.startTime && dayjs(data?.startTime).format('HH:mm'),
    endTime: data?.endTime && dayjs(data?.endTime).format('HH:mm'),
    date: data?.date && dayjs(data?.date).format('DD/MM/YYYY'),
    period_start:
      data?.period_start && dayjs(data?.period_start).format('HH:mm'),
    period_end: data?.period_end && dayjs(data?.period_end).format('HH:mm'),
    report_sent: data?.report_sent
      ? t(`switch.${data?.report_sent}`)
      : t(`switch.${false}`),
    scheduled_start_date:
      data?.scheduled_start_date &&
      dayjs(data?.scheduled_start_date).format('DD/MM/YYYY'),
    scheduled_start_date_hour:
      data?.scheduled_start_date &&
      dayjs(data?.scheduled_start_date).format('HH:mm'),
    start_date:
      data?.start_date && dayjs(data?.start_date).format('DD/MM/YYYY'),
    end_date: data?.end_date && dayjs(data?.end_date).format('DD/MM/YYYY'),
    completion_date:
      data?.completion_date &&
      dayjs(data?.completion_date).format('DD/MM/YYYY'),
    status: data?.status && t(`${modelName}.form.enums.${data?.status}`),
    origin: data?.origin && t(`${modelName}.form.enums.${data?.origin}`),
    recorded_time:
      data?.recorded_time &&
      t(`${modelName}.form.enums.${data?.recorded_time}`),
    transfer_date:
      data?.transfer_date && dayjs(data?.transfer_date).format('DD/MM/YYYY'),
    type: data?.status && t(`${modelName}.form.enums.${data?.type}`),
    frequency: data?.status && t(`${modelName}.form.enums.${data?.frequency}`),
    periodicity:
      data?.status && t(`${modelName}.form.enums.${data?.periodicity}`),
    files: (data?.files && data?.files?.length) || 0,
    date_of_birth:
      data.date_of_birth && dayjs(data?.date_of_birth).format('DD/MM/YYYY'),
    used_software:
      data?.used_software?.length > 0 &&
      data.used_software?.map((p) => p.title).join(', '),
    operation_date:
      data?.operation_date && dayjs(data?.operation_date).format('DD/MM/YYYY'),
    payment_method:
      data?.payment_method &&
      t(`${modelName}.form.enums.${data?.payment_method}`),
    category: data?.category && t(`${modelName}.categories.${data?.category}`)
  });
  return { formattedData };
};
