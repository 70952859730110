import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  useRef
} from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import { startOfWeek } from 'date-fns';
import { useAuthContext } from '../../contexts/AuthContext';
import { useErrorMessage } from '../../utils/errorMessage';
import { showSuccessMessage } from '../../utils/showSuccessMessage';

const OccupancyChartContext = createContext({});

/**
 * Context provider component for managing occupancy_chart.
 * This context provides various state values and methods related to contracts.
 *
 * @component
 * @param {object} props - React component props
 * @param {React.ReactNode} props.children - The child components that will have access to the context
 * @returns {React.ReactNode} Wrapped children with the contract context
 */

export const OccupancyChartContextProvider = ({ children }) => {
  const { dispatchAPI, user } = useAuthContext();
  const { t } = useTranslation();
  const { message } = useErrorMessage();
  const [leaders, setLeaders] = useState([]);
  const [leadersAndGtp, setLeadersAndGtp] = useState([]);
  const [softwares, setSoftwares] = useState([]);
  const [selectedLeaders, setSelectedLeaders] = useState([]);
  const [selectedGtps, setSelectedGtps] = useState();
  const [occupanciesSelectedGtps, setOccupanciesSelectedGtps] = useState([]);
  const [selectedSoftwares, setSelectedSoftwares] = useState([]);
  const startWeekRef = useRef(startOfWeek(new Date()));
  const [startWeek, setStartWeek] = useState(startWeekRef.current);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalGtpInfo, setModalGtpInfo] = useState({});
  const [modalServiceInfo, setModalServiceInfo] = useState({});
  const [purpose, setPurpose] = useState('');
  const [psMicro, setPsMicro] = useState([]);
  const [interventionEnums, setInterventionEnums] = useState([]);
  const [idFromOverlay, setIdFromOverlay] = useState('');
  const [forceRefresh, setForceRefresh] = useState(false);
  const [forceCalendarRefresh, setForceCalendarRefresh] = useState(0);
  const [resource, setResource] = useState('');
  const [date, setDate] = useState({});
  const [editDate, setEditDate] = useState();
  const [customers, setCustomers] = useState([]);
  const [customerEnums, setCustomerEnums] = useState({});
  const [contracts, setContracts] = useState([]);
  const [services, setServices] = useState([]);
  const [formValues, setFormValues] = useState(null);
  const [refresh, setRefresh] = useState(false);
  const [users, setUsers] = useState([]);

  useEffect(() => {
    startWeekRef.current = startWeek;
  }, [startWeek]);

  const getDataFromId = async (id) => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/events/absence-vacation/${id}?populate=user,file,historic.author`
      });
      return data;
    } catch (e) {
      message(e);
      return [];
    }
  };

  const changeStatus = async (id, status, reason) => {
    try {
      const body = { status };
      if (status === 'ARCHIVED') {
        body.isActive = false;
      }
      await dispatchAPI('PATCH', {
        url: `/events/absence-vacation/${id}`,
        body: {
          status,
          reviewed: {
            reason,
            user: user._id
          }
        }
      });
      setRefresh(!refresh);
      showSuccessMessage(t, 'status', 'update');
    } catch (e) {
      message(e);
    }
  };

  const getUsers = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/users`
      });
      setUsers(data);
    } catch (e) {
      message(e);
    }
  };

  const patchIterventionStatus = async (id) => {
    try {
      await dispatchAPI('PATCH', {
        url: `/interventions/${id}`,
        body: {
          status: 'PROVISIONAL'
        }
      });
      setForceCalendarRefresh((prev) => prev + 1);
      showSuccessMessage(t, 'status', 'changed');
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const directPatchIntervention = async (id, day, hour, scheduled_time) => {
    try {
      const body = {
        status: 'PLANNED',
        scheduled_start_date: dayjs(
          `${dayjs(day).format('YYYY-MM-DD')} ${hour}`,
          'YYYY-MM-DD HH:mm'
        ),
        scheduled_end_date: dayjs(
          `${dayjs(day).format('YYYY-MM-DD')} ${hour}`,
          'YYYY-MM-DD HH:mm'
        ).add(scheduled_time || 1, 'hours')
      };

      await dispatchAPI('PATCH', {
        url: `/interventions/${id}`,
        body
      });
      setForceCalendarRefresh((prev) => prev + 1);
      showSuccessMessage(t, 'interventions', 'planned');
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const patchServiceValidate = async (id, method) => {
    try {
      await dispatchAPI('PATCH', {
        url: `/services/validate/${id}`,
        body: {
          start_date: dayjs(startWeekRef.current)
            .add(1, 'day')
            .startOf('day')
            .format('YYYY-MM-DDTHH:mm:ss.SSS[Z]'),
          method
        }
      });
      setForceCalendarRefresh((prev) => prev + 1);
      showSuccessMessage(t, 'services', 'validate');
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const deleteEvent = async (id) => {
    try {
      await dispatchAPI('DELETE', { url: `/events/${id}` });
      setForceCalendarRefresh((prev) => prev + 1);
      showSuccessMessage(t, 'events', 'archive');
    } catch (e) {
      message(e);
    }
  };

  const getSoftwares = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/Software?sort=title`
      });
      setSoftwares(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getMicroPs = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/Psmicro?sort=title`
      });
      setPsMicro(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getLeaders = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: 'users?role=users:LEADER-USER'
      });
      setLeaders(data);
    } catch (error) {
      message(error);
    }
  };

  const getLeadersAndGtp = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: 'users?role=users:LEADER-USER,users:USER'
      });
      setLeadersAndGtp(data);
    } catch (error) {
      message(error);
    }
  };

  const getInterventionEnums = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/interventions/enums`
      });
      setInterventionEnums(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getCustomerEnums = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/customers/enums`
      });
      setCustomerEnums(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getCustomers = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: '/customers'
      });
      setCustomers(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getContracts = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/contracts`
      });
      setContracts(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
    return true;
  };

  const getServices = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/service?status=ACTIVE`
      });
      setServices(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
    return true;
  };

  const openModal = (dragInfo, dropInfo, purp, id, specialPurpose) => {
    setPurpose(purp);
    setModalServiceInfo(dragInfo);
    setModalGtpInfo(dropInfo);
    setIsModalOpen(true);
    setIdFromOverlay(id);
    setResource(specialPurpose || 'occupancies');
  };

  const closeModal = (type) => {
    setModalServiceInfo({});
    setModalGtpInfo({});
    setIsModalOpen(false);
    if (type !== 'cancel') {
      setForceRefresh(!forceRefresh);
      setForceCalendarRefresh(forceCalendarRefresh + 1);
    }
  };

  const deleteIntervention = async (id) => {
    try {
      await dispatchAPI('DELETE', { url: `/interventions/delete/${id}` });
      setForceRefresh(!forceRefresh);
      setForceCalendarRefresh(forceCalendarRefresh + 1);
      showSuccessMessage(t, 'interventions', 'archive');
    } catch (e) {
      message(e);
    }
  };

  useEffect(() => {
    (async () => {
      await getSoftwares();
      await getMicroPs();
      await getLeadersAndGtp();
      await getInterventionEnums();
      await getCustomers();
      await getCustomerEnums();
      await getContracts();
      await getServices();
    })();
  }, []);

  useEffect(() => {
    (async () => {
      await getLeaders();
    })();
  }, [selectedLeaders]);

  useEffect(() => {
    (async () => {
      await getUsers();
    })();
  }, [refresh]);

  return (
    <OccupancyChartContext.Provider
      value={{
        leaders,
        softwares,
        selectedLeaders,
        setSelectedLeaders,
        selectedGtps,
        setSelectedGtps,
        selectedSoftwares,
        setSelectedSoftwares,
        startWeek,
        setStartWeek,
        setIsModalOpen,
        isModalOpen,
        openModal,
        closeModal,
        modalGtpInfo,
        modalServiceInfo,
        purpose,
        psMicro,
        leadersAndGtp,
        interventionEnums,
        idFromOverlay,
        forceCalendarRefresh,
        setForceCalendarRefresh,
        deleteIntervention,
        deleteEvent,
        patchServiceValidate,
        patchIterventionStatus,
        setIdFromOverlay,
        setPurpose,
        resource,
        setResource,
        setDate,
        date,
        setModalServiceInfo,
        setOccupanciesSelectedGtps,
        occupanciesSelectedGtps,
        customers,
        customerEnums,
        contracts,
        services,
        formValues,
        setFormValues,
        directPatchIntervention,
        setEditDate,
        editDate,
        users,
        changeStatus,
        getDataFromId,
        refresh,
        setRefresh
      }}
    >
      {children}
    </OccupancyChartContext.Provider>
  );
};

OccupancyChartContextProvider.propTypes = {
  children: PropTypes.element.isRequired
};

export default () => useContext(OccupancyChartContext);
