import { Col, Row } from 'antd';
import { DownloadOutlined, EyeOutlined } from '@ant-design/icons';
import { ColorSelector } from '../quality_control/ColorSelector';
import { useDownloadDocument } from '../../utils/downloadDoc';

/**
 * Hook for the list content of the quality controls
 *
 * @hook
 * @param {Array} qualityControls - The quality controls array
 * @returns {Array} The list content
 */
export const useListContent = (qualityControls) => {
  const { downloadDocument, viewDocument } = useDownloadDocument();

  return [
    ...(qualityControls.flatMap((qualityControl) => [
      {
        label: 'interventions.form.quality_control.comment',
        span: 20,
        content: qualityControl?.comment,
        category: qualityControl?.category
      },
      {
        label: 'interventions.form.quality_control.assessment',
        span: 4,
        content: <ColorSelector value={qualityControl?.assessment} isGrade />,
        category: qualityControl?.category
      },
      {
        label: 'interventions.form.quality_control.files',
        span: 24,
        content: (
          <Row>
            {qualityControl?.files?.map((file) => (
              <Col span={24} key={file._id}>
                <Row gutter={16}>
                  <Col>{file.metadata.originalName}</Col>
                  <Col>
                    <EyeOutlined
                      onClick={() => {
                        viewDocument(file);
                      }}
                    />
                  </Col>
                  <Col>
                    <DownloadOutlined onClick={() => downloadDocument(file)} />
                  </Col>
                </Row>
              </Col>
            ))}
          </Row>
        ),
        category: qualityControl?.category
      }
    ]) || [])
  ];
};
