import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Drawer, Row, Col } from 'antd';
import InterventionContextProvider from './InterventionContext';
import { DescriptionList } from '../../components';
import { useDrawerInfoContent } from './ShowCards/drawerInfoContent';
import { useDownloadDocument } from '../../utils/downloadDoc';

const InformationsDrawer = ({
  isDrawerOpen,
  closeDrawer,
  type,
  intervention
}) => {
  const { t } = useTranslation();
  const { firstOutstanding } = InterventionContextProvider();
  const { downloadDocument, viewDocument } = useDownloadDocument();

  const listContent = useDrawerInfoContent(
    intervention,
    type,
    downloadDocument,
    viewDocument,
    firstOutstanding
  );

  return (
    <Drawer
      centered
      title={t(`interventions.form.drawer.titles.${type}`)}
      open={isDrawerOpen}
      onClose={closeDrawer}
      width={500}
      footer={false}
    >
      <Col span="24">
        <Row align="middle">
          <Col span={24}>
            <DescriptionList data={listContent} translate columns={24} />
          </Col>
        </Row>
      </Col>
    </Drawer>
  );
};

InformationsDrawer.propTypes = {
  isDrawerOpen: PropTypes.bool.isRequired,
  closeDrawer: PropTypes.func.isRequired,
  intervention: PropTypes.shape({}),
  type: PropTypes.string
};

InformationsDrawer.defaultProps = {
  intervention: null,
  type: ''
};

export default InformationsDrawer;
