import { Button } from 'antd';
import { useTranslation } from 'react-i18next';

/**
 * useFooterButtons hook that returns the buttons to be displayed in the footer
 *
 * @hook
 * @param {string} purpose - The purpose of the footer buttons
 * @param {function} setIsOpenModal - The modal setter
 * @param {function} onSubmit - The submit function
 * @returns {Array} The buttons to be displayed in the footer
 */
export const useFooterButtons = (purpose, setIsOpenModal, onSubmit) => {
  const { t } = useTranslation();
  return [
    ...(purpose === 'PROMOTION'
      ? [
          <Button type="primary" onClick={() => onSubmit('VALID')}>
            {t('buttons.validate')}
          </Button>,
          <Button onClick={() => onSubmit('INVALID')}>
            {t('buttons.unvalidate')}
          </Button>
        ]
      : []),
    <Button onClick={() => setIsOpenModal(false)}>
      {t('buttons.cancel')}
    </Button>,
    purpose === 'CLOSURE' && (
      <Button type="primary" onClick={() => onSubmit('CLOSED')}>
        {t('quality.form.closure.close')}
      </Button>
    )
  ].filter(Boolean);
};
