import { Col, Row, Typography } from 'antd';
import moment from 'moment';

const generateHistoric = (data, t) =>
  data.map((histo) => (
    <Row gutter={[24, 24]} key={histo._id}>
      <Col span={24}>
        <Typography.Title level={5}>
          {`${t(`historic.type.${histo.type}`)}${
            histo?.status ? ` => ${t(`historic.status.${histo.status}`)}` : ''
          }`}
        </Typography.Title>
        <Typography.Text>
          {moment(histo.date).format('DD/MM/YYYY HH:mm')} -{' '}
          {`${histo.author.first_name} ${
            histo.author?.usage_last_name || histo.author?.last_name
          }`}
        </Typography.Text>
      </Col>
    </Row>
  ));

export default generateHistoric;
