import React, { createContext, useContext, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { useAuthContext } from '../../contexts/AuthContext';
import { useErrorMessage } from '../../utils/errorMessage';
import { showSuccessMessage } from '../../utils/showSuccessMessage';

const ContractContext = createContext({});

/**
 * Context provider component for managing contract-related data.
 * This context provides various state values and methods related to contracts.
 *
 * @component
 * @param {object} props - React component props
 * @param {React.ReactNode} props.children - The child components that will have access to the context
 * @returns {React.ReactNode} Wrapped children with the contract context
 */

export const ContractContextProvider = ({ children }) => {
  const { dispatchAPI } = useAuthContext();
  const { t } = useTranslation();
  const { message } = useErrorMessage();
  const [contractEnums, setContractEnums] = useState({});
  const [serviceEnums, setServiceEnums] = useState({});
  const [customerEnums, setCustomerEnums] = useState({});
  const [psMicro, setPsMicro] = useState();
  const [psMacro, setPsMacro] = useState();
  const [leaders, setLeaders] = useState([]);
  const [usersGtp, setUsersGtp] = useState([]);
  const [invoiceEnums, setInvoiceEnums] = useState([]);
  const [interventionEnums, setInterventionEnums] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [forceRefresh, setForceRefresh] = useState(1);
  const [contract, setContract] = useState();

  const getContractEnums = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/contracts/enums`
      });
      setContractEnums(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getInvoiceEnums = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/quittances/enums`
      });
      setInvoiceEnums(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getInterventionEnums = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/interventions/enums`
      });
      setInterventionEnums(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getServiceEnums = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/services/enums`
      });
      setServiceEnums(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getMicroPs = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/Psmicro?sort=title`
      });
      setPsMicro(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getMacroPs = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/Psmacro?sort=title`
      });
      setPsMacro(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getCustomerEnums = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/customers/enums`
      });
      setCustomerEnums(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getLeaders = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: 'users?role=users:LEADER-USER'
      });
      setLeaders(data);
    } catch (error) {
      message(error);
    }
  };

  const getUsersGtp = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: 'users?role=users:USER'
      });
      setUsersGtp(data);
    } catch (error) {
      message(error);
    }
  };

  const patchContract = async (values, id) => {
    try {
      await dispatchAPI('PATCH', {
        url: `/contracts/${id}`,
        body: values
      });
      setIsModalOpen(false);
      setForceRefresh(forceRefresh + 1);
      showSuccessMessage(t, 'contracts', 'update');
    } catch (e) {
      message(e);
    }
  };

  useEffect(() => {
    (async () => {
      await getContractEnums();
      await getServiceEnums();
      await getMicroPs();
      await getMacroPs();
      await getCustomerEnums();
      await getLeaders();
      await getUsersGtp();
      await getInvoiceEnums();
      await getInterventionEnums();
    })();
  }, []);

  return (
    <ContractContext.Provider
      value={{
        contractEnums,
        serviceEnums,
        psMicro,
        psMacro,
        customerEnums,
        leaders,
        setLeaders,
        usersGtp,
        setUsersGtp,
        isModalOpen,
        setIsModalOpen,
        forceRefresh,
        setForceRefresh,
        patchContract,
        invoiceEnums,
        interventionEnums,
        contract,
        setContract
      }}
    >
      {children}
    </ContractContext.Provider>
  );
};

ContractContextProvider.propTypes = {
  children: PropTypes.element.isRequired
};

export default () => useContext(ContractContext);
