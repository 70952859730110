import { Input } from 'antd';
import { ColorSelector } from '../ColorSelector';

const { TextArea } = Input;

/**
 * Hook for the soft skills fields.
 *
 * @hook
 * @returns {Object} The soft skills fields
 */
const useSoftSkillsFields = () => {
  const softSkillsFields = [
    {
      name: ['soft_skills', 'timestamp_assessment'],
      input: <ColorSelector isGrade />
    },
    {
      label: 'comment',
      name: ['soft_skills', 'timestamp_comment'],
      input: <TextArea rows={2} />
    },
    {
      name: ['soft_skills', 'technical_assessment'],
      input: <ColorSelector isGrade />
    },
    {
      label: 'comment',
      name: ['soft_skills', 'technical_comment'],
      input: <TextArea rows={2} />
    },
    {
      name: ['soft_skills', 'planning_assessment'],
      input: <ColorSelector isGrade />
    },
    {
      label: 'comment',
      name: ['soft_skills', 'planning_comment'],
      input: <TextArea rows={2} />
    },
    {
      name: ['soft_skills', 'N1_assessment'],
      input: <ColorSelector isGrade />
    },
    {
      label: 'comment',
      name: ['soft_skills', 'N1_comment'],
      input: <TextArea rows={2} />
    }
  ];
  return {
    softSkillsFields: softSkillsFields.map((f) => f)
  };
};

export default useSoftSkillsFields;
