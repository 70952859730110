import PropTypes from 'prop-types';
import { Card, Typography, Flex, Col } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { formatValue } from '../../../utils/formatNumber';

export const Outstanding = ({
  outstanding,
  openOutstandingModal,
  firstOutstanding,
  intervention,
  hasAccess
}) => {
  const { t } = useTranslation();

  return (
    <Card>
      <Flex justify="space-between">
        <Typography.Title
          level={4}
          style={{
            color: 'var(--userColor)'
          }}
          strong={false}
        >
          {t(
            `interventions.show.outstanding_title.title${
              intervention?.service?.type?.startsWith('LIABILITIES')
                ? '_treatment'
                : ''
            }`
          )}
        </Typography.Title>
        {hasAccess('edit') && (
          <EditOutlined
            style={{
              color: 'var(--userColor)',
              fontSize: 18
            }}
            onClick={() =>
              openOutstandingModal(outstanding?.length ? 'edit' : 'create')
            }
          />
        )}
      </Flex>
      <Flex justify="space-between">
        <Col span={12}>
          <p>{`${t('outstanding.show.start_outstanding')}:`}</p>
          <p>
            {firstOutstanding?.date
              ? formatValue(firstOutstanding?.date, 'date')
              : 'N/A'}
          </p>
        </Col>
        <Col span={12}>
          <p>{`${t(
            `outstanding.show.number${
              intervention?.service?.type?.startsWith('LIABILITIES')
                ? '_treatment'
                : ''
            }`
          )}:`}</p>
          <p>
            {outstanding[0]?.number
              ? `${formatValue(outstanding[0]?.number, 'currency', true)}`
              : 'N/A'}
          </p>
        </Col>
      </Flex>
    </Card>
  );
};

Outstanding.propTypes = {
  openOutstandingModal: PropTypes.func,
  outstanding: PropTypes.arrayOf(
    PropTypes.shape({
      number: PropTypes.number
    })
  ),
  firstOutstanding: PropTypes.shape({ date: PropTypes.string }),
  intervention: PropTypes.shape({
    service: PropTypes.shape({
      type: PropTypes.string
    })
  }),
  hasAccess: PropTypes.func.isRequired
};

Outstanding.defaultProps = {
  intervention: undefined,
  openOutstandingModal: undefined,
  outstanding: [],
  firstOutstanding: null
};
