import { Tag, Button } from 'antd';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import {
  userAdmin,
  userEmployer,
  userLevels,
  userPositions,
  userRoles
} from '../../../utils/constants/tagColors';
import { routes } from '../../../utils/constants/adminRoutes';
import { formatValue } from '../../../utils/formatNumber';

/**
 * Custom hook for generating principal content data for displaying user details in a list format.
 *
 * @hook
 * @param {object} data - User data object
 * @returns {Array} Array of objects containing label, span, and content for each field
 */

export const useListPrincipalContent = (data = {}) => {
  const { t } = useTranslation();
  const {
    employer,
    date_of_birth,
    gender,
    usage_last_name,
    last_name,
    first_name,
    phone_number,
    trigram,
    email,
    date_of_employment,
    date_of_employment_adjustement,
    status,
    role,
    comment,
    company,
    employments
  } = data;

  const isGuest = role?.startsWith('guest');

  const activeEmployment = employments?.find(
    (employment) => employment.status === 'ACTIVE'
  );

  return [
    {
      label: 'users.form.employer',
      span: 5,
      content: employer ? (
        <Tag color={userEmployer[employer]}>{employer}</Tag>
      ) : (
        'N/A'
      )
    },
    ...(!isGuest
      ? [
          {
            label: 'users.form.position',
            span: 5,
            content: (
              <Tag color={userPositions[activeEmployment?.position]}>
                {t(`users.positions.${activeEmployment?.position || 'N/A'}`)}
              </Tag>
            )
          },
          {
            label: 'users.form.level',
            span: 5,
            content: activeEmployment?.job_requirement ? (
              <Tag color={userLevels[activeEmployment?.job_requirement]}>
                {t(`employments.tags.${activeEmployment?.job_requirement}`)}
              </Tag>
            ) : (
              'N/A'
            )
          },
          {
            label: 'users.form.date_of_birth',
            span: 5,
            content: date_of_birth ? formatValue(date_of_birth, 'date') : '-'
          }
        ]
      : []),
    {
      label: 'users.form.gender',
      span: 5,
      content: t(`users.genders.${gender}`) || '-'
    },
    {
      label: 'users.form.last_name',
      span: 5,
      content: last_name || '-'
    },
    {
      label: 'users.form.usage_last_name',
      span: 5,
      content: usage_last_name || '-'
    },
    {
      label: 'users.form.first_name',
      span: 5,
      content: first_name || '-'
    },
    ...(!isGuest
      ? [
          {
            label: 'users.form.phone_number_full',
            span: 5,
            content:
              phone_number && phone_number.number ? (
                <div>
                  {formatValue(
                    `${phone_number?.country_code || ''}${
                      phone_number?.number || ''
                    }`,
                    'phone'
                  )}
                </div>
              ) : (
                '-'
              )
          }
        ]
      : []),
    {
      label: 'users.form.trigram',
      span: 5,
      content: trigram || '-'
    },
    {
      label: 'users.form.email',
      span: 5,
      content: email || '-'
    },
    ...(!isGuest
      ? [
          {
            label: 'users.form.is_leader',
            span: 5,
            content: (
              <Tag color={userAdmin[activeEmployment?.is_leader]}>
                {t(`switch.${activeEmployment?.is_leader}`)}
              </Tag>
            )
          },
          {
            label: 'users.form.leader',
            span: 5,
            content: activeEmployment?.leader
              ? `${activeEmployment?.leader.first_name} ${
                  activeEmployment?.leader?.usage_last_name ||
                  activeEmployment?.leader.last_name
                }`
              : '-'
          },
          {
            label: 'users.form.is_admin',
            span: 5,
            content: (
              <Tag color={userAdmin[activeEmployment?.is_admin]}>
                {t(`switch.${activeEmployment?.is_admin}`)}
              </Tag>
            )
          },
          {
            label: 'users.form.start_date',
            span: 5,
            content: activeEmployment?.start_date
              ? dayjs(activeEmployment?.start_date).format('DD/MM/YYYY')
              : '-'
          }
        ]
      : []),
    {
      label: 'users.form.date_of_employment',
      span: 5,
      content: date_of_employment
        ? dayjs(date_of_employment).format('DD/MM/YYYY')
        : '-'
    },
    {
      label: 'users.form.date_of_employment_adjustement',
      span: 5,
      content: date_of_employment_adjustement
        ? dayjs(date_of_employment_adjustement).format('DD/MM/YYYY')
        : '-'
    },
    {
      label: 'users.form.status',
      span: isGuest ? 5 : 10,
      content:
        (status && (
          <Tag color={status === 'ACTIVE' ? 'orange' : 'red'}>
            {t(`users.tags.${status}`)}
          </Tag>
        )) ||
        '-'
    },
    ...(isGuest
      ? [
          {
            label: 'users.form.company',
            span: 5,
            content: (
              <Button type="link" className="event-btn first">
                <a
                  href={`${`${routes.CUSTOMERS}`}/show/${company?._id}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  aria-label="Voir le client"
                >
                  {company?.company_name}
                </a>
              </Button>
            )
          }
        ]
      : []),
    {
      label: 'users.form.role',
      span: 10,
      content:
        (role && (
          <Tag color={userRoles[role.split(':')[1]]}>
            {t(`users.tags.${role.split(':')[1]}`)}
          </Tag>
        )) ||
        '-'
    },
    {
      label: 'users.form.comment',
      span: 10,
      content: comment || '-'
    }
  ];
};
