export const headers = [
  {
    label: 'code',
    key: 'code'
  },
  {
    label: 'user',
    key: 'user.first_name'
  },
  {
    label: 'user',
    key: 'user.last_name'
  },
  {
    label: 'contract',
    key: 'contract.company_name'
  },
  {
    label: 'period_start',
    key: 'period_start'
  },
  {
    label: 'period_end',
    key: 'period_end'
  },
  {
    label: 'start_date',
    key: 'start_date'
  },
  {
    label: 'end_date',
    key: 'end_date'
  },
  {
    label: 'total_time',
    key: 'total_time'
  },
  {
    label: 'report_sent',
    key: 'report_sent'
  },
  {
    label: 'contract_code',
    key: 'contract_code'
  }
];
