import { createContext, useContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useAuthContext } from '../../contexts/AuthContext';
import { useErrorMessage } from '../../utils/errorMessage';
import { showSuccessMessage } from '../../utils/showSuccessMessage';

const AbsenceVacationContext = createContext({});

export const AbsenceVacationContextProvider = ({ children }) => {
  const { dispatchAPI, user } = useAuthContext();
  const { t } = useTranslation();
  const { message } = useErrorMessage();
  const [enums, setEnums] = useState({});
  const [roles, setRoles] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [users, setUsers] = useState([]);

  const getEnums = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/events/enums`
      });
      setEnums(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getRoles = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/users/enums`
      });
      setRoles(data?.roles);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getUsers = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/users`
      });
      setUsers(data);
    } catch (e) {
      message(e);
    }
  };

  const getDataFromId = async (id) => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/events/absence-vacation/${id}?populate=user,file,historic.author`
      });
      return data;
    } catch (e) {
      message(e);
      return [];
    }
  };

  const changeStatus = async (id, status, reason) => {
    try {
      const body = { status };
      if (status === 'ARCHIVED') {
        body.isActive = false;
      }
      await dispatchAPI('PATCH', {
        url: `/events/absence-vacation/${id}`,
        body: {
          status,
          reviewed: {
            reason,
            user: user._id
          }
        }
      });
      setRefresh(!refresh);
      showSuccessMessage(t, 'status', 'update');
    } catch (e) {
      message(e);
    }
  };

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      await getEnums();
      await getRoles();
      await getUsers();
      setIsLoading(false);
    })();
  }, [refresh]);

  return (
    <AbsenceVacationContext.Provider
      value={{
        enums,
        users,
        roles,
        isLoading,
        refresh,
        setRefresh,
        getDataFromId,
        changeStatus
      }}
    >
      {children}
    </AbsenceVacationContext.Provider>
  );
};

AbsenceVacationContextProvider.propTypes = {
  children: PropTypes.element.isRequired
};

export default () => useContext(AbsenceVacationContext);
