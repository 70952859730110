import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import propTypes from 'prop-types';
import dayjs from 'dayjs';
import { Form, Typography } from 'antd';
import { useParams } from 'react-router-dom';
import { useAuthContext } from '../../contexts/AuthContext';
import { CreateUpdateContainer } from '../../components/CreateUpdateContainer/CreateUpdateContainer';
import useIdentificationFields from './CRUDFields/identificationFields';
import useAdministrationFields from './CRUDFields/administrationFields';
import useTechnicalFields from './CRUDFields/technicalInfosFields';
import { ContentCustom, PageHeaderCustom } from '../../components';
import useCustomerContext from './CustomerContext';
import { useGenerateFormItem } from '../../utils/generateFormItem';
import useFacturationfields from './CRUDFields/facturationFields';
import useProspectGeneralAdditionalFields from './CRUDFields/prospectGeneralAdditionalFields';
import useComplementaryInfosFields from './CRUDFields/complementaryInfosFields';
import useCommentsAndFilesFields from './CRUDFields/commentsAndFilesFields';
import useCollaboratorsFields from './CRUDFields/collaboratorsFields';
import useContactsFields from './CRUDFields/contactsFields';
import { prospectSort } from './CRUDFields/utils';

/**
 * categoryTitle is a utility function that generates a styled title for a category.
 *
 * @function
 * @param {string} title - The title of the category.
 * @returns {React.ReactNode} The rendered title component with styling.
 */

const categoryTitle = (title) => (
  <Typography.Title
    level={4}
    style={{
      fontWeight: 500,
      textUnderlineOffset: '8px',
      color: 'var(--textColorSecondary)'
    }}
    strong={false}
  >
    {title}
  </Typography.Title>
);

/**
 * CreateUpdateCustomer is a React component that provides a form for creating or updating customer data.
 *
 * @component
 * @param {Object} props - The component's props.
 * @param {string} props.purpose - The purpose of the form, either 'create' or 'edit'.
 * @returns {React.ReactNode} The rendered JSX for the CreateUpdateCustomer component.
 */

export const CreateUpdateCustomer = ({ purpose }) => {
  const { user } = useAuthContext();
  const { t } = useTranslation();
  const { createType, getTypeFromId } = useCustomerContext();
  const { id } = useParams();
  const { customer, getCustomer, refresh, setRefresh, getFile, setCreateType } =
    useCustomerContext();
  const [files, setFiles] = useState([]);
  const [populatedFiles, setPopulatedFiles] = useState([]);
  const [infoSheet, setInfoSheet] = useState([]);
  const [populatedInfoSheet, setPopulatedInfoSheet] = useState([]);
  const [paymentSelected, setPaymentSelected] = useState('DIRECTDEBIT');
  const [usedCreateType, setUsedCreateType] = useState(createType);
  const [activeKey, setActiveKey] = useState('1');
  const [modificationRib, setModificationRib] = useState(false);
  const [psNumberRegistered, setPsNumbeRegistered] = useState(null);
  const [emailRegistered, setEmailRegistered] = useState(null);
  const [psIdRegistered, setPsIdRegistered] = useState(null);
  const [formValues, setFormValues] = useState();
  const [selectedMonth, setSelectedMonth] = useState(null);
  const [availableDays, setAvailableDays] = useState([]);
  const [selectedDay, setSelectedDay] = useState(null);
  const [form] = Form.useForm();
  const identificationFields = useIdentificationFields(
    psNumberRegistered,
    emailRegistered,
    psIdRegistered,
    usedCreateType,
    purpose
  );

  const handleMonthChange = (month) => {
    setSelectedMonth(month);
    let daysInMonth;

    if (month === '02') {
      daysInMonth = Array.from({ length: 29 }, (_, i) => i + 1);
    } else if (['04', '06', '09', 11].includes(month)) {
      daysInMonth = Array.from({ length: 30 }, (_, i) => i + 1);
    } else {
      daysInMonth = Array.from({ length: 31 }, (_, i) => i + 1);
    }

    setAvailableDays(daysInMonth);
    setSelectedDay(null);
  };
  const administrationFields = useAdministrationFields(
    purpose,
    purpose === 'edit'
      ? customer
      : { files: populatedFiles, info_sheet: populatedInfoSheet },
    refresh,
    setRefresh,
    setFiles,
    setInfoSheet,
    selectedMonth,
    availableDays,
    selectedDay,
    setSelectedDay,
    handleMonthChange
  );
  const technicalFields = useTechnicalFields(user);
  const collaboratorsFields = useCollaboratorsFields(formValues, form);
  const contactsFields = useContactsFields(formValues, form);
  const facturationFields = useFacturationfields(
    paymentSelected,
    setPaymentSelected,
    modificationRib,
    setModificationRib,
    purpose
  );
  const prospectGeneralAdditionalFields = useProspectGeneralAdditionalFields();
  const complementaryInfosFields = useComplementaryInfosFields();
  const commentsAndFilesFields = useCommentsAndFilesFields(
    purpose,
    purpose === 'edit' ? customer : { files: populatedFiles },
    refresh,
    setRefresh,
    setFiles
  );
  const generateFields = useGenerateFormItem();

  const errorValidationAction = (errors) => {
    if (errors?.errorFields?.[0]?.name?.[0]) {
      const fieldName = errors?.errorFields?.[0]?.name?.[0];
      const isFieldInFacturation = facturationFields.some((field) =>
        field.name.includes(fieldName)
      );
      const isFieldInIdentification = identificationFields.some((field) =>
        field.name.includes(fieldName)
      );
      if (isFieldInIdentification) setActiveKey('1');
      if (isFieldInFacturation) setActiveKey('4');
    }
  };

  useEffect(() => {
    if (files.length) {
      files.forEach(async (file) => {
        const fileData = await getFile(file);
        setPopulatedFiles((prev) => [...prev, fileData]);
      });
    }
  }, [files]);

  useEffect(() => {
    if (infoSheet.length) {
      infoSheet.forEach(async (info) => {
        const fileData = await getFile(info);
        setPopulatedInfoSheet((prev) => [...prev, fileData]);
      });
    }
  }, [infoSheet]);

  useEffect(async () => {
    const type = await getTypeFromId(id);
    setUsedCreateType(purpose === 'edit' ? type : createType);
  }, [createType]);

  useEffect(async () => {
    if (purpose === 'edit') {
      await getCustomer(
        id,
        '?populate=files,collaborators,contacts,info_sheet&select=files'
      );
    }
  }, [id, refresh]);

  const config = {
    onCreateResource: {
      setBody: (data) => {
        const savedCreateType = usedCreateType;
        setCreateType('');
        return {
          ...data,
          status: savedCreateType || 'ACTIVE',
          files: populatedFiles.map((file) => file._id),
          info_sheet: populatedInfoSheet.map((file) => file._id)
        };
      }
    },
    onGetResource: {
      setFields: (data) => {
        setPaymentSelected(data?.payment_type);
        setPsNumbeRegistered(data?.ps_number);
        setEmailRegistered(data?.email);
        setPsIdRegistered(data?.type_ps_id);
        if (data?.financial_statement) {
          const { month, day } = data.financial_statement;
          if (month) {
            handleMonthChange(month);
            setSelectedMonth(month);
          }
          if (day) {
            setSelectedDay(day);
          }
        }
        return {
          ...data,
          ...(data?.rib
            ? {
                bic: data?.rib?.bic,
                iban: data?.rib?.iban,
                ics: data?.rib?.ics,
                rum: data?.rib?.rum,
                rib: data?.rib?._id,
                rib_holder: data?.rib?.rib_holder,
                signature_date: data?.rib?.signature_date
                  ? dayjs(data?.rib?.signature_date)
                  : ''
              }
            : {}),
          date_of_birth: data?.date_of_birth ? dayjs(data?.date_of_birth) : '',
          installation_date: data?.installation_date
            ? dayjs(data?.installation_date)
            : '',
          optimization_date: data?.optimization_date
            ? dayjs(data?.optimization_date)
            : '',
          start_activity_date: data?.start_activity_date
            ? dayjs(data?.start_activity_date)
            : '',
          ongoing_startup_phase:
            data?.ongoing_startup_phase?.length > 1
              ? [
                  dayjs(data?.ongoing_startup_phase[0]) || '',
                  dayjs(data?.ongoing_startup_phase[1]) || ''
                ]
              : [],
          start_of_balance_tracking: data?.start_of_balance_tracking
            ? dayjs(data?.start_of_balance_tracking)
            : '',
          financial_statement: data?.financial_statement
        };
      }
    },
    onUpdateResource: {
      setBody: (data) => {
        const filteredData = Object.keys(data).reduce((acc, key) => {
          if (key !== 'files') {
            acc[key] = data[key];
          }
          return acc;
        }, {});
        setCreateType('');
        return {
          ...filteredData,
          rib: data?.rib
        };
      }
    }
  };

  const tabsFilterIndex = {
    ACTIVE: ['1', '2', '3', '4', '8', '9'],
    NON_PS_CUSTOMER: ['1', '2', '3', '4', '8', '9'],
    TO_TRANSFORM: ['1', '2', '3', '4', '8', '9'],
    PROSPECT: ['5', '6', '7', '9']
  };

  const tabs = [
    {
      label: (
        <Typography.Title
          level={5}
          style={{
            color: 'var(--identificationColor)'
          }}
        >
          {t('customers.form.identification')}
        </Typography.Title>
      ),
      key: '1',
      children: identificationFields.map((field) =>
        generateFields('customers', field)
      )
    },
    {
      label: (
        <Typography.Title
          level={5}
          style={{ color: 'var(--administrationColor)' }}
        >
          {t('customers.form.administration')}
        </Typography.Title>
      ),
      key: '2',
      children: administrationFields.map((field) =>
        generateFields('customers', field)
      )
    },
    {
      label: (
        <Typography.Title level={5} style={{ color: 'var(--technicalColor)' }}>
          {t('customers.form.technical')}
        </Typography.Title>
      ),
      key: '3',
      children: technicalFields.map((field) =>
        generateFields('customers', field)
      )
    },
    ...(!user?.role?.startsWith('users')
      ? [
          {
            label: (
              <Typography.Title
                level={5}
                style={{ color: 'var(--billingColor)' }}
              >
                {t('customers.form.billing')}
              </Typography.Title>
            ),
            key: '4',
            children: facturationFields.map((field) =>
              generateFields('customers', field)
            )
          }
        ]
      : []),
    {
      label: t('customers.prospect.titles.general_infos'),
      key: '5',
      children: [...identificationFields, ...prospectGeneralAdditionalFields]
        .sort((a, b) => prospectSort('name', a, b))
        .map((field) => generateFields('customers', field))
    },
    {
      label: t('customers.prospect.titles.complementary_infos'),
      key: '6',
      children: complementaryInfosFields.map((field) =>
        generateFields('customers', field)
      )
    },
    {
      label: t('customers.prospect.titles.comments_and_files'),
      key: '7',
      children: commentsAndFilesFields.map((field) =>
        generateFields('customers', field)
      )
    },
    {
      label: (
        <Typography.Title
          level={5}
          style={{ color: 'var(--collaboratorColor)' }}
        >
          {t('customers.form.collaborators')}
        </Typography.Title>
      ),

      key: '8',
      children: collaboratorsFields.map((field) =>
        generateFields('customers', field)
      )
    },
    {
      label: (
        <Typography.Title level={5} style={{ color: 'var(--contactColor)' }}>
          {t('customers.form.contacts')}
        </Typography.Title>
      ),

      key: '9',
      children: contactsFields.map((field) =>
        generateFields('customers', field)
      )
    }
  ];

  const items = tabs.filter((tab) =>
    tabsFilterIndex[usedCreateType]?.includes(tab.key)
  );

  return (
    <ContentCustom>
      <PageHeaderCustom
        title={
          purpose === 'edit'
            ? `${t(`customers.title_${purpose}.${usedCreateType}`)} - ${
                customer?.company_name || ''
              } - ${customer?.ps_number || ''}`
            : t(`customers.title_${purpose}.${usedCreateType}`)
        }
      />

      {['ACTIVE', 'NON_PS_CUSTOMER', 'TO_TRANSFORM'].includes(
        usedCreateType
      ) ? (
        <CreateUpdateContainer
          tabs={items}
          purpose={purpose}
          baseUrl="customers"
          resource="customers"
          populate="rib,collaborators,contacts"
          withHeader={false}
          config={config}
          errorValidationAction={errorValidationAction}
          activeKey={activeKey}
          setActiveKey={setActiveKey}
          setFormValues={setFormValues}
          needFormDependencies
          customFormInstance={form}
        />
      ) : (
        <CreateUpdateContainer
          layout="vertical"
          fields={[]}
          formExtra={items
            .map((item) => [categoryTitle(item.label), ...item.children])
            .flat()}
          purpose={purpose}
          baseUrl="customers"
          successMessageUrl="prospect"
          resource="customers"
          populate="rib,collaborators,contacts"
          withHeader={false}
          config={config}
          customFormInstance={form}
        />
      )}
    </ContentCustom>
  );
};

CreateUpdateCustomer.propTypes = {
  purpose: propTypes.string.isRequired
};
