import { createContext, useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useAuthContext } from '../../contexts/AuthContext';
import { useErrorMessage } from '../../utils/errorMessage';
import { showSuccessMessage } from '../../utils/showSuccessMessage';

const QualityControlContext = createContext({});

/**
 * A context provider component for managing quality control data and actions.
 *
 * @context
 * @param {Object} props - Component props
 * @param {ReactNode} props.children - The child components to be wrapped by the context provider
 * @returns {ReactNode} The rendered component
 */
export const QualityControlContextProvider = ({ children }) => {
  const { dispatchAPI, user } = useAuthContext();
  const { t } = useTranslation();
  const { message } = useErrorMessage();

  const [collaborators, setCollaborators] = useState();
  const [collaborator, setCollaborator] = useState();
  const [psMacro, setPsMacro] = useState();
  const [enums, setEnums] = useState();

  const [formValues, setFormValues] = useState();
  const [refresh, setRefresh] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [testFile, setTestFile] = useState();
  const [selectedPsMacros, setSelectedPsMacros] = useState([]);
  const getCollaborators = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/users?role=users:LEADER-USER,users:USER&status=ACTIVE`
      });
      setCollaborators(data);
    } catch (e) {
      message(e);
    }
  };

  const getPsMacro = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/psmacro?sort=title`
      });
      setPsMacro(data);
    } catch (e) {
      message(e);
    }
  };

  const getEnums = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/quality/enums`
      });
      setEnums(data);
    } catch (e) {
      message(e);
    }
  };

  const patchQualityControl = async (id, body) => {
    setIsLoading(true);
    try {
      await dispatchAPI('PATCH', {
        url: `/quality/${id}`,
        body
      });
      showSuccessMessage(t, 'quality', 'update');
    } catch (e) {
      message(e);
    }
    setRefresh(!refresh);
    setIsLoading(false);
  };

  const uploadOrDeleteFile = async (file, id, type, key) => {
    setIsLoading(true);
    const formData = new FormData();
    if (type !== 'delete') formData.append(`${key}`, file);
    formData.append('values', JSON.stringify({ ...formValues }));
    try {
      await dispatchAPI('PATCH', {
        url: `/quality/file/${id}?type=${type}&key=${key}&fileId=${file._id}`,
        body: formData
      });
    } catch (e) {
      message(e);
    }
    setRefresh(!refresh);
    setIsLoading(false);
  };

  const archiveQualityControl = async (id) => {
    try {
      await dispatchAPI('DELETE', { url: `/quality/${id}` });
      setRefresh(!refresh);
      showSuccessMessage(t, 'quality', 'archive');
    } catch (e) {
      message(e);
    }
  };

  useEffect(async () => {
    await getCollaborators();
    await getPsMacro();
    await getEnums();
  }, []);

  return (
    <QualityControlContext.Provider
      value={{
        collaborators,
        collaborator,
        psMacro,
        enums,
        setCollaborator,
        setFormValues,
        uploadOrDeleteFile,
        refresh,
        setRefresh,
        testFile,
        setTestFile,
        isLoading,
        patchQualityControl,
        archiveQualityControl,
        selectedPsMacros,
        setSelectedPsMacros,
        user
      }}
    >
      {children}
    </QualityControlContext.Provider>
  );
};

QualityControlContext.propTypes = {
  children: PropTypes.element.isRequired
};

export default () => useContext(QualityControlContext);
