/**
 * Initializes filters based on the selected tab and type.
 * @module initFiltersByTab
 * @param {string} type - Type of data to initialize filters for.
 * @param {string} indexTab - Index of the selected tab.
 * @param {Object} data - Data to set filters based on.
 * @param {Function} setState - Function to set the state of filters.
 * @returns {void}
 */

export const initFiltersByTab = (type, indexTab, data, setState) => {
  switch (indexTab) {
    case '1':
      if (type === 'gtp') setState(data?._id);
      if (type !== 'gtp') setState(null);
      break;
    case '2':
      if (type === 'leader') setState(data?._id);
      if (type !== 'leader') setState(null);
      break;
    case '3':
      if (type === 'macro_ps') setState([data?._id]);
      if (type !== 'macro_ps') setState([]);
      if (type === 'gtp') setState(null);
      if (type === 'leader') setState(null);
      break;
    case '4':
      if (type === 'macro_ps') setState([data?._id]);
      if (type !== 'macro_ps') setState([]);
      if (type === 'gtp') setState(null);
      if (type === 'leader') setState(null);
      break;
    default:
      break;
  }
};
