import { Select } from 'antd';

const { Option } = Select;

export const prospectOrder = [
  'company',
  'micro_ps',
  'type_ps_id',
  'ps_number',
  'company_name',
  'legal_name',
  'social_reason',
  'address',
  'number',
  'street',
  'postal_code',
  'city',
  'billing_address_is_different',
  'phone_number',
  'email',
  'vat'
];

/**
 * prospectSort is a comparator function used for sorting prospect data based on a predefined order.
 *
 * @function
 * @param {string} key - The key to access the display text for each option.
 * @param {Object} a - The first prospect object to compare.
 * @param {Object} b - The second prospect object to compare.
 * @returns {number} A negative value if `a` should come before `b`, a positive value if `a` should come after `b`, or 0 if they are equal in sorting order.
 */

export const prospectSort = (key, a, b) => {
  const aData = a[key]?.length ? a[key][0] : a[key];
  const bData = b[key]?.length ? b[key][0] : b[key];
  const indexA = prospectOrder.indexOf(aData);
  const indexB = prospectOrder.indexOf(bData);
  return indexA - indexB;
};

/**
 * selectFormat is a utility function to generate a Select component with options from an array of enums.
 *
 * @function
 * @param {Function} t - The translation function to translate text.
 * @param {Array} enums - An array of enums to generate options from.
 * @param {boolean} disabled - Whether the Select component should be disabled.
 * @param {Object} options - Additional options for customizing the Select component.
 * @param {boolean} options.showSearch - Whether to show the search input in the Select.
 * @param {boolean} options.allowClear - Whether to allow clearing the selected value.
 * @param {boolean} options.loading - Whether to display a loading state.
 * @param {Object} options.style - Additional styles to apply to the Select.
 * @param {Function} options.filterOption - A function to determine whether an option should be displayed based on the search input.
 * @param {Function} options.onChange - A function called when the selected value changes.
 * @param {Function} options.defaultValue - The default value for the Select.
 * @param {Function} options.initialValue - The initial value for the Select.
 * @param {boolean} options.optionsHaveID - Whether the options have an '_id' field.
 * @param {string} options.optionsKey - The key to access the display text for each option.
 * @param {Function} options.render - A function to render the display text for each option.
 * @param {string} options.mode - The mode of the Select component.
 * @returns {JSX.Element} A Select component with generated options.
 */

export const selectFormat = (t, enums, options, disabled) => {
  const render = (item) => {
    if (options?.render) {
      return options.render(item);
    }
    return t(options?.optionsKey ? item[options?.optionsKey] : item);
  };
  return (
    <Select
      showSearch={options?.showSearch}
      allowClear={options?.allowClear}
      loading={options?.loading}
      style={options?.style}
      filterOption={options?.filterOption}
      onChange={options?.onChange}
      defaultValue={options?.defaultValue}
      initialValue={options?.initialValue}
      mode={options?.mode}
      disabled={disabled}
    >
      {(enums || []).map((item) => (
        <Option
          key={options?.optionsHaveID ? item._id : item}
          value={options?.optionsHaveID ? item._id : item}
        >
          {render(item)}
        </Option>
      ))}
    </Select>
  );
};
