import React from 'react';
import { Route, Routes } from 'react-router-dom';
import ShowStatistics from './ShowStatistics';
import { StatisticsContextProvider } from './StatisticsContext';

/**
 * Component for managing routes related to statistics.
 * @module StatisticsRouter
 * @returns {JSX.Element} React component
 */

export const StatisticsRouter = () => (
  <StatisticsContextProvider>
    <Routes>
      <Route path="/" element={<ShowStatistics />} />
    </Routes>
  </StatisticsContextProvider>
);
