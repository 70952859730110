import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Row, Col, Typography, Divider, Select, Badge, Card } from 'antd';
import dayjs from 'dayjs';
import { ContentCustom, PageHeaderCustom } from '../../components';
import useInternalGestionContext from './InternalGestionContext';
import { FullYearCalendar } from './FullYearCalendar';
import { ListInternalGestion } from './ListInternalGestion';
import { CommonSwitch } from './CommonSwitch';
import { eventsColor } from '../../utils/constants/tagColors';

const { Option } = Select;

export const InternalGestion = () => {
  const { t } = useTranslation();

  const {
    users,
    getWorkHours,
    workHours,
    events,
    weeksTotal,
    monthsTotal,
    getEventsEnums
  } = useInternalGestionContext();

  const [selectedYear, setSelectedYear] = useState(dayjs().year());
  const [selectedPerson, setSelectedPerson] = useState(null);
  const [selectedFilters, setSelectedFilters] = useState(['all']);
  const [calendarView, setCalendarView] = useState(true);
  const [enums, setEnums] = useState({});

  const onsearch = (input, option) => {
    const result = option?.children.toLowerCase();
    return result.includes(input.toLowerCase());
  };

  const options = ['all', 'workHours', 'absence', 'paid_vacation'];

  const generateYearOptions = () => {
    const currentYear = dayjs().year();
    const years = Array.from({ length: 11 }, (_, i) => currentYear - i);
    return years.map((year) => (
      <Option key={year} value={String(year)}>
        {year}
      </Option>
    ));
  };

  useEffect(async () => {
    await getWorkHours(selectedYear, selectedPerson);
    const enumsData = await getEventsEnums();
    setEnums(enumsData);
  }, [selectedPerson, selectedYear]);

  const filterFct = () =>
    selectedFilters.includes('all') || selectedFilters.includes('workHours');

  return (
    <ContentCustom>
      <PageHeaderCustom title={t('internalGestion.title')} />
      {calendarView ? (
        <>
          <Row justify="end">
            <CommonSwitch state={calendarView} setState={setCalendarView} />
          </Row>

          <Row gutter={[24, 24]} align="middle">
            <Col span={21}>
              <Select
                allowClear
                mode="multiple"
                onChange={(value) => {
                  setSelectedFilters(value);
                }}
                onClear={() => {
                  setSelectedFilters(null);
                }}
                defaultValue={selectedFilters}
                style={{ width: '180px', margin: '5px' }}
              >
                {(options || []).map((option) => (
                  <Option key={option} value={option}>
                    {t(`internalGestion.select.${option}`)}
                  </Option>
                ))}
              </Select>

              <Divider type="vertical" />
              <Typography.Text>
                {`${t('internalGestion.select.year')} :`}
              </Typography.Text>
              <Select
                onChange={(value) => {
                  setSelectedYear(value);
                }}
                defaultValue={selectedYear}
                style={{ width: '180px', margin: '5px' }}
              >
                {generateYearOptions()}
              </Select>
              <Divider type="vertical" />
              <Typography.Text>
                {`${t('internalGestion.select.collaborator')} :`}
              </Typography.Text>
              <Select
                allowClear
                onChange={(value) => setSelectedPerson(value)}
                showSearch
                filterOption={(input, option) => onsearch(input, option)}
                onClear={() => {
                  setSelectedPerson(null);
                }}
                style={{ width: '180px', marginLeft: '15px' }}
              >
                {users?.map((user) => (
                  <Option key={user._id} value={user._id}>
                    {`${user.first_name} ${
                      user?.usage_last_name || user?.last_name
                    }`}
                  </Option>
                ))}
              </Select>
            </Col>
          </Row>
        </>
      ) : null}

      {!calendarView ? (
        <ListInternalGestion
          switchElement={
            <CommonSwitch state={calendarView} setState={setCalendarView} />
          }
        />
      ) : (
        <>
          <Row>
            <FullYearCalendar
              year={selectedYear}
              events={(events || []).filter(
                (event) =>
                  selectedFilters.includes('all') ||
                  selectedFilters.includes(event.type.toLowerCase())
              )}
              workHours={workHours.filter(filterFct)}
              weeksTotal={weeksTotal.filter(filterFct)}
              monthsTotal={monthsTotal.filter(filterFct)}
            />
          </Row>
          <Row
            style={{
              marginBottom: '10px'
            }}
            justify="center"
          >
            {enums?.types?.map((type) => (
              <Col
                style={{
                  marginBottom: '30px',
                  zIndex: '1'
                }}
              >
                <Badge.Ribbon
                  text={t(`events.form.enums.${type}`)}
                  color={eventsColor[type]}
                  key={type}
                >
                  <Card
                    style={{
                      backgroundColor: 'white',
                      blockSize: '0px',
                      color: 'white'
                    }}
                    bordered={false}
                  >
                    {t(`events.form.enums.${type}`)}
                  </Card>
                </Badge.Ribbon>
              </Col>
            ))}
          </Row>
        </>
      )}
    </ContentCustom>
  );
};
