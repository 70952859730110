import { Card } from 'antd';
import { useTranslation } from 'react-i18next';
import { ListResource } from '../../../components';
import useHomeContext from '../HomeContext';
import { useColumns } from './actionsReminderColumns';

/**
 * Card component for displaying actions reminders.
 *
 * @component
 * @returns {JSX.Element} The JSX element representing the ActionReminderCard component.
 */
export const ActionReminderCard = () => {
  const { t } = useTranslation();
  const { actionEnums } = useHomeContext();
  const columns = useColumns(actionEnums);
  return (
    <Card title={t('interventions.show.actions_reminder.title')}>
      <ListResource
        resourceName="actions"
        columns={columns}
        contentCustomStyle={{ margin: 0, padding: 0 }}
        resourceModelName="Action"
        populate="created_by,task_number,action,customer"
        withImportButton={false}
        withCreateButton={false}
        withPageHeader={false}
        withSearchBar={false}
        onDoubleClickAction={false}
        extraQuery="action_reminder_done=false&is_action_reminder=true"
        showAction={false}
        editAction={false}
        deleteAction={false}
        useUrlFilter={false}
      />
    </Card>
  );
};
