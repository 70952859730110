import PropTypes from 'prop-types';
import moment from 'moment';
import { CreateUpdateContainer } from '../../components/CreateUpdateContainer/CreateUpdateContainer';
import { useAuthContext } from '../../contexts/AuthContext';
import UserContextProvider from './UserContext';
import useCollapseByCategories from './collapseByCategories';

/**
 * Component for creating or updating user information.
 *
 * @component
 * @param {string} purpose - Purpose of the form ('create' or 'update')
 * @returns {JSX.Element} CreateUpdateContainer component with user creation or update functionality
 */

export const CreateUpdateUser = ({ purpose }) => {
  const { user, setUser } = useAuthContext();
  const {
    setFormValues,
    indentityCard,
    setIndentityCard,
    securityCard,
    setSecurityCard,
    rib,
    setRib,
    familyBook,
    setFamilyBook,
    others,
    setOthers,
    setFiles,
    files,
    refresh
  } = UserContextProvider();
  const { categories, base64, isFieldsLoading } =
    useCollapseByCategories(purpose);

  const config = {
    onGetResource: {
      setFields: (data) => {
        setIndentityCard(data.indentity_card || []);
        setSecurityCard(data.social_security_card || []);
        setRib(data.rib || []);
        setFamilyBook(data.family_record_book || []);
        setOthers(data.others || []);
        setFiles(data.files || []);
        return {
          ...data,
          date_of_employment:
            data.date_of_employment && moment(data.date_of_employment),
          date_of_employment_adjustement:
            data.date_of_employment_adjustement &&
            moment(data.date_of_employment_adjustement),
          health_insurance_start_date:
            data.health_insurance_start_date &&
            moment(data.health_insurance_start_date),
          health_insurance_end_date:
            data.health_insurance_end_date &&
            moment(data.health_insurance_end_date),
          healthcare_coverage_start_date:
            data.healthcare_coverage_start_date &&
            moment(data.healthcare_coverage_start_date),
          healthcare_coverage_end_date:
            data.healthcare_coverage_end_date &&
            moment(data.healthcare_coverage_end_date),
          rdws_periods: (data?.rdws_periods || []).map((rdw) => ({
            start_date: moment(rdw.start_date),
            end_date: moment(rdw.end_date)
          })),
          medical_check_up: (data?.medical_check_up || []).map((medical) => ({
            ...medical,
            date: moment(medical.date)
          })),
          attribution_date:
            data.attribution_date && moment(data.attribution_date),
          key_delivery: data.key_delivery && moment(data.key_delivery),
          date_of_birth: data.date_of_birth && moment(data.date_of_birth),
          children: (data?.children || []).map((child) => ({
            ...child,
            dob: moment(child.dob)
          }))
        };
      }
    },
    onUpdateResource: {
      setBody: (data) => {
        if (data._id === user._id) setUser({ ...user, ...data });
        const formData = new FormData();
        formData.append(
          'values',
          JSON.stringify({ ...data, ...(base64 ? { photo: base64 } : {}) })
        );
        return formData;
      }
    },
    onCreateResource: {
      setBody: (data) => {
        const formData = new FormData();
        formData.append(
          'values',
          JSON.stringify({ ...data, ...(base64 ? { photo: base64 } : {}) })
        );
        if (indentityCard?.length)
          indentityCard.forEach((f) => formData.append('indentity_card', f));
        if (securityCard?.length)
          indentityCard.forEach((f) =>
            formData.append('social_security_card', f)
          );
        if (rib?.length) rib.forEach((f) => formData.append('rib', f));
        if (familyBook?.length)
          familyBook.forEach((f) => formData.append('family_record_book', f));
        if (others?.length) others.forEach((f) => formData.append('others', f));
        if (files?.length) files.forEach((f) => formData.append('files', f));
        return formData;
      }
    }
  };

  return (
    <CreateUpdateContainer
      loadingFields={isFieldsLoading}
      purpose={purpose}
      populate="indentity_card,social_security_card,rib,family_record_book,others,files"
      baseUrl="users"
      resource="users"
      config={config}
      categories={categories}
      setFormValues={setFormValues}
      refresh={refresh}
    />
  );
};

CreateUpdateUser.propTypes = {
  purpose: PropTypes.string.isRequired
};
