import { Input, Checkbox, Form, Select, Tag } from 'antd';
import { useTranslation } from 'react-i18next';
import { RedoOutlined } from '@ant-design/icons';
import { phoneNumberField } from '../../../../utils/phoneNumberField';
import { selectFormat } from '../../CRUDFields/utils';
import { userRoles } from '../../../../utils/constants/tagColors';
import { useAuthContext } from '../../../../contexts/AuthContext';
import { generateUniqueFakeMail } from '../../../../utils/generateUniqueFakeMail';

const { Option } = Select;

/**
 * Custom hook that generates an array of field objects for technical information.
 *
 * This hook is used to create a configuration array of fields (used in forms)
 * that are specifically related to the technical information of a user or entity.
 * It handles the generation of various input types like text inputs, select dropdowns,
 * and checkboxes, along with their corresponding validation rules.
 *
 * @hook
 * @param {object} enums - Enumerated values for various options (like genders, roles).
 * @param {string} id - The ID to be used as the initial value for the `company` field.
 * @param {object} form - The Ant Design form instance for setting field values.
 * @returns {Array} An array of field objects to be used in the form.
 *
 * @example
 * const technicalFields = useTechnicalFields(enums, companyId, formInstance);
 *
 * @property {Array} technicalFields[].name - The name of the field, used as the form field identifier.
 * @property {string} technicalFields[].key - A unique key for the field.
 * @property {JSX.Element} technicalFields[].input - The input element rendered in the form (e.g., Input, Select, Checkbox).
 * @property {Array} [technicalFields[].rules] - Validation rules for the form field.
 * @property {string} [technicalFields[].label] - A label for the form field (if applicable).
 * @property {boolean} [technicalFields[].hidden] - Whether the field is hidden in the form.
 * @property {any} [technicalFields[].initialValue] - The initial value of the form field.
 * @property {string} [technicalFields[].valuePropName] - The property that represents the field's value (e.g., 'checked' for checkboxes).
 */

export const useTechnicalFields = (enums, id, form) => {
  const { t } = useTranslation();
  const { dispatchAPI } = useAuthContext();

  const handleKeyPress = (e) => {
    const { value } = e.target;
    if (!/[0-9]/.test(e.key) || (value.length === 0 && e.key === '0')) {
      e.preventDefault();
    }
  };

  const generateMail = async () => {
    const email = await generateUniqueFakeMail(dispatchAPI);
    form.setFields([
      {
        name: 'email',
        value: email
      }
    ]);
  };

  return [
    {
      name: ['operator_code'],
      key: 'operator_code',
      input: <Input />
    },
    {
      name: ['gender'],
      key: 'gender',
      input: selectFormat(
        t,
        enums?.genders?.map((gender) => ({
          _id: gender,
          name: `users.genders.${gender}`
        })),
        { optionsHaveID: true, optionsKey: 'name' }
      ),
      rules: [{ required: true }]
    },
    {
      name: ['usage_last_name'],
      key: 'usage_last_name',
      input: <Input />,
      rules: [{ required: true }]
    },
    {
      name: ['first_name'],
      key: 'first_name',
      input: <Input />,
      rules: [{ required: true }]
    },
    {
      name: ['email'],
      key: 'email',
      input: (
        <Input.Group compact>
          <Form.Item noStyle name={['email']}>
            <Input />
          </Form.Item>
          <Form.Item noStyle>
            <RedoOutlined onClick={() => generateMail()} />
          </Form.Item>
        </Input.Group>
      ),
      rules: [{ required: true }]
    },
    phoneNumberField,
    {
      name: ['local_phone_number'],
      key: 'local_phone_number',
      label: 'local_phone_number.number',
      input: (
        <Input.Group compact>
          <Form.Item
            noStyle
            name={['local_phone_number', 'country_code']}
            initialValue="+33"
          >
            <Select style={{ width: '20%' }}>
              <Select.Option value="+33">+33</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item
            noStyle
            name={['local_phone_number', 'number']}
            rules={[
              {
                pattern: /^[1-9]\d{8}$/,
                message: t(`messages.phone_number`)
              }
            ]}
          >
            <Input onKeyPress={handleKeyPress} maxLength={9} />
          </Form.Item>
        </Input.Group>
      )
    },
    {
      name: ['role'],
      key: 'role',
      rules: [{ required: true }],
      input: (
        <Select allowClear>
          {(enums?.roles || [])
            .filter((role) => role.startsWith('guests'))
            .map((role) => (
              <Option key={role} value={role}>
                <Tag color={userRoles[role.split(':')[1]]}>
                  {t(`users.tags.${role.split(':')[1]}`)}
                </Tag>
              </Option>
            ))}
        </Select>
      )
    },
    {
      name: ['invoiced'],
      key: 'invoiced',
      input: <Checkbox />,
      valuePropName: 'checked',
      Layout: 'horizontal'
    },
    {
      name: ['report_recipient'],
      key: 'report_recipient',
      input: <Checkbox />,
      valuePropName: 'checked',
      Layout: 'horizontal'
    },
    {
      name: ['company'],
      key: 'company',
      hidden: true,
      initialValue: id
    }
  ];
};
