import { useTranslation } from 'react-i18next';
import { Tag } from 'antd';
import { templateStatus } from '../../utils/constants/tagColors';

/**
 * A custom hook that generates an array of column objects to be used with a data table component.
 * Each column object contains properties like title, key, dataIndex, width, and render to define how data
 * should be displayed in each column of the table.
 *
 * @function
 * @param {boolean} forceRefresh - A boolean to trigger the refresh of the data table.
 * @param {Function} setForceRefresh - A function to update the forceRefresh state.
 * @returns {Array<Object>} An array of column objects for displaying data in a data table.
 *
 * @example
 *
 * const [forceRefresh, setForceRefresh] = useState(false);
 * const columns = useColumns(forceRefresh, setForceRefresh);
 */
export const useColumns = () => {
  const { t } = useTranslation();
  return [
    {
      title: t('template-documents.form.status'),
      key: 'status',
      dataIndex: 'status',
      width: '10%',
      sorter: true,
      render: (status) =>
        status ? (
          <Tag color={templateStatus[status]}>
            {t(`template-documents.form.${status}`)}
          </Tag>
        ) : (
          '-'
        )
    },
    {
      title: t('template-documents.form.title'),
      key: 'title',
      dataIndex: 'title',
      width: '30%',
      sorter: true
    },
    {
      title: t('template-documents.form.mailTypes'),
      key: 'mailTypes',
      dataIndex: 'mailTypes',
      width: '30%',
      sorter: true,
      render: (mailTypes) =>
        mailTypes ? t(`template-documents.form.${mailTypes}`) : '-'
    },
    {
      title: t('template-documents.form.author'),
      key: 'author',
      dataIndex: 'author',
      width: '30%',
      sorter: true,
      render: (author) => `${author?.first_name} ${author?.last_name}`
    },
    {
      title: t('template-documents.form.macro_ps'),
      key: 'macro_ps',
      dataIndex: 'macro_ps',
      sorter: true,
      width: '30%',
      render: (macro_ps, record) => {
        if (Array.isArray(record.macro_ps) && record.macro_ps.length > 0) {
          return record.macro_ps.map((item) => item.title).join(', ');
        }

        return '-';
      }
    }
  ];
};
