import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { Button, Divider, Tag } from 'antd';
import {
  EyeOutlined,
  EditOutlined,
  PlusCircleOutlined
} from '@ant-design/icons';
import {
  userPositions,
  userEmployer
} from '../../../../utils/constants/tagColors';
import { useAuthContext } from '../../../../contexts/AuthContext';
import { isAuthorized } from '../../../../utils/constants/authorizedMenu';
import { formatValue } from '../../../../utils/formatNumber';

const iconSize = 18;

export const useColumns = (options) => {
  const { t } = useTranslation();
  const { user } = useAuthContext();
  const location = useLocation();
  const { pathname } = location;

  const hasAccess = (purpose) => isAuthorized(pathname, user?.role, purpose);

  return [
    {
      title: t('employments.columns.company'),
      key: 'company',
      dataIndex: ['company'],
      render: (company) => (
        <Tag color={userEmployer[company]}>
          {t(`users.employers.${company}`)}
        </Tag>
      ),
      sorter: true
    },
    {
      title: t('employments.columns.contract_type'),
      key: 'contract_type',
      dataIndex: ['contract_type'],
      sorter: true
    },
    {
      title: t('employments.columns.position'),
      key: 'position',
      dataIndex: ['position'],
      render: (position) => (
        <Tag color={userPositions[position]}>
          {t(`employments.tags.${position}`)}
        </Tag>
      ),
      sorter: true
    },
    {
      title: t('employments.columns.start_date'),
      key: 'start_date',
      dataIndex: ['start_date'],
      render: (start_date) => formatValue(start_date, 'date'),
      sorter: true
    },
    {
      title: t('employments.columns.end_date'),
      key: 'end_date',
      dataIndex: ['end_date'],
      render: (end_date) => (end_date ? formatValue(end_date, 'date') : ''),
      sorter: true
    },
    {
      key: 'action',
      align: 'right',
      render: (record) => (
        <>
          {hasAccess('create') && !record?.end_date && (
            <>
              <Button
                type="ghost"
                onClick={() => options?.createAmendment(record)}
                icon={<PlusCircleOutlined style={{ fontSize: iconSize }} />}
              >
                Nouvel avenant
              </Button>
              <Divider type="vertical" />
            </>
          )}
          <Button
            type="ghost"
            onClick={() => options?.show(record._id)}
            icon={<EyeOutlined style={{ fontSize: iconSize }} />}
          />
          {hasAccess('edit') && !record?.end_date && (
            <>
              <Divider type="vertical" />
              <Button
                type="ghost"
                onClick={() => options?.edit(record._id)}
                icon={<EditOutlined style={{ fontSize: iconSize }} />}
              />
            </>
          )}
        </>
      )
    }
  ];
};
