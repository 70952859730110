import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Card, Row, Col } from 'antd';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer
} from 'recharts';
import StatisticsContextProvider from './StatisticsContext';
import { graphcolors } from '../../utils/constants/tagColors';
import { CustomSpin } from '../../components/CustomSpin/CustomSpin';

/**
 * Customized label component for the LineChart.
 * @module CustomizedLabel
 * @param {Object} props - Props passed to the customized label component.
 * @param {number} props.x - X coordinate of the label.
 * @param {number} props.y - Y coordinate of the label.
 * @param {string} props.stroke - Stroke color of the label.
 * @param {(number|string)} props.value - Value of the label.
 * @returns {JSX.Element} React component
 */

const CustomizedLabel = ({ x, y, stroke, value }) => (
  <text x={x} y={y} dy={-4} fill={stroke} fontSize={10} textAnchor="middle">
    {value}
  </text>
);

/**
 * Customized axis tick component for the LineChart.
 * @module CustomizedAxisTick
 * @param {Object} props - Props passed to the customized axis tick component.
 * @param {number} props.x - X coordinate of the tick.
 * @param {number} props.y - Y coordinate of the tick.
 * @param {Object} props.payload - Tick payload containing value.
 * @param {string} props.payload.value - Value of the tick, representing a month.
 * @returns {JSX.Element} React component
 */

const CustomizedAxisTick = ({ x, y, payload }) => {
  const { t } = useTranslation();
  return (
    <g transform={`translate(${x},${y})`}>
      <text
        x={0}
        y={0}
        dy={16}
        textAnchor="end"
        fill="#666"
        transform="rotate(-35)"
      >
        {t(`statistics.months.${payload.value}`)}
      </text>
    </g>
  );
};

/**
 * React component for rendering PS (Problem Solving) statistics chart.
 * @module ChartSoftware
 * @returns {JSX.Element} React component
 */

export const ChartSoftware = () => {
  const { t } = useTranslation();
  const { chartDatas, isChartsLoading } = StatisticsContextProvider();

  if (isChartsLoading) {
    return <CustomSpin />;
  }

  return (
    <Row gutter={[16, 16]}>
      <Col span={24}>
        <Card title={t('statistics.titles.fse')}>
          <ResponsiveContainer height={300}>
            <LineChart
              data={chartDatas?.updatedSoftwares}
              margin={{
                top: 20,
                right: 30,
                left: 20,
                bottom: 10
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis
                dataKey="name"
                height={60}
                tick={<CustomizedAxisTick />}
                domain={[1, 12]}
              />
              <YAxis />
              <Tooltip />
              <Legend />
              {chartDatas?.updatedSoftwares &&
                Object.keys(chartDatas?.updatedSoftwares?.[0])?.map(
                  (key, index) => {
                    if (key !== 'name') {
                      return (
                        <Line
                          key={key}
                          type="monotone"
                          dataKey={key}
                          stroke={graphcolors[index % graphcolors.length]}
                          name={key?.replace('_', ' ')}
                        />
                      );
                    }
                    return null;
                  }
                )}
            </LineChart>
          </ResponsiveContainer>
        </Card>
      </Col>
      <Col span={24}>
        <Card title={t('statistics.titles.intervention_hours')}>
          <ResponsiveContainer height={300}>
            <LineChart
              data={chartDatas?.hoursInterventionSoftware}
              margin={{
                top: 20,
                right: 30,
                left: 20,
                bottom: 10
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis
                dataKey="name"
                height={60}
                tick={<CustomizedAxisTick />}
                domain={[1, 12]}
              />
              <YAxis />
              <Tooltip />
              <Legend />
              {chartDatas?.hoursInterventionSoftware &&
                Object.keys(chartDatas?.hoursInterventionSoftware?.[0])?.map(
                  (key, index) => {
                    if (key !== 'name') {
                      return (
                        <Line
                          key={key}
                          type="monotone"
                          dataKey={key}
                          stroke={graphcolors[index % graphcolors.length]}
                          name={key?.replace('_', ' ')}
                        />
                      );
                    }
                    return null;
                  }
                )}
            </LineChart>
          </ResponsiveContainer>
        </Card>
      </Col>
    </Row>
  );
};

CustomizedLabel.propTypes = {
  x: PropTypes.number.isRequired,
  y: PropTypes.number.isRequired,
  stroke: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired
};

CustomizedAxisTick.propTypes = {
  x: PropTypes.number.isRequired,
  y: PropTypes.number.isRequired,
  payload: PropTypes.shape({
    value: PropTypes.string.isRequired
  }).isRequired
};
