import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Row,
  Flex,
  Col,
  Typography,
  Collapse,
  List,
  Tag,
  Divider,
  Popconfirm,
  Button
} from 'antd';
import dayjs from 'dayjs';
import { useDrop } from 'react-dnd';
import {
  EditOutlined,
  WarningOutlined,
  CloseOutlined,
  EyeOutlined
} from '@ant-design/icons';
import OccupancyChartContextProvider from '../OccupancyChartContext';
import {
  interventionsStatus,
  eventsColor,
  eventStatus
} from '../../../utils/constants/tagColors';
import { routes, subRoutes } from '../../../utils/constants/adminRoutes';
import { formatValue } from '../../../utils/formatNumber';

const GtpContent = ({ gtp, t }) => {
  const { openModal, deleteIntervention } = OccupancyChartContextProvider();
  const [activeKey, setActiveKey] = useState(null);

  const handleCollapseChange = (key) => {
    setActiveKey(key);
  };

  const [{ isOver }, drop] = useDrop(() => ({
    accept: 'GRID',
    drop: (item) => {
      openModal(item, gtp, 'create');
      return {};
    },
    collect: (monitor) => ({
      isOver: monitor.isOver()
    })
  }));

  const dropStyle = {
    backgroundColor: isOver
      ? 'var(--itemActiveBackground)'
      : 'var(--clientColor)'
  };

  return (
    <Col xs={24} xl={12} key={gtp._id} ref={drop}>
      <Collapse
        accordion
        activeKey={activeKey}
        onChange={handleCollapseChange}
        className="gtp-collapse"
        style={{ ...dropStyle }}
      >
        <Collapse.Panel
          key={gtp._id}
          header={
            <>
              <Row justify="space-between">
                <Col>
                  <Typography.Title
                    level={5}
                    style={{
                      color: 'var(--userColor)',
                      marginBottom: 4
                    }}
                    strong={false}
                  >
                    {`${gtp?.user?.first_name} ${
                      gtp?.user?.usage_last_name || gtp?.user?.last_name
                    }`}
                  </Typography.Title>
                </Col>
                <Col
                  style={{
                    color:
                      (
                        (gtp?.work_hours || 0) - (gtp?.total_hours || 0)
                      ).toFixed(1) !== 0
                        ? 'red'
                        : ''
                  }}
                >
                  {`${(
                    (gtp?.work_hours || 0) - (gtp?.total_hours || 0)
                  ).toFixed(1)}h`}
                </Col>
              </Row>
              <Row justify="space-between">
                <Col style={{ color: 'var(--textColorSecondary)' }}>
                  {t('occupancies.list.work_hours')}
                </Col>
                <Col style={{ color: 'var(--textColorSecondary)' }}>{`${
                  gtp?.work_hours || 0
                }h`}</Col>
              </Row>
            </>
          }
        >
          <List>
            {(gtp?.interventions || []).map((intervention) => (
              <List.Item key={intervention._id}>
                <Row
                  justify="space-between"
                  align="middle"
                  style={{ width: '100%' }}
                >
                  <Col span={6}>
                    <Tag>{intervention?.ps_number}</Tag>
                  </Col>
                  <Col span={8}>
                    <span style={{ color: 'var(--textColorSecondary' }}>
                      {`${intervention?.customer?.company_name} - ${
                        intervention?.service?.total_hours_per_week &&
                        intervention?.service?.total_hours_per_week.toFixed(0)
                      }h${
                        intervention?.service?.frequency
                          ? t('occupancies.carousel.tags.WEEK')
                          : ''
                      }`}
                    </span>
                  </Col>
                  <Col span={5}>
                    <Tag color={interventionsStatus[intervention?.status]}>
                      {t(
                        `interventions.form.enums.${
                          intervention?.status || 'N/A'
                        }`
                      )}
                    </Tag>
                  </Col>
                  <Col span={2}>
                    <Tag>{`${intervention?.scheduled_time}h`}</Tag>
                  </Col>

                  <Col span={3}>
                    {intervention?.status === 'PROVISIONAL' && (
                      <Flex justify="end" align="center">
                        <EditOutlined
                          onClick={() =>
                            openModal(
                              {
                                _id: intervention?.service?._id,
                                type: intervention?.service?.type,
                                total_hours: intervention?.service?.total_hours,
                                frequency: intervention?.service?.frequency
                              },
                              null,
                              'edit',
                              intervention._id
                            )
                          }
                        />
                        <Divider type="vertical" />
                        <Popconfirm
                          title={t('datatable.column.action.abort.title')}
                          okText={t('datatable.column.action.abort.ok')}
                          okButtonProps={{ type: 'danger' }}
                          cancelText={t('datatable.column.action.abort.cancel')}
                          onConfirm={() => deleteIntervention(intervention._id)}
                          icon={<WarningOutlined />}
                        >
                          <CloseOutlined
                            style={{ color: 'var(--errorColor)' }}
                            type="delete"
                          />
                        </Popconfirm>
                      </Flex>
                    )}
                  </Col>
                </Row>
              </List.Item>
            ))}
            {(gtp?.events || []).map((event) => (
              <List.Item key={event._id}>
                <Row
                  justify="space-between"
                  align="middle"
                  style={{ width: '100%' }}
                >
                  <Col span={6}>
                    <Tag color={eventsColor[event?.type]}>
                      {t(`events.form.enums.${event.type}`)}
                    </Tag>
                  </Col>
                  <Col span={8}>
                    <span style={{ color: 'var(--textColorSecondary' }}>
                      {formatValue(event.startTime, 'date')}
                    </span>
                  </Col>
                  <Col span={5}>
                    <Tag color={eventStatus[event?.status]}>
                      {t(`events.form.enums.${event.status}`)}
                    </Tag>
                  </Col>
                  <Col span={2}>
                    <Tag>
                      {`${
                        dayjs(event.endTime).diff(
                          dayjs(event.startTime),
                          'hour',
                          true
                        ) || 0
                      }h`}
                    </Tag>
                  </Col>
                  <Col span={3}>
                    <Button
                      type="link"
                      className="event-btn first"
                      style={{ float: 'right' }}
                    >
                      <a
                        href={`${
                          routes.OCCUPANCY_CHART
                        }${`${subRoutes.OCCUPANCY_CHART.OCCUPANCY}`}/show/${
                          event?._id
                        }`}
                        target="_blank"
                        rel="noopener noreferrer"
                        aria-label="Voir l'intervention ou rh"
                      >
                        <EyeOutlined />
                      </a>
                    </Button>
                  </Col>
                </Row>
              </List.Item>
            ))}
          </List>
        </Collapse.Panel>
      </Collapse>
    </Col>
  );
};

export default GtpContent;

GtpContent.propTypes = {
  t: PropTypes.func.isRequired,
  gtp: PropTypes.shape({
    work_hours: PropTypes.number,
    total_hours: PropTypes.number,
    _id: PropTypes.string,
    user: PropTypes.shape({
      first_name: PropTypes.string,
      usage_last_name: PropTypes.string,
      last_name: PropTypes.string
    }),
    interventions: PropTypes.arrayOf(PropTypes.shape()),
    events: PropTypes.arrayOf(PropTypes.shape())
  }).isRequired
};
