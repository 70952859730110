import { createContext, useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useAuthContext } from '../../contexts/AuthContext';
import { useErrorMessage } from '../../utils/errorMessage';
import { ContentCustom } from '../../components';

const CalendarContext = createContext({});

/**
 * A context provider component for managing calendar-related data and actions.
 *
 * @context
 * @param {Object} props - Component props
 * @param {ReactNode} props.children - The child components to be wrapped by the context provider
 * @returns {ReactNode} The rendered component
 */
export const CalendarContextProvider = ({ children }) => {
  const { dispatchAPI, user } = useAuthContext();
  const { message } = useErrorMessage();

  const [enums, setEnums] = useState({});
  const [isLoadingEnums, setIsLoadingEnums] = useState(true);
  const [type, setType] = useState(null);
  const [leadersAndGtp, setLeadersAndGtp] = useState([]);
  const isAdmin = user.role.split(':')[0] === 'admins';

  const getEnums = async () => {
    try {
      const { data } = await dispatchAPI('GET', { url: '/events/enums' });
      const filteredTypes = data.types.filter(
        (t) => t !== 'INTERVENTION' && (isAdmin || t !== 'ADDITIONAL_HOUR')
      );
      data.types = filteredTypes;
      setEnums(data);
    } catch (error) {
      message(error);
    }
  };

  const getFile = async (id) => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/files/${id}`
      });
      return data;
    } catch (e) {
      message(e);
      return {};
    }
  };

  const getLeadersAndGtp = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: 'users?role=users:LEADER-USER,users:USER'
      });
      setLeadersAndGtp(data);
    } catch (error) {
      message(error);
    }
  };

  useEffect(async () => {
    await getEnums();
    await getLeadersAndGtp();
    setIsLoadingEnums(false);
  }, []);

  const paidVacationsOrder = (a, b) => {
    const order = [
      'type',
      'user',
      'total_cp',
      'reason',
      'start_date',
      'startTime',
      'end_date',
      'endTime',
      'date',
      'makeUpTime',
      'paidLeaveInAdvance',
      'waiveExtraTimeOff',
      'comment',
      'file',
      'created_by'
    ];
    const indexA = order.indexOf(a.name[0]);
    const indexB = order.indexOf(b.name[0]);
    return indexA - indexB;
  };

  return (
    <ContentCustom>
      <CalendarContext.Provider
        value={{
          enums,
          isLoadingEnums,
          getFile,
          type,
          setType,
          paidVacationsOrder,
          leadersAndGtp,
          isAdmin
        }}
      >
        {children}
      </CalendarContext.Provider>
    </ContentCustom>
  );
};

CalendarContext.propTypes = {
  children: PropTypes.element.isRequired
};

export default () => useContext(CalendarContext);
