import PropTypes from 'prop-types';
import { Card } from 'antd';
import { useTranslation } from 'react-i18next';
import { DescriptionList } from '../../../../components';
import { useListContent } from './listContent';

/**
 * ConversionRateCard component that shows the conversion rate information of the quality control
 *
 * @component
 * @param {object} conversionRate - Conversion rate object
 * @return {component} - ConversionRateCard component
 */
export const ConversionRateCard = ({ conversionRate }) => {
  const { t } = useTranslation();
  const listContent = useListContent(conversionRate);
  return (
    <Card title={t('quality.form.conversion_rate.title')}>
      <DescriptionList data={listContent} translate columns={24} />
    </Card>
  );
};

ConversionRateCard.propTypes = {
  conversionRate: PropTypes.shape({})
};

ConversionRateCard.defaultProps = {
  conversionRate: {}
};
