import { useTranslation } from 'react-i18next';
import {
  ContainerOutlined,
  DownloadOutlined,
  EyeOutlined,
  LoadingOutlined,
  WarningOutlined,
  CheckOutlined
} from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { Tag, Popconfirm, Popover, Button, Divider } from 'antd';
import { routes, subRoutes } from '../../utils/constants/adminRoutes';
import { formatValue } from '../../utils/formatNumber';

const iconSize = 18;

/**
 * Custom hook for generating columns for the transfers table.
 *
 * @param {Function} updateTransfer Function to call for updating a transfer.
 * @param {Function} downloadContent Function to call for downloading the content of a transfer.
 * @param {Function} deleteTransfer Function to call for deleting a transfer.
 * @param {Function} setForceRefresh Function to toggle the refresh state.
 * @param {Object} isDownloading Object containing the download state for each transfer.
 * @returns {Array} An array of objects representing the columns for the transfers table.
 */
const useColumns = (
  updateTransfer,
  downloadContent,
  deleteTransfer,
  setForceRefresh,
  isDownloading
) => {
  const { t } = useTranslation();

  const calculateTotalWithDebt = (total, paymentsInfo) => {
    let newTotal = total;
    if (paymentsInfo) {
      Object.entries(paymentsInfo).map((item) => {
        newTotal -= item[1].debt;
        return true;
      });
    }
    return newTotal;
  };

  return [
    {
      title: t('transfers.columns.sent'),
      dataIndex: 'sent',
      key: 'sent',
      sorter: true,
      render: (sent, { _id, status }) => (
        <>
          <Tag color={sent ? 'green' : 'red'}>
            {sent ? t('transfers.tags.sent') : t('transfers.tags.unsent')}
          </Tag>
          {sent || status === 'ARCHIVED' ? (
            ''
          ) : (
            <Popconfirm
              title={t('transfers.columns.message.sent.title')}
              okText={t('transfers.columns.message.sent.ok')}
              okButtonProps={{ type: 'danger' }}
              cancelText={t('transfers.columns.message.sent.cancel')}
              onConfirm={() => updateTransfer(_id, { sent: true })}
              icon={<WarningOutlined />}
            >
              <CheckOutlined style={{ color: 'green' }} />
            </Popconfirm>
          )}
        </>
      ),
      filters: [true, false].map((b) => ({
        text: t(`transfers.tags.${b ? 'sent' : 'unsent'}`),
        value: b
      }))
    },
    {
      title: t('transfers.columns.month'),
      dataIndex: 'month',
      key: 'month',
      sorter: true
    },
    {
      title: t('transfers.columns.levy_date'),
      dataIndex: 'levy_date',
      key: 'levy_date',
      sorter: true,
      render: (date) => formatValue(date, 'date')
    },
    {
      title: t('transfers.columns.created_at'),
      dataIndex: 'created_at',
      key: 'created_at',
      sorter: true,
      render: (date) => formatValue(date, 'date')
    },
    {
      title: t('transfers.columns.levy_count'),
      dataIndex: 'levy_count',
      key: 'levy_count',
      sorter: true
    },
    {
      title: t('transfers.columns.total'),
      dataIndex: 'total',
      key: 'total',
      sorter: true,
      render: (total, { payments_info }) =>
        `${formatValue(
          calculateTotalWithDebt(total, payments_info),
          'currency',
          true
        )}`
    },
    {
      title: t('transfers.columns.comment'),
      dataIndex: 'comment',
      key: 'comment'
    },
    {
      title: t('transfers.columns.rejected'),
      dataIndex: 'rejected',
      key: 'rejected',
      render: (rejected) =>
        rejected ? <Tag color="red">{t(`switch.true`)}</Tag> : ''
    },
    {
      key: 'action',
      align: 'right',
      render: (record) => (
        <>
          <Link
            to={{
              pathname: `${routes.BILLING}${subRoutes.BILLING.TRANSFERS}/show/${record?._id}`
            }}
          >
            <EyeOutlined style={{ fontSize: iconSize }} />
          </Link>
          <Divider type="vertical" />
          <Popover
            content={() => downloadContent(record?._id, record)}
            title={t('buttons.download')}
            trigger="click"
            placement="left"
          >
            <Button style={{ padding: 0 }} type="link">
              {isDownloading[record?.key] ? (
                <LoadingOutlined />
              ) : (
                <DownloadOutlined />
              )}
            </Button>
          </Popover>
          {!record?.sent && record?.status !== 'ARCHIVED' ? (
            <>
              <Divider type="vertical" />
              <Popconfirm
                title={t('datatable.column.action.delete.title')}
                okText={t('datatable.column.action.delete.ok')}
                okButtonProps={{ type: 'danger' }}
                cancelText={t('datatable.column.action.delete.cancel')}
                onConfirm={async () => {
                  setForceRefresh(false);
                  await deleteTransfer(record?._id, () =>
                    setForceRefresh(true)
                  );
                }}
                icon={<WarningOutlined />}
              >
                <ContainerOutlined
                  style={{ fontSize: iconSize, color: 'var(--errorColor)' }}
                  type="delete"
                />
              </Popconfirm>
            </>
          ) : (
            []
          )}
        </>
      )
    }
  ];
};

export default useColumns;
