import { useCallback, useEffect, useState } from 'react';
import { useParams, Link, useLocation } from 'react-router-dom';
import { Button, Divider, Typography, Flex } from 'antd';
import { useTranslation } from 'react-i18next';
import {
  EditOutlined,
  EyeOutlined,
  PrinterOutlined,
  CloseOutlined
} from '@ant-design/icons';
import { useAuthContext } from '../../contexts/AuthContext';
import { useErrorMessage } from '../../utils/errorMessage';
import { ContentCustom, PageHeaderCustom } from '../../components';
import { routes } from '../../utils/constants/adminRoutes';
import { ShowServices } from '../quotes/ShowCards/ShowServices';
import ContractContextProvider from './ContractContext';
import ModalConfirmationContract from '../quotes/ModalConfirmationContract';
import { ShowPrincipalInformations } from './ShowCards/ShowPrincipalInformations';
import { ShowInvoicesAndInterventions } from './ShowCards/InvoicesAndInterventions/ShowInvoicesAndInterventions';
import TerminationModal from './ShowCards/TerminationModal';
import AdjustedHourModal from './AdjustedHourModal';
import { showSuccessMessage } from '../../utils/showSuccessMessage';
import { isAuthorized } from '../../utils/constants/authorizedMenu';
import { CustomSpin } from '../../components/CustomSpin/CustomSpin';

/**
 * Component for displaying detailed information about a contract.
 *
 * @component
 * @returns {JSX.Element} Rendered ShowContract component
 */

export const ShowContract = () => {
  const { id } = useParams();
  const { t } = useTranslation();
  const location = useLocation();
  const { pathname } = location;
  const { message } = useErrorMessage();
  const { dispatchAPI, user } = useAuthContext();
  const {
    leaders,
    usersGtp,
    patchContract,
    isModalOpen,
    setIsModalOpen,
    forceRefresh,
    setForceRefresh,
    contractEnums,
    contract,
    setContract
  } = ContractContextProvider();
  const [isLoading, setIsLoading] = useState(false);
  const [isTerminationModalOpen, setIsTerminationModalOpen] = useState(false);
  const [isModalHourOpen, setIsModalHourOpen] = useState(false);
  const [serviceId, setServiceId] = useState('');
  const [adjustedHours, setAdjustedHours] = useState(0);

  const hasAccess = (purpose) => isAuthorized(pathname, user?.role, purpose);

  const getContract = useCallback(async () => {
    setIsLoading(true);
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/contracts/${id}?populate=quote,sale,micro_ps,macro_ps,services.user_referent leader,customer.contacts`
      });
      setContract(data);
    } catch (e) {
      message(e);
    }
  }, [id]);

  const openModalAdjustedHours = (hours, servId) => {
    setIsModalHourOpen(true);
    setServiceId(servId);
    setAdjustedHours(hours);
  };

  const closeModalAdjustedHours = () => {
    setIsModalHourOpen(false);
    setServiceId('');
    setAdjustedHours(0);
  };

  const patchServiceAdjustedHour = async (idService, values) => {
    try {
      await dispatchAPI('PATCH', {
        url: `/services/${idService}`,
        body: values
      });
      setForceRefresh(forceRefresh + 1);
      showSuccessMessage(t, 'hour', 'adjusted');
      closeModalAdjustedHours();
    } catch (e) {
      message(e);
    }
  };

  const terminationContract = async (values) => {
    try {
      await dispatchAPI('PATCH', {
        url: `/contracts/${id}`,
        body: values
      });
      setForceRefresh(forceRefresh + 1);
      showSuccessMessage(t, 'contracts', 'resiliation');
      setIsTerminationModalOpen(false);
    } catch (e) {
      message(e);
    }
  };

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = async (values) => {
    await patchContract(values, contract._id);
  };

  const closeTerminationModal = async (values) => {
    await terminationContract(values);
  };

  const formattedData = (services) => {
    const formatData = services.map((service) => ({
      ...service,
      leader: service?.leader?._id,
      user_referent: service?.user_referent?._id
    }));
    return formatData;
  };

  useEffect(() => {
    (async () => {
      await getContract();
      setIsLoading(false);
    })();
  }, [getContract, forceRefresh]);

  return isLoading ? (
    <ContentCustom>
      <CustomSpin />
    </ContentCustom>
  ) : (
    <ContentCustom>
      {isModalHourOpen && (
        <AdjustedHourModal
          openModal={isModalHourOpen}
          closeModalAdjustedHours={closeModalAdjustedHours}
          id={serviceId}
          adjustedHours={adjustedHours}
          patchServiceAdjustedHour={patchServiceAdjustedHour}
        />
      )}
      {isModalOpen && (
        <ModalConfirmationContract
          closeModal={closeModal}
          services={formattedData(contract?.services)}
          setIsModalOpen={setIsModalOpen}
          isModalOpen={isModalOpen}
          leaders={leaders}
          usersGtp={usersGtp}
          comment={contract?.comment}
        />
      )}
      {isTerminationModalOpen && (
        <TerminationModal
          closeTerminationModal={closeTerminationModal}
          contract={contract}
          setIsTerminationModalOpen={setIsTerminationModalOpen}
          isTerminationModalOpen={isTerminationModalOpen}
          contractEnums={contractEnums}
        />
      )}
      <PageHeaderCustom
        title={`${t('contracts.show.title')} ${contract?.code}`}
        extra={
          <Flex gap={8} wrap>
            {contract?.status !== 'TERMINATED' && (
              <Button
                type="primary"
                // onClick={printQuote}
              >
                <PrinterOutlined />
                {`${t(`contracts.show.buttons.print`)} `}
              </Button>
            )}

            <Link
              to={{
                pathname: `${routes.CUSTOMERS}/show/${contract?.customer._id}`
              }}
            >
              <Button type="primary">
                <EyeOutlined />
                {`${t(
                  `contracts.show.buttons.show${
                    contract?.customer?.status === 'PROSPECT'
                      ? '_PROSPECT'
                      : '_ACTIVE'
                  }`
                )} `}
              </Button>
            </Link>
            <Link
              to={{ pathname: `${routes.QUOTE}/show/${contract?.quote?._id}` }}
            >
              <Button type="primary">
                <EyeOutlined />
                {`${t('contracts.show.buttons.show_quote')} `}
              </Button>
            </Link>
            {hasAccess('edit') && contract?.status !== 'TERMINATED' && (
              <Button type="primary" onClick={openModal}>
                <EditOutlined />
                {t('buttons.edit')}
              </Button>
            )}
            {hasAccess('terminate') &&
              contract?.status !== 'TERMINATED' &&
              !contract?.termination_date && (
                <Button
                  type="link"
                  danger
                  onClick={() => setIsTerminationModalOpen(true)}
                >
                  <CloseOutlined />
                  {`${t(`contracts.show.buttons.termination`)} `}
                </Button>
              )}
          </Flex>
        }
      />
      {!isLoading && (
        <Flex vertical gap={24}>
          <ShowPrincipalInformations data={contract} />
          <Divider>
            <Typography.Title
              level={4}
              style={{
                fontWeight: 500,
                color: 'var(--userColor)',
                margin: 0
              }}
              strong={false}
            >
              {t('contracts.show.titles.services')}
            </Typography.Title>
          </Divider>
          <ShowServices
            data={contract}
            openModalAdjustedHours={openModalAdjustedHours}
          />
          <ShowInvoicesAndInterventions id={id} />
        </Flex>
      )}
    </ContentCustom>
  );
};
