import PropTypes from 'prop-types';
import { ListResource } from '../../../../components';
import { useColumns } from './absencesColumns';
import UserContextProvider from '../../UserContext';
import { routes, subRoutes } from '../../../../utils/constants/adminRoutes';

/**
 * Component for listing user absences with filtering options.
 *
 * @component
 * @returns {JSX.Element} Rendered ListAbsences component
 */

export const ListAbsences = ({ id }) => {
  const { enums } = UserContextProvider();
  const columns = useColumns(enums);

  return (
    <ListResource
      resourceName="events/absence-vacation"
      contentCustomStyle={{ margin: 0, padding: 0 }}
      columns={columns}
      resourceModelName="Event"
      editAction={false}
      showAction={{
        pathname: (record) =>
          `${routes.RH}${subRoutes.RH.ABSENCE_VACATION}/show/${record._id}`
      }}
      withCreateButton={false}
      withPageHeader={false}
      extraQuery={`type=ABSENCE,PTO&user=${id}`}
    />
  );
};

ListAbsences.propTypes = {
  id: PropTypes.string.isRequired
};
