import { useState, useEffect } from 'react';
import {
  Input,
  InputNumber,
  Button,
  Select,
  Space,
  Divider,
  DatePicker,
  Switch,
  TimePicker,
  Popover,
  Row,
  Col
} from 'antd';
import { CloseOutlined, EyeOutlined, PlusOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { useAuthContext } from '../../contexts/AuthContext';
import useInterventionContext from './InterventionContext';
import { routes } from '../../utils/constants/adminRoutes';

const { TextArea } = Input;
const { Option } = Select;

/**
 * Custom hook to manage and return the form fields for the intervention actions.
 *
 * @param {string} purpose - The purpose of the form, either 'create' or 'edit'.
 * @param {function} setIsNewOperatorCode - Function to set the state for new operator code input.
 * @param {boolean} isNewOperatorCode - Boolean indicating if a new operator code is being added.
 * @param {string} selectedType - The selected type of action.
 * @param {function} setSelectedType - Function to set the selected type of action.
 * @param {string} actionSelected - The selected action ID.
 * @param {function} setActionSelected - Function to set the selected action ID.
 * @param {boolean} isActionReminder - Boolean indicating if the action is a reminder.
 * @param {string} rejectionType - The selected rejection type ID.
 * @param {function} setRejectionType - Function to set the rejection type.
 *
 * @returns {Object} An object containing the fields array and a loading state.
 * @returns {Array} fields - Array of objects representing the form fields.
 * @returns {boolean} isFieldsLoading - Boolean indicating if the fields are still loading.
 *
 * @example
 * const { fields, isFieldsLoading } = useFields(
 *   purpose,
 *   setIsNewOperatorCode,
 *   isNewOperatorCode,
 *   selectedType,
 *   setSelectedType,
 *   actionSelected,
 *   setActionSelected,
 *   isActionReminder,
 *   rejectionType,
 *   setRejectionType
 * );
 */

const useFields = (
  purpose,
  setIsNewOperatorCode,
  isNewOperatorCode,
  selectedType,
  setSelectedType,
  actionSelected,
  setActionSelected,
  isActionReminder,
  rejectionType,
  setRejectionType
) => {
  const { t } = useTranslation();
  const { user } = useAuthContext();
  const [isFieldsLoading, setIsFieldsLoading] = useState(true);
  const [showLinkedActions, setShowLinkedActions] = useState(false);
  const [fseValue, setFseValue] = useState('');
  const {
    actionEnums,
    actions,
    organizations,
    macroPs,
    intervention,
    tasks,
    listActions,
    rejectionTypes,
    rejectionReasons
  } = useInterventionContext();

  const disabledHours = () => {
    const hours = [
      5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23
    ];
    return hours;
  };

  const onsearch = (input, option) => {
    const result = option?.children.toLowerCase();
    return result.includes(input.toLowerCase());
  };

  const validateFse = (value) => {
    setFseValue(value);
    const isFseNumberExists = actions?.some(
      (action) => action.fse_number === value
    );

    if (isFseNumberExists && purpose === 'create') {
      setShowLinkedActions(true);
    } else {
      setShowLinkedActions(false);
    }
  };

  useEffect(() => {
    if (actionEnums && actions && organizations && tasks)
      setIsFieldsLoading(false);
  }, [actionEnums]);

  const fields = [
    {
      name: ['type'],
      initialValue: selectedType,
      rules: [
        {
          required: true
        }
      ],
      input: (
        <Select
          showSearch
          allowClear
          onChange={(value) => setSelectedType(value)}
          loading={isFieldsLoading}
          filterOption={(input, option) => onsearch(input, option)}
        >
          {actionEnums?.types?.map((type) => (
            <Option key={type} value={type}>
              {t(`actions.enums.${type}`)}
            </Option>
          ))}
        </Select>
      )
    },
    ...(selectedType === 'INVOICE'
      ? [
          {
            name: ['fse_number'],
            rules: [
              {
                required: true
              }
            ],
            input: (
              <>
                <Input
                  value={fseValue}
                  onChange={(event) => validateFse(event.target.value)}
                />
                {showLinkedActions && (
                  <div style={{ color: 'orange', marginTop: 5 }}>
                    Voir les actions liées
                    <Popover
                      content={
                        <Row gutter={[16, 16]}>
                          {(actions || [])
                            .filter((action) => action.fse_number === fseValue)
                            .map((action) => (
                              <Col key={action._id}>
                                <div>
                                  <p>{`${t(`actions.action_number`)}${
                                    action.code
                                  }`}</p>
                                  <p>{`${t(`actions.action`)}${
                                    action?.action?.title
                                  }`}</p>
                                  <p>{`${t(`actions.date`)}${dayjs(
                                    action?.created_at
                                  ).format('DD/MM/YYYY')}`}</p>
                                  <p>{`${t(`actions.gtp`)}${
                                    action?.created_by?.first_name
                                  } ${
                                    action?.created_by?.usage_last_name ||
                                    action?.created_by?.last_name
                                  }`}</p>
                                  <p>{`${t(`actions.new_fse_number`)}${
                                    action?.new_fse_number
                                  }`}</p>
                                  <p>{`${t(`actions.comment`)}${
                                    action.comment
                                  }`}</p>
                                  <p>{`${t(`actions.operator_code`)}${
                                    action?.operator_code || 'N/A'
                                  }`}</p>
                                  <p>{`${t(`actions.ps_request`)}${t(
                                    `actions.enums.${action?.ps_request}`
                                  )}`}</p>
                                  <p>{`${t(`actions.contact`)}${t(
                                    `actions.enums.${action?.contact}`
                                  )}`}</p>
                                  <p>{`${t(`actions.rejection_reason`)}${
                                    action?.rejection_reason?.title || 'N/A'
                                  }`}</p>
                                  <p>{`${t(`actions.rejection_type`)}${
                                    action?.rejection_type?.title || 'N/A'
                                  }`}</p>
                                  <p>{`${t(`actions.issuer_organization`)}${
                                    action?.issuer_organization?.name || 'N/A'
                                  }`}</p>
                                  <p>{`${t(`actions.email_send`)}${t(
                                    `switch.${action.email_sent}`
                                  )}`}</p>
                                  <p>{`${t(`actions.status`)}${t(
                                    `actions.enums.${action.status}`
                                  )}`}</p>
                                  <Button
                                    type="link"
                                    className="event-btn first"
                                    style={{ float: 'right' }}
                                  >
                                    <a
                                      href={`${routes.INTERVENTIONS}/show/${action?.intervention}`}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                      aria-label="Voir l'intervention"
                                    >
                                      <EyeOutlined />
                                    </a>
                                  </Button>
                                  <Divider />
                                </div>
                              </Col>
                            ))}
                        </Row>
                      }
                      title={t('actions.popover.title')}
                    >
                      <EyeOutlined style={{ fontSize: 18, marginLeft: 4 }} />
                    </Popover>
                  </div>
                )}
              </>
            )
          }
        ]
      : [
          {
            name: ['task_number'],
            rules: [
              {
                required: true
              }
            ],
            input: (
              <Select
                showSearch
                allowClear
                loading={isFieldsLoading}
                filterOption={(input, option) => onsearch(input, option)}
              >
                {tasks?.map((task) => (
                  <Option key={task?.title} value={task._id}>
                    {task?.title}
                  </Option>
                ))}
              </Select>
            )
          },
          {
            name: ['task_time'],
            rules: [
              {
                required: true
              }
            ],
            input: (
              <TimePicker
                disabledHours={disabledHours}
                hideDisabledOptions
                format="HH:mm"
              />
            )
          }
        ]),
    {
      name: ['part'],
      input: (
        <Select
          showSearch
          allowClear
          loading={isFieldsLoading}
          filterOption={(input, option) => onsearch(input, option)}
        >
          {actionEnums?.parts?.map((part) => (
            <Option key={part} value={part}>
              {t(`actions.enums.${part}`)}
            </Option>
          ))}
        </Select>
      )
    },
    {
      name: ['amount'],
      rules: [
        {
          required: selectedType === 'INVOICE'
        }
      ],
      input: <InputNumber />
    },
    {
      name: ['issuer_organization'],
      input: (
        <Select
          showSearch
          allowClear
          loading={isFieldsLoading}
          filterOption={(input, option) => onsearch(input, option)}
        >
          {(organizations || []).map((organization) => (
            <Option key={organization._id} value={organization._id}>
              {organization.name}
            </Option>
          ))}
        </Select>
      )
    },
    {
      name: ['operator_code'],
      rules: [
        {
          required: selectedType !== 'TASK'
        }
      ],
      input: isNewOperatorCode ? (
        <Input
          suffix={
            <CloseOutlined
              style={{ color: 'rgba(0,0,0,.45)', cursor: 'pointer' }}
              onClick={() => setIsNewOperatorCode(false)}
            />
          }
        />
      ) : (
        <Select
          loading={isFieldsLoading}
          showSearch
          filterOption={(input, option) => onsearch(input, option)}
          dropdownRender={(menu) => (
            <>
              <Space style={{ padding: '0 8px 4px' }}>
                <Button
                  type="text"
                  icon={<PlusOutlined />}
                  onClick={() => setIsNewOperatorCode(true)}
                >
                  Ajouter un nouveau code ?
                </Button>
              </Space>
              <Divider style={{ margin: '8px 0' }} />
              {menu}
            </>
          )}
        >
          {(macroPs?.operator_codes || []).map((operator) => (
            <Option key={operator} value={operator}>
              {operator}
            </Option>
          ))}
        </Select>
      )
    },
    {
      name: ['rejection_reason'],
      input: (
        <Select
          showSearch
          allowClear
          loading={isFieldsLoading}
          filterOption={(input, option) => onsearch(input, option)}
          onChange={(value) =>
            setRejectionType(
              rejectionReasons?.find((r) => r._id === value)?.rejection_type
            )
          }
        >
          {rejectionReasons?.map((reject) => (
            <Option key={reject?._id} value={reject?._id}>
              {reject?.title}
            </Option>
          ))}
        </Select>
      )
    },
    {
      name: ['rejection_type'],
      input: (
        <Select
          showSearch
          disabled
          allowClear
          loading={isFieldsLoading}
          filterOption={(input, option) => onsearch(input, option)}
        >
          {rejectionTypes?.map((r) => (
            <Option key={r?._id} value={r?._id}>
              {r?.title}
            </Option>
          ))}
        </Select>
      )
    },
    {
      name: ['contact'],
      input: (
        <Select
          showSearch
          allowClear
          loading={isFieldsLoading}
          filterOption={(input, option) => onsearch(input, option)}
        >
          {actionEnums?.contacts?.map((contact) => (
            <Option key={contact} value={contact}>
              {t(`actions.enums.${contact}`)}
            </Option>
          ))}
        </Select>
      )
    },
    {
      name: ['action'],
      rules: [{ required: selectedType === 'INVOICE' }],
      input: (
        <Select
          showSearch
          allowClear
          onChange={(value) => setActionSelected(value)}
          loading={isFieldsLoading}
          filterOption={(input, option) => onsearch(input, option)}
        >
          {(listActions || []).map((action) => (
            <Option key={action?._id} value={action?._id}>
              {action?.title}
            </Option>
          ))}
        </Select>
      )
    },
    {
      name: ['recipients_organization'],
      input: (
        <Select
          showSearch
          mode="multiple"
          allowClear
          loading={isFieldsLoading}
          filterOption={(input, option) => onsearch(input, option)}
          onChange={(selectedItems) => {
            if (selectedItems.length > 2) {
              selectedItems.pop();
            }
          }}
        >
          {(organizations || []).map((organization) => (
            <Option key={organization._id} value={organization._id}>
              {organization.name}
            </Option>
          ))}
        </Select>
      )
    },
    {
      name: ['comment'],
      rules: [{ required: true }],
      input: <TextArea rows={5} />
    },
    ...(selectedType === 'INVOICE' ? [{ name: ['new_fse_number'] }] : []),
    {
      name: ['doc_type'],
      input: (
        <Select
          showSearch
          allowClear
          loading={isFieldsLoading}
          filterOption={(input, option) => onsearch(input, option)}
        >
          {actionEnums?.docTypes?.map((doc) => (
            <Option key={doc} value={doc}>
              {t(`actions.enums.${doc}`)}
            </Option>
          ))}
        </Select>
      )
    },
    {
      name: ['ps_request'],
      input: (
        <Select
          showSearch
          allowClear
          loading={isFieldsLoading}
          filterOption={(input, option) => onsearch(input, option)}
        >
          {actionEnums?.ps?.map((ps) => (
            <Option key={ps} value={ps}>
              {t(`actions.enums.${ps}`)}
            </Option>
          ))}
        </Select>
      )
    },
    {
      name: ['credit'],
      rules: [{ required: actionSelected === '660136b702ca826ff04446d6' }],
      input: <InputNumber />
    },
    {
      name: ['pandp'],
      input: <InputNumber />
    },
    {
      name: ['action_reminder'],
      input: <DatePicker format="DD-MM-YYYY" />
    },
    ...(purpose === 'edit' && isActionReminder
      ? [
          {
            name: ['action_reminder_done'],
            valuePropName: 'checked',
            input: (
              <Switch
                checkedChildren={t('switch.yes')}
                unCheckedChildren={t('switch.no')}
              />
            )
          }
        ]
      : []),

    {
      name: ['created_by'],
      initialValue: user._id,
      hidden: true
    },
    {
      name: ['macro_ps'],
      initialValue: macroPs._id,
      hidden: true
    },
    {
      name: ['service'],
      initialValue: intervention.service?._id,
      hidden: true
    },
    {
      name: ['intervention'],
      initialValue: intervention._id,
      hidden: true
    },
    {
      name: ['customer'],
      initialValue: intervention?.customer?._id,
      hidden: true
    }
  ];

  return {
    fields,
    isFieldsLoading
  };
};

export default useFields;
