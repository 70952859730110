import { Col, Card, Row, Steps } from 'antd';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { formatValue } from '../../../utils/formatNumber';

/**
 * Component for displaying quittance historic.
 *
 * @component
 * @param {object} props - Component props
 * @param {object} props.data - Quote data containing historic
 * @returns {JSX.Element} Component JSX
 */

export const Historic = ({ data }) => {
  const { t } = useTranslation();

  const modelNameWithAmount = ['payments', 'details', 'over_payments'];

  const steps = (data?.historic || [])
    .map((historic) => ({
      title: t(
        `${historic?.modelName}.historic.${historic?.status || historic?.type}`
      ),
      description: (
        <>
          <p>
            {`le ${formatValue(historic.date, 'date')} à ${formatValue(
              historic.date,
              'time'
            )}  par ${
              historic?.author?.first_name || t('historic.show.auto')
            } ${
              historic?.author?.usage_last_name ||
              historic?.author?.last_name ||
              t('historic.show.matic')
            }`}
          </p>
          {modelNameWithAmount.includes(historic?.modelName) && (
            <p>{`${t('historic.show.amount')} ${formatValue(
              Number(historic?.values?.amount),
              'currency',
              true
            )}  ${t('historic.show.comment')} ${
              historic?.comment || t('historic.show.none')
            }`}</p>
          )}
        </>
      )
    }))
    .reverse();

  return (
    <Col xs={24} lg={12}>
      <Card title={t('quittances.show.titles.historic')}>
        <Row align="middle">
          <Steps
            direction="vertical"
            items={steps}
            progressDot
            current={steps.length - 1}
          />
          <Col span={24}>{data?.comment}</Col>
        </Row>
      </Card>
    </Col>
  );
};

Historic.propTypes = {
  data: PropTypes.shape({
    historic: PropTypes.arrayOf(PropTypes.shape({})),
    comment: PropTypes.string,
    rejection_quote_reason: PropTypes.string,
    rejection_quote_comment: PropTypes.string,
    rejection_odm_reason: PropTypes.string,
    rejection_odm_comment: PropTypes.string
  })
};

Historic.defaultProps = {
  data: undefined
};
