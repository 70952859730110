import PropTypes from 'prop-types';
import { EditOutlined, MailOutlined } from '@ant-design/icons';
import { Link, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Button, Col, Popconfirm, Row } from 'antd';
import { routes } from '../../utils/constants/adminRoutes';
import { isAuthorized } from '../../utils/constants/authorizedMenu';

/**
 * Component for displaying extra buttons for user management.
 *
 * @component
 * @param {string} id - User ID
 * @param {function} sendResetPasswordEmail - Function for sending reset password email
 * @param {boolean} isAdmin - If user is Admin or SuperAdmin
 * @returns {JSX.Element} Rendered ExtraButtons component
 */
const ExtraButtons = ({ id, sendResetPasswordEmail, user }) => {
  const { t } = useTranslation();
  const location = useLocation();
  const { pathname } = location;
  const isAdmin = user?.role.includes('admins');

  const hasAccess = (purpose) => isAuthorized(pathname, user?.role, purpose);

  return (
    <Row gutter={16}>
      {isAdmin && (
        <Col>
          <Popconfirm
            title={t('users.show.reset_title')}
            okText={t('buttons.confirm')}
            onConfirm={sendResetPasswordEmail}
          >
            <Button type="primary">
              {`${t('users.show.reset_password')} `}
              <MailOutlined />
            </Button>
          </Popconfirm>
        </Col>
      )}
      <Col>
        {hasAccess('edit') && (
          <Link to={{ pathname: `${routes.USERS}/edit/${id}` }}>
            <Button type="primary">
              {`${t('buttons.edit')} `}
              <EditOutlined />
            </Button>
          </Link>
        )}
      </Col>
    </Row>
  );
};

export default ExtraButtons;

ExtraButtons.propTypes = {
  id: PropTypes.string.isRequired,
  sendResetPasswordEmail: PropTypes.func,
  user: PropTypes.shape({
    role: PropTypes.string
  })
};

ExtraButtons.defaultProps = {
  sendResetPasswordEmail: () => {},
  user: undefined
};
