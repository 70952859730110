import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
  Carousel,
  Button,
  Row,
  Card,
  Col,
  Typography,
  Collapse,
  message as antdMessage
} from 'antd';
import { RightOutlined, LeftOutlined } from '@ant-design/icons';
import { useDrop } from 'react-dnd';
import { DraggableCard } from './DraggableCard';
import { useAuthContext } from '../../contexts/AuthContext';
import { useErrorMessage } from '../../utils/errorMessage';

const { Panel } = Collapse;

const CarouselWithCards = ({
  resourceName,
  modelResourceName,
  extraQuery,
  populate,
  forceRefresh,
  chunkSize,
  extraCarousel,
  patchResource,
  patchIterventionStatus
}) => {
  const { dispatchAPI } = useAuthContext();
  const { t } = useTranslation();
  const { message } = useErrorMessage();
  const [resources, setResources] = useState([]);
  const [totalCards, setTotalCards] = useState(0);
  const [visibleCards, setVisibleCards] = useState(0);
  const [validatedResources, setValidatedResources] = useState([]);
  const [advancedResources, setAdvancedResources] = useState([]);
  const [totalValidatedCards, setTotalValidatedCards] = useState(0);
  const [totalAdvancedCards, setTotalAdvancedCards] = useState(0);
  const [visibleValidatedCards, setVisibleValidatedCards] = useState(0);
  const [visibleAdvancedCards, setVisibleAdvancedCards] = useState(0);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [currentValidatedIndex, setCurrentValidatedIndex] = useState(0);
  const [currentAdvancedIndex, setCurrentAdvancedIndex] = useState(0);

  const [{ isOver }, drop] = useDrop(() => ({
    accept: 'GRID',
    drop: async (item) => {
      const { _id, events } = item;
      if (events?.resource === 'plannings') {
        if (events?.type === 'INTERVENTION' && events.status === 'PLANNED') {
          await patchIterventionStatus(events?.key);
        } else {
          antdMessage.warning(t('messages.warning.planning_drop'));
        }
      } else if (_id) {
        await patchResource(_id, 'remove');
      }
      return {};
    },
    collect: (monitor) => ({
      isOver: monitor.isOver()
    })
  }));

  const [{ isOver: isOverDropValidate }, dropValidateMinus] = useDrop(() => ({
    accept: 'GRID',
    drop: async (item) => {
      const { _id, diff_since_start_service } = item;
      if (diff_since_start_service > 0) {
        await patchResource(_id, 'add');
      } else {
        antdMessage.warning(t('messages.warning.planning_drop_minus'));
      }
      return {};
    },
    collect: (monitor) => ({
      isOver: monitor.isOver()
    })
  }));

  const [{ isOver: isOverDropAdvance }, dropValidatePlus] = useDrop(() => ({
    accept: 'GRID',
    drop: async (item) => {
      const { _id, diff_since_start_service } = item;
      if (diff_since_start_service < 0) {
        await patchResource(_id, 'add');
      } else {
        antdMessage.warning(t('messages.warning.planning_drop_plus'));
      }
      return {};
    },
    collect: (monitor) => ({
      isOver: monitor.isOver()
    })
  }));

  const dropStyle = {
    backgroundColor: isOver
      ? 'var(--itemActiveBackground)'
      : 'var(--bgPrimaryColor)'
  };

  const dropValidateStyle = {
    backgroundColor: isOverDropValidate
      ? 'var(--itemActiveBackground)'
      : 'var(--bgPrimaryColor)'
  };

  const dropAdvanceStyle = {
    backgroundColor: isOverDropAdvance
      ? 'var(--itemActiveBackground)'
      : 'var(--bgPrimaryColor)'
  };

  const forceRefreshRef = useRef(forceRefresh);

  useEffect(() => {
    forceRefreshRef.current = forceRefresh;
  }, [forceRefresh]);

  const carouselRef = React.createRef();

  const next = () => {
    const nextIndex = (currentIndex + 1) % resources.length;
    setCurrentIndex(nextIndex);
    setVisibleCards(Math.min(chunkSize, resources[nextIndex]?.length));
    carouselRef.current.next();
  };

  const prev = () => {
    const prevIndex = (currentIndex - 1 + resources.length) % resources.length;
    setCurrentIndex(prevIndex);
    setVisibleCards(Math.min(chunkSize, resources[prevIndex]?.length));
    carouselRef.current.prev();
  };

  const nextValidate = () => {
    const nextIndex = (currentValidatedIndex + 1) % validatedResources.length;
    setCurrentValidatedIndex(nextIndex);
    setVisibleValidatedCards(
      Math.min(chunkSize, validatedResources[nextIndex]?.length)
    );
    carouselRef.current.next();
  };

  const prevValidate = () => {
    const prevIndex =
      (currentValidatedIndex - 1 + validatedResources.length) %
      validatedResources.length;
    setCurrentValidatedIndex(prevIndex);
    setVisibleValidatedCards(
      Math.min(chunkSize, validatedResources[prevIndex]?.length)
    );
    carouselRef.current.prev();
  };

  const nextAdvance = () => {
    const nextIndex = (currentAdvancedIndex + 1) % advancedResources.length;
    setCurrentAdvancedIndex(nextIndex);
    setVisibleAdvancedCards(
      Math.min(chunkSize, advancedResources[nextIndex]?.length)
    );
    carouselRef.current.next();
  };

  const prevAdvance = () => {
    const prevIndex =
      (currentAdvancedIndex - 1 + advancedResources.length) %
      advancedResources.length;
    setCurrentAdvancedIndex(prevIndex);
    setVisibleAdvancedCards(
      Math.min(chunkSize, advancedResources[prevIndex]?.length)
    );
    carouselRef.current.prev();
  };

  const fetchResources = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `${resourceName}${extraQuery ? `?${extraQuery}` : ''}${
          populate
            ? `${
                extraQuery ? `&populate=${populate}` : `?populate=${populate}`
              }`
            : ''
        }`
      });
      const chunks = [];
      if (data?.resource?.length) {
        for (let i = 0; i < data.resource.length; i += chunkSize) {
          chunks.push(data.resource.slice(i, i + chunkSize));
        }
        setResources(chunks);
        setTotalCards(data.resource.length);
        setVisibleCards(Math.min(chunkSize, data.resource.length));
      } else {
        for (let i = 0; i < data.length; i += chunkSize) {
          chunks.push(data.slice(i, i + chunkSize));
        }
        setResources(chunks);
        setTotalCards(data.length);
        setVisibleCards(Math.min(chunkSize, data.length));
      }
      if (extraCarousel) {
        const validatedChunks = [];
        const advancedChunks = [];
        for (let i = 0; i < data.validatedResource.length; i += chunkSize) {
          validatedChunks.push(data.validatedResource.slice(i, i + chunkSize));
        }
        for (let i = 0; i < data.advancedResource.length; i += chunkSize) {
          advancedChunks.push(data.advancedResource.slice(i, i + chunkSize));
        }
        setValidatedResources(validatedChunks);
        setAdvancedResources(advancedChunks);
        setTotalValidatedCards(data.validatedResource.length);
        setTotalAdvancedCards(data.advancedResource.length);
        setVisibleValidatedCards(
          Math.min(chunkSize, data.validatedResource.length)
        );
        setVisibleAdvancedCards(
          Math.min(chunkSize, data.advancedResource.length)
        );
      }
    } catch (e) {
      message(e);
    }
  };

  useEffect(() => {
    fetchResources();
  }, [forceRefresh]);

  return (
    <>
      {extraCarousel && (
        <Collapse style={{ marginBottom: 20 }}>
          <Panel header={t(`${modelResourceName}.carousel.panel_title_plus`)}>
            <Card
              ref={dropValidatePlus}
              style={{ height: 190, ...dropAdvanceStyle }}
              className="carousel-wrapper"
              bordered={false}
            >
              <Typography.Title
                level={4}
                style={{
                  color: 'var(--userColor)'
                }}
                strong={false}
              >
                {t(`${modelResourceName}.carousel.title_advanced`)}
              </Typography.Title>
              <Row justify="center" align="middle">
                <Col xs={3} md={2} xl={1}>
                  <Button
                    type="primary"
                    shape="circle"
                    icon={<LeftOutlined />}
                    onClick={prevAdvance}
                  />
                </Col>
                <Col xs={18} md={20} xl={22}>
                  <Carousel
                    ref={carouselRef}
                    dots={false}
                    className="carousel-container"
                  >
                    {advancedResources.map((resource, index) => (
                      // eslint-disable-next-line react/no-array-index-key
                      <Row key={index} gutter={[8, 8]} className="row-card">
                        {resource.map((r) => (
                          <DraggableCard
                            key={r._id}
                            _id={r._id}
                            content={r}
                            modelResourceName={modelResourceName}
                          />
                        ))}
                      </Row>
                    ))}
                  </Carousel>
                </Col>
                <Col xs={3} md={2} xl={1}>
                  <Button
                    type="primary"
                    shape="circle"
                    icon={<RightOutlined />}
                    onClick={nextAdvance}
                    style={{ float: 'right' }}
                  />
                </Col>
              </Row>
              <Row justify="end" className="event-counter">{`${
                visibleAdvancedCards || 0
              } service(s) affichés sur ${totalAdvancedCards || 0}`}</Row>
            </Card>
          </Panel>
        </Collapse>
      )}
      {extraCarousel && (
        <Collapse style={{ marginBottom: 20 }}>
          <Panel header={t(`${modelResourceName}.carousel.panel_title`)}>
            <Card
              ref={dropValidateMinus}
              style={{ height: 190, ...dropValidateStyle }}
              className="carousel-wrapper"
              bordered={false}
            >
              <Typography.Title
                level={4}
                style={{
                  color: 'var(--userColor)'
                }}
                strong={false}
              >
                {t(`${modelResourceName}.carousel.title_validated`)}
              </Typography.Title>
              <Row justify="center" align="middle">
                <Col xs={3} md={2} xl={1}>
                  <Button
                    type="primary"
                    shape="circle"
                    icon={<LeftOutlined />}
                    onClick={prevValidate}
                  />
                </Col>
                <Col xs={18} md={20} xl={22}>
                  <Carousel
                    ref={carouselRef}
                    dots={false}
                    className="carousel-container"
                  >
                    {validatedResources.map((resource, index) => (
                      // eslint-disable-next-line react/no-array-index-key
                      <Row key={index} gutter={[8, 8]} className="row-card">
                        {resource.map((r) => (
                          <DraggableCard
                            key={r._id}
                            _id={r._id}
                            content={r}
                            modelResourceName={modelResourceName}
                          />
                        ))}
                      </Row>
                    ))}
                  </Carousel>
                </Col>
                <Col xs={3} md={2} xl={1}>
                  <Button
                    type="primary"
                    shape="circle"
                    icon={<RightOutlined />}
                    onClick={nextValidate}
                    style={{ float: 'right' }}
                  />
                </Col>
              </Row>
              <Row justify="end" className="event-counter">{`${
                visibleValidatedCards || 0
              } service(s) affichés sur ${totalValidatedCards || 0}`}</Row>
            </Card>
          </Panel>
        </Collapse>
      )}
      <Card
        ref={drop}
        style={{ ...dropStyle }}
        className="carousel-wrapper"
        bordered={false}
      >
        <Typography.Title
          level={4}
          style={{
            color: 'var(--userColor)'
          }}
          strong={false}
        >
          {t(`${modelResourceName}.carousel.title`)}
        </Typography.Title>
        <Row justify="center" align="middle" style={{ marginTop: 16 }}>
          <Col xs={3} md={2} xl={1}>
            <Button
              type="primary"
              shape="circle"
              icon={<LeftOutlined />}
              onClick={prev}
            />
          </Col>
          <Col xs={18} md={20} xl={22}>
            <Carousel
              ref={carouselRef}
              dots={false}
              className="carousel-container"
            >
              {resources.map((resource, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <Row key={index} gutter={[8, 8]} className="row-card">
                  {resource.map((r) => (
                    <DraggableCard
                      key={r._id}
                      _id={r._id}
                      content={r}
                      modelResourceName={modelResourceName}
                    />
                  ))}
                </Row>
              ))}
            </Carousel>
          </Col>
          <Col xs={3} md={2} xl={1}>
            <Button
              type="primary"
              shape="circle"
              icon={<RightOutlined />}
              onClick={next}
              style={{ float: 'right' }}
            />
          </Col>
        </Row>
        <Row justify="end" className="event-counter">{`${
          visibleCards || 0
        } service(s) affichés sur ${totalCards || 0}`}</Row>
      </Card>
    </>
  );
};

export default CarouselWithCards;

CarouselWithCards.propTypes = {
  resourceName: PropTypes.string.isRequired,
  modelResourceName: PropTypes.string.isRequired,
  extraQuery: PropTypes.string,
  populate: PropTypes.string,
  forceRefresh: PropTypes.number,
  chunkSize: PropTypes.number,
  extraCarousel: PropTypes.bool,
  patchResource: PropTypes.func,
  patchIterventionStatus: PropTypes.func
};

CarouselWithCards.defaultProps = {
  extraQuery: undefined,
  populate: undefined,
  forceRefresh: undefined,
  chunkSize: null,
  extraCarousel: false,
  patchResource: null,
  patchIterventionStatus: null
};
