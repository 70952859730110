import { Tag } from 'antd';
import { userCompanies } from '../../../../utils/constants/tagColors';
import { formatValue } from '../../../../utils/formatNumber';
/**
 * Custom hook for generating informations content data for displaying quality control informations details in a list format.
 *
 * @hook
 * @param {object} data - Quality data object
 * @param {object} t - i18next function for translating strings
 * @returns {Array} Array of objects containing label, span, and content for each field
 */
export const useListContent = (data, t) => {
  const activeContract = data?.user?.employments.find(
    (employment) => employment.status === 'ACTIVE'
  );

  const { company, job_requirement } = activeContract || {};

  const isClosed = ['VALID', 'INVALID', 'CLOSED'].includes(data?.status);

  return [
    {
      label: 'users.form.position',
      span: ['VALID', 'INVALID'].includes(data?.status) ? 24 : 12,
      content: (
        <Tag color={userCompanies[company]}>
          {t(`employments.tags.${company}`)}
        </Tag>
      )
    },
    {
      label: `quality.main_information.${isClosed ? 'initial_level' : 'level'}`,
      span: 12,
      content: t(`employments.tags.${job_requirement}`)
    },
    ...(['VALID', 'INVALID'].includes(data?.status)
      ? [
          {
            label: 'quality.form.closure.validated_level',
            span: 12,
            content: t(`employments.tags.${data?.closure.user_final_level}`)
          }
        ]
      : []),
    {
      label: 'quality.main_information.verifier',
      span: 12,
      content: `${data?.created_by?.first_name || '-'} ${
        data?.created_by?.usage_last_name || data?.created_by?.last_name || '-'
      }`
    },
    ...(isClosed
      ? [
          {
            label: 'quality.main_information.closed_by',
            span: 12,
            content: `${data?.closure?.closed_by?.first_name || '-'} ${
              data?.closure?.closed_by?.usage_last_name ||
              data?.closure?.closed_by?.last_name ||
              '-'
            }`
          }
        ]
      : []),
    {
      label: 'quality.main_information.start_date',
      span: 12,
      content: data?.created_at && formatValue(data?.created_at, 'date')
    },
    ...(isClosed
      ? [
          {
            label: 'quality.main_information.end_date',
            span: 12,
            content:
              data?.closure?.closed_at &&
              formatValue(data?.closure?.closed_at, 'date')
          }
        ]
      : []),
    {
      label: 'quality.main_information.comment',
      span: 12,
      content: data?.comment
    },
    ...(isClosed
      ? [
          {
            label: 'quality.form.closure.final_comment',
            span: 12,
            content: data?.closure.comment
          }
        ]
      : [])
  ];
};
