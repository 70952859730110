import { useState, useEffect } from 'react';
import {
  Input,
  DatePicker,
  TimePicker,
  Upload,
  Checkbox,
  Button,
  Select
} from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { useAuthContext } from '../../contexts/AuthContext';
import useCalendarContext from './CalendarContext';

const { TextArea } = Input;
const { Option } = Select;

const useFields = (
  users,
  purpose,
  setFileList,
  fileList,
  id,
  uploadOrDeleteFile,
  isLoading,
  startPeriodEdit
) => {
  const { t } = useTranslation();
  const { user } = useAuthContext();
  const [startPeriod, setStartPeriod] = useState();
  const { enums, type, setType, isLoadingEnums, paidVacationsOrder, isAdmin } =
    useCalendarContext();
  const format = 'DD-MM-YYYY';
  const absences = ['ABSENCE', 'PTO', 'OCCUPATIONAL_DESEASE'];
  const excludedTypes = ['SICK_LEAVE', 'PTO', 'BANK_HOLIDAY'];
  const includeSales = ['PAID_VACATION'];

  const filteredEnums = () => {
    if (isAdmin)
      return enums?.types?.filter((typeE) => typeE !== 'BANK_HOLIDAY');
    if (user?.role === 'users:SALES-USER' || user?.role === 'users:USER')
      return enums?.types?.filter((typeEn) => includeSales.includes(typeEn));
    return enums?.types?.filter(
      (typeEnum) => !excludedTypes.includes(typeEnum)
    );
  };

  const disabledHours = () => {
    const hours = [0, 1, 2, 3, 4, 5, 6, 7, 20, 21, 22, 23];
    return hours;
  };

  const disabledMinutes = () => {
    const minutes = [];
    for (let i = 0; i < 60; i += 1) {
      if (i % 15 !== 0) {
        minutes.push(i);
      }
    }
    return minutes;
  };

  const disableDatesBeforeStartPeriod = (currentDate) => {
    if (!startPeriod) {
      return false;
    }
    return currentDate.format('YYYY-MM-DD') < startPeriod.format('YYYY-MM-DD');
  };

  const onsearchUser = (input, option) => {
    const result = option?.children.toLowerCase();
    return result.includes(input.toLowerCase());
  };

  useEffect(() => {
    if (startPeriodEdit) setStartPeriod(startPeriodEdit);
  }, [startPeriodEdit]);

  const fields = [
    {
      name: ['type'],
      rules: [{ required: true }],
      input: (
        <Select
          style={{ width: '300px' }}
          showSearch
          allowClear
          loading={isLoadingEnums}
          defaultValue={type}
          onChange={(value) => setType(value)}
        >
          {filteredEnums()?.map((typeLocal) => (
            <Option key={typeLocal} value={typeLocal}>
              {t(`events.form.enums.${typeLocal}`)}
            </Option>
          ))}
        </Select>
      )
    },
    ...(type
      ? [
          ...(isAdmin
            ? [
                {
                  name: ['user'],
                  rules: [{ required: true }],
                  input: (
                    <Select
                      allowClear
                      showSearch
                      filterOption={(input, option) =>
                        onsearchUser(input, option)
                      }
                    >
                      {(users || []).map((us) => (
                        <Option key={us._id} value={us._id}>
                          {`${us.first_name} ${
                            us?.usage_last_name || us?.last_name
                          }`}
                        </Option>
                      ))}
                    </Select>
                  )
                }
              ]
            : [
                {
                  name: ['user'],
                  initialValue: user._id,
                  hidden: true
                }
              ]),
          ...(type === 'PAID_VACATION'
            ? [
                {
                  name: ['total_cp'],
                  input: (
                    <Input type="number" disabled style={{ width: '300px' }} />
                  )
                }
              ]
            : []),
          ...(absences.includes(type)
            ? [
                {
                  name: ['reason']
                }
              ]
            : []),
          ...(purpose === 'create' || purpose === 'editAll'
            ? [
                {
                  name: ['start_date'],
                  rules: [{ required: true }],
                  input: (
                    <DatePicker
                      format={format}
                      onChange={(value) => setStartPeriod(value)}
                      disabled={purpose === 'editAll'}
                    />
                  )
                }
              ]
            : []),
          {
            name: ['startTime'],
            rules: [{ required: true }],
            input: (
              <TimePicker
                format="HH:mm"
                disabledHours={disabledHours}
                disabledMinutes={disabledMinutes}
                minuteStep={15}
                hideDisabledOptions
              />
            )
          },
          ...(purpose === 'create' || purpose === 'editAll'
            ? [
                {
                  name: ['end_date'],
                  rules: [{ required: true }],
                  input: (
                    <DatePicker
                      format={format}
                      disabledDate={(current) =>
                        disableDatesBeforeStartPeriod(current)
                      }
                      disabled={!startPeriod || purpose === 'editAll'}
                    />
                  )
                }
              ]
            : []),

          {
            name: ['endTime'],
            rules: [{ required: true }],
            input: (
              <TimePicker
                format="HH:mm"
                disabledHours={disabledHours}
                disabledMinutes={disabledMinutes}
                minuteStep={15}
                hideDisabledOptions
              />
            )
          },
          ...(purpose === 'edit'
            ? [
                {
                  name: ['date'],
                  rules: [{ required: true }],
                  input: <DatePicker format={format} />
                }
              ]
            : []),
          ...(absences.includes(type)
            ? [
                {
                  name: ['makeUpTime'],
                  valuePropName: 'checked',
                  input: <Checkbox />
                }
              ]
            : []),
          ...(type === 'PAID_VACATION'
            ? [
                {
                  name: ['paidLeaveInAdvance'],
                  valuePropName: 'checked',
                  input: <Checkbox />
                }
              ]
            : []),
          {
            name: ['comment'],
            input: <TextArea rows={5} />
          },
          {
            name: ['file'],
            input: (
              <Upload
                multiple
                maxCount={1}
                beforeUpload={(file) => {
                  if (purpose !== 'edit' && purpose !== 'editAll') {
                    setFileList([file]);
                  } else {
                    uploadOrDeleteFile(file, id, 'upload', 'file');
                  }
                  return false;
                }}
                onRemove={(doc) => {
                  if (purpose !== 'edit' && purpose !== 'editAll') {
                    setFileList();
                  } else {
                    uploadOrDeleteFile(
                      fileList[
                        fileList.findIndex(
                          (file) => file.metadata.originalName === doc.name
                        )
                      ],
                      id,
                      'delete',
                      'file'
                    );
                  }
                }}
                fileList={
                  isLoading
                    ? []
                    : (fileList || []).map((file, index) => ({
                        uid: index,
                        name: file?.metadata?.originalName || file?.name,
                        status: 'done'
                      }))
                }
              >
                <Button icon={<UploadOutlined />}>
                  {t('buttons.upload_2')}
                </Button>
              </Upload>
            )
          },
          {
            name: ['created_by'],
            initialValue: user._id,
            hidden: true
          }
        ]
      : [])
  ];

  const paidVacationSpecific = [
    {
      name: ['waiveExtraTimeOff'],
      valuePropName: 'checked',
      input: <Checkbox />
    }
  ];

  if (type === 'PAID_VACATION') {
    return {
      fields: [...paidVacationSpecific, ...fields].sort(paidVacationsOrder)
    };
  }

  return {
    fields
  };
};

export default useFields;
