import { useEffect, useState } from 'react';
import { Col, Layout, Row, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import LoginForm from './LoginForm';
import ForgotPwdForm from './ForgotPwdForm';
import ChangePwdForm from './ChangePwdForm';
import LogoTPA from '../../assets/images/logo_TPA.png';

export const Login = () => {
  const { token, purpose } = useParams();
  const { t } = useTranslation();
  const { Title } = Typography;
  const [currentForm, setCurrentForm] = useState('login');
  const forms = {
    login: <LoginForm switchForm={(f) => setCurrentForm(f)} />,
    forgotPwd: <ForgotPwdForm switchForm={(f) => setCurrentForm(f)} />,
    changePwd: (
      <ChangePwdForm switchForm={(f) => setCurrentForm(f)} token={token} />
    ),
    createPwd: (
      <ChangePwdForm switchForm={(f) => setCurrentForm(f)} token={token} />
    )
  };

  useEffect(() => {
    if (token) setCurrentForm(purpose === 'reset' ? 'changePwd' : 'createPwd');
  }, [token]);

  return (
    <Layout className="login-layout">
      <Row>
        <Col
          xs={{ span: 24 }}
          md={{ span: 12 }}
          lg={{ span: 8 }}
          xl={{ span: 7 }}
          xxl={{ span: 6 }}
          className="login-form-col"
        >
          <img
            style={{
              width: 240,
              marginBottom: 56
            }}
            alt="Logo"
            src={LogoTPA}
          />
          <Title className="login-title">
            {t(`login.title.${currentForm}`)}
          </Title>
          {forms[currentForm]}
        </Col>
      </Row>
    </Layout>
  );
};
