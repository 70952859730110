import PropTypes from 'prop-types';
import { Col, Card, Tabs } from 'antd';
import { useTranslation } from 'react-i18next';
import { ListVacations } from './ListVacations';
import { ListAbsences } from './ListAbsences';

/**
 * Component for displaying leaves and absences information of a user in a card format of 2 datatable.
 *
 * @component
 * @returns {JSX.Element} Component JSX
 */

export const ShowVacationsAndAbsences = ({ id }) => {
  const { t } = useTranslation();

  const items = [
    {
      label: t('vacations.title'),
      key: '1',
      children: <ListVacations id={id} />
    },
    { label: t('absences.title'), key: '2', children: <ListAbsences id={id} /> }
  ];

  return (
    <Col span="24">
      <Card>
        <Tabs defaultActiveKey="1" type="card" size="large" items={items} />
      </Card>
    </Col>
  );
};

ShowVacationsAndAbsences.propTypes = {
  id: PropTypes.string.isRequired
};
