import { useState, useEffect } from 'react';
import dayjs from 'dayjs';
import {
  Select,
  DatePicker,
  InputNumber,
  Switch,
  Tag,
  Space,
  Button,
  Typography
} from 'antd';
import { WarningOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import OccupancyChartContextProvider from '../OccupancyChartContext';
import { servicesColor } from '../../../utils/constants/tagColors';

const { Option } = Select;
const { Text } = Typography;

const useFields = (
  modalGtpInfo,
  modalServiceInfo,
  purpose,
  psMicro,
  softwares,
  setHasRepetion,
  hasRepetion,
  days,
  setDays,
  date,
  resource,
  editDate
) => {
  const { t } = useTranslation();
  const { startWeek, leadersAndGtp, interventionEnums } =
    OccupancyChartContextProvider();
  const [isFieldsLoading, setIsFieldsLoading] = useState(true);
  const [frequencySelected, setFrequencySelected] = useState('');
  const [repetitionSelected, setRepetitionSelected] = useState(10);

  const format = 'DD-MM-YYYY';
  const start_period = dayjs(startWeek).add(1, 'day').startOf('day');
  const end_period = dayjs(startWeek).endOf('day').add(7, 'day');

  const getCheckedDaysString = (d) =>
    (d || [])
      .filter((day) => day.checked)
      .map((day) => t(`interventions.form.days_fr.${day.name}`))
      .join(', ');

  const disabledHours = () => {
    const hours = [0, 1, 2, 3, 4, 5, 6, 7, 20, 21, 22, 23];
    return hours;
  };

  const disabledMinutes = () => {
    const minutes = [];
    for (let i = 0; i < 60; i += 1) {
      if (i % 15 !== 0) {
        minutes.push(i);
      }
    }
    return minutes;
  };

  const validateDays = () => {
    const isAnyDayChecked = days.some((day) => day.checked);
    if (!isAnyDayChecked) {
      return Promise.reject(new Error('Merci de séléctionner un jour'));
    }
    return Promise.resolve();
  };

  useEffect(() => {
    if (psMicro && softwares && leadersAndGtp) setIsFieldsLoading(false);
  }, []);

  const fields = [
    {
      name: ['ps_number'],
      initialValue:
        purpose === 'create' && modalServiceInfo?.contract?.ps_number,
      rules: [{ required: true }],
      disabled: true
    },
    {
      name: ['company_name'],
      initialValue:
        purpose === 'create' && modalServiceInfo?.contract?.company_name,
      rules: [{ required: true }],
      disabled: true
    },
    {
      name: ['micro_ps'],
      initialValue:
        purpose === 'create' && modalServiceInfo?.contract?.micro_ps,
      rules: [{ required: true }],
      input: (
        <Select loading={isFieldsLoading} mode="multiple" disabled>
          {(psMicro || []).map((micro) => (
            <Option key={micro.title} value={micro._id}>
              {micro.title}
            </Option>
          ))}
        </Select>
      )
    },
    {
      name: ['macro_ps'],
      hidden: true,
      initialValue:
        purpose === 'create' && modalServiceInfo?.contract?.macro_ps,
      rules: [{ required: true }]
    },
    {
      name: ['used_softwares'],
      initialValue:
        purpose === 'create' &&
        modalServiceInfo?.contract?.customer?.used_software,
      input: (
        <Select disabled loading={isFieldsLoading} mode="multiple" allowClear>
          {(softwares || []).map((software) => (
            <Option key={software.title} value={software._id}>
              {software.title}
            </Option>
          ))}
        </Select>
      )
    },
    {
      name: ['contract_code'],
      rules: [{ required: true }],
      initialValue: purpose === 'create' && modalServiceInfo?.contract?.code,
      disabled: true
    },
    {
      name: ['leader'],
      rules: [{ required: true }],
      initialValue: purpose === 'create' && `${modalServiceInfo?.leader?._id}`,
      input: (
        <Select loading={isFieldsLoading} allowClear disabled>
          {(leadersAndGtp || []).map((gtp) => (
            <Option key={gtp._id} value={gtp._id}>
              {`${gtp?.first_name} ${gtp?.usage_last_name || gtp?.last_name}`}
            </Option>
          ))}
        </Select>
      )
    },
    {
      name: ['user_referent'],
      initialValue:
        purpose === 'create' && `${modalServiceInfo?.user_referent?._id}`,
      rules: [{ required: true }],
      input: (
        <Select loading={isFieldsLoading} allowClear disabled>
          {(leadersAndGtp || []).map((gtp) => (
            <Option key={gtp._id} value={gtp._id}>
              {`${gtp?.first_name} ${gtp?.usage_last_name || gtp?.last_name}`}
            </Option>
          ))}
        </Select>
      )
    },
    {
      name: ['period'],
      initialValue: modalServiceInfo?._id,
      disabled: true,
      input: (
        <Tag color={servicesColor[modalServiceInfo?.type?.split('_')[0]]}>
          {`${t(`quotes.tags.${modalServiceInfo?.type}`)}: ${`${
            modalServiceInfo?.total_hours
          }h/${t(`quotes.tags.${modalServiceInfo?.frequency}`)}`}`}
        </Tag>
      )
    },
    {
      name: ['period_start'],
      initialValue: purpose === 'create' && start_period,
      tooltip: true,
      rules: [{ required: true }],
      input: <DatePicker disabled format={format} />
    },
    {
      name: ['period_end'],
      initialValue: purpose === 'create' && end_period,
      tooltip: true,
      rules: [{ required: true }],
      input: <DatePicker disabled format={format} />
    },
    ...(purpose === 'create'
      ? [
          {
            name: ['cumul'],
            initialValue:
              purpose === 'create' &&
              `${modalServiceInfo?.diff_in_hours > 0 ? '-' : '+'} ${
                modalServiceInfo?.diff_in_hours
              }`,
            tooltip: true,
            input: <InputNumber disabled />
          }
        ]
      : []),
    {
      name: ['user'],
      initialValue: purpose === 'create' && `${modalGtpInfo?.user?._id}`,
      disabled: true,
      rules: [{ required: true }],
      input: (
        <Select loading={isFieldsLoading} allowClear>
          {(leadersAndGtp || []).map((gtp) => (
            <Option key={gtp._id} value={gtp._id}>
              {`${gtp?.first_name} ${gtp?.usage_last_name || gtp?.last_name}`}
            </Option>
          ))}
        </Select>
      )
    },
    {
      name: ['service_start'],
      initialValue: purpose === 'create' && dayjs(modalServiceInfo?.start_date),
      tooltip: true,
      rules: [{ required: true }],
      input: <DatePicker disabled format={format} />
    },
    {
      name: ['service_end'],
      initialValue:
        purpose === 'create' && modalServiceInfo?.end_date
          ? dayjs(modalServiceInfo.end_date)
          : undefined,
      tooltip: true,
      input: <DatePicker disabled format={format} />
    },
    {
      name: ['scheduled_time'],
      tooltip: true,
      initialValue: purpose === 'create' && modalServiceInfo?.diff_in_hours,
      rules: [{ required: true }],
      input: (
        <InputNumber
          min={0.5}
          disabled={purpose === 'edit' && resource === 'plannings'}
        />
      )
    },
    ...(purpose === 'edit' && resource === 'plannings'
      ? [
          {
            name: ['scheduled_start_date'],
            input: (
              <DatePicker
                format="DD-MM-YYYY HH:mm"
                disabledHours={disabledHours}
                disabledMinutes={disabledMinutes}
                minuteStep={15}
                hideDisabledOptions
                disabledDate={(current) =>
                  !current ||
                  !current.isSame(dayjs(date?.date || editDate), 'day')
                }
                showTime={{
                  format: 'HH:mm'
                }}
              />
            )
          },
          {
            name: ['scheduled_end_date'],
            input: (
              <DatePicker
                format="DD-MM-YYYY HH:mm"
                disabledHours={disabledHours}
                disabledMinutes={disabledMinutes}
                minuteStep={15}
                hideDisabledOptions
                disabledDate={(current) =>
                  !current ||
                  !current.isSame(dayjs(date?.date || editDate), 'day')
                }
                showTime={{
                  format: 'HH:mm'
                }}
              />
            )
          }
        ]
      : []),
    ...(purpose === 'create'
      ? [
          {
            name: ['has_repetition'],
            initialValue: purpose === 'create' && hasRepetion,
            valuePropName: 'checked',
            input: (
              <Switch
                checkedChildren={t('switch.yes')}
                unCheckedChildren={t('switch.no')}
                onChange={() => setHasRepetion(!hasRepetion)}
              />
            )
          },
          ...(hasRepetion
            ? [
                {
                  name: ['frequency'],
                  rules: [{ required: true }],
                  input: (
                    <Select
                      loading={isFieldsLoading}
                      allowClear
                      onSelect={(value) => setFrequencySelected(value)}
                      onClear={() => setFrequencySelected('')}
                    >
                      {(interventionEnums.frequencies || []).map(
                        (frequency) => (
                          <Option key={frequency} value={frequency}>
                            {t(`interventions.form.enums.${frequency}`)}
                          </Option>
                        )
                      )}
                    </Select>
                  )
                },
                ...(frequencySelected
                  ? [
                      ...(modalServiceInfo?.periodicity === 'PUNCTUAL'
                        ? [
                            {
                              name: ['warning'],
                              input: (
                                <Text style={{ color: 'orange' }}>
                                  <WarningOutlined style={{ marginRight: 5 }} />
                                  {`${t('interventions.form.warning_text')} ${
                                    modalServiceInfo?.total_hours
                                  }h.`}
                                </Text>
                              )
                            }
                          ]
                        : []),
                      {
                        name: ['repetition_days'],
                        rules: [
                          {
                            validator: validateDays
                          }
                        ],
                        input: (
                          <Space>
                            {(days || []).map((day, index) => (
                              <Space direction="vertical" key={day.name}>
                                <Button
                                  style={{
                                    background: day.checked ? 'lightblue' : '',
                                    color: day.checked ? 'white' : ''
                                  }}
                                  onClick={() =>
                                    setDays(
                                      days.map((d, idx) =>
                                        idx === index
                                          ? { ...d, checked: !day.checked }
                                          : d
                                      )
                                    )
                                  }
                                >
                                  {t(`interventions.form.days.${day.name}`)}
                                </Button>
                              </Space>
                            ))}
                          </Space>
                        )
                      },
                      {
                        name: ['repetition_week'],
                        initialValue: repetitionSelected,
                        rules: [{ required: true }],
                        input: (
                          <InputNumber
                            min={1}
                            onChange={(value) => setRepetitionSelected(value)}
                          />
                        )
                      },
                      {
                        name: ['recap'],
                        input: (
                          <Text>
                            {`${
                              frequencySelected
                                ? t(
                                    `interventions.form.enums.${frequencySelected}`
                                  )
                                : ''
                            } ${getCheckedDaysString(
                              days
                            )} (répétition: x${repetitionSelected})`}
                          </Text>
                        )
                      }
                    ]
                  : [])
              ]
            : [])
        ]
      : []),
    {
      name: ['contract'],
      rules: [{ required: true }],
      initialValue: purpose === 'create' && modalServiceInfo?.contract?._id,
      disabled: true,
      hidden: true
    },
    {
      name: ['service'],
      rules: [{ required: true }],
      initialValue: purpose === 'create' && modalServiceInfo?._id,
      disabled: true,
      hidden: true
    },
    {
      name: ['service_type'],
      rules: [{ required: true }],
      initialValue: purpose === 'create' && modalServiceInfo?.type,
      disabled: true,
      hidden: true
    },
    {
      name: ['customer'],
      rules: [{ required: true }],
      initialValue:
        purpose === 'create' && modalServiceInfo?.contract?.customer?._id,
      disabled: true,
      hidden: true
    }
  ];

  return {
    fields
  };
};

export default useFields;
