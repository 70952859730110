import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
  Typography,
  Modal,
  Checkbox,
  ColorPicker,
  DatePicker,
  Input,
  InputNumber,
  Radio,
  Select,
  TimePicker,
  Row,
  Col
} from 'antd';
import dayjs from 'dayjs';

/*
 * Data Format
 * [
 *  {
 *   type: 'checkbox',
 *   description: 'test',
 *   disabled: false,
 *   defaultChecked: false,
 *   onChange: () => {}
 *  }
 * ]
 *
 * Available Types : Checkbox, ColorPicker, DatePicker, Input, InputNumber, Radio.Group, Radio, Select, TimePicker
 */
const formattedInputs = (infos, extra, value, onChange) => {
  switch (infos.type) {
    case 'checkbox':
      return (
        <Col span={24}>
          <Checkbox
            disabled={infos.disabled}
            checked={value}
            defaultChecked={infos.defaultChecked}
            onChange={() => onChange(!value)}
          >
            {infos.description}
          </Checkbox>
        </Col>
      );
    case 'colorpicker':
      return (
        <Col span={24}>
          <Typography>{infos.description}</Typography>
          <ColorPicker
            disabled={infos.disabled}
            color={value}
            defaultColor={infos.defaultColor}
            onChange={onChange}
            placeholder={infos.placeholder}
          />
        </Col>
      );
    case 'datepicker':
      return (
        <Col span={24}>
          <Typography>{infos.description}</Typography>
          <DatePicker
            disabled={infos.disabled}
            value={value}
            defaultValue={infos.defaultValue}
            onChange={onChange}
            format={infos.format}
            locale={infos.locale}
            placeholder={infos.placeholder}
          />
        </Col>
      );
    case 'input':
      return (
        <Input
          addonBefore={infos.description}
          disabled={infos.disabled}
          value={value}
          defaultValue={infos.defaultValue}
          onChange={(data) => onChange(data.target.value)}
          placeholder={infos.placeholder}
          maxLength={infos.maxLength}
        />
      );
    case 'inputnumber':
      return (
        <InputNumber
          disabled={infos.disabled}
          value={value}
          defaultValue={infos.defaultValue}
          onChange={onChange}
          placeholder={infos.description}
          min={infos.min}
          max={infos.max}
        />
      );
    case 'radiogroup':
      return (
        <Col span={24}>
          <Radio.Group
            onChange={(data) => onChange(data.target.value)}
            value={value}
          >
            {extra.map((option) => (
              <Radio value={option.value}>{option.description}</Radio>
            ))}
          </Radio.Group>
        </Col>
      );
    case 'radio':
      return (
        <Col span={24}>
          {' '}
          <Radio
            disabled={infos.disabled}
            value={value}
            defaultValue={infos.defaultValue}
            onChange={(data) => onChange(data.target.value)}
          >
            {infos.description}
          </Radio>
        </Col>
      );
    case 'select':
      return (
        <Col span={24}>
          <Typography>{infos.description}</Typography>
          <Select
            disabled={infos.disabled}
            value={value}
            defaultValue={infos.defaultValue}
            onChange={onChange}
            placeholder={infos.placeholder}
            options={extra}
            mode={infos.mode}
            showSearch={
              infos.showSearch ||
              infos.mode === 'multiple' ||
              infos.mode === 'tags'
            }
            size={infos.size}
          />
        </Col>
      );
    case 'timepicker':
      return (
        <Col span={24}>
          <Typography>{infos.description}</Typography>
          <TimePicker
            disabled={infos.disabled}
            value={value}
            defaultValue={infos.defaultValue}
            onChange={onChange}
            format={infos.format}
            placeholder={infos.placeholder}
          />
        </Col>
      );

    default:
      return <div>Default</div>;
  }
};

export const AllPurposeModal = ({
  type,
  path,
  fetchItems,
  setModalPath,
  defaultData,
  title,
  data,
  isOpen,
  setIsOpen,
  onClose,
  extra
}) => {
  const { t } = useTranslation();
  const [modalData, setModalData] = useState(defaultData);

  useEffect(() => {
    setModalData(defaultData);
  }, [defaultData]);

  useEffect(() => {
    if (type === 'edit') {
      fetchItems(`${path}`, (dataToSet) => {
        const updatedData = dataToSet;
        if (updatedData.date) {
          updatedData.date = dayjs(updatedData.date);
        }
        setModalData(updatedData);
      });
    }
  }, [type, path]);

  return (
    <Modal
      title={title}
      open={isOpen}
      cancelText={t('buttons.cancel')}
      okText={t('buttons.save')}
      onCancel={() => {
        setIsOpen(false);
        setModalData({});
        setModalPath('');
      }}
      onOk={() => {
        setIsOpen(false);
        onClose(modalData);
        setModalData({});
      }}
    >
      <Row gutter={[16, 16]}>
        {data?.map((item) =>
          formattedInputs(item, extra, modalData[item.key], (value) => {
            setModalData((prev) => ({ ...prev, [item.key]: value }));
          })
        ) || null}
      </Row>
    </Modal>
  );
};

AllPurposeModal.propTypes = {
  type: PropTypes.string,
  path: PropTypes.string,
  fetchItems: PropTypes.func,
  defaultData: PropTypes.shape(),
  title: PropTypes.string.isRequired,
  data: PropTypes.arrayOf(PropTypes.shape()),
  isOpen: PropTypes.bool.isRequired,
  setIsOpen: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  extra: PropTypes.arrayOf(PropTypes.shape()),
  setModalPath: PropTypes.func.isRequired
};

AllPurposeModal.defaultProps = {
  type: 'create',
  path: '',
  fetchItems: () => {},
  defaultData: {},
  extra: [],
  data: undefined
};
