export const headers = [
  {
    label: 'ps_number',
    key: 'customer.ps_number'
  },
  {
    label: 'company_name',
    key: 'customer.company_name'
  },
  {
    label: 'micro_ps',
    key: 'micro_ps.title'
  },
  {
    label: 'leader',
    key: 'leader.first_name'
  },
  {
    label: 'leader',
    key: 'leader.last_name'
  },
  {
    label: 'action_date',
    key: 'action_date'
  },
  {
    label: 'status',
    key: 'status'
  }
];
