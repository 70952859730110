import { Button, Card, DatePicker, Form, InputNumber, Select } from 'antd';
import { CloseCircleOutlined } from '@ant-design/icons';
import { ColorSelector } from '../ColorSelector';
import useQualityControlContext from '../QualityControlContext';

const { RangePicker } = DatePicker;

/**
 * Hook for the productivities fields.
 *
 * @hook
 * @param {Object} t - i18n translate function
 * @returns {Object} The productivities fields
 */
const useProductivityFields = (t) => {
  const { psMacro, selectedPsMacros, setSelectedPsMacros } =
    useQualityControlContext();

  const handlePsChange = (value, fieldIndex) => {
    const newSelectedPsMacros = [...selectedPsMacros];
    newSelectedPsMacros[fieldIndex] = value;
    setSelectedPsMacros(newSelectedPsMacros);
  };

  const productivitiesFields = [
    {
      name: ['productivities', 'title'],
      noLabel: true,
      input: (
        <Form.List name="productivities" initialValue={[{}]}>
          {(fields, { add, remove }) => (
            <>
              {fields.map((field, index) => (
                <Card
                  key={field.name}
                  style={{ marginBottom: '10px', minWidth: '400px' }}
                >
                  <Form.Item
                    {...field}
                    key={[field.name, 'macro_ps', '_id']}
                    name={[field.name, 'macro_ps', '_id']}
                    label={t('quality.form.productivities.macro_ps')}
                    rules={[{ required: true }]}
                  >
                    <Select
                      onChange={(value) => handlePsChange(value, index)}
                      style={{ width: 250 }}
                    >
                      {psMacro
                        ?.filter(
                          (ps) =>
                            !selectedPsMacros.includes(ps._id) ||
                            ps._id === selectedPsMacros[index]
                        )
                        .map((ps) => (
                          <Select.Option key={ps._id} value={ps._id}>
                            {ps.title}
                          </Select.Option>
                        ))}
                    </Select>
                  </Form.Item>
                  <CloseCircleOutlined
                    style={{
                      position: 'absolute',
                      top: '5px',
                      right: '5px',
                      fontSize: '16px',
                      color: 'red',
                      cursor: 'pointer'
                    }}
                    onClick={() => remove(field.name)}
                  />
                  <Form.Item
                    {...field}
                    key={[field.name, 'range_concerned']}
                    name={[field.name, 'range_concerned']}
                    label={t('quality.form.productivities.range_concerned')}
                  >
                    <RangePicker format="DD-MM-YYYY" />
                  </Form.Item>
                  <Form.Item
                    {...field}
                    key={[field.name, 'average']}
                    name={[field.name, 'average']}
                    label={t('quality.form.productivities.average')}
                  >
                    <InputNumber />
                  </Form.Item>
                  <Form.Item
                    {...field}
                    key={[field.name, 'assessment']}
                    name={[field.name, 'assessment']}
                    label={t('quality.form.productivities.assessment')}
                  >
                    <ColorSelector isGrade />
                  </Form.Item>
                </Card>
              ))}
              <Form.Item>
                <Button type="primary" onClick={add} block>
                  {t('quality.form.productivities.add_type_PS')}
                </Button>
              </Form.Item>
            </>
          )}
        </Form.List>
      )
    }
  ];
  return {
    productivitiesFields: productivitiesFields.map((f) => f)
  };
};

export default useProductivityFields;
