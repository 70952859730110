export const headers = [
  {
    label: 'ps_number',
    key: 'customer.ps_number'
  },
  {
    label: 'company_name',
    key: 'customer.company_name'
  },
  {
    label: 'ps_micro',
    key: 'ps_micro.title'
  },
  {
    label: 'leader',
    key: 'leader.first_name'
  },
  {
    label: 'leader',
    key: 'leader.last_name'
  },
  {
    label: 'reject_number',
    key: 'reject_number'
  },
  {
    label: 'reject_part',
    key: 'reject_part'
  },
  {
    label: 'reject_rate',
    key: 'reject_rate'
  }
];
