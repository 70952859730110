import { useTranslation } from 'react-i18next';
import { Tag } from 'antd';
import { userRoles } from '../../utils/constants/tagColors';
import { formatValue } from '../../utils/formatNumber';

/**
 * Generates an array of table columns configuration for displaying events or data.
 *
 * @hook
 * @returns {Object[]} An array of column configuration objects, each specifying the title,
 *                      dataIndex, key, sorting behavior, and rendering function.
 */

export const useAbsenceColumns = () => {
  const { t } = useTranslation();

  return [
    {
      title: t('events.form.type'),
      dataIndex: 'type',
      key: 'type',
      sorter: true,
      render: (value) => t(`events.form.enums.${value}`)
    },
    {
      title: t('events.form.user_function'),
      dataIndex: 'user',
      key: 'role',
      sorter: true,
      render: (user) =>
        user ? (
          <Tag color={userRoles[user?.role?.split(':')[1]]}>
            {t(`users.tags.${user?.role?.split(':')[1]}`, {
              defaultValue: user?.role?.split(':')[1]
            })}
          </Tag>
        ) : (
          'N/R'
        )
    },
    {
      title: t('events.form.user'),
      dataIndex: 'user',
      key: 'first_name',
      sorter: true,
      render: (value, record) =>
        `${record.user.first_name} ${
          record.user?.usage_last_name || record.user?.last_name || ''
        }`
    },
    {
      title: t('events.form.start_date'),
      dataIndex: 'start_date',
      key: 'start_date',
      sorter: true,
      render: (value) => formatValue(value, 'date') || '-'
    },
    {
      title: t('events.form.startTime'),
      dataIndex: 'startTime',
      key: 'startTime',
      sorter: true,
      render: (value) => formatValue(value, 'time') || '-'
    },
    {
      title: t('events.form.end_date'),
      dataIndex: 'end_date',
      key: 'end_date',
      sorter: true,
      render: (value) => formatValue(value, 'date') || '-'
    },
    {
      title: t('events.form.endTime'),
      dataIndex: 'endTime',
      key: 'endTime',
      sorter: true,
      render: (value) => formatValue(value, 'time') || '-'
    },
    {
      title: t('events.form.makeUpTime'),
      dataIndex: 'makeUpTime',
      key: 'makeUpTime',
      sorter: true,
      render: (value) => <Tag>{value ? t('general.YES') : t('general.NO')}</Tag>
    },
    {
      title: t('events.form.reason'),
      dataIndex: 'reason',
      key: 'reason',
      sorter: true
    },
    {
      title: t('events.form.status'),
      dataIndex: 'status',
      key: 'status',
      sorter: true,
      render: (value) => <Tag>{t(`events.form.enums.${value}`)}</Tag>
    }
  ];
};
