import React, { useState, useEffect } from 'react';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
  Modal,
  Form,
  Select,
  Typography,
  DatePicker,
  Input,
  Button,
  Row
} from 'antd';
import { CloseOutlined, CheckOutlined } from '@ant-design/icons';

const { Option } = Select;
const { TextArea } = Input;

const ModalConfirmationContract = ({
  closeTerminationModal,
  contract,
  setIsTerminationModalOpen,
  isTerminationModalOpen,
  contractEnums
}) => {
  const { t } = useTranslation();
  const [isFieldsLoading, setIsFieldsLoading] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [form] = Form.useForm();

  const handleSubmit = async (values) => {
    setIsSubmitting(true);
    await closeTerminationModal(values);
    setIsSubmitting(false);
  };

  useEffect(() => {
    if (contract) setIsFieldsLoading(false);
  }, []);

  return (
    <Modal
      centered
      title={
        <Typography.Title
          level={4}
          style={{
            fontWeight: 300,
            textUnderlineOffset: '8px',
            color: 'var(--userColor)',
            marginBottom: 30
          }}
          underline
          strong={false}
        >
          {t('contracts.modal_termination.title')}
        </Typography.Title>
      }
      footer={false}
      open={isTerminationModalOpen}
      onCancel={() => {
        setIsTerminationModalOpen(false);
      }}
      width={600}
    >
      <Form form={form} onFinish={handleSubmit}>
        <Form.Item
          name={['resiliation_date']}
          label={t('contracts.form.resiliation_date')}
          rules={[{ required: true }]}
        >
          <DatePicker
            format="DD-MM-YYYY"
            disabledDate={(current) =>
              current && current.endOf('day') < dayjs().endOf('day')
            }
          />
        </Form.Item>
        <Form.Item
          name={['resiliation_reason']}
          label={`${t('contracts.form.resiliation_reason')}`}
          rules={[{ required: true }]}
        >
          <Select allowClear loading={isFieldsLoading}>
            {(contractEnums?.resiliationReasons || []).map(
              (resiliation_reason) => (
                <Option key={resiliation_reason} value={resiliation_reason}>
                  {t(`contracts.tags.${resiliation_reason}`)}
                </Option>
              )
            )}
          </Select>
        </Form.Item>
        <Form.Item
          name={['resiliation_comment']}
          label={`${t('contracts.form.resiliation_comment')}`}
        >
          <TextArea rows={5} />
        </Form.Item>
        <Form.Item
          name={['status']}
          label={`${t('contracts.form.status')}`}
          initialValue="TERMINATED"
          hidden
        >
          <Input />
        </Form.Item>
        <Form.Item>
          <Row justify="end">
            <Button
              style={{ margin: '0 8px' }}
              type="link"
              danger
              onClick={() => setIsTerminationModalOpen(false)}
            >
              {`${t('buttons.cancel')} `}
              <CloseOutlined />
            </Button>
            <Button
              type="primary"
              htmlType="submit"
              loading={isSubmitting}
              disabled={isSubmitting}
            >
              {`${t('buttons.validate')} `}
              <CheckOutlined />
            </Button>
          </Row>
        </Form.Item>
      </Form>
    </Modal>
  );
};

ModalConfirmationContract.propTypes = {
  closeTerminationModal: PropTypes.func.isRequired,
  setIsTerminationModalOpen: PropTypes.func.isRequired,
  contract: PropTypes.shape({
    _id: PropTypes.string
  }),
  isTerminationModalOpen: PropTypes.bool.isRequired,
  contractEnums: PropTypes.arrayOf({})
};

ModalConfirmationContract.defaultProps = {
  contract: [],
  contractEnums: []
};

export default ModalConfirmationContract;
