import pluralize from 'pluralize';
import { pickTagColor } from '../utils/pickTagColor';
import { createTranslationKey } from '../utils/createTranslationKey';

/**
 * Inserts a value into the editor and then closes the options popup.
 *
 * @function
 *
 * @param {string} value - The value to be inserted into the editor.
 * @param {Object} editor - The editor instance where the value will be inserted.
 * @param {function} close - Function to close the options popup.
 * @param {string} type - The value of item selected.
 *
 * @returns {void}
 */
const handleInsert = (value, editor, close, type) => {
  if (editor) {
    if (type === 'Photo') {
      editor.s.insertHTML(`
        <table style="border-collapse:collapse;width: 100%;">
          <tbody>
            <tr>
                <td style="width: 100%;">${value}<br></td>
            </tr>
          </tbody>
        </table>
      `);
    } else {
      editor.s.insertHTML(value);
    }
    editor.selection.focus(); // Focus back to the editor
    close();
  }
};

/**
 * Renders options for model keys in a structured format.
 *
 * @param {Array} items - Array of item objects representing the keys and their subcontent.
 * @param {Object} editor - The editor instance where the options will be inserted.
 * @param {function} t - Translation function for localizing the option labels.
 * @param {function} close - Function to close the options popup.
 * @param {string} [parentKeyPath=''] - The key path of the parent item, if any.
 * @param {number} [depth=0] - The current depth of the nested options for indentation.
 * @returns {HTMLElement} The HTML element containing the structured options.
 */
const renderOptions = (
  items,
  editor,
  t,
  close,
  parentKeyPath = '',
  depth = 0
) => {
  const nbsp = '\u00A0\u00A0\u00A0\u00A0';
  const indent = nbsp.repeat(depth);

  const container = document.createElement('div');
  container.style.padding = '10px';

  items.forEach((item) => {
    let currentKey = parentKeyPath ? `${parentKeyPath}.${item.key}` : item.key;
    const tagColor = pickTagColor(item.type);

    const optionLabel = createTranslationKey('tag', currentKey, t, pluralize);

    if (
      [
        'array-ids',
        'array-object',
        'array-strings',
        'array-array-object'
      ].includes(item.type)
    ) {
      currentKey += '.index';

      if (['array-array-object'].includes(item.type)) {
        currentKey += '.index';
      }
    }

    const itemDiv = document.createElement('div');
    itemDiv.style.marginBottom = '5px';

    if (item.subcontent && item.subcontent.length > 0) {
      const itemLabel = document.createElement('strong');
      itemLabel.innerHTML = indent + optionLabel;
      itemLabel.style.color = tagColor;
      itemDiv.appendChild(itemLabel);

      const subOptions = renderOptions(
        item.subcontent,
        editor,
        t,
        close,
        currentKey,
        depth + 1
      );
      subOptions.style.marginLeft = '10px';
      itemDiv.appendChild(subOptions);
    } else {
      const itemButton = document.createElement('button');
      itemButton.innerHTML = indent + optionLabel;
      itemButton.style.marginTop = '5px';
      itemButton.style.color = tagColor;
      itemButton.style.cursor = 'pointer';
      itemButton.onclick = (e) => {
        e.preventDefault();
        e.stopPropagation();
        handleInsert(`{{${currentKey}}}`, editor, close, item.type);
      };
      itemDiv.appendChild(itemButton);
    }

    container.appendChild(itemDiv);
  });

  return container;
};

/**
 * Generates a model key popup configuration for a text editor.
 *
 * @function
 *
 * @param {Array} tags - Array of tag objects that will be used as options in the popup.
 * @param {function} t - Translation function for localizing the popup text.
 * @returns {Object} Configuration object for the model key popup.
 */
export const modelKeyPopUp = (tags, t) => {
  const tagTypes = ['String', 'Photo', 'Number', 'Date', 'Boolean'];

  return {
    name: 'Clés de modèles',
    tooltip:
      'Clés de modèles qui seront remplacées par des valeurs à la génération',
    popup: (editor, _, close) => {
      const legend = document.createElement('div');

      const legendTitle = document.createElement('div');
      legendTitle.textContent = 'Legend: ';
      legendTitle.style.marginBottom = '10px';
      legend.appendChild(legendTitle);

      const legendContainer = document.createElement('div');
      legendContainer.style.marginBottom = '10px';
      legendContainer.style.display = 'flex';
      legendContainer.style.justifyContent = 'space-between';
      legend.appendChild(legendContainer);

      tagTypes.forEach((type) => {
        const legendItem = document.createElement('span');
        legendItem.textContent = type;
        legendItem.style.color = pickTagColor(type);
        legendItem.style.marginRight = '10px';
        legendContainer.appendChild(legendItem);
      });

      const ButtonTodayTitle = document.createElement('div');
      ButtonTodayTitle.textContent = "Date d'aujourd'hui: ";
      ButtonTodayTitle.style.marginTop = '10px';
      ButtonTodayTitle.style.marginBottom = '10px';
      legend.appendChild(ButtonTodayTitle);

      const itemButton = document.createElement('button');
      itemButton.innerHTML = "Date d'aujourd'hui";
      itemButton.style.marginTop = '5px';
      itemButton.style.marginBottom = '5px';
      itemButton.style.color = pickTagColor('Date');
      itemButton.style.cursor = 'pointer';
      itemButton.onclick = (e) => {
        e.preventDefault();
        e.stopPropagation();
        handleInsert(`{{current.day}}`, editor, close, 'Date');
      };
      legend.appendChild(itemButton);

      const optionsContainer = renderOptions(tags, editor, t, close);
      optionsContainer.insertBefore(legend, optionsContainer.firstChild);
      return optionsContainer;
    }
  };
};
