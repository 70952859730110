import { Input, Select } from 'antd';
import { useTranslation } from 'react-i18next';
import useCustomerContext from '../CustomerContext';

const { Option } = Select;

/**
 * useFields is a custom hook that provides an array of field configurations for customer data input.
 *
 * @hook
 * @returns {Array<Object>} An array of field configurations.
 */

const useFields = () => {
  const { t } = useTranslation();
  const { enums } = useCustomerContext();
  const requiredMessage = t('errors.form.REQUIRED');

  const onsearch = (input, option) => {
    const result = option?.children.toLowerCase();
    return result.includes(input.toLowerCase());
  };

  return [
    {
      name: ['social_reason'],
      key: 'social_reason',
      input: <Input />
    },
    {
      name: ['vat'],
      key: 'vat',
      rules: [{ required: true, message: requiredMessage }],
      input: (
        <Select
          allowClear
          showSearch
          filterOption={(input, option) => onsearch(input, option)}
        >
          {enums?.vats?.map((vat) => (
            <Option key={vat} value={vat}>
              {`${vat}%`}
            </Option>
          ))}
        </Select>
      )
    }
  ];
};

export default useFields;
