/**
 * Custom hook for generating extra informations content data for displaying intervention informations details in a list format.
 *
 * @hook
 * @param {object} data - User data object
 * @returns {Array} Array of objects containing label, span, and content for each field
 */

import { formatValue } from '../../../utils/formatNumber';

export const useExtraInformationsContent = (data = {}) => {
  const {
    company_name,
    address,
    phone_number,
    operator_code,
    session_password,
    contacts
  } = data;

  return [
    {
      label: 'interventions.show.company_name',
      span: 8,
      content: company_name || '-'
    },
    {
      label: 'interventions.show.city',
      span: 8,
      content: address?.city || '-'
    },
    {
      label: 'interventions.show.principal_contact',
      span: 8,
      content:
        contacts?.length > 0
          ? `${contacts?.[0]?.first_name} ${contacts?.[0]?.last_name} `
          : '-'
    },
    {
      label: 'interventions.show.operator_code',
      span: 8,
      content: operator_code || '-'
    },
    {
      label: 'interventions.show.session_password',
      span: 8,
      content: session_password || '-'
    },
    {
      label: 'interventions.show.phone_number',
      span: 8,
      content:
        formatValue(
          `${phone_number?.country_code || ''}${phone_number?.number || ''}`,
          'phone'
        ) || '-'
    }
  ];
};
