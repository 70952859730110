import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { Modal } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { CreateUpdateContainer } from '../../components';
import useQualityControlFields from './QualityControlFields';
import useInterventionContext from './InterventionContext';
import { QualityControlsCards } from './QualityControlsCards';
import { useAuthContext } from '../../contexts/AuthContext';
import { useErrorMessage } from '../../utils/errorMessage';

/**
 * Component for displaying the quality control modal.
 *
 * @component
 * @param {string} id - The id of the intervention
 * @param {boolean} isQualityControlOpen - The state of the quality control modal
 * @param {Function} setIsQualityControlOpen - The function to set the state of the quality control modal
 * @returns {JSX.Element} The quality control modal component
 */
export const QualityControlModal = ({
  id,
  isQualityControlOpen,
  setIsQualityControlOpen
}) => {
  const { t } = useTranslation();
  const { enums, setQualityControlFiles, setSelectedCategories } =
    useInterventionContext();
  const { qualityControlFields } = useQualityControlFields(
    enums?.categories,
    t
  );
  const { dispatchAPI } = useAuthContext();
  const [purpose, setPurpose] = useState('show');
  const [qualityControls, setQualityControls] = useState([]);
  const [qualityStatus, setQualityStatus] = useState();
  const { message } = useErrorMessage();

  const getQualityControls = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/interventions/quality/${id}`
      });
      setQualityControls(data.qualities);
      setQualityStatus(data.quality_status);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const config = {
    onGetResource: {
      setFields: (data) => {
        const fileList = {};
        data.qualities.forEach((quality, index) => {
          fileList[index] = quality.files;
        });
        setQualityControlFiles(fileList);
        setSelectedCategories(
          data.qualities.map((quality) => quality.category)
        );
        return {
          ...data
        };
      }
    },

    onUpdateResource: {
      setBody: (data) => {
        const formData = new FormData();
        formData.append(
          'values',
          JSON.stringify({
            ...data,
            qualities: data.qualities.map((q) => ({
              category: q.category,
              assessment: q.assessment,
              comment: q.comment,
              files: q.files
            }))
          })
        );
        data.qualities.forEach((quality) => {
          quality.files?.fileList?.forEach((file) => {
            formData.append(quality.category, file.originFileObj);
          });
        });
        return formData;
      }
    }
  };

  useEffect(() => {
    (async () => {
      if (isQualityControlOpen) {
        await getQualityControls();
      }
    })();
  }, [isQualityControlOpen]);

  return (
    <Modal
      centered
      open={isQualityControlOpen}
      onCancel={() => setIsQualityControlOpen(false)}
      width={800}
      footer={purpose !== 'edit' ? undefined : false}
      title={t('interventions.form.quality_control.title')}
      okText={t('buttons.edit')}
      onOk={() => setPurpose('edit')}
      okButtonProps={{
        disabled: qualityStatus && qualityStatus !== 'ON_GOING'
      }}
      cancelText={t('buttons.back')}
      closeIcon={<InfoCircleOutlined />}
      closable={false}
    >
      {purpose === 'edit' ? (
        <CreateUpdateContainer
          fields={qualityControlFields}
          loadingFields={false}
          populate="qualities.files"
          purpose="edit"
          baseUrl="interventions/quality"
          resource="intervention"
          isOverlay
          customNavigate={() => setIsQualityControlOpen(false)}
          config={config}
          withHeader={false}
          refresh
        />
      ) : (
        <QualityControlsCards qualityControls={qualityControls} />
      )}
    </Modal>
  );
};

QualityControlModal.propTypes = {
  id: PropTypes.string.isRequired,
  isQualityControlOpen: PropTypes.bool,
  setIsQualityControlOpen: PropTypes.func
};

QualityControlModal.defaultProps = {
  isQualityControlOpen: false,
  setIsQualityControlOpen: () => {}
};
