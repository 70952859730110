import { Tag, Button } from 'antd';
import { ExportOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { actionStatus } from '../../../utils/constants/tagColors';
import { routes } from '../../../utils/constants/adminRoutes';
import { formatValue } from '../../../utils/formatNumber';

/**
 * Custom hook for generating table columns for displaying actions reminder data.
 *
 * @hook
 * @param {object} actionEnums - Enumerations data for actions reminder properties
 * @returns {Array} Array of table column objects
 */

export const useColumns = (actionEnums) => {
  const { t } = useTranslation();

  return [
    {
      title: t('interventions.show.actions_reminder.columns.status'),
      key: 'action_reminder',
      dataIndex: ['action_reminder'],
      render: (action_reminder) => (
        <Tag
          color={
            actionStatus[
              dayjs(action_reminder).isBefore(dayjs()) ||
              dayjs(action_reminder).isSame(dayjs())
                ? 'URGENT'
                : 'UPCOMING'
            ]
          }
        >
          {t(
            `interventions.show.actions_reminder.enums.${
              dayjs(action_reminder).isBefore(dayjs()) ||
              dayjs(action_reminder).isSame(dayjs())
                ? 'URGENT'
                : 'UPCOMING'
            }`
          )}
        </Tag>
      ),
      sorter: true,
      filters: ['URGENT', 'UPCOMING'].map((s) => ({
        text: t(`interventions.show.actions_reminder.enums.${s}`),
        value: s
      }))
    },
    {
      title: t('interventions.show.actions_reminder.columns.fse_number'),
      key: 'fse_number',
      dataIndex: 'fse_number',
      sorter: true,
      render: (fse_number, data) => fse_number || data?.task_number?.title
    },
    {
      title: t('interventions.form.ps_number'),
      key: 'ps_number',
      dataIndex: ['customer'],
      sorter: true,
      render: (customer) => (customer?.ps_number ? customer.ps_number : '-')
    },
    {
      title: t('interventions.form.company_name'),
      key: 'company_name',
      dataIndex: ['customer'],
      sorter: true,
      render: (customer) =>
        customer?.company_name ? customer.company_name : '-'
    },
    {
      title: t('interventions.show.actions_reminder.columns.created_at'),
      key: 'created_at',
      dataIndex: 'created_at',
      render: (created_at) => formatValue(created_at, 'date'),
      sorter: true
    },
    {
      title: t('interventions.show.actions_reminder.columns.created_by'),
      key: 'created_by',
      dataIndex: 'created_by',
      sorter: true,
      render: (created_by) =>
        `${created_by?.first_name} ${
          created_by?.usage_last_name || created_by?.last_name
        }`
    },
    {
      title: t('interventions.show.actions_reminder.columns.action_reminder'),
      key: 'action_reminder',
      dataIndex: 'action_reminder',
      sorter: true,
      render: (action_reminder) => formatValue(action_reminder, 'date')
    },
    {
      title: t('interventions.show.actions_reminder.columns.part'),
      key: 'part',
      dataIndex: 'part',
      render: (part) =>
        part ? t(`interventions.show.actions_reminder.enums.${part}`) : '-',
      sorter: true,
      filters: actionEnums?.parts?.map((p) => ({
        text: t(`interventions.show.actions_reminder.enums.${p}`),
        value: p
      }))
    },
    {
      title: t('interventions.show.actions_reminder.columns.amount'),
      key: 'amount',
      dataIndex: 'amount',
      render: (amount) => (amount ? `${formatValue(amount, 'currency')}` : '-'),
      sorter: true
    },
    {
      title: t('interventions.show.actions_reminder.columns.rejection_reason'),
      key: 'rejection_reason',
      dataIndex: 'rejection_reason',
      render: (rejection_reason) => rejection_reason,
      sorter: true,
      filters: actionEnums?.rejectReasons?.map((r) => ({
        text: t(`interventions.show.actions_reminder.enums.${r}`),
        value: r
      }))
    },
    {
      title: t('interventions.show.actions_reminder.columns.comment'),
      key: 'comment',
      dataIndex: 'comment'
    },
    {
      key: 'action',
      align: 'right',
      dataIndex: 'intervention',
      render: (interventionId) => (
        <Button type="link" className="event-btn first">
          <a
            href={`${routes.INTERVENTIONS}/show/${interventionId}`}
            target="_blank"
            rel="noopener noreferrer"
            aria-label="Voir l'intervention"
          >
            <ExportOutlined />
          </a>
        </Button>
      )
    }
  ];
};
