export const accountingAccounts = {
  MANAGEMENT_MEDICAL: {
    20: 706001,
    8.5: 706101,
    0: 706201
  },
  MANAGEMENT_DENTAL: {
    20: 706002,
    8.5: 706102,
    0: 706202
  },
  MANAGEMENT_LABORATORY: {
    20: 706003,
    8.5: 706103,
    0: 706203
  },
  MANAGEMENT_PHARMACIE: {
    20: 706004,
    8.5: 706104,
    0: 706204
  },
  MANAGEMENT_AUDIO: {
    20: 706007,
    8.5: 706107,
    0: 706207
  },
  MANAGEMENT_OTHERS: {
    20: 706010,
    8.5: 706110,
    0: 7062210
  },
  MANAGEMENT_OPTICAL: {
    20: 706014,
    8.5: 706114,
    0: 706214
  },
  LIABILITIES_TREATMENT_PHARMACY: {
    20: 706024,
    8.5: 706124,
    0: 706224
  },
  LIABILITIES_TREATMENT_OPTICAL: {
    20: 706025,
    8.5: 706125,
    0: 706225
  },
  LIABILITIES_TREATMENT_MEDICAL: {
    20: 706026,
    8.5: 706126,
    0: 706226
  },
  LIABILITIES_TREATMENT_LABORATORY: {
    20: 706027,
    8.5: 706127,
    0: 706227
  },
  LIABILITIES_TREATMENT_DENTAL: {
    20: 706028,
    8.5: 706128,
    0: 706228
  },
  LIABILITIES_TREATMENT_AUDIO: {
    20: 706029,
    8.5: 706129,
    0: 706229
  },
  LIABILITIES_TREATMENT_OTHER: {
    20: 706030,
    8.5: 706130,
    0: 706230
  },
  FINANCIAL_TRACKING: {
    20: 706033,
    8.5: 706133,
    0: 706233
  },
  CONFIGURATION: {
    20: 706021,
    8.5: 706121,
    0: 706221
  },
  OPTIMISATION: {
    20: 706009,
    8.5: 706109,
    0: 706209
  },
  CONFIG_OPTI: {
    20: 706023,
    8.5: 706123,
    0: 706223
  },
  SEO: {
    20: 706006,
    8.5: 706106,
    0: 706206
  },
  MISSION: {
    20: 706031,
    8.5: 706131,
    0: 706231
  },
  BILLING_TELEMONITORING: {
    20: 706022,
    8.5: 706122,
    0: 706222
  },
  SHIPPING_COSTS: {
    20: 706015,
    8.5: 706115,
    0: 706215
  },
  REMINDER_FEES: {
    20: 706016,
    8.5: 706016,
    0: 706016
  },
  COMMUTING: {
    20: 708102,
    8.5: 708102,
    0: 708102
  },
  OTHER: {
    20: 706032,
    8.5: 706132,
    0: 706232
  }
};
