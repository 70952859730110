export const headers = [
  {
    label: 'status',
    key: 'status'
  },
  {
    label: 'macro_ps',
    key: 'macro_ps.title'
  },
  {
    label: 'contract.ps_number',
    key: 'contract.ps_number'
  },
  {
    label: 'code',
    key: 'code'
  },
  {
    label: 'contract.company_name',
    key: 'contract.company_name'
  },
  {
    label: 'user',
    key: 'user.first_name'
  },
  {
    label: 'user',
    key: 'user.last_name'
  },
  {
    label: 'scheduled_start_date',
    key: 'scheduled_start_date'
  },
  {
    label: 'scheduled_start_date_hour',
    key: 'scheduled_start_date_hour'
  },
  {
    label: 'start_date',
    key: 'start_date'
  },
  {
    label: 'end_date',
    key: 'end_date'
  },
  {
    label: 'total_time',
    key: 'total_time'
  },
  {
    label: 'actions',
    key: 'actions'
  },
  {
    label: 'emails',
    key: 'emails'
  },
  {
    label: 'files',
    key: 'files'
  },
  {
    label: 'report_sent',
    key: 'report_sent'
  }
];
