import { useTranslation } from 'react-i18next';
import { Select, DatePicker, Input } from 'antd';
import moment from 'moment';
import useFeesContext from './FeesContext';

export const useFeesFields = () => {
  const { t } = useTranslation();
  const { enums, accounts, setService, ps_micro } = useFeesContext();
  const ruleMessage = t('errors.form.REQUIRED');

  const onsearch = (input, option) => {
    const result = option?.label.toLowerCase();
    return result.includes(input.toLowerCase());
  };

  const optionsFormat = (data) =>
    data?.map((item) => ({
      label: t(`fees.enums.${item}`),
      value: item
    })) || [];

  return [
    {
      label: 'service',
      name: 'service',
      rules: [{ required: true, message: ruleMessage }],
      input: (
        <Select
          options={optionsFormat(enums?.services)}
          onChange={(value) => setService(value)}
          allowClear
          showSearch
          filterOption={(input, option) => onsearch(input, option)}
          onClear={() => {
            setService(null);
          }}
        />
      )
    },
    {
      label: 'account',
      input: <Input disabled value={accounts?.account} />
    },
    {
      label: 'label',
      input: <Input disabled value={accounts?.label} />
    },
    {
      label: 'fee_code',
      name: 'fee_code',
      rules: [{ required: true, message: ruleMessage }],
      input: (
        <Select
          options={enums?.codes?.map((code) => ({
            label: code,
            value: code
          }))}
          allowClear
          showSearch
          filterOption={(input, option) => onsearch(input, option)}
        />
      )
    },
    {
      label: 'fee_type',
      name: 'fee_type',
      rules: [{ required: true, message: ruleMessage }],
      input: <Select options={optionsFormat(enums?.types)} />
    },
    {
      label: 'micro_ps',
      name: 'micro_ps',
      rules: [{ required: true, message: ruleMessage }],
      input: (
        <Select
          showSearch
          filterOption={(input, option) => onsearch(input, option)}
          options={ps_micro?.map((item) => ({
            label: item.title,
            value: item._id
          }))}
        />
      )
    },
    {
      label: 'amount',
      name: 'amount',
      rules: [{ required: true, message: ruleMessage }],
      input: <Input type="number" suffix="€" />
    },

    {
      label: 'discount',
      name: 'discount',
      input: <Input type="number" suffix="%" />
    },
    {
      label: 'start_date',
      name: 'start_date',
      rules: [{ required: true, message: ruleMessage }],
      input: (
        <DatePicker
          format="DD-MM-YYYY"
          disabledDate={(current) => {
            if (current) return moment().add(-1, 'days') >= current;
            return false;
          }}
        />
      )
    }
  ];
};
