import { useRef } from 'react';
import { Input, Upload, Button, Select, Form, Divider } from 'antd';
import { useTranslation } from 'react-i18next';
import { UploadOutlined, CloseCircleOutlined } from '@ant-design/icons';
import JoditEditor from 'jodit-react';

const { Option } = Select;

/**
 * A custom hook that generates an array of form fields based on the provided parameters.
 *
 * @hook
 * @param {Object} params - The parameters for generating form fields.
 * @param {Object} params.enums - An object containing enumerated values used for field options.
 * @param {string} params.content - The content for a rich text field.
 * @param {Function} params.setContent - A function to update the content of the rich text field.
 * @param {string} params.purpose - The purpose of the form fields, such as 'create' or 'edit'.
 * @param {Function} params.uploadOrDeleteFile - A function for handling file upload or deletion.
 * @param {boolean} params.isLoading - Indicates whether data is loading.
 * @param {string} params.id - The ID of the form data when editing.
 * @param {Array} params.fileList - An array of files to display in the file upload field.
 * @param {Function} params.setFileList - A function to update the file list.
 * @returns {Array} An array of form field objects.
 */
export const useFields = ({
  setFileList,
  fileList,
  setContent,
  content,
  isLoading,
  contacts,
  templates,
  company,
  customerEnums,
  subject,
  useCaseFilter,
  id,
  modelName,
  idModal
}) => {
  const { t } = useTranslation();
  const editorRef = useRef(null);

  const onsearch = (input, option) => {
    const result = option?.children.toLowerCase();
    return result.includes(input.toLowerCase());
  };

  const fields = [
    {
      name: ['to'],
      rules: [{ required: true }],
      input: (
        <Select
          loading={isLoading}
          mode="multiple"
          allowClear
          showSearch
          filterOption={(input, option) => onsearch(input, option)}
        >
          {(contacts || [])
            .filter((f) => f.email)
            .map((contact) => (
              <Option key={contact?._id} value={contact?._id}>
                {`${contact?.first_name} ${contact?.last_name} (${contact?.email})`}
              </Option>
            ))}
        </Select>
      )
    },
    {
      name: ['extra_contacts'],
      input: (
        <Form.List name="extra_contacts" initialValue={[]}>
          {(extra_contacts, { add, remove }) => (
            <>
              {extra_contacts.map((field) => (
                <div key={field.name}>
                  <Form.Item
                    {...field}
                    key={[field.name, 'email']}
                    name={[field.name, 'email']}
                    label={t('emails.form.extra.email')}
                    rules={[{ required: true }]}
                  >
                    <Input type="email" />
                  </Form.Item>
                  <CloseCircleOutlined
                    style={{
                      position: 'absolute',
                      top: '5px',
                      right: '5px',
                      fontSize: '16px',
                      color: 'red',
                      cursor: 'pointer'
                    }}
                    onClick={() => remove(field.name)}
                  />
                  <Form.Item
                    {...field}
                    key={[field.name, 'first_name']}
                    name={[field.name, 'first_name']}
                    label={t('emails.form.extra.first_name')}
                    rules={[{ required: true }]}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    {...field}
                    key={[field.name, 'last_name']}
                    name={[field.name, 'last_name']}
                    label={t('emails.form.extra.last_name')}
                    rules={[{ required: true }]}
                  >
                    <Input />
                  </Form.Item>
                  <Divider />
                </div>
              ))}
              <Form.Item>
                <Button type="primary" onClick={() => add()}>
                  {t('emails.form.extra.add')}
                </Button>
              </Form.Item>
            </>
          )}
        </Form.List>
      )
    },
    {
      name: ['template'],
      rules: [{ required: true }],
      input: (
        <Select
          loading={isLoading}
          allowClear
          showSearch
          filterOption={(input, option) => onsearch(input, option)}
          onClear={() => {
            setContent('');
          }}
        >
          {(templates || [])
            .filter((f) => f.use_case === useCaseFilter)
            .map((template) => (
              <Option key={template._id} value={template._id}>
                {template.title}
              </Option>
            ))}
        </Select>
      )
    },
    {
      name: ['company'],
      rules: [{ required: true }],
      initialValue: company,
      input: (
        <Select
          loading={isLoading}
          allowClear
          showSearch
          filterOption={(input, option) => onsearch(input, option)}
          disabled
        >
          {(customerEnums?.companies || []).map((comp) => (
            <Option key={comp} value={comp}>
              {t(`employments.tags.${comp}`)}
            </Option>
          ))}
        </Select>
      )
    },
    {
      name: ['files'],
      input: (
        <Upload
          multiple
          maxCount={1}
          beforeUpload={(file) => {
            setFileList([file]);
          }}
          onRemove={() => {
            setFileList();
          }}
          fileList={
            isLoading
              ? []
              : (fileList || []).map((file, index) => ({
                  uid: index,
                  name: file?.metadata?.originalName || file?.name,
                  status: 'done'
                }))
          }
        >
          <Button icon={<UploadOutlined />}>{t('buttons.upload_2')}</Button>
        </Upload>
      )
    },
    {
      name: ['subject'],
      initialValue: subject || '',
      rules: [{ required: true }],
      input: <Input />
    },
    {
      name: ['content'],
      rules: [{ required: true }],
      input: (
        <JoditEditor
          ref={editorRef}
          placeholder="Commencez votre texte..."
          value={content}
          onChange={setContent}
          style={{ height: '300px', marginBottom: '20px', minWidth: 640 }}
        />
      )
    },
    {
      name: ['id'],
      initialValue: id || idModal,
      rules: [{ required: true }],
      hidden: true,
      input: <Input />
    },
    {
      name: ['model_name'],
      initialValue: modelName,
      rules: [{ required: true }],
      hidden: true,
      input: <Input />
    }
  ];

  return fields;
};
