const restrictions = {
  'guests:GUEST': {
    '/interventions': ['create', 'export', 'archive'],
    '/interventions/show': ['create', 'edit', 'archive', 'cancel', 'link'],
    '/customers': ['filters', 'create', 'edit', 'export'],
    '/customers/show': [
      'create',
      'archive',
      'validate',
      'edit',
      'delete_doc',
      'comment',
      'show_invoice',
      'show_quittance',
      'edit_website',
      'delete_website',
      'edit_collab',
      'archive_collab',
      'edit_contact',
      'archive_contact'
    ],
    '/contract/show': ['edit', 'terminate', 'archive'],
    '/profile': ['edit']
  },
  'guests:GROUP': {
    '/interventions': ['create', 'export', 'archive'],
    '/interventions/show': ['create', 'edit', 'archive', 'cancel', 'link'],
    '/customers': ['filters', 'create', 'edit', 'export', 'validate'],
    '/customers/show': [
      'create',
      'archive',
      'validate',
      'edit',
      'delete_doc',
      'comment',
      'show_invoice',
      'show_quittance',
      'edit_website',
      'delete_website',
      'edit_collab',
      'archive_collab',
      'edit_contact',
      'archive_contact'
    ],
    '/contract/show': ['edit', 'terminate', 'archive'],
    '/profile': ['edit']
  },
  'guests:ADMIN-GUEST': {
    '/interventions': ['create', 'export', 'archive'],
    '/interventions/show': ['create', 'edit', 'archive', 'cancel', 'link'],
    '/customers': ['filters', 'create', 'edit', 'export'],
    '/customers/show': [
      'create',
      'archive',
      'validate',
      'edit',
      'delete_doc',
      'comment',
      'show_invoice',
      'show_quittance',
      'edit_website',
      'delete_website'
    ],
    '/contract/show': ['edit', 'terminate', 'archive'],
    '/profile': ['edit']
  },
  'users:USER': {
    '/interventions': ['archive', 'export'],
    '/interventions/show': [],
    '/customerservices': ['export', 'archive'],
    '/customerservices/show': ['archive', 'delete_doc'],
    '/customers': ['edit', 'create'],
    '/customers/show': [
      'archive',
      'validate',
      'edit',
      'delete_doc',
      'archive_collab',
      'edit_collab',
      'edit_contact',
      'archive_contact'
    ],
    '/customers/edit': [],
    '/actualities': ['create', 'archive', 'duplicate'],
    '/actualities/show': ['stats', 'archive', 'duplicate', 'delete_doc'],
    '/quote': ['export', 'archive'],
    '/quality_control': ['edit', 'archive'],
    '/quality_control/show': ['edit', 'archive', 'close'],
    '/users': ['edit', 'archive', 'create'],
    '/users/show': ['create', 'edit', 'archive'],
    '/rh/absence-vacation': ['archive'],
    '/rh/absence-vacation/show': ['archive', 'edit', 'validate', 'refuse'],
    '/profile': ['edit']
  },
  'users:SALES-USER': {
    '/interventions': ['archive', 'export', 'create'],
    '/interventions/show': [],
    '/customerservices': ['export', 'archive'],
    '/customerservices/show': ['archive', 'delete_doc'],
    '/customerservices/edit': ['description'],
    '/customers': ['show'],
    '/customers/show': [
      'archive',
      'delete_website',
      'export_website',
      'create_website',
      'edit_website',
      'delete_doc',
      'edit',
      'create',
      'edit_contact',
      'archive_contact',
      'archive_collab',
      'edit_collab'
    ],
    '/customers/edit': [],
    '/actualities': ['create', 'archive', 'duplicate'],
    '/actualities/show': ['stats', 'archive', 'duplicate', 'delete_doc'],
    '/quote': ['export', 'archive'],
    '/quality_control/show': ['edit', 'archive', 'close'],
    '/users': ['edit', 'archive', 'create'],
    '/users/show': ['edit', 'create', 'archive'],
    '/rh/absence-vacation': ['archive'],
    '/rh/absence-vacation/show': ['archive', 'edit', 'validate', 'refuse'],
    '/profile': ['edit']
  },
  'users:LEADER-USER': {
    '/interventions': ['archive'],
    '/interventions/show': [],
    '/customerservices': ['export', 'archive'],
    '/customerservices/show': ['archive', 'delete_doc'],
    '/customerservices/edit': [
      'name',
      'status',
      'customer',
      'recorded_time',
      'created_by',
      'recipients',
      'completion_date',
      'origin',
      'description',
      'remove_doc'
    ],
    '/customers': ['edit', 'create'],
    '/customers/show': [
      'archive',
      'validate',
      'edit',
      'delete_doc',
      'download_doc'
    ],
    '/customers/edit': [],
    '/actualities': ['create', 'archive', 'duplicate'],
    '/actualities/show': ['stats', 'archive', 'duplicate', 'delete_doc'],
    '/quote': ['export', 'archive'],
    '/quality_control': ['edit', 'archive'],
    '/quality_control/show': ['archive', 'close'],
    '/users': ['edit', 'archive', 'create'],
    '/users/show': ['create', 'edit', 'archive'],
    '/rh/absence-vacation': ['archive'],
    '/rh/absence-vacation/show': ['archive', 'edit', 'validate', 'refuse'],
    '/profile': ['edit']
  },
  'admins:ADMIN-PROD': {
    '/interventions': ['archive'],
    '/interventions/show': [],
    '/customerservices': ['export', 'archive'],
    '/customerservices/show': ['archive'],
    '/customerservices/edit': [],
    '/customers': ['edit', 'create'],
    '/customers/show': [
      'archive',
      'validate',
      'edit',
      'delete_doc',
      'download_doc'
    ],
    '/customers/edit': [],
    '/actualities': ['create', 'archive', 'duplicate'],
    '/actualities/show': ['stats', 'archive', 'duplicate', 'delete_doc'],
    '/quote': ['export', 'archive', 'create', 'edit'],
    '/quote/show': ['action', 'archive', 'edit'],
    '/contract': ['edit'],
    '/contract/show': ['edit', 'terminate', 'archive'],
    '/quality_control': ['archive'],
    '/quality_control/show': ['archive', 'close'],
    '/users': ['edit', 'archive', 'create'],
    '/users/show': ['create', 'edit', 'archive'],
    '/rh/absence-vacation': ['archive'],
    '/rh/absence-vacation/show': ['archive', 'edit', 'validate', 'refuse'],
    '/profile': []
  },
  'admins:ADMIN': {
    '/interventions': [],
    '/interventions/show': [],
    '/customerservices': [],
    '/customerservices/show': [],
    '/customerservices/edit': [],
    '/customers': [],
    '/customers/edit': [],
    '/actualities': [],
    '/actualities/show': [],
    '/quote': [],
    '/profile': []
  },
  'admins:SUPER-ADMIN': {
    '/interventions': [],
    '/interventions/show': [],
    '/customerservices': [],
    '/customerservices/show': [],
    '/customerservices/edit': [],
    '/customers': [],
    '/customers/edit': [],
    '/actualities': [],
    '/actualities/show': [],
    '/quote': [],
    '/profile': []
  }
};

/**
 * isAuthorized checks if a user has permission to access a component of a certain route.
 *
 * @function
 * @param {String} url - The URL path.
 * @param {String} role - The user's role.
 * @param {String} purpose - The component that the user is trying to access.
 * @returns {Boolean} Whether the user has permission to access the component.
 */
export const isAuthorized = (url, role, purpose) => {
  let parts = url.toLocaleLowerCase().split('/show');
  let pathname = parts[0];
  if (parts.length > 1) {
    pathname += '/show';
  } else {
    parts = url.toLocaleLowerCase().split('/edit');
    pathname = parts[0];
    if (parts.length > 1) {
      pathname += '/edit';
    }
  }

  const hasPermission = !restrictions[role]?.[pathname]?.includes(purpose);

  return hasPermission;
};
