import { useEffect } from 'react';
import { useAuthContext } from '../contexts/AuthContext';
import { GroupRouter } from './GroupRouter';
import { GuestRouter } from './GuestRouter';
import { UserRouter } from './UserRouter';
import { AdminRouter } from './AdminRouter';
import { useThemeContext } from '../contexts/ThemeContext';

export const RouterDispatch = () => {
  const { user } = useAuthContext();
  const { setTheme } = useThemeContext();

  useEffect(() => {
    const body = document.body;
    if (body && !user) {
      body.className = 'user';
      setTheme('user');
    }
  }, [user]);

  const dispatchRouter = () => {
    switch (user?.role) {
      case 'admins:ADMIN':
      case 'admins:ADMIN-PROD':
      case 'admins:ADMIN-ACCOUNTANT':
      case 'admins:ADMIN-RH':
      case 'admins:SUPER-ADMIN':
        return <AdminRouter />;
      case 'users:USER':
      case 'users:LEADER-USER':
      case 'users:SALES-USER':
        return <UserRouter />;
      case 'guests:GUEST':
      case 'guests:ADMIN-GUEST':
        return <GuestRouter />;
      case 'guests:GROUP':
        return <GroupRouter />;
      default:
        return <UserRouter />;
    }
  };
  return dispatchRouter();
};
