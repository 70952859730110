import { Select, Input } from 'antd';
import dayjs from 'dayjs';

const { Option } = Select;
const { TextArea } = Input;

/**
 * Custom hook to generate form fields for creating or updating transfers.
 *
 * @param {boolean} isLoading Indicates if data is currently loading.
 * @param {Function} setSelectedMonth Function to set the selected month state.
 * @param {string} selectedMonth The currently selected month.
 * @param {Array} bankReconciliations List of bank reconciliation records.
 * @param {Array} transferDates List of transfer dates for the selected month and bank reconciliation.
 * @param {Function} setSelectedBankReconciliation Function to set the selected bank reconciliation state.
 * @param {Function} setLevyDate set the selected levy_date state.
 * @returns {Array} An array of objects representing each field configuration for the form.
 */
const useFields = (
  isLoading,
  setSelectedMonth,
  selectedMonth,
  bankReconciliations,
  transferDates,
  setSelectedBankReconciliation,
  setLevyDate
) => {
  const today = new Date();
  const year = today.getFullYear();
  const months = [];

  for (let i = today.getMonth(); i > today.getMonth() - 6; i -= 1) {
    if (i + 1 > 0) {
      const month = (i + 1).toString().length === 1 ? `0${i + 1}` : `${i + 1}`;
      months.push(`${month}/${year}`);
    } else {
      const month =
        (12 + (i + 1)).toString().length === 1
          ? `0${12 + (i + 1)}`
          : `${12 + (i + 1)}`;
      months.push(`${month}/${year - 1}`);
    }
  }

  return [
    {
      name: ['bank_account'],
      rules: [{ required: true }],
      label: 'bank_account',
      input: (
        <Select
          loading={isLoading}
          onSelect={(value) => setSelectedBankReconciliation(value)}
        >
          {(bankReconciliations || []).map((b) => (
            <Option key={b._id} value={b._id}>{`${b.name} (${b.iban})`}</Option>
          ))}
        </Select>
      ),
      purpose: ['create']
    },
    {
      name: ['month'],
      rules: [{ required: true }],
      label: 'month',
      input: (
        <Select
          loading={isLoading}
          onSelect={(value) => setSelectedMonth(value)}
        >
          {months.reverse().map((type) => (
            <Option value={type}>{type}</Option>
          ))}
        </Select>
      ),
      purpose: ['create']
    },
    {
      name: ['levy_date'],
      rules: [{ required: true }],
      label: 'levy_date',
      input: (
        <Select
          loading={isLoading}
          disabled={!selectedMonth}
          onSelect={(_, data) => setLevyDate(data)}
        >
          {(transferDates || []).map((t) => (
            <Option value={t.transfer_date}>{`${dayjs(t.transfer_date).format(
              'DD/MM/YYYY'
            )} (Total factures: ${t.total}) ${
              t.rejected ? ' REJETÉE' : ''
            }`}</Option>
          ))}
        </Select>
      ),
      purpose: ['create']
    },
    {
      name: ['comment'],
      label: 'comment',
      input: <TextArea />,
      purpose: ['create', 'edit']
    }
  ];
};

export default useFields;
