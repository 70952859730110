import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Card, Form, Input, Button, Select, DatePicker } from 'antd';
import { useTranslation } from 'react-i18next';
import { CloseCircleOutlined } from '@ant-design/icons';

/**
 * Component for creating or nested Form List for quote fields services.
 *
 * @component
 * @returns {JSX.Element} FormListNested component inside a Form List
 */

const { Option } = Select;
const { RangePicker } = DatePicker;

export const FormListNested = ({ name, serviceEnums }) => {
  const { t } = useTranslation();
  const [isFieldsLoading, setIsFieldsLoading] = useState(true);

  const onsearch = (input, option) => {
    const result = option?.children.toLowerCase();
    return result.includes(input.toLowerCase());
  };

  useEffect(() => {
    if (serviceEnums) setIsFieldsLoading(false);
  }, []);

  return (
    <Form.List name={[name, 'discounts']}>
      {(fields, { add, remove }) => (
        <>
          {fields.map((field) => (
            <Card key={field.name}>
              <Form.Item
                {...field}
                key={[field.name, 'amount']}
                name={[field.name, 'amount']}
                label={t('quotes.form.service.discounts.amount')}
                rules={[
                  {
                    required: true
                  }
                ]}
              >
                <Input type="number" />
              </Form.Item>
              <Form.Item
                {...field}
                key={[field.name, 'amount_type']}
                name={[field.name, 'amount_type']}
                label={t('quotes.form.service.discounts.amount_type')}
                rules={[
                  {
                    required: true
                  }
                ]}
                initialValue="€"
              >
                <Select
                  style={{ width: '250px', marginRight: 20 }}
                  loading={isFieldsLoading}
                >
                  {['€', '%'].map((type) => (
                    <Option key={type} value={type}>
                      {type}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
              <CloseCircleOutlined
                style={{
                  position: 'absolute',
                  top: '5px',
                  right: '5px',
                  fontSize: '16px',
                  color: 'red',
                  cursor: 'pointer'
                }}
                onClick={() => remove(field.name)}
              />
              <Form.Item
                {...field}
                key={[field.name, 'type']}
                name={[field.name, 'type']}
                label={t('quotes.form.service.discounts.type')}
                rules={[
                  {
                    required: true
                  }
                ]}
              >
                <Select
                  style={{ width: '250px', marginRight: 20 }}
                  allowClear
                  showSearch
                  loading={isFieldsLoading}
                  filterOption={(input, option) => onsearch(input, option)}
                >
                  {serviceEnums?.discounts?.map((discount) => (
                    <Option key={discount} value={discount}>
                      {t(`quotes.tags.${discount}`)}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item
                {...field}
                key={[field.name, 'periods']}
                name={[field.name, 'periods']}
                label={t('quotes.form.service.discounts.periods')}
                rules={[
                  {
                    required: true
                  }
                ]}
              >
                <RangePicker picker="month" />
              </Form.Item>
            </Card>
          ))}
          <Form.Item>
            <Button type="dashed" onClick={() => add()}>
              {t('quotes.form.service.discounts.add_discount')}
            </Button>
          </Form.Item>
        </>
      )}
    </Form.List>
  );
};

FormListNested.propTypes = {
  serviceEnums: PropTypes.shape({
    discounts: PropTypes.arrayOf(PropTypes.shape({}))
  }).isRequired,
  name: PropTypes.string.isRequired
};
