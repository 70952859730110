export const headers = [
  {
    label: 'title_field',
    key: 'title'
  },
  {
    label: 'status',
    key: 'status'
  },
  {
    label: 'category',
    key: 'category'
  },
  {
    label: 'description',
    key: 'description'
  },
  {
    label: 'created_at',
    key: 'created_at'
  }
];
