import { Card } from 'antd';
import { useTranslation } from 'react-i18next';
import { ListResource } from '../../../components';
import { useColumns } from './columns';
import useHomeContext from '../HomeContext';
import { routes } from '../../../utils/constants/adminRoutes';

/**
 * Card component for displaying quality control data.
 *
 * @component
 * @returns {JSX.Element} The JSX element representing the QualityControlCard component.
 */
export const QualityControlCard = () => {
  const { t } = useTranslation();
  const { qualityEnums } = useHomeContext();
  const columns = useColumns(qualityEnums);
  return (
    <Card title={t('quality.title')}>
      <ListResource
        withPageHeader={false}
        contentCustomStyle={{ margin: 0, padding: 0 }}
        resourceName="quality"
        resourceModelName="Quality"
        populate="user.employments"
        columns={columns}
        withCreateButton={false}
        deleteAction={false}
        editAction={false}
        path={`${routes.QUALITY_CONTROL}`}
        useUrlFilter={false}
      />
    </Card>
  );
};
