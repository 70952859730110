import React, { useState } from 'react';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';
import { Modal } from 'antd';
import { CreateUpdateContainer } from '../../components/CreateUpdateContainer/CreateUpdateContainer';
import useFields from './actionFields';

const ActionModal = ({
  isActionModalOpen,
  closeActionModal,
  purpose,
  idFromOverlay,
  customNavigateAction,
  isActionReminder,
  customer
}) => {
  const [isNewOperatorCode, setIsNewOperatorCode] = useState(false);
  const [selectedType, setSelectedType] = useState('INVOICE');
  const [actionSelected, setActionSelected] = useState('');
  const [rejectionType, setRejectionType] = useState(null);
  const [formValues, setFormValues] = useState(null);

  const { fields, isFieldsLoading } = useFields(
    purpose,
    setIsNewOperatorCode,
    isNewOperatorCode,
    selectedType,
    setSelectedType,
    actionSelected,
    setActionSelected,
    isActionReminder,
    rejectionType,
    setRejectionType,
    formValues
  );

  const config = {
    onGetResource: {
      setFields: (data) => {
        setSelectedType(data?.type);
        setRejectionType(data?.rejection_type);
        const today = dayjs().format('YYYY-MM-DD');
        return {
          ...data,
          action_reminder:
            data?.action_reminder && dayjs(data?.action_reminder),
          task_time: data?.task_time && dayjs(`${today}T${data?.task_time}`),
          use_case: data.use_case
        };
      }
    },
    onCreateResource: {
      setBody: (data) => ({
        ...data,
        customer_id: customer,
        new_operator_code: isNewOperatorCode,
        ...(data?.task_time
          ? { task_time: dayjs(data?.task_time).format('HH:mm') }
          : {})
      })
    },
    onUpdateResource: {
      setBody: (data) => ({
        ...data,
        new_operator_code: isNewOperatorCode,
        ...(data?.task_time
          ? { task_time: dayjs(data?.task_time).format('HH:mm') }
          : {}),
        ...(isActionReminder && data?.action_reminder_done
          ? { status: 'DONE' }
          : {})
      })
    }
  };

  return (
    <Modal
      centered
      open={isActionModalOpen}
      onCancel={closeActionModal}
      width={1000}
      footer={false}
    >
      <CreateUpdateContainer
        fields={fields}
        loadingFields={isFieldsLoading}
        purpose={purpose}
        baseUrl="actions"
        resource="actions"
        isOverlay
        customNavigate={customNavigateAction}
        idFromOverlay={idFromOverlay}
        config={config}
        setFormValues={setFormValues}
        needFormDependencies
      />
    </Modal>
  );
};

ActionModal.propTypes = {
  isActionReminder: PropTypes.bool,
  isActionModalOpen: PropTypes.bool.isRequired,
  closeActionModal: PropTypes.func.isRequired,
  purpose: PropTypes.string,
  idFromOverlay: PropTypes.string,
  customNavigateAction: PropTypes.func.isRequired,
  customer: PropTypes.string.isRequired
};

ActionModal.defaultProps = {
  purpose: null,
  idFromOverlay: null,
  isActionReminder: false
};

export default ActionModal;
