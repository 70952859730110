import { Col, Card, Row, Button } from 'antd';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { DescriptionList } from '../../../components';
import { useExtraInformationsContent } from './extraInformationsContent';
import { RejectionChart } from './RejectionChart';
import { OutstandingChart } from './OutstandingChart';

/**
 * Component for displaying extra intervention informations.
 *
 * @component
 * @param {object} props - Component props
 * @param {object} props.data - Intervention data containing principal details
 * @returns {JSX.Element} Component JSX
 */

export const ExtraInformations = ({
  data,
  openDrawer,
  openModal,
  rejections,
  outstandings,
  user,
  hasAccess
}) => {
  const listContent = useExtraInformationsContent(data?.customer);
  const { t } = useTranslation();

  return (
    <>
      <Col xs={24} xxl={user.role !== 'guests:GUEST' ? 10 : 12}>
        <Card>
          <DescriptionList data={listContent} translate columns={24} />
        </Card>
      </Col>
      <Col xs={24} sm={12} lg={7} xxl={user.role !== 'guests:GUEST' ? 4 : 6}>
        <Card>
          <p>{`${t('interventions.show.rejection_rate')}:`}</p>
          <RejectionChart data={rejections} />
        </Card>
      </Col>
      <Col xs={24} sm={12} lg={7} xxl={user.role !== 'guests:GUEST' ? 4 : 6}>
        <Card>
          <p>{`${t('interventions.show.outstanding')}:`}</p>
          <OutstandingChart data={outstandings} />
        </Card>
      </Col>
      {hasAccess('link') && (
        <Col xs={24} xl={6}>
          <Card>
            <Row align="middle" gutter={[16, 16]}>
              <Col xs={24} md={12}>
                <Button block onClick={() => openDrawer('administration')}>
                  {t('interventions.show.buttons.administration')}
                </Button>
              </Col>
              <Col xs={24} md={12}>
                <Button block onClick={() => openDrawer('security')}>
                  {t('interventions.show.buttons.security')}
                </Button>
              </Col>
              <Col xs={24} md={12}>
                <Button block onClick={() => openDrawer('technical')}>
                  {t('interventions.show.buttons.technical')}
                </Button>
              </Col>
              <Col xs={24} md={12}>
                <Button block onClick={() => openDrawer('comment')}>
                  {t('interventions.show.buttons.comment')}
                </Button>
              </Col>
              <Col xs={24}>
                <Button type="primary" block onClick={openModal}>
                  {t('interventions.show.buttons.websites')}
                </Button>
              </Col>
            </Row>
          </Card>
        </Col>
      )}
    </>
  );
};

ExtraInformations.propTypes = {
  data: PropTypes.shape({
    customer: PropTypes.shape({})
  }),
  rejections: PropTypes.arrayOf(PropTypes.shape({})),
  openDrawer: PropTypes.func.isRequired,
  openModal: PropTypes.func.isRequired,
  outstandings: PropTypes.arrayOf(PropTypes.shape({})),
  user: PropTypes.shape({ role: PropTypes.string }).isRequired,
  hasAccess: PropTypes.func.isRequired
};

ExtraInformations.defaultProps = {
  data: undefined,
  rejections: [],
  outstandings: []
};
