import { useTranslation } from 'react-i18next';
import { formatValue } from '../../../utils/formatNumber';
/**
 * useTechnicalContent is a custom hook that generates an array of content configurations for displaying technical informations customer data.
 *
 * @hook
 * @param {Object} data - Customer data object containing technical information.
 * @returns {Array<Object>} An array of content configurations for displaying technical informations customer data.
 */

export const useTechnicalContent = (data) => {
  const { t } = useTranslation();
  const {
    used_software,
    operator_code,
    connection_type,
    connection_comment,
    session_password,
    central_hub,
    scor,
    vital_sesam,
    pro_card_type,
    pro_card_code,
    pro_card_number,
    rejection_rate_entry,
    input_in_progress,
    monthly_reconciliation_statement,
    ps_security_code,
    connection_period,
    insured_contact_type,
    courrier,
    on_site_billing,
    min_amount_customer,
    max_amount_customer
  } = data;

  return [
    {
      label: 'customers.form.used_software',
      span: 5,
      content: used_software?.map((soft) => soft?.title).join(', ') || '-'
    },
    {
      label: 'customers.form.operator_code',
      span: 5,
      content: operator_code || '-'
    },
    {
      label: 'customers.form.connection_type',
      span: 5,
      content: connection_type
        ? t(`customers.connectionTypes.${connection_type}`)
        : '-'
    },
    {
      label: 'customers.form.connection_comment',
      span: 5,
      content: connection_comment || '-'
    },
    {
      label: 'customers.form.session_password',
      span: 5,
      content: session_password || '-'
    },
    {
      label: 'customers.form.central_hub',
      span: 5,
      content: central_hub?.title || '-'
    },
    {
      label: 'customers.form.scor',
      span: 5,
      content: t(`general.${scor ? 'YES' : 'NO'}`)
    },
    {
      label: 'customers.form.pro_card_type',
      span: 5,
      content: pro_card_type || '-'
    },
    {
      label: 'customers.form.pro_card_code',
      span: 5,
      content: pro_card_code || '-'
    },
    {
      label: 'customers.form.pro_card_number',
      span: 5,
      content: pro_card_number || '-'
    },
    {
      label: 'customers.form.vital_sesam',
      span: 5,
      content: t(`general.${vital_sesam ? 'YES' : 'NO'}`)
    },
    {
      label: 'customers.form.rejection_rate_entry',
      span: 5,
      content: t(`general.${rejection_rate_entry ? 'YES' : 'NO'}`)
    },
    {
      label: 'customers.form.input_in_progress',
      span: 5,
      content: t(`general.${input_in_progress ? 'YES' : 'NO'}`)
    },
    {
      label: 'customers.form.monthly_reconciliation_statement',
      span: 5,
      content: t(`general.${monthly_reconciliation_statement ? 'YES' : 'NO'}`)
    },
    {
      label: 'customers.form.ps_security_code',
      span: 5,
      content: ps_security_code || '-'
    },
    {
      label: 'customers.form.connection_period',
      span: 5,
      content: connection_period || '-'
    },
    {
      label: 'customers.form.insured_contact_type',
      span: 5,
      content: insured_contact_type?.length
        ? insured_contact_type?.map((ct) => ct).join(', ')
        : '-'
    },
    {
      label: 'customers.form.courrier',
      span: 5,
      content: courrier ? t(`customers.courrier.${courrier}`) : '-'
    },
    {
      label: 'customers.form.on_site_billing',
      span: 5,
      content: t(`general.${on_site_billing ? 'YES' : 'NO'}`)
    },
    {
      label: 'customers.form.min_amount_customer',
      span: 5,
      content: min_amount_customer
        ? `${formatValue(min_amount_customer, 'currency')}€`
        : '-'
    },
    {
      label: 'customers.form.max_amount_customer',
      span: 5,
      content: max_amount_customer
        ? `${formatValue(max_amount_customer, 'currency')}€`
        : '-'
    }
  ];
};
