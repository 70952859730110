import moment from 'moment';

type DataObject = {
  date: string;
  hour: string;
};

export const createEvents = (
  rawEvents: any[],
  weekCellRender: (
    event: object,
    allDay: boolean,
    setOpenModal: React.Dispatch<React.SetStateAction<boolean>>,
    setEventToUpdate: React.Dispatch<React.SetStateAction<string>>,
    setDate: React.Dispatch<React.SetStateAction<DataObject>>,
    directPatchIntervention: (eventId: string) => void,
    deleteEvent: (eventId: string) => void,
    printResource: (eventId: string) => void,
    resource: string,
    userConnected: {
      role: string;
    }
  ) => any,
  setOpenModal: React.Dispatch<React.SetStateAction<boolean>>,
  setEventToUpdate: React.Dispatch<React.SetStateAction<string>>,
  setDate: React.Dispatch<React.SetStateAction<DataObject>>,
  directPatchIntervention: (eventId: string) => void,
  deleteEvent: (eventId: string) => void,
  printResource: (eventId: string) => void,
  resource: string,
  userConnected: {
    role: string;
  }
) => {
  const events: any[] = [];
  rawEvents?.map((event) => {
    if (event.startTime) {
      const startTime = moment(event.startTime).toDate();
      const endTime = moment(event.endTime).toDate();
      events.push({
        startTime,
        endTime,
        type: event.type,
        status: event.status,
        title: weekCellRender(
          event,
          false,
          setOpenModal,
          setEventToUpdate,
          setDate,
          directPatchIntervention,
          deleteEvent,
          printResource,
          resource,
          userConnected
        ),
        key: event._id,
        backgroundColor: event.backgroundColor,
        resource,
        scheduled_time: event.scheduled_time
      });
    } else {
      const startTime = moment(event.date).startOf('day').toDate();
      const endTime = moment(event.date).endOf('day').toDate();
      events.push({
        allDay: true,
        startTime,
        endTime,
        title: weekCellRender(
          event,
          true,
          setOpenModal,
          setEventToUpdate,
          setDate,
          directPatchIntervention,
          deleteEvent,
          printResource,
          resource,
          userConnected
        ),
        key: event._id
      });
    }
  });

  return events;
};
