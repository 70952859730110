import { Col, Row, Tag, Popover } from 'antd';
import { Link } from 'react-router-dom';
import moment from 'moment';
import styled from 'styled-components';
import { routes } from '../../utils/constants/adminRoutes';
import { weeklyCalendarContent } from '../../routes/calendar/weeklyCalendarContent';
import { weeklyPlanningContent } from '../../routes/occupancy_chart/planning/weeklyPlanningContent';
import { servicesColor } from '../../utils/constants/tagColors';
import { useTranslation } from 'react-i18next';

const Cell = styled.div`
  list-style: none;
  user-select: none;
  width: 100%;
  height: 100%;
`;

interface IEventItem {
  _id: string;
  date: Date;
  startTime?: string;
  endTime?: string;
  type?: string;
  comment?: string;
  status?: string;
  reason?: string;
  company_name?: string;
  ps_number?: string;
  code?: string;
  scheduled_start_date?: string;
  scheduled_end_date?: string;
  start_date?: string;
  end_date?: string;
  service?: {};
  customer?: {};
  leader?: {};
  user?: {};
  file?: FileItem[];
  scheduled_time?: number;
}

type FileItem = {
  _id: string;
  metadata: {
    originalName: string;
  };
};

type DataObject = {
  date: string;
  hour: string;
};

export const weekCellRender = (
  eventItem: IEventItem,
  allDay: boolean,
  setOpenModal: React.Dispatch<React.SetStateAction<boolean>>,
  setEventToUpdate: React.Dispatch<React.SetStateAction<string>>,
  setDate: React.Dispatch<React.SetStateAction<DataObject>>,
  directPatchIntervention: (
    id: string,
    day: Date,
    hour: string,
    scheduled_time: number
  ) => void,
  deleteEvent: React.Dispatch<React.SetStateAction<string>>,
  printResource: (fileId: string, fileName: string) => void,
  resource: string,
  userConnected: {
    role: string;
  }
) => {
  const { _id } = eventItem;
  const handleButtonClick = () => {
    setEventToUpdate(_id);
    setOpenModal(true);
  };

  const completeEventItem = {
    ...eventItem,
    startTime: eventItem.startTime || null,
    endTime: eventItem.endTime || null,
    type: eventItem.type || null,
    comment: eventItem.comment || null,
    status: eventItem.status || null,
    reason: eventItem.reason || null,
    scheduled_start_date: eventItem.scheduled_start_date || null,
    scheduled_end_date: eventItem.scheduled_end_date || null,
    start_date: eventItem.scheduled_end_date || null,
    end_date: eventItem.scheduled_end_date || null,
    service: eventItem.service || null,
    leader: eventItem.leader || null,
    user: eventItem.user || null,
    company_name: eventItem.company_name || null,
    ps_number: eventItem.ps_number || null,
    code: eventItem.code || null,
    customer: eventItem.customer || null,
    file: eventItem.file || null,
    scheduled_time: eventItem.scheduled_time || null
  };

  const content =
    resource === 'plannings'
      ? weeklyPlanningContent(completeEventItem, handleButtonClick, deleteEvent)
      : weeklyCalendarContent(
          completeEventItem,
          handleButtonClick,
          deleteEvent,
          printResource,
          userConnected
        );

  return (
    <Row justify="end">
      {(content || []).map((c) => (
        <Popover
          overlayStyle={{ width: 300 }}
          content={
            eventItem?.type === 'INTERVENTION' ||
            eventItem?.type === 'REAL_INTERVENTION' ? (
              <Row
                style={{
                  width: '100%'
                }}
              >
                <Col span="24">{`N° intervention: ${eventItem?.code}`}</Col>
                <Col span="24">{`Nom de l'établissement: ${
                  eventItem?.company_name || 'N/A'
                }`}</Col>
                <Col span="24">{`Heure prévue début: ${moment(
                  eventItem?.scheduled_start_date
                )
                  .format('HH:mm')
                  ?.replace(':', 'h')}`}</Col>
                <Col span="24">{`Heure prévue fin: ${moment(
                  eventItem?.scheduled_end_date
                )
                  .format('HH:mm')
                  ?.replace(':', 'h')}`}</Col>
                <Col span="24">{`Début réel: ${
                  eventItem?.start_date
                    ? moment(eventItem?.start_date)
                        .format('HH:mm')
                        ?.replace(':', 'h')
                    : 'N/A'
                }`}</Col>
                <Col span="24">{`Fin réel: ${
                  eventItem?.end_date
                    ? moment(eventItem?.end_date)
                        .format('HH:mm')
                        ?.replace(':', 'h')
                    : 'N/A'
                }`}</Col>
                <Col span="24">{`Leader: ${
                  eventItem?.leader?.first_name || '-'
                } ${
                  eventItem?.leader?.usage_last_name ||
                  eventItem?.leader?.last_name
                }`}</Col>
                <Col span="24">{`Gtp: ${eventItem?.user?.first_name || '-'} ${
                  eventItem?.user?.usage_last_name || eventItem?.user?.last_name
                }`}</Col>
              </Row>
            ) : (
              <Row>
                <Col span="24">{`Motif: ${eventItem?.reason}`}</Col>
                <Col span="24">{`Commentaire: ${eventItem?.comment}`}</Col>
              </Row>
            )
          }
          placement="right"
        >
          <Col key={c._id} span={c.span}>
            {c.content}
          </Col>
        </Popover>
      ))}
    </Row>
  );
};

export const monthCellRender = (value: moment.Moment, events: Array<any>) => {
  const date = value;
  const { t } = useTranslation();

  const eventsToDisplay = (events || []).filter(
    (event) =>
      date.day() !== 0 &&
      date.format('YYYY-MM-DD') >=
        moment(event.startTime).format('YYYY-MM-DD') &&
      date.format('YYYY-MM-DD') <= moment(event.startTime).format('YYYY-MM-DD')
  );

  return eventsToDisplay.map((event) => (
    <li key={event._id}>
      <Popover
        overlayStyle={{ width: 300 }}
        content={
          event?.type === 'INTERVENTION' ||
          event?.type === 'REAL_INTERVENTION' ? (
            <Row
              style={{
                width: '100%'
              }}
            >
              <Col span="24">{`N° intervention: ${event?.code}`}</Col>
              <Col span="24">{`Nom de l'établissement: ${
                event?.company_name || 'N/A'
              }`}</Col>
              <Col span="24">{`Heure prévue début: ${moment(
                event?.scheduled_start_date
              )
                .format('HH:mm')
                ?.replace(':', 'h')}`}</Col>
              <Col span="24">{`Heure prévue fin: ${moment(
                event?.scheduled_end_date
              )
                .format('HH:mm')
                ?.replace(':', 'h')}`}</Col>
              <Col span="24">{`Début réel: ${
                event?.start_date
                  ? moment(event?.start_date).format('HH:mm')?.replace(':', 'h')
                  : 'N/A'
              }`}</Col>
              <Col span="24">{`Fin réel: ${
                event?.end_date
                  ? moment(event?.end_date).format('HH:mm')?.replace(':', 'h')
                  : 'N/A'
              }`}</Col>
              <Col span="24">{`Leader: ${event?.leader?.first_name || '-'} ${
                event?.leader?.usage_last_name || event?.leader?.last_name
              }`}</Col>
              <Col span="24">{`Gtp: ${event?.user?.first_name || '-'} ${
                event?.user?.usage_last_name || event?.user?.last_name
              }`}</Col>
            </Row>
          ) : (
            <Row>
              <Col span="24">{`Motif: ${event?.reason}`}</Col>
              <Col span="24">{`Commentaire: ${event?.comment}`}</Col>
            </Row>
          )
        }
      >
        <Row
          style={{
            width: '100%'
          }}
        >
          <Tag
            color={
              event?.type === 'INTERVENTION' ||
              event?.type === 'REAL_INTERVENTION'
                ? servicesColor[event?.service?.type?.split('_')[0]]
                : event.backgroundColor
            }
            style={{
              display: 'flex',
              width: '100%',
              justifyContent: 'space-between'
            }}
          >
            <span style={{ fontSize: 10 }}>
              {event?.type === 'INTERVENTION' ||
              event?.type === 'REAL_INTERVENTION'
                ? event?.ps_number
                : `${t(`events.form.enums.${event?.type}`)}`}
            </span>
            <span style={{ fontSize: 10 }}>
              {`${moment(event?.startTime)
                .format('HH:mm')
                ?.replace(':', 'h')} - ${moment(event?.endTime)
                .format('HH:mm')
                ?.replace(':', 'h')}`}
            </span>
          </Tag>
        </Row>
      </Popover>
    </li>
  ));
};

export const annualCellRender = (value: moment.Moment, events: Array<any>) => {
  const month = moment(value).month();
  return (
    <Cell>
      {(events || [])
        .filter(
          (event) =>
            month >= moment(event.date).month() &&
            month <= moment(event.date).month()
        )
        .sort((a, b) => moment(b.date).day() - moment(a.date).day())
        .map((event) => (
          <li key={event._id}>
            <Link
              to={{
                pathname: `${routes.CALENDAR}/show/${event._id}`
              }}
            >
              {moment(event.date).format('DD-MM-YYYY')}
            </Link>
          </li>
        ))}
    </Cell>
  );
};
