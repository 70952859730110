import { Typography, Tag } from 'antd';
import { userRoles } from '../../utils/constants/tagColors';

/**
 * Generates a list of content items for displaying details about an actuality.
 *
 * @hook
 * @param {Function} t - The translation function used for internationalization.
 * @param {Object} news - An object containing properties related to the actuality.
 * @returns {Object[]} An array of content items, each with a label, span, and content.
 */

export const getListContent = (t, news) => {
  if (!news) return [];
  const { recipients, macro_ps, micro_ps, category } = news;

  const listContent = [
    {
      label: t('news.form.recipients'),
      span: 24,
      content: (
        <Typography.Text>
          {recipients?.map((rec) => (
            <Tag color={userRoles[rec.split(':')[1]]} key={rec}>
              {t(`users.tags.${rec.split(':')[1]}`)}
            </Tag>
          ))}
        </Typography.Text>
      )
    },
    {
      label: t('news.form.macro_ps'),
      span: 8,
      content: (
        <Typography.Text>
          {macro_ps?.map((ps) => ps.title)?.join(',')}
        </Typography.Text>
      )
    },
    {
      label: t('news.form.micro_ps'),
      span: 8,
      content: (
        <Typography.Text>
          {micro_ps?.map((ps) => ps.title)?.join(',')}
        </Typography.Text>
      )
    },
    {
      label: t('news.form.category'),
      span: 8,
      content: (
        <Typography.Text>
          {category ? t(`news.categories.${category}`) : '-'}
        </Typography.Text>
      )
    }
  ];
  return listContent;
};
