export const headers = [
  {
    label: 'type',
    key: 'type'
  },
  {
    label: 'status',
    key: 'status'
  },
  {
    label: 'frequency',
    key: 'frequency'
  },
  {
    label: 'periodicity',
    key: 'periodicity'
  },
  {
    label: 'accounting_account',
    key: 'accounting_account'
  },
  {
    label: 'amount',
    key: 'amount'
  },
  {
    label: 'total_hours',
    key: 'total_hours'
  },
  {
    label: 'monthly_price_vat_included',
    key: 'monthly_price_vat_included'
  },
  {
    label: 'fee_discount',
    key: 'fee_discount'
  },
  {
    label: 'ec_th',
    key: 'ec_th'
  },
  {
    label: 'em',
    key: 'em'
  },
  {
    label: 'em_catp',
    key: 'em_catp'
  },
  {
    label: 'oem_ps',
    key: 'contract.customer.outstanding_balance_target'
  },
  {
    label: 'leader',
    key: 'leader.first_name'
  },
  {
    label: 'leader',
    key: 'leader.last_name'
  },
  {
    label: 'user_referent',
    key: 'user_referent.first_name'
  },
  {
    label: 'user_referent',
    key: 'user_referent.last_name'
  },
  {
    label: 'start_date',
    key: 'start_date'
  },
  {
    label: 'vat',
    key: 'vat'
  },
  {
    label: 'start_date',
    key: 'start_date'
  }
];
