import { useEffect, useState } from 'react';
import { Drawer, Spin } from 'antd';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { useAuthContext } from '../../../../contexts/AuthContext';
import { useErrorMessage } from '../../../../utils/errorMessage';
import { DescriptionList } from '../../../../components';
import listContent from './listContent';

export const ShowEmploymentDrawer = ({ id, opened, setOpen, contractType }) => {
  const { t } = useTranslation();
  const { dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();
  const [employments, setEmployments] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const getEmployment = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `employments/${id}?populate=leader`
      });
      setEmployments(data);
    } catch (error) {
      message(error);
    }
  };

  useEffect(async () => {
    if (!id) return;
    await getEmployment();
    setIsLoading(false);
  }, [id]);

  return (
    <Drawer
      title={`${t(`employments.titles.show`)} ${t(
        `employments.titles.${contractType}`
      )}`}
      width={600}
      open={opened}
      onClose={() => setOpen(false)}
    >
      <Spin spinning={isLoading} />
      <DescriptionList data={listContent(employments, t)} translate />
    </Drawer>
  );
};

ShowEmploymentDrawer.propTypes = {
  id: PropTypes.string,
  opened: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  contractType: PropTypes.string.isRequired
};

ShowEmploymentDrawer.defaultProps = {
  id: null
};
