import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { Flex, Select, Card } from 'antd';
import { ContentCustom, PageHeaderCustom } from '../../../components';
import { CustomSpin } from '../../../components/CustomSpin/CustomSpin';
import OccupancyChartContextProvider from '../OccupancyChartContext';
import { CalendarHeader } from '../../../components/Calendar/WeeklyCalendar/CalendarHeader.tsx';
import { Calendar } from '../../../components/Calendar/Calendar';
import CarouselWithCards from '../../../components/Carousel/CarouselWithCards';
import InterventionModal from '../occupancy/InterventionModal';
import { useAuthContext } from '../../../contexts/AuthContext';
import { useErrorMessage } from '../../../utils/errorMessage';
import {
  annualCellRender,
  monthCellRender,
  weekCellRender
} from '../../../components/Calendar/CellRenders.tsx';
import { headers } from './headers';
import { useFormatter } from '../../../utils/formatter';

const { Option } = Select;

/**
 * Component to manage planning for users.
 *
 * @component
 * @returns {JSX.Element} Rendered ManagePlanning component
 */

export const ManagePlanning = () => {
  const { t } = useTranslation();
  const { dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();
  const {
    leaders,
    softwares,
    selectedLeaders,
    setSelectedLeaders,
    selectedGtps,
    setSelectedGtps,
    selectedSoftwares,
    setSelectedSoftwares,
    startWeek,
    setStartWeek,
    isModalOpen,
    setForceCalendarRefresh,
    forceCalendarRefresh,
    forceRefresh,
    openModal,
    patchIterventionStatus,
    setIsModalOpen,
    setPurpose,
    setIdFromOverlay,
    setResource,
    setDate,
    deleteEvent,
    directPatchIntervention
  } = OccupancyChartContextProvider();
  const { formattedData } = useFormatter('events');
  const [isLoading, setIsLoading] = useState(true);
  const [gtpsWithoutInterventions, setGtpsWithoutInterventions] = useState([]);

  const manageModalFromPlanning = () => {
    setPurpose('edit');
    setIsModalOpen(true);
    setResource('plannings');
  };

  const onsearch = (input, option) =>
    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;

  useEffect(() => {
    setIsLoading(false);
  }, [leaders]);

  const openOverlay = () => {
    openModal(null, null, 'create', null, 'interventions');
  };

  const getGtpsWithoutInterventions = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `employments/interventions?populate=user&withoutInterventions=${true}&leader=${selectedLeaders}${
          selectedGtps ? `&_id=${selectedGtps}` : ''
        }${
          selectedSoftwares.length ? `&used_softwares=${selectedSoftwares}` : ''
        }&period=${[
          dayjs(startWeek)
            .add(1, 'day')
            .startOf('day')
            .format('YYYY-MM-DDTHH:mm:ss.SSS[Z]'),
          dayjs(startWeek)
            .add(7, 'day')
            .endOf('day')
            .format('YYYY-MM-DDTHH:mm:ss.SSS[Z]')
        ]}`
      });
      setGtpsWithoutInterventions(data);
    } catch (error) {
      message(error);
    }
  };

  useEffect(() => {
    (() => {
      const shouldFetch =
        selectedLeaders.length ||
        selectedGtps ||
        selectedSoftwares.length ||
        (!selectedLeaders.length && !selectedGtps && !selectedSoftwares.length);
      if (shouldFetch) {
        setForceCalendarRefresh((prev) => prev + 1);
      }
    })();
  }, [selectedLeaders, selectedSoftwares, forceRefresh, selectedGtps]);

  useEffect(() => {
    (async () => {
      const shouldFetch =
        selectedLeaders.length ||
        selectedGtps ||
        selectedSoftwares.length ||
        (!selectedLeaders.length && !selectedGtps && !selectedSoftwares.length);
      if (shouldFetch) {
        await getGtpsWithoutInterventions();
        setForceCalendarRefresh((prev) => prev + 1);
      }
    })();
  }, [selectedLeaders, selectedSoftwares, forceRefresh]);

  useEffect(() => {
    setSelectedLeaders([]);
    setSelectedGtps(null);
    setSelectedSoftwares([]);
  }, []);

  return isLoading ? (
    <ContentCustom>
      <CustomSpin />
    </ContentCustom>
  ) : (
    <ContentCustom>
      {isModalOpen && <InterventionModal />}
      <PageHeaderCustom title={`${t('planning.title')}`} />
      <Card className="calendar-wrapper">
        <CalendarHeader
          startWeek={startWeek}
          setStartWeek={setStartWeek}
          ISOWeekName
          mode="week"
          switchModes={false}
          setForceRefresh={setForceCalendarRefresh}
          forceRefresh={forceCalendarRefresh}
          extraHeader={
            <Flex gap={8} wrap>
              <Select
                style={{ width: 250 }}
                allowClear
                showSearch
                mode="multiple"
                placeholder={t('occupancies.list.placeholder_leader')}
                filterOption={(input, option) => onsearch(input, option)}
                onSelect={(value) => {
                  setSelectedLeaders([...selectedLeaders, value]);
                }}
                onDeselect={(value) =>
                  setSelectedLeaders((prevStatus) =>
                    prevStatus.filter((item) => item !== value)
                  )
                }
                onClear={() => {
                  setSelectedLeaders([]);
                }}
              >
                {(leaders || []).map((leader) => (
                  <Option key={leader._id} value={leader._id}>
                    {`${leader.first_name} ${
                      leader?.usage_last_name || leader?.last_name
                    }`}
                  </Option>
                ))}
              </Select>
              <Select
                style={{ width: 250 }}
                allowClear
                showSearch
                placeholder={t('occupancies.list.placeholder_gtp')}
                filterOption={(input, option) => onsearch(input, option)}
                onSelect={(value) => {
                  setSelectedGtps(value);
                }}
                onClear={() => {
                  setSelectedGtps(null);
                }}
              >
                {(gtpsWithoutInterventions || [])
                  .sort((a, b) =>
                    a?.user?.first_name
                      ?.toLowerCase()
                      ?.localeCompare(b?.user?.first_name?.toLowerCase())
                  )
                  .map((gtp) => (
                    <Option key={gtp._id} value={gtp?.user?._id}>
                      {`${gtp?.user?.first_name} ${
                        gtp?.user?.usage_last_name || gtp?.user?.last_name
                      }`}
                    </Option>
                  ))}
              </Select>
              <Select
                style={{ width: 250 }}
                allowClear
                showSearch
                mode="multiple"
                placeholder={t('occupancies.list.placeholder_software')}
                filterOption={(input, option) => onsearch(input, option)}
                onSelect={(value) => {
                  setSelectedSoftwares([...selectedSoftwares, value]);
                }}
                onDeselect={(value) =>
                  setSelectedSoftwares((prevStatus) =>
                    prevStatus.filter((item) => item !== value)
                  )
                }
                onClear={() => {
                  setSelectedSoftwares([]);
                }}
              >
                {(softwares || []).map((sofware) => (
                  <Option key={sofware._id} value={sofware._id}>
                    {`${sofware.title}`}
                  </Option>
                ))}
              </Select>
            </Flex>
          }
        />
        <DndProvider backend={HTML5Backend}>
          <CarouselWithCards
            resourceName="interventions/calcul"
            modelResourceName="planning"
            extraQuery={`${
              selectedLeaders.length > 0 ? `&leader=${selectedLeaders}` : ''
            }${selectedGtps ? `&user=${selectedGtps}` : ''}${
              selectedSoftwares.length > 0
                ? `&used_softwares=${selectedSoftwares}`
                : ''
            }${
              startWeek
                ? `&period_start>=${dayjs(startWeek)
                    .add(1, 'day')
                    .startOf('day')
                    .format('YYYY-MM-DDTHH:mm:ss.SSS[Z]')}&period_end<=${dayjs(
                    startWeek
                  )
                    .add(7, 'day')
                    .endOf('day')
                    .format('YYYY-MM-DDTHH:mm:ss.SSS[Z]')}`
                : ''
            }&status=PROVISIONAL`}
            populate="contract,user_referent,leader,user,customer,service"
            chunkSize={8}
            forceRefresh={forceCalendarRefresh}
            setForceRefresh={setForceCalendarRefresh}
            patchIterventionStatus={patchIterventionStatus}
          />
          <Calendar
            modes={['week', 'month']}
            resourceName="events"
            resource="plannings"
            monthCellRender={monthCellRender}
            weekCellRender={weekCellRender}
            annualCellRender={annualCellRender}
            initialMode="week"
            hourStart={9}
            hourEnd={21}
            extraQuery={`user=${selectedGtps || null}`}
            populate="user,service,leader,used_softwares"
            withExtraHeader
            withCreateButton
            withUploadButton
            formatter={formattedData}
            headers={headers}
            withImportButton={false}
            openOverlay={openOverlay}
            startWeekDependance={startWeek}
            setOpenModal={manageModalFromPlanning}
            setEventToUpdate={setIdFromOverlay}
            setDate={setDate}
            directPatchIntervention={directPatchIntervention}
            deleteEvent={deleteEvent}
            forceRefresh={forceCalendarRefresh}
            setForceRefresh={setForceCalendarRefresh}
          />
        </DndProvider>
      </Card>
    </ContentCustom>
  );
};
