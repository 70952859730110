import { useCallback, useEffect, useState } from 'react';
import { Col, Row, Tag } from 'antd';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ContentCustom, PageHeaderCustom } from '../../../components';
import { CustomSpin } from '../../../components/CustomSpin/CustomSpin';
import { useAuthContext } from '../../../contexts/AuthContext';
import { useErrorMessage } from '../../../utils/errorMessage';
import { qualityControlStatus } from '../../../utils/constants/tagColors';
import { ExtraButtons } from './ExtraButtons';
import { InterventionsCollapse } from './interventions/InteventionsCollapse';
import { ProductivitiesCard } from './productivities/ProductivitiesCard';
import { SoftSkillsCard } from './softSkills/SoftSkillsCard';
import { TestCard } from './test/TestCard';
import { ConversionRateCard } from './conversionRate/ConversionRateCard';
import { MainCard } from './mainData/MainCard';
import { GradesCard } from './grades/GradesCard';
import useQualityControlContext from '../QualityControlContext';
import { showSuccessMessage } from '../../../utils/showSuccessMessage';

/**
 * ShowQualityControl component that shows the quality control information
 *
 * @component
 * @return {component} - ShowQualityControl component
 */
export const ShowQualityControl = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const { message } = useErrorMessage();
  const { dispatchAPI } = useAuthContext();
  const [isLoading, setIsLoading] = useState(false);
  const [qualityControl, setQualityControl] = useState();
  const { refresh, setRefresh } = useQualityControlContext();

  const getQualityControl = useCallback(async () => {
    setIsLoading(true);
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/quality/${id}`
      });
      setQualityControl(data);
    } catch (e) {
      message(e);
    }
  }, [id, refresh]);

  const closeQualityControl = async (body) => {
    const activeEmployment = qualityControl?.user?.employments.find(
      (employment) => employment.status === 'ACTIVE'
    );
    setIsLoading(true);
    try {
      await dispatchAPI('POST', {
        url: `/quality/close/${id}`,
        body: { ...body.closure, user_initial_level: activeEmployment?.level }
      });
      showSuccessMessage(t, 'quality', 'create');
    } catch (e) {
      message(e);
    }
    setIsLoading(false);
    setRefresh(!refresh);
  };

  const items = [
    {
      content: <MainCard quality={qualityControl} />,
      span: 12,
      key: 'main'
    },
    {
      content: <GradesCard grades={qualityControl?.grades} />,
      span: 12,
      key: 'grades'
    },
    {
      content: (
        <InterventionsCollapse interventions={qualityControl?.interventions} />
      ),
      span: 24,
      key: 'interventions'
    },
    {
      content: (
        <ProductivitiesCard productivities={qualityControl?.productivities} />
      ),
      span: 12,
      key: 'productivities'
    },
    {
      content: <SoftSkillsCard softSkills={qualityControl?.soft_skills} />,
      span: 12,
      key: 'softSkills'
    },
    {
      content: (
        <ConversionRateCard conversionRate={qualityControl?.conversion_rate} />
      ),
      span: 12,
      key: 'conversionRate'
    },
    {
      content: <TestCard test={qualityControl?.test} />,
      span: 12,
      key: 'test'
    }
  ];

  useEffect(() => {
    (async () => {
      await getQualityControl();
      setIsLoading(false);
    })();
  }, [getQualityControl]);

  return isLoading || !qualityControl ? (
    <ContentCustom>
      <CustomSpin />
    </ContentCustom>
  ) : (
    <ContentCustom>
      <PageHeaderCustom
        title={
          <>
            {`${qualityControl?.user?.first_name} ${(
              qualityControl?.user?.usage_last_name ||
              qualityControl?.user?.last_name ||
              ''
            ).toUpperCase()}
            `}
            <Tag
              style={{ marginLeft: 12 }}
              color={qualityControlStatus[qualityControl?.status]}
            >
              {t(`quality.columns.statuses.${qualityControl?.status}`)}
            </Tag>
          </>
        }
        extra={
          <ExtraButtons
            id={id}
            closeQualityControl={closeQualityControl}
            status={qualityControl?.status}
            qualityControl={qualityControl}
          />
        }
      />
      <Row gutter={[16, 16]}>
        {items.map((item) => (
          <Col xs={24} xl={item.span || 12} key={item.key}>
            {item.content}
          </Col>
        ))}
      </Row>
    </ContentCustom>
  );
};
