import PropTypes from 'prop-types';
import { Card, Col, Progress, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import { LevelsPopover } from './LevelsPopover';
import { GradeBar } from '../GradeBar';

/**
 * GradesCard component that shows the grades information of the quality control
 *
 * @component
 * @param {object} grades - Grades object
 * @return {component} - GradesCard component
 */
export const GradesCard = ({ grades }) => {
  const { t } = useTranslation();
  return (
    <Card title={t('quality.global_grade')} extra={<LevelsPopover />}>
      <Row>
        <Col span={10}>
          <Row align="middle" justify="center" style={{ height: '100%' }}>
            <Progress type="circle" percent={grades?.global} />
          </Row>
        </Col>
        <Col span={14}>
          {[
            'interventions',
            'productivities',
            'soft_skills',
            'conversion_rate',
            'test'
          ].map((key) => (
            <GradeBar
              title={t(`quality.form.${key}.title`)}
              grade={grades?.[key]}
              key={key}
            />
          ))}
        </Col>
      </Row>
    </Card>
  );
};

GradesCard.propTypes = {
  grades: PropTypes.shape({
    global: PropTypes.number,
    interventions: PropTypes.number,
    productivity: PropTypes.number,
    soft_skills: PropTypes.number,
    conversion_rate: PropTypes.number,
    test: PropTypes.number
  })
};

GradesCard.defaultProps = {
  grades: {}
};
