import { useTranslation } from 'react-i18next';
import { Tag } from 'antd';
import {
  yesNoSwitch,
  eventStatus
} from '../../../../utils/constants/tagColors';
import { formatValue } from '../../../../utils/formatNumber';

export const useColumns = () => {
  const { t } = useTranslation();

  return [
    {
      title: t('absences.columns.startTime'),
      key: 'startTime',
      dataIndex: ['startTime'],
      render: (startTime) => formatValue(startTime, 'date'),
      sorter: true
    },
    {
      title: t('absences.columns.start_hour'),
      key: 'startTime',
      dataIndex: ['startTime'],
      render: (startTime) => formatValue(startTime, 'time'),
      sorter: true
    },
    {
      title: t('absences.columns.endTime'),
      key: 'endTime',
      dataIndex: ['endTime'],
      render: (endTime) => formatValue(endTime, 'date'),
      sorter: true
    },
    {
      title: t('absences.columns.end_hour'),
      key: 'endTime',
      dataIndex: ['endTime'],
      render: (endTime) => formatValue(endTime, 'time'),
      sorter: true
    },
    {
      title: t('absences.columns.paidLeaveInAdvance'),
      key: 'paidLeaveInAdvance',
      dataIndex: ['paidLeaveInAdvance'],
      render: (paidLeaveInAdvance) => (
        <Tag color={yesNoSwitch[paidLeaveInAdvance]}>
          {paidLeaveInAdvance ? t(`switch.${paidLeaveInAdvance}`) : 'N/A'}
        </Tag>
      ),
      sorter: true
    },
    {
      title: t('absences.columns.reason'),
      key: 'reason',
      dataIndex: ['reason'],
      sorter: true
    },
    {
      title: t('absences.columns.status'),
      key: 'status',
      dataIndex: ['status'],
      render: (status) => (
        <Tag color={eventStatus[status]}>{t(`vacations.enums.${status}`)}</Tag>
      ),
      sorter: true
    }
  ];
};
