import { Tag } from 'antd';
import { formatValue } from '../../../utils/formatNumber';

/**
 * Custom hook for generating service content data for displaying service details in a list format.
 *
 * @hook
 * @param {object} data - User data object
 * @returns {Array} Array of objects containing label, span, and content for each field
 */

export const useListContent = (data, t, modelResourceName) => {
  const {
    contract,
    leader,
    user_referent,
    start_date,
    end_date,
    type,
    service,
    user,
    scheduled_time,
    diff_in_hours,
    diff_since_start_service
  } = data;

  return [
    {
      label: 'occupancies.carousel.popover.content.company_name',
      content: contract?.company_name,
      span: 1,
      key: 0
    },
    {
      label: 'occupancies.carousel.popover.content.type',
      content: t(
        `quotes.tags.${
          modelResourceName === 'occupancies' ? type : service?.type
        }`
      ),
      span: 1,
      key: 1
    },
    {
      label: 'occupancies.carousel.popover.content.leader',
      content: `${leader?.first_name || '-'} ${
        leader?.usage_last_name || leader?.last_name
      }`,
      span: 1,
      key: 2
    },
    ...(modelResourceName === 'occupancies'
      ? [
          {
            label: 'occupancies.carousel.popover.content.user_referent',
            content: `${user_referent?.first_name || '-'} ${
              user_referent?.usage_last_name || user_referent?.last_name || '-'
            }`,
            span: 1,
            key: 3
          }
        ]
      : [
          {
            label: 'occupancies.carousel.popover.content.user_referent',
            content: `${user?.first_name || '-'} ${
              user?.usage_last_name || user?.last_name || '-'
            }`,
            span: 1,
            key: 4
          }
        ]),
    {
      label: 'occupancies.carousel.popover.content.used_software',
      // eslint-disable-next-line no-nested-ternary
      content: Array.isArray(user_referent?.used_software) ? (
        user_referent.used_software.map((software) => (
          <span key={software.id}>{`${software.title || 'N/A'}, `}</span>
        ))
      ) : user_referent?.used_software ? (
        <span>{user_referent.used_software.title}</span>
      ) : null,
      span: 1,
      key: 5
    },
    {
      label: 'occupancies.carousel.popover.content.company',
      content: t(`employments.tags.${contract?.company}`),
      span: 1,
      key: 6
    },
    ...(modelResourceName === 'occupancies'
      ? [
          {
            label: 'occupancies.carousel.popover.content.period',
            content: `Du ${formatValue(start_date, 'date')} ${
              end_date ? `au ${formatValue(end_date, 'date')}` : ''
            }`,
            span: 1,
            key: 7
          },
          {
            label: 'occupancies.carousel.popover.content.diff_in_hours',
            content: (
              <Tag
                color={diff_in_hours > 0 ? 'var(--errorColor)' : 'green'}
              >{`${
                diff_in_hours > 0 ? diff_in_hours : Math.abs(diff_in_hours)
              }`}</Tag>
            ),
            span: 1,
            key: 10
          },
          {
            label:
              'occupancies.carousel.popover.content.diff_since_start_service',
            content: (
              <Tag
                color={
                  diff_since_start_service > 0 ? 'var(--errorColor)' : 'green'
                }
              >{`${
                diff_since_start_service > 0
                  ? diff_since_start_service
                  : Math.abs(diff_since_start_service)
              }`}</Tag>
            ),
            span: 1,
            key: 10
          }
        ]
      : [
          {
            label: 'occupancies.carousel.popover.content.total_hours_per_week',
            content: `${
              service?.total_hours_per_week &&
              service?.total_hours_per_week.toFixed(0)
            }h${
              service?.frequency
                ? t(`${modelResourceName}.carousel.tags.WEEK`)
                : ''
            }`,
            span: 1,
            key: 8
          },
          {
            label: 'occupancies.carousel.popover.content.scheduled_time',
            content: `${scheduled_time}h`,
            span: 1,
            key: 9
          }
        ])
  ];
};
