import { Tag } from 'antd';
import { useTranslation } from 'react-i18next';
import { interventionsStatus } from '../../utils/constants/tagColors';
import { formatValue } from '../../utils/formatNumber';

/**
 * Custom hook for generating table columns for displaying intervention data.
 *
 * @hook
 * @param {object} interventionEnums - Enumerations data for intervention properties
 * @param {Array} usersGtp - List of users
 * @returns {Array} Array of table column objects
 */

export const useColumns = (interventionEnums) => {
  const { t } = useTranslation();

  return [
    {
      title: t('interventions.columns.status'),
      key: 'status',
      dataIndex: ['status'],
      render: (status) => (
        <Tag color={interventionsStatus[status]}>
          {t(`interventions.form.enums.${status || 'N/A'}`)}
        </Tag>
      ),
      sorter: true,
      filters: interventionEnums?.status?.map((c) => ({
        text: t(`interventions.form.enums.${c}`),
        value: c
      }))
    },
    {
      title: t('interventions.columns.ps_number'),
      key: 'contract',
      dataIndex: 'contract',
      render: ({ ps_number }) => (ps_number ? <Tag>{ps_number}</Tag> : '-')
    },
    {
      title: t('interventions.columns.company_name'),
      key: 'contract',
      dataIndex: 'contract',
      render: ({ company_name }) => (company_name && company_name) || '-'
    },
    {
      title: t('interventions.columns.start_date_date'),
      key: 'start_date',
      dataIndex: 'start_date',
      render: (start_date, data) =>
        (start_date && formatValue(start_date, 'date')) ||
        (data?.scheduled_start_date &&
          formatValue(data?.scheduled_start_date, 'date')),
      sorter: true
    },
    {
      title: t('interventions.columns.scheduled_start_date'),
      key: 'scheduled_start_date',
      dataIndex: 'scheduled_start_date',
      render: (scheduled_start_date) =>
        scheduled_start_date && formatValue(scheduled_start_date, 'time')
    },
    {
      title: t('interventions.columns.start_date'),
      key: 'start_date',
      dataIndex: 'start_date',
      render: (start_date) => start_date && formatValue(start_date, 'time')
    },
    {
      title: t('interventions.columns.end_date'),
      key: 'end_date',
      dataIndex: 'end_date',
      render: (end_date) => end_date && formatValue(end_date, 'time')
    }
  ];
};
