/**
 * An array containing chart definitions for customer statistics.
 * Each entry represents a type of chart with associated properties.
 *
 * @type {Array<Object>}
 * @property {string} keyData - Key indicating the type of data for the chart.
 * @property {string} [average] - Key indicating the type of average used (if applicable).
 * @property {string} color - Color associated with the chart.
 */
export const statsCharts = [
  {
    keyData: 'outstandings',
    average: 'outstanding_balance_target',
    color: '#89B3DD'
  },
  {
    keyData: 'passive_outstandings',
    color: '#C766BE'
  },
  {
    keyData: 'rejections',
    average: 'target_rejection_rate',
    color: 'blue'
  },
  {
    keyData: 'actions_per_hour',
    color: '#87D95D'
  },
  {
    keyData: 'actions',
    color: '#87D95D'
  },
  {
    keyData: 'intervention_hours',
    color: '#F0845B'
  }
];
