import { useEffect } from 'react';
import { Row, Col, Typography, Popconfirm, Button, Tag } from 'antd';
import { useTranslation } from 'react-i18next';
import {
  CloseCircleOutlined,
  PauseCircleOutlined,
  PlayCircleOutlined,
  ClockCircleOutlined
} from '@ant-design/icons';
import moment from 'moment';
import 'moment/locale/fr'; // Importer la locale française
import useHomeContext from './HomeContext';
import { WorkButton } from './WorkButton';
import { CustomSpin } from '../../components/CustomSpin/CustomSpin';

moment.locale('fr');

/**
 * The Badgeuse component displays work-related information and allows users
 * to track their work hours and interventions.
 *
 * @component
 * @returns {JSX.Element} The JSX element representing the Badgeuse component.
 */

const Badgeuse = () => {
  const { t } = useTranslation();
  const {
    interventions,
    workHours,
    endWorkDay,
    changeCurrentState,
    isLoading,
    setTimer,
    timer,
    calculateInitialTimer
  } = useHomeContext();

  useEffect(() => {
    let intervalId;
    if (workHours?.current_state === 'work') {
      intervalId = setInterval(() => {
        setTimer(calculateInitialTimer());
      }, 1000);
    }
    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [workHours]);

  return isLoading ? (
    <CustomSpin />
  ) : (
    <>
      {workHours?.status !== 'ENDED' && (
        <Col>
          <Col
            span="24"
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center'
            }}
          >
            <Typography.Title
              level={4}
              style={{
                fontWeight: 300,
                textUnderlineOffset: '8px',
                color: 'var(--userColor)'
              }}
              strong
            >
              {t('home.cards.titles.badgeuse')}
            </Typography.Title>
            <ClockCircleOutlined />
          </Col>
          <Row
            justify="center"
            style={{ textAlign: 'center', margin: '30px 0 20px 0' }}
          >
            <Col span={24}>
              <Typography.Title
                level={3}
                style={{
                  fontWeight: 400,
                  color: '#33B6B2'
                }}
                strong={false}
              >
                {moment()
                  .format('dddd DD MMMM')
                  ?.replace(/^\w/, (c) => c?.toUpperCase())}
              </Typography.Title>
            </Col>
            <Col span={24} style={{ fontSize: 18 }}>
              <Tag>
                {workHours?.start_time
                  ? `${timer.hours().toString().padStart(2, '0')} : ${timer
                      .minutes()
                      .toString()
                      .padStart(2, '0')} : ${timer
                      .seconds()
                      .toString()
                      .padStart(2, '0')}`
                  : '00:00:00'}
              </Tag>
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col span={12}>
              <WorkButton
                isActive={workHours?.current_state === 'work'}
                onClick={() => changeCurrentState('work')}
                icon={<PlayCircleOutlined />}
                text="Travail"
              />
            </Col>
            <Col span={12}>
              <WorkButton
                isActive={workHours?.current_state === 'pause'}
                onClick={() => changeCurrentState('pause')}
                icon={<PauseCircleOutlined />}
                text="Pause"
              />
            </Col>
          </Row>
          {interventions?.map((inter) => (
            <Col span={12}>
              <WorkButton
                isActive={workHours?.current_state === inter.ps_number}
                onClick={() => changeCurrentState(inter.ps_number)}
                text={`N° PS : ${inter.ps_number}`}
              />
            </Col>
          ))}
          <Row>
            <Popconfirm
              title={
                <span
                  style={{ fontSize: 16, color: 'red', fontWeight: 'bold' }}
                >
                  {t('home.end_day.title')}
                </span>
              }
              okText={t('home.end_day.ok')}
              okButtonProps={{ danger: 'true' }}
              cancelText={t('home.end_day.cancel')}
              onConfirm={() => endWorkDay()}
            >
              <Button
                icon={<CloseCircleOutlined />}
                style={{
                  width: '100%',
                  backgroundColor: '#A25384',
                  borderColor: '#A25384',
                  color: 'white',
                  marginTop: '16px'
                }}
              >
                Fin de journée
              </Button>
            </Popconfirm>
          </Row>
        </Col>
      )}
      {workHours?.status === 'ENDED' && (
        <Typography.Text style={{ justifyContent: 'center' }}>
          {t('home.end_day.ended')}
        </Typography.Text>
      )}
    </>
  );
};

export default Badgeuse;
