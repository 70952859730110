import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Row } from 'antd';
import { useTranslation } from 'react-i18next';
import { useAuthContext } from '../../contexts/AuthContext';
import { useErrorMessage } from '../../utils/errorMessage';
import { ContentCustom, PageHeaderCustom } from '../../components';
import { CustomSpin } from '../../components/CustomSpin/CustomSpin';
import { ShowServices } from '../quotes/ShowCards/ShowServices';
import ServiceContextProvider from './ServiceContext';
import AdjustedHourModal from '../contracts/AdjustedHourModal';
import { showSuccessMessage } from '../../utils/showSuccessMessage';

/**
 * Component for displaying detailed information about a contract.
 *
 * @component
 * @returns {JSX.Element} Rendered ShowContract component
 */

export const ShowService = () => {
  const { id } = useParams();
  const { t } = useTranslation();
  const { message } = useErrorMessage();
  const { dispatchAPI } = useAuthContext();
  const { service, setService, setForceRefresh, forceRefresh } =
    ServiceContextProvider();
  const [isLoading, setIsLoading] = useState(true);
  const [isModalHourOpen, setIsModalHourOpen] = useState(false);
  const [serviceId, setServiceId] = useState('');
  const [adjustedHours, setAdjustedHours] = useState(0);

  const getService = useCallback(async () => {
    setIsLoading(true);
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/services/calcul/${id}?populate=contract,user_referent,assigned_user,leader`
      });
      setService(data);
    } catch (e) {
      message(e);
    }
  }, [id]);

  const openModalAdjustedHours = (hours, servId) => {
    setIsModalHourOpen(true);
    setServiceId(servId);
    setAdjustedHours(hours);
  };

  const closeModalAdjustedHours = () => {
    setIsModalHourOpen(false);
    setServiceId('');
    setAdjustedHours(0);
  };

  const patchServiceAdjustedHour = async (idService, values) => {
    try {
      await dispatchAPI('PATCH', {
        url: `/services/${idService}`,
        body: values
      });
      setForceRefresh(forceRefresh + 1);
      showSuccessMessage(t, 'hour', 'adjusted');
      closeModalAdjustedHours();
    } catch (e) {
      message(e);
    }
  };

  useEffect(() => {
    (async () => {
      await getService();
      setIsLoading(false);
    })();
  }, [getService, forceRefresh]);

  return isLoading ? (
    <ContentCustom>
      <CustomSpin />
    </ContentCustom>
  ) : (
    <ContentCustom>
      {isModalHourOpen && (
        <AdjustedHourModal
          openModal={isModalHourOpen}
          closeModalAdjustedHours={closeModalAdjustedHours}
          id={serviceId}
          adjustedHours={adjustedHours}
          patchServiceAdjustedHour={patchServiceAdjustedHour}
        />
      )}
      <PageHeaderCustom title={`${t('services.show.title')} `} />
      {!isLoading && (
        <Row gutter={[24, 24]}>
          <ShowServices
            data={{ services: [service] }}
            openModalAdjustedHours={openModalAdjustedHours}
          />
        </Row>
      )}
    </ContentCustom>
  );
};
