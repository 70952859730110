import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { Tag, Popconfirm } from 'antd';
import moment from 'moment';
import { CloseOutlined } from '@ant-design/icons';
import { userPositions } from '../../utils/constants/tagColors';
import useHomeContext from './HomeContext';
import { formatValue } from '../../utils/formatNumber';

/**
 * Generate columns configuration for displaying collaborator data in a table.
 *
 * @hook
 * @param {string[]} positions - The list of available positions.
 * @param {Object[]} companies - The list of companies with their information.
 * @returns {Object[]} An array of column configuration objects.
 */

export const useColumns = (positions, companies, date, isAdmin) => {
  const { t } = useTranslation();
  const { deleteEndWorkDay } = useHomeContext();

  return [
    {
      title: t('home.form.collaborator'),
      key: 'first_name',
      dataIndex: ['user'],
      render: (user) =>
        `${user?.first_name} ${user?.usage_last_name || user?.last_name} `,
      sorter: true
    },
    {
      title: t('home.form.position'),
      key: 'position',
      dataIndex: ['user'],
      render: (user) => (
        <Tag
          color={
            userPositions[
              (user?.employments || []).filter((f) => !f.end_date)[0]?.position
            ]
          }
        >
          {t(
            `users.positions.${
              (user?.employments || []).filter((f) => !f.end_date)[0]
                ?.position || 'N/A'
            }`
          )}
        </Tag>
      ),
      sorter: true,
      filters: positions?.map((p) => ({
        text: t(`users.positions.${p.split(':')[1]}`),
        value: p
      }))
    },
    {
      title: t('home.form.start_time'),
      key: 'start_time',
      dataIndex: 'start_time',
      sorter: true,
      render: (start_time) => formatValue(start_time, 'time')
    },
    {
      title: t('home.form.end_time'),
      key: 'end_time',
      dataIndex: 'end_time',
      sorter: true,
      render: (end_time) =>
        end_time !== '-' ? (
          <p>
            {formatValue(end_time, 'time')}
            <Popconfirm
              title={
                <span
                  style={{ fontSize: 16, color: 'red', fontWeight: 'bold' }}
                >
                  {t('datatable.column.action.cancel_end_day.title')}
                </span>
              }
              okText={t('datatable.column.action.cancel_end_day.ok')}
              okButtonProps={{ danger: 'true' }}
              cancelText={t('datatable.column.action.cancel_end_day.cancel')}
              onConfirm={() => deleteEndWorkDay()}
            >
              {dayjs(date).format('DD/MM/YYYY') ===
                dayjs().format('DD/MM/YYYY') && isAdmin ? (
                <CloseOutlined style={{ color: 'red', marginLeft: '12px' }} />
              ) : (
                ''
              )}
            </Popconfirm>
          </p>
        ) : (
          '-'
        )
    },
    {
      title: t('home.form.duration'),
      key: 'duration',
      dataIndex: 'duration',
      sorter: true,
      render: (duration) => {
        const durationSec = moment.duration(duration, 'seconds');
        const hours = Math.floor(durationSec.asHours());
        const minutes = durationSec.minutes();

        return `${hours}h${minutes}`;
      }
    },
    // TODO: Check when interventions available
    {
      title: t('home.form.first_inter_start_time'),
      key: 'firstInter',
      dataIndex: ['firstInter'],
      sorter: true,
      render: (firstInter) =>
        firstInter?.length ? formatValue(firstInter.start_time, 'time') : '-'
    },
    {
      title: t('home.form.delta'),
      key: 'delta',
      dataIndex: 'delta',
      sorter: true,
      render: (delta) => moment.duration(delta, 'seconds').humanize()
    },
    {
      title: t('home.form.ps_number_first_inter'),
      key: 'firstInter',
      dataIndex: ['firstInter'],
      sorter: true,
      render: (firstInter) =>
        firstInter?.length ? firstInter[0].ps_number : '-'
    },
    {
      title: t('home.form.company_name'),
      key: 'firstInter',
      dataIndex: ['firstInter'],
      sorter: true,
      render: (firstInter) =>
        firstInter?.length
          ? companies?.find((c) => c.ps_number === firstInter[0].ps_number)
              ?.company_name
          : '-'
    }
  ];
};
