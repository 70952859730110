import { useTranslation } from 'react-i18next';
import { Tabs } from 'antd';
import { ContentCustom, PageHeaderCustom } from '../../components';
import StatisticsContextProvider from './StatisticsContext';
import StatisticsFilters from './StatisticsFilters';
import { ChartGtp } from './ChartGtp';
import { ChartPs } from './ChartPs';
import { ChartSoftware } from './ChartSoftware';
import { useAuthContext } from '../../contexts/AuthContext';

/**
 * Component for displaying statistics.
 * @module ShowStatistics
 * @returns {JSX.Element} React component
 */

const ShowStatistics = () => {
  const { t } = useTranslation();
  const { user } = useAuthContext();
  const { onTabChange, indexTab } = StatisticsContextProvider();

  const items = [
    {
      label: t('statistics.tabs.titles.gtp'),
      key: '1',
      children: indexTab === '1' && <ChartGtp />
    },
    ...(user?.role === 'users:USER'
      ? []
      : [
          {
            label: t('statistics.tabs.titles.leader'),
            key: '2',
            children: indexTab === '2' && <ChartGtp />
          }
        ]),
    {
      label: t('statistics.tabs.titles.ps_type'),
      key: '3',
      children: indexTab === '3' && <ChartPs />
    },
    {
      label: t('statistics.tabs.titles.software'),
      key: '4',
      children: indexTab === '4' && <ChartSoftware />
    }
  ];

  return (
    <ContentCustom>
      <PageHeaderCustom title={t('basiclayout.menu.statistics')} />
      <StatisticsFilters />
      <Tabs
        type="card"
        defaultActiveKey={indexTab}
        items={items}
        onChange={onTabChange}
      />
    </ContentCustom>
  );
};
export default ShowStatistics;
