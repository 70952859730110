import dayjs from 'dayjs';
import { Typography, Row, Col } from 'antd';

export const popoverHoursFormat = (t, workHours) => {
  const interventions = [];
  const interKeys = Object.keys(workHours.interventions);
  for (let i = 0; i < Object.keys(workHours.interventions)?.length; i += 1) {
    interventions.push({
      ...workHours.interventions[interKeys[i]],
      type: interKeys[i]
    });
  }

  const work = workHours?.work?.map((wh) => ({
    start_time: wh.start_time,
    end_time: wh.end_time,
    type: 'work'
  }));

  const pause = workHours?.pause?.map((wh) => ({
    start_time: wh.start_time,
    end_time: wh.end_time,
    type: 'pause'
  }));

  const flatened = [...work, ...pause, ...interventions]
    .sort((a, b) => dayjs(a.start_time).diff(dayjs(b.start_time)))
    .flat();

  const mapped = flatened.map((item) => {
    const start = dayjs(item.start_time);
    const end = dayjs(item.end_time);
    return (
      <Row>
        <Col span={12}>
          <Typography.Text>
            {t(`internalGestion.popover.${item.type}`)}
          </Typography.Text>
        </Col>
        <Col span={12}>
          <Typography.Text>{`${start.format('HH:mm')} - ${
            item.end_time ? end.format('HH:mm') : ''
          }`}</Typography.Text>
        </Col>
      </Row>
    );
  });

  return mapped;
};
