import { useState } from 'react';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import {
  Select,
  Space,
  Button,
  Typography,
  Tag,
  DatePicker,
  InputNumber,
  Switch
} from 'antd';
import { servicesColor } from '../../utils/constants/tagColors';

const { Option } = Select;
const { Text } = Typography;
const { TimePicker } = DatePicker;

const useInteventionFields = (
  isFieldsLoading,
  interventionEnums,
  leadersAndGtp,
  customers,
  customerEnums,
  psMicro,
  softwares,
  services,
  startWeek,
  setHasRepetion,
  hasRepetion,
  days,
  setDays,
  formValues,
  user,
  fromInterventionList,
  isDirectIntervention,
  setIsDirectIntervention
) => {
  const { t } = useTranslation();
  const [frequencySelected, setFrequencySelected] = useState('');
  const isGtp = user?.role === 'users:USER';

  const disabledHours = () => {
    const hours = [0, 1, 2, 3, 4, 5, 6, 7, 20, 21, 22, 23];
    return hours;
  };

  const disabledMinutes = () => {
    const minutes = [];
    for (let i = 0; i < 60; i += 1) {
      if (i % 15 !== 0) {
        minutes.push(i);
      }
    }
    return minutes;
  };

  const format = 'DD-MM-YYYY';
  const start_period = dayjs(startWeek).add(1, 'day').startOf('day');
  const end_period = dayjs(startWeek).endOf('day').add(7, 'day');

  const getCheckedDaysString = (d) =>
    d
      .filter((day) => day.checked)
      .map((day) => t(`interventions.form.days_fr.${day.name}`))
      .join(', ');

  const validateDays = () => {
    const isAnyDayChecked = days.some((day) => day.checked);
    if (!isAnyDayChecked) {
      return Promise.reject(new Error('Merci de séléctionner un jour'));
    }
    return Promise.resolve();
  };
  const onSearch = (input, option) => {
    const childrenText = Array.isArray(option.children)
      ? option.children.join(' ')
      : option.children;
    return childrenText.toLowerCase().includes(input.toLowerCase());
  };
  const interventionFields = [
    {
      name: ['ps_number'],
      rules: [{ required: true }],
      input: (
        <Select
          allowClear
          showSearch
          filterOption={(input, option) => onSearch(input, option)}
        >
          {(customers || []).map((cust) => (
            <Option key={cust._id} value={cust?.ps_number}>
              {cust?.ps_number} {cust?.company_name}
            </Option>
          ))}
        </Select>
      )
    },
    {
      name: ['company_name'],
      rules: [{ required: true }],
      input: (
        <Select
          showSearch
          disabled
          options={customerEnums?.status?.map((item) => ({
            label: t(`customers.status.${item}`),
            value: item
          }))}
        />
      )
    },
    {
      name: ['micro_ps'],
      rules: [{ required: true }],
      input: (
        <Select loading={isFieldsLoading} disabled>
          {(psMicro || []).map((micro) => (
            <Option key={micro?.title} value={micro._id}>
              {micro?.title}
            </Option>
          ))}
        </Select>
      )
    },
    {
      name: ['macro_ps'],
      hidden: true,
      rules: [{ required: true }]
    },
    {
      name: ['used_softwares'],
      input: (
        <Select disabled loading={isFieldsLoading} mode="multiple" allowClear>
          {(softwares || []).map((software) => (
            <Option key={software.title} value={software._id}>
              {software.title}
            </Option>
          ))}
        </Select>
      )
    },
    {
      name: ['contract_code'],
      rules: [{ required: true }],
      hidden: true,
      disabled: true
    },
    {
      name: ['service'],
      rules: [{ required: true }],
      input: (
        <Select loading={isFieldsLoading} disabled={!formValues?.ps_number}>
          {(services || [])
            .filter((f) => f?.customer === formValues?.customer)
            .map((service) => (
              <Option key={service._id} value={service._id}>
                <Tag color={servicesColor[service?.type?.split('_')[0]]}>
                  {`${t(`quotes.tags.${service?.type}`)}: ${`${
                    service?.total_hours
                  }h/${t(`quotes.tags.${service?.frequency}`)}`}`}
                </Tag>
                {service.code}
              </Option>
            ))}
        </Select>
      )
    },
    {
      name: ['service_start'],
      tooltip: true,
      rules: [{ required: true }],
      input: <DatePicker disabled format={format} />
    },
    {
      name: ['service_end'],
      tooltip: true,
      input: <DatePicker disabled format={format} />
    },
    ...(fromInterventionList
      ? [
          {
            name: ['direct_intervention'],
            tooltip: true,
            initialValue: isDirectIntervention,
            input: (
              <Switch
                disabled={isGtp}
                checkedChildren={t('switch.yes')}
                unCheckedChildren={t('switch.no')}
                onChange={() => setIsDirectIntervention(!isDirectIntervention)}
              />
            )
          }
        ]
      : []),
    {
      name: ['scheduled_time'],
      tooltip: true,
      rules: [{ required: !isDirectIntervention }],
      hidden: isDirectIntervention,
      input: <InputNumber />
    },
    ...(isDirectIntervention
      ? [
          {
            name: ['scheduled_start_date'],
            label: 'scheduled_start_date_hour',
            rules: [{ required: true }],
            input: (
              <TimePicker
                format="HH:mm"
                disabledHours={disabledHours}
                disabledMinutes={disabledMinutes}
                minuteStep={15}
                disabled={isDirectIntervention}
                hideDisabledOptions
              />
            )
          },
          {
            name: ['scheduled_end_date'],
            label: 'scheduled_end_date_hour',
            rules: [{ required: true }],
            input: (
              <TimePicker
                format="HH:mm"
                disabledHours={disabledHours}
                disabledMinutes={disabledMinutes}
                minuteStep={15}
                hideDisabledOptions
                disabled
              />
            )
          }
        ]
      : []),
    {
      name: ['leader'],
      rules: [{ required: true }],
      initialValue: isGtp && leadersAndGtp[0]._id,
      input: (
        <Select
          loading={isFieldsLoading}
          disabled={isGtp}
          allowClear
          showSearch
          filterOption={(input, option) =>
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
        >
          {(leadersAndGtp || [])
            .filter((f) => f.role === 'users:LEADER-USER')
            .map((gtp) => (
              <Option key={gtp._id} value={gtp._id}>
                {`${gtp?.first_name} ${gtp?.usage_last_name || gtp?.last_name}`}
              </Option>
            ))}
        </Select>
      )
    },
    {
      name: ['user_referent'],
      rules: [{ required: true }],
      initialValue: isGtp && user?._id,
      input: (
        <Select
          loading={isFieldsLoading}
          disabled={isGtp}
          allowClear
          showSearch
          filterOption={(input, option) =>
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
        >
          {(leadersAndGtp || []).map((gtp) => (
            <Option key={gtp._id} value={gtp._id}>
              {`${gtp?.first_name} ${gtp?.usage_last_name || gtp?.last_name}`}
            </Option>
          ))}
        </Select>
      )
    },
    {
      name: ['user'],
      rules: [{ required: true }],
      initialValue: isGtp && user?._id,
      hidden: isGtp,
      input: (
        <Select loading={isFieldsLoading}>
          {(leadersAndGtp || []).map((gtp) => (
            <Option key={gtp._id} value={gtp._id}>
              {`${gtp?.first_name} ${gtp?.usage_last_name || gtp?.last_name}`}
            </Option>
          ))}
        </Select>
      )
    },
    {
      name: ['period_start'],
      initialValue: startWeek && start_period,
      tooltip: true,
      rules: [{ required: true }],
      input: (
        <DatePicker
          disabled={!!startWeek}
          format={format}
          disabledDate={(current) => current && current.day() !== 1}
        />
      )
    },
    {
      name: ['period_end'],
      initialValue: startWeek && end_period,
      tooltip: true,
      rules: [{ required: true }],
      input: <DatePicker disabled format={format} />
    },
    ...(!isGtp && !isDirectIntervention
      ? [
          {
            name: ['has_repetition'],
            valuePropName: 'checked',
            input: (
              <Switch
                checkedChildren={t('switch.yes')}
                unCheckedChildren={t('switch.no')}
                onChange={() => setHasRepetion(!hasRepetion)}
              />
            )
          }
        ]
      : []),
    ...(hasRepetion
      ? [
          {
            name: ['frequency'],
            rules: [{ required: true }],
            input: (
              <Select
                loading={isFieldsLoading}
                allowClear
                onSelect={(value) => setFrequencySelected(value)}
                onClear={() => setFrequencySelected('')}
              >
                {(interventionEnums.frequencies || []).map((frequency) => (
                  <Option key={frequency} value={frequency}>
                    {t(`interventions.form.enums.${frequency}`)}
                  </Option>
                ))}
              </Select>
            )
          },
          ...(frequencySelected
            ? [
                {
                  name: ['repetition_days'],
                  rules: [
                    {
                      validator: validateDays
                    }
                  ],
                  input: (
                    <Space>
                      {days.map((day, index) => (
                        <Space direction="vertical" key={day.name}>
                          <Button
                            style={{
                              background: day.checked ? 'lightblue' : '',
                              color: day.checked ? 'white' : ''
                            }}
                            onClick={() =>
                              setDays(
                                days.map((d, idx) =>
                                  idx === index
                                    ? { ...d, checked: !day.checked }
                                    : d
                                )
                              )
                            }
                          >
                            {t(`interventions.form.days.${day.name}`)}
                          </Button>
                        </Space>
                      ))}
                    </Space>
                  )
                },
                {
                  name: ['repetition_week'],
                  initialValue: 10,
                  rules: [{ required: true }],
                  input: <InputNumber />
                },
                {
                  name: ['recap'],
                  input: (
                    <Text>
                      {`${
                        frequencySelected
                          ? t(`interventions.form.enums.${frequencySelected}`)
                          : ''
                      } ${getCheckedDaysString(days)}`}
                    </Text>
                  )
                }
              ]
            : [])
        ]
      : []),
    {
      name: ['customer'],
      rules: [{ required: true }],
      disabled: true,
      hidden: true
    },
    {
      name: ['contract'],
      rules: [{ required: true }],
      disabled: true,
      hidden: true
    }
  ];
  return {
    interventionFields
  };
};

export default useInteventionFields;
