import { Select, Input } from 'antd';
import { useTranslation } from 'react-i18next';

const { Option } = Select;
/**
 * Custom hook for retrieving and managing technique-related fields for user data.
 *
 * @hook
 * @returns {object} An object containing technique fields and loading state
 * @property {Array} techniqueFields - An array of technique-related fields
 * @property {boolean} isFieldsLoading - A boolean indicating whether the fields are loading
 */

const useFields = (users) => {
  const { t } = useTranslation();
  const ruleMessage = t('errors.form.REQUIRED');

  const fields = [
    {
      label: 'total_days_did',
      name: 'total_days_did',
      rules: [{ required: true, message: ruleMessage }],
      input: <Input type="number" />
    },
    {
      label: 'total_days',
      name: 'total_days',
      rules: [{ required: true, message: ruleMessage }],
      input: <Input type="number" />
    },
    {
      label: 'balance',
      name: 'balance',
      rules: [{ required: true, message: ruleMessage }],
      input: <Input type="number" disabled />
    },
    {
      label: 'reported_day',
      name: 'reported_day',
      input: <Input type="number" disabled />
    },
    {
      label: 'given',
      name: 'given',
      input: <Input type="number" disabled />
    },
    {
      label: 'received',
      name: 'received',
      input: <Input type="number" disabled />
    },
    {
      label: 'author',
      name: 'author',
      startWithDivider: {
        orientation: 'center',
        plain: true,
        title: t('vacations.modal.transfer')
      },
      input: (
        <Select>
          {(users || []).map((transmitter) => (
            <Option key={transmitter._id} value={transmitter._id}>
              {transmitter.first_name}{' '}
              {transmitter.usage_last_name || transmitter.last_name}
            </Option>
          ))}
        </Select>
      )
    },
    {
      label: 'number',
      name: 'number',
      input: <Input type="number" />
    }
  ];
  return { fields };
};

export default useFields;
