export const headers = [
  {
    label: 'company',
    key: 'customer.company'
  },
  {
    label: 'ps_number',
    key: 'ps_number'
  },
  {
    label: 'company_name',
    key: 'customer.company_name'
  },
  {
    label: 'micro_ps',
    key: 'micro_ps.title'
  },
  {
    label: 'macro_ps',
    key: 'macro_ps.title'
  },
  {
    label: 'code',
    key: 'code'
  }
];
