import { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { DatePicker, Select, Checkbox, Input } from 'antd';
import { useTranslation } from 'react-i18next';

const { Option } = Select;
const { RangePicker } = DatePicker;

const selectFormat = (t, enums, options) => (
  <Select
    showSearch={options?.showSearch}
    allowClear={options?.allowClear}
    loading={options?.loading}
    filterOption={options?.filterOption}
    disabled={options?.disabled}
  >
    {(enums || []).map((item) => (
      <Option key={item} value={item}>
        {t(`employments.tags.${item}`)}
      </Option>
    ))}
  </Select>
);

const useFields = (
  purpose,
  enums,
  leaders,
  user,
  contractType,
  rootContract,
  startDateContract,
  company
) => {
  const { t } = useTranslation();
  const [isFieldsLoading, setIsFieldsLoading] = useState(true);
  const userNeedTeam = ['USER', 'LEADER-USER'];

  const requiredMessage = t('errors.form.REQUIRED');

  const disabledHours = () => {
    const hours = [0, 1, 2, 3, 4, 5, 6, 7, 20, 21, 22, 23];
    return hours;
  };

  const disabledMinutes = () => {
    const minutes = [];
    for (let i = 0; i < 60; i += 1) {
      if (i % 15 !== 0) {
        minutes.push(i);
      }
    }
    return minutes;
  };

  const defaultMorningValue = [
    dayjs().hour(8).minute(30),
    dayjs().hour(12).minute(30)
  ];
  const defaultAfternoonValue = [
    dayjs().hour(13).minute(0),
    dayjs().hour(17).minute(0)
  ];

  useEffect(() => {
    if (enums) setIsFieldsLoading(false);
  }, []);

  const fields = [
    {
      name: ['company'],
      input: selectFormat(t, enums?.companies, {
        disabled: purpose === 'edit' || company
      }),
      initialValue: company,
      rules: [{ required: true, message: requiredMessage }],
      step: 0
    },
    {
      name: ['type'],
      input: selectFormat(t, enums?.types, { disabled: true }),
      rules: [{ required: true, message: requiredMessage }],
      initialValue: contractType,
      step: 0
    },
    {
      name: ['contract_type'],
      input: selectFormat(t, enums?.contract_types),
      rules: [{ required: true, message: requiredMessage }],
      step: 0
    },
    {
      name: ['position'],
      input: selectFormat(
        t,
        enums?.positions?.filter((f) => f)
      ),
      rules: [{ required: true, message: requiredMessage }],
      step: 0
    },
    {
      name: ['job_requirement'],
      input: selectFormat(t, enums?.levels),
      rules: [{ required: true, message: requiredMessage }],
      step: 0
    },
    {
      name: ['is_admin'],
      valuePropName: 'checked',
      input: <Checkbox />,
      layout: 'horizontal',
      step: 0
    },
    ...(userNeedTeam.includes(user?.role?.split(':')[1])
      ? [
          {
            name: ['leader'],
            rules: [{ required: true, message: requiredMessage }],
            input: (
              <Select allowClear>
                {(leaders || []).map((leader) => (
                  <Option key={leader._id} value={leader._id}>
                    {`${leader.first_name} ${
                      leader?.usage_last_name || leader?.last_name
                    }`}
                  </Option>
                ))}
              </Select>
            ),
            step: 0
          },
          {
            name: ['is_leader'],
            valuePropName: 'checked',
            input: <Checkbox />,
            step: 0
          }
        ]
      : []),
    {
      name: ['start_date'],
      input: (
        <DatePicker
          format="DD-MM-YYYY"
          disabledDate={(current) => {
            if (startDateContract) {
              return current && current.isBefore(dayjs(startDateContract));
            }
            return false;
          }}
        />
      ),
      rules: [{ required: true, message: requiredMessage }],
      step: 0
    },
    {
      name: ['work_hours'],
      input: selectFormat(t, enums?.hours),
      rules: [{ required: true, message: requiredMessage }],
      step: 0
    },
    {
      name: ['remaining_paid_vacation'],
      input: <Input type="number" />,
      step: 0
    },
    {
      name: ['user'],
      hidden: true,
      initialValue: user?._id,
      step: 0
    },
    {
      name: ['rootContract'],
      hidden: true,
      initialValue: rootContract,
      step: 0
    },
    {
      name: ['monday_morning'],
      input: (
        <RangePicker
          picker="time"
          format="HH:mm"
          defaultValue={defaultMorningValue}
          disabledHours={disabledHours}
          disabledMinutes={disabledMinutes}
          minuteStep={15}
          hideDisabledOptions
        />
      ),
      initialValue: defaultMorningValue,
      startWithDivider: {
        orientation: 'left',
        plain: true,
        title: 'Lundi',
        style: { marginTop: 30 }
      },
      step: 1
    },
    {
      name: ['monday_afternoon'],
      input: (
        <RangePicker
          picker="time"
          format="HH:mm"
          defaultValue={defaultAfternoonValue}
          disabledHours={disabledHours}
          disabledMinutes={disabledMinutes}
          minuteStep={15}
          hideDisabledOptions
        />
      ),
      initialValue: defaultAfternoonValue,
      step: 1
    },
    {
      name: ['tuesday_morning'],
      input: (
        <RangePicker
          picker="time"
          format="HH:mm"
          defaultValue={defaultMorningValue}
          disabledHours={disabledHours}
          disabledMinutes={disabledMinutes}
          minuteStep={15}
          hideDisabledOptions
        />
      ),
      initialValue: defaultMorningValue,
      startWithDivider: { orientation: 'left', plain: true, title: 'Mardi' },
      step: 1
    },
    {
      name: ['tuesday_afternoon'],
      input: (
        <RangePicker
          picker="time"
          format="HH:mm"
          defaultValue={defaultAfternoonValue}
          disabledHours={disabledHours}
          disabledMinutes={disabledMinutes}
          minuteStep={15}
          hideDisabledOptions
        />
      ),
      initialValue: defaultAfternoonValue,
      step: 1
    },
    {
      name: ['wednesday_morning'],
      input: (
        <RangePicker
          picker="time"
          format="HH:mm"
          defaultValue={defaultMorningValue}
          disabledHours={disabledHours}
          disabledMinutes={disabledMinutes}
          minuteStep={15}
          hideDisabledOptions
        />
      ),
      initialValue: defaultMorningValue,
      startWithDivider: { orientation: 'left', plain: true, title: 'Mercredi' },
      step: 1
    },
    {
      name: ['wednesday_afternoon'],
      input: (
        <RangePicker
          picker="time"
          format="HH:mm"
          defaultValue={defaultAfternoonValue}
          disabledHours={disabledHours}
          disabledMinutes={disabledMinutes}
          minuteStep={15}
          hideDisabledOptions
        />
      ),
      initialValue: defaultAfternoonValue,
      step: 1
    },
    {
      name: ['thursday_morning'],
      input: (
        <RangePicker
          picker="time"
          format="HH:mm"
          defaultValue={defaultMorningValue}
          disabledHours={disabledHours}
          disabledMinutes={disabledMinutes}
          minuteStep={15}
          hideDisabledOptions
        />
      ),
      initialValue: defaultMorningValue,
      startWithDivider: { orientation: 'left', plain: true, title: 'Jeudi' },
      step: 1
    },
    {
      name: ['thursday_afternoon'],
      input: (
        <RangePicker
          picker="time"
          format="HH:mm"
          defaultValue={defaultAfternoonValue}
          disabledHours={disabledHours}
          disabledMinutes={disabledMinutes}
          minuteStep={15}
          hideDisabledOptions
        />
      ),
      initialValue: defaultAfternoonValue,
      step: 1
    },
    {
      name: ['friday_morning'],
      input: (
        <RangePicker
          picker="time"
          format="HH:mm"
          defaultValue={defaultMorningValue}
          disabledHours={disabledHours}
          disabledMinutes={disabledMinutes}
          minuteStep={15}
          hideDisabledOptions
        />
      ),
      initialValue: defaultMorningValue,
      startWithDivider: { orientation: 'left', plain: true, title: 'Vendredi' },
      step: 1
    },
    {
      name: ['friday_afternoon'],
      input: (
        <RangePicker
          picker="time"
          format="HH:mm"
          defaultValue={defaultAfternoonValue}
          disabledHours={disabledHours}
          disabledMinutes={disabledMinutes}
          minuteStep={15}
          hideDisabledOptions
        />
      ),
      initialValue: defaultAfternoonValue,
      step: 1
    }
  ];

  return {
    fields,
    isFieldsLoading
  };
};

export default useFields;
