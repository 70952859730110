import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Card, Col } from 'antd';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer
} from 'recharts';

/**
 * Customized axis tick component for the LineChart.
 * @module CustomizedAxisTick
 * @param {Object} props - Component props
 * @param {number} props.x - X coordinate of the tick.
 * @param {number} props.y - Y coordinate of the tick.
 * @param {Object} props.payload - Tick payload containing value.
 * @param {string} props.payload.value - Value of the tick, representing month.
 * @returns {JSX.Element} React component
 */
const CustomizedAxisTick = ({ x, y, payload }) => {
  const { t } = useTranslation();
  return (
    <g transform={`translate(${x},${y})`}>
      <text
        x={0}
        y={0}
        dy={16}
        textAnchor="end"
        fill="#666"
        transform="rotate(-35)"
      >
        {t(`statistics.months.${payload.value}`)}
      </text>
    </g>
  );
};

/**
 * Customized label component for the LineChart.
 * @module CustomizedLabel
 * @param {Object} props - Component props
 * @param {number} props.x - X coordinate of the label.
 * @param {number} props.y - Y coordinate of the label.
 * @param {string} props.stroke - Stroke color of the label.
 * @param {(number|string)} props.value - Value of the label.
 * @returns {JSX.Element} React component
 */
const CustomizedLabel = ({ x, y, stroke, value }) => (
  <text x={x} y={y} dy={-4} fill={stroke} fontSize={10} textAnchor="middle">
    {value}
  </text>
);

/**
 * A component for displaying a graph with specified data, title, and configuration.
 *
 * @component
 * @param {Object} props - The component's props.
 * @param {Array<Object>} props.data - The data points for the graph.
 * @param {number} props.span - The span size for the component layout.
 * @param {string} props.title - The title of the graph.
 * @param {string} props.color - The color of the graph title and underline.
 * @param {Object} props.axisTypes - The types of x-axis and y-axis (optional).
 * @param {string} props.axisTypes.xaxisType - The type of the x-axis (default: 'time').
 * @param {string} props.axisTypes.yaxisType - The type of the y-axis (default: 'linear').
 * @returns {JSX.Element} The JSX element representing the graph component.
 */
export const GraphCard = ({ data, keyData, average, span, title, color }) => {
  const { t } = useTranslation();
  return (
    <Col xs={24} xl={span}>
      <Card title={title}>
        <ResponsiveContainer height={300}>
          <LineChart
            data={data}
            margin={{
              top: 20,
              right: 30,
              left: 20,
              bottom: 10
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="title" height={60} tick={<CustomizedAxisTick />} />
            <YAxis />
            <Tooltip />
            <Legend />
            <Line
              type="monotone"
              dataKey={keyData}
              stroke={color}
              label={<CustomizedLabel />}
              name={t(`statistics.gtp.${keyData}`)}
            />
            {average && (
              <Line
                type="monotone"
                dataKey={average}
                stroke="red"
                label={<CustomizedLabel />}
                name={t('statistics.gtp.average')}
              />
            )}
          </LineChart>
        </ResponsiveContainer>
      </Card>
    </Col>
  );
};

GraphCard.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  span: PropTypes.number,
  title: PropTypes.string.isRequired,
  keyData: PropTypes.string,
  average: PropTypes.string,
  color: PropTypes.string
};

GraphCard.defaultProps = {
  span: 24,
  keyData: undefined,
  average: undefined,
  color: undefined
};

CustomizedLabel.propTypes = {
  x: PropTypes.number,
  y: PropTypes.number,
  stroke: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
};

CustomizedLabel.defaultProps = {
  x: undefined,
  y: undefined,
  stroke: undefined,
  value: undefined
};

CustomizedAxisTick.propTypes = {
  x: PropTypes.number,
  y: PropTypes.number,
  payload: PropTypes.shape({
    value: PropTypes.number.isRequired
  })
};

CustomizedAxisTick.defaultProps = {
  x: undefined,
  y: undefined,
  payload: undefined
};
