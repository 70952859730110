import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Drawer } from 'antd';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';
import { CreateUpdateContainer } from '../../../../components/CreateUpdateContainer/CreateUpdateContainer';
import useFields from './fields';
import { useAuthContext } from '../../../../contexts/AuthContext';
import { useErrorMessage } from '../../../../utils/errorMessage';

export const CreateUpdateDrawer = ({
  purpose,
  isOverlayVisible,
  idFromOverlay,
  customNavigate,
  user,
  contractType,
  rootContract,
  startDateContract,
  company
}) => {
  const { t } = useTranslation();
  const { dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();
  const [enums, setEnums] = useState({});
  const [leaders, setLeaders] = useState([]);
  const { fields, isFieldsLoading } = useFields(
    purpose,
    enums,
    leaders,
    user,
    contractType,
    rootContract,
    startDateContract,
    company
  );

  const filterPositions = (data) => {
    const filteredPositions = [];
    if (user?.role === 'users:USER') filteredPositions.push('USER');
    if (user?.role === 'users:LEADER-USER')
      filteredPositions.push('LEADER-USER');
    if (user?.role === 'users:SALES-USER')
      filteredPositions.push('SALES-USER', 'SALES-PROSPECTOR');
    if (user?.role === 'admins:ADMIN-PROD')
      filteredPositions.push('ADMIN-PROD');
    if (user?.role === 'admins:ADMIN') filteredPositions.push('ADMIN');
    if (user?.role === 'admins:ADMIN-RH') filteredPositions.push('ADMIN-RH');
    if (user?.role === 'admins:ADMIN-ACCOUNTANT')
      filteredPositions.push('ADMIN-ACCOUNTANT');
    if (user?.role === 'admins:SUPER-ADMIN')
      filteredPositions.push('SUPER-ADMIN');
    return { ...data, positions: filteredPositions };
  };

  const config = {
    onGetResource: {
      setFields: (data) => ({
        ...data,
        start_date: data.start_date && dayjs(data.start_date),
        monday_morning: (data.monday_morning || []).map((mm) => dayjs(mm)),
        monday_afternoon: (data.monday_afternoon || []).map((ma) => dayjs(ma)),
        tuesday_morning: (data.tuesday_morning || []).map((tm) => dayjs(tm)),
        tuesday_afternoon: (data.tuesday_afternoon || []).map((ta) =>
          dayjs(ta)
        ),
        wednesday_morning: (data.wednesday_morning || []).map((wm) =>
          dayjs(wm)
        ),
        wednesday_afternoon: (data.wednesday_afternoon || []).map((wa) =>
          dayjs(wa)
        ),
        thursday_morning: (data.thursday_morning || []).map((tm) => dayjs(tm)),
        thursday_afternoon: (data.thursday_afternoon || []).map((ta) =>
          dayjs(ta)
        ),
        friday_morning: (data.friday_morning || []).map((fm) => dayjs(fm)),
        friday_afternoon: (data.friday_afternoon || []).map((fa) => dayjs(fa))
      })
    },
    onCreateResource: {
      setBody: (data) => ({
        ...data,
        leader: data.is_leader ? user?._id : data.leader
      })
    }
  };

  const getEnums = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: 'employments/enums'
      });
      const filteredData = filterPositions(data);
      setEnums(filteredData);
    } catch (error) {
      message(error);
    }
  };

  const getLeaders = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: 'users?role=users:LEADER-USER'
      });
      setLeaders(data);
    } catch (error) {
      message(error);
    }
  };

  useEffect(async () => {
    await getEnums();
    await getLeaders();
  }, []);

  return (
    <Drawer
      centered
      title={`${t(`employments.titles.${purpose}`)} ${t(
        `employments.titles.${contractType}`
      )}`}
      open={isOverlayVisible}
      onClose={() => customNavigate()}
      width={700}
      footer={false}
    >
      <CreateUpdateContainer
        layout="vertical"
        fields={fields}
        loadingFields={isFieldsLoading}
        purpose={purpose}
        baseUrl="employments"
        resource="employments"
        isOverlay
        customNavigate={customNavigate}
        withHeader={false}
        idFromOverlay={idFromOverlay}
        config={config}
        drawer
      />
    </Drawer>
  );
};

CreateUpdateDrawer.propTypes = {
  purpose: PropTypes.string,
  isOverlayVisible: PropTypes.bool.isRequired,
  idFromOverlay: PropTypes.string,
  customNavigate: PropTypes.func,
  user: PropTypes.shape({
    _id: PropTypes.string,
    role: PropTypes.string
  }),
  contractType: PropTypes.string.isRequired,
  rootContract: PropTypes.string,
  startDateContract: PropTypes.string,
  company: PropTypes.string
};

CreateUpdateDrawer.defaultProps = {
  user: undefined,
  purpose: 'create',
  idFromOverlay: null,
  customNavigate: null,
  rootContract: null,
  startDateContract: null,
  company: null
};
