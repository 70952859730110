import { useState, useEffect } from 'react';
import { Input, Select, DatePicker } from 'antd';
import { useTranslation } from 'react-i18next';
import { useAuthContext } from '../../contexts/AuthContext';
import { useErrorMessage } from '../../utils/errorMessage';

const { TextArea } = Input;
const { Option } = Select;

const useFields = (
  quittance,
  enums,
  purpose,
  bankReconciliations,
  customers,
  selectedType,
  setSelectedType,
  selectedCustomer,
  setSelectedCustomer
) => {
  const { t } = useTranslation();
  const { dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();
  const [isFieldsLoading, setIsFieldsLoading] = useState(true);
  const [quittancesNotPaid, setQuittancesNotPaid] = useState(null);
  const [quittancesPaid, setQuittancesPaid] = useState(null);

  const getQuittancesNotPaid = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/quittances?status!=PAID${
          selectedCustomer?._id ? `&customer=${selectedCustomer?._id}` : ''
        }`
      });
      setQuittancesNotPaid(data);
    } catch (e) {
      message(e);
    }
  };

  const getQuittancesPaid = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/quittances?status=PAID${
          selectedCustomer?._id ? `&customer=${selectedCustomer?._id}` : ''
        }`
      });
      setQuittancesPaid(data);
    } catch (e) {
      message(e);
    }
  };

  const onsearch = (input, option) =>
    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;

  useEffect(() => {
    (async () => {
      if (selectedCustomer) {
        await getQuittancesNotPaid();
        await getQuittancesPaid();
      }
      setIsFieldsLoading(false);
    })();
  }, [selectedCustomer]);

  const fields = [
    {
      name: ['type'],
      rules: [{ required: true }],
      initialValue: selectedType,
      input: (
        <Select
          loading={isFieldsLoading}
          disabled={purpose === 'edit'}
          allowClear
          showSearch
          filterOption={(input, option) => onsearch(input, option)}
          onSelect={(value) => {
            setSelectedType(value);
          }}
        >
          {(enums?.types || []).map((type) => (
            <Option key={type} value={type}>
              {t(`payments.tags.${type}`)}
            </Option>
          ))}
        </Select>
      )
    },
    {
      name: ['label'],
      rules: [{ required: true }]
    },
    {
      name: ['amount'],
      rules: [{ required: true }],
      input: <Input type="number" addonAfter="€" disabled />
    },
    {
      name: ['customer'],
      initialValue: quittance?.customer?._id,
      hidden: quittance?.customer?._id,
      rules: [{ required: true }],
      input: (
        <Select
          loading={isFieldsLoading}
          allowClear
          disabled
          showSearch
          filterOption={(input, option) => onsearch(input, option)}
          onSelect={(value) => {
            const selectedCustomerData = customers.find(
              (customer) => customer._id === value
            );
            setSelectedCustomer(selectedCustomerData);
          }}
        >
          {(customers || []).map((customer) => (
            <Option key={customer._id} value={customer._id}>
              {customer?.company_name}
            </Option>
          ))}
        </Select>
      )
    },
    {
      name: ['quittance'],
      initialValue: quittance._id,
      rules: [{ required: true }],
      input: (
        <Select
          loading={isFieldsLoading}
          disabled={!!quittance._id || !quittancesNotPaid}
          allowClear
          showSearch
          filterOption={(input, option) => onsearch(input, option)}
        >
          {(quittancesNotPaid || []).map((q) => (
            <Option key={q.code} value={q._id}>
              {t(`${q.code} (${q.total_with_taxes.toFixed(2)} €)`)}
            </Option>
          ))}
        </Select>
      )
    },
    {
      name: ['quittance_linked'],
      initialValue: quittance._id,
      input: (
        <Select
          loading={isFieldsLoading}
          disabled
          allowClear
          showSearch
          filterOption={(input, option) => onsearch(input, option)}
        >
          {(quittancesPaid || []).map((q) => (
            <Option key={q.code} value={q._id}>
              {t(`${q.code} (${q.total_with_taxes.toFixed(2)} €)`)}
            </Option>
          ))}
        </Select>
      )
    },

    {
      name: ['payment_method'],
      rules: [{ required: true }],
      input: (
        <Select
          loading={isFieldsLoading}
          allowClear
          showSearch
          disabled
          filterOption={(input, option) => onsearch(input, option)}
        >
          {(enums?.payment_methods || []).map((payment) => (
            <Option key={payment} value={payment}>
              {t(`payments.tags.${payment}`)}
            </Option>
          ))}
        </Select>
      )
    },
    {
      name: ['bank_reconciliation'],
      initialValue:
        quittance?.customer?.bank_reconciliation?._id ||
        bankReconciliations?.[0]?._id,
      rules: [{ required: true }],
      input: (
        <Select
          allowClear
          showSearch
          filterOption={(input, option) => onsearch(input, option)}
        >
          {bankReconciliations?.map((bank) => (
            <Option key={bank._id} value={bank._id}>
              {bank.name}
            </Option>
          ))}
        </Select>
      )
    },
    {
      name: ['operation_date'],
      rules: [{ required: true }],
      input: <DatePicker format="DD-MM-YYYY" disabled />
    },
    {
      name: ['reconciliation_date'],
      rules: [{ required: true }],
      input: <DatePicker format="DD-MM-YYYY" />
    },
    {
      name: ['comment'],
      input: <TextArea rows={4} />
    }
  ];

  return {
    fields,
    isFieldsLoading
  };
};

export default useFields;
