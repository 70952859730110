import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Col, Collapse, Typography, Badge, Flex } from 'antd';
import PropTypes from 'prop-types';
import InterventionContextProvider from '../InterventionContext';
import { ListResource } from '../../../components';
import { useColumns } from './actionsReminderColumns';
import { useAuthContext } from '../../../contexts/AuthContext';
import { isAuthorized } from '../../../utils/constants/authorizedMenu';

export const ListActionsReminder = ({
  interventionId,
  forceActionsRefresh,
  onEdit,
  onArchived
}) => {
  const { t } = useTranslation();
  const { user } = useAuthContext();
  const { actionEnums, rejectionReasons } = InterventionContextProvider();
  const [titleTotals, setTitleTotals] = useState({});
  const location = useLocation();
  const { pathname } = location;

  const hasAccess = (purpose) => isAuthorized(pathname, user?.role, purpose);

  const columns = useColumns(
    actionEnums,
    rejectionReasons,
    { onEdit, onArchived },
    hasAccess
  );
  const items = [
    {
      key: '1',
      forceRender: true,
      label: (
        <Flex align="center" gap={8}>
          <Typography.Title
            level={4}
            style={{
              color: 'var(--userColor)',
              margin: 0
            }}
            strong={false}
          >
            {t('interventions.show.actions_reminder.title')}
          </Typography.Title>
          {titleTotals.urgent > 0 && (
            <Badge count={titleTotals?.urgent} color="#F31B05" />
          )}
          {titleTotals.upcoming > 0 && (
            <Badge count={titleTotals?.upcoming} color="#06B050" />
          )}
        </Flex>
      ),
      children: (
        <ListResource
          contentCustomStyle={{ padding: 0, margin: 0 }}
          resourceName="actions"
          columns={columns}
          resourceModelName="Action"
          populate="created_by,task_number,action,rejection_reason"
          withImportButton={false}
          forceRefresh={forceActionsRefresh}
          withCreateButton={false}
          withPageHeader={false}
          withSearchBar={false}
          onDoubleClickAction={false}
          extraQuery={`intervention=${interventionId}&is_action_reminder=true&action_reminder_done=false`}
          showAction={false}
          editAction={false}
          deleteAction={false}
          setTitleTotals={setTitleTotals}
          customActionColumn
        />
      )
    }
  ];

  return (
    <Col span="24">
      <Collapse
        items={items}
        expandIconPosition="end"
        style={{ borderColor: 'var(--borderPrimaryColor)' }}
      />
    </Col>
  );
};

ListActionsReminder.propTypes = {
  forceActionsRefresh: PropTypes.bool.isRequired,
  interventionId: PropTypes.string.isRequired,
  onEdit: PropTypes.func,
  onArchived: PropTypes.func
};

ListActionsReminder.defaultProps = {
  onEdit: null,
  onArchived: null
};
