import { Col, Card, Row } from 'antd';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { DescriptionList } from '../../../components';
import { usePrincipalInformationsContent } from './principalInformationsContent';

/**
 * Component for displaying principal quote informations.
 *
 * @component
 * @param {object} props - Component props
 * @param {object} props.data - Intervention data containing principal details
 * @returns {JSX.Element} Component JSX
 */

export const ShowPrincipalInformations = ({ data }) => {
  const listContent = usePrincipalInformationsContent(data);
  const { t } = useTranslation();

  return (
    <Col span="24">
      <Card title={t('quotes.show.titles.principal')}>
        <Row align="middle">
          <Col span={24}>
            <DescriptionList data={listContent} translate columns={24} />
          </Col>
        </Row>
      </Card>
    </Col>
  );
};

ShowPrincipalInformations.propTypes = {
  data: PropTypes.shape({
    status: PropTypes.string
  })
};

ShowPrincipalInformations.defaultProps = {
  data: undefined
};
