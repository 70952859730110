import { useTranslation } from 'react-i18next';
import { Switch, Flex, Typography } from 'antd';
import PropTypes from 'prop-types';
import { CalendarOutlined, TableOutlined } from '@ant-design/icons';

export const CommonSwitch = ({ state, setState }) => {
  const { t } = useTranslation();

  return (
    <Flex align="center" gap={8} wrap="nowrap">
      <Flex align="center" gap={6} wrap="nowrap">
        <TableOutlined />
        <Typography.Text style={{ whiteSpace: 'nowrap' }}>
          {t('internalGestion.switch.list')}
        </Typography.Text>
      </Flex>
      <Switch onChange={setState} checked={state} />
      <Flex align="center" gap={6} wrap="nowrap">
        <CalendarOutlined />
        <Typography.Text style={{ whiteSpace: 'nowrap' }}>
          {t('internalGestion.switch.calendar')}
        </Typography.Text>
      </Flex>
    </Flex>
  );
};

CommonSwitch.propTypes = {
  state: PropTypes.bool.isRequired,
  setState: PropTypes.func.isRequired
};
