import { useTranslation } from 'react-i18next';
import { Button, Form, Input, Popover, Tag } from 'antd';
import { CheckOutlined } from '@ant-design/icons';

export const useColumns = (
  psMicro,
  leaders,
  totalCount,
  customers,
  handleEditRejectPart,
  handleEditRejectNumber,
  editing,
  save,
  handleEditCancel,
  inputRefRejectNumber,
  inputRefRejectPart
) => {
  const { t } = useTranslation();

  return [
    {
      title: (
        <div>
          {t('rejections.columns.ps_number')}
          <Popover content={t('rejections.columns.total')}>
            <Tag color="#32B6B2">{totalCount}</Tag>
          </Popover>
        </div>
      ),
      key: 'ps_number',
      dataIndex: 'customer',
      render: (customer) => (
        <Tag>{(customer && customer?.ps_number) || '-'}</Tag>
      ),
      sorter: true
    },
    {
      title: t('rejections.columns.company_name'),
      key: 'customer.company_name',
      dataIndex: 'customer',
      render: (customer) => (customer && customer?.company_name) || '-',
      filterSearch: true,
      filters: customers?.map((customer) => ({
        text: customer.company_name,
        value: customer._id
      })),
      sorter: true
    },
    {
      title: t('rejections.columns.micro_ps'),
      key: 'micro_ps.title',
      dataIndex: 'micro_ps',
      render: (micro_ps) => (micro_ps && micro_ps?.title) || '-',
      filterSearch: true,
      filters: psMicro?.map((ps) => ({
        text: ps.title,
        value: ps._id
      })),
      sorter: true
    },
    {
      title: t('rejections.columns.leader'),
      key: 'leader.first_name',
      dataIndex: 'leader',
      render: (leader) =>
        (leader &&
          `${leader?.first_name} ${
            leader?.usage_last_name || leader?.last_name
          }`) ||
        '-',
      sorter: true,
      filters: (leaders || []).map((leader) => ({
        text: `${leader?.first_name} ${
          leader?.usage_last_name || leader?.last_name
        }`,
        value: leader._id
      }))
    },
    {
      title: t('rejections.columns.reject_number'),
      key: 'reject_number',
      dataIndex: 'reject_number',
      onCell: (record) => ({
        onClick: () => handleEditRejectNumber(record)
      }),
      render: (rejectNumber, record) =>
        editing.rejectNumber === record._id ? (
          <Form.Item name={['reject_number']}>
            <Input
              ref={inputRefRejectNumber}
              addonAfter={
                <Button
                  style={{ background: '#32B6B2', color: 'white' }}
                  onMouseDown={(e) => {
                    e.preventDefault();
                    save(record);
                  }}
                >
                  <CheckOutlined />
                </Button>
              }
              value={rejectNumber}
              onBlur={() => handleEditCancel()}
              onPressEnter={() => save(record)}
            />
          </Form.Item>
        ) : (
          <div>{rejectNumber}</div>
        ),
      sorter: true
    },
    {
      title: t('rejections.columns.reject_part'),
      key: 'reject_part',
      dataIndex: 'reject_part',
      onCell: (record) => ({
        onClick: () => handleEditRejectPart(record)
      }),
      render: (rejectPart, record) =>
        editing.rejectPart === record._id ? (
          <Form.Item name={['reject_part']}>
            <Input
              ref={inputRefRejectPart}
              addonAfter={
                <Button
                  style={{ background: '#32B6B2', color: 'white' }}
                  onMouseDown={(e) => {
                    e.preventDefault();
                    save(record);
                  }}
                >
                  <CheckOutlined />
                </Button>
              }
              value={rejectPart}
              onBlur={() => handleEditCancel()}
              onPressEnter={() => save(record)}
            />
          </Form.Item>
        ) : (
          <div>
            {rejectPart} {rejectPart ? t('rejections.euro') : ''}
          </div>
        ),
      sorter: true
    },
    {
      title: t('rejections.columns.reject_rate'),
      key: 'reject_rate',
      dataIndex: 'reject_rate',
      render: (reject_rate, record) =>
        (editing.rejectNumber || editing.rejectPart) === record._id ? (
          <Form.Item name={['reject_rate']}>
            <Input value={reject_rate} disabled />
          </Form.Item>
        ) : (
          <div>
            {reject_rate} {reject_rate ? t('rejections.percent') : ''}
          </div>
        ),
      sorter: true
    }
  ];
};
