import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Upload, Button } from 'antd';
import { useTranslation } from 'react-i18next';
import { UploadOutlined } from '@ant-design/icons/lib';
import UserContextProvider from './UserContext';

/**
 * Custom hook for generating document-related fields and inputs.
 *
 * @hook
 * @param {string} purpose - Purpose of the form ('create' or 'edit')
 * @returns {object} Object containing an array of document-related fields and loading status
 */

const useDocumentFields = (purpose) => {
  const { t } = useTranslation();
  const {
    enums,
    indentityCard,
    setIndentityCard,
    securityCard,
    setSecurityCard,
    rib,
    setRib,
    familyBook,
    setFamilyBook,
    others,
    files,
    setOthers,
    setFiles,
    uploadOrDeleteFile,
    isLoading
  } = UserContextProvider();
  const { id } = useParams();
  const [isFieldsLoading, setIsFieldsLoading] = useState(true);
  useEffect(() => {
    if (enums) setIsFieldsLoading(false);
  }, []);

  const documentFields = [
    {
      name: ['indentity_card'],
      input: (
        <Upload
          multiple
          maxCount={2}
          beforeUpload={(file) => {
            if (purpose !== 'edit') {
              const newFile = [...indentityCard];
              newFile.push(file);
              setIndentityCard(newFile);
            } else {
              uploadOrDeleteFile(file, id, 'upload', 'indentity_card');
            }
            return false;
          }}
          onRemove={(doc) => {
            if (purpose !== 'edit') {
              const index = indentityCard.indexOf(doc);
              const newFile = indentityCard.slice();
              newFile.splice(index, 1);
              setIndentityCard(newFile);
            } else {
              uploadOrDeleteFile(
                indentityCard[
                  indentityCard.findIndex(
                    (file) => file.metadata.originalName === doc.name
                  )
                ],
                id,
                'delete',
                'indentity_card'
              );
            }
          }}
          fileList={
            isLoading
              ? []
              : (indentityCard || []).map((file, index) => ({
                  uid: index,
                  name: file?.metadata?.originalName || file?.name,
                  status: 'done'
                }))
          }
        >
          <Button icon={<UploadOutlined />}>{t('buttons.upload')}</Button>
        </Upload>
      )
    },
    {
      name: ['social_security_card'],
      input: (
        <Upload
          multiple
          maxCount={2}
          beforeUpload={(file) => {
            if (purpose !== 'edit') {
              const newFile = [...securityCard];
              newFile.push(file);
              setSecurityCard(newFile);
            } else {
              uploadOrDeleteFile(file, id, 'upload', 'social_security_card');
            }
            return false;
          }}
          onRemove={(doc) => {
            const index = securityCard.indexOf(doc);
            if (purpose !== 'edit') {
              const newFile = securityCard.slice();
              newFile.splice(index, 1);
              setSecurityCard(newFile);
            } else {
              uploadOrDeleteFile(
                securityCard[
                  securityCard.findIndex(
                    (file) => file.metadata.originalName === doc.name
                  )
                ],
                id,
                'delete',
                'social_security_card'
              );
            }
          }}
          fileList={
            isLoading
              ? []
              : (securityCard || []).map((file, index) => ({
                  uid: index,
                  name: file?.metadata?.originalName || file?.name,
                  status: 'done'
                }))
          }
        >
          <Button icon={<UploadOutlined />}>{t('buttons.upload')}</Button>
        </Upload>
      )
    },
    {
      name: ['rib'],
      input: (
        <Upload
          multiple
          maxCount={2}
          beforeUpload={(file) => {
            if (purpose !== 'edit') {
              const newFile = [...rib];
              newFile.push(file);
              setRib(newFile);
            } else {
              uploadOrDeleteFile(file, id, 'upload', 'rib');
            }
            return false;
          }}
          onRemove={(doc) => {
            const index = rib.indexOf(doc);
            if (purpose !== 'edit') {
              const newFile = rib.slice();
              newFile.splice(index, 1);
              setRib(newFile);
            } else {
              uploadOrDeleteFile(
                rib[
                  rib.findIndex(
                    (file) => file.metadata.originalName === doc.name
                  )
                ],
                id,
                'delete',
                'rib'
              );
            }
          }}
          fileList={
            isLoading
              ? []
              : (rib || []).map((file, index) => ({
                  uid: index,
                  name: file?.metadata?.originalName || file?.name,
                  status: 'done'
                }))
          }
        >
          <Button icon={<UploadOutlined />}>{t('buttons.upload')}</Button>
        </Upload>
      )
    },
    {
      name: ['family_record_book'],
      input: (
        <Upload
          multiple
          maxCount={2}
          beforeUpload={(file) => {
            if (purpose !== 'edit') {
              const newFile = [...familyBook];
              newFile.push(file);
              setFamilyBook(newFile);
            } else {
              uploadOrDeleteFile(file, id, 'upload', 'family_record_book');
            }
            return false;
          }}
          onRemove={(doc) => {
            const index = familyBook.indexOf(doc);
            if (purpose !== 'edit') {
              const newFile = familyBook.slice();
              newFile.splice(index, 1);
              setFamilyBook(newFile);
            } else {
              uploadOrDeleteFile(
                familyBook[
                  familyBook.findIndex(
                    (file) => file.metadata.originalName === doc.name
                  )
                ],
                id,
                'delete',
                'family_record_book'
              );
            }
          }}
          fileList={
            isLoading
              ? []
              : (familyBook || []).map((file, index) => ({
                  uid: index,
                  name: file?.metadata?.originalName || file?.name,
                  status: 'done'
                }))
          }
        >
          <Button icon={<UploadOutlined />}>{t('buttons.upload')}</Button>
        </Upload>
      )
    },
    {
      name: ['others'],
      input: (
        <Upload
          multiple
          maxCount={5}
          beforeUpload={(file) => {
            if (purpose !== 'edit') {
              const newFile = [...others];
              newFile.push(file);
              setOthers(newFile);
            } else {
              uploadOrDeleteFile(file, id, 'upload', 'others');
            }
            return false;
          }}
          onRemove={(doc) => {
            const index = others.indexOf(doc);
            if (purpose !== 'edit') {
              const newFile = others.slice();
              newFile.splice(index, 1);
              setOthers(newFile);
            } else {
              uploadOrDeleteFile(
                others[
                  others.findIndex(
                    (file) => file.metadata.originalName === doc.name
                  )
                ],
                id,
                'delete',
                'others'
              );
            }
          }}
          fileList={
            isLoading
              ? []
              : (others || []).map((file, index) => ({
                  uid: index,
                  name: file?.metadata?.originalName || file?.name,
                  status: 'done'
                }))
          }
        >
          <Button icon={<UploadOutlined />}>{t('buttons.upload')}</Button>
        </Upload>
      )
    },
    {
      name: ['files'],
      input: (
        <Upload
          multiple
          maxCount={5}
          beforeUpload={(file) => {
            if (purpose !== 'edit') {
              const newFile = [...files];
              newFile.push(file);
              setFiles(newFile);
            } else {
              uploadOrDeleteFile(file, id, 'upload', 'files');
            }
            return false;
          }}
          onRemove={(doc) => {
            const index = files.indexOf(doc);
            if (purpose !== 'edit') {
              const newFile = files.slice();
              newFile.splice(index, 1);
              setFiles(newFile);
            } else {
              uploadOrDeleteFile(
                files[
                  files.findIndex(
                    (file) => file.metadata.originalName === doc.name
                  )
                ],
                id,
                'delete',
                'files'
              );
            }
          }}
          fileList={
            isLoading
              ? []
              : (files || []).map((file, index) => ({
                  uid: index,
                  name: file?.metadata?.originalName || file?.name,
                  status: 'done'
                }))
          }
        >
          <Button icon={<UploadOutlined />}>{t('buttons.upload')}</Button>
        </Upload>
      )
    }
  ];
  return {
    documentFields: documentFields.map((f) => f),
    isFieldsLoading
  };
};

export default useDocumentFields;
