import React, { useState, useEffect } from 'react';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
  Modal,
  Form,
  Select,
  Typography,
  DatePicker,
  Input,
  Button,
  Row
} from 'antd';
import { CloseOutlined, CheckOutlined } from '@ant-design/icons';

const { Option } = Select;
const { TextArea } = Input;

const RejectionModal = ({
  setIsRejectionModalOpen,
  isRejectionModalOpen,
  quoteEnums,
  patchStatus,
  quote
}) => {
  const { t } = useTranslation();
  const [isFieldsLoading, setIsFieldsLoading] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isQuoteRejection, setIsQuoteRejection] = useState(false);
  const [form] = Form.useForm();

  const handleSubmit = async (values) => {
    setIsSubmitting(true);
    await patchStatus(quote?.status, 'refused', values);
    setIsSubmitting(false);
  };

  useEffect(() => {
    if (quote?.status === 'QUOTE_SENT') setIsQuoteRejection(true);
    if (quoteEnums) setIsFieldsLoading(false);
  }, []);

  return (
    <Modal
      centered
      title={
        <Typography.Title
          level={4}
          style={{
            fontWeight: 300,
            textUnderlineOffset: '8px',
            color: 'var(--userColor)',
            marginBottom: 30
          }}
          underline
          strong={false}
        >
          {t(
            `quotes.modal_rejection.${isQuoteRejection ? 'quote' : 'odm'}.title`
          )}
        </Typography.Title>
      }
      footer={false}
      open={isRejectionModalOpen}
      onCancel={() => {
        setIsRejectionModalOpen(false);
      }}
      width={600}
    >
      <Form form={form} onFinish={handleSubmit}>
        <Form.Item
          name={[`rejection_${isQuoteRejection ? 'quote' : 'odm'}_date`]}
          label={t(
            `quotes.form.rejection_${isQuoteRejection ? 'quote' : 'odm'}_date`
          )}
          initialValue={dayjs()}
          rules={[{ required: true }]}
          hidden
        >
          <DatePicker format="DD-MM-YYYY" />
        </Form.Item>
        <Form.Item
          name={[`rejection_${isQuoteRejection ? 'quote' : 'odm'}_reason`]}
          label={`${t(
            `quotes.form.rejection_${isQuoteRejection ? 'quote' : 'odm'}_reason`
          )}`}
          rules={[{ required: true }]}
        >
          <Select allowClear loading={isFieldsLoading}>
            {(quoteEnums?.rejectionReasons || []).map((reason) => (
              <Option key={reason} value={reason}>
                {t(`quotes.tags.${reason}`)}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          name={[`rejection_${isQuoteRejection ? 'quote' : 'odm'}_comment`]}
          label={`${t(
            `quotes.form.rejection_${
              isQuoteRejection ? 'quote' : 'odm'
            }_comment`
          )}`}
        >
          <TextArea rows={5} />
        </Form.Item>
        <Form.Item>
          <Row justify="end">
            <Button
              style={{ margin: '0 8px' }}
              type="link"
              danger
              onClick={() => setIsRejectionModalOpen(false)}
            >
              {`${t('buttons.cancel')} `}
              <CloseOutlined />
            </Button>
            <Button
              type="primary"
              htmlType="submit"
              loading={isSubmitting}
              disabled={isSubmitting}
            >
              {`${t('buttons.validate')} `}
              <CheckOutlined />
            </Button>
          </Row>
        </Form.Item>
      </Form>
    </Modal>
  );
};

RejectionModal.propTypes = {
  patchStatus: PropTypes.func.isRequired,
  setIsRejectionModalOpen: PropTypes.func.isRequired,
  contract: PropTypes.shape({}),
  isRejectionModalOpen: PropTypes.bool.isRequired,
  quoteEnums: PropTypes.arrayOf({}),
  quote: PropTypes.shape({ status: PropTypes.string })
};

RejectionModal.defaultProps = {
  contract: [],
  quoteEnums: [],
  quote: null
};

export default RejectionModal;
