import { ColorSelector } from '../../ColorSelector';

/**
 * Custom hook to get the list content for the soft skills
 *
 * @hook
 * @param {object} softSkills - Soft skills data object
 * @returns {Array} Array of objects containing label, span, and content for each field
 */
export const useListContent = (softSkills) =>
  ['timestamp', 'technical', 'planning', 'N1'].flatMap((type) => [
    {
      label: 'quality.assessment',
      span: 24,
      content: (
        <ColorSelector value={softSkills?.[`${type}_assessment`]} isGrade />
      ),
      type
    },
    {
      label: 'quality.form.comment',
      span: 24,
      content: softSkills?.[`${type}_comment`],
      type
    }
  ]) || [];
