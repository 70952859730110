import { Input } from 'antd';

const { TextArea } = Input;

/**
 * Hook for the comment field.
 *
 * @hook
 * @returns {Object} The comment field
 * @return {Object} commentField - The comment field
 */
const useCommentField = () => {
  const commentField = {
    name: ['comment'],
    input: <TextArea rows={5} />
  };

  return {
    commentField
  };
};

export default useCommentField;
