import { Select, Input, DatePicker, Button, Upload, Space, Form } from 'antd';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { CloseCircleOutlined, UploadOutlined } from '@ant-design/icons';
import { useState } from 'react';
import { useAuthContext } from '../../contexts/AuthContext';
import { useErrorMessage } from '../../utils/errorMessage';
import CustomerServiceContextProvider from './CustomerServiceContext';
import { isAuthorized } from '../../utils/constants/authorizedMenu';
import { routes } from '../../utils/constants/adminRoutes';

const { Option } = Select;
/**
 * Custom hook for retrieving and managing technique-related fields for user data.
 *
 * @hook
 * @returns {object} An object containing technique fields and loading state
 * @property {Array} techniqueFields - An array of technique-related fields
 * @property {boolean} isFieldsLoading - A boolean indicating whether the fields are loading
 */

const useFields = (user, id, fileList, setFileList, edit, formValues) => {
  const { t } = useTranslation();
  const isAdminOrLeader =
    user.role.split(':')[0] === 'admins' ||
    user.role.split(':')[1] === 'LEADER-USER';
  const { dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();
  const { TextArea } = Input;
  const [isLoading, setIsLoading] = useState(false);
  const ruleMessage = t('errors.form.REQUIRED');
  const { customerServiceEnums, customers, users } =
    CustomerServiceContextProvider();

  const hasAccess = (purpose) =>
    isAuthorized(`${routes.CUSTOMERSERVICES}/edit`, user?.role, purpose);

  const removeFile = async (fileId) => {
    setIsLoading(true);
    try {
      await dispatchAPI('DELETE', {
        url: `/customerservices/remove-file/${id}/${fileId}`
      });
    } catch (e) {
      message(e);
    }
    setIsLoading(false);
  };

  const beforeUpload = (file) => {
    setFileList((prevFiles) => [...prevFiles, file]);
    return false;
  };

  const onRemove = (file) => {
    if (!hasAccess('remove_doc')) {
      message('403');
      return;
    }
    if (file._id) {
      setFileList((prevFiles) => prevFiles.filter((f) => f._id !== file._id));
      removeFile(file._id);
    } else {
      setFileList((prevFiles) => prevFiles.filter((f) => f.uid !== file.uid));
    }
  };

  const onSearch = (input, option) => {
    const childrenText = Array.isArray(option.children)
      ? option.children.join(' ')
      : option.children;
    return childrenText.toLowerCase().includes(input.toLowerCase());
  };

  const fields = [
    {
      label: 'name',
      name: 'name',
      rules: [{ required: true, message: ruleMessage }],
      input: <Input type="text" />
    },
    {
      label: 'status',
      name: 'status',
      rules: [{ required: true, message: ruleMessage }],
      input: (
        <Select>
          {(customerServiceEnums.status || []).map((status) => (
            <Option key={status} value={status}>
              {t(`customerServices.form.enums.${status}`)}
            </Option>
          ))}
        </Select>
      )
    },
    {
      label: 'customer',
      name: 'customer',
      rules: [{ required: true, message: ruleMessage }],
      input: (
        <Select
          showSearch
          placeholder={t('statistics.placeholder.service')}
          filterOption={(input, option) => onSearch(input, option)}
          disabled={edit === 'edit'}
        >
          {(customers || []).map((customer) => (
            <Option key={customer._id} value={customer._id}>
              {customer.ps_number} {customer.company_name}
            </Option>
          ))}
        </Select>
      )
    },
    {
      label: 'recorded_time',
      name: 'recorded_time',
      input: (
        <Select>
          {(customerServiceEnums.mins || []).map((min) => (
            <Option key={min} value={min}>
              {t(`customerServices.form.enums.${min}`)}
            </Option>
          ))}
        </Select>
      )
    },
    {
      label: 'created_by',
      name: 'created_by',
      rules: [{ required: true, message: ruleMessage }],
      initialValue: user?._id,
      input: (
        <Select disabled>
          {(users || []).map((transmitter) => (
            <Option key={transmitter._id} value={transmitter._id}>
              {transmitter.first_name}{' '}
              {transmitter.usage_last_name || transmitter.last_name}
            </Option>
          ))}
        </Select>
      )
    },
    {
      label: 'recipients',
      name: 'recipients',
      rules: [{ required: true, message: ruleMessage }],
      input: (
        <Select
          mode="multiple"
          showSearch
          allowClear
          optionFilterProp="children"
          loading={isLoading}
          filterOption={(input, option) =>
            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
          }
          filterSort={(optionA, optionB) =>
            optionA?.label
              ?.toLowerCase()
              ?.localeCompare(optionB?.label?.toLowerCase())
          }
          options={(users || []).map((recipient) => ({
            label: `${recipient.first_name} ${
              recipient.usage_last_name || recipient.last_name
            }`,
            value: recipient?._id
          }))}
        />
      )
    },
    {
      label: 'completion_date',
      name: 'completion_date',
      input: (
        <DatePicker
          format="DD-MM-YYYY"
          disabledDate={(current) => {
            if (current) return dayjs().add(-1, 'days') >= current;
            return false;
          }}
        />
      )
    },
    {
      label: 'origin',
      name: 'origin',
      rules: [{ required: true, message: ruleMessage }],
      input: (
        <Select>
          {(customerServiceEnums.origins || []).map((origin) => (
            <Option key={origin} value={origin}>
              {t(`customerServices.form.enums.${origin}`)}
            </Option>
          ))}
        </Select>
      )
    },
    {
      label: 'description',
      name: 'description',
      input: <TextArea rows={6} disabled={edit === 'edit'} />
    },
    {
      name: ['comment'],
      input: (
        <Form.List name="comments">
          {(comments, { add, remove }) => (
            <>
              {comments.map((field, index) => (
                <div key={field.name}>
                  <Form.Item
                    {...field}
                    key={[field.name, 'content']}
                    name={[field.name, 'content']}
                    label={` ${index + 1}`}
                  >
                    <Input.TextArea
                      rows={6}
                      disabled={
                        !isAdminOrLeader &&
                        !!formValues?.comments?.[index]?._id &&
                        edit === 'edit'
                      }
                    />
                  </Form.Item>
                  <CloseCircleOutlined
                    style={{
                      position: 'absolute',
                      top: '5px',
                      right: '5px',
                      fontSize: '16px',
                      color: 'red',
                      cursor: 'pointer'
                    }}
                    onClick={() => isAdminOrLeader && remove(field.name)}
                  />
                </div>
              ))}
              <Form.Item>
                <Button type="primary" onClick={() => add()}>
                  {t('customers.form.comments.addComment')}
                </Button>
              </Form.Item>
            </>
          )}
        </Form.List>
      )
    },
    {
      name: ['files'],
      input: (
        <Space
          direction="vertical"
          style={{
            width: '100%'
          }}
          size="large"
        >
          <Upload
            beforeUpload={beforeUpload}
            onRemove={onRemove}
            fileList={
              isLoading
                ? []
                : (fileList || []).map((file, index) => ({
                    uid: index,
                    name: file?.metadata?.originalName || file?.name,
                    status: 'done',
                    ...file
                  }))
            }
            listType="picture"
            maxCount={5}
            multiple
          >
            <Button icon={<UploadOutlined />}>{t('buttons.upload_2')}</Button>
          </Upload>
        </Space>
      )
    }
  ];
  return { fields };
};

export default useFields;
