import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Card,
  Row,
  Flex,
  Col,
  Button,
  Typography,
  Tag,
  Drawer,
  Modal,
  Input
} from 'antd';
import { useParams, Link, useNavigate, useLocation } from 'react-router-dom';
import {
  ExportOutlined,
  CheckOutlined,
  CloseOutlined,
  ContainerOutlined
} from '@ant-design/icons';
import dayjs from 'dayjs';
import {
  ContentCustom,
  CreateUpdateContainer,
  PageHeaderCustom
} from '../../../components/index';
import { useErrorMessage } from '../../../utils/errorMessage';
import useOccupancyChartContext from '../OccupancyChartContext';
import { DescriptionList } from '../../../components/DescriptionList/DescriptionList';
import { getListContent } from './utils/getListContent';
import { FilesManagement } from '../../../components/FilesManagement';
import useFields from '../../calendar/fields';
import { useAuthContext } from '../../../contexts/AuthContext';
import generateHistoric from '../../../utils/generateHistoric';
import { isAuthorized } from '../../../utils/constants/authorizedMenu';
import { CustomSpin } from '../../../components/CustomSpin/CustomSpin';
import { isEmptyObject } from '../../../utils/objects';

/**
 * A component for displaying details of an absence or vacation event, including its status, user information,
 * and related content.
 *
 * @component
 * @returns {JSX.Element} The JSX element representing the absence or vacation event details.
 */

export const ShowOccupancy = () => {
  const { id } = useParams();
  const { t } = useTranslation();
  const { getDataFromId, changeStatus, refresh, setRefresh, users } =
    useOccupancyChartContext();
  const { message } = useErrorMessage();
  const { dispatchAPI, user } = useAuthContext();
  const location = useLocation();
  const { pathname } = location;
  const [isLoading, setIsLoading] = useState(true);
  const [absenceVacation, setAbsenceVacation] = useState();
  const [listContent, setListContent] = useState([]);
  const [showDrawer, setShowDrawer] = useState(false);
  const [fileList, setFileList] = useState(absenceVacation?.file || null);
  const [refreshCUContainer, setRefreshCUContainer] = useState(false);
  const [statusChangeModal, setStatusChangeModal] = useState(false);
  const [statusChangeInput, setStatusChangeInput] = useState('');
  const [startPeriodEdit, setStartPeriodEdit] = useState(null);

  const hasAccess = (purpose) => isAuthorized(pathname, user?.role, purpose);

  const uploadOrDeleteFile = async (file, localId, type, key) => {
    const formData = new FormData();
    formData.append('values', JSON.stringify({ file: [file._id] }));
    if (type !== 'delete') formData.append(`${key}`, file);
    try {
      const { data } = await dispatchAPI('PATCH', {
        url: `/events/file/${localId}?type=${type}&key=${key}&fileId=${file._id}`,
        body: formData
      });
      setFileList(() => {
        if (type === 'delete') {
          return null;
        }
        return [{ ...file, _id: data._id }];
      });
      setRefreshCUContainer(!refresh);
    } catch (e) {
      message(e);
    }
  };

  const { fields } = useFields(
    users,
    'editAll',
    setFileList,
    fileList,
    id,
    uploadOrDeleteFile,
    false,
    startPeriodEdit
  );

  const navigate = useNavigate();

  const config = {
    onGetResource: {
      setFields: (data) => {
        setFileList(data.file || []);
        setStartPeriodEdit(dayjs(data?.start_date));
        return {
          ...data,
          start_date: data.start_date && dayjs(data.start_date),
          end_date: data.end_date && dayjs(data.end_date),
          date: data.date && dayjs(data.date),
          startTime: data.startTime && dayjs(data.startTime),
          endTime: data.endTime && dayjs(data.endTime)
        };
      }
    },
    onUpdateResource: {
      setBody: (data) => ({
        ...data,
        date: data.date && dayjs(data.date)
      })
    }
  };

  const getAbsenceVacation = async () => {
    setIsLoading(true);
    try {
      const data = await getDataFromId(id);
      setAbsenceVacation(data);
    } catch (e) {
      message(e);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    (async () => {
      await getAbsenceVacation();
    })();
  }, [refresh, id]);

  useEffect(() => {
    if (absenceVacation) {
      setFileList(absenceVacation?.file || []);
      setListContent(getListContent(t, absenceVacation));
    }
  }, [absenceVacation]);

  return isLoading ? (
    <ContentCustom>
      <CustomSpin />
    </ContentCustom>
  ) : (
    <ContentCustom>
      <PageHeaderCustom
        title={
          !isEmptyObject && (
            <Flex gap={8} align="center">
              {t(`events.titles.details.${absenceVacation?.type}`)}
              <Tag>{t(`events.form.enums.${absenceVacation?.status}`)}</Tag>
            </Flex>
          )
        }
        extra={
          !isEmptyObject && (
            <Flex justify="end" gap={8}>
              <Link to={`/users/show/${absenceVacation?.user?._id}`}>
                <Button type="primary" icon={<ExportOutlined size={24} />}>
                  {t('events.buttons.see_user')}
                </Button>
              </Link>
              {hasAccess('validate') &&
                absenceVacation?.status !== 'APPROVED' && (
                  <Button
                    type="primary"
                    icon={<CheckOutlined size={24} />}
                    onClick={() => changeStatus(id, 'APPROVED')}
                  >
                    {t('events.buttons.validate')}
                  </Button>
                )}
              {statusChangeModal && (
                <Modal
                  title={t('events.titles.modal.status')}
                  open={statusChangeModal}
                  onOk={() => {
                    changeStatus(id, 'REJECTED', statusChangeInput);
                    setStatusChangeModal(false);
                  }}
                  onCancel={() => setStatusChangeModal(false)}
                >
                  <Flex justify="center">
                    <Input
                      onChange={(e) => setStatusChangeInput(e.target.value)}
                    />
                  </Flex>
                </Modal>
              )}
              {hasAccess('refuse') &&
                absenceVacation?.status !== 'REJECTED' &&
                absenceVacation?.type !== 'BANK_HOLIDAY' && (
                  <Button
                    type="danger"
                    danger
                    icon={<CloseOutlined size={24} />}
                    onClick={() => setStatusChangeModal(true)}
                  >
                    {t('events.buttons.reject')}
                  </Button>
                )}
              {hasAccess('archive') && (
                <Button
                  type="link"
                  danger
                  icon={<ContainerOutlined size={24} />}
                  onClick={() => {
                    changeStatus(id, 'ARCHIVED');
                    navigate(-1);
                  }}
                >
                  {t('events.buttons.archive')}
                </Button>
              )}
            </Flex>
          )
        }
      />
      {showDrawer && (
        <Drawer
          centered
          title={t(`events.titles.edit.${absenceVacation?.type}`)}
          open={showDrawer}
          onClose={() => {
            setShowDrawer(false);
          }}
          width={700}
          footer={false}
        >
          <CreateUpdateContainer
            fields={fields}
            purpose="edit"
            baseUrl="events/absence-vacation"
            resource="events"
            isOverlay
            populate="file"
            customNavigate={() => {
              setShowDrawer(false);
              setRefresh(!refresh);
            }}
            withHeader={false}
            idFromOverlay={id}
            config={config}
            refresh={refreshCUContainer}
            drawer
          />
        </Drawer>
      )}
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <Card>
            <DescriptionList data={listContent} translate columns={24} />
          </Card>
        </Col>
        <Col xs={24} xl={8}>
          <Card title={t('events.titles.history')}>
            <Typography.Text>
              {generateHistoric(absenceVacation?.historic || [], t)}
            </Typography.Text>
          </Card>
        </Col>
        <Col xs={24} xl={8}>
          <Card title={t('events.titles.comment')}>
            {absenceVacation?.comment || '-'}
            <br />
            {absenceVacation?.reviewed?.reason || ''}
          </Card>
        </Col>
        <Col xs={24} xl={8}>
          <FilesManagement purpose="show" documents={fileList} border />
        </Col>
      </Row>
    </ContentCustom>
  );
};
