import parse from 'html-react-parser';

export const modules = {
  toolbar: [
    ['bold', 'italic', 'underline', 'strike'],
    [{ list: 'ordered' }, { list: 'bullet' }],
    [{ header: [1, 2, 3, 4, 5, 6, false] }],
    [{ font: [] }],
    [{ align: [] }],
    ['link', 'image'],
    [{ color: [] }, { background: [] }]
  ]
};

export const formattedContent = (text) => {
  const pattern = /<.*?>/g;
  return pattern.test(text) ? parse(text) : text;
};
