import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Input, Select } from 'antd';
import QuittanceContextProvider from '../QuittanceContext';

const { Option } = Select;

export const useServiceFields = (customer, quittanceId) => {
  const { t } = useTranslation();
  const { serviceEnums } = QuittanceContextProvider();
  const [isFieldsLoading, setIsFieldsLoading] = useState(true);

  const onsearch = (input, option) => {
    const result = option?.children.toLowerCase();
    return result.includes(input.toLowerCase());
  };

  useEffect(() => {
    if (serviceEnums) setIsFieldsLoading(false);
  }, []);

  const fields = [
    {
      name: ['ps_number'],
      initialValue: customer?.ps_number,
      hidden: true,
      input: <Input />
    },
    {
      name: ['customer'],
      hidden: true
    },
    {
      name: ['macro_ps'],
      hidden: true
    },
    {
      name: ['micro_ps'],
      hidden: true
    },
    {
      name: ['from_scratch'],
      initialValue: true,
      hidden: true
    },
    {
      name: ['quittance'],
      initialValue: quittanceId,
      hidden: true
    },
    {
      name: ['_id'],
      hidden: true
    },
    {
      name: ['type'],
      label: `${t('detail.type')}`,
      rules: [{ required: true }],
      input: (
        <Select
          allowClear
          showSearch
          loading={isFieldsLoading}
          filterOption={(input, option) => onsearch(input, option)}
        >
          {serviceEnums?.types?.map((type) => (
            <Option key={type} value={type}>
              {t(`quotes.tags.${type}`)}
            </Option>
          ))}
        </Select>
      )
    },
    {
      name: ['title'],
      rules: [{ required: true }],
      label: t('detail.title')
    },
    {
      name: ['amount'],
      rules: [{ required: true }],
      label: t('detail.amount'),
      input: <Input type="number" suffix="€" />
    },
    {
      name: ['total_hours_service'],
      rules: [{ required: true }],
      label: t('detail.total_hours_service'),
      input: <Input type="number" />
    },
    {
      name: ['fee_discount'],
      rules: [{ required: true }],
      label: t('detail.fee_discount'),
      input: <Input type="number" suffix="€" />
    },
    {
      name: ['total'],
      rules: [{ required: true }],
      label: t('detail.total'),
      input: <Input type="number" suffix="€" disabled />
    },
    {
      name: ['taxes'],
      rules: [{ required: true }],
      input: <Input type="number" suffix="%" disabled />
    },
    {
      name: ['total_with_taxes'],
      rules: [{ required: true }],
      label: t('detail.total_with_taxes'),
      input: <Input type="number" suffix="€" disabled />
    },
    {
      name: ['accounting_account'],
      rules: [{ required: true }],
      label: t('detail.accounting_account'),
      input: <Input disabled />
    }
  ];

  return {
    fields,
    isFieldsLoading
  };
};
