/**
 * Get the url for the remaining tasks of a given task type.
 *
 * @function
 * @param {string} task - The type of task to get the remaining tasks for.
 * @returns {string} The url for the remaining tasks of the given task type.
 */
const getUrl = (task) => {
  switch (task) {
    case 'ABSENCE_VACATION':
      return '/events/absence-vacation?status=PENDING';
    case 'RH':
      return '/events/absence-vacation?status=PENDING';
    default:
      return '';
  }
};

/**
 * Get the remaining tasks of a given task type.
 *
 * @function
 * @param {function} dispatchAPI - The dispatchAPI function.
 * @param {string} task - The type of task to get the remaining tasks for.
 * @returns {Array} The remaining tasks of the given task type.
 * @throws {Error} The error which occurred during the process.
 */
export const getRemainingTasks = async (dispatchAPI, task) => {
  try {
    const url = getUrl(task);
    if (!url) return [];
    const { data } = await dispatchAPI('GET', {
      url
    });
    return data || [];
  } catch (e) {
    return Error(e);
  }
};
