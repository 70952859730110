import PropTypes from 'prop-types';
import { Col, Card, Tabs } from 'antd';
import { useTranslation } from 'react-i18next';
import { useAuthContext } from '../../../../contexts/AuthContext';
import { ListInterventions } from './ListInterventions';
import { ListQuittances } from './ListQuittances';

/**
 * Component for displaying invoices and interventions informations of a contract in a card format of 2 datatables.
 *
 * @component
 * @returns {JSX.Element} Component JSX
 */

export const ShowInvoicesAndInterventions = ({ id }) => {
  const { t } = useTranslation();
  const { user } = useAuthContext();

  const items = [
    ...(!user?.role.startsWith('users')
      ? [
          {
            label: t('contracts.show.titles.invoices'),
            key: '1',
            children: <ListQuittances id={id} />
          }
        ]
      : []),
    {
      label: t('contracts.show.titles.interventions'),
      key: '2',
      children: <ListInterventions id={id} />
    }
  ];

  return (
    <Col span="24">
      <Card>
        <Tabs
          defaultActiveKey="1"
          type="card"
          size="large"
          items={items}
          className="card-tabs"
        />
      </Card>
    </Col>
  );
};

ShowInvoicesAndInterventions.propTypes = {
  id: PropTypes.string.isRequired
};
