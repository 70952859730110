import { Card, Col, Row, Steps } from 'antd';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { formatValue } from '../../utils/formatNumber';

/**
 * Component for displaying quote comment.
 *
 * @component
 * @param {object} props - Component props
 * @param {object} props.data - Quote data containing comment
 * @returns {JSX.Element} Component JSX
 */

export const ShowHistoric = ({ data }) => {
  const { t } = useTranslation();

  const steps = (data?.historic || [])
    .map((historic) => ({
      title: `${t(`historic.type.${historic.type}`)}${
        historic?.status ? ` => ${t(`historic.status.${historic.status}`)}` : ''
      }`,
      description: `${t('historic.description', {
        date: formatValue(historic.date, 'date'),
        time: formatValue(historic.date, 'time'),
        author: `${historic?.author?.first_name} ${
          historic?.author?.usage_last_name || historic?.author?.last_name
        }`
      })} `
    }))
    .reverse();

  return (
    <Col xs={24} xl={12}>
      <Card title={t('customerServices.labels.history')}>
        <Row align="middle" style={{ marginTop: 30 }}>
          <Steps
            direction="vertical"
            items={steps}
            progressDot
            current={steps.length - 1}
          />
        </Row>
      </Card>
    </Col>
  );
};

ShowHistoric.propTypes = {
  data: PropTypes.shape({
    historic: PropTypes.arrayOf(PropTypes.shape({})),
    comment: PropTypes.string
  })
};

ShowHistoric.defaultProps = {
  data: undefined
};
