import { useState } from 'react';
import PropTypes from 'prop-types';
import { Row, Col, message, Button } from 'antd';
import { CloseOutlined, ExportOutlined } from '@ant-design/icons';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useAuthContext } from '../../../../contexts/AuthContext';
import { getLink } from './utils';
import { showSuccessMessage } from '../../../../utils/showSuccessMessage';

/**
 * NotificationCard is a React component that displays a notification card.
 *
 * @component
 * @param {object} notification - The notification data to be displayed.
 * @param {function} getNotifications - A function to retrieve notifications.
 * @returns {ReactNode} - A ReactNode representing the NotificationCard component.
 */
export const NotificationCard = ({ notification, getNotifications }) => {
  const { t } = useTranslation();
  const { dispatchAPI } = useAuthContext();

  const [isRead, setIsRead] = useState(notification.isRead);

  const { content, translation_key, created_at, type } = notification;

  const patchNotification = async () => {
    try {
      await dispatchAPI('PATCH', {
        url: `/notifications/${notification._id}`,
        body: { isRead: !isRead }
      });
      setIsRead(!isRead);
    } catch (error) {
      if (error.message) message.error(error.message);
    }
  };

  const deleteNotification = async () => {
    try {
      await dispatchAPI('DELETE', {
        url: `/notifications/${notification._id}`
      });
      await getNotifications();
      showSuccessMessage(t, 'notification', 'archive');
    } catch (error) {
      if (error.message) message.error(error.message);
    }
  };

  const handleCardClick = (e) => {
    patchNotification();
    e.stopPropagation();
  };

  const handleLinkClick = (e) => {
    if (!isRead) {
      patchNotification();
    }
    e.stopPropagation();
  };

  const getTimeDisplay = () => {
    const currentDate = new Date();
    const createdAtDate = new Date(created_at);

    const timeDifference = currentDate - createdAtDate;

    const minutesDifference = Math.floor(timeDifference / (1000 * 60));
    const hoursDifference = Math.floor(timeDifference / (1000 * 60 * 60));

    if (minutesDifference < 60) {
      return `Il y a ${minutesDifference} minutes`;
    }
    if (hoursDifference < 24) {
      return `Il y a ${hoursDifference} heures`;
    }
    return moment(created_at).format('DD-MM-YYYY');
  };

  return (
    <Row className="notification-card" onClick={handleCardClick}>
      <Col>
        <Row className={`${isRead ? 'blank' : 'red'}-circle`} />
      </Col>
      <Col span={18}>
        <Row>{t(translation_key, content)}</Row>
        <Row className="notification-time">{getTimeDisplay()}</Row>
      </Col>
      <Col onClick={handleLinkClick}>
        <Link to={getLink(type, content._id)}>
          <Button
            type="link"
            icon={<ExportOutlined style={{ fontSize: 18 }} />}
          />
        </Link>
      </Col>
      <Col>
        <Button
          type="link"
          icon={<CloseOutlined style={{ fontSize: 18 }} />}
          onClick={deleteNotification}
        />
      </Col>
    </Row>
  );
};

NotificationCard.propTypes = {
  notification: PropTypes.shape({
    _id: PropTypes.string,
    type: PropTypes.number,
    content: PropTypes.shape({
      _id: PropTypes.string
    }),
    translation_key: PropTypes.string,
    created_at: PropTypes.string,
    isRead: PropTypes.bool
  }).isRequired,
  getNotifications: PropTypes.func
};

NotificationCard.defaultProps = {
  getNotifications: () => {}
};
