import PropTypes from 'prop-types';
import { Card } from 'antd';
import { useTranslation } from 'react-i18next';
import { DescriptionList } from '../../../../components';
import { useListContent } from './listContent';
import { TestFile } from './TestFile';

/**
 * TestCard component that shows the test information of the quality control
 *
 * @component
 * @param {object} test - Test object
 * @return {component} - TestCard component
 */
export const TestCard = ({ test }) => {
  const { t } = useTranslation();
  const listContent = useListContent(test);

  return (
    <Card title={t('quality.form.test.title')}>
      <DescriptionList data={listContent} translate columns={24} />
      <TestFile testFile={test.test_file} />
    </Card>
  );
};

TestCard.propTypes = {
  test: PropTypes.shape({
    test_file: PropTypes.shape({})
  })
};

TestCard.defaultProps = {
  test: {}
};
