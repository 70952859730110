import { Input, InputNumber } from 'antd';
import { ColorSelector } from '../ColorSelector';
import useQualityControlContext from '../QualityControlContext';

const { TextArea } = Input;

/**
 * Hook for the conversion rate fields.
 *
 * @hook
 * @returns {Object} The conversion rate fields
 */
const useconversionRateFields = () => {
  const { conversionRateAssessment, setConversionRateAssessment } =
    useQualityControlContext();
  const conversionRateFields = [
    {
      name: ['conversion_rate', 'percentage'],
      input: <InputNumber addonAfter="%" />
    },
    {
      name: ['conversion_rate', 'comment'],
      input: <TextArea rows={5} />
    },
    {
      name: ['conversion_rate', 'assessment'],
      input: (
        <ColorSelector
          color={conversionRateAssessment}
          setColor={setConversionRateAssessment}
        />
      )
    }
  ];
  return {
    conversionRateFields: conversionRateFields.map((f) => f)
  };
};

export default useconversionRateFields;
