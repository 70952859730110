import { useEffect, useState } from 'react';
import moment from 'moment';
import { DatePicker, Form, Input, Button, Checkbox } from 'antd';
import { useTranslation } from 'react-i18next';
import { CloseCircleOutlined } from '@ant-design/icons/lib';
import UserContextProvider from './UserContext';

const { TextArea } = Input;

/**
 * Custom hook for generating health protection-related fields and inputs.
 *
 * @hook
 * @returns {object} Object containing an array of health protection-related fields and loading status
 */

const useHealthProtectionFields = () => {
  const { t } = useTranslation();
  const { enums, formValues } = UserContextProvider();
  const [isFieldsLoading, setIsFieldsLoading] = useState(true);
  const format = 'DD-MM-YYYY';

  useEffect(() => {
    if (enums) setIsFieldsLoading(false);
  }, []);

  const healthProtectionFields = [
    {
      name: ['rdws_periods'],
      input: (
        <Form.List name="rdws_periods" initialValue={[]}>
          {(fields, { add, remove }) => (
            <>
              {fields.map((field) => (
                <div key={field.name}>
                  <Form.Item
                    {...field}
                    key={[field.name, 'start_date']}
                    name={[field.name, 'start_date']}
                    label={t('users.form.rdws_v.start_date')}
                  >
                    <DatePicker
                      format={format}
                      disabledDate={(current) => current >= moment()}
                    />
                  </Form.Item>
                  <CloseCircleOutlined
                    style={{
                      position: 'absolute',
                      top: '5px',
                      right: '5px',
                      fontSize: '16px',
                      color: 'red',
                      cursor: 'pointer'
                    }}
                    onClick={() => remove(field.name)}
                  />
                  <Form.Item
                    {...field}
                    key={[field.name, 'end_date']}
                    name={[field.name, 'end_date']}
                    label={t('users.form.rdws_v.end_date')}
                  >
                    <DatePicker
                      format={format}
                      disabledDate={(current) => current >= moment()}
                    />
                  </Form.Item>
                </div>
              ))}
              <Form.Item>
                <Button type="primary" onClick={() => add()}>
                  {t('users.form.rdws_v.period')}
                </Button>
              </Form.Item>
            </>
          )}
        </Form.List>
      )
    },
    {
      name: ['medical_check_up'],
      input: (
        <Form.List name="medical_check_up" initialValue={[]}>
          {(fields, { add, remove }) => (
            <>
              {fields.map((field) => (
                <div key={field.name}>
                  <Form.Item
                    {...field}
                    key={[field.name, 'date']}
                    name={[field.name, 'date']}
                    label={t('users.form.medical_check_up_v.date')}
                  >
                    <DatePicker
                      format={format}
                      disabledDate={(current) => current >= moment()}
                    />
                  </Form.Item>
                  <CloseCircleOutlined
                    style={{
                      position: 'absolute',
                      top: '5px',
                      right: '5px',
                      fontSize: '16px',
                      color: 'red',
                      cursor: 'pointer'
                    }}
                    onClick={() => remove(field.name)}
                  />
                  <Form.Item
                    {...field}
                    key={[field.name, 'comment']}
                    name={[field.name, 'comment']}
                    label={t('users.form.medical_check_up_v.comment')}
                  >
                    <TextArea rows={5} />
                  </Form.Item>
                </div>
              ))}
              <Form.Item>
                <Button type="primary" onClick={() => add()}>
                  {t('users.form.buttons.addMedicalCheckup')}
                </Button>
              </Form.Item>
            </>
          )}
        </Form.List>
      )
    },
    {
      name: ['health_insurance'],
      valuePropName: 'checked',
      input: <Checkbox />
    },
    ...(formValues?.health_insurance
      ? [
          {
            name: ['health_insurance_start_date'],
            input: (
              <DatePicker
                format={format}
                disabledDate={(current) => current > moment()}
              />
            )
          },
          {
            name: ['health_insurance_end_date'],
            input: (
              <DatePicker
                format={format}
                disabledDate={(current) => current > moment()}
              />
            )
          },
          {
            name: ['health_insurance_end_comment'],
            input: <TextArea rows={5} />
          }
        ]
      : []),
    {
      name: ['healthcare_coverage'],
      valuePropName: 'checked',
      input: <Checkbox />
    },
    ...(formValues?.healthcare_coverage
      ? [
          {
            name: ['healthcare_coverage_start_date'],
            input: (
              <DatePicker
                format={format}
                disabledDate={(current) => current > moment()}
              />
            )
          },
          {
            name: ['healthcare_coverage_end_date'],
            input: (
              <DatePicker
                format={format}
                disabledDate={(current) => current > moment()}
              />
            )
          },
          {
            name: ['healthcare_coverage_end_comment'],
            input: <TextArea rows={5} />
          }
        ]
      : [])
  ];
  return {
    healthProtectionFields: healthProtectionFields.map((f) => f),
    isFieldsLoading
  };
};

export default useHealthProtectionFields;
