import { Tag } from 'antd';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  customerStatus,
  userEmployer
} from '../../../utils/constants/tagColors';
import { routes } from '../../../utils/constants/adminRoutes';
import { formatValue } from '../../../utils/formatNumber';

/**
 * Custom hook for generating administration content data for displaying quote administration details in a list format.
 *
 * @hook
 * @param {object} data - User data object
 * @returns {Array} Array of objects containing label, span, and content for each field
 */

export const usePrincipalInformationsContent = (data = {}) => {
  const { t } = useTranslation();

  const { code, customer, micro_ps, created_at, sale } = data;

  return [
    {
      label: 'quotes.form.code',
      span: 6,
      content: code || '-'
    },
    {
      label: 'quotes.form.status',
      span: 6,
      content: (
        <Tag color={customerStatus[customer?.status]}>
          {t(`customers.status.${customer?.status || 'N/A'}`)}
        </Tag>
      )
    },
    {
      label: 'quotes.form.contract_type',
      span: 6,
      content: (
        <Tag color={userEmployer[customer?.company_customer]}>
          {t(`users.employers.${customer?.company || 'N/A'}`)}
        </Tag>
      )
    },
    {
      label: 'quotes.columns.micro_ps',
      span: 6,
      content: customer?.ps_number || '-'
    },
    {
      label: 'quotes.form.company_name',
      span: 6,
      content: (
        <Link
          to={{
            pathname: `${routes.CUSTOMERS}/show/${customer?._id}`
          }}
        >
          {customer?.company_name || '-'}
        </Link>
      )
    },
    {
      label: 'quotes.form.micro_ps',
      span: 6,
      content: micro_ps?.title || '-'
    },
    {
      label: 'quotes.form.created_at',
      span: 6,
      content: formatValue(created_at, 'date') || '-'
    },
    {
      label: 'quotes.form.sale',
      span: 6,
      content: `${sale?.first_name || '-'} ${
        sale?.usage_last_name || sale?.last_name || '-'
      }`
    }
  ];
};
