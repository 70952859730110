import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { DatePicker, Typography, Col } from 'antd';
import { useAuthContext } from '../../contexts/AuthContext';
import { useErrorMessage } from '../../utils/errorMessage';
import { ListResource } from '../../components';
import { useColumns } from './interventionsColumns';
import { useFormatter } from '../../utils/formatter';
import { routes } from '../../utils/constants/adminRoutes';

/**
 * Component for listing interventions with filtering options.
 *
 * @component
 * @returns {JSX.Element} Rendered ListInterventions component
 */

export const ListInterventions = ({ origin }) => {
  const { t } = useTranslation();
  const { dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();
  const { formattedData } = useFormatter();
  const [interventionEnums, setInterventionEnums] = useState({});
  const columns = useColumns(interventionEnums);
  const [filterDate, setFilterDate] = useState(dayjs());

  const getInterventionEnums = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/interventions/enums`
      });
      setInterventionEnums(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  useEffect(() => {
    (async () => {
      await getInterventionEnums();
    })();
  }, []);

  return (
    <Col>
      <Typography.Title
        level={4}
        style={{
          color: 'var(--userColor)'
        }}
        strong={false}
      >
        {t(`messages.success.interventions`)}
      </Typography.Title>
      <ListResource
        withPageHeader={false}
        resourceName="interventions"
        columns={columns}
        populate="contract,user"
        resourceModelName="Intervention"
        withImportButton={false}
        editAction={false}
        formatter={formattedData}
        withCreateButton={false}
        onDoubleClickAction={origin !== 'home'}
        deleteAction={false}
        showAction={{
          pathname: (record) => `${routes.INTERVENTIONS}/show/${record?._id}`
        }}
        extraQuery={`${
          filterDate
            ? `&period_start>=${dayjs(
                new Date(filterDate).setHours(0, 0, 0, 0)
              ).toISOString()}&period_start<=${dayjs(
                new Date(filterDate).setHours(23, 59, 59, 999)
              ).toISOString()}`
            : ''
        }`}
        extra
        extraFilters={
          <DatePicker
            style={{ marginLeft: '20px' }}
            onChange={setFilterDate}
            value={filterDate}
            format="DD-MM-YYYY"
          />
        }
      />
    </Col>
  );
};

ListInterventions.propTypes = {
  origin: PropTypes.string
};

ListInterventions.defaultProps = {
  origin: undefined
};
