import { useEffect, useState } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Button, Dropdown, Flex, Input } from 'antd';
import { MenuOutlined, SearchOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

import { ExportButton } from '../ExportButton/ExportButton';
import { ImportButton } from '../importButton';
import { Datatable } from '../DataTable/Datatable';
import { PageHeaderCustom } from '../PageHeader/PageHeader';
import { ContentCustom } from '../ContentCustom/ContentCustom';
import { AddIcon } from '../../utils/constants/customIcons';
import { isAuthorized } from '../../utils/constants/authorizedMenu';
import { useAuthContext } from '../../contexts/AuthContext';
import { pathSearches, routes } from '../../utils/constants/adminRoutes';
import { getKeyByValue } from '../../utils/objects';

const { Search } = Input;

/**
 * A reusable component for displaying a list of resources with various options and actions.
 *
 * @component
 * @param {Object} props - Component props
 * @param {string} props.resourceName - Name of the resource to display (e.g., "customers")
 * @param {string} props.tradKey - Key for translation (defaults to resourceName.title)
 * @param {string} props.dataToFetch - Name of the data to fetch (defaults to resourceName)
 * @param {Array} props.columns - Columns configuration for the DataTable
 * @param {ReactNode} props.customActionColumn - Custom action column for DataTable
 * @param {Array} props.headers - Headers for export functionality
 * @param {ReactNode} props.children - Additional content to display
 * @param {string} props.populate - Field to populate during data fetch
 * @param {string} props.extraQuery - Additional query parameters for data fetch
 * @param {ReactNode} props.extraHeader - Additional content for page header
 * @param {ReactNode} props.extraButtons - Additional buttons for top right corner
 * @param {string} props.exportUrl - URL for exporting data
 * @param {boolean} props.withCreateButton - Show create button in the top right corner
 * @param {boolean} props.withUploadButton - Show upload button in the top right corner
 * @param {boolean} props.withPageHeader - Show page header
 * @param {boolean} props.withSearchBar - Show search bar
 * @param {boolean} props.forceRefresh - Force refresh data
 * @param {string} props.resourceModelName - Model name of the resource
 * @param {boolean} props.extraEditCondition - Condition for editAction
 * @param {Object} props.scroll - Scroll configuration for DataTable
 * @param {boolean} props.showAction - Enable show action
 * @param {boolean} props.duplicateAction - Enable duplicate action
 * @param {boolean} props.printAction - Enable print action
 * @param {boolean} props.deleteAction - Enable delete action
 * @param {boolean} props.onDoubleClickAction - Enable action on double click
 * @param {Object} props.scroll - Scroll configuration for DataTable
 * @param {boolean} props.expandable - Enable expandable rows
 * @param {string} props.path - Path for resource links
 * @param {string} props.withCreateButtonText - Custom text for create button
 * @param {string} props.rowKey - Key for identifying rows
 * @param {Function} props.formatter - Function for formatting exported data
 * @param {ReactNode} props.extraFilters - Additional filters for search bar
 * @param {Function} props.onClickAdd - Click event for add button
 * @param {boolean} props.useUrlFilter - Use URL filter, should be false if there are several ListResource components in the same page
 */

export const ListResource = ({
  resourceName,
  tradKey,
  dataToFetch,
  columns,
  customActionColumn,
  contentCustomStyle,
  headers,
  children,
  populate,
  extraQuery,
  extraHeader,
  extraButtons,
  exportUrl,
  withCreateButton,
  withCreateButtonText,
  withUploadButton,
  withPageHeader,
  withSearchBar,
  forceRefresh,
  resourceModelName,
  editAction,
  extraEditCondition,
  showAction,
  duplicateAction,
  printAction,
  deleteAction,
  onDoubleClickAction,
  scroll,
  expandable,
  path,
  rowKey,
  formatter,
  extraFilters,
  onClickAdd,
  withImportButton,
  extraSorter,
  setTitleTotals,
  rowSelection,
  useUrlFilter,
  flexWrap,
  withPagination
}) => {
  const { pathname } = useLocation();
  const { user } = useAuthContext();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const location = useLocation();
  const [stateSearch, setStateSearch] = useState(
    pathSearches[getKeyByValue(routes, pathname)]
  );
  const params = new URLSearchParams(stateSearch || location.search);
  const keyword = params.get('k');
  const pageSize = params.get('pS');
  const currentFilters = params.get('f');
  const currentSorter = params.get('s');
  const [searchValue, setSearchValue] = useState(keyword);

  const hasAccess = (purpose) => isAuthorized(pathname, user?.role, purpose);

  const searchResource = (value) => {
    const search = `?p=1${pageSize ? `&pS=${pageSize}` : ''}${
      currentSorter ? `&s=${currentSorter}` : ''
    }${currentFilters ? `&f=${currentFilters}` : ''}${
      value ? `&k=${encodeURIComponent(value)}` : ''
    }`;

    if (useUrlFilter) navigate({ pathname, search });
    else setStateSearch(search);
  };

  useEffect(() => {
    setSearchValue(null);
  }, [pathname]);

  useEffect(() => {
    if (keyword) setSearchValue(keyword);
    else setSearchValue(null);
  }, [keyword]);

  const menu = {
    items: [
      ...(headers
        ? [
            {
              key: 'export',
              label: (
                <ExportButton
                  dataName={resourceName.split('/')[0]}
                  headers={headers}
                  url={`/${exportUrl || resourceName}`}
                  fileName={`${resourceName}.csv`}
                  populate={populate}
                  extraQuery={extraQuery}
                  formatter={formatter}
                />
              )
            }
          ]
        : []),
      ...(withImportButton
        ? [
            {
              key: 'import',
              label: <ImportButton resourceName={resourceModelName} />
            }
          ]
        : [])
    ]
  };

  return (
    <ContentCustom style={contentCustomStyle}>
      {withPageHeader && (
        <PageHeaderCustom
          title={t(`${tradKey || resourceName}.title`)}
          extra={extraHeader}
        />
      )}
      <Flex
        justify={withSearchBar ? 'space-between' : 'end'}
        gap={8}
        align="center"
        style={{ width: '100%' }}
        wrap
      >
        {withSearchBar && (
          <Flex
            gap={8}
            align="end"
            wrap={!!flexWrap}
            style={{ flex: '2 1 auto' }}
          >
            <Search
              allowClear
              placeholder={t('placeholder.search')}
              defaultValue={searchValue}
              onSearch={(value) => searchResource(value)}
              style={{ maxWidth: 300 }}
              enterButton={
                <Button
                  type="secondary"
                  style={{ borderColor: 'var(--borderColor)' }}
                >
                  <SearchOutlined />
                </Button>
              }
            />
            {extraFilters}
          </Flex>
        )}
        <Flex gap={16} align="center">
          <Flex gap={8} align="center">
            {extraButtons}
          </Flex>
          <Flex align="center">
            {withCreateButton && hasAccess('create') && onClickAdd && (
              <Button type="primary" onClick={onClickAdd}>
                {withCreateButtonText || `${t('buttons.create')}`}
                &nbsp;
                {withCreateButton?.buttonIcon || <AddIcon />}
              </Button>
            )}
            {hasAccess('create') && withCreateButton && !onClickAdd && (
              <Link to={withCreateButton?.path || `${pathname}/create`}>
                <Button type="primary">
                  {withCreateButtonText || `${t('buttons.create')}`}
                  &nbsp;
                  {withCreateButton?.buttonIcon || <AddIcon />}
                </Button>
              </Link>
            )}
            {hasAccess('export') && withUploadButton && headers && (
              <Dropdown menu={menu}>
                <Button type="link">
                  <MenuOutlined
                    style={{ fontSize: 16, color: 'var(--textColor)' }}
                  />
                </Button>
              </Dropdown>
            )}
          </Flex>
        </Flex>
      </Flex>
      <Flex gap={8}>{children}</Flex>
      <Datatable
        style={{ marginTop: 16 }}
        resourceName={dataToFetch || resourceName}
        columns={columns}
        customActionColumn={customActionColumn}
        extraQuery={extraQuery}
        populate={populate}
        forceRefresh={forceRefresh}
        editAction={editAction}
        extraEditCondition={extraEditCondition}
        showAction={showAction}
        duplicateAction={duplicateAction}
        printAction={printAction}
        deleteAction={deleteAction}
        onDoubleClickAction={onDoubleClickAction}
        scroll={scroll || { x: 1200 }}
        expandable={expandable}
        path={path}
        rowKey={rowKey}
        extraSorter={extraSorter}
        setTitleTotals={setTitleTotals}
        rowSelection={rowSelection}
        stateSearch={stateSearch}
        setStateSearch={setStateSearch}
        useUrlFilter={useUrlFilter}
        withPagination={withPagination}
      />
    </ContentCustom>
  );
};

ListResource.propTypes = {
  resourceName: PropTypes.string.isRequired,
  tradKey: PropTypes.string,
  dataToFetch: PropTypes.string,
  columns: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  contentCustomStyle: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.bool,
    PropTypes.object
  ]),
  customActionColumn: PropTypes.bool,
  headers: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  extraQuery: PropTypes.string,
  extraHeader: PropTypes.element,
  extraButtons: PropTypes.oneOfType([PropTypes.element, PropTypes.bool]),
  extraFilters: PropTypes.oneOfType([PropTypes.element, PropTypes.bool]),
  exportUrl: PropTypes.string,
  populate: PropTypes.string,
  withCreateButton: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.shape({
      path: PropTypes.string,
      buttonText: PropTypes.string,
      buttonIcon: PropTypes.element
    })
  ]),
  withUploadButton: PropTypes.bool,
  withImportButton: PropTypes.bool,
  withPageHeader: PropTypes.bool,
  withSearchBar: PropTypes.bool,
  forceRefresh: PropTypes.bool,
  resourceModelName: PropTypes.string,
  editAction: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.shape({
      pathname: PropTypes.func
    })
  ]),
  extraEditCondition: PropTypes.func,
  duplicateAction: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.shape({
      pathname: PropTypes.func
    })
  ]),
  printAction: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.shape({
      pathname: PropTypes.func
    })
  ]),
  showAction: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.shape({
      pathname: PropTypes.func
    })
  ]),
  deleteAction: PropTypes.bool,
  onDoubleClickAction: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.shape({
      action: PropTypes.func
    })
  ]),
  scroll: PropTypes.shape({}),
  expandable: PropTypes.shape({}),
  path: PropTypes.string,
  rowKey: PropTypes.string,
  formatter: PropTypes.func,
  onClickAdd: PropTypes.func,
  extraSorter: PropTypes.string,
  setTitleTotals: PropTypes.func,
  rowSelection: PropTypes.shape({}),
  useUrlFilter: PropTypes.bool,
  withCreateButtonText: PropTypes.string,
  flexWrap: PropTypes.bool,
  withPagination: PropTypes.bool
};

ListResource.defaultProps = {
  tradKey: null,
  headers: null,
  extraQuery: null,
  extraHeader: null,
  extraButtons: null,
  extraFilters: null,
  exportUrl: null,
  populate: null,
  contentCustomStyle: null,
  customActionColumn: false,
  withCreateButton: true,
  withUploadButton: true,
  withImportButton: true,
  withSearchBar: true,
  withPageHeader: true,
  dataToFetch: null,
  forceRefresh: null,
  resourceModelName: null,
  editAction: true,
  extraEditCondition: () => true,
  showAction: true,
  duplicateAction: false,
  printAction: false,
  deleteAction: true,
  onDoubleClickAction: true,
  scroll: null,
  expandable: undefined,
  path: null,
  rowKey: '_id',
  formatter: undefined,
  onClickAdd: null,
  extraSorter: null,
  setTitleTotals: null,
  rowSelection: null,
  useUrlFilter: true,
  withCreateButtonText: null,
  flexWrap: null,
  withPagination: true
};
