import { useState } from 'react';
import { Drawer, Form } from 'antd';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useTechnicalColumns } from './columns/technicalColumns';
import { useAuthContext } from '../../../contexts/AuthContext';
import { useErrorMessage } from '../../../utils/errorMessage';
import { CreateUpdateContainer, ListResource } from '../../../components';
import { useTechnicalFields } from './fields/TechnicalFields';
import { showSuccessMessage } from '../../../utils/showSuccessMessage';

/**
 * CollaboratorsTable component
 *
 * This component displays a table of collaborators associated with a specific customer.
 * It allows for creating, editing, and deleting collaborators using a modal form.
 * The component leverages Ant Design's Drawer, Form, and other UI components.
 *
 * @component
 * @param {object} props - Component properties.
 * @param {object} props.customer - The customer object associated with the collaborators.
 * @param {string} props.customer._id - The ID of the customer.
 * @param {object} props.enums - An object containing enumerations used in form fields (e.g., gender, roles).
 * @param {string} props.id - The ID of the customer, used for associating new collaborators.
 * @param {function} props.refresh - A function that triggers a refresh of the collaborators data.
 * @returns {JSX.Element} - The rendered component.
 */

export const CollaboratorsTable = ({ enums, customer, id, refresh }) => {
  const { t } = useTranslation();
  const { dispatchAPI, user } = useAuthContext();
  const { message } = useErrorMessage();

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [purpose, setPurpose] = useState('create');
  const [itemId, setItemId] = useState(null);
  const [form] = Form.useForm();

  const onEdit = (item) => {
    setItemId(item);
    setPurpose('edit');
    setIsModalVisible(true);
  };

  const onDelete = async (item) => {
    try {
      await dispatchAPI('PATCH', {
        url: `/users/${item}`,
        body: { values: JSON.stringify({ status: 'INACTIVE' }) }
      });
      refresh();
      showSuccessMessage(t, 'users', 'archive');
    } catch (error) {
      message(error);
    }
  };

  const sendData = async (collaData) => {
    try {
      if (purpose === 'create') {
        await dispatchAPI('POST', {
          url: `/users?customerId=${id}`,
          body: { values: JSON.stringify(collaData) }
        });
      } else {
        await dispatchAPI('PATCH', {
          url: `/users/${itemId}`,
          body: { values: JSON.stringify(collaData) }
        });
      }
      setIsModalVisible(false);
      showSuccessMessage(
        t,
        'collaborators',
        purpose === 'create' ? 'create' : 'update'
      );
      refresh();
    } catch (error) {
      message(error);
    }
  };

  const handleOK = async (collaData) => {
    await sendData(collaData);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setPurpose('create');
  };

  const columns = useTechnicalColumns({ onEdit, onDelete });
  const fields = useTechnicalFields(enums, id, form);

  const checkIfRoleContainsUser = () => user.role.split(':')[1] === 'USER';

  return (
    <>
      <Drawer
        title={t(`collaborators.title.${purpose}`)}
        open={isModalVisible}
        width={700}
        destroyOnClose
        onClose={handleCancel}
      >
        <CreateUpdateContainer
          layout="vertical"
          fields={fields}
          purpose={purpose}
          baseUrl="users"
          resource="users"
          withHeader={false}
          customSubmit={handleOK}
          isOverlay
          customNavigate={handleCancel}
          idFromOverlay={itemId}
          customFormInstance={form}
        />
      </Drawer>
      <ListResource
        resourceName="users"
        contentCustomStyle={{ margin: 0, padding: 0 }}
        columns={columns}
        extraQuery={`&status=ACTIVE&company=${
          customer._id
        }&noFilterByRole=${true}`}
        onClickAdd={() => {
          setItemId(null);
          setIsModalVisible(true);
        }}
        withCreateButtonText={t('buttons.create_custom.collaborators')}
        withCreateButton={!checkIfRoleContainsUser()}
        withUploadButton={false}
        withPageHeader={false}
        withSearchBar={false}
        editAction={false}
        showAction={false}
        onDoubleClickAction={false}
        resourceModelName="User"
        deleteAction={false}
      />
    </>
  );
};

CollaboratorsTable.propTypes = {
  customer: PropTypes.shape({
    _id: PropTypes.string
  }),
  enums: PropTypes.shape(PropTypes.arrayOf(PropTypes.string)),
  id: PropTypes.string.isRequired,
  refresh: PropTypes.func
};

CollaboratorsTable.defaultProps = {
  customer: null,
  refresh: () => {},
  enums: undefined
};
