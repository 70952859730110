import { Route, Routes } from 'react-router-dom';
import { CreateUpdateFees } from './CreateUpdateFees';
import { Fees } from './Fees';
import { Exception } from '../../components';
import { FeesContextProvider } from './FeesContext';

export const FeesRouter = () => (
  <FeesContextProvider>
    <Routes>
      <Route path="/create" element={<CreateUpdateFees purpose="create" />} />
      <Route path="/edit/:id" element={<CreateUpdateFees purpose="edit" />} />
      <Route index element={<Fees />} />
      <Route path="*" element={<Exception />} />
    </Routes>
  </FeesContextProvider>
);
