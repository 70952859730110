import { createContext, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { useErrorMessage } from '../../utils/errorMessage';
import { showSuccessMessage } from '../../utils/showSuccessMessage';
import { useAuthContext } from '../../contexts/AuthContext';
import useConfigurationContextConst from './ConfigurationContextConst';

export const ConfigurationContext = createContext({});

export const ConfigurationContextProvider = ({ children }) => {
  const { dispatchAPI } = useAuthContext();
  const { t } = useTranslation();
  const { message } = useErrorMessage();
  const [forceRefresh, setForceRefresh] = useState(false);
  const [editingKey, setEditingKey] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalTitle, setModalTitle] = useState('');
  const [modalPath, setModalPath] = useState('');
  const { defaultItemsColumns, defaultItemsEntries, defaultItemTitles } =
    useConfigurationContextConst();

  const isEditing = (record) => record._id === editingKey;

  const cancel = () => setEditingKey('');

  const fetchItems = async (resourceName, setData) => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/${resourceName}`
      });
      setData(data);
    } catch (e) {
      message(e);
    }
  };

  const addItem = async (newItem, resourceName) => {
    try {
      await dispatchAPI('POST', { url: `/${resourceName}`, body: newItem });
      showSuccessMessage(t, resourceName, 'create');
    } catch (e) {
      message(e);
    }
    setForceRefresh(!forceRefresh);
  };

  const editItem = async (item, id, resourceName) => {
    try {
      await dispatchAPI('PATCH', {
        url: `/${resourceName}/${id}`,
        body: item
      });
      showSuccessMessage(t, resourceName, 'update');
    } catch (e) {
      message(e);
    }
    setForceRefresh(!forceRefresh);
  };

  const deleteItem = async (item, resourceName) => {
    try {
      const url =
        typeof item === 'string'
          ? `/${resourceName}/${item}`
          : `/${resourceName}/${item._id}`;

      await dispatchAPI('DELETE', {
        url
      });

      showSuccessMessage(t, resourceName, 'archive');
    } catch (e) {
      message(e);
    }
    setForceRefresh(!forceRefresh);
  };

  const onClick = (resourceName, id, actionType) => {
    if (actionType === 'edit') {
      setModalTitle(resourceName);
      setModalPath(`${resourceName}/${id}`);
      setIsModalOpen(true);
    } else if (actionType === 'delete') {
      deleteItem(id, resourceName);
    }
  };

  return (
    <ConfigurationContext.Provider
      value={{
        fetchItems,
        addItem,
        editItem,
        deleteItem,
        editingKey,
        setEditingKey,
        forceRefresh,
        setForceRefresh,
        isEditing,
        cancel,
        defaultItemTitles,
        defaultItemsColumns: defaultItemsColumns(onClick),
        defaultItemsEntries,
        isModalOpen,
        setIsModalOpen,
        modalTitle,
        setModalTitle,
        modalPath,
        setModalPath
      }}
    >
      {children}
    </ConfigurationContext.Provider>
  );
};

ConfigurationContextProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element)
  ]).isRequired
};

export default () => useContext(ConfigurationContext);
