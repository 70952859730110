import { createContext, useContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useAuthContext } from '../../contexts/AuthContext';
import { useErrorMessage } from '../../utils/errorMessage';

const InternalGestionContext = createContext({});

export const InternalGestionContextProvider = ({ children }) => {
  const { dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();
  const [isLoading, setIsLoading] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [users, setUsers] = useState([]);
  const [workHours, setWorkHours] = useState([]);
  const [events, setEvents] = useState([]);
  const [weeksTotal, setWeeksTotal] = useState([]);
  const [monthsTotal, setMonthsTotal] = useState([]);

  const getUsers = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/users`
      });
      setUsers(data);
    } catch (e) {
      message(e);
    }
  };

  const getWorkHours = async (year, person) => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/workhours/internalgestion?start_time>=${year}-01-01&start_time<=${year}-12-31&user=${
          person || null
        }&populate=user`
      });
      setWorkHours(data.workhours);
      setWeeksTotal(data.weeks);
      setMonthsTotal(data.months);

      const { data: data2 } = await dispatchAPI('GET', {
        url: `/events/absence-vacation?start_date=${new Date(
          `${year}-01-01`
        )}&end_date=${new Date(`${year}-12-31`)}&user=${
          person || null
        }&status=APPROVED,PENDING`
      });
      setEvents(data2);
    } catch (e) {
      message(e);
    }
  };

  const getLeaders = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/users?role=users:LEADER-USER`
      });
      return data;
    } catch (e) {
      message(e);
      return [];
    }
  };

  const getEnums = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `internalgestion/enums`
      });
      return data;
    } catch (e) {
      message(e);
      return [];
    }
  };

  const getEventsEnums = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `events/enums`
      });
      return data;
    } catch (e) {
      message(e);
      return [];
    }
  };

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      await getUsers();
      setIsLoading(false);
    })();
  }, [refresh]);

  return (
    <InternalGestionContext.Provider
      value={{
        users,
        isLoading,
        refresh,
        setRefresh,
        getWorkHours,
        workHours,
        events,
        weeksTotal,
        monthsTotal,
        getLeaders,
        getEnums,
        getEventsEnums
      }}
    >
      {children}
    </InternalGestionContext.Provider>
  );
};

InternalGestionContextProvider.propTypes = {
  children: PropTypes.element.isRequired
};

export default () => useContext(InternalGestionContext);
