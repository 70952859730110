import { createContext, useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useAuthContext } from '../../contexts/AuthContext';
import { useErrorMessage } from '../../utils/errorMessage';
import { showSuccessMessage } from '../../utils/showSuccessMessage';
import { isAuthorized } from '../../utils/constants/authorizedMenu';

const CustomerContext = createContext({});

/**
 * A context provider component for managing customer-related data and actions.
 *
 * @context
 * @param {Object} props - Component props
 * @param {ReactNode} props.children - The child components to be wrapped by the context provider
 * @returns {ReactNode} The rendered component
 */
export const CustomerContextProvider = ({ children }) => {
  const { dispatchAPI, user } = useAuthContext();
  const { message } = useErrorMessage();
  const { t } = useTranslation();
  const location = useLocation();
  const { pathname } = location;
  const [enums, setEnums] = useState({});
  const [isLoadingEnums, setIsLoadingEnums] = useState(false);
  const [createType, setCreateType] = useState(
    user?.role?.startsWith('guests') ? '' : 'ACTIVE'
  );
  const [micro_ps, setMicro_ps] = useState([{}]);
  const [psMacro, setPsMacro] = useState([{}]);
  const [selectedMicroPs, setSelectedMicroPs] = useState('');
  const [refresh, setRefresh] = useState(false);
  const [customer, setCustomer] = useState({});
  const [businessIntroducer, setBusinessIntroducer] = useState({});
  const [bankReconciliations, setBankReconciliations] = useState(null);
  const [psNumbers, setPsNumbers] = useState([]);
  const [centralHubs, setCentralHubs] = useState([]);
  const [isOverPaymentModalOpen, setIsOverPaymentModalOpen] = useState(false);
  const [overPayment, setOverPayment] = useState(null);

  const hasAccess = (purpose) => isAuthorized(pathname, user?.role, purpose);

  const getResource = async (url, setter) => {
    try {
      const { data } = await dispatchAPI('GET', { url });
      setter(data);
    } catch (error) {
      message(error);
    }
  };

  const getTypeFromId = async (id) => {
    if (!id) return null;
    try {
      const { data } = await dispatchAPI('GET', {
        url: `customers/${id}?select=status`
      });
      return data.status;
    } catch (error) {
      message(error);
      return null;
    }
  };

  const changeStatus = async (id, status) => {
    try {
      if (!id) return;
      if (!status) return;

      let req;
      if (status === 'ACTIVE') {
        req = await dispatchAPI('POST', {
          url: `/users?customerId=${id}`,
          body: {
            values: JSON.stringify({
              status: 'ACTIVE',
              role: 'guests:ADMIN-GUEST',
              email: customer?.email,
              first_name: customer?.company_name,
              usage_last_name: 'Principal',
              gender: 'MR',
              trigram: `${customer?.company_name?.slice(0, 3)}`,
              send_password: true,
              company: id
            })
          }
        });
      }

      let prospect_comment = `${customer?.prospect_comment}\n` || '';

      prospect_comment +=
        [
          'rejection_rate',
          'outstanding_third_party_number',
          'third_party_payment_turnover',
          'global_turnover',
          'link_to_opportunity'
        ]
          .map(
            (field) =>
              customer &&
              customer[field] &&
              customer[field] !== '' &&
              `${t(`customers.form.${field}`)}: ${customer[field]}`
          )
          .join('\n') || '';

      await dispatchAPI('PATCH', {
        url: `/customers/${id}`,
        body: { status, user_id: req?.data?._id, prospect_comment }
      });
      setRefresh(!refresh);
      showSuccessMessage(t, 'customers', 'update');
    } catch (e) {
      message(e);
    }
  };

  const getCustomer = async (id, extra) => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/customers/${id}${extra || ''}`
      });
      setCustomer(data);
    } catch (e) {
      message(e);
    }
  };

  const getBankReconciliation = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/bankreconciliations`
      });
      setBankReconciliations(data);
    } catch (e) {
      message(e);
    }
  };

  const getBusinessIntroducer = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/businessintroducers?sort=title`
      });
      setBusinessIntroducer(data);
    } catch (e) {
      message(e);
    }
  };

  const getFile = async (id) => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/files/${id}`
      });
      return data;
    } catch (e) {
      message(e);
      return {};
    }
  };

  const addContact = async (purpose, idCustomer, contact, contactId) => {
    try {
      if (purpose === 'create') {
        await dispatchAPI('POST', {
          url: `/contacts?idCustomer=${idCustomer}`,
          body: contact
        });
      }
      if (purpose === 'edit') {
        await dispatchAPI('PATCH', {
          url: `/contacts/${contactId}`,
          body: contact
        });
      }
      showSuccessMessage(
        t,
        'contacts',
        purpose === 'create' ? 'create' : 'update'
      );
      setRefresh(!refresh);
    } catch (e) {
      message(e);
    }
  };

  const changeContactsStatus = async (id, status) => {
    try {
      if (!id) return;
      if (!status) return;
      await dispatchAPI('PATCH', {
        url: `/contacts/${id}`,
        body: { status }
      });
      setRefresh(!refresh);
      showSuccessMessage(t, 'status', 'changed');
    } catch (e) {
      message(e);
    }
  };

  const getSales = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: '/users?role=users:SALES-USER'
      });
      setEnums((prev) => ({ ...prev, sales: data }));
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getPsNumbers = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: '/customers/ps_number'
      });
      setPsNumbers(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getCentralHubs = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: '/centralhub'
      });
      setCentralHubs(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const patchOverPayment = async (id, body) => {
    try {
      await dispatchAPI('PATCH', {
        url: `/customers/overpayment/${id}`,
        body
      });
      setIsOverPaymentModalOpen(false);
      setRefresh(!refresh);
      showSuccessMessage(t, 'customers', 'update');
    } catch (e) {
      message(e);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      const promises = [
        getResource('customers/enums', setEnums),
        getResource('users/enums', (data) =>
          setEnums((prev) => ({ ...prev, ...data, status: prev.status }))
        ),
        getSales(),
        getBusinessIntroducer(),
        getResource('psmicro', setMicro_ps),
        getResource('psmacro', setPsMacro),
        getBankReconciliation(),
        getPsNumbers(),
        getCentralHubs()
      ];
      await Promise.all(promises);
      setIsLoadingEnums(false);
    };

    fetchData();
  }, []);

  return (
    <CustomerContext.Provider
      value={{
        enums,
        isLoadingEnums,
        createType,
        setCreateType,
        micro_ps,
        selectedMicroPs,
        setSelectedMicroPs,
        getTypeFromId,
        changeStatus,
        refresh,
        setRefresh,
        getCustomer,
        customer,
        getFile,
        addContact,
        changeContactsStatus,
        bankReconciliations,
        psNumbers,
        centralHubs,
        patchOverPayment,
        isOverPaymentModalOpen,
        setIsOverPaymentModalOpen,
        overPayment,
        setOverPayment,
        psMacro,
        businessIntroducer,
        hasAccess
      }}
    >
      {children}
    </CustomerContext.Provider>
  );
};

CustomerContext.propTypes = {
  children: PropTypes.element.isRequired
};

export default () => useContext(CustomerContext);
