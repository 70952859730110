import { useState, useEffect } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Layout } from 'antd';
import { Link, Outlet, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import LogoTPA from '../../assets/images/logo_TPA.png';
import LogoCollapsed from '../../assets/images/logo_TPA_small.png';
import HeaderLayout from './HeaderLayout/HeaderLayout';
import { useHandleResize } from '../../utils/handleResize';
import { AdminNavMenu } from './AdminNavMenu';
import packageJson from '../../../package.json';

const { Content, Footer, Sider } = Layout;

const StyledLayout = styled.div`
  height: 100vh;
`;

const StyledSider = styled.div`
  height: 100vh;
  z-index: 10;
  box-shadow: 1px 0 4px var(--borderColor);
  overflow-x: hidden;
  position: fixed;
  left: 0;
`;

const LogoDiv = styled.div`
  position: relative;
  padding: 16px 0px;
  overflow: hidden;
  line-height: 56px;
  transition: all 0.3s;
  z-index: 900;
  text-align: center;

  img {
    display: inline-block;
    height: 48px;
    vertical-align: middle;
  }
`;

const StyledContent = styled.div`
  margin: 56px 0 0 0;
  background-color: var(--itemActiveBackground);
  min-height: calc(100vh - 56px);
  overflow-y: auto;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
`;

const StyledFooter = styled.footer`
  padding: 8px 24px;
  text-align: center;
  color: var(--textColor);
  background: var(--componentBackground);

  a {
    color: var(--primaryColor);
  }
`;

export const AdminLayout = ({ isAtWork }) => {
  const { t } = useTranslation();
  const location = useLocation();
  const [collapsed, setCollapsed] = useState(window.innerWidth < 992);
  const { width, height } = useHandleResize();
  const [collapseWidth, setCollapseWidth] = useState(
    window.innerWidth < 576 ? 0 : 80
  );

  const onCollapse = (newCollapsed) => {
    setCollapsed(newCollapsed);
  };

  useEffect(() => {
    if (width < 576) {
      setCollapseWidth(0);
    } else {
      setCollapseWidth(80);
    }
  }, [width, height]);

  useEffect(() => {
    if (location.search === '') {
      document
        .getElementById('mainContent')
        .scrollTo({ behavior: 'smooth', top: 0 });
    }
  }, [location]);

  return (
    <StyledLayout as={Layout}>
      <StyledSider
        as={Sider}
        width={256}
        breakpoint="lg"
        trigger={null}
        collapsible
        collapsedWidth={collapseWidth}
        collapsed={collapsed}
        onCollapse={onCollapse}
      >
        <LogoDiv>
          <Link to="/">
            <img alt="Logo" src={width < 993 ? LogoCollapsed : LogoTPA} />
          </Link>
        </LogoDiv>
        {isAtWork && <AdminNavMenu setCollapsed={setCollapsed} />}
      </StyledSider>
      <Layout>
        <HeaderLayout
          collapsed={collapsed}
          collapseSider={setCollapsed}
          collapseWidth={collapseWidth}
        />
        <StyledContent as={Content} id="mainContent">
          <Outlet />
          <StyledFooter as={Footer}>
            {`${t('global.title')} v${
              packageJson.version
            } ©${dayjs().year()} powered by `}
            <a href="https://strateg.in">Strateg.in</a>
          </StyledFooter>
        </StyledContent>
      </Layout>
    </StyledLayout>
  );
};

AdminLayout.propTypes = {
  isAtWork: PropTypes.bool.isRequired
};
