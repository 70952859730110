import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { ContentCustom, PageHeaderCustom } from '..';
import useConfigurationContext from './ConfigurationContext';
import { CollapsableTable } from '../Collapsable/Collapsable';
import { dataFormatter, formatToJsx, indexFinder } from './utils';
import { AllPurposeModal } from '../Modal/Modal';

export const Configurations = () => {
  const { t } = useTranslation();
  const {
    addItem,
    editItem,
    fetchItems,
    forceRefresh,
    defaultItemTitles,
    defaultItemsEntries,
    defaultItemsColumns,
    modalTitle,
    setModalTitle,
    isModalOpen,
    setIsModalOpen,
    modalPath,
    setModalPath
  } = useConfigurationContext();

  const [data, setData] = useState({});
  const [defaultOpen, setDefaultOpen] = useState([]);
  const [defaultOpenChildren, setDefaultOpenChildren] = useState([]);
  const [defaultModalData, setDefaultModalData] = useState({});

  const nestedCollapseModel = ['psmacro', 'rejectiontype'];
  const childrenCollapse = {
    psmacro: 'psmicro',
    rejectiontype: 'rejectionreason'
  };

  const loadFromDB = (resourceName) => {
    if (nestedCollapseModel.includes(resourceName)) {
      fetchItems(`${resourceName}/get-all`, (data2) =>
        setData((prev) => ({ ...prev, [resourceName]: data2 }))
      );
      return;
    }
    fetchItems(resourceName, (data2) =>
      setData((prev) => ({ ...prev, [resourceName]: data2 }))
    );
  };

  useEffect(() => {
    defaultItemTitles.forEach((item) => loadFromDB(`${item}`));
  }, [forceRefresh]);

  useEffect(() => {}, [data]);

  return (
    <ContentCustom>
      <PageHeaderCustom title={t('configurations.title')} />
      <AllPurposeModal
        type={modalPath ? 'edit' : 'create'}
        path={modalPath}
        title={t(`configurations.${modalPath ? 'edit' : 'add'}.${modalTitle}`)}
        data={defaultItemsEntries[modalTitle]}
        defaultData={defaultModalData}
        isOpen={isModalOpen}
        setIsOpen={setIsModalOpen}
        onClose={(newItem) => {
          if (modalPath)
            editItem(newItem, modalPath?.split('/')[1], modalTitle);
          else addItem(newItem, modalTitle);
          setModalPath('');
        }}
        extra={
          data?.psmacro?.map((item) => ({
            value: item._id,
            label: item.title
          })) || []
        }
        fetchItems={fetchItems}
        setModalPath={setModalPath}
      />
      <CollapsableTable
        key="root"
        items={Object.keys(data)
          .sort(
            (a, b) =>
              indexFinder(defaultItemTitles, a) -
              indexFinder(defaultItemTitles, b)
          )
          .map((item, index) =>
            dataFormatter(
              t,
              data || [],
              item === 'psmacro' || item === 'rejectiontype',
              nestedCollapseModel.includes(item) ? childrenCollapse[item] : '',
              item,
              '',
              index
            )
          )}
        addItem={(type, parent) => {
          if (parent && parent?.resourceName === 'psmacro')
            setDefaultModalData({
              macro_ps: parent._id
            });
          if (parent && parent?.resourceName === 'rejectiontype')
            setDefaultModalData({
              rejection_type: parent._id
            });
          setModalTitle(type);
          setIsModalOpen(true);
        }}
        defaultOpen={defaultOpen}
        defaultOpenChildren={defaultOpenChildren}
        onOpen={setDefaultOpen}
        onOpenChildren={setDefaultOpenChildren}
        accordion={false}
        subAccordion
        formatToJsx={(itemLocal) =>
          itemLocal &&
          formatToJsx(
            itemLocal,
            defaultItemsColumns[itemLocal[0]?.resourceName]
          )
        }
        disableAddButtonCondition={(resourceName) => resourceName === 'account'}
      />
    </ContentCustom>
  );
};
