import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Typography, Table, Tag, Popover, Button, Divider, Spin } from 'antd';
import { useTranslation } from 'react-i18next';
import { DownloadOutlined, LoadingOutlined } from '@ant-design/icons';
import { ContentCustom, PageHeaderCustom } from '../../components';
import { useAuthContext } from '../../contexts/AuthContext';
import { useErrorMessage } from '../../utils/errorMessage';
import { quittancesStatus } from '../../utils/constants/tagColors';
import { routes, subRoutes } from '../../utils/constants/adminRoutes';
import QuittanceContext from '../quittances/QuittanceContext';
import { formatValue } from '../../utils/formatNumber';

const { Column } = Table;

/**
 * Component to display detailed view of a specific transfer.
 * This includes information on related quittances and the ability to download the transfer data.
 *
 * @returns {React.Component} The ShowTransfer component.
 */
const ShowTransfer = () => {
  const { message } = useErrorMessage();
  const { t } = useTranslation();
  const { dispatchAPI } = useAuthContext();
  const [isLoading, setIsLoading] = useState(false);
  const { id } = useParams();
  const [transfers, setTransfers] = useState({});
  const { downloadTransfer } = QuittanceContext();
  const [isDownloading, setIsDownloading] = useState(false);

  const getTransfers = useCallback(async () => {
    setIsLoading(true);
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/transfers/${id}?populate=quittances.contract customer rib`
      });
      setTransfers(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
    setIsLoading(false);
  }, [id]);

  useEffect(() => {
    (async () => {
      await getTransfers();
    })();
  }, [getTransfers]);

  const downloadContent = (key) => (
    <Spin spinning={isDownloading}>
      <Button
        onClick={async () => {
          setIsDownloading(true);
          await downloadTransfer(key, 'csv', () => setIsDownloading(false));
        }}
      >
        CSV
      </Button>
      <Divider type="vertical" />
      <Button
        onClick={async () => {
          setIsDownloading(true);
          await downloadTransfer(
            key,
            'xml',
            () => setIsDownloading(false),
            transfers
          );
        }}
      >
        XML
      </Button>
    </Spin>
  );

  return (
    <ContentCustom>
      <PageHeaderCustom
        title={t('transfers.show.title')}
        extra={
          <Popover
            content={() => downloadContent(id)}
            title={t('buttons.download')}
            trigger="click"
            placement="left"
          >
            <Button
              style={{ padding: 10 }}
              type="primary"
              icon={
                isDownloading[id] ? <LoadingOutlined /> : <DownloadOutlined />
              }
            >
              {t('buttons.download')}
            </Button>
          </Popover>
        }
      />

      <Typography.Title level={4}>
        {`${t('transfers.show.sub_title_quittances')}  (${
          transfers.levy_count
        }) - Prélèvements du ${formatValue(
          transfers.levy_date,
          'date'
        )} - ${formatValue(transfers.total, 'currency', true)}`}
      </Typography.Title>
      <Typography.Text>
        {`${t('transfers.show.iban')}  -  ${transfers?.bank_account?.iban}`}
      </Typography.Text>
      {transfers && (
        <Table
          dataSource={(transfers.quittances || []).map((quittance) => ({
            ...quittance,
            key: quittance._id
          }))}
          loading={isLoading}
          scroll={{ x: 'max-content' }}
        >
          <Column
            title={t('transfers.show.customer')}
            key="name"
            render={(quittance) => (
              <Tag style={{ borderStyle: 'dashed' }}>
                <a
                  href={`${routes.CUSTOMERS}/show/${quittance?.customer?._id}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  aria-label="Voir le client"
                >
                  {quittance?.customer?.company_name}
                </a>
              </Tag>
            )}
            sorter={(a, b) =>
              a?.customer_account?.label?.localeCompare(
                b?.customer_account?.label
              )
            }
            width={180}
          />
          <Column
            title={t('transfers.show.contract')}
            key="number"
            render={(quittance) => (
              <a
                href={`${`${routes.CONTRACT}`}/show/${
                  quittance?.contract?._id
                }`}
                target="_blank"
                rel="noopener noreferrer"
                aria-label="Voir le contrat"
                style={{ color: 'var(--primaryColor)' }}
              >
                {quittance?.contract?.code}
              </a>
            )}
            sorter={(a, b) =>
              a?.contract?.number?.localeCompare(b?.contract?.number)
            }
            width={120}
          />
          <Column
            title={t('transfers.show.quittance')}
            key="number"
            render={(quittance) => (
              <a
                href={`${routes.BILLING}${subRoutes.BILLING.QUITTANCES}/show/${quittance?._id}`}
                target="_blank"
                rel="noopener noreferrer"
                aria-label="Voir la facture"
                style={{ color: 'var(--primaryColor)' }}
              >
                {quittance?.code}
              </a>
            )}
            sorter={(a, b) =>
              a?.contract?.number?.localeCompare(b?.contract?.number)
            }
            width={120}
          />
          <Column
            title={t('transfers.show.total_with_taxes')}
            key="total_with_taxes"
            render={(quittance) =>
              `${quittance?.total_with_taxes?.toFixed(2)} €`
            }
            width={100}
          />
          <Column
            title={t('transfers.show.already_paid')}
            key="already_paid"
            render={(quittance) => `${quittance?.already_paid?.toFixed(2)} €`}
            width={100}
          />
          <Column
            title={t('transfers.show.total_with_already_paid')}
            key="already_paid"
            render={(quittance) => {
              const totalWithTaxes = quittance.total_with_taxes ?? 0;
              const alreadyPaid = quittance.already_paid ?? 0;
              const result = (totalWithTaxes - alreadyPaid).toFixed(2);
              return `${result} €`;
            }}
            width={100}
          />
          <Column
            title={t('transfers.show.quittance_status')}
            key="status"
            render={(quittance) => (
              <Tag color={quittancesStatus[quittance?.status]}>
                {t(`quittances.form.enums.${quittance?.status}`)}
              </Tag>
            )}
            sorter={(a, b) =>
              t(`quittances.form.enums.${a?.quittance_status}`).localeCompare(
                t(`quittances.form.enums.${b?.quittance_status}`)
              )
            }
            width={150}
          />
          <Column
            title={t('transfers.show.rum')}
            key="rum"
            render={(quittance) => quittance?.rib?.rum}
            width={130}
          />
        </Table>
      )}
    </ContentCustom>
  );
};
export default ShowTransfer;
