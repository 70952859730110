import { useState } from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import { useParams } from 'react-router-dom';
import { CreateUpdateContainer } from '../../components/CreateUpdateContainer/CreateUpdateContainer';
import CustomerServiceContext from './CustomerServiceContext';
import useCustomerServicesFields from './fields';
import { useAuthContext } from '../../contexts/AuthContext';

/**
 * Component for creating or updating quote information.
 *
 * @component
 * @param {string} purpose - Purpose of the form ('create' or 'update')
 * @returns {JSX.Element} CreateUpdateContainer component with quote creation or update functionality
 */

export const CreateUpdateCustomerService = ({ purpose }) => {
  const { user } = useAuthContext();
  const { id } = useParams();
  const [fileList, setFileList] = useState([]);
  const [formValues, setFormValues] = useState();
  const { refresh } = CustomerServiceContext();

  const { fields } = useCustomerServicesFields(
    user,
    id,
    fileList,
    setFileList,
    purpose,
    formValues
  );

  const config = {
    onGetResource: {
      setFields: (data) => {
        setFileList(data.files || []);
        return {
          ...data,
          completion_date: data.completion_date && dayjs(data.completion_date)
        };
      }
    },
    onUpdateResource: {
      setBody: (data) => {
        const { files, ...restData } = data;
        const formData = new FormData();
        formData.append('values', JSON.stringify({ ...restData }));
        if (fileList) {
          fileList.forEach((fileId) => {
            formData.append('files', fileId);
          });
        }
        return formData;
      }
    },
    onCreateResource: {
      setBody: (data) => {
        const { files, ...restData } = data;
        const formData = new FormData();
        formData.append('values', JSON.stringify({ ...restData }));
        if (fileList) {
          fileList.forEach((fileId) => {
            formData.append('files', fileId);
          });
        }
        return formData;
      }
    }
  };

  return (
    <CreateUpdateContainer
      purpose={purpose}
      fields={fields}
      baseUrl="/customerServices"
      resource="customerServices"
      populate="files"
      config={config}
      refresh={refresh}
      setFormValues={setFormValues}
      needFormDependencies
    />
  );
};

CreateUpdateCustomerService.propTypes = {
  purpose: PropTypes.string.isRequired
};
