import dayjs from 'dayjs';
import { Tag } from 'antd';
import numeral from 'numeral';
import { routes } from '../../../utils/constants/adminRoutes';
import { formatValue } from '../../../utils/formatNumber';

/**
 * Custom hook for generating informations content data for displaying quittance informations details in a list format.
 *
 * @hook
 * @param {object} data - User data object
 * @returns {Array} Array of objects containing label, span, and content for each field
 */

export const useListContent = (data, t) => {
  const {
    customer,
    contract,
    title,
    bank_reconciliation,
    range_concerned,
    payment_choice,
    transfer_date,
    total,
    taxes,
    total_with_taxes,
    payments,
    paid_date
  } = data;

  const totalPaid = (payments || []).reduce(
    (acc, p) => acc + (p?.amount || 0),
    0
  );

  const totalDue =
    Number(total_with_taxes?.toFixed(2)) - Number(totalPaid?.toFixed(2));

  return [
    {
      label: 'quittances.show.ps_number',
      span: 12,
      content: customer?.ps_number ? <Tag>{customer?.ps_number}</Tag> : '-',
      type: 'customer'
    },
    {
      label: 'quittances.show.company_name',
      span: 12,
      content: (
        <a
          href={`${`${routes.CUSTOMERS}`}/show/${customer?._id}`}
          target="_blank"
          rel="noopener noreferrer"
          aria-label="Voir le client"
        >
          {customer?.company_name}
        </a>
      ),
      type: 'customer'
    },
    {
      label: 'quittances.show.contract_number',
      span: 12,
      content: (
        <a
          href={`${`${routes.CONTRACT}`}/show/${contract?._id}`}
          target="_blank"
          rel="noopener noreferrer"
          aria-label="Voir le contrat"
        >
          {contract?.code}
        </a>
      ),
      type: 'customer'
    },
    {
      label: 'quittances.show.billing_address',
      span: 12,
      content: `${customer?.billing_address?.street || '-'}
        ${customer?.billing_address?.postal_code || '-'}  ${
        customer?.billing_address?.city || '-'
      }
        `,
      type: 'customer'
    },
    {
      label: 'quittances.show.phone_number',
      span: 12,
      content:
        customer?.phone_number && customer?.phone_number.number ? (
          <div>
            {formatValue(
              `${customer?.phone_number?.country_code || ''}${
                customer?.phone_number?.number || ''
              }`,
              'phone'
            )}
          </div>
        ) : (
          '-'
        ),
      type: 'customer'
    },
    {
      label: 'quittances.show.email',
      span: 12,
      content: (
        <a
          href={`mailto:${customer?.email}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          {customer?.email}
        </a>
      ),
      type: 'customer'
    },
    {
      label: 'quittances.show.title',
      span: 12,
      content: t(`quittances.form.enums.${title}`),
      type: 'quittance'
    },
    {
      label: 'quittances.show.emitter',
      span: 12,
      content: t(`employments.tags.${customer?.company}`),
      type: 'quittance'
    },
    {
      label: 'quittances.show.bank_reconciliation',
      span: 12,
      content: bank_reconciliation?.name,
      type: 'quittance'
    },
    {
      label: 'quittances.show.range_concerned',
      span: 12,
      content:
        range_concerned?.length > 0
          ? `Du ${formatValue(range_concerned[0], 'date')} au ${dayjs(
              range_concerned[1]
            )
              .subtract(1, 'day')
              .format('DD/MM/YYYY')}`
          : '-',
      type: 'quittance'
    },
    {
      label: 'quittances.show.transfer_date',
      span: 12,
      content: dayjs(transfer_date).add(10, 'day').format('DD/MM/YYYY'),
      type: 'quittance'
    },
    {
      label: 'quittances.show.payment_choice',
      span: 12,
      content: t(`quittances.form.enums.${payment_choice}`),
      type: 'quittance'
    },
    {
      label: 'quittances.show.total',
      span: 12,
      content: total ? `${formatValue(total, 'currency', true)}` : 'N/A',
      type: 'total'
    },
    {
      label: 'quittances.show.taxes',
      span: 12,
      content: `${taxes}%`,
      type: 'total'
    },
    {
      label: 'quittances.show.total_with_taxes',
      span: 12,
      content: total_with_taxes
        ? `${formatValue(total_with_taxes, 'currency', true)}`
        : 'N/A',
      type: 'total'
    },
    {
      label: 'quittances.show.paid_amount',
      span: 12,
      content: totalPaid
        ? `${formatValue(totalPaid, 'currency', true)}`
        : `${numeral().format('0,0.00')}€`,
      type: 'total'
    },
    {
      label: 'quittances.show.total_due',
      span: 12,
      content: totalDue
        ? `${formatValue(totalDue, 'currency', true)}`
        : `${numeral().format('0,0.00')}€`,
      type: 'total'
    },
    {
      label: 'quittances.show.paid_date',
      span: 12,
      content: paid_date ? formatValue(paid_date, 'date') : '-',
      type: 'total'
    }
  ];
};
