import { createContext, useContext } from 'react';
import { Divider, Button, Row, Col, Popconfirm } from 'antd';
import {
  ContainerOutlined,
  EditOutlined,
  WarningOutlined
} from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';

export const ConfigurationContextConst = createContext({});

export const ConfigurationContextConstProvider = ({ children }) => {
  const { t } = useTranslation();

  const renderer = (text, onClick, resourceName, id) => (
    <Row>
      <Col flex="auto">{text}</Col>
      <Col>
        <Divider type="vertical" />
        <Button
          onClick={() => onClick(resourceName, id, 'edit')}
          icon={<EditOutlined style={{ fontSize: 18 }} />}
          type="text"
        />
      </Col>
      <Col>
        <Divider type="vertical" />
        <Popconfirm
          title={t('datatable.column.action.delete.title')}
          okText={t('datatable.column.action.delete.ok')}
          okButtonProps={{ type: 'danger' }}
          cancelText={t('datatable.column.action.delete.cancel')}
          onConfirm={() => onClick(resourceName, id, 'delete')}
          icon={<WarningOutlined />}
        >
          <ContainerOutlined
            style={{ color: 'var(--errorColor)', fontSize: 18 }}
            type="delete"
          />
        </Popconfirm>
      </Col>
    </Row>
  );

  const defaultItemTitles = [
    'psmacro',
    'account',
    'software',
    'organization',
    'task',
    'listAction',
    'centralhub',
    'businessintroducer',
    'bankreconciliation',
    'hollydays',
    'rejectiontype'
  ];

  const defaultItemsColumns = (onClick) => ({
    software: [
      {
        title: t('configurations.columns.software'),
        dataIndex: 'label',
        key: 'label',
        sorter: (a, b) => a.title.localeCompare(b.title),
        render: (text, data) =>
          renderer(text, onClick, data.resourceName, data._id)
      }
    ],
    centralhub: [
      {
        title: t('configurations.columns.centralhub'),
        dataIndex: 'title',
        key: 'title',
        sorter: (a, b) => a.title?.localeCompare(b.title),
        render: (text, data) =>
          renderer(text, onClick, data.resourceName, data._id)
      }
    ],
    businessintroducer: [
      {
        title: t('configurations.columns.businessintroducer'),
        dataIndex: 'title',
        key: 'title',
        sorter: (a, b) => a.title?.localeCompare(b.title),
        render: (text, data) =>
          renderer(text, onClick, data.resourceName, data._id)
      }
    ],
    task: [
      {
        title: t('configurations.columns.task'),
        dataIndex: 'label',
        key: 'label',
        sorter: (a, b) => a.title?.localeCompare(b.title),
        render: (text, data) =>
          renderer(text, onClick, data.resourceName, data._id)
      }
    ],
    listAction: [
      {
        title: t('configurations.columns.listAction'),
        dataIndex: 'label',
        key: 'label',
        sorter: (a, b) => a.title?.localeCompare(b.title),
        render: (text, data) =>
          renderer(text, onClick, data.resourceName, data._id)
      }
    ],
    account: [
      {
        title: t('configurations.columns.interventions'),
        dataIndex: 'title',
        key: 'title',
        sorter: (a, b) =>
          t(`fees.enums.${a.title}`).localeCompare(t(`fees.enums.${b.title}`)),
        render: (text) => t(`fees.enums.${text}`)
      },
      {
        title: t('configurations.columns.account'),
        dataIndex: 'account',
        key: 'account',
        sorter: (a, b) => a.account.localeCompare(b.account)
      },
      {
        title: t('configurations.columns.label'),
        dataIndex: 'displayLabel',
        key: 'displayLabel',
        sorter: (a, b) => a.displayLabel.localeCompare(b.displayLabel),
        render: (text, data) =>
          renderer(text, onClick, data.resourceName, data._id)
      }
    ],
    psmacro: [
      {
        title: t('configurations.columns.name'),
        dataIndex: 'label',
        key: 'label',
        render: (text, data) =>
          renderer(text, onClick, data.resourceName, data._id)
      }
    ],
    hollydays: [
      {
        title: t('configurations.columns.name'),
        dataIndex: 'name',
        key: 'name'
      },
      {
        title: t('configurations.columns.date'),
        dataIndex: 'date',
        key: 'date',
        render: (date, data) =>
          data.annual
            ? dayjs(date).format('DD/MM')
            : dayjs(date).format('DD/MM/YYYY')
      },
      {
        title: t('configurations.columns.annual'),
        dataIndex: 'annual',
        key: 'annual',
        render: (annual, data) =>
          renderer(
            t(`switch.${annual || 'no'}`),
            onClick,
            data.resourceName,
            data._id
          )
      }
    ],
    psmicro: [
      {
        title: t('configurations.columns.name'),
        dataIndex: 'label',
        key: 'label',
        render: (text, data) =>
          renderer(text, onClick, data.resourceName, data._id)
      }
    ],
    organization: [
      {
        title: t('configurations.columns.name'),
        dataIndex: 'name',
        key: 'name',
        sorter: (a, b) => a.name?.localeCompare(b.name)
      },
      {
        title: t('configurations.columns.type'),
        dataIndex: 'type',
        key: 'type',
        sorter: (a, b) => a.type?.localeCompare(b.type)
      },
      {
        title: t('configurations.columns.address'),
        dataIndex: 'address',
        key: 'address',
        sorter: (a, b) => a.address?.localeCompare(b.address)
      },
      {
        title: t('configurations.columns.email'),
        dataIndex: 'email',
        key: 'email',
        sorter: (a, b) => a.email?.localeCompare(b.email)
      },
      {
        title: t('configurations.columns.link'),
        dataIndex: 'link',
        key: 'link',
        sorter: (a, b) => a.link?.localeCompare(b.link),
        render: (text, data) =>
          renderer(text, onClick, data.resourceName, data._id)
      }
    ],
    bankreconciliation: [
      {
        title: t('configurations.columns.name'),
        dataIndex: 'name',
        key: 'name',
        sorter: (a, b) => a.name?.localeCompare(b.name)
      },
      {
        title: t('configurations.columns.creditor_id'),
        dataIndex: 'creditor_id',
        key: 'creditor_id',
        sorter: (a, b) => a.creditor_id?.localeCompare(b.creditor_id)
      },
      {
        title: t('configurations.columns.iban'),
        dataIndex: 'iban',
        key: 'iban',
        sorter: (a, b) => a.iban?.localeCompare(b.iban)
      },
      {
        title: t('configurations.columns.bic'),
        dataIndex: 'bic',
        key: 'bic',
        sorter: (a, b) => a.bic?.localeCompare(b.bic)
      },
      {
        title: t('configurations.columns.ics'),
        dataIndex: 'ics',
        key: 'ics',
        sorter: (a, b) => a.ics?.localeCompare(b.ics),
        render: (text, data) =>
          renderer(text, onClick, data.resourceName, data._id)
      }
    ],
    rejectiontype: [
      {
        title: t('configurations.columns.rejectiontype'),
        dataIndex: 'title',
        key: 'title',
        render: (text, data) =>
          renderer(text, onClick, data.resourceName, data._id)
      }
    ],
    rejectionreason: [
      {
        title: t('configurations.columns.name'),
        dataIndex: 'title',
        key: 'title',
        render: (text, data) =>
          renderer(text, onClick, data.resourceName, data._id)
      }
    ]
  });

  const defaultItemsEntries = {
    psmacro: [
      {
        type: 'input',
        description: t('configurations.columns.name'),
        key: 'title'
      },
      {
        type: 'input',
        description: t('configurations.columns.objective'),
        key: 'objective'
      }
    ],
    hollydays: [
      {
        type: 'input',
        description: t('configurations.columns.name'),
        key: 'name'
      },
      {
        type: 'datepicker',
        description: t('configurations.columns.date'),
        key: 'date',
        format: 'DD-MM-YYYY'
      },
      {
        type: 'checkbox',
        description: t('configurations.columns.annual'),
        key: 'annual'
      }
    ],
    psmicro: [
      {
        type: 'input',
        description: t('configurations.columns.name'),
        key: 'title'
      },
      {
        type: 'select',
        description: t('configurations.columns.ps-macro-id'),
        key: 'macro_ps',
        style: { width: '100%' },
        showSearch: true
      }
    ],
    account: [
      {
        description: t('configurations.columns.interventions'),
        type: 'input',
        key: 'title',
        disabled: true
      },
      {
        description: t('configurations.columns.account'),
        type: 'input',
        key: 'account'
      },
      {
        description: t('configurations.columns.label'),
        type: 'input',
        key: 'label'
      }
    ],
    software: [
      {
        type: 'input',
        description: t('configurations.columns.name'),
        key: 'title'
      }
    ],
    task: [
      {
        type: 'input',
        description: t('configurations.columns.name'),
        key: 'title'
      }
    ],
    listAction: [
      {
        type: 'input',
        description: t('configurations.columns.name'),
        key: 'title'
      }
    ],
    centralhub: [
      {
        type: 'input',
        description: t('configurations.columns.name'),
        key: 'title'
      }
    ],
    businessintroducer: [
      {
        type: 'input',
        description: t('configurations.columns.name'),
        key: 'title'
      }
    ],
    organization: [
      {
        description: t('configurations.columns.name'),
        type: 'input',
        key: 'name'
      },
      {
        description: t('configurations.columns.type'),
        type: 'input',
        key: 'type'
      },
      {
        description: t('configurations.columns.address'),
        type: 'input',
        key: 'address'
      },
      {
        description: t('configurations.columns.email'),
        type: 'input',
        key: 'email'
      },
      {
        description: t('configurations.columns.link'),
        type: 'input',
        key: 'link'
      }
    ],
    bankreconciliation: [
      {
        description: t('configurations.columns.name'),
        type: 'input',
        key: 'name'
      },
      {
        description: t('configurations.columns.creditor_id'),
        type: 'input',
        key: 'creditor_id'
      },
      {
        description: t('configurations.columns.iban'),
        type: 'input',
        key: 'iban'
      },
      {
        description: t('configurations.columns.bic'),
        type: 'input',
        key: 'bic'
      },
      {
        description: t('configurations.columns.ics'),
        type: 'input',
        key: 'ics'
      }
    ],
    rejectiontype: [
      {
        type: 'input',
        description: t('configurations.columns.name'),
        key: 'title'
      }
    ],
    rejectionreason: [
      {
        type: 'input',
        description: t('configurations.columns.name'),
        key: 'title'
      }
    ]
  };

  return (
    <ConfigurationContextConst.Provider
      value={{
        defaultItemTitles,
        defaultItemsColumns,
        defaultItemsEntries
      }}
    >
      {children}
    </ConfigurationContextConst.Provider>
  );
};

ConfigurationContextConstProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element)
  ]).isRequired
};

export default () => useContext(ConfigurationContextConst);
