import dayjs from 'dayjs';

/**
 * Formats the given value based on the specified type, including phone numbers, dates, SIRET, RIB, IBAN, numbers, currency amounts, and time.
 *
 * The function identifies the type of value and applies the appropriate formatting:
 * - Phone numbers (`phone`): Formats with spaces separating each group of digits in the format `+33 4 37 50 28 28`.
 * - Dates (`date`): Formats the date in `DD-MM-YYYY`.
 * - SIRET numbers (`siret`): Formats in `000 000 000 0000`.
 * - RIB numbers (`rib`): Formats in `00000 00000 00000000000 00`.
 * - IBAN (`iban`): Formats as `FR 14 20041 01005 0500013M026 06`.
 * - Simple numbers (`number`): Formats with thousand separators.
 * - Currency amounts (`currency`): Formats with thousand separators and an optional decimal point, followed by the Euro symbol (€).
 * - Time (`time`): Extracts and formats time from a date in `HH[h]mm` format (e.g., `13h45`).
 *
 * @function
 * @param {string|number} value - The value to be formatted.
 * @param {string} type - The type of the value. Valid types are:
 *   - `'phone'` for phone numbers.
 *   - `'date'` for dates.
 *   - `'siret'` for SIRET numbers.
 *   - `'rib'` for RIB numbers.
 *   - `'iban'` for IBAN numbers.
 *   - `'number'` for simple numbers.
 *   - `'currency'` for currency amounts.
 *   - `'time'` for time values.
 * @param {boolean} [withCents=false] - Optional. Specifies whether to include two decimal places for currency amounts. Defaults to `false`.
 *
 * @returns {string} The formatted value according to the specified type.
 *
 * @throws {TypeError} Throws an error if the input value is neither a string nor a number, or if an unsupported type is provided.
 */
export const formatValue = (value, type, withCents = false) => {
  const formatPhoneNumber = (phoneNumber) => {
    if (typeof phoneNumber !== 'string') return '';
    return phoneNumber.replace(
      /(\+\d{2})(\d)(\d{2})(\d{2})(\d{2})(\d{2})/,
      '$1 $2 $3 $4 $5 $6'
    );
  };

  const formatCurrency = (amount) => {
    if (typeof amount !== 'string' && typeof amount !== 'number') return '';
    const formattedAmount = parseFloat(amount).toLocaleString('fr-FR', {
      minimumFractionDigits: withCents ? 2 : 0,
      maximumFractionDigits: withCents ? 2 : 0
    });
    return `${formattedAmount} €`;
  };

  const formatNumber = (number) => {
    if (typeof number !== 'string' && typeof number !== 'number') return '';
    return parseFloat(number).toLocaleString('fr-FR');
  };

  const formatDate = (date) => {
    if (!dayjs(date).isValid()) return '';
    return dayjs(date).format('DD-MM-YYYY');
  };

  const formatTime = (date) => {
    if (!dayjs(date).isValid()) return '';
    return dayjs(date).format('HH[h]mm');
  };

  const formatSiret = (siret) => {
    if (typeof siret !== 'string' || siret.length !== 14) return '';
    return siret.replace(/(\d{3})(\d{3})(\d{3})(\d{5})/, '$1 $2 $3 $4');
  };

  const formatRIB = (rib) => {
    if (typeof rib !== 'string' || rib.length !== 23) return '';
    return rib.replace(/(\d{5})(\d{5})(\d{11})(\d{2})/, '$1 $2 $3 $4');
  };

  const formatIBAN = (iban) => {
    if (typeof iban !== 'string' || iban.length !== 27) return '';
    return iban.replace(
      /(.{4})(.{4})(.{4})(.{4})(.{4})(.{4})(.{4})(.{2})/,
      '$1 $2 $3 $4 $5 $6 $7 $8'
    );
  };

  switch (type) {
    case 'phone':
      return formatPhoneNumber(value);
    case 'currency':
      return formatCurrency(value);
    case 'number':
      return formatNumber(value);
    case 'date':
      return formatDate(value);
    case 'time':
      return formatTime(value);
    case 'siret':
      return formatSiret(value);
    case 'rib':
      return formatRIB(value);
    case 'iban':
      return formatIBAN(value);
    default:
      throw new TypeError('Invalid type specified for formatting.');
  }
};
