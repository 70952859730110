import { useTranslation } from 'react-i18next';
import { useParams, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { Button, Card, Col, Row } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import { useAuthContext } from '../../contexts/AuthContext';
import { useErrorMessage } from '../../utils/errorMessage';
import {
  ContentCustom,
  DescriptionList,
  PageHeaderCustom
} from '../../components';
import { listContent } from './listContent';
import { PDFviewer } from '../../components/PDFviewer/PDFviewer';

/**
 * `ShowTemplate` is a component that retrieves and displays a specific template document
 * based on the ID parameter from the URL. It fetches the document data from the server
 * and allows the user to generate a PDF version of the document.
 *
 * It utilizes various hooks such as `useTranslation` for internationalization, `useParams`
 * to get the ID parameter from the URL, and `useState` and `useEffect` to manage
 * the component's state and lifecycle respectively.
 *
 * @component
 * @returns {React.Element} A JSX element that displays the template document details and provides a button to generate a PDF version of the document.
 *
 * @example
 *
 * return (
 *   <ShowTemplate />
 * );
 */
export const ShowTemplate = () => {
  const { t } = useTranslation();
  const { dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();
  const { id } = useParams();
  const [template, setTemplate] = useState();
  const [documentUrl, setDocumentUrl] = useState();
  const [pageNumber, setPageNumber] = useState(1);
  const navigate = useNavigate();

  const getTemplate = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `template-documents/${id}?populate=author`
      });
      setTemplate(data);
    } catch (e) {
      message(e);
    }
  };

  const handleUpdateTemplate = () => {
    navigate(`/template-docs/edit/${encodeURIComponent(id)}`);
  };

  useEffect(() => {
    (async () => {
      try {
        await getTemplate();
        const { data } = await dispatchAPI('POST', {
          url: `template-documents/generateRawPDF/${id}`,
          responseType: 'arraybuffer'
        });

        const blob = new Blob([data], { type: 'application/pdf' });

        const url = URL.createObjectURL(blob);

        setDocumentUrl(url);
      } catch (e) {
        message(e);
      }
    })();
  }, []);

  return (
    <ContentCustom>
      <PageHeaderCustom
        title={template?.title}
        extra={
          <Button onClick={handleUpdateTemplate}>
            <EditOutlined />
            {t('template-documents.edit_button')}
          </Button>
        }
      />
      <Row gutter={[8, 8]}>
        <Col span={8}>
          <Card title={t('template-documents.show.infos')}>
            <DescriptionList data={listContent(template || {}, t)} translate />
          </Card>
        </Col>
        <Col xs={16}>
          <Card>
            <PDFviewer
              currentFile={documentUrl}
              pageNumber={pageNumber}
              setPageNumber={setPageNumber}
            />
          </Card>
        </Col>
      </Row>
    </ContentCustom>
  );
};
