import { Tag, Button, Popconfirm, Divider, Col, Row } from 'antd';
import {
  EditOutlined,
  ContainerOutlined,
  WarningOutlined,
  MailOutlined,
  CloudDownloadOutlined
} from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { actionStatus } from '../../../utils/constants/tagColors';
import { useDownloadDocument } from '../../../utils/downloadDoc';
import { formatValue } from '../../../utils/formatNumber';

/**
 * Custom hook for generating table columns for displaying actions reminder data.
 *
 * @hook
 * @param {object} actionEnums - Enumerations data for actions reminder properties
 * @returns {Array} Array of table column objects
 */

export const useColumns = (
  actionEnums,
  rejectionReasons,
  options,
  hasAccess
) => {
  const { t } = useTranslation();

  const { downloadDocumentById } = useDownloadDocument();

  return [
    {
      title: t('actions.columns.status'),
      key: 'status',
      dataIndex: ['status'],
      render: (status) => (
        <Tag color={actionStatus[status]}>
          {t(`interventions.show.actions_reminder.enums.${status}`)}
        </Tag>
      ),
      sorter: true,
      filters: ['DONE', 'ARCHIVED'].map((s) => ({
        text: t(`interventions.show.actions_reminder.enums.${s}`),
        value: s
      })),
      filteredValue: ['DONE']
    },
    {
      title: t('actions.columns.fse_number'),
      key: 'fse_number',
      dataIndex: 'fse_number',
      sorter: true,
      render: (fse_number, data) => fse_number || data?.task_number?.title
    },
    {
      title: t('actions.columns.part'),
      key: 'part',
      dataIndex: 'part',
      render: (part) =>
        part ? t(`interventions.show.actions_reminder.enums.${part}`) : '-',
      sorter: true,
      filters: actionEnums?.parts?.map((p) => ({
        text: t(`actions.enums.${p}`),
        value: p
      }))
    },
    {
      title: t('actions.columns.amount'),
      key: 'amount',
      dataIndex: 'amount',
      render: (amount) => (amount ? `${formatValue(amount, 'currency')}` : '-'),
      sorter: true
    },
    {
      title: t('actions.columns.credit'),
      key: 'credit',
      dataIndex: 'credit',
      render: (credit) => (credit ? `${formatValue(credit, 'currency')}` : '-'),
      sorter: true
    },
    {
      title: t('actions.columns.action'),
      key: 'action',
      dataIndex: 'action',
      render: (action) => (action ? action?.title : '-'),
      sorter: true
    },
    {
      title: t('actions.columns.comment'),
      key: 'comment',
      dataIndex: 'comment'
    },
    {
      title: t('actions.columns.new_fse_number'),
      key: 'new_fse_number',
      dataIndex: 'new_fse_number',
      sorter: true
    },
    {
      title: t('actions.columns.task_time'),
      key: 'task_time',
      dataIndex: 'task_time',
      sorter: true
    },
    {
      title: t('actions.columns.doc_type'),
      key: 'doc_type',
      dataIndex: 'doc_type',
      render: (doc_type) => t(`actions.enums.${doc_type}`),
      sorter: true,
      filters: actionEnums?.docTypes?.map((d) => ({
        text: t(`actions.enums.${d}`),
        value: d
      }))
    },
    {
      title: t('actions.columns.rejection_reason'),
      key: 'rejection_reason',
      dataIndex: 'rejection_reason',
      render: (rejection_reason) => rejection_reason?.title,
      sorter: true,
      filters: rejectionReasons?.map((rejectionReason) => ({
        text: rejectionReason?.title,
        value: rejectionReason._id
      }))
    },
    {
      title: t('actions.columns.pandp'),
      key: 'pandp',
      dataIndex: 'pandp',
      render: (pandp) => (pandp ? `${formatValue(pandp, 'currency')}` : '-'),
      sorter: true
    },
    {
      title: t('actions.columns.operator_code'),
      key: 'operator_code',
      dataIndex: 'operator_code'
    },
    {
      title: t('actions.columns.contact'),
      key: 'contact',
      dataIndex: 'contact',
      render: (contact) => t(`actions.enums.${contact}`),
      sorter: true,
      filters: actionEnums?.contacts?.map((c) => ({
        text: t(`actions.enums.${c}`),
        value: c
      }))
    },
    {
      title: t('actions.columns.issuer_organization'),
      key: 'issuer_organization',
      dataIndex: 'issuer_organization',
      render: (issuer_organization) => `${issuer_organization?.name || ''}`
    },
    {
      title: t('actions.columns.created_at'),
      key: 'created_at',
      dataIndex: 'created_at',
      render: (created_at) => formatValue(created_at, 'time'),
      sorter: true
    },
    {
      key: 'action',
      align: 'right',
      fixed: 'right',
      render: (record) => (
        <Row wrap={false} align="middle" gutter={12} justify="end">
          <Col span={3}>
            {record?.email_sent && hasAccess('cancel') ? (
              <Popconfirm
                title={t('datatable.column.action.cancel.title')}
                okText={t('datatable.column.action.cancel.ok')}
                okButtonProps={{ type: 'danger' }}
                cancelText={t('datatable.column.action.cancel.cancel')}
                onConfirm={() => options.onCancelEmailSent(record._id)}
                icon={<WarningOutlined />}
              >
                <MailOutlined style={{ fontSize: 18 }} />
              </Popconfirm>
            ) : (
              <MailOutlined style={{ fontSize: 18, visibility: 'hidden' }} />
            )}
          </Col>

          <Divider type="vertical" />

          <Col span={3}>
            {hasAccess('edit') ? (
              <Button
                type="link"
                onClick={() => options.onEdit(record._id)}
                className="no-padding"
              >
                <EditOutlined style={{ fontSize: 18 }} />
              </Button>
            ) : (
              <EditOutlined style={{ fontSize: 18, visibility: 'hidden' }} />
            )}
          </Col>

          <Divider type="vertical" />

          <Col span={3}>
            {record?.file ? (
              <Button
                type="link"
                onClick={() => downloadDocumentById(record.file)}
                className="no-padding"
              >
                <CloudDownloadOutlined style={{ fontSize: 18 }} />
              </Button>
            ) : (
              <CloudDownloadOutlined
                style={{ fontSize: 18, visibility: 'hidden' }}
              />
            )}
          </Col>

          <Divider type="vertical" />

          <Col span={3}>
            {hasAccess('archive') ? (
              <Popconfirm
                title={t('datatable.column.action.delete.title')}
                okText={t('datatable.column.action.delete.ok')}
                okButtonProps={{ type: 'danger' }}
                cancelText={t('datatable.column.action.delete.cancel')}
                onConfirm={() => options.onArchived(record._id)}
                icon={<WarningOutlined />}
              >
                <Button type="link" danger className="no-padding">
                  <ContainerOutlined style={{ fontSize: 18 }} />
                </Button>
              </Popconfirm>
            ) : (
              <ContainerOutlined
                style={{ fontSize: 18, visibility: 'hidden' }}
              />
            )}
          </Col>
        </Row>
      )
    }
  ];
};
