import { Button, Popconfirm, Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';
import {
  ContainerOutlined,
  CopyOutlined,
  EditOutlined,
  WarningOutlined
} from '@ant-design/icons';
import { useErrorMessage } from '../../../../utils/errorMessage';
import { showSuccessMessage } from '../../../../utils/showSuccessMessage';

/**
 * Custom hook to generate columns configuration for the websites table.
 *
 * @hook
 * @param {Object} options - Configuration options.
 * @param {function} options.onEdit - Function to handle editing a website entry.
 * @param {function} options.onDelete - Function to handle deleting a website entry.
 * @param {string} userRole - The role of the current user.
 * @returns {Array} Columns configuration for the websites table.
 */

export const useWebsitesColumns = (options, hasAccess) => {
  const { t } = useTranslation();
  const { message } = useErrorMessage();

  const handleCellClick = (text) => {
    navigator.clipboard.writeText(text).then(
      () => {
        showSuccessMessage(t, 'data', 'copied');
      },
      (err) => {
        message(err);
      }
    );
  };

  const handleKeyDown = (event, text) => {
    if (event.key === 'Enter' || event.key === ' ') {
      handleCellClick(text);
    }
  };

  const renderCopyButton = (text) => (
    <Tooltip title={t(`messages.success.copy`)}>
      <Button
        type="link"
        icon={<CopyOutlined />}
        onClick={() => handleCellClick(text)}
      />
    </Tooltip>
  );

  const ensureHttpPrefix = (url) => {
    if (!url.startsWith('http://') && !url.startsWith('https://')) {
      return `http://${url}`;
    }
    return url;
  };

  return [
    {
      title: t('customers.form.websites.name'),
      key: 'name',
      dataIndex: ['name'],
      sorter: true
    },
    {
      title: t('customers.form.websites.login'),
      key: 'login',
      dataIndex: ['login'],
      sorter: true,
      render: (login) => (
        <span
          onClick={() => handleCellClick(login)}
          onKeyDown={(event) => handleKeyDown(event, login)}
          tabIndex={0}
          role="button"
          style={{ cursor: 'pointer' }}
        >
          {login}
          {renderCopyButton(login)}
        </span>
      )
    },
    {
      title: t('customers.form.websites.password'),
      key: 'password',
      dataIndex: ['password'],
      sorter: true,
      render: (password) => (
        <span
          onClick={() => handleCellClick(password)}
          onKeyDown={(event) => handleKeyDown(event, password)}
          tabIndex={0}
          role="button"
          style={{ cursor: 'pointer' }}
        >
          {password}
          {renderCopyButton(password)}
        </span>
      )
    },
    {
      title: t('customers.form.websites.url'),
      key: 'url',
      dataIndex: ['url'],
      sorter: true,
      render: (url) => {
        const correctedUrl = ensureHttpPrefix(url);
        return (
          <span>
            <a
              href={correctedUrl}
              target="_blank"
              rel="noopener noreferrer"
              style={{ marginRight: 8, color: 'var(--primaryColor)' }}
            >
              {url}
            </a>
            {renderCopyButton(correctedUrl)}
          </span>
        );
      }
    },
    {
      key: 'action',
      align: 'right',
      render: (record) => (
        <>
          {hasAccess('edit_website') && (
            <Button type="ghost" onClick={() => options.onEdit(record._id)}>
              <EditOutlined style={{ fontSize: 18 }} />
            </Button>
          )}
          {hasAccess('delete_website') && (
            <Popconfirm
              title={t('datatable.column.action.delete.title')}
              okText={t('datatable.column.action.delete.ok')}
              okButtonProps={{ type: 'danger' }}
              cancelText={t('datatable.column.action.delete.cancel')}
              onConfirm={() => options.onDelete(record._id)}
              icon={<WarningOutlined />}
            >
              <ContainerOutlined
                style={{ fontSize: 18, color: 'red' }}
                type="delete"
              />
            </Popconfirm>
          )}
        </>
      )
    }
  ];
};
