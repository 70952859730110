export const headers = [
  {
    label: 'type',
    key: 'type'
  },
  {
    label: 'role',
    key: 'user.role'
  },
  {
    label: 'first_name',
    key: 'user.first_name'
  },
  {
    label: 'last_name',
    key: 'user.last_name'
  },
  {
    label: 'start_date',
    key: 'start_date'
  },
  {
    label: 'end_date',
    key: 'end_date'
  },
  {
    label: 'paidLeaveInAdvance',
    key: 'paidLeaveInAdvance'
  },
  {
    label: 'created_at',
    key: 'created_at'
  },
  {
    label: 'waiveExtraTimeOff',
    key: 'waiveExtraTimeOff'
  },
  {
    label: 'status',
    key: 'status'
  }
];
