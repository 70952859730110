import { Input, Checkbox, Select } from 'antd';
import { useTranslation } from 'react-i18next';
import useCustomerContext from '../CustomerContext';
import { selectFormat } from './utils';
import { phoneNumberField } from '../../../utils/phoneNumberField';

const { Option } = Select;

/**
 * useFields is a custom hook that provides an array of field configurations for customer data input.
 *
 * @hook
 * @returns {Array<Object>} An array of field configurations.
 */

const useFields = (
  psNumberRegistered,
  emailRegistered,
  psIdRegistered,
  usedCreateType,
  purpose
) => {
  const { enums, micro_ps, setSelectedMicroPs, psNumbers } =
    useCustomerContext();
  const { t } = useTranslation();
  const requiredMessage = t('errors.form.REQUIRED');

  const onsearch = (input, option) => {
    const result = option?.children.toLowerCase();
    return result.includes(input.toLowerCase());
  };

  const validatePsNumber = (_, value) => {
    const isPsNumberExists = psNumbers?.some((ps) => ps.ps_number === value);

    if (isPsNumberExists && value !== psNumberRegistered) {
      return Promise.reject(new Error(t('Numéro PS déjà utilisé')));
    }
    return Promise.resolve();
  };

  const validateEmail = (_, value) => {
    const isEmailExists = psNumbers?.some((ps) => ps.email === value);

    if (isEmailExists && value !== emailRegistered) {
      return Promise.reject(new Error(t('Email déjà utilisé')));
    }
    return Promise.resolve();
  };

  const validateIdPs = (_, value) => {
    const isIdPsExists = psNumbers?.some((ps) => ps.type_ps_id === value);

    if (isIdPsExists && value !== psIdRegistered) {
      return Promise.reject(new Error(t('ID Type PS déjà utilisé')));
    }
    return Promise.resolve();
  };

  return [
    {
      name: ['company'],
      input: (
        <Select
          allowClear
          showSearch
          filterOption={(input, option) => onsearch(input, option)}
        >
          {(enums?.companies || []).map((company) => (
            <Option key={company} value={company}>
              {t(`users.companies.${company}`)}
            </Option>
          ))}
        </Select>
      ),
      rules: [{ required: true, message: requiredMessage }]
    },
    {
      name: ['ps_number'],
      key: 'ps_number',
      initialValue:
        usedCreateType === 'NON_PS_CUSTOMER' && purpose === 'create'
          ? '999999999'
          : '',
      hidden: usedCreateType === 'NON_PS_CUSTOMER' && purpose === 'create',
      input: (
        <Input
          type="number"
          disabled={usedCreateType === 'NON_PS_CUSTOMER' && purpose === 'edit'}
        />
      ),
      rules: [
        {
          validator: validatePsNumber
        },
        { required: true, len: 9 }
      ]
    },
    {
      name: ['company_name'],
      key: 'company_name',
      input: <Input />,
      rules: [{ required: true, message: requiredMessage }]
    },
    {
      name: ['legal_name'],
      key: 'legal_name',
      input: <Input />,
      rules: [{ required: true, message: requiredMessage }]
    },
    {
      name: ['micro_ps'],
      key: 'micro_ps',
      input: selectFormat(t, micro_ps, {
        optionsKey: 'title',
        optionsHaveID: true,
        onChange: (value) => setSelectedMicroPs(value),
        showSearch: true,
        filterOption: (input, option) => onsearch(input, option)
      }),
      rules: [{ required: true, message: requiredMessage }]
    },
    {
      name: ['type_ps_id'],
      key: 'type_ps_id',
      rules: [
        {
          validator: validateIdPs
        }
      ],
      input: <Input />
    },
    {
      name: ['address', 'number'],
      key: 'number',
      input: <Input type="number" />
    },
    {
      name: ['address', 'street'],
      key: 'street',
      input: <Input />,
      rules: [{ required: true, message: requiredMessage }]
    },
    {
      name: ['address', 'postal_code'],
      key: 'postal_code',
      input: <Input type="number" />,
      rules: [{ required: true, message: requiredMessage, len: 5 }]
    },
    {
      name: ['address', 'city'],
      key: 'city',
      input: <Input />,
      rules: [{ required: true, message: requiredMessage }]
    },
    phoneNumberField,
    {
      name: ['email'],
      key: 'email',
      input: <Input type="email" />,
      rules: [
        {
          validator: validateEmail
        },
        { required: true, message: requiredMessage }
      ]
    },
    {
      name: ['sharing_data'],
      key: 'sharing_data',
      tooltip: true,
      input: <Checkbox />,
      valuePropName: 'checked'
    }
  ];
};

export default useFields;
