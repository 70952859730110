import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Menu } from 'antd';
import { NavLink, useLocation } from 'react-router-dom';
import navMenuLogos from '../../utils/constants/navMenuLogos';
import { useHandleResize } from '../../utils/handleResize';
import {
  routes,
  subRoutes,
  pathSearches
} from '../../utils/constants/userRoutes';
import { useAuthContext } from '../../contexts/AuthContext';

export const UserNavMenu = ({ setCollapsed }) => {
  const { t } = useTranslation();
  const { user, workHours } = useAuthContext();
  const location = useLocation();
  const { width } = useHandleResize();
  const path = location?.pathname || '/';
  const activeKeys = path
    ?.replace('/', '')
    .split('/')
    .map((p) => `/${p}`);

  const hideMenu = {
    OCCUPANCY_CHART: ['users:SALES-USER', 'users:USER'],
    QUOTE: ['users:USER', 'users:LEADER-USER'],
    QUALITY_CONTROL: ['users:SALES-USER'],
    SERVICES: ['users:SALES-USER', 'users:USER', 'users:LEADER-USER'],
    CONTRACT: ['users:SALES-USER', 'users:USER', 'users:LEADER-USER'],
    INTERVENTIONS: ['users:SALES-USER'],
    CUSTOMER_ANALYSIS: ['users:SALES-USER'],
    STATISTICS: ['users:SALES-USER']
  };

  const isPaused = workHours.current_state === 'pause';

  return (
    <Menu
      style={{
        padding: '12px 0',
        background: 'var(--clientColor)'
      }}
      selectedKeys={activeKeys}
      defaultOpenKeys={activeKeys}
      mode="inline"
      onSelect={width < 992 ? () => setCollapsed(true) : () => {}}
      items={Object.entries(routes).map(([menuItem, p]) => {
        if (
          (hideMenu[menuItem] && hideMenu[menuItem].includes(user?.role)) ||
          isPaused
        )
          return null;
        return subRoutes[menuItem]
          ? {
              key: p,
              icon: navMenuLogos[menuItem],
              label: t(`basiclayout.menu.${menuItem.toLowerCase()}`),
              children: Object.entries(subRoutes[menuItem]).map(
                ([subMenuItem, subPath]) => ({
                  key: subPath,
                  disabled: subPath === '' || isPaused,
                  label: (
                    <NavLink
                      to={`${p}${subPath}${
                        pathSearches[subMenuItem]
                          ? pathSearches[subMenuItem]
                          : ''
                      }`}
                    >
                      <span>
                        {t(`basiclayout.submenu.${subMenuItem.toLowerCase()}`)}
                      </span>
                    </NavLink>
                  )
                })
              )
            }
          : {
              key: p,
              disabled: p === '' || isPaused,
              label: (
                <NavLink
                  to={`${p}${
                    pathSearches[menuItem] ? pathSearches[menuItem] : ''
                  }`}
                >
                  {navMenuLogos[menuItem]}
                  <span>{t(`basiclayout.menu.${menuItem.toLowerCase()}`)}</span>
                </NavLink>
              )
            };
      })}
    />
  );
};

UserNavMenu.propTypes = {
  setCollapsed: PropTypes.func.isRequired
};
