import { useEffect, useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { Card, Flex, Select, message as antdMessage } from 'antd';
import { ContentCustom, PageHeaderCustom } from '../../components';
import { useAuthContext } from '../../contexts/AuthContext';
import { useErrorMessage } from '../../utils/errorMessage';
import { useFormatter } from '../../utils/formatter';
import { Calendar } from '../../components/Calendar/Calendar';
import EventDrawer from './EventDrawer';
import EventModal from './EventModal';
import {
  annualCellRender,
  monthCellRender,
  weekCellRender
} from '../../components/Calendar/CellRenders.tsx';
import useCalendarContext from './CalendarContext';
import { headers } from './headers';
import ModalConflict from './ModalConflict';
import { showSuccessMessage } from '../../utils/showSuccessMessage';

const { Option } = Select;

export const ShowCalendar = () => {
  const { t } = useTranslation();
  const { dispatchAPI, user: userConnected } = useAuthContext();
  const { message } = useErrorMessage();
  const { formattedData } = useFormatter('events');
  const [users, setUsers] = useState([]);
  const [psMacro, setPsMacro] = useState([]);
  const [psMicro, setPsMicro] = useState([]);
  const [numberPs, setNumberPs] = useState([]);
  const [showDrawer, setShowDrawer] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedUser, setSelectedUser] = useState();
  const [selectedNumberPs, setSelectedNumberPs] = useState();
  const [selectedPsMacro, setSelectedPsMacro] = useState();
  const [selectedPsMicro, setSelectedPsMicro] = useState();
  const [forceRefresh, setForceRefresh] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [eventToUpdate, setEventToUpdate] = useState();
  const [fileList, setFileList] = useState();
  const [conflicts, setConflicts] = useState([]);
  const [isModalConflictOpen, setIsModalConflictOpen] = useState(false);
  const { setType } = useCalendarContext();

  const openDrawer = () => {
    setShowDrawer(true);
  };

  const closeOverlay = (data) => {
    if (data?.conflicts?.length > 0) {
      setForceRefresh(!forceRefresh);
      setConflicts(data.conflicts);
      setIsModalConflictOpen(true);
    } else {
      setType(null);
      setShowDrawer(false);
      setOpenModal(false);
      setForceRefresh(!forceRefresh);
      setFileList();
    }
  };

  const printResource = async (idFile, fileName) => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/files/${idFile}`,
        responseType: 'blob',
        headers: {
          type: 'blob'
        }
      });
      const blob = new Blob([data], {
        contentType: 'application/pdf'
      });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = `${fileName}`;
      a.click();
      antdMessage.success(t('messages.success.download'));
    } catch (e) {
      message(e);
    }
  };

  const deleteEvent = async (id) => {
    try {
      await dispatchAPI('DELETE', { url: `/events/${id}` });
      setForceRefresh(!forceRefresh);

      showSuccessMessage(t, 'events', 'archive');
    } catch (e) {
      message(e);
    }
  };

  const onsearchUser = (input, option) => {
    const result = option?.children.toLowerCase();
    return result.includes(input.toLowerCase());
  };

  const onsearchNumberPs = (input, option) => {
    const result = option?.children.toLowerCase();
    return result.includes(input.toLowerCase());
  };

  const onsearchPsMicro = (input, option) => {
    const result = option?.children.toLowerCase();
    return result.includes(input.toLowerCase());
  };

  const onsearchPsMacro = (input, option) => {
    const result = option?.children.toLowerCase();
    return result.includes(input.toLowerCase());
  };

  const getUsers = useCallback(async () => {
    try {
      let specialFilter;
      if (userConnected.role === 'users:LEADER-USER')
        specialFilter = '?role=users:LEADER-USER,users:USER';
      if (userConnected.role === 'users:USER')
        specialFilter = '?role=users:USER';
      if (userConnected.role === 'users:SALES-USER')
        specialFilter = '?role=users:SALES-USER';
      const { data } = await dispatchAPI('GET', {
        url: `users${
          userConnected.role.startsWith('admins') ? '' : specialFilter
        }${
          userConnected.role.startsWith('admins')
            ? ''
            : `&noFilterByRole=${true}`
        }`
      });
      setUsers(data);
    } catch (e) {
      message(e);
    }
  }, []);

  const getMacroPs = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/Psmacro?sort=title`
      });
      setPsMacro(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getMicroPs = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/Psmicro?sort=title`
      });
      setPsMicro(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getAllPsNumber = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/psnumber`
      });
      setNumberPs(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  useEffect(() => {
    setIsLoading(true);
    (async () => {
      await getUsers();
    })();
    setIsLoading(false);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setIsLoading(true);
    (async () => {
      await getUsers();
      await getMacroPs();
      await getMicroPs();
      await getAllPsNumber();
    })();
    setIsLoading(false);
    // eslint-disable-next-line
  }, []);

  return (
    <>
      {isModalConflictOpen && (
        <ModalConflict
          open={isModalConflictOpen}
          setIsModalConflictOpen={setIsModalConflictOpen}
          conflicts={conflicts}
        />
      )}
      {showDrawer && (
        <EventDrawer
          showDrawer={showDrawer}
          setShowDrawer={setShowDrawer}
          users={users}
          purpose="create"
          closeOverlay={closeOverlay}
          idFromOverlay={eventToUpdate}
          fileList={fileList}
          setFileList={setFileList}
        />
      )}
      {openModal && (
        <EventModal
          openModal={openModal}
          setOpenModal={setOpenModal}
          users={users}
          purpose="edit"
          closeOverlay={closeOverlay}
          idFromOverlay={eventToUpdate}
          fileList={fileList}
          setFileList={setFileList}
        />
      )}
      <DndProvider backend={HTML5Backend}>
        <Card className="calendar-wrapper">
          <PageHeaderCustom title={t('calendar.title')} />
          <Calendar
            modes={['week', 'month']}
            resourceName="events"
            resource="events"
            monthCellRender={monthCellRender}
            weekCellRender={weekCellRender}
            annualCellRender={annualCellRender}
            initialMode="week"
            hourStart={9}
            hourEnd={21}
            populate="contract,user_referent,leader,user,customer,service"
            extraQuery={`${selectedUser ? `user=${selectedUser}` : ''}${
              selectedPsMacro ? `&macro_ps=${selectedPsMacro}` : ''
            }${selectedPsMicro ? `&micro_ps=${selectedPsMicro}` : ''}${
              selectedNumberPs ? `&ps_number=${selectedNumberPs}` : ''
            }`}
            forceRefresh={forceRefresh}
            withExtraHeader
            withCreateButton
            withUploadButton={userConnected.role.split(':')[1] !== 'USER'}
            withImportButton={false}
            formatter={formattedData}
            headers={headers}
            withSearchBar
            openOverlay={openDrawer}
            setOpenModal={setOpenModal}
            setEventToUpdate={setEventToUpdate}
            deleteEvent={deleteEvent}
            printResource={printResource}
            setForceRefresh={setForceRefresh}
            switchModes
            extraButtons={
              <Flex gap={8}>
                <Select
                  style={{ width: 250 }}
                  placeholder={t('calendar.placeholder.user')}
                  allowClear
                  showSearch
                  loading={isLoading}
                  filterOption={(input, option) => onsearchUser(input, option)}
                  onSelect={(value) => {
                    setSelectedUser(value);
                    setForceRefresh(!forceRefresh);
                  }}
                  onClear={() => {
                    setSelectedUser(null);
                    setForceRefresh(!forceRefresh);
                  }}
                >
                  {users.map((user) => (
                    <Option key={user._id} value={user._id}>
                      {`${user.first_name} ${
                        user?.usage_last_name || user?.last_name
                      }`}
                    </Option>
                  ))}
                </Select>
                <Select
                  style={{ width: 250 }}
                  placeholder={t('calendar.placeholder.numberPs')}
                  showSearch
                  allowClear
                  loading={isLoading}
                  filterOption={(input, option) =>
                    onsearchNumberPs(input, option)
                  }
                  onSelect={(value) => {
                    setSelectedNumberPs(value);
                    setForceRefresh(!forceRefresh);
                  }}
                  onClear={() => {
                    setSelectedNumberPs(null);
                    setForceRefresh(!forceRefresh);
                  }}
                >
                  {numberPs.map((number) => (
                    <Option key={number} value={number.split(' ')[0]}>
                      {number}
                    </Option>
                  ))}
                </Select>
                <Select
                  style={{ width: 250 }}
                  placeholder={t('calendar.placeholder.psMacro')}
                  showSearch
                  allowClear
                  loading={isLoading}
                  filterOption={(input, option) =>
                    onsearchPsMacro(input, option)
                  }
                  onSelect={(value) => {
                    setSelectedPsMacro(value);
                    setForceRefresh(!forceRefresh);
                  }}
                  onClear={() => {
                    setSelectedPsMacro(null);
                    setForceRefresh(!forceRefresh);
                  }}
                >
                  {psMacro
                    ?.sort((a, b) => a.title.localeCompare(b.title))
                    ?.map((macro) => (
                      <Option key={macro._id} value={macro._id}>
                        {macro.title}
                      </Option>
                    ))}
                </Select>
                <Select
                  style={{ width: 250 }}
                  placeholder={t('calendar.placeholder.psMicro')}
                  showSearch
                  allowClear
                  loading={isLoading}
                  filterOption={(input, option) =>
                    onsearchPsMicro(input, option)
                  }
                  onSelect={(value) => {
                    setSelectedPsMicro(value);
                    setForceRefresh(!forceRefresh);
                  }}
                  onClear={() => {
                    setSelectedPsMicro(null);
                    setForceRefresh(!forceRefresh);
                  }}
                >
                  {psMicro
                    ?.sort((a, b) => a.title.localeCompare(b.title))
                    ?.map((micro) => (
                      <Option key={micro._id} value={micro._id}>
                        {micro.title}
                      </Option>
                    ))}
                </Select>
              </Flex>
            }
          />
        </Card>
      </DndProvider>
      <ContentCustom />
    </>
  );
};
