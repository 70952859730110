import {
  Tag,
  Popover,
  Card,
  Typography,
  Popconfirm,
  Divider,
  Button
} from 'antd';
import {
  NotificationFilled,
  EyeOutlined,
  ZoomInOutlined,
  WarningOutlined,
  ContainerOutlined,
  CloudDownloadOutlined,
  CopyOutlined
} from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import useArticleContext from './ArticleContext';
import { newsStatus } from '../../utils/constants/tagColors';
import { formattedContent } from '../../utils/constants/modulesReactQuill';
import { formatValue } from '../../utils/formatNumber';
import { isAuthorized } from '../../utils/constants/authorizedMenu';
import { useDownloadDocument } from '../../utils/downloadDoc';

/**
 * A custom hook that generates columns for displaying news or articles in a table.
 *
 * @hook
 * @param {string} user - The ID of the user viewing the articles.
 * @param {object} enums - Enums object.
 * @returns {Array} An array of column objects for rendering news or articles.
 */
export const useColumns = (user, enums) => {
  const { t } = useTranslation();
  const userId = user._id;
  const userRole = user.role;
  const read = [{ text: 'NON', value: userId }];
  const isSorterAllowed = !['users:USER', 'users:LEADER-USER'].includes(
    userRole
  );
  const { viewDocument } = useDownloadDocument();
  const { duplicateResource, deleteResource } = useArticleContext();
  const location = useLocation();
  const { pathname } = location;

  const hasAccess = (purpose) => isAuthorized(pathname, user?.role, purpose);
  return [
    {
      title: t('news.labels.read'),
      key: 'read_by',
      dataIndex: 'read_by',
      width: 80,
      render: (data) =>
        data?.includes(userId) ? null : (
          <NotificationFilled style={{ color: 'red' }} size={24} />
        ),
      filters: read.map((r) => ({
        text: r.text,
        value: r.value
      }))
    },
    {
      title: t('news.labels.title'),
      key: 'title',
      dataIndex: 'title',
      sorter: isSorterAllowed
    },
    {
      title: t('news.labels.category'),
      key: 'category',
      dataIndex: 'category',
      render: (category) =>
        category ? <Tag>{t(`news.categories.${category}`)}</Tag> : '-',
      sorter: isSorterAllowed,
      filters: enums?.categories?.map((categorie) => ({
        text: t(`news.categories.${categorie}`),
        value: categorie
      }))
    },
    {
      title: t('news.labels.status'),
      key: 'status',
      dataIndex: 'status',
      render: (data) => (
        <Tag color={newsStatus[data]}>{t(`news.status.${data}`)}</Tag>
      ),
      sorter: isSorterAllowed
    },
    {
      title: t('news.labels.type_ps'),
      key: 'micro_ps',
      dataIndex: 'micro_ps',
      render: (_, data) => (
        <Popover
          content={(data?.macro_ps?.length
            ? // eslint-disable-next-line no-unsafe-optional-chaining
              data?.macro_ps
            : data?.micro_ps || []
          ).map((ps) => (
            <p key={ps._id}>{`${ps.title}`}</p>
          ))}
          title={t(
            `news.labels.ps_${data?.macro_ps?.length ? 'macro' : 'micro'}`
          )}
        >
          <EyeOutlined style={{ fontSize: 18 }} />
        </Popover>
      )
    },
    {
      title: t('news.labels.created_at'),
      key: 'created_at',
      dataIndex: 'created_at',
      render: (data) => formatValue(data, 'date'),
      sorter: true
    },
    {
      title: t('news.labels.description'),
      key: 'description',
      dataIndex: 'description',
      render: (description) => (
        <Popover
          content={
            <Card title={t('news.labels.description')}>
              <Typography.Text>{formattedContent(description)}</Typography.Text>
            </Card>
          }
        >
          <ZoomInOutlined />
        </Popover>
      )
    },
    {
      key: 'action',
      align: 'right',
      fixed: 'right',
      render: (record) => (
        <>
          {record?.file?.[0] && (
            <>
              <Button
                onClick={() => viewDocument(record?.file?.[0], 'show')}
                type="link"
                className="no-padding"
              >
                <CloudDownloadOutlined style={{ fontSize: 18 }} />
              </Button>
              <Divider type="vertical" />
            </>
          )}
          {hasAccess('duplicate') && (
            <>
              <Popconfirm
                title={t('datatable.column.action.duplicate.title')}
                okText={t('datatable.column.action.duplicate.ok')}
                okButtonProps={{ type: 'danger' }}
                cancelText={t('datatable.column.action.duplicate.cancel')}
                onConfirm={() => duplicateResource(record?._id)}
                icon={<WarningOutlined />}
              >
                <CopyOutlined style={{ fontSize: 18 }} />
              </Popconfirm>
              <Divider type="vertical" />
            </>
          )}
          {hasAccess('show') && (
            <>
              <Link to={`/actualities/show/${record._id}`}>
                <EyeOutlined style={{ fontSize: 18 }} />
              </Link>
              <Divider type="vertical" />
            </>
          )}
          {hasAccess('archive') && (
            <Popconfirm
              title={t('datatable.column.action.delete.title')}
              okText={t('datatable.column.action.delete.ok')}
              okButtonProps={{ type: 'danger' }}
              cancelText={t('datatable.column.action.delete.cancel')}
              onConfirm={() => deleteResource(record._id)}
              icon={<WarningOutlined />}
            >
              <Button type="link" danger>
                <ContainerOutlined style={{ fontSize: 18 }} type="delete" />
              </Button>
            </Popconfirm>
          )}
        </>
      )
    }
  ];
};
