import { Col, Card, Row } from 'antd';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

/**
 * Component for displaying quote comment.
 *
 * @component
 * @param {object} props - Component props
 * @param {object} props.data - Quote data containing comment
 * @returns {JSX.Element} Component JSX
 */

export const ShowComment = ({ data }) => {
  const { t } = useTranslation();

  return (
    <Col xs={24} lg={12}>
      <Card title={t('quotes.show.titles.comment_quote')}>
        <Row align="middle">
          {(data?.services || []).map((service) => (
            <Col span={24} key={service._id}>
              <h3 style={{ marginBottom: 4 }}>
                {t('quotes.show.comments.internal')} :
              </h3>
              <p>{service?.comment || '-'}</p>
              <h3 style={{ marginBottom: 4 }}>
                {t('quotes.show.comments.customer')} :
              </h3>
              <p>{service?.comment_customer || '-'}</p>
            </Col>
          ))}
        </Row>
      </Card>
    </Col>
  );
};

ShowComment.propTypes = {
  data: PropTypes.shape({
    services: PropTypes.arrayOf(
      PropTypes.shape({
        comment: PropTypes.string
      })
    )
  })
};

ShowComment.defaultProps = {
  data: undefined
};
