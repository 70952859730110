import { useEffect } from 'react';
import { Col, Card, Flex, Tag } from 'antd';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import { useTranslation } from 'react-i18next';
import { routes } from '../../../utils/constants/adminRoutes';
import InterventionContextProvider from '../InterventionContext';
import { formatValue } from '../../../utils/formatNumber';

dayjs.extend(duration);

export const PrincipalInformations = ({ data }) => {
  const { calculateInitialTimer, setTimer, timer, user } =
    InterventionContextProvider();
  const { t } = useTranslation();

  useEffect(() => {
    let intervalId;
    if (data?.status === 'OPEN') {
      intervalId = setInterval(() => {
        setTimer(calculateInitialTimer());
      }, 1000);
    }
    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [data]);

  return (
    <Col span="24">
      <Card>
        <Flex justify="space-between" align="center">
          <Flex align="center">
            <Tag style={{ borderStyle: 'dashed' }}>
              <a
                href={`${routes.CUSTOMERS}/show/${data?.customer?._id}`}
                target="_blank"
                rel="noopener noreferrer"
                aria-label="Voir le client"
              >
                {data?.ps_number}
              </a>
            </Tag>
            {`${t(`employments.tags.${data?.contract?.company}`)} - ${
              data?.user?.first_name
            } ${
              data?.user?.usage_last_name || data?.user?.last_name
            } - ${formatValue(data?.scheduled_start_date, 'date')} - ${
              user.role !== 'guests:GUEST'
                ? `${formatValue(data?.scheduled_start_date, 'time')} à`
                : ''
            } ${formatValue(data?.scheduled_end_date, 'time')}`}
          </Flex>
          {user.role !== 'guests:GUEST' && (
            <Tag>
              {data?.start_date
                ? `${timer.hours().toString().padStart(2, '0')} : ${timer
                    .minutes()
                    .toString()
                    .padStart(2, '0')} : ${timer
                    .seconds()
                    .toString()
                    .padStart(2, '0')}`
                : '00:00:00'}
            </Tag>
          )}
        </Flex>
      </Card>
    </Col>
  );
};

PrincipalInformations.propTypes = {
  data: PropTypes.shape({
    pauses: PropTypes.arrayOf(PropTypes.shape({})),
    status: PropTypes.string,
    ps_number: PropTypes.string,
    scheduled_start_date: PropTypes.string,
    scheduled_end_date: PropTypes.string,
    start_date: PropTypes.string,
    contract: PropTypes.shape({
      company: PropTypes.string
    }),
    user: PropTypes.shape({
      usage_last_name: PropTypes.string,
      first_name: PropTypes.string,
      last_name: PropTypes.string
    }),
    customer: PropTypes.shape({
      _id: PropTypes.string
    })
  })
};

PrincipalInformations.defaultProps = {
  data: undefined
};
