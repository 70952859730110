import { Col, Card, Row, Steps } from 'antd';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { formatValue } from '../../../utils/formatNumber';

/**
 * Component for displaying quote historic.
 *
 * @component
 * @param {object} props - Component props
 * @param {object} props.data - Quote data containing historic
 * @returns {JSX.Element} Component JSX
 */

export const ShowHistoric = ({ data }) => {
  const { t } = useTranslation();

  const steps = (data?.historic || [])
    .map((historic) => ({
      title: t(
        `${historic?.modelName}.historic.${
          historic?.type === 'POST' ? historic?.type : historic?.status
        }`
      ),
      description: `le ${formatValue(historic.date, 'date')} à ${formatValue(
        historic.date,
        'time'
      )}  par ${historic?.author?.first_name} ${
        historic?.author?.usage_last_name || historic?.author?.last_name
      } ${
        historic?.status === 'REFUSED'
          ? `=> Motif: ${t(`quotes.tags.${data?.rejection_quote_reason}`)} (${
              data?.rejection_quote_comment
            })`
          : ''
      } ${
        historic?.status === 'ODM_INVALID'
          ? `=> Motif: ${t(`quotes.tags.${data?.rejection_odm_reason}`)} (${
              data?.rejection_odm_comment
            })`
          : ''
      }`
    }))
    .reverse();

  return (
    <Col xs={24} lg={12}>
      <Card title={t('quotes.show.titles.historic')}>
        <Row align="middle">
          <Steps
            direction="vertical"
            items={steps}
            progressDot
            current={steps.length - 1}
          />
          <Col span={24}>{data?.comment}</Col>
        </Row>
      </Card>
    </Col>
  );
};

ShowHistoric.propTypes = {
  data: PropTypes.shape({
    historic: PropTypes.arrayOf(PropTypes.shape({})),
    comment: PropTypes.string,
    rejection_quote_reason: PropTypes.string,
    rejection_quote_comment: PropTypes.string,
    rejection_odm_reason: PropTypes.string,
    rejection_odm_comment: PropTypes.string
  })
};

ShowHistoric.defaultProps = {
  data: undefined
};
