import React from 'react';
import PropTypes from 'prop-types';
import { Divider, Menu } from 'antd';
import { NotificationCard } from './NotificationCard';

/**
 * NotificationMenu is a React component that displays a list of notifications in a dropdown menu.
 *
 * @component
 * @param {Array} notifications - An array of notification objects to display.
 * @param {Function} getNotifications - A callback function to retrieve updated notifications.
 * @returns {ReactNode} - A ReactNode representing the NotificationMenu component.
 */
export const NotificationMenu = ({ notifications, getNotifications }) => (
  <Menu className="notification-container">
    {notifications.map((notification, index) => (
      <React.Fragment key={notification._id}>
        <Menu.Item key={notification._id}>
          <NotificationCard
            notification={notification}
            getNotifications={getNotifications}
          />
        </Menu.Item>
        {index < notifications.length - 1 && <Divider style={{ margin: 0 }} />}
      </React.Fragment>
    ))}
  </Menu>
);

NotificationMenu.propTypes = {
  notifications: PropTypes.arrayOf(PropTypes.shape({})),
  getNotifications: PropTypes.func
};

NotificationMenu.defaultProps = {
  notifications: [],
  getNotifications: () => {}
};
