import { useState, useEffect } from 'react';
import {
  BrowserRouter,
  Route,
  Routes,
  Navigate,
  useLocation
} from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  outOfNavRoutes,
  routes,
  subRoutes
} from '../utils/constants/adminRoutes';
import { Login } from '../routes/login';
import { Home } from '../routes/home/Home';
import { Exception } from '../components';
import { useAuthContext } from '../contexts/AuthContext';
import { AdminLayout } from '../components/layouts/AdminLayout';
import { ProfileRouter } from '../routes/profile/ProfileRouter';
import { Settings } from '../routes/settings/settings';
import { UserRouter } from '../routes/users/UserRouter';
import { AdminTheme } from '../assets/styles/Theme/AdminTheme';
import { ShowCalendar } from '../routes/calendar/ShowCalendar';
import { FeesRouter } from '../routes/fees/FeesRouter';
import ConfigurationRouter from './ConfigurationRouter';
import { CustomerRouter } from '../routes/customers/CustomerRouter';
import { CustomerServiceRouter } from '../routes/CustomerServices/CustomerServiceRouter';
import { ServiceRouter } from '../routes/services/ServiceRouter';
import { QuoteRouter } from '../routes/quotes/QuoteRouter';
import { ContractRouter } from '../routes/contracts/ContractRouter';
import { CalendarContextProvider } from '../routes/calendar/CalendarContext';
import { AbsenceVacationContextProvider } from '../routes/absenceVacation/AbsenceVacationContext';
import { AbsenceVacationRouter } from '../routes/absenceVacation/AbsenceVacationRouter';
import { ArticleRouter } from '../routes/Actualities/ArticleRouter';
import { HomeContextProvider } from '../routes/home/HomeContext';
import { InternalGestion } from '../routes/internalGestion/InternalGestion';
import { InternalGestionContextProvider } from '../routes/internalGestion/InternalGestionContext';
import { OccupancyChartRouter } from '../routes/occupancy_chart/OccupancyChartRouter';
import { InterventionRouter } from '../routes/interventions/InterventionRouter';
import { MailRouter } from '../routes/mail/MailRouter';
import { CustomerAnalysisRouter } from '../routes/CustomerAnalysis/CustomerAnalysisRouter';
import { TemplateDocRouter } from '../routes/template-docs/TemplateDocsRouter';
import { QuittanceRouter } from '../routes/quittances/QuittanceRouter';
import { QualityControlRouter } from '../routes/quality_control/QualityControlRouter';
import { StatisticsRouter } from '../routes/statistics/StatisticsRouter';

const RequireAuth = ({ isValid, isAtWork }) => {
  const location = useLocation();

  if (!isValid) {
    return Navigate({
      to: {
        pathname: outOfNavRoutes.LOGIN
      },
      state: { from: location }
    });
  }
  return <AdminLayout isAtWork={isAtWork} />;
};

RequireAuth.propTypes = {
  isValid: PropTypes.bool.isRequired,
  isAtWork: PropTypes.bool.isRequired
};

export const AdminRouter = () => {
  const { isValid } = useAuthContext();
  const [isAtWork, setIsAtWork] = useState(true);

  useEffect(() => {
    const body = document.body;
    if (body) {
      body.className = 'admin';
    }
  });

  return (
    <BrowserRouter>
      <AdminTheme />
      <Routes>
        <Route
          path={`${outOfNavRoutes.LOGIN}/:purpose/:token`}
          element={<Login />}
        />
        <Route path={outOfNavRoutes.LOGIN} element={<Login />} />
        <Route path={`${outOfNavRoutes.REDIRECT}/*`} element={<Login />} />
        <Route element={<RequireAuth isValid={isValid} isAtWork={isAtWork} />}>
          <Route
            path={`${outOfNavRoutes.PROFILE}/*`}
            element={<ProfileRouter />}
          />
          <Route path={outOfNavRoutes.SETTINGS} element={<Settings />} />
          <Route
            path={routes.HOME}
            element={
              <HomeContextProvider setIsAtWork={setIsAtWork}>
                <Home />
              </HomeContextProvider>
            }
          />
          <Route path={`${routes.USERS}/*`} element={<UserRouter />} />
          <Route path={`${routes.BILLING}/*`} element={<QuittanceRouter />} />
          <Route
            path={`${routes.CALENDAR}/*`}
            element={
              <CalendarContextProvider>
                <ShowCalendar />
              </CalendarContextProvider>
            }
          />
          <Route
            path={`${routes.SETTINGS}${subRoutes.SETTINGS.CONFIGURATIONS}`}
            element={<ConfigurationRouter />}
          />
          <Route path={`${routes.FEES}/*`} element={<FeesRouter />} />
          <Route path={`${routes.CUSTOMERS}/*`} element={<CustomerRouter />} />
          <Route
            path={`${routes.RH}${subRoutes.RH.ABSENCE_VACATION}/*`}
            element={
              <CalendarContextProvider>
                <AbsenceVacationContextProvider>
                  <AbsenceVacationRouter />
                </AbsenceVacationContextProvider>
              </CalendarContextProvider>
            }
          />
          <Route
            path={`/rh${subRoutes.RH.INTERNAL_GESTION}/*`}
            element={
              <InternalGestionContextProvider>
                <InternalGestion />
              </InternalGestionContextProvider>
            }
          />
          <Route path={`${routes.QUOTE}/*`} element={<QuoteRouter />} />
          <Route path={`${routes.CONTRACT}/*`} element={<ContractRouter />} />
          <Route
            path={`${routes.QUALITY_CONTROL}/*`}
            element={<QualityControlRouter />}
          />
          <Route
            path={`${routes.OCCUPANCY_CHART}/*`}
            element={<OccupancyChartRouter />}
          />
          <Route
            path={`${routes.CUSTOMER_ANALYSIS}/*`}
            element={<CustomerAnalysisRouter />}
          />
          <Route path={`${routes.ACTUALITIES}/*`} element={<ArticleRouter />} />
          <Route
            path={`${routes.INTERVENTIONS}/*`}
            element={<InterventionRouter />}
          />
          <Route
            path={`${routes.CUSTOMERSERVICES}/*`}
            element={<CustomerServiceRouter />}
          />
          <Route path={`${routes.SERVICES}/*`} element={<ServiceRouter />} />
          <Route
            path={`${routes.TEMPLATE_DOCS}/*`}
            element={<TemplateDocRouter />}
          />
          <Route path={`${routes.MAILS}/*`} element={<MailRouter />} />
          <Route
            path={`${routes.STATISTICS}/*`}
            element={<StatisticsRouter />}
          />
          <Route path="*" element={<Exception />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
};
