import PropTypes from 'prop-types';
import {
  CheckCircleOutlined,
  DownloadOutlined,
  EyeOutlined
} from '@ant-design/icons';
import { Col, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import { useDownloadDocument } from '../../../../utils/downloadDoc';

/**
 * TestFile component that shows the test file of the quality control
 *
 * @component
 * @param {object} testFile - Test file object
 * @return {component} - TestFile component
 */
export const TestFile = ({ testFile }) => {
  const { t } = useTranslation();
  const { downloadDocument, viewDocument } = useDownloadDocument();

  return (
    <Row justify="space-between">
      <Col>
        <Row gutter={16}>
          <Col>
            <CheckCircleOutlined style={{ color: 'green' }} />
          </Col>
          <Col>{t('quality.form.test.title').toUpperCase()}</Col>
        </Row>
      </Col>
      {testFile && (
        <Col>
          <Row gutter={16}>
            <Col>{testFile.metadata.originalName}</Col>
            <Col>
              <EyeOutlined
                onClick={() => {
                  viewDocument(testFile);
                }}
              />
            </Col>
            <Col>
              <DownloadOutlined onClick={() => downloadDocument(testFile)} />
            </Col>
          </Row>
        </Col>
      )}
    </Row>
  );
};

TestFile.propTypes = {
  testFile: PropTypes.shape({
    metadata: PropTypes.shape({
      originalName: PropTypes.string
    })
  })
};

TestFile.defaultProps = {
  testFile: null
};
