import { useCallback, useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { useParams, useLocation } from 'react-router-dom';
import { Button, Row, Affix, Col, Flex, Popconfirm } from 'antd';
import { useTranslation } from 'react-i18next';
import { PlusOutlined, CheckOutlined } from '@ant-design/icons';
import { useAuthContext } from '../../contexts/AuthContext';
import { useErrorMessage } from '../../utils/errorMessage';
import { ContentCustom } from '../../components';
import InterventionContextProvider from './InterventionContext';
import { interventionsStatus } from '../../utils/constants/tagColors';
import { PrincipalInformations } from './ShowCards/PrincipalInformations';
import { ExtraInformations } from './ShowCards/ExtraInformations';
import { ListActionsReminder } from './ShowCards/ListActionsReminder';
import { ListActions } from './ShowCards/ListActions';
import { Comments } from './ShowCards/Comments';
import { Documents } from './ShowCards/Documents';
import { Outstanding } from './ShowCards/Outstanding';
import InformationsDrawer from './InformationsDrawer';
import WebSitesModal from './WebSitesModal';
import ActionModal from './ActionModal';
import MailModal from './MailModal';
import OutstandingModal from './OutstandingModal';
import PageHeader from './PageHeader';
import { QualityControlModal } from './QualityControlModal';
import { isAuthorized } from '../../utils/constants/authorizedMenu';
import { CustomSpin } from '../../components/CustomSpin/CustomSpin';
import EmailModal from '../../components/EmailModal/EmailModal';

/**
 * Component for displaying detailed information about an intervention.
 *
 * @component
 * @returns {JSX.Element} Rendered ShowIntervention component
 */
export const ShowIntervention = () => {
  const { id } = useParams();
  const { t } = useTranslation();
  const { message } = useErrorMessage();
  const { dispatchAPI, user } = useAuthContext();
  const {
    intervention,
    setIntervention,
    forceRefresh,
    patchPauses,
    startIntervention,
    setCustomerComments,
    rejections,
    outstandings,
    firstOutstanding,
    getRejections,
    getOutstandings,
    getFirstOutstanding,
    getPsMacro,
    getActions,
    forceActionsRefresh,
    setForceActionsRefresh,
    onCancelEmailSent,
    onArchived,
    openMailModal,
    isMailModalOpen,
    closeMailModal,
    customNavigateMail,
    selectedRowKeys,
    openOutstandingModal,
    outstandingPurpose,
    isOutstandingModalOpen,
    closeOutstandingModal,
    customNavigateOutstanding,
    refresh,
    forceWebSitesRefresh,
    onEdit,
    isActionReminder,
    actionPurpose,
    setActionPurpose,
    setSelectedRowKeys,
    idActionFromOverlay,
    setIdActionFromOverlay,
    isActionModalOpen,
    setIsActionModalOpen,
    openDrawer,
    closeDrawer,
    openActionModal,
    closeActionModal,
    customNavigateAction,
    isDrawerOpen,
    type,
    handleCloseIntervention,
    openModalShow,
    closeModalShow,
    isWebsiteModalOpen,
    isEmailModalOpen,
    setIsEmailModalOpen
  } = InterventionContextProvider();
  const [isLoading, setIsLoading] = useState(false);
  const [isQualityControlOpen, setIsQualityControlOpen] = useState(false);
  const location = useLocation();
  const { pathname } = location;

  const hasAccess = (purpose) => isAuthorized(pathname, user?.role, purpose);

  const availableClosedStatusButton = ['OPEN', 'SUSPENDED'];

  const getIntervention = useCallback(async () => {
    setIsLoading(true);
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/interventions/${id}?populate=contract,service,user,leader,customer.contacts bank_reconciliation info_sheet,files,user_referent,qualities.files`
      });
      setIntervention(data);
      setCustomerComments(data?.customer?.customer_comments || []);
    } catch (e) {
      message(e);
    }
  }, [id]);

  const closeEmailModal = () => {
    setIsEmailModalOpen(false);
  };

  useEffect(() => {
    (async () => {
      await getIntervention();
      setIsLoading(false);
    })();
  }, [getIntervention, forceRefresh]);

  useEffect(() => {
    (async () => {
      await getActions();
      if (intervention?.customer?.macro_ps) await getPsMacro();
      if (intervention?.customer?._id) {
        await getRejections();
        await getOutstandings();
        await getFirstOutstanding();
      }
    })();
  }, [forceActionsRefresh, intervention]);

  return isLoading ? (
    <ContentCustom>
      <CustomSpin />
    </ContentCustom>
  ) : (
    <ContentCustom>
      {isOutstandingModalOpen && (
        <OutstandingModal
          isOutstandingModalOpen={isOutstandingModalOpen}
          closeOutstandingModal={closeOutstandingModal}
          purpose={outstandingPurpose}
          outstanding={(outstandings || []).filter(
            (f) => f?.intervention === intervention?._id
          )}
          customNavigateOutstanding={customNavigateOutstanding}
          user={user}
        />
      )}
      {isEmailModalOpen && (
        <EmailModal
          isModalOpen={isEmailModalOpen}
          closeModal={closeEmailModal}
          contacts={intervention?.customer?.contacts || []}
          baseUrl={`emails?type=intervention_report&id=${intervention?._id}`}
          extraQuery="status=VALIDATED"
          company={t(`employments.tags.${intervention?.customer?.company}`)}
          subject={`[${
            intervention?.customer?.company
          }] - Mail de compte rendu intervention N° ${
            intervention?.code
          } du ${dayjs().format('DD/MM/YYYY')}`}
          useCaseFilter="INTERVENTION_REPORT"
          successMessageUrl="emails"
          modelName="Intervention"
          idModal={intervention?._id}
        />
      )}
      {isMailModalOpen && (
        <MailModal
          isMailModalOpen={isMailModalOpen}
          closeMailModal={closeMailModal}
          selectedRowKeys={selectedRowKeys}
          intervention={intervention}
          customNavigateMail={customNavigateMail}
          extraQuery="status=VALIDATED"
          modelName="Intervention"
          useCaseFilter="ACTION"
          setForceActionsRefresh={setForceActionsRefresh}
          forceActionsRefresh={forceActionsRefresh}
          contacts={
            intervention?.customer?.contacts?.filter(
              (f) => f.status === 'ACTIVE'
            ) || []
          }
          interventionId={intervention?._id}
          company={intervention?.customer?.company}
        />
      )}
      {isDrawerOpen && (
        <InformationsDrawer
          isDrawerOpen={isDrawerOpen}
          closeDrawer={closeDrawer}
          type={type}
          intervention={intervention}
        />
      )}
      {isWebsiteModalOpen && (
        <WebSitesModal
          isWebsiteModalOpen={isWebsiteModalOpen}
          closeModal={closeModalShow}
          id={intervention.customer?._id}
          refresh={refresh}
          forceWebSitesRefresh={forceWebSitesRefresh}
        />
      )}
      {isQualityControlOpen && (
        <QualityControlModal
          id={id}
          isQualityControlOpen={isQualityControlOpen}
          setIsQualityControlOpen={setIsQualityControlOpen}
        />
      )}
      {isActionModalOpen && (
        <ActionModal
          isActionModalOpen={isActionModalOpen}
          closeActionModal={closeActionModal}
          purpose={actionPurpose}
          idFromOverlay={idActionFromOverlay}
          customNavigateAction={customNavigateAction}
          isActionReminder={isActionReminder}
          customer={intervention.customer?._id}
        />
      )}
      <PageHeader
        intervention={intervention}
        interventionsStatus={interventionsStatus}
        startIntervention={startIntervention}
        patchPauses={patchPauses}
        setIsQualityControlOpen={setIsQualityControlOpen}
        id={id}
        hasAccess={hasAccess}
      />
      {!isLoading && (
        <Row gutter={[16, 16]}>
          <PrincipalInformations data={intervention} />
          <ExtraInformations
            data={intervention}
            openDrawer={openDrawer}
            openModal={openModalShow}
            rejections={rejections}
            outstandings={outstandings}
            user={user}
            hasAccess={hasAccess}
          />
          {user.role !== 'guests:GUEST' && (
            <ListActionsReminder
              interventionId={id}
              forceActionsRefresh={forceActionsRefresh}
              setActionPurpose={setActionPurpose}
              setIdActionFromOverlay={setIdActionFromOverlay}
              setIsActionModalOpen={setIsActionModalOpen}
              setForceActionsRefresh={setForceActionsRefresh}
              onEdit={onEdit}
              onArchived={onArchived}
            />
          )}
          <ListActions
            interventionId={id}
            forceActionsRefresh={forceActionsRefresh}
            openActionModal={openActionModal}
            onEdit={onEdit}
            onArchived={onArchived}
            onCancelEmailSent={onCancelEmailSent}
            openMailModal={openMailModal}
            selectedRowKeys={selectedRowKeys}
            setSelectedRowKeys={setSelectedRowKeys}
          />
          <Col span={24}>
            <Row align="stretch" gutter={[16, 16]}>
              <Col xl={16}>
                <Comments intervention={intervention} hasAccess={hasAccess} />
              </Col>
              <Col xl={8}>
                <Flex vertical gap={16}>
                  <Documents data={intervention} hasAccess={hasAccess} />
                  <Outstanding
                    outstanding={(outstandings || []).filter(
                      (f) => f?.intervention === intervention?._id
                    )}
                    openOutstandingModal={openOutstandingModal}
                    firstOutstanding={firstOutstanding}
                    intervention={intervention}
                    hasAccess={hasAccess}
                  />
                </Flex>
              </Col>
            </Row>
          </Col>
          {!user.role.startsWith('guests') &&
            availableClosedStatusButton.includes(intervention?.status) && (
              <Col span={24}>
                <Flex justify="end">
                  <Popconfirm
                    title={t(
                      `datatable.column.action.close_intervention.title${
                        (outstandings || []).find(
                          (f) => f?.intervention === intervention?._id
                        )
                          ? ''
                          : '_no'
                      }`
                    )}
                    okText={t('datatable.column.action.close_intervention.ok')}
                    okButtonProps={{ type: 'danger' }}
                    cancelText={t(
                      'datatable.column.action.close_intervention.cancel'
                    )}
                    onConfirm={() => {
                      handleCloseIntervention();
                    }}
                    style={{ marginTop: 20 }}
                  >
                    <Button type="danger" danger>
                      <CheckOutlined />
                      {t('buttons.validate_intervention')}
                    </Button>
                  </Popconfirm>
                </Flex>
              </Col>
            )}
          {hasAccess('create') && (
            <Affix
              style={{
                position: 'fixed',
                bottom: '20px',
                right: '50px',
                zIndex: 100
              }}
            >
              <Button type="primary" onClick={openActionModal}>
                <PlusOutlined />
                {t('buttons.create_action')}
              </Button>
            </Affix>
          )}
        </Row>
      )}
    </ContentCustom>
  );
};
