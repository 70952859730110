import React, { createContext, useContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useAuthContext } from '../../contexts/AuthContext';
import { useErrorMessage } from '../../utils/errorMessage';

const QuoteContext = createContext({});

/**
 * Context provider component for managing quote-related data.
 * This context provides various state values and methods related to quotes.
 *
 * @component
 * @param {object} props - React component props
 * @param {React.ReactNode} props.children - The child components that will have access to the context
 * @returns {React.ReactNode} Wrapped children with the quote context
 */

export const QuoteContextProvider = ({ children }) => {
  const { dispatchAPI, user } = useAuthContext();
  const { message } = useErrorMessage();
  const [formValues, setFormValues] = useState(null);
  const [quoteEnums, setQuoteEnums] = useState({});
  const [customerEnums, setCustomerEnums] = useState({});
  const [serviceEnums, setServiceEnums] = useState({});
  const [psMicro, setPsMicro] = useState();
  const [sales, setSales] = useState([]);
  const [customers, setCustomers] = useState([]);

  const getQuoteEnums = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/quotes/enums`
      });
      setQuoteEnums(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getCustomerEnums = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/customers/enums`
      });
      setCustomerEnums(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getServiceEnums = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/services/enums`
      });
      setServiceEnums(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getMicroPs = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/Psmicro?sort=title`
      });
      setPsMicro(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getSales = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: '/users'
      });
      setSales(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getCustomers = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: '/customers?sort=ps_number'
      });
      setCustomers(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  useEffect(() => {
    (async () => {
      await getQuoteEnums();
      await getCustomerEnums();
      await getServiceEnums();
      await getMicroPs();
      await getSales();
      await getCustomers();
    })();
  }, []);

  return (
    <QuoteContext.Provider
      value={{
        quoteEnums,
        customerEnums,
        serviceEnums,
        psMicro,
        sales,
        customers,
        setFormValues,
        formValues,
        user
      }}
    >
      {children}
    </QuoteContext.Provider>
  );
};

QuoteContextProvider.propTypes = {
  children: PropTypes.element.isRequired
};

export default () => useContext(QuoteContext);
