import { useState, useEffect } from 'react';
import { DatePicker, Flex, Select } from 'antd';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { ListResource } from '../../components';
import { useColumns } from './columns';
import { headers } from './headers';
import useInternalGestionContext from './InternalGestionContext';

const { RangePicker } = DatePicker;
const { Option } = Select;

export const ListInternalGestion = ({ switchElement }) => {
  const { t } = useTranslation();

  const { getLeaders, getEnums, users } = useInternalGestionContext();

  const onsearchUser = (input, option) =>
    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;

  const [startOfWeek, setStartOfWeek] = useState();
  const [endOfWeek, setEndOfWeek] = useState();
  const [leaders, setLeaders] = useState([]);
  const [selectedPerson, setSelectedPerson] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedUser, setSelectedUser] = useState();
  const [enums, setEnums] = useState({});
  const columns = useColumns(enums);

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      const leaderData = await getLeaders();
      const enumsData = await getEnums();
      setLeaders(leaderData);
      setEnums(enumsData);
      setIsLoading(false);
    })();
  }, []);

  return (
    <ListResource
      resourceName="internalGestion"
      columns={columns}
      headers={headers}
      populate="user"
      scroll={{ x: 1500 }}
      resourceModelName="Internalgestion"
      withCreateButton={false}
      editAction={false}
      deleteAction={false}
      withPageHeader={false}
      withUploadButton
      showAction={false}
      onDoubleClickAction={false}
      contentCustomStyle={{ margin: 0, padding: 0 }}
      flexWrap
      extraButtons={switchElement}
      extraQuery={`${
        startOfWeek && endOfWeek
          ? `&start=${startOfWeek.format('YYYY-MM-DD')}&end=${endOfWeek.format(
              'YYYY-MM-DD'
            )}`
          : ''
      }${selectedUser ? `&leader=${selectedUser}` : ''}${
        selectedPerson ? `&user=${selectedPerson}` : ''
      }`}
      extraFilters={
        <Flex align="center" gap="small" wrap>
          <RangePicker
            format="DD-MM-YYYY"
            picker="week"
            onChange={(dates) => {
              if (dates && dates.length === 2) {
                setStartOfWeek(dayjs(dates[0]).startOf('week'));
                setEndOfWeek(dayjs(dates[1]).endOf('week'));
              } else {
                setStartOfWeek(null);
                setEndOfWeek(null);
              }
            }}
          />
          <Select
            style={{ width: 200 }}
            placeholder={t('internalGestion.select.leader')}
            showSearch
            allowClear
            loading={isLoading}
            filterOption={(input, option) => onsearchUser(input, option)}
            onSelect={(value) => {
              setSelectedUser(value);
            }}
            onClear={() => {
              setSelectedUser();
            }}
          >
            {leaders?.map((user) => (
              <Option key={user._id} value={user._id}>
                {`${user.first_name} ${
                  user?.usage_last_name || user?.last_name
                }`}
              </Option>
            ))}
          </Select>
          <Select
            allowClear
            placeholder={t('internalGestion.select.collaborator')}
            onChange={(value) => setSelectedPerson(value)}
            onClear={() => {
              setSelectedPerson(null);
            }}
            style={{ width: 180 }}
          >
            {users?.map((user) => (
              <Option key={user._id} value={user._id}>
                {`${user.first_name} ${
                  user?.usage_last_name || user?.last_name
                }`}
              </Option>
            ))}
          </Select>
        </Flex>
      }
    />
  );
};

ListInternalGestion.propTypes = {
  switchElement: PropTypes.node.isRequired
};
