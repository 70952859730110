import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import { DatePicker } from 'antd';
import { useTranslation } from 'react-i18next';
import { ListResource } from '../../../components';
import { useColumns } from './columns/InterventionColumns';
import { headers } from '../../interventions/headers';
import { useFormatter } from '../../../utils/formatter';
import { useAuthContext } from '../../../contexts/AuthContext';
import { useErrorMessage } from '../../../utils/errorMessage';
import { showSuccessMessage } from '../../../utils/showSuccessMessage';

const { RangePicker } = DatePicker;

/**
 * Component for listing interventions with filtering options.
 *
 * @component
 * @returns {JSX.Element} Rendered ListInterventions component
 */

export const ListInterventions = ({ id, withoutUploadButton }) => {
  const { formattedData } = useFormatter();
  const { dispatchAPI } = useAuthContext();
  const { t } = useTranslation();
  const { message } = useErrorMessage();
  const [filterDate, setFilterDate] = useState([]);
  const [interventionEnums, setInterventionEnums] = useState({});
  const [leadersAndGtp, setLeadersAndGtp] = useState([]);
  const [forceRefresh, setForceRefresh] = useState(false);

  const deleteResource = async (idIntervention) => {
    try {
      await dispatchAPI('DELETE', {
        url: `/interventions/delete/${idIntervention}`,
        body: { status: 'ARCHIVED' }
      });
      setForceRefresh(true);
      showSuccessMessage(t, 'interventions', 'archive');
    } catch (e) {
      message(e);
    }
  };
  const columns = useColumns(interventionEnums, leadersAndGtp, deleteResource);

  const getInterventionEnums = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/interventions/enums`
      });
      setInterventionEnums(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getLeadersAndGtp = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `users?role=users:LEADER-USER,users:USER&noFilterByRole=${true}`
      });
      setLeadersAndGtp(data);
    } catch (error) {
      message(error);
    }
  };

  useEffect(() => {
    (async () => {
      await getInterventionEnums();
      await getLeadersAndGtp();
    })();
  }, []);

  return (
    <ListResource
      resourceName="interventions"
      contentCustomStyle={{ margin: 0, padding: 0 }}
      columns={columns}
      headers={headers}
      onDoubleClickAction={false}
      populate="contract,user"
      resourceModelName="Intervention"
      withImportButton={false}
      withUploadButton={!withoutUploadButton}
      customActionColumn
      forceRefresh={forceRefresh}
      formatter={formattedData}
      withCreateButton={false}
      extraQuery={`customer=${id}&noFilterByRole=${true}${
        filterDate?.length
          ? `&start_date>=${dayjs(
              new Date(filterDate[0]).setHours(0, 0, 0, 0)
            ).toISOString()}&end_date<=${dayjs(
              new Date(filterDate[1]).setHours(23, 59, 59, 999)
            ).toISOString()}`
          : ''
      }`}
      extra
      extraFilters={
        <RangePicker
          format="DD-MM-YYYY"
          style={{ marginLeft: '20px' }}
          onChange={setFilterDate}
        />
      }
    />
  );
};

ListInterventions.propTypes = {
  id: PropTypes.string.isRequired,
  withoutUploadButton: PropTypes.bool
};

ListInterventions.defaultProps = {
  withoutUploadButton: undefined
};
