import { useTranslation } from 'react-i18next';
import { Button, Divider, Tag, Popconfirm } from 'antd';
import { useLocation } from 'react-router-dom';
import dayjs from 'dayjs';
import {
  EyeOutlined,
  EditOutlined,
  ContainerOutlined
} from '@ant-design/icons';
import { levelEmployees } from '../../../../utils/constants/tagColors';
import { calculateHoursPerMonth } from './calculateHoursPerMonth';
import UserContextProvider from '../../UserContext';
import { useAuthContext } from '../../../../contexts/AuthContext';
import { isAuthorized } from '../../../../utils/constants/authorizedMenu';
import { formatValue } from '../../../../utils/formatNumber';

const iconSize = 18;

export const useExpandableColumns = (options) => {
  const { t } = useTranslation();
  const { user } = useAuthContext();
  const location = useLocation();
  const { pathname } = location;
  const { levels } = UserContextProvider();

  const hasAccess = (purpose) => isAuthorized(pathname, user?.role, purpose);

  return [
    {},
    {
      title: t('employments.columns.job_requirement'),
      key: 'job_requirement',
      dataIndex: ['job_requirement'],
      render: (job_requirement) => (
        <Tag color={levelEmployees[job_requirement]}>
          {t(`employments.columns.${job_requirement}`)}
        </Tag>
      ),
      sorter: (a, b) => {
        const indexA = levels.indexOf(a.job_requirement);
        const indexB = levels.indexOf(b.job_requirement);
        if (indexA === -1 || indexB === -1) {
          return 0;
        }
        return indexA - indexB;
      }
    },
    {
      title: t('employments.columns.start_date'),
      key: 'start_date',
      dataIndex: ['start_date'],
      render: (start_date) => start_date && formatValue(start_date, 'date'),
      sorter: (a, b) => dayjs(a.start_date).unix() - dayjs(b.start_date).unix()
    },
    {
      title: t('employments.columns.end_date_amendment'),
      key: 'end_date_amendment',
      dataIndex: ['end_date_amendment'],
      render: (end_date_amendment) =>
        end_date_amendment && formatValue(end_date_amendment, 'date'),
      sorter: (a, b) =>
        dayjs(a.end_date_amendment).unix() - dayjs(b.end_date_amendment).unix()
    },
    {
      title: t('employments.columns.hours_per_month'),
      key: 'employment',
      dataIndex: ['employment'],
      render: (_, employment) => calculateHoursPerMonth(employment).toFixed(2)
    },
    {
      key: 'action',
      align: 'right',
      render: (record) => (
        <>
          <Button
            type="ghost"
            onClick={() => options?.show(record._id)}
            icon={<EyeOutlined style={{ fontSize: iconSize }} />}
          />
          {hasAccess('create') && !record?.end_date_amendment && (
            <>
              <Divider type="vertical" />
              <Button
                type="ghost"
                onClick={() => options?.edit(record._id)}
                icon={<EditOutlined style={{ fontSize: iconSize }} />}
              />
            </>
          )}
          {hasAccess('edit') && (
            <>
              <Divider type="vertical" />
              <Popconfirm
                title={t('messages.warning.delete')}
                onConfirm={() => options?.delete(record._id)}
                placement="topRight"
              >
                <Button
                  type="ghost"
                  icon={
                    <ContainerOutlined
                      style={{ fontSize: iconSize, color: 'red' }}
                    />
                  }
                />
              </Popconfirm>
            </>
          )}
        </>
      )
    }
  ];
};
