import { InputNumber, Upload, Button } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { useParams } from 'react-router-dom';
import { ColorSelector } from '../ColorSelector';
import useQualityControlContext from '../QualityControlContext';

/**
 * Hook for the test fields.
 *
 * @hook
 * @param {string} purpose - The purpose of the form
 * @param {Object} t - i18n translate function
 * @returns {Object} The test fields
 * @returns {Array} testFields - Array of test fields
 */
const useTestFields = (purpose, t) => {
  const {
    testAssessment,
    setTestAssessment,
    testFile,
    setTestFile,
    uploadOrDeleteFile,
    isLoading
  } = useQualityControlContext();
  const { id } = useParams();

  const testFields = [
    {
      name: ['test', 'grade'],
      input: <InputNumber min={0} max={100} addonAfter="/100" />
    },
    {
      name: ['test', 'assessment'],
      input: (
        <ColorSelector color={testAssessment} setColor={setTestAssessment} />
      )
    },
    {
      name: ['test', 'test_file'],
      input: (
        <Upload
          beforeUpload={(file) => {
            if (purpose !== 'edit') {
              const newFile = [...testFile];
              newFile.push(file);
              setTestFile(newFile);
            } else {
              uploadOrDeleteFile(file, id, 'upload', 'test.test');
              setTestFile(file);
            }
            return false;
          }}
          onRemove={() => {
            if (purpose !== 'edit') {
              setTestFile();
            } else {
              uploadOrDeleteFile(testFile, id, 'delete', 'test_file');
              setTestFile();
            }
          }}
          fileList={
            isLoading
              ? []
              : (testFile ? [testFile] : []).map((file, index) => ({
                  uid: index,
                  name: file?.metadata?.originalName || file?.name,
                  status: 'done'
                }))
          }
        >
          <Button type="dashed" icon={<UploadOutlined />} block>
            {t('quality.form.test.upload')}
          </Button>
        </Upload>
      )
    }
  ];

  return {
    testFields: testFields.map((f) => f)
  };
};

export default useTestFields;
