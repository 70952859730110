import { createContext, useContext, useState, useEffect } from 'react';
import dayjs from 'dayjs';
import { message as antdMessage } from 'antd';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import moment from 'moment';
import { useAuthContext } from '../../contexts/AuthContext';
import { useErrorMessage } from '../../utils/errorMessage';

const HomeContext = createContext({});

export const HomeContextProvider = ({ children, setIsAtWork }) => {
  const { dispatchAPI, user, workHours, setWorkHours } = useAuthContext();
  const { message } = useErrorMessage();
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [interventions, setInterventions] = useState([]);
  // const [workHours, setWorkHours] = useState([]);
  const [leaders, setLeaders] = useState([]);
  const [positions, setPositions] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [qualityEnums, setQualityEnums] = useState([]);
  const [ActionEnums, setActionEnums] = useState([]);
  const [psMacro, setPsMacro] = useState([]);
  const [psMicro, setPsMicro] = useState([]);
  const [forceRefreshList, setForceRefreshList] = useState(false);
  const isAdmin = user.role.split(':')[0] === 'admins';

  const calculateInitialTimer = () => {
    if (workHours?.start_time) {
      const totalPauseDuration =
        workHours?.pause?.reduce((total, pause) => {
          const start = dayjs(pause.start_time);
          const end = dayjs(pause.end_time);
          return total + end.diff(start);
        }, 0) || 0;

      const totalDurationSinceStart = dayjs().diff(
        dayjs(workHours?.start_time)
      );
      const effectiveDuration = totalDurationSinceStart - totalPauseDuration;
      return dayjs.duration(effectiveDuration);
    }
    return dayjs.duration(0);
  };

  const [timer, setTimer] = useState(calculateInitialTimer);

  const filterDuplicatePsNumbers = (inters) => {
    const seen = new Map();
    inters.forEach((intervention) => {
      const ps_number = intervention.ps_number;
      if (!seen.has(ps_number)) {
        seen.set(ps_number, intervention);
      }
    });
    return Array.from(seen.values());
  };

  const getInterventions = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/interventions?user=${user._id}`
      });
      setInterventions(filterDuplicatePsNumbers(data));
    } catch (e) {
      message(e);
    }
  };

  const getLeaders = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: 'users?role=users:LEADER-USER'
      });
      setLeaders(data);
    } catch (error) {
      message(error);
    }
  };

  const getPositions = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: 'users/enums'
      });
      setPositions(data.roles);
    } catch (error) {
      message(error);
    }
  };

  const getCompanies = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: 'customers?fields=company_name,ps_number'
      });
      setCompanies(data);
    } catch (error) {
      message(error);
    }
  };

  const getWorkHours = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/workhours?user=${user._id}&date=${moment().format('YYYY-MM-DD')}`
      });
      if (data.length) {
        setWorkHours(data[0]);
      } else {
        const { data: data2 } = await dispatchAPI('POST', {
          url: `/workhours`,
          body: {
            user: user._id,
            date: moment().format('YYYY-MM-DD'),
            start_time: moment().format('YYYY-MM-DD HH:mm:ss'),
            current_state: 'work',
            status: 'STARTED',
            work: [
              {
                start_time: moment().format('YYYY-MM-DD HH:mm:ss')
              }
            ]
          }
        });
        setWorkHours(data2);
      }
    } catch (e) {
      message(e);
    }
  };

  const endWorkDay = async () => {
    try {
      await dispatchAPI('PATCH', {
        url: `/workhours/${workHours._id}`,
        body: {
          current_state: 'stopped',
          status: 'ENDED'
        }
      });
      setRefresh(!refresh);
      setIsAtWork(false);
      antdMessage.success(t('messages.badgeuse.end_work_day'));
    } catch (e) {
      message(e);
    }
  };

  const deleteEndWorkDay = async () => {
    try {
      await dispatchAPI('PATCH', {
        url: `/workhours/${workHours._id}`,
        body: {
          end_time: null,
          current_state: 'work',
          status: 'STARTED'
        }
      });
      setForceRefreshList(!forceRefreshList);
      antdMessage.success(t('messages.badgeuse.end_work_day_cancel'));
    } catch (e) {
      message(e);
    }
  };

  const changeCurrentState = async (state) => {
    try {
      await dispatchAPI('PATCH', {
        url: `/workhours/${workHours._id}`,
        body: {
          current_state: state
        }
      });
      setRefresh(!refresh);
      setIsAtWork(state !== 'pause');
      antdMessage.success(t(`messages.badgeuse.${state}`));
    } catch (e) {
      message(e);
    }
  };

  const getQualityEnums = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/quality/enums`
      });
      setQualityEnums(data);
    } catch (e) {
      message(e);
    }
  };

  const getActionEnums = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/actions/enums`
      });
      setActionEnums(data);
    } catch (e) {
      message(e);
    }
  };

  const getPsMacro = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/psmacro?sort=title`
      });
      setPsMacro(data);
    } catch (e) {
      message(e);
    }
  };

  const getPsMicro = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/psmicro?sort=title`
      });
      setPsMicro(data);
    } catch (e) {
      message(e);
    }
  };

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      if (user?.role !== 'users:SALES-USER') await getInterventions();
      await getWorkHours();
      await getLeaders();
      await getPositions();
      await getCompanies();
      await getQualityEnums();
      await getActionEnums();
      await getPsMacro();
      await getPsMicro();
      setIsLoading(false);
    })();
  }, [refresh]);

  useEffect(() => {
    if (workHours?.status === 'ENDED' && !isAdmin) setIsAtWork(false);
  }, [workHours]);

  return (
    <HomeContext.Provider
      value={{
        isLoading,
        refresh,
        setRefresh,
        interventions,
        workHours,
        endWorkDay,
        changeCurrentState,
        leaders,
        positions,
        companies,
        deleteEndWorkDay,
        forceRefreshList,
        qualityEnums,
        ActionEnums,
        psMacro,
        psMicro,
        timer,
        setTimer,
        calculateInitialTimer
      }}
    >
      {children}
    </HomeContext.Provider>
  );
};

HomeContextProvider.propTypes = {
  children: PropTypes.element.isRequired,
  setIsAtWork: PropTypes.func.isRequired
};

export default () => useContext(HomeContext);
