import { createContext, useContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useAuthContext } from '../../contexts/AuthContext';
import { useErrorMessage } from '../../utils/errorMessage';

const FeesContext = createContext({});

export const FeesContextProvider = ({ children }) => {
  const { dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();
  const [enums, setEnums] = useState({});
  const [formValues, setFormValues] = useState(null);
  const [refresh, setRefresh] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [service, setService] = useState(null);
  const [accounts, setAccounts] = useState([]);
  const [ps_micro, setPs_micro] = useState(null);

  const getEnums = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/fees/enums`
      });
      setEnums(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getPsMicro = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/psmicro?sort=title`
      });
      setPs_micro(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  useEffect(() => {
    if (service) {
      (async () => {
        try {
          const { data } = await dispatchAPI('GET', {
            url: `/accounts?title=${service}`
          });
          setAccounts(data[0]);
        } catch (e) {
          if (e.response) message(e.response.status);
        }
      })();
    }
  }, [service]);

  // TO DELETE
  useEffect(() => {
    setRefresh(false);
  }, []);

  useEffect(() => {
    (async () => {
      await getEnums();
      await getPsMicro();
      setIsLoading(false);
    })();
  }, []);

  return (
    <FeesContext.Provider
      value={{
        enums,
        formValues,
        setFormValues,
        refresh,
        isLoading,
        accounts,
        ps_micro,
        setService
      }}
    >
      {children}
    </FeesContext.Provider>
  );
};

FeesContextProvider.propTypes = {
  children: PropTypes.element.isRequired
};

export default () => useContext(FeesContext);
