import { Card, Typography } from 'antd';

/**
 * cardRenderer is a utility function that renders a Card component with the specified content, color, and title.
 *
 * @component
 * @param {React.ReactNode} content - The content to be rendered within the Card.
 * @param {string} color - The border color of the Card.
 * @param {string} title - The title to be displayed on the Card.
 * @param {string} aditionalTitle - The aditional title to be displayed on the Card.
 * @returns {React.ReactNode} The rendered Card component with the provided content, color, and title.
 */

export const cardRenderer = (content, color, title, aditionalTitle) => (
  <Card style={{ borderColor: color }}>
    <Typography.Title level={4} strong={false}>
      <span
        style={{
          color,
          marginRight: '1em'
        }}
      >
        {title}
      </span>
      {aditionalTitle}
    </Typography.Title>
    {content}
  </Card>
);
