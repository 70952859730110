import { useEffect, useState } from 'react';
import { Flex, Select, Typography, Tag, Switch } from 'antd';
import { useTranslation } from 'react-i18next';
import { ListResource } from '../../components';
import { useColumns } from './columns';
import { headers } from './headers';
import { useFormatter } from '../../utils/formatter';
import CustomerServiceContext from './CustomerServiceContext';
import { useAuthContext } from '../../contexts/AuthContext';

const { Option } = Select;
/**
 * Component for listing quotes with filtering options.
 *
 * @component
 * @returns {JSX.Element} Rendered ListQuotes component
 */
export const ListCustomerServices = () => {
  const { formattedData } = useFormatter('customerServices');
  const { t } = useTranslation();
  const { user } = useAuthContext();
  const [selectedStatus, setSelectedStatus] = useState();
  const [selectedLeader, setSelectedLeader] = useState(null);
  const [selectedRecipient, setSelectedRecipient] = useState(null);
  const [filterMyRSC, setFilterMyRSC] = useState(false);
  const {
    customerServiceEnums,
    users,
    refresh,
    changeColor,
    urgentCount,
    totalCount,
    getCustomerServices
  } = CustomerServiceContext();

  const onsearch = (input, option) => {
    const result = option?.children.toLowerCase();
    return result.includes(input.toLowerCase());
  };

  useEffect(async () => {
    await getCustomerServices();
  }, [refresh]);

  const columns = useColumns(changeColor, totalCount, urgentCount, user);
  return (
    <ListResource
      resourceName="customerServices"
      flexWrap
      columns={columns}
      headers={headers}
      customActionColumn
      forceRefresh={refresh}
      resourceModelName="CustomerServices"
      formatter={formattedData}
      withImportButton={false}
      withCreateButtonText={t('buttons.create_custom.customerServices')}
      populate="created_by,recipients,customer.macro_ps micro_ps leader"
      scroll={{ x: 1700 }}
      extraSorter="-completion_date"
      extraButtons={
        user?.role?.startsWith('admins') && (
          <Switch
            checkedChildren={t('buttons.filter_my_RSC')}
            unCheckedChildren={t('buttons.see_all')}
            value={filterMyRSC}
            onChange={() => setFilterMyRSC(!filterMyRSC)}
          />
        )
      }
      extraQuery={`${selectedStatus ? `status=${selectedStatus}` : ''}${
        selectedRecipient ? `&recipients=${selectedRecipient}` : ''
      }${selectedLeader ? `&leader=${selectedLeader}` : ''}${
        filterMyRSC ? `&created_by=${user._id}` : ''
      }`}
      extra
      extraFilters={
        <Flex align="center" gap={16} wrap>
          <Flex align="center" gap={8}>
            <Typography>{t('customerServices.select.status')} :</Typography>
            <Select
              allowClear
              mode="multiple"
              onChange={(value) => {
                setSelectedStatus(value);
              }}
              onClear={() => {
                setSelectedStatus(null);
              }}
              defaultValue={selectedStatus}
              style={{ width: 180 }}
            >
              {(customerServiceEnums.status || []).map((status) => (
                <Option key={status} value={status}>
                  {t(`customerServices.form.enums.${status}`)}
                </Option>
              ))}
            </Select>
          </Flex>
          {user.role === 'admins:SUPER-ADMIN' ||
          user.role === 'admins:ADMIN' ? (
            <>
              <Flex align="center" gap={8}>
                <Typography>{t('customerServices.select.leader')} :</Typography>
                <Select
                  allowClear
                  onChange={(value) => setSelectedLeader(value)}
                  onClear={() => {
                    setSelectedLeader(null);
                  }}
                  style={{ width: 180 }}
                >
                  {customerServiceEnums?.leaders?.map((leader) => (
                    <Option key={leader._id} value={leader._id}>
                      <Tag color={leader.color}>
                        {`${leader.first_name} ${
                          leader?.usage_last_name || leader?.last_name
                        }`}
                      </Tag>
                    </Option>
                  ))}
                </Select>
              </Flex>
              <Flex align="center" gap={8}>
                <Typography>
                  {t('customerServices.select.recipients')} :
                </Typography>
                <Select
                  allowClear
                  mode="multiple"
                  onChange={(value) => setSelectedRecipient(value)}
                  onDeselect={(value) =>
                    setSelectedRecipient((prevStatus) =>
                      prevStatus.filter((item) => item !== value)
                    )
                  }
                  onClear={() => {
                    setSelectedRecipient();
                  }}
                  style={{ width: 180 }}
                  showSearch
                  filterOption={(input, option) => onsearch(input, option)}
                >
                  {users?.map((recipient) => (
                    <Option key={recipient._id} value={recipient._id}>
                      {`${recipient.first_name} ${
                        recipient?.usage_last_name || recipient.last_name
                      }`}
                    </Option>
                  ))}
                </Select>
              </Flex>
            </>
          ) : null}
        </Flex>
      }
    />
  );
};
