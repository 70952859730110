import PropTypes from 'prop-types';
import { ListResource } from '../../../components';
import { useColumns } from '../../contracts/columns';

/**
 * Component for listing contracts with filtering options.
 *
 * @component
 * @returns {JSX.Element} Rendered LsitContracts component
 */

export const ListContracts = ({ id }) => {
  const columns = useColumns();

  return (
    <ListResource
      resourceName="contracts"
      contentCustomStyle={{ margin: 0, padding: 0 }}
      resourceModelName="Contract"
      columns={columns}
      populate="micro_ps,macro_ps,customer"
      onDoubleClickAction={false}
      withCreateButton={false}
      withImportButton={false}
      customActionColumn
      extraQuery={`customer=${id}`}
      withPageHeader={false}
    />
  );
};

ListContracts.propTypes = {
  id: PropTypes.string.isRequired
};
