import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Collapse, Row, Typography } from 'antd';
import { InterventionsCard } from './InterventionsCard';

const { Panel } = Collapse;

/**
 * Component for displaying the interventions collapse.
 *
 * @component
 * @param {Object} interventions - The interventions list
 * @returns {JSX.Element} The interventions collapse component
 */
export const InterventionsCollapse = ({ interventions }) => {
  const { t } = useTranslation();

  const qualityControls = {};
  const companiesName = {};

  interventions?.forEach((intervention) => {
    if (!qualityControls[intervention.ps_number]) {
      qualityControls[intervention.ps_number] = [intervention];
      companiesName[intervention.ps_number] = intervention.company_name;
    } else {
      qualityControls[intervention.ps_number].push(intervention);
    }
  });

  return (
    <Collapse style={{ borderColor: 'var(--borderPrimaryColor)' }}>
      <Panel
        header={
          <Typography.Text
            style={{
              color: 'var(--primaryColor)',
              fontSize: 18,
              fontWeight: 600
            }}
          >
            {t('quality.form.interventions.title')}
          </Typography.Text>
        }
        key="1"
      >
        <Row gutter={[16, 16]}>
          {Object.keys(qualityControls).map((psNumber) => (
            <InterventionsCard
              key={psNumber}
              psNumber={psNumber}
              interventions={qualityControls[psNumber]}
              companyName={companiesName[psNumber]}
            />
          ))}
        </Row>
      </Panel>
    </Collapse>
  );
};

InterventionsCollapse.propTypes = {
  interventions: PropTypes.arrayOf(PropTypes.shape({}))
};

InterventionsCollapse.defaultProps = {
  interventions: []
};
