import { Input } from 'antd';
import { FilesManagement } from '../../../components/FilesManagement';

/**
 * useFields is a custom hook that provides an array of field configurations for customer data input.
 *
 * @hook
 * @returns {Array<Object>} An array of field configurations.
 */

const useFields = (purpose, customer, refresh, setRefresh, setFiles) => [
  {
    name: ['prospect_comment'],
    key: 'prospect_comment',
    input: <Input.TextArea />
  },
  {
    name: ['files'],
    key: 'files',
    input: (
      <FilesManagement
        purpose={purpose}
        documents={customer?.files || []}
        setForceRefresh={setRefresh}
        forceRefresh={refresh}
        uploadURL="/customers/upload"
        setData={setFiles}
        modelKey="files"
      />
    )
  }
];

export default useFields;
