import { useState } from 'react';
import { Switch } from 'antd';
import { useTranslation } from 'react-i18next';
import { ListResource } from '../../components';
import { useFeesColumns } from './columns';
import { headers } from './headers';

export const Fees = () => {
  const { t } = useTranslation();
  const columns = useFeesColumns();

  const [seeAll, setSeeAll] = useState(false);

  return (
    <ListResource
      resourceName="fees"
      columns={columns}
      populate="service micro_ps created_by"
      resourceModelName="Fee"
      deleteAction={false}
      showAction={false}
      withImportButton={false}
      withCreateButtonText={t('buttons.create_custom.fees')}
      headers={headers}
      onDoubleClickAction={false}
      extraQuery={`status=${seeAll ? '' : 'ACTIVE'}`}
      extraButtons={
        <Switch
          size="large"
          checkedChildren={t('buttons.see_active')}
          unCheckedChildren={t('buttons.see_all')}
          checked={seeAll}
          onChange={setSeeAll}
        />
      }
    />
  );
};
