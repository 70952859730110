/* eslint-disable react/jsx-filename-extension */
import ReactDOM from 'react-dom';
import { MsalProvider } from '@azure/msal-react';
import { PublicClientApplication, EventType } from '@azure/msal-browser';
import App from './components/App';
import * as serviceWorker from './serviceWorker';
import { msalConfig } from './authConfig';

const msalInstance = new PublicClientApplication(msalConfig);

// Listen for sign-in event and set active account
msalInstance.addEventCallback((event) => {
  if (
    !msalInstance.getActiveAccount() &&
    (event.eventType === EventType.LOGIN_SUCCESS ||
      event.eventType === 'msal:acquireTokenSuccess') &&
    event.payload.account
  ) {
    const account = event.payload.account;
    msalInstance.setActiveAccount(account);
  }
});

ReactDOM.render(
  <MsalProvider instance={msalInstance}>
    <App />
  </MsalProvider>,

  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
