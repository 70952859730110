import { useEffect, useState } from 'react';
import moment from 'moment';
import { Checkbox, Select, DatePicker, Tag } from 'antd';
import { useTranslation } from 'react-i18next';
import UserContextProvider from './UserContext';
import { userMaterials } from '../../utils/constants/tagColors';

/**
 * Custom hook for generating communication-related form fields for user communication details.
 *
 * @hook
 * @returns {object} Object containing communicationFields array and isFieldsLoading flag
 */

const useCommunicationFields = () => {
  const { t } = useTranslation();
  const { enums } = UserContextProvider();
  const [isFieldsLoading, setIsFieldsLoading] = useState(true);
  const format = 'DD-MM-YYYY';

  const { Option } = Select;

  useEffect(() => {
    if (enums) setIsFieldsLoading(false);
  }, []);

  const communicationFields = [
    {
      name: ['right_of_publicity'],
      valuePropName: 'checked',
      input: <Checkbox />
    },
    {
      name: ['sharing_birthday_date'],
      valuePropName: 'checked',
      input: <Checkbox />
    },
    {
      name: ['parking_badge_number']
    },
    {
      name: ['parking_place_number']
    },
    {
      name: ['attribution_date'],
      input: (
        <DatePicker
          format={format}
          disabledDate={(current) => current > moment()}
        />
      )
    },
    {
      name: ['key_delivery'],
      input: (
        <DatePicker
          format={format}
          disabledDate={(current) => current > moment()}
        />
      )
    },
    {
      name: ['work_materials'],
      input: (
        <Select loading={isFieldsLoading} mode="multiple" allowClear>
          {(enums?.materials || []).map((materials) => (
            <Option key={materials} value={materials}>
              <Tag color={userMaterials[materials]}>
                {t(`users.materials.${materials}`)}
              </Tag>
            </Option>
          ))}
        </Select>
      )
    }
  ];
  return {
    communicationFields: communicationFields.map((f) => f),
    isFieldsLoading
  };
};

export default useCommunicationFields;
