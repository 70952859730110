import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  DeleteOutlined,
  DownloadOutlined,
  EyeOutlined,
  InboxOutlined,
  WarningOutlined
} from '@ant-design/icons';
import { useLocation, useParams } from 'react-router-dom';
import {
  Button,
  Card,
  Col,
  Flex,
  Popconfirm,
  Row,
  Upload,
  message as antdMessage
} from 'antd';
import dayjs from 'dayjs';
import { useErrorMessage } from '../utils/errorMessage';
import { useAuthContext } from '../contexts/AuthContext';
import { useDownloadDocument } from '../utils/downloadDoc';
import { isAuthorized } from '../utils/constants/authorizedMenu';

const { Dragger } = Upload;
const iconSize = 16;

/**
 * FilesManagement is a React component that handles the management of files for a customer.
 *
 * @component
 * @param {Object} props - The props that are passed to the component.
 * @param {string} props.purpose - The purpose of the form, either 'create' or 'edit'.
 * @param {boolean} props.forceRefresh - A flag to trigger a refresh when files are uploaded.
 * @param {function} props.setForceRefresh - A function to set the forceRefresh flag.
 * @param {Array} props.documents - An array of documents associated with the customer.
 * @param {string} props.uploadURL - The URL to upload files to.
 * @param {function} props.setData - A function to set the data of the customer.
 * @param {string} props.modelKey - The model key for the customer.
 * @param {boolean} props.disabled - A flag to disable the file upload.
 * @returns {React.ReactNode} The rendered component for managing customer files.
 */

export const FilesManagement = ({
  purpose,
  forceRefresh,
  setForceRefresh,
  documents,
  uploadURL,
  setData,
  modelKey,
  disabled,
  border,
  showTitle
}) => {
  const { pathname } = useLocation();
  const { t } = useTranslation();
  const { message } = useErrorMessage();
  const { dispatchAPI, user } = useAuthContext();
  const { downloadDocument, viewDocument } = useDownloadDocument();
  const [fileList, setFileList] = useState([]);

  const { id } = useParams();

  const hasAccess = (p) => isAuthorized(pathname, user?.role, p);

  const handlePostDocuments = async () => {
    try {
      if (purpose === 'show') return;
      const formData = new FormData();

      if (fileList.length) {
        fileList.forEach((file) => {
          formData.append('files', file);
        });
      }

      if (purpose === 'create') {
        const { data } = await dispatchAPI('POST', {
          url: `${uploadURL}?modelKey=${modelKey}`,
          body: formData
        });
        setData(data);
      } else {
        await dispatchAPI('POST', {
          url: `${uploadURL}/${id}?modelKey=${modelKey}`,
          body: formData
        });
      }
      antdMessage.success(t('messages.success.upload'));
      setForceRefresh(!forceRefresh);
      setFileList([]);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const deleteFile = async (fileID) => {
    try {
      await dispatchAPI(`DELETE`, { url: `files/${fileID}` });
      window.location.reload();
    } catch (error) {
      message(error);
    }
  };

  const draggerProps = {
    multiple: false,
    onRemove: (file) => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);
    },
    beforeUpload: (file) => {
      setFileList([file]);
      return true;
    },
    fileList
  };

  return (
    <Card
      title={showTitle ? t('files.title') : ''}
      bordered={!!border}
      className="file-manager"
    >
      <Row gutter={[24, 24]}>
        <Col xs={24} md={18} className="custom-document-list">
          {documents?.map((document) => (
            <Flex
              align="center"
              gap="large"
              key={document._id}
              className="custom-list-item"
              justify="space-between"
            >
              <Flex align="center" className="document-meta" gap="small">
                <Col className="custom-list-label">
                  {!document ? (
                    <CloseCircleOutlined
                      style={{ color: 'var(--errorColor)' }}
                    />
                  ) : (
                    <CheckCircleOutlined style={{ color: 'var(--addColor)' }} />
                  )}
                  {document?.title}
                </Col>
                <Col className="customfile">
                  {document?.metadata?.originalName}
                </Col>
              </Flex>
              {document && (
                <Flex
                  align="center"
                  gap="middle"
                  className="custom-list-action"
                >
                  <Col>{dayjs(document?.uploadDate).format('DD/MM/YYYY')}</Col>
                  <Button
                    onClick={() => viewDocument(document, 'show')}
                    type="link"
                    className="no-padding"
                  >
                    <EyeOutlined style={{ fontSize: iconSize }} />
                  </Button>
                  {hasAccess('download_doc') && (
                    <Button
                      onClick={() => downloadDocument(document)}
                      type="link"
                      className="no-padding"
                    >
                      <DownloadOutlined style={{ fontSize: iconSize }} />
                    </Button>
                  )}
                  {hasAccess('delete_doc') && (
                    <Popconfirm
                      title={t('files.delete.title')}
                      okText={t('files.delete.ok')}
                      okButtonProps={{ danger: 'true' }}
                      cancelText={t('files.delete.cancel')}
                      onConfirm={() => deleteFile(document._id)}
                      icon={<WarningOutlined />}
                    >
                      <DeleteOutlined
                        style={{
                          fontSize: iconSize,
                          color: 'var(--errorColor)'
                        }}
                      />
                    </Popconfirm>
                  )}
                </Flex>
              )}
            </Flex>
          ))}
        </Col>
        {purpose !== 'show' && (
          <Col xs={24} md={6} className="file-upload-container">
            <Dragger disabled={disabled} {...draggerProps}>
              <p className="ant-upload-drag-icon">
                <InboxOutlined />
              </p>
              <p className="ant-upload-text">{t('files.drag_or_drop')}</p>
            </Dragger>
            {fileList.length > 0 && (
              <Button onClick={handlePostDocuments}>{t('files.save')}</Button>
            )}
          </Col>
        )}
      </Row>
    </Card>
  );
};

FilesManagement.propTypes = {
  purpose: PropTypes.string,
  forceRefresh: PropTypes.bool,
  setForceRefresh: PropTypes.func,
  documents: PropTypes.arrayOf(PropTypes.shape({})),
  uploadURL: PropTypes.string,
  setData: PropTypes.func,
  modelKey: PropTypes.string,
  disabled: PropTypes.bool,
  border: PropTypes.bool,
  showTitle: PropTypes.bool
};

FilesManagement.defaultProps = {
  purpose: null,
  forceRefresh: undefined,
  setForceRefresh: null,
  documents: null,
  uploadURL: null,
  setData: null,
  modelKey: null,
  disabled: false,
  border: null,
  showTitle: true
};
