import { useTranslation } from 'react-i18next';

/**
 * Generates an array of column configuration objects for the customer rejections list table.
 *
 * @function useColumns
 * @param {Array} rejectionTypes - An array containing rejection types.
 * @returns {Array} - An array of column configuration objects.
 */
export const useColumns = (rejectionTypes) => {
  const { t } = useTranslation();

  const mappedColumns = [];
  if (rejectionTypes && rejectionTypes.length > 0) {
    rejectionTypes.forEach((rejectionType) => {
      if (rejectionType && rejectionType.title) {
        const title = rejectionType.title?.replace(/\s/g, '_');
        const column = {
          title: rejectionType.title,
          key: title,
          dataIndex: title,
          render: (data) => data || ''
        };
        mappedColumns.push(column);
      }
    });
  }

  return [
    {
      title: t('customers.stats.columns.operator_code'),
      key: 'operator_code',
      dataIndex: ['operator_code']
    },
    ...mappedColumns,
    {
      title: t('customers.stats.columns.total'),
      key: 'total',
      dataIndex: ['total']
    }
  ];
};
