import { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'antd';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useAuthContext } from '../../contexts/AuthContext';
import { useFields } from './fields';
import { useErrorMessage } from '../../utils/errorMessage';
import { CreateUpdateContainer } from '../CreateUpdateContainer/CreateUpdateContainer';

/**
 * EmailModal Component.
 *
 * This component displays a modal for composing and sending emails. It allows users to select
 * contacts, templates, upload files, and enter subject and content.
 *
 * @component
 * @param {Object} props - The props object.
 * @param {boolean} props.isModalOpen - Indicates whether the modal is open.
 * @param {Function} props.closeModal - Function to close the modal.
 * @param {Array<Object>} props.contacts - Array of contact objects.
 * @param {string} props.baseUrl - Base URL for API requests.
 * @param {string} props.extraQuery - Additional query parameters for API requests.
 * @param {string} props.company - The company associated with the email.
 * @returns {React.Component} EmailModal component.
 */
const EmailModal = ({
  isModalOpen,
  closeModal,
  contacts,
  baseUrl,
  extraQuery,
  company,
  subject,
  useCaseFilter,
  successMessageUrl,
  modelName,
  idModal
}) => {
  const { dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();
  const { t } = useTranslation();
  const { id } = useParams();
  const [templates, setTemplates] = useState([]);
  const [fileList, setFileList] = useState([]);
  const [content, setContent] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [formValues, setFormValues] = useState(null);
  const [customerEnums, setCustomerEnums] = useState(null);

  const fields = useFields({
    setFileList,
    fileList,
    setContent,
    content,
    isLoading,
    contacts,
    templates,
    customerEnums,
    company,
    formValues,
    subject,
    useCaseFilter,
    id,
    modelName,
    idModal
  });

  const getTemplates = useCallback(async () => {
    setIsLoading(true);
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/template-documents${extraQuery ? `/?${extraQuery}` : ''}`
      });
      setTemplates(data);
    } catch (e) {
      message(e);
    }
    setIsLoading(false);
  }, []);

  const getCustomerEnums = async () => {
    try {
      const { data } = await dispatchAPI('GET', { url: 'customers/enums' });
      setCustomerEnums(data);
    } catch (error) {
      message(error);
    }
  };

  const config = {
    onCreateResource: {
      setBody: (data) => {
        const formData = new FormData();
        formData.append('values', JSON.stringify({ ...data }));
        if (fileList) formData.append('files', fileList[0]);
        return formData;
      }
    }
  };

  useEffect(() => {
    setIsLoading(true);
    (async () => {
      await getTemplates();
      await getCustomerEnums();
    })();
  }, []);

  return (
    <Modal
      centered
      open={isModalOpen}
      onCancel={() => closeModal('cancel')}
      width="80vw"
      footer={false}
    >
      <CreateUpdateContainer
        fields={fields}
        purpose="create"
        baseUrl={baseUrl}
        resource="emails"
        config={config}
        isOverlay
        customNavigate={closeModal}
        submitLabel={t('buttons.send')}
        setFormValues={setFormValues}
        needFormDependencies
        successMessageUrl={successMessageUrl}
      />
    </Modal>
  );
};

EmailModal.propTypes = {
  isModalOpen: PropTypes.bool,
  closeModal: PropTypes.func.isRequired,
  contacts: PropTypes.arrayOf(PropTypes.shape()),
  baseUrl: PropTypes.string,
  extraQuery: PropTypes.string,
  company: PropTypes.string,
  subject: PropTypes.string,
  useCaseFilter: PropTypes.string,
  successMessageUrl: PropTypes.string,
  modelName: PropTypes.string,
  idModal: PropTypes.string
};

EmailModal.defaultProps = {
  isModalOpen: false,
  contacts: [],
  baseUrl: undefined,
  extraQuery: undefined,
  company: undefined,
  subject: undefined,
  useCaseFilter: undefined,
  successMessageUrl: undefined,
  modelName: undefined,
  idModal: undefined
};

export default EmailModal;
