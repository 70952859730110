import { Tag } from 'antd';
import { useTranslation } from 'react-i18next';
import { userEmployer } from '../../../utils/constants/tagColors';
import { formatValue } from '../../../utils/formatNumber';

/**
 * Custom hook for generating administration content data for displaying contract administration details in a list format.
 *
 * @hook
 * @param {object} data - User data object
 * @returns {Array} Array of objects containing label, span, and content for each field
 */

export const usePrincipalInformationsContent = (data = {}) => {
  const { t } = useTranslation();

  const {
    customer,
    micro_ps,
    macro_ps,
    quote,
    services,
    comment,
    resiliation_date,
    resiliation_reason,
    resiliation_comment
  } = data;

  return [
    {
      label: 'contracts.show.ps_number',
      span: 6,
      content: customer?.ps_number || '-'
    },
    {
      label: 'contracts.show.company_name',
      span: 6,
      content: customer?.company_name || '-'
    },
    {
      label: 'contracts.show.micro_ps',
      span: 6,
      content: micro_ps?.title || '-'
    },
    {
      label: 'contracts.show.macro_ps',
      span: 6,
      content: macro_ps?.title || '-'
    },
    {
      label: 'contracts.show.contract_type',
      span: 6,
      content: (
        <Tag color={userEmployer[customer?.company_customer]}>
          {t(`users.employers.${customer?.company || 'N/A'}`)}
        </Tag>
      )
    },
    {
      label: 'contracts.show.quote_code',
      span: 6,
      content: quote?.code || '-'
    },
    {
      label: 'contracts.show.leader',
      span: 6,
      content:
        (services?.length &&
          `${services?.[0]?.leader?.first_name} ${
            services?.[0]?.leader?.usage_last_name ||
            services?.[0]?.leader?.last_name
          }`) ||
        '-'
    },
    {
      label: 'contracts.show.user_referent',
      span: 6,
      content:
        (services?.length &&
          `${services?.[0]?.user_referent?.first_name} ${
            services?.[0]?.user_referent?.usage_last_name ||
            services?.[0]?.user_referent?.last_name
          }`) ||
        '-'
    },
    {
      label: 'contracts.show.comment',
      span: 6,
      content: comment || '-'
    },
    ...(resiliation_date
      ? [
          {
            label: 'contracts.show.resiliation_date',
            span: 6,
            content: formatValue(resiliation_date, 'date') || '-'
          }
        ]
      : []),
    ...(resiliation_reason
      ? [
          {
            label: 'contracts.show.resiliation_reason',
            span: 6,
            content: t(`contracts.tags.${resiliation_reason}`) || '-'
          }
        ]
      : []),
    ...(resiliation_comment
      ? [
          {
            label: 'contracts.show.resiliation_comment',
            span: 6,
            content: resiliation_comment || '-'
          }
        ]
      : [])
  ];
};
