import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Modal, Form, Button, Row } from 'antd';
import { CloseOutlined, CheckOutlined } from '@ant-design/icons';
import useFields from './fields';
import { useAuthContext } from '../../../../contexts/AuthContext';
import { useErrorMessage } from '../../../../utils/errorMessage';
import { useGenerateFormItem } from '../../../../utils/generateFormItem';
import { showSuccessMessage } from '../../../../utils/showSuccessMessage';

const BalanceModal = ({ setForceRefresh, absenceVacation, users }) => {
  const { t } = useTranslation();
  const { fields } = useFields(users);
  const { dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();
  const generateFields = useGenerateFormItem();
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);
  const [isModalOpenedLocaly, setIsModalOpenedLocaly] = useState(true);

  const patchUserData = async (data) => {
    setIsLoading(true);
    try {
      await dispatchAPI('PATCH', {
        url: `/paidvacations/${absenceVacation._id}`,
        body: data
      });
    } catch (e) {
      if (e.response) message(e.response.status);
    }
    setForceRefresh((prev) => !prev);
    showSuccessMessage(t, 'paidvacations', 'update');
    setIsModalOpenedLocaly(false);
  };

  return (
    <Modal
      title={`${t('vacations.modal.title', {
        year1: absenceVacation.year1,
        year2: absenceVacation.year2
      })} `}
      centered
      open={isModalOpenedLocaly}
      onCancel={() => setIsModalOpenedLocaly(false)}
      width={700}
      footer={false}
    >
      <Form
        form={form}
        onFinish={patchUserData}
        initialValues={absenceVacation}
      >
        {fields.map((field) => generateFields('paidvacations', field))}
        <Form.Item>
          <Row justify="end">
            <Button
              style={{ margin: '0 8px' }}
              type="link"
              danger
              onClick={() => setIsModalOpenedLocaly(false)}
            >
              {`${t('buttons.cancel')} `}
              <CloseOutlined />
            </Button>
            <Button
              type="primary"
              htmlType="submit"
              loading={isLoading}
              disabled={isLoading}
            >
              {`${t('buttons.save')} `}
              <CheckOutlined />
            </Button>
          </Row>
        </Form.Item>
      </Form>
    </Modal>
  );
};

BalanceModal.propTypes = {
  setForceRefresh: PropTypes.bool.isRequired,
  absenceVacation: PropTypes.shape({
    year1: PropTypes.number.isRequired,
    year2: PropTypes.number.isRequired,
    _id: PropTypes.number.isRequired,
    user: PropTypes.string.isRequired
  }).isRequired,
  users: PropTypes.arrayOf({}).isRequired
};

export default BalanceModal;
