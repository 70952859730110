import { useState } from 'react';
import dayjs from 'dayjs';
import { DatePicker, Select, Tag } from 'antd';
import { useTranslation } from 'react-i18next';
import { ListResource } from '../../components';
import { useColumns } from './columns';
import { headers } from './headers';
import InterventionContextProvider from './InterventionContext';
import { useFormatter } from '../../utils/formatter';
import CreateUpdateInterventionModal from './CreateUpdateInterventionModal';

const { RangePicker } = DatePicker;
const { Option } = Select;

/**
 * Component for listing interventions with filtering options.
 *
 * @component
 * @returns {JSX.Element} Rendered ListInterventions component
 */

export const ListInterventions = () => {
  const { t } = useTranslation();
  const {
    interventionEnums,
    leadersAndGtp,
    openModal,
    isModalOpen,
    psMicro,
    forceRefresh,
    deleteResource,
    user,
    psMacro,
    contacts
  } = InterventionContextProvider();
  const { formattedData } = useFormatter('interventions');
  const columns = useColumns(
    interventionEnums,
    leadersAndGtp,
    deleteResource,
    user,
    psMacro
  );
  const [filterDate, setFilterDate] = useState([]);
  const [selectedTypePs, setSelectedTypePs] = useState([]);
  const [selectedContacts, setSelectedContacts] = useState([]);

  const onSearch = (input, option) =>
    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;

  return (
    <>
      {isModalOpen && <CreateUpdateInterventionModal />}
      <ListResource
        flexWrap="true"
        resourceName="interventions"
        columns={columns}
        headers={headers}
        populate="contract,customer,user,macro_ps"
        resourceModelName="Intervention"
        withImportButton={false}
        customActionColumn
        formatter={formattedData}
        onClickAdd={openModal}
        forceRefresh={forceRefresh}
        withCreateButtonText={t('buttons.create_custom.interventions')}
        scroll={{ x: 1600 }}
        extraQuery={`${
          selectedTypePs.length > 0 ? `&micro_ps=${selectedTypePs || ''}` : ''
        }${
          filterDate.length
            ? `&scheduled_start_date>=${dayjs(filterDate[0])
                .startOf('day')
                .toISOString()}&scheduled_start_date<=${dayjs(filterDate[1])
                .endOf('day')
                .toISOString()}`
            : ''
        }${
          selectedContacts.length > 0
            ? `&contacts=${selectedContacts || ''}`
            : ''
        }`}
        extra
        extraFilters={
          <>
            <RangePicker
              format="DD-MM-YYYY"
              style={{ marginLeft: '20px' }}
              onChange={setFilterDate}
            />
            {!user?.role?.startsWith('guests') && (
              <>
                <Select
                  allowClear
                  placeholder={t('calendar.placeholder.psMicro')}
                  mode="multiple"
                  onSelect={(value) => {
                    setSelectedTypePs([...selectedTypePs, value]);
                  }}
                  onDeselect={(value) =>
                    setSelectedTypePs((prevStatus) =>
                      prevStatus.filter((item) => item !== value)
                    )
                  }
                  onClear={() => {
                    setSelectedTypePs([]);
                  }}
                  style={{ width: '250px', marginLeft: '20px' }}
                >
                  {psMicro
                    ?.sort((a, b) => a.title.localeCompare(b.title))
                    ?.map((type_ps) => (
                      <Option key={type_ps._id} value={type_ps._id}>
                        <Tag color={type_ps.color}>{type_ps.title}</Tag>
                      </Option>
                    ))}
                </Select>
                <Select
                  allowClear
                  placeholder={t('calendar.placeholder.contacts')}
                  mode="multiple"
                  filterOption={(input, option) => onSearch(input, option)}
                  onSelect={(value) => {
                    setSelectedContacts([...selectedContacts, value]);
                  }}
                  onDeselect={(value) =>
                    setSelectedContacts((prevStatus) =>
                      prevStatus.filter((item) => item !== value)
                    )
                  }
                  onClear={() => {
                    setSelectedContacts([]);
                  }}
                  style={{ width: '400px', marginLeft: '20px' }}
                >
                  {contacts
                    ?.sort((a, b) => a.first_name.localeCompare(b.first_name))
                    ?.map((contact) => (
                      <Option key={contact._id} value={contact._id}>
                        {`${contact?.first_name} ${contact?.last_name} (${contact?.ps_number}-${contact?.company_name})`}
                      </Option>
                    ))}
                </Select>
              </>
            )}
          </>
        }
      />
    </>
  );
};
