import { useTranslation } from 'react-i18next';
import { Tag } from 'antd';
import {
  yesNoSwitch,
  eventStatus
} from '../../../../utils/constants/tagColors';
import { formatValue } from '../../../../utils/formatNumber';

export const useColumns = () => {
  const { t } = useTranslation();

  return [
    {
      title: t('vacations.columns.type'),
      key: 'type',
      dataIndex: ['type'],
      render: (type) => <Tag>{t(`vacations.enums.${type}`)}</Tag>,
      sorter: true
    },
    {
      title: t('vacations.columns.startTime'),
      key: 'startTime',
      dataIndex: ['startTime'],
      render: (startTime) =>
        `${formatValue(startTime, 'date')} ${formatValue(startTime, 'time')}`,
      sorter: true
    },
    {
      title: t('vacations.columns.endTime'),
      key: 'endTime',
      dataIndex: ['endTime'],
      render: (endTime) =>
        `${formatValue(endTime, 'date')} ${formatValue(endTime, 'time')}`,
      sorter: true
    },
    {
      title: t('vacations.columns.makeUpTime'),
      key: 'makeUpTime',
      dataIndex: ['makeUpTime'],
      render: (makeUpTime) => (
        <Tag color={yesNoSwitch[makeUpTime]}>
          {makeUpTime ? t(`switch.${makeUpTime}`) : 'N/A'}
        </Tag>
      ),
      sorter: true
    },
    {
      title: t('vacations.columns.created_at'),
      key: 'created_at',
      dataIndex: ['created_at'],
      render: (endTime) => formatValue(endTime, 'date'),
      sorter: true
    },
    {
      title: t('vacations.columns.waiveExtraTimeOff'),
      key: 'waiveExtraTimeOff',
      dataIndex: ['waiveExtraTimeOff'],
      render: (waiveExtraTimeOff) => (
        <Tag color={yesNoSwitch[waiveExtraTimeOff]}>
          {waiveExtraTimeOff ? t(`switch.${waiveExtraTimeOff}`) : 'N/A'}
        </Tag>
      ),
      sorter: true
    },
    {
      title: t('vacations.columns.status'),
      key: 'status',
      dataIndex: ['status'],
      render: (status) => (
        <Tag color={eventStatus[status]}>{t(`vacations.enums.${status}`)}</Tag>
      ),
      sorter: true
    }
  ];
};
