import { useState } from 'react';
import PropTypes from 'prop-types';
import { Card, Typography, Flex } from 'antd';
import { EditOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import InterventionContextProvider from '../InterventionContext';
import { CreateUpdateContainer } from '../../../components/CreateUpdateContainer/CreateUpdateContainer';
import useFields from './commentFields';

export const Comments = ({ intervention, hasAccess }) => {
  const { t } = useTranslation();
  const [editMode, setEditMode] = useState(false);
  const [commentSelected, setCommentSelected] = useState('');
  const [commentIndex, setCommentIndex] = useState(null);
  const { fields } = useFields(commentSelected);
  const { forceRefresh, setForceRefresh } = InterventionContextProvider();

  const customNavigateAction = () => {
    setEditMode(false);
    setForceRefresh(!forceRefresh);
  };

  const config = {
    onUpdateResource: {
      setBody: (data) => {
        const { historic, ...restOfIntervention } = intervention;
        return {
          ...restOfIntervention,
          comments:
            commentIndex !== null
              ? restOfIntervention.comments.map((comment, index) =>
                  index === commentIndex ? data.comment : comment
                )
              : [...restOfIntervention.comments, data.comment]
        };
      }
    }
  };

  return (
    <Card style={{ height: '100%' }}>
      <Flex justify="space-between">
        <Typography.Title
          level={4}
          style={{
            color: 'var(--userColor)'
          }}
          strong={false}
        >
          {t('interventions.show.comments.title')}
        </Typography.Title>
        {!editMode && hasAccess('edit') && (
          <PlusCircleOutlined
            style={{
              color: 'var(--userColor)',
              fontSize: 18
            }}
            onClick={() => intervention?.status === 'OPEN' && setEditMode(true)}
          />
        )}
      </Flex>
      {!editMode ? (
        (intervention?.comments || []).map((comment, index) => (
          <Card style={{ marginTop: 3 }}>
            <Flex justify="space-between">
              {comment}
              <EditOutlined
                onClick={() => {
                  if (intervention?.status === 'OPEN') {
                    setEditMode(true);
                    setCommentIndex(index);
                    setCommentSelected(comment);
                  }
                }}
              />
            </Flex>
          </Card>
        ))
      ) : (
        <CreateUpdateContainer
          fields={fields}
          purpose="edit"
          baseUrl="interventions"
          resource="actions"
          isOverlay
          customNavigate={customNavigateAction}
          config={config}
          withHeader={false}
        />
      )}
    </Card>
  );
};

Comments.propTypes = {
  intervention: PropTypes.shape({
    comments: PropTypes.arrayOf(PropTypes.string),
    status: PropTypes.string,
    historic: PropTypes.arrayOf(PropTypes.shape({}))
  }),
  hasAccess: PropTypes.func.isRequired
};

Comments.defaultProps = {
  intervention: undefined
};
