import React, { createContext, useContext, useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { message as antdMessage } from 'antd';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import format from 'date-fns/format';
import { useAuthContext } from '../../contexts/AuthContext';
import { useErrorMessage } from '../../utils/errorMessage';
import { showSuccessMessage } from '../../utils/showSuccessMessage';
import { isAuthorized } from '../../utils/constants/authorizedMenu';

const QuittanceContext = createContext({});

/**
 * Context provider component for managing quittance-related data.
 * This context provides various state values and methods related to quittances.
 *
 * @component
 * @param {object} props - React component props
 * @param {React.ReactNode} props.children - The child components that will have access to the context
 * @returns {React.ReactNode} Wrapped children with the contract context
 */

export const QuittanceContextProvider = ({ children }) => {
  const { dispatchAPI, user } = useAuthContext();
  const { t } = useTranslation();
  const { message } = useErrorMessage();
  const location = useLocation();
  const { pathname } = location;
  const [customers, setCustomers] = useState();
  const [quittanceEnums, setQuittanceEnums] = useState({});
  const [paymentEnums, setPaymentEnums] = useState({});
  const [serviceEnums, setServiceEnums] = useState({});
  const [leaders, setLeaders] = useState([]);
  const [sales, setSales] = useState([]);
  const [macroPs, setMacroPs] = useState([]);
  const [microPs, setMicroPs] = useState([]);
  const [bankReconciliations, setBankReconciliations] = useState(null);
  const [forceRefresh, setForceRefresh] = useState(false);
  const [selectedPeriods, setSelectedPeriods] = useState(null);
  const [quittance, setQuittance] = useState({});
  const [formValues, setFormValues] = useState(null);
  const [isSpinLoading, setIsSpinLoading] = useState(false);
  const [idInvoice, setIdInvoice] = useState('');
  const [isModalStatusOpen, setIsModalStatusOpen] = useState(false);
  const [buttonClicked, setButtonClicked] = useState();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [condition, setCondition] = useState(null);
  const [idFromOverlay, setIdFromOverlay] = useState(null);
  const isAdmin = user.role.split(':')[0] === 'admins';

  const hasAccess = (purpose) => isAuthorized(pathname, user?.role, purpose);

  const openModalStatus = () => {
    setIsModalStatusOpen(true);
  };

  const closeStatusModal = () => {
    setIsModalStatusOpen(false);
    setIdFromOverlay(null);
  };

  const customNavigate = () => {
    setIsModalOpen(false);
    setIsModalStatusOpen(false);
    setCondition(null);
    setIdFromOverlay(null);
    setForceRefresh(!forceRefresh);
  };

  const getQuittanceEnums = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/quittances/enums`
      });
      setQuittanceEnums(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getPaymentEnums = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/quittances/enums`
      });
      setPaymentEnums(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getLeaders = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: 'users?role=users:LEADER-USER'
      });
      setLeaders(data);
    } catch (error) {
      message(error);
    }
  };

  const getBankReconciliation = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/bankreconciliations`
      });
      setBankReconciliations(data);
    } catch (e) {
      message(e);
    }
  };

  const getCustomers = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: '/customers'
      });
      setCustomers(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getSales = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: '/users?role=users:SALES-USER'
      });
      setSales(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getPsMacro = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/psmacro?sort=title`
      });
      setMacroPs(data);
    } catch (e) {
      message(e);
    }
  };

  const getPsMicro = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/psmicro?sort=title`
      });
      setMicroPs(data);
    } catch (e) {
      message(e);
    }
  };

  const getServiceEnums = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/services/enums`
      });
      setServiceEnums(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const onArchived = async (id) => {
    try {
      await dispatchAPI('PATCH', {
        url: `/quittances/${id}`,
        body: { status: 'ARCHIVED' }
      });
      setForceRefresh(!forceRefresh);
      showSuccessMessage(t, 'quittances', 'archive');
    } catch (error) {
      message(error);
    }
  };

  const deleteTransfer = async (id, callback) => {
    try {
      await dispatchAPI('DELETE', { url: `/transfer/${id}` });
      callback();
      showSuccessMessage(t, 'transfer', 'archive');
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const downloadTransfer = async (id, type, callback, record) => {
    try {
      if (type === 'csv') {
        const result = await dispatchAPI('GET', {
          url: `/transfer/generate/${id}/${type}`
        });
        const blob = new Blob([result.data], {
          type: 'text/csv;charset=UTF-8'
        });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = `${format(new Date(), 'yyyyMMdd_hhmmss')}_vrmnt.csv`;
        a.click();
      } else {
        let data = record?.xml_content;
        if (!data) {
          const result = await dispatchAPI('GET', {
            url: `/transfer/generate/${id}/${type}`
          });
          data = result.data;
        }

        const blob = new Blob([data], {
          type: 'text/xml;charset=UTF-8'
        });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = `${format(new Date(), 'yyyyMMdd_hhmmss')}_vrmt.xml`;
        a.click();
      }
    } catch (e) {
      if (e.response) message(e.response.status);
    }
    callback();
  };

  const onPrintAction = async (id) => {
    setIdInvoice(id);
    setIsSpinLoading(true);
    try {
      const { data, headers } = await dispatchAPI('GET', {
        url: `/invoices/generate/${id}`,
        responseType: 'blob'
      });
      const blob = new Blob([data], {
        type: data.type
      });
      const fileName = headers['content-disposition'].substring(
        headers['content-disposition'].indexOf('=') + 1,
        headers['content-disposition'].length
      );
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = `${fileName}`;
      a.click();
      antdMessage.success(t('success.messages.download'));
    } catch (e) {
      if (e.response) message(e.response.status);
    }
    setIdInvoice('');
    setIsSpinLoading(false);
  };

  useEffect(() => {
    (async () => {
      await Promise.all([
        getQuittanceEnums(),
        getPaymentEnums(),
        getLeaders(),
        getSales(),
        getPsMicro(),
        getPsMacro(),
        getCustomers(),
        getBankReconciliation(),
        getServiceEnums()
      ]);
    })();
  }, []);

  return (
    <QuittanceContext.Provider
      value={{
        quittanceEnums,
        paymentEnums,
        leaders,
        sales,
        microPs,
        macroPs,
        forceRefresh,
        onArchived,
        selectedPeriods,
        setSelectedPeriods,
        quittance,
        setQuittance,
        isAdmin,
        setForceRefresh,
        customers,
        formValues,
        setFormValues,
        bankReconciliations,
        serviceEnums,
        onPrintAction,
        deleteTransfer,
        downloadTransfer,
        isSpinLoading,
        idInvoice,
        isModalStatusOpen,
        closeStatusModal,
        customNavigate,
        buttonClicked,
        setButtonClicked,
        openModalStatus,
        isModalOpen,
        setIsModalOpen,
        condition,
        setCondition,
        idFromOverlay,
        setIdFromOverlay,
        hasAccess
      }}
    >
      {children}
    </QuittanceContext.Provider>
  );
};

QuittanceContextProvider.propTypes = {
  children: PropTypes.element.isRequired
};

export default () => useContext(QuittanceContext);
