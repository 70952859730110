/**
 * Checks if the keys in the key array are present in the filter object keys array.
 * It sets the `sliceIndexFilter` to the index of the found key minus one and `filterObjectKeysBoolean` to true if a match is found.
 *
 * @param {string[]} keyArray - An array of keys to check.
 * @param {string[]} filterObjectKeys - An array of filter object keys to compare against.
 * @returns {Object} An object containing the slice index filter and a boolean indicating if a filter object key was found.
 */
const checkFilterObjectKeys = (keyArray, filterObjectKeys) => {
  let sliceIndexFilter;
  let filterObjectKeysBoolean;
  for (let i = 0; i < keyArray.length; i += 1) {
    for (let j = 0; j < filterObjectKeys.length; j += 1) {
      if (keyArray[i] === filterObjectKeys[j]) {
        sliceIndexFilter = i - 1;
        filterObjectKeysBoolean = true;
      }
    }
  }
  return { sliceIndexFilter, filterObjectKeysBoolean };
};

/**
 * Removes the colon (and any trailing spaces) from the end of a string.
 *
 * @function
 *
 * @param {string} str - The string from which the colon should be removed.
 * @returns {string} - The string without the trailing colon and spaces.
 */
const trimColon = (str) => str?.replace(/ :\s*$/, '');

/**
 * Picks the correct translation for a given text considering its context (form or show).
 *
 * This function formats and selects the appropriate translation based on various conditions.
 * It handles different cases for showing and form contexts and adjusts translations accordingly.
 *
 * @function
 *
 * @param {string} showText - The key for translation in 'show' context.
 * @param {string} formText - The key for translation in 'form' context.
 * @param {Function} t - Translation function from i18next.
 * @param {boolean} filterObjectKeysBoolean - Determines if filter object keys should be considered.
 * @param {string} source - The source context of the translation ('header', 'show', etc.).
 * @returns {string} - The formatted and translated text.
 */
const pickCorrectTranslation = (
  showText,
  formText,
  t,
  filterObjectKeysBoolean,
  source
) => {
  const formatText = (inputString) =>
    inputString.charAt(0).toUpperCase() +
    inputString.substring(1).toLowerCase();

  const filterObjectCase = (string) => {
    const stringArray = string.split('.');
    const rawArray = stringArray.slice(0, 1);
    rawArray.push('title');
    const transformedString = formatText(
      t(`${rawArray.join('.')}`).slice(0, -1)
    );
    return transformedString;
  };

  const createFirstPartString = (caseSource) => {
    if (caseSource === 'show') {
      return filterObjectCase(showText);
    }
    return filterObjectCase(formText);
  };

  const createSecondPartString = (caseSource) => {
    if (caseSource === 'show') {
      return showText?.replace(/^.*?\./, 'users.');
    }
    return formText?.replace(/^.*?\./, 'users.');
  };

  switch (true) {
    case formatText(t(showText)).includes(
      'returned an object instead of string'
    ) &&
      filterObjectKeysBoolean &&
      source === 'header':
      return formatText(
        `${createFirstPartString('show')} - ${t(
          `${createSecondPartString('show')}.title`
        )}`
      );
    case formatText(t(formText)).includes(
      'returned an object instead of string'
    ) &&
      filterObjectKeysBoolean &&
      source === 'header':
      return `${createFirstPartString('form')} - ${t(
        `${createSecondPartString('form')}.title`
      )}`;
    case formatText(t(showText)).includes(
      'returned an object instead of string'
    ):
      return formatText(t(`${showText}.title`));
    case formatText(t(formText)).includes(
      'returned an object instead of string'
    ):
      return formatText(t(`${formText}.title`));
    case showText !== t(showText):
      return formatText(t(showText));
    case formText !== t(formText):
      return formatText(t(formText));
    default:
      return t(formText);
  }
};

/**
 * Creates a translation key based on a provided source, key, translation function, and prefix function.
 *
 * This function is responsible for generating translation keys considering different contexts
 * and scenarios. It handles tag, address, phone number, user keys, and other specific cases
 * to create a suitable translation key for the given context.
 *
 * @param {string} source - The source of the key (e.g., 'tag', 'form').
 * @param {string} key - The key to be translated.
 * @param {Function} t - Translation function from i18next.
 * @param {Function} p - Prefix function to prepend a prefix based on context.
 * @returns {string} - The generated translation key.
 */
export const createTranslationKey = (source, key, t, p) => {
  let updatedKey = key;
  updatedKey = key?.replace(/\.index/g, '');

  const userKeys = ['manager'];
  const filterObjectKeys = [
    'phone_number',
    'address',
    'cell_phone_number',
    'created_by'
  ];
  const addressKeysToBeReplaced = ['petsitting_address'];
  const phoneNumberKeysToBeReplaced = [
    'supervision_phone',
    'cell_phone_number'
  ];
  const userKeysToBeReplaced = ['created_by'];

  let showText;
  let formText;

  let keyArray;

  if (source === 'tag') {
    const tagKeyArray = `users.${updatedKey}`.split('.');

    if (tagKeyArray.some((item) => addressKeysToBeReplaced.includes(item))) {
      keyArray = tagKeyArray.map((item) =>
        addressKeysToBeReplaced.includes(item) ? 'address' : item
      );
    } else if (
      tagKeyArray.some((item) => phoneNumberKeysToBeReplaced.includes(item))
    ) {
      keyArray = tagKeyArray.map((item) =>
        phoneNumberKeysToBeReplaced.includes(item) ? 'phone_number' : item
      );
    } else if (
      tagKeyArray.some((item) => userKeysToBeReplaced.includes(item))
    ) {
      keyArray = tagKeyArray.map((item) =>
        userKeysToBeReplaced.includes(item) ? 'user' : item
      );
    } else {
      keyArray = updatedKey.split('.');
    }
  } else {
    keyArray = updatedKey.split('.');
  }

  const { sliceIndexFilter, filterObjectKeysBoolean } = checkFilterObjectKeys(
    keyArray,
    filterObjectKeys
  );

  const sliceIndex =
    filterObjectKeysBoolean === true && source === 'tag'
      ? sliceIndexFilter
      : -2;

  // FIRST LEVEL TRANSFORMATION
  if (keyArray.length === 1) {
    return t(`${p(keyArray[0])}.placeholder_title`);
  }
  if (keyArray.length === 2) {
    const showTextArray = [...keyArray];
    showTextArray.splice(0, 1, `${p(keyArray[0])}`);
    showTextArray.splice(1, 0, 'show');

    showText = showTextArray.join('.');

    const formTextArray = [...keyArray];
    formTextArray.splice(0, 1, `${p(keyArray[0])}`);
    formTextArray.splice(1, 0, 'form');
    formText = formTextArray.join('.');

    return trimColon(
      pickCorrectTranslation(
        showText,
        formText,
        t,
        filterObjectKeysBoolean,
        userKeys,
        source
      )
    );
  }

  // SECOND LEVEL TRANSFORMATION
  const showTextArray = [...keyArray].slice(sliceIndex);

  if (userKeys.includes(showTextArray[0]) && source === 'tag') {
    showTextArray.splice(0, 1, 'users');
  }
  showTextArray.splice(0, 1, `${p(showTextArray[0])}`);
  showTextArray.splice(1, 0, 'show');
  showText = showTextArray.join('.');

  const formTextArray = [...keyArray].slice(sliceIndex);
  if (userKeys.includes(formTextArray[0]) && source === 'tag') {
    formTextArray.splice(0, 1, 'users');
  }
  formTextArray.splice(0, 1, `${p(formTextArray[0])}`);
  formTextArray.splice(1, 0, 'form');
  formText = formTextArray.join('.');

  return trimColon(
    pickCorrectTranslation(
      showText,
      formText,
      t,
      filterObjectKeysBoolean,
      source
    )
  );
};
