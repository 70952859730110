import { useTranslation } from 'react-i18next';
import { endOfWeek, getMonth, format, getWeek, addWeeks } from 'date-fns';
import { Flex, Tag, Typography } from 'antd';

interface MonthNameProps {
  startWeek: Date;
  ISOWeekName: boolean;
}

export const MonthName: React.FunctionComponent<MonthNameProps> = ({
  startWeek,
  ISOWeekName
}) => {
  const { t } = useTranslation();
  const getMonthName = () => {
    const endOfWeekDate = endOfWeek(startWeek);

    if (getMonth(endOfWeekDate) == getMonth(startWeek)) {
      return `${t(`calendar.months.${format(startWeek, 'MMMM')}`)}     ${format(
        startWeek,
        'y'
      )}`;
    }

    return `${t(`calendar.months.${format(startWeek, 'MMMM')}`)} ${
      format(startWeek, 'y') !== format(endOfWeekDate, 'y')
        ? format(startWeek, 'y')
        : ''
    } - ${t(`calendar.months.${format(endOfWeekDate, 'MMMM')}`)} ${format(
      endOfWeekDate,
      'y'
    )}`;
  };

  return (
    <Flex gap={10} style={{ maxHeight: '25px' }}>
      <Typography.Title
        level={4}
        className="calendar-period"
        style={{
          marginBottom: 0,
          maxWidth: 260,
          textAlign: 'right'
        }}
      >
        {getMonthName()}
      </Typography.Title>
      <Tag style={{ width: 90, textAlign: 'right', fontSize: 14 }}>
        {`${t('calendar.week')}
        ${
          ISOWeekName
            ? getWeek(startWeek)
            : getWeek(new Date(addWeeks(startWeek, -1)))
        }`}
      </Tag>
    </Flex>
  );
};
