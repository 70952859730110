import React, { createContext, useContext, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { useAuthContext } from '../../contexts/AuthContext';
import { useErrorMessage } from '../../utils/errorMessage';
import { showSuccessMessage } from '../../utils/showSuccessMessage';

const CustomerServiceContext = createContext({});

/**
 * Context provider component for managing quote-related data.
 * This context provides various state values and methods related to quotes.
 *
 * @component
 * @param {object} props - React component props
 * @param {React.ReactNode} props.children - The child components that will have access to the context
 * @returns {React.ReactNode} Wrapped children with the quote context
 */
export const CustomerServiceContextProvider = ({ children }) => {
  const { dispatchAPI } = useAuthContext();
  const { t } = useTranslation();
  const { message } = useErrorMessage();
  const [customerServiceEnums, setCustomerServiceEnums] = useState({});
  const [customerServices, setCustomerServices] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [users, setUsers] = useState([]);
  const [psMacro, setPsMacro] = useState({});
  const [psMicro, setPsMicro] = useState({});
  const [refresh, setRefresh] = useState(false);
  const [urgentCount, setUrgentCount] = useState();
  const [totalCount, setTotalCount] = useState();
  const [customerService, setCustomerService] = useState({});

  const getCustomerService = async (id, extra) => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/customerservices/${id}${extra || ''}`
      });
      setCustomerService(data);
    } catch (e) {
      message(e);
    }
  };

  const addSeen = async (id) => {
    try {
      await dispatchAPI('GET', {
        url: `/customerservices/${id}/seen`
      });
    } catch (e) {
      message(e);
    }
  };
  const getMacroPs = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/Psmacro?sort=title`
      });
      setPsMacro(data);
    } catch (e) {
      message(e);
    }
  };

  const getMicroPs = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/Psmicro?sort=title`
      });
      setPsMicro(data);
    } catch (e) {
      message(e);
    }
  };

  const unsee = async (id) => {
    try {
      await dispatchAPI('GET', {
        url: `/customerservices/${id}/unsee`
      });
      setRefresh(true);
    } catch (e) {
      message(e);
    }
  };

  const changeColor = (rowStatus, rowDate) => {
    const currentDate = new Date();
    if (rowStatus === 'TO_PROCESSED') {
      if (rowDate < currentDate) {
        return 'red';
      }
      return 'green';
    }
    return '';
  };

  const getCustomerServiceEnums = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/customerservices/enums`
      });
      setCustomerServiceEnums(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };
  const deleteResource = async (id) => {
    try {
      await dispatchAPI('DELETE', { url: `/customerservices/${id}` });
      setRefresh(true);
      showSuccessMessage(t, 'customerservices', 'archive');
    } catch (e) {
      message(e);
    }
  };
  const getCustomerServices = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/customerservices`
      });
      setCustomerServices(data);
      setTotalCount(data.length);
      let num = 0;
      data.forEach((item) => {
        if (item.status === 'TO_PROCESSED') {
          num += 1;
        }
      });
      setUrgentCount(num);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getDataFromId = async (id) => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/customerservices/${id}?populate=created_by,recipients,customer,customer.leader,historic,historic.author,files`
      });
      return data;
    } catch (e) {
      message(e);
      return [];
    }
  };

  const changeStatus = async (id, status) => {
    try {
      const formData = new FormData();
      formData.append('values', JSON.stringify({ status }));
      await dispatchAPI('PATCH', {
        url: `/customerservices/${id}`,
        body: formData
      });
      setRefresh(!refresh);
      showSuccessMessage(t, 'status', 'changed');
    } catch (e) {
      message(e);
    }
  };

  const getCustomers = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: '/customers?sort=ps_number'
      });
      setCustomers(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getUsers = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/users?noFilterByRole=${true}`
      });
      setUsers(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  useEffect(() => {
    (async () => {
      await getCustomerServices();
      await getCustomerServiceEnums();
      await getCustomers();
      await getUsers();
      await getMicroPs();
      await getMacroPs();
    })();
  }, []);

  return (
    <CustomerServiceContext.Provider
      value={{
        customerServices,
        customerServiceEnums,
        customers,
        users,
        deleteResource,
        getDataFromId,
        refresh,
        changeStatus,
        getCustomerService,
        customerService,
        setRefresh,
        changeColor,
        urgentCount,
        totalCount,
        addSeen,
        unsee,
        getCustomerServices,
        psMacro,
        psMicro
      }}
    >
      {children}
    </CustomerServiceContext.Provider>
  );
};

CustomerServiceContextProvider.propTypes = {
  children: PropTypes.element.isRequired
};

export default () => useContext(CustomerServiceContext);
