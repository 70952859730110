import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import { DatePicker } from 'antd';
import { ListResource } from '../../../../components';
import { useColumns } from '../../../interventions/columns';
import { headers } from '../../../interventions/headers';
import { useFormatter } from '../../../../utils/formatter';
import { useAuthContext } from '../../../../contexts/AuthContext';
import { useErrorMessage } from '../../../../utils/errorMessage';

const { RangePicker } = DatePicker;

/**
 * Component for listing interventions with filtering options.
 *
 * @component
 * @returns {JSX.Element} Rendered ListInterventions component
 */

export const ListInterventions = ({ id }) => {
  const { formattedData } = useFormatter();
  const { dispatchAPI, user } = useAuthContext();
  const { message } = useErrorMessage();
  const [filterDate, setFilterDate] = useState([]);
  const [interventionEnums, setInterventionEnums] = useState({});
  const [leadersAndGtp, setLeadersAndGtp] = useState([]);
  const columns = useColumns(interventionEnums, leadersAndGtp, null, user);

  const getInterventionEnums = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/interventions/enums`
      });
      setInterventionEnums(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getLeadersAndGtp = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: 'users?role=users:LEADER-USER,users:USER'
      });
      setLeadersAndGtp(data);
    } catch (error) {
      message(error);
    }
  };

  useEffect(() => {
    (async () => {
      await getInterventionEnums();
      await getLeadersAndGtp();
    })();
  }, []);

  return (
    <ListResource
      resourceName="interventions"
      contentCustomStyle={{ margin: 0, padding: 0 }}
      columns={columns}
      headers={headers}
      populate="contract,user"
      resourceModelName="Intervention"
      withImportButton={false}
      editAction={false}
      formatter={formattedData}
      withCreateButton={false}
      showAction={false}
      withPageHeader={false}
      extraQuery={`contract=${id}${
        filterDate?.length
          ? `&start_date>=${dayjs(
              new Date(filterDate[0]).setHours(0, 0, 0, 0)
            ).toISOString()}&end_date<=${dayjs(
              new Date(filterDate[1]).setHours(23, 59, 59, 999)
            ).toISOString()}`
          : ''
      }`}
      extra
      extraFilters={
        <RangePicker format="DD-MM-YYYY" onChange={setFilterDate} />
      }
    />
  );
};

ListInterventions.propTypes = {
  id: PropTypes.string.isRequired
};
