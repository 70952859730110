import { useState } from 'react';
import { Select, Typography, Tag, Flex } from 'antd';
import { useTranslation } from 'react-i18next';
import { ListResource } from '../../components';
import { useColumns } from './columns';
import { headers } from './headers';
import { useFormatter } from '../../utils/formatter';
import ServiceContext from './ServiceContext';
import { useAuthContext } from '../../contexts/AuthContext';
import AdjustedHourModal from '../contracts/AdjustedHourModal';
import { showSuccessMessage } from '../../utils/showSuccessMessage';
import { useErrorMessage } from '../../utils/errorMessage';

const { Option } = Select;

export const ListServices = () => {
  const { formattedData } = useFormatter('services');
  const { t } = useTranslation();
  const { message } = useErrorMessage();
  const { dispatchAPI, user } = useAuthContext();
  const { serviceEnums } = ServiceContext();
  const [selectedType, setSelectedType] = useState();
  const [selectedLeader, setSelectedLeader] = useState([]);
  const [isModalHourOpen, setIsModalHourOpen] = useState(false);
  const [serviceId, setServiceId] = useState('');
  const [adjustedHours, setAdjustedHours] = useState(0);
  const [forceRefresh, setForceRefresh] = useState(false);

  const openModalAdjustedHours = (hours, servId) => {
    setIsModalHourOpen(true);
    setServiceId(servId);
    setAdjustedHours(hours);
  };

  const closeModalAdjustedHours = () => {
    setIsModalHourOpen(false);
    setServiceId('');
    setAdjustedHours(0);
  };

  const columns = useColumns(openModalAdjustedHours);

  const patchServiceAdjustedHour = async (idService, values) => {
    try {
      await dispatchAPI('PATCH', {
        url: `/services/${idService}`,
        body: values
      });
      setForceRefresh(!forceRefresh);
      showSuccessMessage(t, 'hour', 'adjusted');
      closeModalAdjustedHours();
    } catch (e) {
      message(e);
    }
  };

  return (
    <>
      {isModalHourOpen && (
        <AdjustedHourModal
          openModal={isModalHourOpen}
          closeModalAdjustedHours={closeModalAdjustedHours}
          id={serviceId}
          adjustedHours={adjustedHours}
          patchServiceAdjustedHour={patchServiceAdjustedHour}
        />
      )}
      <ListResource
        resourceName="services/calcul"
        columns={columns}
        headers={headers}
        resourceModelName="Services"
        formatter={formattedData}
        withImportButton={false}
        withCreateButton={false}
        deleteAction={false}
        editAction={false}
        populate="contract.customer,user_referent,assigned_user,leader"
        forceRefresh={forceRefresh}
        extraQuery={`type=${selectedType || ''}${
          selectedLeader.length ? `&leader=${selectedLeader}` : ''
        }`}
        extra
        extraFilters={
          <>
            <Flex wrap>
              <Typography style={{ marginLeft: '15px', alignSelf: 'center' }}>
                {t('services.columns.type')} :
              </Typography>
              <Select
                allowClear
                mode="multiple"
                onChange={(value) => {
                  setSelectedType(value);
                }}
                onClear={() => {
                  setSelectedType(null);
                }}
                defaultValue={selectedType}
                style={{ width: '180px', marginLeft: '15px' }}
              >
                {(serviceEnums.types || []).map((type) => (
                  <Option key={type} value={type}>
                    {t(`services.columns.enums.${type}`)}
                  </Option>
                ))}
              </Select>
            </Flex>

            {user.role === 'admins:SUPER-ADMIN' ||
            user.role === 'admins:ADMIN' ? (
              <Flex wrap>
                <Typography style={{ marginLeft: '15px', alignSelf: 'center' }}>
                  {t('services.columns.leader')} :
                </Typography>
                <Select
                  allowClear
                  mode="multiple"
                  onChange={(value) => setSelectedLeader(value)}
                  onClear={() => {
                    setSelectedLeader([]);
                  }}
                  style={{ width: '180px', marginLeft: '15px' }}
                >
                  {serviceEnums?.leaders?.map((leader) => (
                    <Option key={leader._id} value={leader._id}>
                      <Tag color={leader.color}>
                        {`${leader.first_name} ${
                          leader?.usage_last_name || leader?.last_name
                        }`}
                      </Tag>
                    </Option>
                  ))}
                </Select>
              </Flex>
            ) : null}
          </>
        }
      />
    </>
  );
};
