import { useTranslation } from 'react-i18next';
import { Tag } from 'antd';
import { userRoles } from '../../utils/constants/tagColors';

/**
 * Returns an array of column objects for displaying news-related data.
 *
 * @hook
 * @returns {Array} An array of column objects with titles, dataIndex, and rendering functions.
 */
export const useColumns = () => {
  const { t } = useTranslation();
  return [
    {
      title: t('news.form.function'),
      dataIndex: 'role',
      key: 'role',
      render: (_, record) => (
        <Tag color={userRoles[record.role.split(':')[1]]}>
          {t(`users.tags.${record.role.split(':')[1]}`)}
        </Tag>
      )
    },
    {
      title: t('news.form.name'),
      dataIndex: 'first_name',
      key: 'first_name',
      render: (_, record) =>
        `${record.first_name || ''} ${
          record?.usage_last_name || record?.last_name
        }`
    }
  ];
};
