import { useTranslation } from 'react-i18next';
import { Space, Button, Tag, Divider, Popconfirm } from 'antd';
import {
  EditOutlined,
  ContainerOutlined,
  WarningOutlined
} from '@ant-design/icons';
import { useLocation } from 'react-router-dom';
import { userRoles } from '../../../../utils/constants/tagColors';
import { isAuthorized } from '../../../../utils/constants/authorizedMenu';
import { useAuthContext } from '../../../../contexts/AuthContext';
import { formatValue } from '../../../../utils/formatNumber';

/**
 * Custom hook to generate columns configuration for the technical users table.
 *
 * @hook
 * @returns {Array} Columns configuration for the technical users table.
 */

export const useTechnicalColumns = (options) => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const { user } = useAuthContext();
  const hasAccess = (purpose) => isAuthorized(pathname, user?.role, purpose);

  return [
    {
      title: t('users.form.operator_code'),
      key: 'operator_code',
      dataIndex: 'operator_code',
      sorter: true
    },
    {
      title: t('users.form.gender'),
      key: 'gender',
      dataIndex: 'gender',
      sorter: true,
      render: (text) => t(`users.genders.${text}`)
    },
    {
      title: t('users.form.usage_last_name'),
      key: 'usage_last_name',
      dataIndex: 'usage_last_name',
      sorter: true
    },
    {
      title: t('users.form.first_name'),
      key: 'first_name',
      dataIndex: 'first_name',
      sorter: true
    },
    {
      title: t('users.form.email'),
      key: 'email',
      dataIndex: 'email',
      sorter: true
    },
    {
      title: t('users.form.phone_number.number'),
      key: 'phone_number.number',
      dataIndex: 'phone_number',
      sorter: true,
      render: (phone_number) =>
        formatValue(
          `${phone_number?.country_code || ''}${phone_number?.number || ''}`,
          'phone'
        )
    },
    {
      title: t('users.form.local_phone_number.number'),
      key: 'local_phone_number',
      dataIndex: 'local_phone_number',
      sorter: true,
      render: (local_phone_number) =>
        formatValue(
          `${local_phone_number?.country_code || ''}${
            local_phone_number?.number || ''
          }`,
          'phone'
        )
    },
    {
      title: t('customers.form.collaborator.role'),
      key: 'role',
      dataIndex: 'role',
      render: (_, record) => (
        <Tag color={userRoles[record.role.split(':')[1]]}>
          {t(`users.tags.${record.role.split(':')[1]}`)}
        </Tag>
      )
    },
    {
      title: t('users.form.invoiced'),
      key: 'invoiced',
      dataIndex: 'invoiced',
      sorter: true,
      render: (text) => <Tag>{text ? t('general.YES') : t('general.NO')}</Tag>
    },
    {
      title: t('users.form.report_recipient_abr'),
      key: 'report_recipient',
      dataIndex: 'report_recipient',
      sorter: true,
      render: (text) => <Tag>{text ? t('general.YES') : t('general.NO')}</Tag>
    },
    {
      key: 'action',
      align: 'right',
      render: (text, record) => (
        <Space size="middle">
          {hasAccess('edit_collab') && (
            <Button
              type="ghost"
              onClick={() => options?.onEdit(record?._id)}
              icon={<EditOutlined />}
            />
          )}
          <Divider type="vertical" />
          {hasAccess('archive_collab') && (
            <Popconfirm
              title={t('datatable.column.action.delete.title')}
              okText={t('datatable.column.action.delete.ok')}
              okButtonProps={{ type: 'danger' }}
              cancelText={t('datatable.column.action.delete.cancel')}
              onConfirm={() => options.onDelete(record._id)}
              icon={<WarningOutlined />}
            >
              <ContainerOutlined style={{ fontSize: 18, color: 'red' }} />
            </Popconfirm>
          )}
        </Space>
      )
    }
  ];
};
