import PropTypes from 'prop-types';
import { Modal, Form } from 'antd';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import useQualityControlContext from '../QualityControlContext';
import useClosureFields from '../fields/closureFields';
import { useGenerateFormItem } from '../../../utils/generateFormItem';
import { formItemLayout } from '../../../utils/constants/formLayout';
import { useFooterButtons } from './useFooterButtons';

/**
 * ClosureModal component that shows the closure modal of the quality control
 *
 * @component
 * @param {object} isModalOpen - Modal open state
 * @param {function} setIsModalOpen - Set modal open state
 * @param {function} closeQualityControl - Close quality control function
 * @return {component} - ClosureModal component
 */
export const ClosureModal = ({
  isModalOpen,
  setIsModalOpen,
  closeQualityControl
}) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const { enums } = useQualityControlContext();
  const [purpose, setPurpose] = useState();
  const [status, setStatus] = useState();

  const generateFields = useGenerateFormItem();

  const { closureFields } = useClosureFields(purpose, setPurpose, enums, t);

  const onSubmit = (s) => {
    setStatus(s);
    form.submit();
  };

  const footerButtons = useFooterButtons(purpose, setIsModalOpen, onSubmit);

  const onFinish = (values) => {
    closeQualityControl({ closure: { ...values.closure, status } });
  };

  return (
    <Modal
      centered
      open={isModalOpen}
      onClose={() => setIsModalOpen(false)}
      title={t('quality.close')}
      onCancel={() => {
        setIsModalOpen(false);
      }}
      footer={footerButtons}
    >
      <Form form={form} {...formItemLayout} onFinish={onFinish}>
        {closureFields.map((field) => generateFields('quality', field))}
      </Form>
    </Modal>
  );
};

ClosureModal.propTypes = {
  isModalOpen: PropTypes.bool,
  setIsModalOpen: PropTypes.func,
  closeQualityControl: PropTypes.func
};

ClosureModal.defaultProps = {
  isModalOpen: false,
  setIsModalOpen: () => {},
  closeQualityControl: () => {}
};
