import PropTypes from 'prop-types';
import { Modal } from 'antd';
import { CreateUpdateContainer } from '../../components/CreateUpdateContainer/CreateUpdateContainer';
import useFields from './outstandingFields';

const OutstandingModal = ({
  isOutstandingModalOpen,
  closeOutstandingModal,
  purpose,
  outstanding,
  customNavigateOutstanding,
  user
}) => {
  const { fields } = useFields(user);

  const config = {
    onCreateResource: {
      setBody: (data) => ({
        ...data
      })
    }
  };

  return (
    <Modal
      centered
      open={isOutstandingModalOpen}
      onCancel={closeOutstandingModal}
      width={800}
      footer={false}
    >
      <CreateUpdateContainer
        fields={fields}
        loadingFields={false}
        purpose={purpose}
        baseUrl="outstandings"
        resource="outstanding"
        isOverlay
        customNavigate={customNavigateOutstanding}
        config={config}
        idFromOverlay={outstanding[0]?._id}
      />
    </Modal>
  );
};

OutstandingModal.propTypes = {
  isOutstandingModalOpen: PropTypes.bool.isRequired,
  closeOutstandingModal: PropTypes.func.isRequired,
  outstanding: PropTypes.shape({
    _id: PropTypes.string
  }),
  customNavigateOutstanding: PropTypes.func,
  purpose: PropTypes.string,
  user: PropTypes.shape({})
};

OutstandingModal.defaultProps = {
  outstanding: [],
  customNavigateOutstanding: null,
  purpose: null,
  user: null
};

export default OutstandingModal;
