import PropTypes from 'prop-types';
import { Card } from 'antd';
import { useTranslation } from 'react-i18next';
import { useListContent } from './listContent';
import { DescriptionList } from '../../../../components';

/**
 * MainCard component that shows the main information of the quality control
 *
 * @component
 * @param {object} quality - Quality control object
 * @return {component} - MainCard component
 */
export const MainCard = ({ quality }) => {
  const { t } = useTranslation();
  const listContent = useListContent(quality, t);
  return (
    <Card title={t('quality.main_information.title')}>
      <DescriptionList data={listContent} translate columns={24} />
    </Card>
  );
};

MainCard.propTypes = {
  quality: PropTypes.shape({})
};

MainCard.defaultProps = {
  quality: {}
};
