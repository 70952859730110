import { useState } from 'react';
import { Drawer } from 'antd';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useWebsitesColumns } from './columns/websitesColumns';
import { useAuthContext } from '../../../contexts/AuthContext';
import { useErrorMessage } from '../../../utils/errorMessage';
import { CreateUpdateContainer, ListResource } from '../../../components';
import { websitesFields } from './fields/websitesFields';
import { websitesHeaders } from './headers/websitesHeaders';
import { showSuccessMessage } from '../../../utils/showSuccessMessage';
import { isAuthorized } from '../../../utils/constants/authorizedMenu';
import { routes } from '../../../utils/constants/adminRoutes';

/**
 * Displays a table of website data for a specific customer.
 *
 * @component
 * @param {object} props - Component props.
 * @param {string} props.id - ID of the customer.
 * @param {function} props.refresh - Function to trigger a data refresh.
 * @returns {JSX.Element} - Rendered component.
 */

export const WebsitesTable = ({ id, refresh, forceWebSitesRefresh }) => {
  const { t } = useTranslation();
  const { dispatchAPI, user } = useAuthContext();
  const { message } = useErrorMessage();
  const [isDrawerVisible, setIsDrawerVisible] = useState(false);
  const [purpose, setPurpose] = useState('create');
  const [itemId, setItemId] = useState(null);

  const hasAccess = (p) =>
    isAuthorized(`${routes.CUSTOMERS}/show`, user.role, p);

  const onEdit = (item) => {
    setItemId(item);
    setPurpose('edit');
    setIsDrawerVisible(true);
  };

  const sendData = async (websiteData) => {
    try {
      if (purpose === 'create') {
        await dispatchAPI('POST', {
          url: `/websites`,
          body: { ...websiteData, customer: id }
        });
      } else {
        await dispatchAPI('PATCH', {
          url: `/websites/${itemId}`,
          body: websiteData
        });
      }
      showSuccessMessage(
        t,
        'websites',
        purpose === 'create' ? 'create' : 'update'
      );
    } catch (error) {
      message(error);
    }
  };

  const handleOK = async (websiteData) => {
    await sendData(websiteData);
    setIsDrawerVisible(false);
    refresh();
  };

  const handleCancel = () => {
    setIsDrawerVisible(false);
  };

  const onDelete = async (item) => {
    try {
      await dispatchAPI('PATCH', {
        url: `/websites/${item}`,
        body: { status: 'ARCHIVED' }
      });
      refresh();
      showSuccessMessage(t, 'websites', 'archive');
    } catch (error) {
      message(error);
    }
  };

  const columns = useWebsitesColumns({ onEdit, onDelete }, hasAccess);
  const headers = websitesHeaders;
  const fields = websitesFields;

  return (
    <>
      <Drawer
        title={t('websites.title')}
        open={isDrawerVisible}
        width={700}
        destroyOnClose
        onClose={handleCancel}
      >
        <CreateUpdateContainer
          layout="vertical"
          fields={fields}
          purpose={purpose}
          baseUrl="websites"
          resource="websites"
          withHeader={false}
          customSubmit={handleOK}
          customNavigate={handleCancel}
          isOverlay
          idFromOverlay={itemId}
        />
      </Drawer>
      <ListResource
        resourceName="websites"
        contentCustomStyle={{ margin: 0, padding: 0 }}
        columns={columns}
        onClickAdd={
          hasAccess('create_website') &&
          (() => {
            setItemId(null);
            setIsDrawerVisible(true);
          })
        }
        withCreateButton={hasAccess('create_website')}
        headers={headers && hasAccess('export_website')}
        extraQuery={`status=ACTIVE&customer=${id}`}
        editAction={false}
        showAction={false}
        withCreateButtonText={t('buttons.create_custom.websites')}
        onDoubleClickAction={false}
        withPageHeader={false}
        resourceModelName="Website"
        deleteAction={false}
        withImportButton={false}
        withUploadButton={user.role.split(':')[1] !== 'USER'}
        forceRefresh={forceWebSitesRefresh}
      />
    </>
  );
};

WebsitesTable.propTypes = {
  id: PropTypes.string,
  refresh: PropTypes.func,
  forceWebSitesRefresh: PropTypes.bool
};

WebsitesTable.defaultProps = {
  refresh: () => {},
  id: undefined,
  forceWebSitesRefresh: undefined
};
