import PropTypes from 'prop-types';
import moment from 'moment';
import { useState } from 'react';
import { CreateUpdateContainer } from '../../components/CreateUpdateContainer/CreateUpdateContainer';
import useCollapseByCategories from './collapseByCategories';
import useQualityControlContext from './QualityControlContext';
import { ContentCustom } from '../../components';

/**
 * Component for creating or updating user information.
 *
 * @component
 * @param {string} purpose - Purpose of the form ('create' or 'update')
 * @returns {JSX.Element} CreateUpdateContainer component with user creation or update functionality
 */
export const CreateUpdateQualityControl = ({ purpose }) => {
  const [qualityControl, setQualityControl] = useState();
  const { setFormValues, collaborator, setTestFile, setSelectedPsMacros } =
    useQualityControlContext();

  const config = {
    onGetResource: {
      setFields: (data) => {
        setQualityControl(data);
        setTestFile(data.test?.test_file);
        setSelectedPsMacros(data.productivities?.map((p) => p.macro_ps._id));
        return {
          ...data,
          productivities: data.productivities?.map((p) => ({
            ...p,
            range_concerned: p.range_concerned?.length
              ? [moment(p.range_concerned[0]), moment(p.range_concerned[1])]
              : [null, null]
          }))
        };
      }
    },
    onUpdateResource: {
      setBody: (data) => {
        const formData = new FormData();
        formData.append(
          'values',
          JSON.stringify({
            ...data,
            productivities: data.productivities.map((p) => ({
              macro_ps: p.macro_ps,
              range_concerned: p.range_concerned,
              average: p.average,
              assessment: p.assessment
            }))
          })
        );
        return formData;
      }
    },
    onCreateResource: {
      setBody: (data) => {
        const newBody = {
          ...data,
          user: collaborator
        };
        return newBody;
      }
    }
  };
  const { categories, commentField } = useCollapseByCategories(purpose);
  return (
    <ContentCustom>
      <CreateUpdateContainer
        purpose={purpose}
        baseUrl="quality"
        resource="quality"
        config={config}
        categories={categories}
        setFormValues={setFormValues}
        formExtra={commentField}
        titleSuffix={`${qualityControl?.user?.first_name} ${(
          qualityControl?.user?.usage_last_name ||
          qualityControl?.user?.last_name ||
          ''
        ).toUpperCase()}
            `}
      />
    </ContentCustom>
  );
};

CreateUpdateQualityControl.propTypes = {
  purpose: PropTypes.string.isRequired
};
