import { Input, Checkbox, Form, Button, Select } from 'antd';
import { CloseCircleOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import useCustomerContext from '../CustomerContext';
import { selectFormat } from './utils';

const { Option } = Select;

/**
 * useFields is a custom hook that provides an array of field configurations for customer data input.
 *
 * @hook
 * @returns {Array<Object>} An array of field configurations.
 */

const useFields = (user) => {
  const { enums, centralHubs } = useCustomerContext();
  const { t } = useTranslation();
  const isAdminOrLeader =
    user.role.split(':')[0] === 'admins' ||
    user.role.split(':')[1] === 'LEADER-USER';

  const onsearch = (input, option) => {
    const result = option?.children.toLowerCase();
    return result.includes(input.toLowerCase());
  };

  return [
    {
      name: ['used_software'],
      key: 'used_software',
      rules: [{ required: true }],
      input: selectFormat(t, enums?.used_software, {
        optionsHaveID: true,
        optionsKey: 'title'
      })
    },
    { name: ['operator_code'], key: 'operator_code', input: <Input /> },
    {
      name: ['connection_type'],
      key: 'connection_type',
      input: selectFormat(t, enums?.connections, {
        render: (value) => t(`customers.connectionTypes.${value}`)
      })
    },
    {
      name: ['connection_comment'],
      key: 'connection_comment'
    },
    {
      name: ['session_password'],
      key: 'session_password'
    },
    {
      name: ['central_hub'],
      key: 'central_hub',
      input: (
        <Select
          allowClear
          showSearch
          filterOption={(input, option) => onsearch(input, option)}
        >
          {centralHubs?.map((hub) => (
            <Option key={hub._id} value={hub._id}>
              {hub.title}
            </Option>
          ))}
        </Select>
      )
    },
    {
      name: ['scor'],
      key: 'scor',
      input: <Checkbox />,
      valuePropName: 'checked'
    },
    {
      name: ['vital_sesam'],
      key: 'vital_sesam',
      input: <Checkbox />,
      valuePropName: 'checked'
    },
    {
      name: ['pro_card_type'],
      key: 'pro_card_type',
      input: selectFormat(t, enums?.cards)
    },
    {
      name: ['pro_card_code'],
      key: 'pro_card_code'
    },
    {
      name: ['pro_card_number'],
      key: 'pro_card_number'
    },
    {
      name: ['rejection_rate_entry'],
      key: 'rejection_rate_entry',
      input: <Checkbox />,
      valuePropName: 'checked'
    },
    {
      name: ['input_in_progress'],
      key: 'input_in_progress',
      input: <Checkbox />,
      valuePropName: 'checked'
    },
    {
      name: ['monthly_reconciliation_statement'],
      key: 'monthly_reconciliation_statement',
      input: <Checkbox />,
      valuePropName: 'checked'
    },
    {
      name: ['ps_security_code'],
      key: 'ps_security_code'
    },
    {
      name: ['connection_period'],
      key: 'connection_period',
      input: <Input.TextArea />
    },
    {
      name: ['insured_contact_type'],
      key: 'insured_contact_type',
      input: (
        <Checkbox.Group
          options={enums?.contactTypes?.map((type) => ({
            label: t(`customers.courrierTypes.${type}`),
            value: type
          }))}
        />
      )
    },
    {
      name: ['courrier'],
      key: 'courrier',
      input: (
        <Select
          allowClear
          showSearch
          filterOption={(input, option) => onsearch(input, option)}
        >
          {(enums?.courrierTypes || []).map((courrier) => (
            <Option key={courrier} value={courrier}>
              {t(`customers.courrier.${courrier}`)}
            </Option>
          ))}
        </Select>
      )
    },
    {
      name: ['on_site_billing'],
      key: 'on_site_billing',
      input: <Checkbox />,
      valuePropName: 'checked'
    },
    {
      name: ['min_amount_customer'],
      key: 'min_amount_customer',
      input: <Input type="number" />
    },
    {
      name: ['max_amount_customer'],
      key: 'max_amount_customer',
      input: <Input type="number" />
    },
    {
      name: ['customer_comments'],
      input: (
        <Form.List name="customer_comments" initialValue={[{}]}>
          {(fields, { add, remove }) => (
            <>
              {fields.map((field, index) => (
                <div key={field.name}>
                  <Form.Item
                    {...field}
                    key={[field.name, 'title']}
                    name={[field.name, 'title']}
                    label={`${t('customers.form.comments.comment')} ${
                      index + 1
                    }`}
                  >
                    <Input.TextArea disabled={!isAdminOrLeader} />
                  </Form.Item>
                  <CloseCircleOutlined
                    style={{
                      position: 'absolute',
                      top: '5px',
                      right: '5px',
                      fontSize: '16px',
                      color: 'red',
                      cursor: 'pointer'
                    }}
                    onClick={() => isAdminOrLeader && remove(field.name)}
                  />
                </div>
              ))}
              <Form.Item>
                <Button type="primary" onClick={() => isAdminOrLeader && add()}>
                  {t('customers.form.comments.addComment')}
                </Button>
              </Form.Item>
            </>
          )}
        </Form.List>
      )
    }
  ];
};

export default useFields;
