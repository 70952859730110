import { useState } from 'react';
import { Card, DatePicker } from 'antd';
import { useTranslation } from 'react-i18next';
import { ListResource } from '../../../components';
import { useColumns } from './columns';
import { routes, subRoutes } from '../../../utils/constants/adminRoutes';

const { RangePicker } = DatePicker;

/**
 * Card component for displaying absences longer than 72 hours.
 *
 * @component
 * @returns {JSX.Element} The JSX element representing the AdministrativeCard component.
 */
export const AdministrativeCard = () => {
  const { t } = useTranslation();
  const [dateRange, setDateRange] = useState([]);

  const columns = useColumns();
  return (
    <Card title={t('home.cards.titles.absences')}>
      <ListResource
        withPageHeader={false}
        contentCustomStyle={{ margin: 0, padding: 0 }}
        resourceName="events/absence-72"
        resourceModelName="Event"
        extraQuery={`${
          dateRange?.length
            ? `&startTime=${new Date(dateRange[0]) || ''}&endTime=${
                new Date(dateRange[1]) || ''
              }`
            : ''
        }`}
        columns={columns}
        withCreateButton={false}
        customActionColumn
        path={`${routes.RH}${subRoutes.RH.ABSENCE_VACATION}`}
        extraFilters={
          <RangePicker
            format="DD-MM-YYYY"
            style={{ marginLeft: '20px' }}
            onChange={setDateRange}
          />
        }
      />
    </Card>
  );
};
