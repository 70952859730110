import React, { useState } from 'react';
import dayjs from 'dayjs';
import { DatePicker, Switch } from 'antd';
import { t } from 'i18next';
import { ListResource } from '../../components';
import { useColumns } from './columns';
import { headers } from './headers';
import ContractContextProvider from './ContractContext';
import { useFormatter } from '../../utils/formatter';
import ModalConfirmationContract from '../quotes/ModalConfirmationContract';

/**
 * Component for listing contracts with filtering options.
 *
 * @component
 * @returns {JSX.Element} Rendered ListContracts component
 */

export const ListContracts = () => {
  const {
    psMicro,
    psMacro,
    customerEnums,
    setContract,
    contract,
    setIsModalOpen,
    isModalOpen,
    leaders,
    usersGtp,
    patchContract
  } = ContractContextProvider();
  const { formattedData } = useFormatter();
  const [filterDate, setFilterDate] = useState();
  const [activeContract, setActiveContract] = useState(true);

  const onEditAction = (data) => {
    setContract(data);
    setIsModalOpen(true);
  };

  const columns = useColumns(psMicro, psMacro, customerEnums, onEditAction);

  const closeModal = async (values) => {
    await patchContract(values, contract._id);
  };

  return (
    <>
      {isModalOpen && (
        <ModalConfirmationContract
          closeModal={closeModal}
          services={contract?.services}
          setIsModalOpen={setIsModalOpen}
          isModalOpen={isModalOpen}
          leaders={leaders}
          usersGtp={usersGtp}
          comment={contract?.comment}
        />
      )}
      <ListResource
        resourceName="contracts"
        columns={columns}
        headers={headers}
        populate="sale,micro_ps,macro_ps,services,customer.contacts"
        resourceModelName="Contract"
        withCreateButton={false}
        withImportButton={false}
        deleteAction={false}
        customActionColumn
        formatter={formattedData}
        extraQuery={`status=${activeContract ? 'ACTIVE' : 'TERMINATED'}${
          filterDate
            ? `&created_at>=${dayjs(
                new Date(filterDate).setHours(0, 0, 0, 0)
              ).toISOString()}`
            : ''
        }`}
        extra
        extraFilters={
          <DatePicker
            format="DD-MM-YYYY"
            style={{ width: 270 }}
            onChange={setFilterDate}
            placeholder="Séléctionner une date de début"
          />
        }
        extraButtons={
          <Switch
            onChange={() => setActiveContract(!activeContract)}
            checkedChildren={t(`contracts.status.${activeContract}`)}
            unCheckedChildren={t(`contracts.status.${activeContract}`)}
          />
        }
      />
    </>
  );
};
