import React, { useState } from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import { DatePicker } from 'antd';
import { ListResource } from '../../../../components';
import { useColumns } from './quittancesColumns';
import ContractContextProvider from '../../ContractContext';

const { RangePicker } = DatePicker;

/**
 * Component for listing contract quittances with filtering options.
 *
 * @component
 * @returns {JSX.Element} Rendered ListQuittances component
 */

export const ListQuittances = ({ id }) => {
  const { invoiceEnums, customerEnums, psMicro, psMacro } =
    ContractContextProvider();
  const columns = useColumns(invoiceEnums, customerEnums, psMicro, psMacro);
  const [filterDate, setFilterDate] = useState([]);

  const handleRangeChange = (dates) => {
    if (dates && dates.length === 2) {
      const startOfMonth = dates[0].startOf('month');
      const endOfMonth = dates[1].endOf('month');
      setFilterDate([startOfMonth, endOfMonth]);
    } else {
      setFilterDate(null);
    }
  };

  return (
    <ListResource
      resourceName="quittances"
      contentCustomStyle={{ margin: 0, padding: 0 }}
      populate="customer,micro_ps,micro_ps"
      columns={columns}
      resourceModelName="Quittance"
      withCreateButton={false}
      withPageHeader={false}
      extraQuery={`contract=${id}${
        filterDate?.length
          ? `&transfer_date>=${dayjs(
              new Date(filterDate[0]).setHours(0, 0, 0, 0)
            ).toISOString()}&transfer_date<=${dayjs(
              new Date(filterDate[1]).setHours(23, 59, 59, 999)
            ).toISOString()}`
          : ''
      }`}
      extraFilters={
        <RangePicker
          format="DD-MM-YYYY"
          picker="month"
          value={filterDate}
          onChange={handleRangeChange}
          placeholder={[
            'Sélectionner le mois de début',
            'Sélectionner le mois de fin'
          ]}
        />
      }
    />
  );
};

ListQuittances.propTypes = {
  id: PropTypes.string.isRequired
};
