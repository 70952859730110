import { Col, Row, Card } from 'antd';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { DescriptionList } from '../../../components';
import { useListTechniqueContent } from './listTechniqueContent';

/**
 * Component for displaying technique informations of a user in a card format.
 *
 * @component
 * @param {object} props - Component props
 * @param {object} props.data - User data containing technique details
 * @param {number} props.span - Number of columns the card should span
 * @returns {JSX.Element} Component JSX
 */

export const ShowTechnique = ({ data }) => {
  const { t } = useTranslation();
  const listContent = useListTechniqueContent(data);
  return (
    <Col xs={24} xl={10}>
      <Card title={t('users.show.technique')}>
        <Row align="middle">
          <Col span={24}>
            <DescriptionList data={listContent} translate />
          </Col>
        </Row>
      </Card>
    </Col>
  );
};

ShowTechnique.propTypes = {
  data: PropTypes.shape({})
};

ShowTechnique.defaultProps = {
  data: undefined
};
