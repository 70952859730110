import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Modal, Form, Row, Button, Typography, InputNumber } from 'antd';
import { CloseOutlined, CheckOutlined } from '@ant-design/icons';

const AdjustedHourModal = ({
  openModal,
  closeModalAdjustedHours,
  id,
  adjustedHours,
  patchServiceAdjustedHour
}) => {
  const { t } = useTranslation();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [form] = Form.useForm();

  const handleSubmit = async (values) => {
    setIsSubmitting(true);
    await patchServiceAdjustedHour(id, values);
    setIsSubmitting(false);
  };

  return (
    <Modal
      centered
      title={
        <Typography.Title
          level={4}
          style={{
            fontWeight: 300,
            textUnderlineOffset: '8px',
            color: 'var(--userColor)',
            marginBottom: 30
          }}
          underline
          strong={false}
        >
          {t('contracts.modal_adjusted_hours.title')}
        </Typography.Title>
      }
      footer={false}
      open={openModal}
      onCancel={() => {
        closeModalAdjustedHours(false);
      }}
      width={600}
    >
      <Form form={form} onFinish={handleSubmit}>
        <Form.Item
          name={['adjusted_hours']}
          label={t('quotes.form.service.adjusted_hours')}
          rules={[{ required: true }]}
          initialValue={adjustedHours}
        >
          <InputNumber />
        </Form.Item>
        <Form.Item>
          <Row justify="end">
            <Button
              style={{ margin: '0 8px' }}
              type="link"
              danger
              onClick={() => closeModalAdjustedHours(false)}
            >
              {`${t('buttons.cancel')} `}
              <CloseOutlined />
            </Button>
            <Button
              type="primary"
              htmlType="submit"
              loading={isSubmitting}
              disabled={isSubmitting}
            >
              {`${t('buttons.validate')} `}
              <CheckOutlined />
            </Button>
          </Row>
        </Form.Item>
      </Form>
    </Modal>
  );
};

AdjustedHourModal.propTypes = {
  closeModalAdjustedHours: PropTypes.func.isRequired,
  patchServiceAdjustedHour: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  openModal: PropTypes.bool.isRequired,
  adjustedHours: PropTypes.number
};

AdjustedHourModal.defaultProps = {
  adjustedHours: 0
};

export default AdjustedHourModal;
