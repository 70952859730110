import { useState } from 'react';
import { Button } from 'antd';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

export const AddButton = ({ resourceName, addItem }) => {
  const { t } = useTranslation();
  const [newItem] = useState({});

  return (
    <Button
      type="dashed"
      style={{ width: '100%' }}
      onClick={() => addItem(newItem, resourceName)}
    >
      {t(`configurations.add.${resourceName}`)}
    </Button>
  );
};

AddButton.propTypes = {
  resourceName: PropTypes.string.isRequired,
  addItem: PropTypes.func.isRequired
};
