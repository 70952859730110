import { Col, Row, Card, Avatar } from 'antd';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { DescriptionList } from '../../../components';
import { useListPrincipalContent } from './listPrincipalContent';

/**
 * Component for displaying principal information of a user in a card format.
 *
 * @component
 * @param {object} props - Component props
 * @param {object} props.data - User data containing principal details
 * @param {number} props.span - Number of columns the card should span
 * @returns {JSX.Element} Component JSX
 */

export const ShowPrincipalInfos = ({ data }) => {
  const { t } = useTranslation();
  const listContent = useListPrincipalContent(data);
  return (
    <Col span="24">
      <Card title={t('users.show.principal_infos')}>
        <Row gutter={[16, 16]}>
          {data && data.photo && (
            <Col xs={24} xxl={3}>
              <Avatar size={120} src={data && data.photo ? data.photo : ``} />
            </Col>
          )}
          <Col xs={24} xxl={19}>
            <DescriptionList data={listContent} translate columns={20} />
          </Col>
        </Row>
      </Card>
    </Col>
  );
};

ShowPrincipalInfos.propTypes = {
  data: PropTypes.shape({
    photo: PropTypes.string
  })
};

ShowPrincipalInfos.defaultProps = {
  data: undefined
};
