import { Tag } from 'antd';
import { useTranslation } from 'react-i18next';
import { userRoles, userPositions } from '../../utils/constants/tagColors';

/**
 * Custom hook for generating table columns for displaying user data.
 *
 * @hook
 * @param {object} enums - Enumerations data for user properties
 * @param {Array} psMicro - List of macro ps data for popover
 * @param {object} user - Current user object
 * @returns {Array} Array of table column objects
 */

export const useColumns = (enums, psMicro, user) => {
  const { t } = useTranslation();
  const isNotUserRole = user.role.split(':')[1] !== 'USER';

  const columns = [
    {
      title: t('users.form.position'),
      key: 'position',
      dataIndex: 'employments',
      render: (employments) => (
        <Tag color={userPositions[employments?.position]}>
          {employments?.position
            ? t(`users.positions.${employments?.position}`)
            : ''}
        </Tag>
      ),
      sorter: isNotUserRole
    },
    {
      title: t('users.form.micro_ps'),
      key: 'micro_ps.title',
      dataIndex: 'micro_ps',
      render: (micro_ps) => `${micro_ps?.title || ''}`,
      sorter: isNotUserRole
    },
    {
      title: t('users.form.last_name'),
      key: 'usage_last_name',
      dataIndex: 'usage_last_name',
      sorter: isNotUserRole
    },
    {
      title: t('users.form.first_name'),
      key: 'first_name',
      dataIndex: 'first_name',
      sorter: isNotUserRole
    },
    {
      title: t('users.form.trigram'),
      key: 'trigram',
      dataIndex: 'trigram',
      sorter: isNotUserRole
    },
    {
      title: t('users.form.email'),
      key: 'email',
      dataIndex: 'email',
      sorter: isNotUserRole
    },
    {
      title: t('users.form.role'),
      key: 'role',
      dataIndex: ['role'],
      render: (role) =>
        role ? (
          <Tag color={userRoles[role.split(':')[1]]}>
            {t(`users.tags.${role.split(':')[1]}`, {
              defaultValue: role.split(':')[1]
            })}
          </Tag>
        ) : (
          'N/R'
        ),
      sorter: isNotUserRole
    }
  ];

  if (isNotUserRole) {
    columns[0].filters = (enums?.positions || []).map((r) => ({
      text: t(`users.positions.${r}`, {
        defaultValue: r
      }),
      value: r
    }));
    columns[1].filters = (psMicro || []).map((ps) => ({
      text: ps?.title,
      value: ps?._id
    }));

    columns[6].filters = (enums?.roles || []).map((r) => ({
      text: t(`users.tags.${r.split(':')[1]}`, {
        defaultValue: r.split(':')[1]
      }),
      value: r
    }));
  }

  return columns;
};
