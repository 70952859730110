import { Input, Checkbox, Select, Switch, DatePicker } from 'antd';
import IBAN from 'iban';
import { useTranslation } from 'react-i18next';
import useCustomerContext from '../CustomerContext';
import { isValidBIC } from '../../../utils/RIBBICValidator';

const { Option } = Select;

/**
 * useFields is a custom hook that provides an array of field configurations for customer billing data input.
 *
 * @hook
 * @returns {Array<Object>} An array of field configurations.
 */

const useFields = (
  paymentSelected,
  setPaymentSelected,
  modificationRib,
  setModificationRib,
  purpose
) => {
  const { enums, bankReconciliations } = useCustomerContext();
  const { t } = useTranslation();

  const requiredMessage = t('errors.form.REQUIRED');

  const onsearch = (input, option) => {
    const result = option?.children.toLowerCase();
    return result.includes(input.toLowerCase());
  };

  return [
    {
      name: ['payment_type'],
      initialValue: paymentSelected,
      rules: [{ required: true, message: requiredMessage }],
      input: (
        <Select
          allowClear
          showSearch
          onChange={(value) => setPaymentSelected(value)}
          onClear={() => setPaymentSelected(null)}
          filterOption={(input, option) => onsearch(input, option)}
        >
          {(enums?.paymentTypes || []).map((payment) => (
            <Option key={payment} value={payment}>
              {t(`quittances.form.enums.${payment}`)}
            </Option>
          ))}
        </Select>
      )
    },
    ...(paymentSelected === 'DIRECTDEBIT'
      ? [
          ...(purpose === 'edit'
            ? [
                {
                  name: ['rib_modification'],
                  input: (
                    <Switch
                      checkedChildren={t('switch.yes')}
                      unCheckedChildren={t('switch.no')}
                      onChange={() => setModificationRib(!modificationRib)}
                    />
                  )
                }
              ]
            : []),
          {
            name: ['rib'],
            hidden: true
          },
          {
            name: ['rib_holder'],
            disabled: !modificationRib && purpose === 'edit',
            rules: [{ required: true, message: requiredMessage }]
          },
          {
            name: ['rum'],
            disabled: !modificationRib && purpose === 'edit',
            rules: [{ required: true, message: requiredMessage }]
          },
          {
            name: ['iban'],
            disabled: !modificationRib && purpose === 'edit',
            hasFeedback: IBAN,
            rules: [
              { required: true, message: t('errors.form.REQUIRED') },
              () => ({
                validator(_, value) {
                  if (IBAN.isValid(value)) return Promise.resolve();
                  return Promise.reject(new Error('IBAN invalide'));
                }
              })
            ]
          },
          {
            name: ['bic'],
            disabled: !modificationRib && purpose === 'edit',
            rules: [
              { required: true, message: requiredMessage },
              () => ({
                validator(_, value) {
                  if (isValidBIC(value) || !value) return Promise.resolve();
                  return Promise.reject(new Error('BIC invalide'));
                }
              })
            ]
          },
          {
            name: ['ics'],
            disabled: !modificationRib && purpose === 'edit',
            rules: [{ required: true }]
          },
          {
            name: ['signature_date'],
            rules: [{ required: true, message: requiredMessage }],
            input: (
              <DatePicker
                format="DD-MM-YYYY"
                disabled={!modificationRib && purpose === 'edit'}
              />
            )
          },
          {
            name: ['transfer_date'],
            initialValue: 10,
            rules: [{ required: true, message: requiredMessage }],
            input: <Input type="number" min="1" max="28" />
          }
        ]
      : []),
    {
      name: ['bank_reconciliation'],
      initialValue: bankReconciliations?.[0]?._id,
      rules: [{ required: true, message: requiredMessage }],
      input: (
        <Select
          allowClear
          showSearch
          filterOption={(input, option) => onsearch(input, option)}
        >
          {bankReconciliations?.map((bank) => (
            <Option key={bank._id} value={bank._id}>
              {bank.name}
            </Option>
          ))}
        </Select>
      )
    },
    {
      name: ['vat'],
      rules: [{ required: true, message: requiredMessage }],
      input: (
        <Select
          allowClear
          showSearch
          filterOption={(input, option) => onsearch(input, option)}
        >
          {enums?.vats?.map((vat) => (
            <Option key={vat} value={vat}>
              {`${vat}%`}
            </Option>
          ))}
        </Select>
      )
    },
    {
      name: ['social_reason'],
      input: <Input />
    },
    {
      name: ['electronic_invoice'],
      valuePropName: 'checked',
      initialValue: true,
      input: <Checkbox />
    },
    {
      name: ['email_invoice'],
      rules: [{ required: true, message: requiredMessage }],
      input: <Input type="email" />
    },
    {
      name: ['billing_address', 'street']
    },
    {
      name: ['billing_address', 'postal_code']
    },
    {
      name: ['billing_address', 'city']
    }
  ];
};

export default useFields;
