import PropTypes from 'prop-types';
import { Card, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import { DescriptionList } from '../../../../components';
import { useListContent } from './listContent';

/**
 * SoftSkillsCard component that shows the soft skills information of the quality control
 *
 * @component
 * @param {object} softSkills - Soft skills object
 * @return {component} - SoftSkillsCard component
 */
export const SoftSkillsCard = ({ softSkills }) => {
  const { t } = useTranslation();
  const listContent = useListContent(softSkills);
  return (
    <Card title={t('quality.form.soft_skills.title')}>
      <Row gutter={[16, 16]}>
        {['timestamp', 'technical', 'planning', 'N1'].map((type) => [
          <Card
            title={t(`quality.form.soft_skills.${type}_assessment`)}
            key={type}
          >
            <DescriptionList
              data={listContent.filter((f) => f.type === type)}
              translate
              columns={24}
            />
          </Card>
        ])}
      </Row>
    </Card>
  );
};

SoftSkillsCard.propTypes = {
  softSkills: PropTypes.shape({})
};

SoftSkillsCard.defaultProps = {
  softSkills: {}
};
