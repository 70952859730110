import { createContext, useContext, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { useAuthContext } from '../../contexts/AuthContext';
import { useErrorMessage } from '../../utils/errorMessage';
import { showSuccessMessage } from '../../utils/showSuccessMessage';

const ArticleContext = createContext({});

export const ArticleContextProvider = ({ children }) => {
  const { dispatchAPI } = useAuthContext();
  const { t } = useTranslation();
  const { message } = useErrorMessage();
  const [enums, setEnums] = useState({});
  const [roles, setRoles] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [refresh, setRefresh] = useState(false);

  const getEnums = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/news/enums`
      });
      setEnums(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getRoles = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/users/enums`
      });
      setRoles(data?.roles);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getDataFromId = async (id) => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/news/${id}?populate=micro_ps,macro_ps,historic.author,file`
      });
      return data;
    } catch (e) {
      message(e);
      return null;
    }
  };

  const changeStatus = async (id, status) => {
    try {
      await dispatchAPI('PATCH', {
        url: `/news/${id}`,
        body: { status }
      });
      setRefresh(!refresh);
      showSuccessMessage(
        t,
        status === 'ARCHIVED' ? 'news' : 'status',
        status === 'ARCHIVED' ? 'archive' : 'changed'
      );
      setRefresh(!refresh);
    } catch (e) {
      message(e);
    }
  };

  const getPsMacro = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/psmacro?sort=title`
      });
      setEnums((prev) => ({
        ...prev,
        macro_ps: data
      }));
    } catch (e) {
      message(e);
    }
  };

  const getPsMicro = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/psmicro?sort=title`
      });
      setEnums((prev) => ({
        ...prev,
        micro_ps: data
      }));
    } catch (e) {
      message(e);
    }
  };

  const addSeen = async (articleId) => {
    try {
      await dispatchAPI('GET', {
        url: `/news/${articleId}/seen`
      });
    } catch (e) {
      message(e);
    }
  };

  const unsee = async (articleId) => {
    try {
      await dispatchAPI('GET', {
        url: `/news/${articleId}/unsee`
      });
      setRefresh(!refresh);
      showSuccessMessage(t, 'status', 'changed');
    } catch (e) {
      message(e);
    }
  };

  const likeDislike = async (articleId, isLike) => {
    try {
      await dispatchAPI('GET', {
        url: `/news/${articleId}/${isLike ? 'like' : 'dislike'}`
      });
      setRefresh(!refresh);
    } catch (e) {
      message(e);
    }
  };

  const getUsersByRole = async (role) => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/users?role=${role}`
      });
      return data;
    } catch (e) {
      message(e);
      return [];
    }
  };

  const duplicateResource = async (id) => {
    try {
      await dispatchAPI('POST', { url: `/news/duplicate/${id}` });
      showSuccessMessage(t, 'news', 'duplicate');
      setRefresh(!refresh);
    } catch (e) {
      message(e);
    }
  };

  const deleteResource = async (id) => {
    try {
      await dispatchAPI('DELETE', { url: `/news/${id}` });
      setRefresh(!refresh);
      showSuccessMessage(t, 'news', 'archive');
    } catch (e) {
      message(e);
    }
  };

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      await getEnums();
      await getRoles();
      await getPsMacro();
      await getPsMicro();
      setIsLoading(false);
    })();
  }, [refresh]);

  return (
    <ArticleContext.Provider
      value={{
        enums,
        roles,
        isLoading,
        refresh,
        setRefresh,
        getDataFromId,
        changeStatus,
        addSeen,
        unsee,
        likeDislike,
        getUsersByRole,
        duplicateResource,
        deleteResource
      }}
    >
      {children}
    </ArticleContext.Provider>
  );
};

ArticleContextProvider.propTypes = {
  children: PropTypes.element.isRequired
};

export default () => useContext(ArticleContext);
