import { Route, Routes } from 'react-router-dom';
import { ListInterventions } from './ListInterventions';
import { ShowIntervention } from './ShowIntervention';
import { InterventionContextProvider } from './InterventionContext';
import { Exception } from '../../components';

export const InterventionRouter = () => (
  <InterventionContextProvider>
    <Routes>
      <Route path="/show/:id" element={<ShowIntervention />} />
      <Route index element={<ListInterventions />} />
      <Route path="*" element={<Exception />} />
    </Routes>
  </InterventionContextProvider>
);
