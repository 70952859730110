import { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { DatePicker, Select, Input, Form, Card, Button } from 'antd';
import { CloseCircleOutlined } from '@ant-design/icons';
import QuittanceContextProvider from './QuittanceContext';
import { phoneNumberField } from '../../utils/phoneNumberField';

const { Option } = Select;

const useFields = () => {
  const { t } = useTranslation();
  const {
    customers,
    quittanceEnums,
    bankReconciliations,
    serviceEnums,
    formValues
  } = QuittanceContextProvider();
  const [isFieldsLoading, setIsFieldsLoading] = useState(true);
  const format = 'DD-MM-YYYY';

  const onsearch = (input, option) => {
    const result = option?.children.toLowerCase();
    return result.includes(input.toLowerCase());
  };

  useEffect(() => {
    if (quittanceEnums) setIsFieldsLoading(false);
  }, []);

  const fields = [
    {
      name: ['ps_number'],
      rules: [{ required: true }],
      startWithDivider: {
        orientation: 'left',
        plain: true,
        title: 'Clients à facturer'
      },
      input: (
        <Select
          loading={isFieldsLoading}
          showSearch
          filterOption={(input, option) => onsearch(input, option)}
        >
          {(customers || []).map((customer) => (
            <Option key={customer._id} value={customer.ps_number}>
              {`${customer.ps_number} ${customer.company_name}`}
            </Option>
          ))}
        </Select>
      )
    },
    ...(formValues?.ps_number
      ? [
          {
            name: ['billing_address', 'street'],
            input: <Input disabled />
          },
          {
            name: ['billing_address', 'postal_code'],
            input: <Input disabled />
          },
          {
            name: ['billing_address', 'city'],
            input: <Input disabled />
          },
          {
            name: ['email'],
            key: 'email',
            input: <Input type="email" disabled />
          },
          phoneNumberField,
          {
            name: ['title'],
            label: 'title_field',
            rules: [{ required: true }],
            startWithDivider: {
              orientation: 'left',
              plain: true,
              title: 'Corps de la facture'
            },
            input: (
              <Select
                loading={isFieldsLoading}
                allowClear
                showSearch
                filterOption={(input, option) => onsearch(input, option)}
                disabled
              >
                {(quittanceEnums?.titles || []).map((title) => (
                  <Option key={title} value={title}>
                    {t(`quittances.form.enums.${title}`)}
                  </Option>
                ))}
              </Select>
            )
          },
          {
            name: ['company'],
            input: (
              <Select
                disabled
                allowClear
                showSearch
                filterOption={(input, option) => onsearch(input, option)}
              >
                {(quittanceEnums?.companies || []).map((company) => (
                  <Option key={company} value={company}>
                    {t(`employments.tags.${company}`)}
                  </Option>
                ))}
              </Select>
            )
          },
          {
            name: ['bank_reconciliation'],
            rules: [{ required: true }],
            input: (
              <Select
                disabled
                allowClear
                showSearch
                filterOption={(input, option) => onsearch(input, option)}
              >
                {(bankReconciliations || []).map((bank) => (
                  <Option key={bank._id} value={bank._id}>
                    {bank.name}
                  </Option>
                ))}
              </Select>
            )
          },
          {
            name: ['period_date'],
            rules: [{ required: true }],
            input: (
              <DatePicker
                disabledDate={(current) => {
                  const startOfPreviousMonth = dayjs()
                    .subtract(1, 'month')
                    .startOf('month');
                  return current && current < startOfPreviousMonth;
                }}
                format={format}
              />
            )
          },
          {
            name: ['transfer_date'],
            rules: [{ required: true }],
            input: <DatePicker disabled format={format} />
          },
          {
            name: ['payment_choice'],
            rules: [{ required: true }],
            input: (
              <Select
                loading={isFieldsLoading}
                allowClear
                showSearch
                filterOption={(input, option) => onsearch(input, option)}
              >
                {(quittanceEnums?.paymentChoices || []).map((payment) => (
                  <Option key={payment} value={payment}>
                    {t(`payments.tags.${payment}`)}
                  </Option>
                ))}
              </Select>
            )
          },
          ...(formValues?.transfer_date
            ? [
                {
                  name: ['details'],
                  startWithDivider: {
                    orientation: 'left',
                    plain: true,
                    title: 'Détail'
                  },
                  input: (
                    <Form.List name="details" initialValue={[{}]}>
                      {(fieldsDetails, { add, remove }) => (
                        <>
                          {fieldsDetails.map((field, index) => (
                            <Card
                              key={field.name}
                              style={{ paddingRight: '10px' }}
                            >
                              <Form.Item
                                {...field}
                                key={[field.name, 'type']}
                                name={[field.name, 'type']}
                                label={`${t('quittances.form.detail.type')} ${
                                  index + 1
                                }`}
                                rules={[
                                  {
                                    required: true
                                  }
                                ]}
                              >
                                <Select
                                  allowClear
                                  showSearch
                                  loading={isFieldsLoading}
                                  filterOption={(input, option) =>
                                    onsearch(input, option)
                                  }
                                >
                                  {serviceEnums?.types?.map((type) => (
                                    <Option key={type} value={type}>
                                      {t(`quotes.tags.${type}`)}
                                    </Option>
                                  ))}
                                </Select>
                              </Form.Item>
                              <CloseCircleOutlined
                                style={{
                                  position: 'absolute',
                                  top: '5px',
                                  right: '5px',
                                  fontSize: '16px',
                                  color: 'red',
                                  cursor: 'pointer'
                                }}
                                onClick={() => remove(field.name)}
                              />
                              <Form.Item
                                {...field}
                                key={[field.name, 'title']}
                                name={[field.name, 'title']}
                                label={t('quittances.form.detail.title')}
                              >
                                <Input />
                              </Form.Item>
                              <Form.Item
                                {...field}
                                key={[field.name, 'amount']}
                                name={[field.name, 'amount']}
                                label={t('quittances.form.detail.amount')}
                                rules={[
                                  {
                                    required: true
                                  }
                                ]}
                              >
                                <Input type="number" suffix="€" />
                              </Form.Item>
                              <Form.Item
                                {...field}
                                key={[field.name, 'total_hours_service']}
                                name={[field.name, 'total_hours_service']}
                                label={t(
                                  'quittances.form.detail.total_hours_service'
                                )}
                                rules={[
                                  {
                                    required: true
                                  }
                                ]}
                              >
                                <Input type="number" />
                              </Form.Item>
                              <Form.Item
                                {...field}
                                key={[field.name, 'fee_discount']}
                                name={[field.name, 'fee_discount']}
                                label={t('quittances.form.detail.fee_discount')}
                              >
                                <Input type="number" suffix="€" />
                              </Form.Item>
                              <Form.Item
                                {...field}
                                key={[field.name, 'total']}
                                name={[field.name, 'total']}
                                label={t('quittances.form.detail.total')}
                                rules={[
                                  {
                                    required: true
                                  }
                                ]}
                              >
                                <Input type="number" suffix="€" disabled />
                              </Form.Item>
                              <Form.Item
                                {...field}
                                key={[field.name, 'total_with_taxes']}
                                name={[field.name, 'total_with_taxes']}
                                label={t(
                                  'quittances.form.detail.total_with_taxes'
                                )}
                                rules={[
                                  {
                                    required: true
                                  }
                                ]}
                              >
                                <Input type="number" suffix="€" disabled />
                              </Form.Item>
                              <Form.Item
                                {...field}
                                key={[field.name, 'accounting_account']}
                                name={[field.name, 'accounting_account']}
                                label={t(
                                  'quittances.form.detail.accounting_account'
                                )}
                                rules={[
                                  {
                                    required: true
                                  }
                                ]}
                              >
                                <Input disabled />
                              </Form.Item>
                            </Card>
                          ))}
                          <Form.Item>
                            <Button
                              style={{ marginTop: '10px' }}
                              type="primary"
                              onClick={() => add()}
                            >
                              {t('quittances.form.detail.add_button')}
                            </Button>
                          </Form.Item>
                        </>
                      )}
                    </Form.List>
                  )
                },
                {
                  name: ['total_discount'],
                  rules: [{ required: true }],
                  startWithDivider: {
                    orientation: 'left',
                    plain: true,
                    title: 'Total'
                  },
                  input: <Input type="number" suffix="€" disabled />
                },
                {
                  name: ['total'],
                  rules: [{ required: true }],
                  input: <Input type="number" suffix="€" disabled />
                },
                {
                  name: ['taxes'],
                  rules: [{ required: true }],
                  input: <Input type="number" suffix="%" disabled />
                },
                {
                  name: ['total_with_taxes'],
                  rules: [{ required: true }],
                  input: <Input type="number" suffix="€" disabled />
                },
                {
                  name: ['comment'],
                  input: <Input.TextArea />
                },
                {
                  name: ['customer'],
                  hidden: true
                },
                {
                  name: ['macro_ps'],
                  hidden: true
                },
                {
                  name: ['micro_ps'],
                  hidden: true
                }
              ]
            : [])
        ]
      : [])
  ];

  return {
    fields,
    isFieldsLoading
  };
};

export default useFields;
