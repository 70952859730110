import { Button, Col, Input } from 'antd';
import {
  DownloadOutlined,
  EyeOutlined,
  CheckCircleOutlined,
  CloseCircleOutlined,
  PlusOutlined,
  CloseOutlined,
  CheckOutlined
} from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import InterventionContextProvider from '../InterventionContext';
import { formatValue } from '../../../utils/formatNumber';

/**
 * Custom hook for generating informations content data for displaying intervention details in a list format.
 *
 * @hook
 * @param {object} data - Intervention data object
 * @param {string} data - Filter type for content
 * @returns {Array} Array of objects containing label, span, and content for each field
 */

export const useDrawerInfoContent = (
  data,
  type,
  downloadDocument,
  viewDocument,
  firstOutstanding
) => {
  const { t } = useTranslation();

  const {
    customerComments,
    setAddComment,
    addComment,
    patchComment,
    setNewComment
  } = InterventionContextProvider();
  const { customer, user_referent } = data;
  const iconSize = 18;

  return [
    ...(type === 'administration'
      ? [
          {
            label: 'interventions.form.drawer.address',
            span: 24,
            content: customer?.address
              ? `${customer?.address?.number || '-'} ${
                  customer?.address?.street || '-'
                } ${customer?.address?.postal_code || '-'} ${
                  customer?.address?.city || '-'
                }`
              : 'N/A'
          },
          {
            label: 'interventions.form.drawer.user_referent',
            span: 24,
            content: user_referent
              ? `${user_referent?.first_name || '-'} ${
                  user_referent?.usage_last_name || user_referent?.last_name
                }`
              : '-'
          },
          {
            label: 'interventions.form.drawer.email',
            span: 24,
            content: customer?.email ? customer?.email : '-'
          },
          {
            label: 'interventions.form.drawer.start_outstanding',
            span: 24,
            content: firstOutstanding?.date
              ? formatValue(firstOutstanding?.date, 'date')
              : 'N/A'
          },
          {
            label: 'interventions.form.drawer.em_objective',
            span: 24,
            content: customer?.outstanding_balance_target || 'N/A'
          },
          {
            label: 'interventions.form.drawer.info_sheet',
            span: 24,
            content:
              data?.customer?.info_sheet?.length > 0 ? (
                data?.customer?.info_sheet?.map((document) => (
                  <Col className="custom-list-action">
                    <CheckCircleOutlined />
                    {document?.metadata?.originalName}
                    <Button
                      onClick={() => viewDocument(document, 'show')}
                      type="link"
                    >
                      <EyeOutlined style={{ fontSize: iconSize }} />
                    </Button>
                    <Button
                      onClick={() => downloadDocument(document)}
                      type="link"
                    >
                      <DownloadOutlined style={{ fontSize: iconSize }} />
                    </Button>
                  </Col>
                ))
              ) : (
                <CloseCircleOutlined />
              )
          }
        ]
      : []),
    ...(type === 'security'
      ? [
          {
            label: 'interventions.form.drawer.start_activity_date',
            span: 24,
            content: customer?.start_activity_date
              ? formatValue(customer?.start_activity_date, 'date')
              : 'N/A'
          },
          {
            label: 'interventions.form.drawer.iban',
            span: 24,
            content: customer?.iban ? customer?.iban : 'N/A'
          },
          {
            label: 'interventions.form.drawer.pro_card_number',
            span: 24,
            content: customer?.pro_card_number
              ? customer?.pro_card_number
              : 'N/A'
          },
          {
            label: 'interventions.form.drawer.ps_security_code',
            span: 24,
            content: customer?.ps_security_code
              ? customer?.ps_security_code
              : 'N/A'
          }
        ]
      : []),
    ...(type === 'technical'
      ? [
          {
            label: 'interventions.form.drawer.connection_period',
            span: 24,
            content: customer?.connection_period
              ? customer?.connection_period
              : 'N/A'
          },
          {
            label: 'interventions.form.drawer.connection_type',
            span: 12,
            content: customer?.connection_type
              ? customer?.connection_type
              : 'N/A'
          },
          {
            label: 'interventions.form.drawer.session_password',
            span: 12,
            content: customer?.session_password
              ? customer?.session_password
              : 'N/A'
          },
          {
            label: 'interventions.form.drawer.connection_comment',
            span: 24,
            content: customer?.connection_comment
              ? customer?.connection_comment
              : 'N/A'
          },
          {
            label: 'interventions.form.drawer.used_softwares',
            span: 24,
            content:
              customer?.used_software?.map((soft) => soft?.title).join(', ') ||
              '-'
          },
          {
            label: 'interventions.form.drawer.central_hub',
            span: 12,
            content: customer?.central_hub ? customer?.central_hub : 'N/A'
          },
          {
            label: 'interventions.form.drawer.pro_card_type',
            span: 12,
            content: customer?.pro_card_type ? customer?.pro_card_type : 'N/A'
          },
          {
            label: 'interventions.form.drawer.pro_card_code',
            span: 12,
            content: customer?.pro_card_code ? customer?.pro_card_code : 'N/A'
          },
          {
            label: 'interventions.form.drawer.scor',
            span: 12,
            content:
              customer?.scor && typeof customer.scor !== 'object'
                ? customer?.scor
                : 'N/A'
          },
          {
            label: 'interventions.form.drawer.vital_sesam',
            span: 24,
            content: customer?.vital_sesam ? customer?.vital_sesam : 'N/A'
          },
          {
            label: 'interventions.form.drawer.min_amount_customer',
            span: 12,
            content: customer?.min_amount_customer
              ? `${customer?.min_amount_customer}€`
              : 'N/A'
          },
          {
            label: 'interventions.form.drawer.max_amount_customer',
            span: 12,
            content: customer?.max_amount_customer
              ? `${customer?.max_amount_customer}$`
              : 'N/A'
          },
          {
            label: 'interventions.form.drawer.insured_contact_type',
            span: 12,
            content: customer?.insured_contact_type?.length
              ? customer?.insured_contact_type?.map((ct) => ct).join(', ')
              : '-'
          },
          {
            label: 'interventions.form.drawer.courrier',
            span: 12,
            content: t(`customers.courrier.${customer?.courrier}`) || '-'
          },
          {
            label: 'interventions.form.drawer.bank_reconciliation',
            span: 12,
            content: customer?.bank_reconciliation?.title
              ? customer?.bank_reconciliation?.title
              : 'N/A'
          }
        ]
      : []),
    ...(type === 'comment'
      ? [
          {
            label: 'interventions.form.drawer.customer_comments',
            span: 24,
            content: customerComments?.length
              ? (customerComments || []).map((comment) => (
                  <p>{comment?.title}</p>
                ))
              : 'N/A'
          },
          {
            label: 'interventions.form.drawer.add_comment',
            span: 24,
            content: addComment ? (
              <Input.TextArea
                onChange={(event) => setNewComment(event.target.value)}
              />
            ) : (
              <PlusOutlined onClick={() => setAddComment(true)} />
            )
          },
          ...(addComment
            ? [
                {
                  noLabel: true,
                  span: 24,
                  content: (
                    <>
                      <CloseOutlined
                        onClick={() => {
                          setAddComment(false);
                          setNewComment(null);
                        }}
                      />
                      <CheckOutlined
                        onClick={() => patchComment(customer._id)}
                      />
                    </>
                  )
                }
              ]
            : [])
        ]
      : [])
  ];
};
