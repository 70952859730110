import { useCallback, useEffect, useState } from 'react';
import { DatePicker, Select, Tag, Input, Form, Space } from 'antd';
import { useTranslation } from 'react-i18next';
import { useAuthContext } from '../../contexts/AuthContext';
import { useErrorMessage } from '../../utils/errorMessage';
import { userRoles } from '../../utils/constants/tagColors';

const { Option } = Select;

const useFields = () => {
  const { message } = useErrorMessage();
  const { t } = useTranslation();
  const { dispatchAPI } = useAuthContext();
  const [isLoading, setIsLoading] = useState(false);
  const [enums, setEnums] = useState({});

  const handleKeyPress = (e) => {
    const { value } = e.target;
    if (!/[0-9]/.test(e.key) || (value.length === 0 && e.key === '0')) {
      e.preventDefault();
    }
  };

  const fields = [
    {
      name: ['last_name'],
      rules: [{ required: true }]
    },
    {
      name: ['first_name'],
      rules: [{ required: true }]
    },
    {
      name: ['role'],
      rules: [{ required: true }],
      input: (
        <Select loading={isLoading}>
          {(enums.roles || []).map((role) => (
            <Option key={role} value={role}>
              <Tag color={userRoles[role.split(':')[1]]}>
                {t(`users.tags.${role.split(':')[1]}`)}
              </Tag>
            </Option>
          ))}
        </Select>
      )
    },
    {
      name: ['email'],
      rules: [{ required: true }, { type: 'email' }],
      disabled: true
    },
    {
      name: ['phone_number'],
      label: 'phone_number.number',
      input: (
        <Space.Compact>
          <Form.Item
            noStyle
            name={['phone_number', 'country_code']}
            initialValue="+33"
          >
            <Select>
              <Option value="+33">+33</Option>
            </Select>
          </Form.Item>
          <Form.Item
            noStyle
            name={['phone_number', 'number']}
            rules={[
              {
                pattern: /^[1-9]\d{8}$/,
                message: t(`messages.phone_number`)
              }
            ]}
          >
            <Input onKeyPress={handleKeyPress} maxLength={9} />
          </Form.Item>
        </Space.Compact>
      )
    },
    {
      name: ['date_of_birth'],
      input: <DatePicker format="DD-MM-YYYY" />
    },
    {
      label: 'number',
      name: ['address', 'number']
    },
    {
      label: 'street',
      name: ['address', 'street']
    },
    {
      label: 'additional',
      name: ['address', 'additional']
    },
    {
      label: 'postal_code',
      name: ['address', 'postal_code']
    },
    {
      label: 'city',
      name: ['address', 'city']
    }
  ];

  const getEnums = async () => {
    try {
      const { data } = await dispatchAPI('GET', { url: '/users/enums' });
      setEnums(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getSelectOptions = useCallback(async () => {
    setIsLoading(true);
    await getEnums();
    setIsLoading(false);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    (async () => {
      await getSelectOptions();
    })();
  }, [getSelectOptions]);

  return {
    fields
  };
};

export default useFields;
