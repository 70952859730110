import React, { useState } from 'react';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';
import { Modal } from 'antd';
import { useAuthContext } from '../../contexts/AuthContext';
import { useErrorMessage } from '../../utils/errorMessage';
import { CreateUpdateContainer } from '../../components/CreateUpdateContainer/CreateUpdateContainer';
import useFields from './fields';

const EventModal = ({
  openModal,
  setOpenModal,
  users,
  purpose,
  closeOverlay,
  idFromOverlay,
  fileList,
  setFileList
}) => {
  const { dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();
  const [refresh, setRefresh] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const uploadOrDeleteFile = async (file, id, type, key) => {
    setIsLoading(true);
    const formData = new FormData();
    formData.append('values', JSON.stringify({ file: [file._id] }));
    if (type !== 'delete') formData.append(`${key}`, file);
    try {
      await dispatchAPI('PATCH', {
        url: `/events/file/${id}?type=${type}&key=${key}&fileId=${file._id}`,
        body: formData
      });
    } catch (e) {
      message(e);
    }
    setRefresh(!refresh);
    setIsLoading(false);
  };

  const { fields, isFieldsLoading } = useFields(
    users,
    purpose,
    setFileList,
    fileList,
    idFromOverlay,
    uploadOrDeleteFile,
    isLoading
  );

  const config = {
    onGetResource: {
      setFields: (data) => {
        setFileList(data.file || []);
        return {
          ...data,
          date: data.date && dayjs(data.date),
          startTime: data.startTime && dayjs(data.startTime),
          endTime: data.endTime && dayjs(data.endTime)
        };
      }
    },
    onUpdateResource: {
      setBody: (data) => ({
        ...data,
        date: data.date && dayjs(data.date)
      })
    }
  };

  return (
    <Modal
      centered
      open={openModal}
      onCancel={() => {
        setOpenModal(false);
      }}
      width={700}
      footer={false}
    >
      <CreateUpdateContainer
        fields={fields}
        loadingFields={isFieldsLoading}
        purpose={purpose}
        baseUrl="events"
        resource="events"
        populate="file"
        isOverlay
        customNavigate={closeOverlay}
        idFromOverlay={idFromOverlay}
        config={config}
        refresh={refresh}
        drawer
      />
    </Modal>
  );
};

EventModal.propTypes = {
  openModal: PropTypes.bool.isRequired,
  setOpenModal: PropTypes.func.isRequired,
  users: PropTypes.arrayOf(PropTypes.shape({})),
  purpose: PropTypes.string,
  closeOverlay: PropTypes.func.isRequired,
  idFromOverlay: PropTypes.string,
  fileList: PropTypes.shape({}),
  setFileList: PropTypes.func
};

EventModal.defaultProps = {
  users: null,
  purpose: null,
  idFromOverlay: null,
  fileList: null,
  setFileList: null
};

export default EventModal;
