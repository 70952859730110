export const headers = [
  {
    label: 'service',
    key: 'service'
  },
  {
    label: 'fee_code',
    key: 'fee_code'
  },
  {
    label: 'amount',
    key: 'amount'
  },
  {
    label: 'micro_ps',
    key: 'micro_ps.title'
  },
  {
    label: 'discount',
    key: 'discount'
  },
  {
    label: 'start_date',
    key: 'start_date'
  },
  {
    label: 'created_by',
    key: 'created_by.first_name'
  },
  {
    label: 'created_by',
    key: 'created_by.last_name'
  }
];
