import { Input } from 'antd';
import InterventionContextProvider from './InterventionContext';

const useFields = (user) => {
  const { intervention } = InterventionContextProvider();

  const fields = [
    {
      name: ['number'],
      rules: [
        {
          required: true
        }
      ],
      input: <Input type="number" prefix="€" />
    },
    {
      name: ['customer'],
      hidden: true,
      initialValue: intervention?.customer?._id,
      rules: [
        {
          required: true
        }
      ]
    },
    {
      name: ['intervention'],
      hidden: true,
      initialValue: intervention?._id,
      rules: [
        {
          required: true
        }
      ]
    },
    {
      name: ['user'],
      hidden: true,
      initialValue: user?._id,
      rules: [
        {
          required: true
        }
      ]
    }
  ];

  return {
    fields
  };
};

export default useFields;
