import { useState } from 'react';
import { Switch, DatePicker, Select, Tag, Flex } from 'antd';
import { useTranslation } from 'react-i18next';
import { ListResource } from '../../components';
import { useColumns } from './columns';
import { headers } from './headers';
import useAbsenceVacationContext from './AbsenceVacationContext';
import { useAbsenceColumns } from './AbsenceColumns';
import { userRoles } from '../../utils/constants/tagColors';
import { useAuthContext } from '../../contexts/AuthContext';
import { useFormatter } from '../../utils/formatter';

const { RangePicker } = DatePicker;
const { Option } = Select;

/**
 * A component for displaying a list of absence and vacation events with filtering and search capabilities.
 *
 * @component
 * @returns {JSX.Element} The JSX element representing the list of absence and vacation events.
 */

export const ListAbsenceVacation = () => {
  const { t } = useTranslation();
  const { formattedData } = useFormatter('events');
  const { user: userConnected } = useAuthContext();
  const columns = useColumns();
  const absenceColumns = useAbsenceColumns();
  const { isLoading, users, setRefresh, refresh, roles, enums } =
    useAbsenceVacationContext();

  const onsearchUser = (input, option) =>
    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
  const onsearchRole = (input, option) =>
    option.props['data-label'].toLowerCase().indexOf(input.toLowerCase()) >= 0;
  const onsearchstatus = (input, option) =>
    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;

  const [absence, setAbsence] = useState(true);
  const [dateRange, setDateRange] = useState([]);
  const [selectedUser, setSelectedUser] = useState();
  const [selectedRole, setSelectedRole] = useState();
  const [selectedStatus, setSelectedStatus] = useState('PENDING');

  return (
    <ListResource
      resourceName="events/absence-vacation"
      contentCustomStyle={{ margin: 0, padding: 0 }}
      columns={!absence ? columns : absenceColumns}
      headers={headers}
      formatter={formattedData}
      populate="user,reviewed.user"
      resourceModelName="Event"
      withCreateButton={false}
      editAction={false}
      deleteAction
      withImportButton={false}
      withUploadButton={userConnected.role.split(':')[1] !== 'USER'}
      showAction
      flexWrap
      scroll={{ x: 1700 }}
      extraSorter="-created_at"
      extraQuery={`type=${absence ? 'absences' : 'vacations'}${
        dateRange?.length
          ? `&start_date=${new Date(dateRange[0]) || ''}&end_date=${
              new Date(dateRange[1]) || ''
            }`
          : ''
      }${selectedUser ? `&user=${selectedUser}` : ''}${
        selectedRole ? `&role=${selectedRole}` : ''
      }${selectedStatus ? `&status=${selectedStatus}` : '&status!=ARCHIVED'}`}
      extraButtons={
        <Switch
          size="large"
          checkedChildren={t('events.form.enums.ABSENCES')}
          unCheckedChildren={t('events.form.enums.VACATIONS')}
          checked={absence}
          onChange={setAbsence}
        />
      }
      extraFilters={
        <Flex gap={8} wrap>
          <RangePicker format="DD-MM-YYYY" onChange={setDateRange} />
          {userConnected?.role?.startsWith('admins') && (
            <>
              <Select
                style={{ width: 200 }}
                placeholder={t('calendar.placeholder.user')}
                showSearch
                allowClear
                loading={isLoading}
                filterOption={(input, option) => onsearchUser(input, option)}
                onSelect={(value) => {
                  setSelectedUser(value);
                  setRefresh(!refresh);
                }}
                onClear={() => {
                  setSelectedUser();
                  setRefresh(!refresh);
                }}
              >
                {users.map((user) => (
                  <Option key={user._id} value={user._id}>
                    {`${user.first_name} ${
                      user?.usage_last_name || user?.last_name
                    }`}
                  </Option>
                ))}
              </Select>
              <Select
                style={{ width: 200 }}
                placeholder={t('calendar.placeholder.function')}
                showSearch
                allowClear
                loading={isLoading}
                filterOption={(input, option) => onsearchRole(input, option)}
                onSelect={(value) => {
                  setSelectedRole(value);
                  setRefresh(!refresh);
                }}
                onClear={() => {
                  setSelectedRole();
                  setRefresh(!refresh);
                }}
              >
                {roles?.map((role) => (
                  <Option
                    key={role}
                    value={role}
                    data-label={t(`users.tags.${role.split(':')[1]}`)}
                  >
                    <Tag color={userRoles[role.split(':')[1]]}>
                      {t(`users.tags.${role.split(':')[1]}`)}
                    </Tag>
                  </Option>
                ))}
              </Select>
            </>
          )}
          <Select
            style={{ width: 200 }}
            placeholder={t('calendar.placeholder.status')}
            showSearch
            allowClear
            loading={isLoading}
            filterOption={(input, option) => onsearchstatus(input, option)}
            onSelect={(value) => {
              setSelectedStatus(value);
              setRefresh(!refresh);
            }}
            onClear={() => {
              setSelectedStatus();
              setRefresh(!refresh);
            }}
            defaultValue="PENDING"
          >
            {enums?.status?.map((status) => (
              <Option key={status} value={status}>
                {t(`events.form.enums.${status}`)}
              </Option>
            ))}
          </Select>
        </Flex>
      }
    />
  );
};
