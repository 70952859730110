import { InfoCircleOutlined } from '@ant-design/icons';
import { Popover, Row, Col } from 'antd';

/**
 * Component for displaying the levels popover.
 *
 * @component
 * @returns {JSX.Element} The levels popover component
 */
export const LevelsPopover = () => {
  const levels = ['DEB', 'INT1', 'INT2', 'EXP1', 'EXP2', 'EXP3', 'LEADER'];
  const promotionThresholds = [70, 75, 80, 85, 90, 95];

  const content = (
    <Col>
      {promotionThresholds.map((threshold, index) => (
        <Row key={threshold} justify="space-between" gutter={16}>
          <Col>{`${levels[index]} -> ${levels[index + 1]}`}</Col>
          <Col>{`${threshold} %`}</Col>
        </Row>
      ))}
    </Col>
  );
  return (
    <Popover placement="leftTop" content={content}>
      <InfoCircleOutlined />
    </Popover>
  );
};
