import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { Card, Col, Empty, Flex, Row, Select } from 'antd';
import { useTranslation } from 'react-i18next';
import { LineChart, Line, XAxis, YAxis, Tooltip, Legend } from 'recharts';
import { useAuthContext } from '../../../contexts/AuthContext';
import { useErrorMessage } from '../../../utils/errorMessage';

/**
 * Card to display the revenue of the company of year N and year N-1
 *
 * @component
 * @param {Array} psMicro - The list of micro PS
 * @param {Array} psMacro - The list of macro PS
 * @param {number} year - The year to display the revenue
 * @returns {JSX.Element} - The card to display the revenue
 */
export const RevenueCard = ({ psMicro, psMacro, year }) => {
  const { t } = useTranslation();
  const { message } = useErrorMessage();

  const { dispatchAPI } = useAuthContext();
  const [revenue, setRevenue] = useState([]);
  const [selectedTypePs, setSelectedTypePs] = useState('ALL');
  const [selectedPs, setSelectedPs] = useState();

  const typesPs = ['ALL', 'macro_ps', 'micro_ps'];

  const getRevenue = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/quittances/revenue?year=${year}${
          selectedTypePs !== 'ALL' && selectedPs
            ? `&type=${selectedTypePs}&ps=${selectedPs}`
            : ''
        }`
      });
      setRevenue(data);
    } catch (error) {
      message(error);
    }
  };

  const handlePsTypeChange = (value) => {
    setSelectedTypePs(value);
    setSelectedPs();
  };

  useEffect(() => {
    (async () => {
      await getRevenue();
    })();
  }, [year, selectedPs]);

  return (
    <Card title={t('home.cards.titles.revenue')}>
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <Flex align="center" gap={8}>
            <span>{t('home.tabs.ps_types.title')}</span>
            <Select
              value={selectedTypePs}
              onChange={handlePsTypeChange}
              style={{ width: 200 }}
            >
              {typesPs.map((type) => (
                <Select.Option key={type}>
                  {t(`home.tabs.ps_types.${type}`)}
                </Select.Option>
              ))}
            </Select>
            <Select
              value={selectedPs}
              onChange={setSelectedPs}
              style={{ width: 200 }}
              disabled={selectedTypePs === 'ALL'}
            >
              {(selectedTypePs === 'macro_ps' ? psMacro : psMicro).map((ps) => (
                <Select.Option key={ps._id}>{ps.title}</Select.Option>
              ))}
            </Select>
          </Flex>
        </Col>
        {revenue.length && revenue.find((r) => r[year] || r[year - 1]) ? (
          <LineChart width={700} height={300} data={revenue}>
            <XAxis
              dataKey="month"
              tickFormatter={(month) => t(`home.tabs.months_short.${month}`)}
            />
            <YAxis />
            <Tooltip
              labelFormatter={(month) => t(`home.tabs.months.${month}`)}
              formatter={(value) => `${value.toFixed(2)}€`}
            />
            <Legend />
            <Line
              type="linear"
              dataKey={year}
              stroke="var(--primaryColor)"
              activeDot={{ r: 8 }}
            />
            <Line
              type="linear"
              dataKey={year - 1}
              stroke="var(--secondaryColor)"
              activeDot={{ r: 8 }}
            />
          </LineChart>
        ) : (
          <Empty style={{ margin: '24px auto' }} />
        )}
      </Row>
    </Card>
  );
};

RevenueCard.propTypes = {
  psMicro: PropTypes.arrayOf(PropTypes.shape({})),
  psMacro: PropTypes.arrayOf(PropTypes.shape({})),
  year: PropTypes.string
};

RevenueCard.defaultProps = {
  psMicro: [],
  psMacro: [],
  year: new Date().getFullYear()
};
