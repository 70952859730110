export const websitesHeaders = [
  {
    key: 'name',
    label: 'name'
  },
  {
    key: 'login',
    label: 'login'
  },
  {
    key: 'password',
    label: 'password'
  },
  {
    key: 'url',
    label: 'url'
  }
];
