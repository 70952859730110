import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Modal, Typography } from 'antd';
import { WebsitesTable } from '../customers/ShowCustomer/WebsitesTable';

const WebSitesModal = ({
  isWebsiteModalOpen,
  closeModal,
  id,
  refresh,
  forceWebSitesRefresh
}) => {
  const { t } = useTranslation();

  return (
    <Modal
      centered
      title={
        <Typography.Title
          level={4}
          style={{
            fontWeight: 300,
            textUnderlineOffset: '8px',
            color: 'var(--userColor)',
            marginBottom: 30
          }}
          underline
          strong={false}
        >
          {t('interventions.form.modal.title')}
        </Typography.Title>
      }
      footer={false}
      open={isWebsiteModalOpen}
      onCancel={closeModal}
      width={1500}
    >
      <WebsitesTable
        id={id}
        refresh={refresh}
        forceWebSitesRefresh={forceWebSitesRefresh}
      />
    </Modal>
  );
};

WebSitesModal.propTypes = {
  isWebsiteModalOpen: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  refresh: PropTypes.func.isRequired,
  forceWebSitesRefresh: PropTypes.bool.isRequired
};

export default WebSitesModal;
