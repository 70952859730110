import { Tag } from 'antd';
import { useTranslation } from 'react-i18next';
import { quittancesStatus } from '../../../../utils/constants/tagColors';
import { formatValue } from '../../../../utils/formatNumber';

/**
 * Custom hook for generating table columns for displaying quittances data.
 *
 * @hook
 * @param {object} invoiceEnums - Enumerations data for quittance properties
 * @param {object} customerEnums - Enumerations data for customer properties
 * @param {object} psMicro - Enumerations data for psMicro properties
 * @param {object} psMacro - Enumerations data for psMacro properties
 * @returns {Array} Array of table column objects
 */

export const useColumns = (invoiceEnums, customerEnums, psMicro, psMacro) => {
  const { t } = useTranslation();

  return [
    {
      title: t('quittances.columns.status'),
      key: 'status',
      dataIndex: ['status'],
      render: (status) => (
        <Tag color={quittancesStatus[status]}>
          {t(`quittances.form.enums.${status || 'N/A'}`)}
        </Tag>
      ),
      sorter: true,
      filters: invoiceEnums?.status?.map((c) => ({
        text: t(`quittances.form.enums.${c}`),
        value: c
      }))
    },
    {
      title: t('quittances.columns.company'),
      key: 'customer',
      dataIndex: 'customer',
      render: (customer) =>
        (customer && t(`employments.tags.${customer?.company}`)) || '-',
      sorter: true,
      filters: customerEnums?.companies?.map((c) => ({
        text: t(`users.employers.${c}`),
        value: c
      }))
    },
    {
      title: t('quittances.columns.code'),
      key: 'code',
      dataIndex: 'code'
    },

    {
      title: t('quittances.columns.micro_ps'),
      dataIndex: 'micro_ps',
      key: 'micro_ps',
      sorter: true,
      render: (value) => value?.title,
      filterSearch: true,
      filters: psMicro?.map((ps) => ({
        text: ps.title,
        value: ps._id
      }))
    },
    {
      title: t('quittances.columns.macro_ps'),
      dataIndex: 'macro_ps',
      key: 'macro_ps',
      sorter: true,
      render: (value) => value?.title,
      filterSearch: true,
      filters: psMacro?.map((ps) => ({
        text: ps.title,
        value: ps._id
      }))
    },
    {
      title: t('quittances.columns.total'),
      key: 'total',
      dataIndex: 'total',
      render: (total) => `${formatValue(total, 'currency', true)}`
    },
    {
      title: t('quittances.columns.total_with_taxes'),
      key: 'total_with_taxes',
      dataIndex: 'total_with_taxes',
      render: (total_with_taxes) =>
        `${formatValue(total_with_taxes, 'currency', true)}`
    },
    {
      title: t('quittances.columns.transfer_date'),
      key: 'transfer_date',
      dataIndex: 'transfer_date',
      render: (transfer_date) => formatValue(transfer_date, 'date')
    },
    {
      title: t('quittances.columns.payment_choice'),
      key: 'payment_choice',
      dataIndex: 'payment_choice',
      render: (payment_choice) => t(`quittances.form.enums.${payment_choice}`)
    },
    {
      title: t('quittances.columns.total_with_taxes_vs_paid'),
      key: 'payments',
      render: ({ payments, total_with_taxes }) => {
        const totalPaid = (payments || []).reduce(
          (acc, p) => acc + (p?.amount || 0),
          0
        );
        const total_due =
          Number(total_with_taxes.toFixed(2)) - Number(totalPaid.toFixed(2));
        return `${formatValue(total_due, 'currency', true)}`;
      }
    },
    {
      title: t('quittances.columns.paid_amount'),
      key: 'payments',
      render: ({ payments }) => {
        const totalPaid = (payments || []).reduce(
          (acc, p) => acc + (p?.amount || 0),
          0
        );
        return `${formatValue(totalPaid, 'currency', true)}`;
      }
    },
    {
      title: t('quittances.columns.allocation'),
      key: 'allocation',
      dataIndex: 'allocation'
    }
  ];
};
