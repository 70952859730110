import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { Card, Col, Empty, Flex, Row, Select } from 'antd';
import { useTranslation } from 'react-i18next';
import { Tooltip, PieChart, Pie, Cell, Sector } from 'recharts';
import { useAuthContext } from '../../../contexts/AuthContext';
import { CustomTooltip } from './CustomToolTip';
import { useErrorMessage } from '../../../utils/errorMessage';

/**
 * card to display the revenue distribution of the company
 *
 * @Component
 * @param {Array} psMacro - The list of macro PS
 * @param {number} year - The year to display the revenue distribution
 * @returns {JSX.Element} - The card to display the revenue distribution
 */
export const RevenueDistributionCard = ({ psMacro, year }) => {
  const { t } = useTranslation();
  const { message } = useErrorMessage();
  const { dispatchAPI } = useAuthContext();
  const [revenueDistribution, setRevenueDistribution] = useState([]);
  const [selectedTypePs, setSelectedTypePs] = useState('ALL');
  const [selectedPs, setSelectedPs] = useState();

  const typesPs = ['ALL', 'macro_ps'];

  const getRevenueDistribution = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/quittances/revenueDistribution?year=${year}${
          selectedTypePs !== 'ALL' && selectedPs ? `&ps=${selectedPs}` : ''
        }`
      });
      setRevenueDistribution(data);
    } catch (error) {
      message(error);
    }
  };

  const handlePsTypeChange = (value) => {
    setSelectedTypePs(value);
    setSelectedPs();
  };

  useEffect(() => {
    (async () => {
      await getRevenueDistribution();
    })();
  }, [year, selectedPs]);

  const COLORS = [
    'var(--primaryColor)',
    'var(--secondaryColor)',
    'var(--statsColor)',
    'var(--technicalColor)',
    '#8fd96a'
  ];

  const RADIAN = Math.PI / 180;
  const renderActiveShape = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    startAngle,
    endAngle,
    fill,
    percent,
    value
  }) => {
    const sin = Math.sin(-RADIAN * midAngle);
    const cos = Math.cos(-RADIAN * midAngle);
    const sx = cx + (outerRadius + 10) * cos;
    const sy = cy + (outerRadius + 10) * sin;
    const mx = cx + (outerRadius + 30) * cos;
    const my = cy + (outerRadius + 30) * sin;
    const ex = mx + (cos >= 0 ? 1 : -1) * 22;
    const ey = my;

    return (
      <g>
        <text x={cx} y={cy} dy={0} textAnchor="middle" fill={fill}>
          {`CA ${value.toFixed(2)}€`}
        </text>
        <text x={cx} y={cy} dy={18} textAnchor="middle" fill="#999">
          {`(${(percent * 100).toFixed(2)}%)`}
        </text>
        <Sector
          cx={cx}
          cy={cy}
          innerRadius={innerRadius}
          outerRadius={outerRadius}
          startAngle={startAngle}
          endAngle={endAngle}
          fill={fill}
        />
        <Sector
          cx={cx}
          cy={cy}
          startAngle={startAngle}
          endAngle={endAngle}
          innerRadius={outerRadius + 6}
          outerRadius={outerRadius + 10}
          fill={fill}
        />
        <path
          d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`}
          stroke={fill}
          fill="none"
        />
        <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
      </g>
    );
  };

  const renderCustomizedLabel = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    percent,
    name,
    fill
  }) => {
    if (!percent) return null;
    const radius = innerRadius + (outerRadius - innerRadius) * 2;
    const x = cx + radius * Math.cos((-midAngle * Math.PI) / 180);

    const sin = Math.sin(-RADIAN * midAngle);
    const cos = Math.cos(-RADIAN * midAngle);

    const sx = cx + (outerRadius + 10) * cos;
    const sy = cy + (outerRadius + 10) * sin;
    const mx = cx + (outerRadius + 30) * cos;
    const my = cy + (outerRadius + 30) * sin;
    const ex = mx + (cos >= 0 ? 1 : -1) * 22;
    const ey = my;

    return (
      <g>
        <path
          d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`}
          stroke={fill}
          fill="none"
        />
        <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
        <text
          x={ex + (cos >= 0 ? 1 : -1) * 12}
          y={ey}
          textAnchor={x > cx ? 'start' : 'end'}
          dominantBaseline="central"
        >
          {name}
        </text>
      </g>
    );
  };

  return (
    <Card title={t('home.cards.titles.revenue_distribution')}>
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <Flex gap={8} wrap={false} align="center">
            <span style={{ whiteSpace: 'nowrap' }}>
              {t('home.tabs.ps_types.title')}
            </span>

            <Select
              value={selectedTypePs}
              onChange={handlePsTypeChange}
              style={{ width: '40%' }}
            >
              {typesPs.map((type) => (
                <Select.Option key={type}>
                  {t(`home.tabs.ps_types.${type}`)}
                </Select.Option>
              ))}
            </Select>

            <Select
              value={selectedPs}
              onChange={setSelectedPs}
              disabled={selectedTypePs === 'ALL'}
              style={{ width: '40%' }}
            >
              {psMacro.map((ps) => (
                <Select.Option key={ps._id}>{ps.title}</Select.Option>
              ))}
            </Select>
          </Flex>
        </Col>
        {revenueDistribution.length &&
        revenueDistribution.find((r) => r.revenue !== 0) ? (
          <PieChart width={560} height={300}>
            <Pie
              data={revenueDistribution}
              dataKey="revenue"
              nameKey="name"
              cx="50%"
              cy="50%"
              innerRadius={80}
              outerRadius={110}
              fill="#82ca9d"
              label={renderCustomizedLabel}
              labelLine={false}
              activeShape={renderActiveShape}
            >
              {revenueDistribution.map((entry, index) => (
                <Cell key={entry} fill={COLORS[index % COLORS.length]} />
              ))}
            </Pie>
            <Tooltip content={<CustomTooltip />} />
          </PieChart>
        ) : (
          <Empty style={{ margin: '24px auto' }} />
        )}
      </Row>
    </Card>
  );
};

RevenueDistributionCard.propTypes = {
  psMacro: PropTypes.arrayOf(PropTypes.shape({})),
  year: PropTypes.string
};

RevenueDistributionCard.defaultProps = {
  psMacro: [],
  year: new Date().getFullYear()
};
