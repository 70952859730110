import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Card, Col, Typography, Button, Flex, Badge } from 'antd';
import { MailOutlined } from '@ant-design/icons';
import InterventionContextProvider from '../InterventionContext';
import { ListResource } from '../../../components';
import { useColumns } from './actionsColumns';
import { isAuthorized } from '../../../utils/constants/authorizedMenu';

export const ListActions = ({
  interventionId,
  forceActionsRefresh,
  openActionModal,
  onEdit,
  onArchived,
  onCancelEmailSent,
  openMailModal,
  selectedRowKeys,
  setSelectedRowKeys
}) => {
  const { t } = useTranslation();
  const { actionEnums, user, rejectionReasons } = InterventionContextProvider();
  const [titleTotals, setTitleTotals] = useState({});
  const location = useLocation();
  const { pathname } = location;

  const hasAccess = (purpose) => isAuthorized(pathname, user?.role, purpose);

  const columns = useColumns(
    actionEnums,
    rejectionReasons,
    {
      onEdit,
      onArchived,
      onCancelEmailSent
    },
    hasAccess
  );

  const rowSelection = {
    selectedRowKeys,
    onChange: (newSelectedRowKeys) => {
      setSelectedRowKeys(newSelectedRowKeys);
    }
  };

  return (
    <Col span="24">
      <Card>
        <Flex align="center" gap={8} style={{ marginBottom: 20 }}>
          <Typography.Title
            level={4}
            style={{
              color: 'var(--userColor)',
              margin: 0
            }}
            strong={false}
          >
            {t('interventions.show.actions.title')}
          </Typography.Title>
          {user.role !== 'guests:GUEST' && (
            <>
              <Badge count={titleTotals?.total || 0} color="#06B050" />
              <span style={{ color: '#06B050' }}>
                {`${titleTotals?.total_price || 0.0} €`}{' '}
              </span>
            </>
          )}
        </Flex>
        <ListResource
          resourceName="actions"
          contentCustomStyle={{ margin: 0, padding: 0 }}
          columns={columns}
          resourceModelName="Action"
          populate="issuer_organization,task_number,action,rejection_reason"
          withImportButton={false}
          forceRefresh={forceActionsRefresh}
          withPageHeader={false}
          onDoubleClickAction={false}
          extraQuery={`intervention=${interventionId}`}
          onClickAdd={openActionModal}
          showAction={false}
          editAction={false}
          deleteAction={false}
          withCreateButtonText={t('buttons.create_custom.actions')}
          rowSelection={rowSelection}
          scroll={{ x: 2100 }}
          extraButtons={
            hasAccess('create') && (
              <Button
                type="primary"
                disabled={selectedRowKeys.length === 0}
                onClick={openMailModal}
              >
                <MailOutlined />
                {`${t('buttons.create_mail')} `}
              </Button>
            )
          }
          setTitleTotals={setTitleTotals}
        />
      </Card>
    </Col>
  );
};

ListActions.propTypes = {
  forceActionsRefresh: PropTypes.bool.isRequired,
  interventionId: PropTypes.string.isRequired,
  openActionModal: PropTypes.func.isRequired,
  onEdit: PropTypes.func,
  onArchived: PropTypes.func,
  openMailModal: PropTypes.func,
  onCancelEmailSent: PropTypes.func,
  selectedRowKeys: PropTypes.arrayOf(PropTypes.string),
  setSelectedRowKeys: PropTypes.func
};

ListActions.defaultProps = {
  onEdit: null,
  onArchived: null,
  openMailModal: null,
  selectedRowKeys: [],
  setSelectedRowKeys: null,
  onCancelEmailSent: null
};
