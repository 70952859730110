import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { Badge } from 'antd';
import { useAuthContext } from '../../contexts/AuthContext';
import { useErrorMessage } from '../../utils/errorMessage';
import { useSocketContext } from '../../contexts/SocketContext';
import { getRemainingTasks } from './utils';

/**
 * A component for displaying the number of remaining tasks to be completed for a given task type.
 *
 * @component
 * @param {Object} props - The props of the component.
 * @param {string} props.task - The type of task to count the remaining tasks for.
 * @returns {JSX.Element} The JSX element representing the remaining tasks count.
 */
export const RemainingTasksCount = ({ task }) => {
  const { dispatchAPI } = useAuthContext();
  const { socket } = useSocketContext();

  const { message } = useErrorMessage();
  const [remainingTasksCount, setRemainingTasksCount] = useState(0);

  const getRemainingTasksCount = async () => {
    try {
      const data = await getRemainingTasks(dispatchAPI, task);
      if (data instanceof Error) throw data;
      setRemainingTasksCount(data.length);
    } catch (e) {
      message(e);
    }
  };

  useEffect(() => {
    getRemainingTasksCount();
  }, [task]);

  useEffect(() => {
    if (socket) {
      socket.on('absence-vacation', getRemainingTasks);
    }
  }, [socket]);

  if (!remainingTasksCount) return null;
  return <Badge count={remainingTasksCount} />;
};

RemainingTasksCount.propTypes = {
  task: PropTypes.string
};

RemainingTasksCount.defaultProps = {
  task: ''
};
