import { useCallback, useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Divider, Popconfirm, Table } from 'antd';
import {
  CopyOutlined,
  ContainerOutlined,
  EditOutlined,
  EyeOutlined,
  WarningOutlined,
  PrinterOutlined
} from '@ant-design/icons';
import { useAuthContext } from '../../contexts/AuthContext';
import { useErrorMessage } from '../../utils/errorMessage';
import { isAuthorized } from '../../utils/constants/authorizedMenu';
import { showSuccessMessage } from '../../utils/showSuccessMessage';

const iconSize = 18;

export const Datatable = ({
  resourceName,
  path,
  columns,
  customActionColumn,
  populate,
  style,
  extraQuery,
  forceRefresh,
  showAction,
  editAction,
  extraEditCondition,
  deleteAction,
  duplicateAction,
  printAction,
  onDoubleClickAction,
  scroll,
  expandable,
  rowKey,
  extraSorter,
  setTitleTotals,
  rowSelection,
  withCustomData,
  datas,
  stateSearch,
  setStateSearch,
  useUrlFilter,
  withPagination
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { pathname } = location;
  const { t } = useTranslation();
  const { user, dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();
  const [isLoading, setIsLoading] = useState(false);
  const [resources, setResources] = useState([]);
  const isMounted = useRef(true);
  const params = new URLSearchParams(
    useUrlFilter ? location.search : stateSearch
  );
  const searchValue = params.get('k');
  const currentPage = Number(params.get('p') || 1);
  const pageSize = Number(params.get('pS') || 10);
  const currentFilters = params.get('f');
  const currentSorter = params.get('s');
  const [pagination, setPagination] = useState({
    pageSize: 10,
    total: 0,
    showTotal: (total, range) => `${range[1]} sur ${total} éléments`,
    showSizeChanger: true
  });
  const [expandedRowKeys, setExpandedRowKeys] = useState([]);

  const hasAccess = (purpose) => isAuthorized(pathname, user?.role, purpose);

  const fetchData = useCallback(
    async (page = pagination) => {
      if (!isMounted.current) return;
      setIsLoading(true);

      const searchURL = searchValue
        ? `/search/${encodeURIComponent(searchValue)}`
        : null;

      let sortingParameter;
      if (extraSorter) sortingParameter = `sort=${extraSorter}&`;
      if (currentSorter) sortingParameter = `sort=${currentSorter}&`;
      let filterParameter;
      if (currentFilters)
        filterParameter = `${currentFilters?.replaceAll('__', '&')}`;
      try {
        const { data, headers } = await dispatchAPI('GET', {
          url: `/${resourceName}${searchURL || ''}?${
            extraQuery ? `${extraQuery}&` : ''
          }${sortingParameter || ''}${filterParameter || ''}${
            populate ? `populate=${populate}&` : ''
          }limit=${pageSize}&skip=${(currentPage - 1) * pageSize}`
        });
        if (isMounted.current) {
          setPagination({
            ...page,
            total: parseInt(headers?.['x-total-count'] || 0, 10)
          });
          setResources(data);
          if (setTitleTotals && data?.[0]?.totals) {
            setTitleTotals(data?.[0]?.totals);
          }
        }
      } catch (e) {
        message(e);
      } finally {
        if (isMounted.current) setIsLoading(false);
      }
    },
    [
      searchValue,
      currentPage,
      pageSize,
      currentSorter,
      currentFilters,
      forceRefresh,
      extraQuery
    ]
  );

  const deleteResource = async (id) => {
    try {
      await dispatchAPI('DELETE', { url: `/${resourceName}/${id}` });
      await fetchData();
      showSuccessMessage(t, resourceName, 'archive');
    } catch (e) {
      message(e);
    }
  };

  const duplicateResource = async (id) => {
    try {
      await dispatchAPI('POST', { url: `/${resourceName}/duplicate/${id}` });
      await fetchData();
      showSuccessMessage(t, resourceName, 'duplicate');
    } catch (e) {
      message(e);
    }
  };

  const printResource = async (id) => {
    try {
      await dispatchAPI('GET', { url: `/files/${id}` });
      await fetchData();
    } catch (e) {
      message(e);
    }
  };

  const handlePageChange = (page, filters, sorters = {}) => {
    let sortingParameter;
    if (sorters) {
      if (!sorters.order) {
        sortingParameter = null;
      } else if (sorters.order === 'descend') {
        sortingParameter = `&s=-${sorters.columnKey}`;
      } else {
        sortingParameter = `&s=${sorters.columnKey}`;
      }
    }
    let filterParameter = '';
    Object.entries(filters || {}).forEach((el) => {
      if (el[1] && el[1].length) filterParameter += `${el[0]}=${[...el[1]]}__`;
    });

    const search = `?p=${page.current}&pS=${page.pageSize}${
      sortingParameter || ''
    }${filterParameter ? `&f=${filterParameter}` : ''}${
      searchValue ? `&k=${searchValue}` : ''
    }`;

    if (useUrlFilter) navigate({ pathname, search });
    else setStateSearch(search);

    setPagination((prevState) => ({
      ...prevState,
      current: page.current,
      pageSize: page.pageSize
    }));
  };

  useEffect(() => {
    isMounted.current = true;
    (async () => {
      if (withCustomData) {
        if (isMounted.current) setResources(datas);
      } else await fetchData();
    })();
    return () => {
      isMounted.current = false;
    };
  }, [fetchData, pageSize]);

  useEffect(() => {
    const expandableKeys = resources
      .filter(
        (resource) => resource.amendments && resource.amendments.length > 0
      )
      .map((resource) => resource._id);
    setExpandedRowKeys(expandableKeys);
  }, [resources]);

  const expandableConfig = {
    ...expandable,
    expandedRowKeys
  };

  const actionColumn = [
    {
      key: 'action',
      align: 'right',
      fixed: 'right',
      render: (record) => (
        <>
          {hasAccess('duplicate') && duplicateAction && (
            <Popconfirm
              title={t('datatable.column.action.duplicate.title')}
              okText={t('datatable.column.action.duplicate.ok')}
              okButtonProps={{ type: 'danger' }}
              cancelText={t('datatable.column.action.duplicate.cancel')}
              onConfirm={() => duplicateResource(record?.[rowKey])}
              icon={<WarningOutlined />}
            >
              <CopyOutlined style={{ fontSize: iconSize }} />
            </Popconfirm>
          )}
          {showAction && (
            <>
              <Divider type="vertical" />
              <Link
                to={{
                  pathname: showAction.pathname
                    ? showAction.pathname(record)
                    : `${path || pathname}/show/${record?.[rowKey]}`
                }}
              >
                <EyeOutlined style={{ fontSize: iconSize }} />
              </Link>
            </>
          )}
          {hasAccess('edit') && editAction && extraEditCondition(record) && (
            <>
              <Divider type="vertical" />
              <Link
                to={{
                  pathname: editAction.pathname
                    ? editAction.pathname(record)
                    : `${path || pathname}/edit/${record?.[rowKey]}`
                }}
              >
                <EditOutlined style={{ fontSize: iconSize }} />
              </Link>
            </>
          )}
          {printAction && (
            <>
              <Divider type="vertical" />
              <PrinterOutlined
                onClick={() => printResource(record?.[rowKey])}
                style={{ fontSize: iconSize }}
              />
            </>
          )}
          {hasAccess('archive') &&
            deleteAction &&
            record?.status !== 'ARCHIVED' && (
              <>
                <Divider type="vertical" />
                <Popconfirm
                  title={t('datatable.column.action.delete.title')}
                  okText={t('datatable.column.action.delete.ok')}
                  okButtonProps={{ type: 'danger' }}
                  cancelText={t('datatable.column.action.delete.cancel')}
                  onConfirm={() => deleteResource(record?.[rowKey])}
                  icon={<WarningOutlined />}
                >
                  <ContainerOutlined
                    style={{ color: 'var(--errorColor)', fontSize: iconSize }}
                    type="delete"
                  />
                </Popconfirm>
              </>
            )}
        </>
      )
    }
  ];

  const adjustedColumns = (columns || []).map((col) => {
    let order;
    let orderKey;
    if (currentSorter) {
      order = 'ascend';
      orderKey = currentSorter;
    }
    if (currentSorter && currentSorter.charAt(0) === '-') {
      order = 'descend';
      orderKey = currentSorter.substring(1);
    }
    const filters = {};
    if (currentFilters) {
      const filtersList = currentFilters.split('__');
      filtersList.forEach((f) => {
        if (f.split('=').length) {
          const [key, values] = f.split('=');
          filters[key] = values;
        }
      });
    }

    return {
      ...col,
      sortOrder: col.key === orderKey ? order : false,
      filteredValue: filters[col.key] ? filters[col.key].split(',') : []
    };
  });

  return (
    <Table
      style={style}
      scroll={scroll}
      rowKey={rowKey}
      rowClassName="rowStyle"
      onRow={(record) => ({
        ...(onDoubleClickAction
          ? {
              onDoubleClick: () =>
                onDoubleClickAction.action
                  ? onDoubleClickAction.action(record)
                  : navigate(`${path || pathname}/show/${record[rowKey]}`)
            }
          : {})
      })}
      dataSource={resources}
      loading={isLoading}
      onChange={handlePageChange}
      pagination={
        withPagination
          ? { ...pagination, current: currentPage, pageSize }
          : false
      }
      columns={
        customActionColumn
          ? adjustedColumns
          : [...adjustedColumns, ...actionColumn]
      }
      expandable={expandableConfig}
      rowSelection={rowSelection}
      tableLayout="auto"
    />
  );
};

Datatable.propTypes = {
  resourceName: PropTypes.string.isRequired,
  path: PropTypes.string,
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
      key: PropTypes.string.isRequired,
      dataIndex: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.arrayOf(PropTypes.string)
      ]),
      render: PropTypes.func,
      sorter: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
      filters: PropTypes.arrayOf(
        PropTypes.shape({
          text: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
          value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool])
        })
      )
    })
  ).isRequired,
  customActionColumn: PropTypes.bool,
  populate: PropTypes.string,
  style: PropTypes.shape({}),
  extraQuery: PropTypes.string,
  forceRefresh: PropTypes.bool,
  editAction: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.shape({
      pathname: PropTypes.func
    })
  ]),
  extraEditCondition: PropTypes.func,
  showAction: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.shape({
      pathname: PropTypes.func
    })
  ]),
  duplicateAction: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.shape({
      pathname: PropTypes.func
    })
  ]),
  printAction: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.shape({
      pathname: PropTypes.func
    })
  ]),
  deleteAction: PropTypes.bool,
  onDoubleClickAction: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.shape({
      action: PropTypes.func
    })
  ]),
  scroll: PropTypes.shape({}),
  expandable: PropTypes.shape({
    expandedRowKeys: PropTypes.arrayOf(PropTypes.string)
  }),
  rowKey: PropTypes.string,
  extraSorter: PropTypes.string,
  setTitleTotals: PropTypes.func,
  rowSelection: PropTypes.shape({}),
  datas: PropTypes.arrayOf(PropTypes.shape({})),
  withCustomData: PropTypes.bool,
  stateSearch: PropTypes.string,
  setStateSearch: PropTypes.func,
  useUrlFilter: PropTypes.bool,
  withPagination: PropTypes.bool
};

Datatable.defaultProps = {
  path: null,
  customActionColumn: false,
  populate: null,
  style: null,
  extraQuery: null,
  forceRefresh: null,
  editAction: true,
  extraEditCondition: () => true,
  showAction: true,
  duplicateAction: false,
  printAction: false,
  deleteAction: true,
  onDoubleClickAction: true,
  scroll: null,
  expandable: undefined,
  rowKey: '_id',
  extraSorter: null,
  setTitleTotals: null,
  rowSelection: null,
  datas: [],
  withCustomData: false,
  stateSearch: null,
  setStateSearch: () => {},
  useUrlFilter: true,
  withPagination: true
};
