import { useCallback, useEffect, useState, forwardRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Button, Form, Row, Spin, Collapse, Tabs, Card } from 'antd';
import { CloseOutlined, CheckOutlined } from '@ant-design/icons';
import { ContentCustom } from '../ContentCustom/ContentCustom';
import { useAuthContext } from '../../contexts/AuthContext';
import { useErrorMessage } from '../../utils/errorMessage';
import { PageHeaderCustom } from '../PageHeader/PageHeader';
import { useGenerateFormItem } from '../../utils/generateFormItem';
import {
  formItemLayout,
  tailFormItemLayout,
  drawerFormLayout,
  drawerTailFormLayout
} from '../../utils/constants/formLayout';
import { formDependencies } from '../../utils/formDependencies';
import { showSuccessMessage } from '../../utils/showSuccessMessage';
import { useHandleResize } from '../../utils/handleResize';
import { useDownloadDocument } from '../../utils/downloadDoc';

/**
 * CreateUpdateContainer is a React component that provides a form for creating or updating a resource.
 * It supports customization through a variety of props, enabling flexible form configurations.
 *
 * @component
 * @param {string} purpose - The purpose of the form, either 'create' or 'edit'.
 * @param {Array<Object>} fields - An array of field configurations for the form.
 * @param {Array<string>} loadingFields - An array of field names to be loaded asynchronously.
 * @param {string} resource - The resource identifier, used for API calls and translations.
 * @param {string} baseUrl - The base URL for API requests.
 * @param {Object} config - Configuration object for CRUD operations.
 * @param {Object} config.onGetResource - Configuration for getting the resource.
 * @param {Function} config.onGetResource.setFields - Function to set the form fields based on the fetched resource data.
 * @param {Object} config.onCreateResource - Configuration for creating a resource.
 * @param {Function} config.onCreateResource.setBody - Function to modify the body of the create request.
 * @param {Object} config.onUpdateResource - Configuration for updating a resource.
 * @param {Function} config.onUpdateResource.setBody - Function to modify the body of the update request.
 * @param {React.ReactNode} formExtra - Extra content to be displayed within the form.
 * @param {string} tradKey - Key for translation purposes.
 * @param {string} submitLabel - Label for the submit button.
 * @param {Function} customSubmit - Custom submit function for the form.
 * @param {boolean} isParentLoading - Indicates if the parent component is loading.
 * @param {Array<Object>} categories - An array of category configurations, used to group fields into collapsible sections.
 * @param {Function} setFormValues - Function to set form values externally.
 * @param {string} populate - Additional data to populate the form with, typically query parameters.
 * @param {Function} refresh - Function to trigger form refresh.
 * @param {boolean} isOverlay - Indicates if the form is displayed as an overlay.
 * @param {Function} customNavigate - Custom navigation function to override the default navigation behavior.
 * @param {string} idFromOverlay - Identifier used in overlay mode for fetching or updating a resource.
 * @param {boolean} withHeader - Indicates if a header should be displayed above the form.
 * @param {boolean} withCards - Indicates if the form should display fields within card containers.
 * @param {Array<Object>} tabs - An array of tab configurations for organizing form fields into tabs.
 * @param {string} layout - The layout of the form, can be 'horizontal', 'vertical', or 'inline'.
 * @param {Object} rejection - Object containing rejection conditions based on the fetched resource data.
 * @param {Array<string>} rejection.condition - An array of conditions for rejecting a resource.
 * @param {string} rejection.key - The key in the resource data to check against the rejection conditions.
 * @param {boolean} needFormDependencies - Indicates if form dependencies should be handled (e.g., dynamic field changes).
 * @param {Function} errorValidationAction - Custom function to handle validation errors.
 * @param {string} activeKey - The currently active key for tabs.
 * @param {Function} setActiveKey - Function to set the active tab key.
 * @param {Object} forceOnValuesChange - Object that triggers a manual onValuesChange call when updated.
 * @param {boolean} drawer - Indicates if the form is rendered in a drawer layout.
 * @param {string} titleSuffix - Additional text to be added to the form title.
 * @param {boolean} isDownloadFile - Boolean indicating whether a file should be downloaded after form submission.
 * @param {string} successMessageUrl - URL used to show a success message after form submission.
 * @param {Object} customFormInstance - Custom instance of the Ant Design Form to be used in the component.
 * @param {Object} ref - React ref object for the form.
 *
 * @returns {React.ReactNode} The rendered JSX for the CreateUpdateContainer component.
 */

export const CreateUpdateContainer = forwardRef(
  (
    {
      purpose,
      fields,
      loadingFields,
      resource,
      baseUrl,
      config,
      formExtra,
      tradKey,
      submitLabel,
      customSubmit,
      isParentLoading,
      categories,
      setFormValues,
      populate,
      refresh,
      isOverlay,
      customNavigate,
      idFromOverlay,
      withHeader,
      withCards,
      tabs,
      layout,
      rejection,
      needFormDependencies,
      errorValidationAction,
      activeKey,
      setActiveKey,
      forceOnValuesChange,
      drawer,
      titleSuffix,
      isDownloadFile,
      successMessageUrl,
      customFormInstance
    },
    ref
  ) => {
    const { id } = useParams();
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { message } = useErrorMessage();
    const { dispatchAPI } = useAuthContext();
    const [isLoading, setIsLoading] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const generateFields = useGenerateFormItem();
    const [form] = Form.useForm();
    const { onGetResource, onCreateResource, onUpdateResource } = config;
    const { width } = useHandleResize();
    const { downloadDocumentById } = useDownloadDocument();

    const updateResource = async (body) => {
      setIsSubmitting(true);
      try {
        await dispatchAPI('PATCH', {
          url: `${baseUrl}/${idFromOverlay || id}`,
          body:
            onUpdateResource && onUpdateResource.setBody
              ? onUpdateResource.setBody(body)
              : body
        });

        if (isDownloadFile) {
          const { data } = await dispatchAPI('GET', {
            url: `${baseUrl}/${idFromOverlay || id}?${
              populate ? `populate=${populate}` : ''
            }`
          });
          downloadDocumentById(data?.file);
        }
        if (isOverlay) {
          customNavigate();
        } else {
          navigate(-1);
        }
      } catch (e) {
        setIsSubmitting(false);
        message(e);
      }
    };

    const createResource = async (body) => {
      setIsSubmitting(true);
      try {
        const { data } = await dispatchAPI('POST', {
          url: `${baseUrl}`,
          body:
            onCreateResource && onCreateResource.setBody
              ? onCreateResource.setBody(body)
              : body
        });
        showSuccessMessage(t, successMessageUrl || baseUrl, 'create');
        if (isOverlay) {
          customNavigate(data);
          setIsSubmitting(false);
        } else {
          navigate(-1);
        }
      } catch (e) {
        setIsSubmitting(false);
        message(e);
      }
    };

    const getResource = useCallback(async () => {
      setIsLoading(true);
      try {
        const { data } = await dispatchAPI('GET', {
          url: `${baseUrl}/${idFromOverlay || id}?${
            populate ? `populate=${populate}` : ''
          }`
        });
        if (rejection && !rejection?.condition.includes(data[rejection.key])) {
          navigate(-1);
        }
        (customFormInstance || form).setFieldsValue(
          onGetResource && onGetResource.setFields
            ? onGetResource.setFields(data)
            : data
        );
        if (categories?.length || withCards?.length || needFormDependencies)
          setFormValues({
            ...(onGetResource && onGetResource.setFields
              ? onGetResource.setFields(data)
              : data)
          });
      } catch (e) {
        message(e);
      }
      setIsLoading(false);
    }, [purpose, id, loadingFields, baseUrl]);

    useEffect(() => {
      if (purpose === 'edit' && (idFromOverlay || id)) {
        setIsLoading(true);
        if (!loadingFields)
          (async () => {
            await getResource();
          })();
      }
    }, [getResource, refresh, idFromOverlay]);

    const handleSubmit = async (values) => {
      if (customSubmit) {
        setIsSubmitting(true);
        await customSubmit(values);
        setIsSubmitting(false);
      } else {
        if (purpose === 'edit') await updateResource(values);
        if (purpose === 'create') await createResource(values);
      }
    };

    const handleFinishFailed = (errorInfo) => {
      if (errorValidationAction) {
        errorValidationAction(errorInfo);
      }
    };

    const onChangeActiveTab = (key) => {
      setActiveKey(key);
    };

    const handleChange = (values) => {
      if (categories?.length || withCards?.length || needFormDependencies) {
        const formData = form.getFieldsValue();
        setFormValues({ ...formData });
        formDependencies(
          dispatchAPI,
          message,
          values,
          formData,
          resource,
          customFormInstance || form,
          setFormValues,
          categories?.map((category) => category.children),
          t
        );
      }
    };

    const formLayout = drawer ? drawerFormLayout : formItemLayout;
    const tailFormLayout = drawer ? drawerTailFormLayout : tailFormItemLayout;

    useEffect(() => {
      if (forceOnValuesChange) {
        handleChange(forceOnValuesChange);
      }
    }, [forceOnValuesChange]);

    return (
      <ContentCustom
        style={{
          margin: drawer ? 0 : 16,
          padding: drawer ? 0 : '16px 24px 24px'
        }}
      >
        {withHeader && (
          <PageHeaderCustom
            title={`${t(`${resource}.form.title.${purpose}`)} ${
              titleSuffix || ''
            }`}
          />
        )}

        <Spin spinning={isLoading || isParentLoading}>
          <Form
            ref={ref}
            layout={layout}
            {...(layout !== 'vertical' && formLayout)}
            onFinish={handleSubmit}
            scrollToFirstError={{ block: 'center', behavior: 'smooth' }}
            onFinishFailed={handleFinishFailed}
            onValuesChange={handleChange}
            form={customFormInstance || form}
          >
            {!tabs?.length &&
              !withCards?.length &&
              (categories?.length ? (
                <Collapse
                  defaultActiveKey={['1']}
                  items={categories}
                  style={{ marginBottom: 16 }}
                />
              ) : (
                <>
                  {fields.map((field) =>
                    generateFields(tradKey || resource, field)
                  )}
                </>
              ))}
            {withCards &&
              withCards.map((card) => (
                <Card
                  bordered={card.bordered}
                  title={t(`${resource}.titles.${card.title}`)}
                >
                  {card.fields.map((field) =>
                    generateFields(tradKey || resource, field)
                  )}
                </Card>
              ))}
            {tabs?.length ? (
              <Tabs
                className="create-customer-tabs"
                defaultActiveKey={activeKey}
                activeKey={activeKey}
                items={tabs}
                onChange={onChangeActiveTab}
              />
            ) : null}
            {formExtra}
            <Form.Item {...tailFormLayout}>
              <Row
                justify="end"
                style={{ marginTop: width < 1200 ? 40 : 0, flexWrap: 'nowrap' }}
              >
                <Button
                  style={{ margin: '0 8px' }}
                  type="default"
                  onClick={() =>
                    isOverlay ? customNavigate('cancel') : navigate(-1)
                  }
                >
                  {`${t('buttons.cancel')} `}
                  <CloseOutlined />
                </Button>
                <Button
                  type="primary"
                  htmlType="submit"
                  loading={isSubmitting}
                  disabled={isSubmitting}
                >
                  {`${t(submitLabel || 'buttons.save')} `}
                  <CheckOutlined />
                </Button>
              </Row>
            </Form.Item>
          </Form>
        </Spin>
      </ContentCustom>
    );
  }
);

CreateUpdateContainer.propTypes = {
  purpose: PropTypes.string.isRequired,
  fields: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  baseUrl: PropTypes.string.isRequired,
  resource: PropTypes.string.isRequired,
  loadingFields: PropTypes.bool,
  config: PropTypes.shape({
    onGetResource: PropTypes.shape({
      setFields: PropTypes.func
    }),
    onCreateResource: PropTypes.shape({
      setBody: PropTypes.func
    }),
    onUpdateResource: PropTypes.shape({
      setBody: PropTypes.func
    })
  }),
  formExtra: PropTypes.element,
  tradKey: PropTypes.string,
  submitLabel: PropTypes.string,
  customSubmit: PropTypes.func,
  setFormValues: PropTypes.func,
  isParentLoading: PropTypes.bool,
  categories: PropTypes.arrayOf(PropTypes.shape({})),
  populate: PropTypes.string,
  refresh: PropTypes.bool,
  isOverlay: PropTypes.bool,
  customNavigate: PropTypes.func,
  idFromOverlay: PropTypes.string,
  withHeader: PropTypes.bool,
  tabs: PropTypes.arrayOf(PropTypes.shape({})),
  layout: PropTypes.string,
  withCards: PropTypes.arrayOf(PropTypes.shape({})),
  rejection: PropTypes.shape({
    condition: PropTypes.arrayOf(PropTypes.string),
    key: PropTypes.string
  }),
  needFormDependencies: PropTypes.bool,
  errorValidationAction: PropTypes.func,
  activeKey: PropTypes.string,
  setActiveKey: PropTypes.func,
  forceOnValuesChange: PropTypes.shape({}),
  drawer: PropTypes.bool,
  titleSuffix: PropTypes.string,
  isDownloadFile: PropTypes.bool,
  successMessageUrl: PropTypes.string,
  customFormInstance: PropTypes.shape({})
};

CreateUpdateContainer.defaultProps = {
  config: {},
  loadingFields: false,
  formExtra: null,
  tradKey: null,
  submitLabel: null,
  customSubmit: null,
  setFormValues: null,
  isParentLoading: false,
  categories: null,
  populate: null,
  refresh: false,
  isOverlay: false,
  customNavigate: null,
  idFromOverlay: null,
  withHeader: true,
  tabs: [],
  withCards: [],
  layout: 'horizontal',
  rejection: null,
  needFormDependencies: false,
  errorValidationAction: null,
  activeKey: '1',
  setActiveKey: null,
  forceOnValuesChange: null,
  drawer: false,
  titleSuffix: null,
  isDownloadFile: false,
  successMessageUrl: undefined,
  customFormInstance: null
};
