import { routes, subRoutes } from '../../../../utils/constants/adminRoutes';

/**
 * Get the link for redirection based on the type of notification
 *
 * @function
 * @param {Number} type - The type of notification
 * @param {String} id - The id of the content
 * @returns {String} The link for redirection
 */
export const getLink = (type, id) => {
  switch (type) {
    case 1:
      return `${routes.ACTUALITIES}/show/${id}`;
    case 2:
      return `${routes.CUSTOMERSERVICES}/show/${id}`;
    case 3:
      return `${routes.RH}${subRoutes.RH.ABSENCE_VACATION}/show/${id}`;
    case 4:
      return `${routes.QUOTE}/show/${id}`;
    case 5:
      return `${routes.CONTRACT}/show/${id}`;
    case 6:
      return `${routes.CUSTOMERS}/show/${id}`;
    case 7:
      return `${routes.QUALITY_CONTROL}/show/${id}`;
    case 8:
    case 9:
      return `${routes.CUSTOMER_ANALYSIS}${subRoutes.CUSTOMER_ANALYSIS.RECONCILIATION_STATEMENT}`;
    case 10:
      return `${routes.RH}${subRoutes.RH.ABSENCE_VACATION}/show/${id}`;
    default:
      return '/';
  }
};
