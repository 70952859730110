export const optionsList = {
  MANAGEMENT: [
    'treatment',
    'organization_recovery',
    'customer_recovery',
    'track',
    'report',
    'traceability',
    'synthesis',
    'monitoring',
    'stats',
    'customer_area',
    'anomaly_analysis',
    'reject_analysis',
    'reporting',
    'analysis_balance_target',
    'check',
    'bank',
    'evolution'
  ],
  LIABILITIES: [
    'treatment',
    'op_recovery',
    'customer_recovery',
    'maj',
    'report',
    'traceability',
    'monitoring',
    'stats',
    'customer_area'
  ],
  FINANCIAL: [],
  CONFIGURATION: ['install', 'presentation'],
  OPTIMISATION: ['validation', 'analysis', 'duplicate'],
  CONFIG: [
    'installation',
    'presentation',
    'validation',
    'analysis',
    'duplicate'
  ],
  SEO: ['conception']
};
