import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { Tag, Divider, Flex, Popconfirm, Popover } from 'antd';
import {
  ContainerOutlined,
  EditOutlined,
  EyeOutlined,
  NotificationFilled,
  WarningOutlined
} from '@ant-design/icons';
import { Link, useLocation } from 'react-router-dom';
import { customerServicesColor } from '../../utils/constants/tagColors';
import CustomerServiceContext from './CustomerServiceContext';
import { isAuthorized } from '../../utils/constants/authorizedMenu';
import { formatValue } from '../../utils/formatNumber';

export const useColumns = (changeColor, totalCount, urgentCount, user) => {
  const { customerServiceEnums, users, deleteResource } =
    CustomerServiceContext();
  const { t } = useTranslation();
  const location = useLocation();
  const { pathname } = location;
  const iconSize = 18;
  const isGtp = user?.role === 'users:USER';
  const read = [
    { text: 'NON', value: false },
    { text: 'OUI', value: true }
  ];

  const hasAccess = (purpose) => isAuthorized(pathname, user?.role, purpose);

  const accessGtpCondition = (record) => {
    const isRecipient = record?.recipients?.some(
      (recipient) => recipient._id === user._id
    );
    const isCreator = record?.created_by?._id === user._id;
    if (!isGtp) return true;
    if (isRecipient || isCreator) return true;
    return false;
  };

  return [
    {
      title: t('customerServices.columns.read'),
      key: 'read',
      dataIndex: 'read_by',
      width: 80,
      render: (data) =>
        data?.includes(user?._id) ? null : (
          <NotificationFilled
            style={{ color: 'var(--errorColor)' }}
            size={24}
          />
        ),
      filters: read.map((r) => ({
        text: r.text,
        value: r.value
      })),
      filteredValue: read.map((r) => r.value)
    },
    {
      title: (
        <Flex gap={8}>
          {t('customerServices.columns.status')}
          <Popover content={t('customerServices.columns.total')}>
            <Tag>{totalCount}</Tag>
          </Popover>
          <Popover content={t(`customerServices.columns.enums.TO_PROCESSED`)}>
            <Tag color="red">{urgentCount}</Tag>
          </Popover>
        </Flex>
      ),
      key: 'status',
      dataIndex: ['status'],
      render: (status) => (
        <Tag color={customerServicesColor[status]}>
          {t(`customerServices.columns.enums.${status || 'N/A'}`, {
            defaultValue: status
          })}
        </Tag>
      ),
      sorter: true,
      filters: customerServiceEnums?.status?.map((p) => ({
        text: t(`customerServices.columns.enums.${p}`),
        value: p
      }))
    },
    {
      title: t('customerServices.columns.created_by'),
      key: 'created_by.first_name',
      dataIndex: 'created_by',
      render: (created_by) =>
        created_by
          ? `${created_by.first_name} ${
              created_by?.usage_last_name || created_by.last_name
            }`
          : '-',
      sorter: true,
      filters: (users || []).map((created_by) => ({
        text: `${created_by?.first_name} ${
          created_by?.usage_last_name || created_by?.last_name
        }`,
        value: created_by._id
      }))
    },
    {
      title: t('customerServices.columns.recipients'),
      key: 'recipients',
      dataIndex: ['recipients'],
      width: 140,
      render: (recipients) => (
        <Popover
          content={(recipients || []).map((recipient) => (
            <div style={{ display: 'flex', alignItems: 'baseline' }}>
              <p
                key={recipient._id}
                style={{ margin: '0', marginRight: '10px' }}
              >
                {recipient?.first_name}{' '}
                {recipient?.usage_last_name || recipient?.last_name}
              </p>
            </div>
          ))}
          title={t('customerServices.columns.recipients')}
        >
          <EyeOutlined style={{ fontSize: 18 }} />
        </Popover>
      )
    },
    {
      title: t('customerServices.columns.completion_date'),
      key: 'completion_date',
      render: (currentRow) => (
        <Tag
          color={changeColor(
            currentRow.status,
            dayjs(currentRow.completion_date)
          )}
        >
          {currentRow.completion_date &&
            formatValue(currentRow.completion_date, 'date')}
        </Tag>
      ),
      sorter: true
    },
    {
      title: t('customerServices.columns.origin'),
      key: 'origin',
      dataIndex: ['origin'],
      render: (origin) =>
        t(`customerServices.columns.enums.${origin || 'N/A'}`, {
          defaultValue: origin
        }),
      sorter: true,
      filters: customerServiceEnums?.origins?.map((p) => ({
        text: t(`customerServices.columns.enums.${p}`),
        value: p
      }))
    },
    {
      title: t('customerServices.columns.ps_number'),
      key: 'ps_number',
      dataIndex: 'customer',
      width: '7%',
      render: (customer) => (
        <Tag>{(customer && customer?.ps_number) || '-'}</Tag>
      ),
      sorter: true
    },
    {
      title: t('users.form.macro_ps'),
      key: 'macro_ps',
      dataIndex: 'customer',
      render: (customer) => `${customer?.macro_ps?.title || '-'}`
    },
    {
      title: t('customers.form.micro_ps'),
      key: 'micro_ps',
      dataIndex: 'customer',
      render: (customer) => customer?.micro_ps?.title
    },
    {
      title: t('customerServices.columns.company_name'),
      key: 'company_name',
      dataIndex: 'customer',
      render: (customer) => (customer && customer?.company_name) || '-',
      sorter: true
    },
    {
      title: t('customerServices.columns.recorded_time'),
      key: 'recorded_time',
      dataIndex: ['recorded_time'],
      render: (recorded_time) =>
        t(`customerServices.columns.enums.${recorded_time || 'N/A'}`, {
          defaultValue: recorded_time
        }),
      sorter: true
    },
    {
      key: 'action',
      align: 'right',
      fixed: 'right',
      render: (record) => (
        <>
          <Link to={`show/${record?._id}`}>
            <EyeOutlined style={{ fontSize: iconSize }} />
          </Link>
          {hasAccess('edit') && accessGtpCondition(record) && (
            <>
              <Divider type="vertical" />
              <Link to={`edit/${record?._id}`}>
                <EditOutlined style={{ fontSize: iconSize }} />
              </Link>
            </>
          )}
          {hasAccess('archive') && record?.status !== 'TO_PROCESSED' ? (
            <>
              <Divider type="vertical" />
              <Popconfirm
                title={t('datatable.column.action.delete.title')}
                okText={t('datatable.column.action.delete.ok')}
                okButtonProps={{ type: 'danger' }}
                cancelText={t('datatable.column.action.delete.cancel')}
                onConfirm={() => deleteResource(record?._id)}
                icon={<WarningOutlined />}
              >
                <ContainerOutlined
                  style={{ color: '#b51010', fontSize: iconSize }}
                  type="delete"
                />
              </Popconfirm>
            </>
          ) : null}
        </>
      )
    }
  ];
};
