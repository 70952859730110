import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Card, Col, Tabs, Row } from 'antd';
import { PageHeader } from '@ant-design/pro-components';
import { ContentCustom } from '../../components';

import Badgeuse from './Badgeuse';
import { useAuthContext } from '../../contexts/AuthContext';
import { ListBadging } from './ListBadging';
import { ListInterventions } from './ListInterventions';
import { ProductivityTab } from './productivity/ProductivityTab';
import { FinancialTab } from './financial/FinancialTab';
import { AdministrativeCard } from './administrative/AdministrativeCard';

/**
 * The Home component displays content based on the user's role.
 * It includes tabs for different categories of activities.
 *
 * @component
 * @returns {JSX.Element} The JSX element representing the Home component.
 */

export const Home = () => {
  const { t } = useTranslation();
  const { user } = useAuthContext();
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const handleTabChange = () => {
    navigate({
      pathname,
      search: ''
    });
  };

  const items = [
    {
      label: t('home.tabs.titles.activities'),
      children: <ListBadging user={user} />,
      key: '1'
    },
    {
      label: t('home.tabs.titles.productivity'),
      children: <ProductivityTab />,
      key: '2'
    },
    {
      label: t('home.tabs.titles.administrative'),
      children: <AdministrativeCard />,
      key: '3'
    },
    {
      label: t('home.tabs.titles.financial'),
      children: <FinancialTab />,
      key: '4'
    }
  ];

  return (
    <ContentCustom>
      <PageHeader title={t('home.title')} />
      {user?.role.includes('admins') ? (
        <Tabs
          className="tab-home"
          defaultActiveKey="1"
          items={items}
          onChange={handleTabChange}
          type="card"
        />
      ) : (
        <Row gutter={[16, 16]}>
          {!user?.role.includes('guests') ? (
            <Col span={10}>
              <Card>
                <Badgeuse />
              </Card>
            </Col>
          ) : null}
          {user?.role !== 'users:SALES-USER' && (
            <Col span={24}>
              <Card>
                <ListInterventions origin="home" />
              </Card>
            </Col>
          )}
        </Row>
      )}
    </ContentCustom>
  );
};
