import PropTypes from 'prop-types';
import { Card, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import { DescriptionList } from '../../../../components';
import { useListContent } from './listContent';

/**
 * ProductivitiesCard component that shows the productivities information of the quality control
 *
 * @component
 * @param {object} productivities - Productivities object
 * @return {component} - ProductivitiesCard component
 */
export const ProductivitiesCard = ({ productivities }) => {
  const { t } = useTranslation();
  const listContent = useListContent(productivities);
  return (
    <Card title={t('quality.form.productivities.title')}>
      <Row gutter={[16, 16]}>
        {productivities.map((p) => (
          <Card title={p?.macro_ps?.title?.toUpperCase()} key={p?._id}>
            <DescriptionList
              data={listContent.filter((f) => f.id === p._id)}
              translate
              columns={24}
            />
          </Card>
        ))}
      </Row>
    </Card>
  );
};

ProductivitiesCard.propTypes = {
  productivities: PropTypes.arrayOf(PropTypes.shape({}))
};

ProductivitiesCard.defaultProps = {
  productivities: []
};
