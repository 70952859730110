import { Route, Routes } from 'react-router-dom';
import { ListContracts } from './ListContracts';
import { ShowContract } from './ShowContract';
import { Exception } from '../../components';
import { ContractContextProvider } from './ContractContext';

export const ContractRouter = () => (
  <ContractContextProvider>
    <Routes>
      <Route index element={<ListContracts />} />
      <Route path="/show/:id" element={<ShowContract />} />
      <Route path="*" element={<Exception />} />
    </Routes>
  </ContractContextProvider>
);
