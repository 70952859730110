import { Popover, Col, Row, Divider } from 'antd';
import { useTranslation } from 'react-i18next';
import { EditOutlined, EyeOutlined } from '@ant-design/icons';
import { formatValue } from '../../../utils/formatNumber';

/**
 * Generates a structured array of service content data from quote data, suitable for display purposes.
 * Each entry in the returned array represents a data point with a label, column span, and content
 * formatted for display. This hook also supports conditional formatting and interactive elements
 * like popovers for discounts and editable fields depending on the service type and conditions.
 *
 * @param {object} data - The service data object containing all relevant service details such as dates,
 *                        amounts, discounts, and more.
 * @param {Function} openModalAdjustedHours - Function to open modal for adjusting service hours.
 *                        This is triggered when the editable hours field is clicked.
 * @param {string} resourceName - The name of the resource (e.g., 'quote'). Used to conditionally render
 *                        editable fields.
 * @returns {Array} An array of objects where each object contains:
 *                   - label (string): Translation key for the label.
 *                   - span (number): The grid span for layout purposes.
 *                   - content (React.ReactNode): The content to display, formatted appropriately,
 *                     which may include text, numbers, or JSX elements.
 */
export const useServiceContent = (
  data,
  openModalAdjustedHours,
  resourceName
) => {
  const { t } = useTranslation();
  const {
    _id,
    type,
    start_date,
    end_date,
    amount,
    total_hours,
    frequency,
    fee_discount,
    discounts,
    monthly_price,
    vat,
    monthly_price_vat_included,
    diff_since_start_service,
    adjusted_hours
  } = data;

  let totalDiscounts = 0;

  (discounts || []).map((discount) => {
    if (discount?.amount) totalDiscounts += discount?.amount || 0;
    return totalDiscounts;
  });

  return [
    {
      label: 'quotes.form.service.start_date',
      span: 8,
      content: formatValue(start_date, 'date') || '-'
    },
    ...(type === 'LIABILITIES_TREATMENT'
      ? [
          {
            label: 'quotes.form.service.end_date',
            span: frequency === 'NONE' ? 24 : 8,
            content: formatValue(end_date, 'date') || '-'
          }
        ]
      : []),
    {
      label: 'quotes.form.service.amount',
      span: 8,
      content: `${
        formatValue(Math.floor(amount * 100) / 100, 'currency') || '-'
      }`
    },
    {
      label: 'quotes.form.service.total_hour_frequency',
      span: 8,
      content: `${total_hours || '-'}h / ${
        t(`quotes.tags.${frequency}`) || '-'
      }`
    },
    {
      label: 'quotes.form.service.fee_discount',
      span: 24,
      content: `${fee_discount} %`
    },
    {
      label: 'quotes.form.service.total_discounts',
      span: 8,
      content: (
        <p>
          {`${
            formatValue((Math.floor(totalDiscounts) * 100) / 100, 'currency') ||
            '0'
          }`}
          {totalDiscounts ? (
            <Popover
              title={t('quotes.form.service.total_discounts')}
              content={(discounts || []).map((discount) => (
                <Row key={discount._id}>
                  <Col span="24">{`Remise: ${formatValue(
                    discount?.amount,
                    'currency'
                  )}`}</Col>
                  <Col span="24">{`Type: ${t(
                    `quotes.tags.${discount?.type}`
                  )}`}</Col>
                  <Col span="24">{`Du ${formatValue(
                    discount?.periods?.[0],
                    'date'
                  )} au ${formatValue(discount?.periods?.[1], 'date')}`}</Col>
                  <Divider />
                </Row>
              ))}
            >
              <EyeOutlined style={{ fontSize: 18, marginLeft: 8 }} />
            </Popover>
          ) : (
            ''
          )}
        </p>
      )
    },
    {
      label: `quotes.form.service.monthly_price${
        frequency === 'NONE' ? '_punctual' : ''
      }`,
      span: 8,
      content: `${
        formatValue(Math.floor(monthly_price * 100) / 100, 'currency') || '-'
      }`
    },
    {
      label: 'quotes.form.service.vat',
      span: 8,
      content: `${vat} %`
    },
    {
      label: `quotes.form.service.monthly_price_vat_included${
        frequency === 'NONE' ? '_punctual' : ''
      }`,
      span: 8,
      content: `${
        formatValue(
          Math.floor(monthly_price_vat_included * 100) / 100,
          'currency'
        ) || '-'
      }`
    },
    {
      label: 'quotes.form.service.diff_since_start_service',
      span: 8,
      content: (
        <p style={{ color: diff_since_start_service > 0 ? 'red' : 'green' }}>
          {`${
            diff_since_start_service > 0
              ? `- ${diff_since_start_service || 'N/A'}`
              : `+ ${diff_since_start_service || 'N/A'}`
          }h`}
        </p>
      )
    },
    {
      label: 'quotes.form.service.adjusted_hours',
      span: 8,
      content: (
        <p>
          {`${adjusted_hours || 'N/A'}h`}
          {resourceName !== 'quote' && (
            <EditOutlined
              style={{ marginLeft: 5, color: 'purple' }}
              onClick={() => openModalAdjustedHours(adjusted_hours, _id)}
            />
          )}
        </p>
      )
    }
  ];
};
