/**
 * Get statuses list based on user role
 *
 * @function
 * @param {Array} statuses - list of statuses
 * @param {String} role - user role
 * @returns {Array} - list of statuses based on user role
 */
export const getStatuses = (statuses, role) => {
  if (!statuses) return [];
  if (role === 'admins:SUPER-ADMIN') {
    return statuses;
  }
  return statuses;
};
