import { ColorSelector } from '../../ColorSelector';

/**
 * Custom hook to get the list content for the conversion rate
 *
 * @hook
 * @param {object} conversionRate - Conversion rate data object
 * @returns {Array} Array of objects containing label, span, and content for each field
 */
export const useListContent = (conversionRate) => [
  {
    label: 'quality.form.conversion_rate.percentage',
    span: 12,
    content: `${conversionRate?.percentage || '-'} %`,
    type: 'conversion_rate'
  },
  {
    label: 'quality.form.conversion_rate.assessment',
    span: 12,
    content: <ColorSelector value={conversionRate?.assessment} />,
    type: 'conversion_rate'
  }
];
