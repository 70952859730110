import { Input, Upload, Button, Select, Tag } from 'antd';
import ReactQuill from 'react-quill';
import { useTranslation } from 'react-i18next';
import { UploadOutlined } from '@ant-design/icons';
import { selectFormat } from '../customers/CRUDFields/utils';
import 'react-quill/dist/quill.snow.css';
import { userRoles } from '../../utils/constants/tagColors';
import { modules } from '../../utils/constants/modulesReactQuill';

const { Option } = Select;

/**
 * A custom hook that generates an array of form fields based on the provided parameters.
 *
 * @hook
 * @param {Object} params - The parameters for generating form fields.
 * @param {Object} params.enums - An object containing enumerated values used for field options.
 * @param {string} params.content - The content for a rich text field.
 * @param {Function} params.setContent - A function to update the content of the rich text field.
 * @param {string} params.purpose - The purpose of the form fields, such as 'create' or 'edit'.
 * @param {Function} params.uploadOrDeleteFile - A function for handling file upload or deletion.
 * @param {boolean} params.isLoading - Indicates whether data is loading.
 * @param {string} params.id - The ID of the form data when editing.
 * @param {Array} params.fileList - An array of files to display in the file upload field.
 * @param {Function} params.setFileList - A function to update the file list.
 * @returns {Array} An array of form field objects.
 */

export const useFields = ({
  enums,
  content,
  setContent,
  purpose,
  uploadOrDeleteFile,
  isLoading,
  id,
  fileList,
  setFileList,
  psMacro,
  setPsMacro,
  psMicro,
  setPsMicro
}) => {
  const { t } = useTranslation();

  const onsearchRole = (input, option) =>
    option.props['data-label'].toLowerCase().indexOf(input.toLowerCase()) >= 0;

  const onsearchPsMicro = (input, option) =>
    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;

  const onsearchPsMacro = (input, option) =>
    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;

  const fields = [
    ...(purpose === 'edit'
      ? [
          {
            label: 'status',
            input: <Input defaultValue={t('news.status.CREATED')} disabled />
          }
        ]
      : []),
    {
      name: ['title'],
      label: 'title_field',
      rules: [{ required: true }],
      input: <Input />
    },
    {
      name: ['recipients'],
      rules: [{ required: true }],
      input: (
        <Select
          loading={isLoading}
          mode="multiple"
          allowClear
          filterOption={(input, option) => onsearchRole(input, option)}
        >
          {(enums.roles || [])
            .map((role) => ({
              original: role,
              translated: t(`users.tags.${role.split(':')[1]}`)
            }))
            .sort((a, b) => a.translated.localeCompare(b.translated))
            .map(({ original, translated }) => (
              <Option key={original} value={original} data-label={translated}>
                <Tag color={userRoles[original.split(':')[1]]}>
                  {translated}
                </Tag>
              </Option>
            ))}
        </Select>
      )
    },
    {
      name: ['macro_ps'],
      input: (
        <Select
          loading={isLoading}
          mode="multiple"
          allowClear
          disabled={psMicro?.length}
          filterOption={(input, option) => onsearchPsMacro(input, option)}
          onSelect={(value) => {
            setPsMacro([...psMacro, value]);
          }}
          onClear={() => {
            setPsMacro([]);
          }}
        >
          {(enums.macro_ps || []).map((macro) => (
            <Option key={macro.title} value={macro._id}>
              {macro.title}
            </Option>
          ))}
        </Select>
      )
    },
    {
      name: ['micro_ps'],
      input: (
        <Select
          loading={isLoading}
          mode="multiple"
          allowClear
          filterOption={(input, option) => onsearchPsMicro(input, option)}
          disabled={psMacro?.length}
          onSelect={(value) => {
            setPsMicro([...psMicro, value]);
          }}
          onClear={() => {
            setPsMicro([]);
          }}
        >
          {(enums.micro_ps || []).map((micro) => (
            <Option key={micro.title} value={micro._id}>
              {micro.title}
            </Option>
          ))}
        </Select>
      )
    },
    {
      name: ['category'],
      input: selectFormat(t, enums?.categories, {
        allowClear: true,
        allowSearch: true,
        render: (text) => t(`news.categories.${text}`)
      })
    },
    {
      name: ['file'],
      input: (
        <Upload
          multiple
          maxCount={1}
          beforeUpload={(file) => {
            if (purpose !== 'edit') {
              setFileList([file]);
            } else {
              uploadOrDeleteFile(file, id, 'upload', 'file');
            }
            return false;
          }}
          onRemove={(doc) => {
            if (purpose !== 'edit') {
              setFileList();
            } else {
              uploadOrDeleteFile(
                fileList[
                  fileList.findIndex(
                    (file) => file.metadata.originalName === doc.name
                  )
                ],
                id,
                'delete',
                'file'
              );
            }
          }}
          fileList={
            isLoading
              ? []
              : (fileList || []).map((file, index) => ({
                  uid: index,
                  name: file?.metadata?.originalName || file?.name,
                  status: 'done'
                }))
          }
        >
          <Button icon={<UploadOutlined />}>{t('buttons.upload_2')}</Button>
        </Upload>
      )
    },
    {
      name: ['description'],
      input: (
        <ReactQuill
          theme="snow"
          value={content}
          onChange={setContent}
          modules={modules}
          style={{ height: '300px', minWidth: 640 }}
        />
      )
    }
  ];

  return fields;
};
