import dayjs from 'dayjs';
import 'dayjs/locale/fr';
import { Row, Col, Typography, Popover } from 'antd';
import { useTranslation } from 'react-i18next';
import { DayFormat } from './DayFormat';
import { popoverHoursFormat } from './utils';

const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December'
];

dayjs.locale('fr');

export const FullYearCalendar = ({
  year,
  events,
  workHours,
  weeksTotal,
  monthsTotal
}) => {
  const { t } = useTranslation();
  return months.map((month, index) => {
    const firstDay = dayjs()
      .set('month', index)
      .set('year', year)
      .startOf('month');
    const lastDay = dayjs()
      .set('month', index)
      .set('year', year)
      .endOf('month');
    const days = [];

    const hours = monthsTotal.find((m) => m.month === index + 1)?.hours || 0;
    const minutes =
      monthsTotal.find((m) => m.month === index + 1)?.minutes || 0;

    for (
      let i = firstDay;
      i.isBefore(lastDay) || i.isSame(lastDay);
      i = i.add(1, 'day')
    ) {
      days.push(i);
    }

    return (
      <Col span={2} key={month}>
        <div
          style={{
            backgroundColor: '#796477',
            border: '1px solid white',
            textAlign: 'center'
          }}
        >
          <Typography.Text
            style={{
              color: 'white'
            }}
          >
            {t(`months.${month}`)}
          </Typography.Text>
        </div>
        {days.map((day) => {
          const hasWorkHours =
            workHours?.filter((wh) =>
              wh.date.includes(day.format('YYYY-MM-DD'))
            ).length > 0;

          const specialDay = events?.filter(
            (e) =>
              ((dayjs(day).isSame(dayjs(e.start_date), 'day') ||
                dayjs(day).isAfter(dayjs(e.start_date), 'day')) &&
                (dayjs(day).isSame(dayjs(e.end_date), 'day') ||
                  dayjs(day).isBefore(dayjs(e.end_date), 'day'))) ||
              dayjs(e.date).isSame(day, 'day')
          );

          const isSpecialDay = specialDay.length > 0;
          const dayHours = workHours?.filter((wh) =>
            wh.date.includes(day.format('YYYY-MM-DD'))
          )[0];

          return hasWorkHours || isSpecialDay ? (
            <Popover
              content={
                !hasWorkHours && isSpecialDay ? (
                  specialDay
                    .map((d) => t(`events.form.enums.${d.type}`))
                    .join(', ')
                ) : (
                  <>
                    <Typography.Text>{`${t(
                      'internalGestion.popover.start'
                    )} : ${dayjs(dayHours?.start_time).format(
                      'HH:mm'
                    )}`}</Typography.Text>
                    {popoverHoursFormat(t, dayHours)}
                    <Typography.Text>{`${t('internalGestion.popover.end')} : ${
                      dayHours?.end_time
                        ? dayjs(dayHours?.end_time).format('HH:mm')
                        : ''
                    }`}</Typography.Text>
                  </>
                )
              }
            >
              <Typography>
                <DayFormat
                  isSpecialDay={isSpecialDay}
                  day={day}
                  workHours={workHours}
                  hasWorkHours={hasWorkHours}
                  weeksTotal={weeksTotal}
                  specialDay={specialDay}
                />
              </Typography>
            </Popover>
          ) : (
            <Typography>
              <DayFormat
                isSpecialDay={isSpecialDay}
                day={day}
                workHours={workHours}
                hasWorkHours={hasWorkHours}
                weeksTotal={weeksTotal}
              />
            </Typography>
          );
        })}
        <Row
          style={{
            backgroundColor: '#a25384',
            border: '1px solid #f0f0f0',
            borderRadius: '5px',
            padding: '5px',
            margin: '5px',
            flexWrap: 'nowrap'
          }}
        >
          <Col span={10}>
            <Typography.Text style={{ color: 'white' }}>
              {`${t(`abbreviatedmonths.${month}`)}`}
            </Typography.Text>
          </Col>
          <Col span={14}>
            <Typography.Text style={{ color: 'white' }}>
              {`${String(hours).padStart(2, '0')}H${String(minutes).padStart(
                2,
                '0'
              )}`}
            </Typography.Text>
          </Col>
        </Row>
      </Col>
    );
  });
};
