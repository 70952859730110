import { Divider, Popconfirm, Tag } from 'antd';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import {
  ContainerOutlined,
  EyeOutlined,
  WarningOutlined
} from '@ant-design/icons';
import { useAuthContext } from '../../../../contexts/AuthContext';
import { interventionsStatus } from '../../../../utils/constants/tagColors';
import { routes } from '../../../../utils/constants/adminRoutes';
import { isAuthorized } from '../../../../utils/constants/authorizedMenu';
import { formatValue } from '../../../../utils/formatNumber';

/**
 * Custom hook for generating table columns for displaying intervention data.
 *
 * @hook
 * @param {object} interventionEnums - Enumerations data for intervention properties
 * @param {Array} usersGtp - List of users
 * @returns {Array} Array of table column objects
 */

export const useColumns = (
  interventionEnums,
  leadersAndGtp,
  deleteResource
) => {
  const { t } = useTranslation();
  const { user: connectedUser } = useAuthContext();
  const location = useLocation();
  const { pathname } = location;
  const iconSize = 18;

  const hasAccess = (purpose) =>
    isAuthorized(pathname, connectedUser?.role, purpose);

  return [
    {
      title: t('interventions.columns.status'),
      key: 'status',
      dataIndex: ['status'],
      render: (status) => (
        <Tag color={interventionsStatus[status]}>
          {t(`interventions.form.enums.${status || 'N/A'}`)}
        </Tag>
      ),
      sorter: true,
      filters: interventionEnums?.status?.map((c) => ({
        text: t(`interventions.form.enums.${c}`),
        value: c
      }))
    },
    {
      title: t('interventions.columns.ps_number'),
      key: 'contract',
      dataIndex: 'contract',
      render: ({ ps_number }) => (ps_number ? <Tag>{ps_number}</Tag> : '-')
    },
    {
      title: t('interventions.columns.company_name'),
      key: 'contract',
      dataIndex: 'contract',
      render: ({ company_name }) => (company_name && company_name) || '-'
    },
    {
      title: t('interventions.columns.user'),
      key: 'user',
      dataIndex: 'user',
      render: (user) =>
        (user &&
          `${user?.first_name} ${user?.usage_last_name || user?.last_name}`) ||
        '-',
      sorter: true,
      filters: leadersAndGtp?.map((c) => ({
        text: `${c?.first_name} ${c?.usage_last_name || c?.last_name}`,
        value: c._id
      }))
    },
    {
      title: t('interventions.columns.start_date_date'),
      key: 'start_date',
      dataIndex: 'start_date',
      render: (start_date, data) =>
        (start_date && formatValue(start_date, 'date')) ||
        (data?.scheduled_start_date &&
          formatValue(data?.scheduled_start_date, 'date')),
      sorter: true
    },
    {
      title: t('interventions.columns.scheduled_start_date'),
      key: 'scheduled_start_date',
      dataIndex: 'scheduled_start_date',
      render: (scheduled_start_date) =>
        scheduled_start_date && formatValue(scheduled_start_date, 'time')
    },
    {
      title: t('interventions.columns.start_date'),
      key: 'start_date',
      dataIndex: 'start_date',
      render: (start_date) => start_date && formatValue(start_date, 'time')
    },
    {
      title: t('interventions.columns.end_date'),
      key: 'end_date',
      dataIndex: 'end_date',
      render: (end_date) => end_date && formatValue(end_date, 'time')
    },
    {
      title: t('interventions.columns.total_time'),
      key: 'total_time',
      dataIndex: 'total_time',
      render: (total_time) =>
        total_time ? `${(total_time / 60).toFixed(2)}h` : '-'
    },
    {
      title: t('interventions.columns.actions'),
      key: 'actions',
      dataIndex: 'actions',
      render: (actions) => (actions && actions) || 0
    },
    {
      title: t('interventions.columns.emails'),
      key: 'emails',
      dataIndex: 'emails',
      render: (emails) => (emails && emails) || 0
    },
    {
      title: t('interventions.columns.files'),
      key: 'files',
      dataIndex: 'files',
      render: (files) => (files && files?.length) || 0
    },
    {
      title: t('interventions.columns.report_sent'),
      key: 'report_sent',
      dataIndex: 'report_sent',
      render: (report_sent) =>
        report_sent ? t(`switch.${report_sent}`) : 'N/A'
    },
    {
      key: 'action',
      align: 'right',
      fixed: 'right',
      render: (record) => {
        const canView = !(
          connectedUser?.role === 'users:SALES-USER' ||
          (['users:USER'].includes(connectedUser?.role) &&
            record.user._id !== connectedUser._id &&
            record.status === 'OPEN')
        );

        const canDelete = hasAccess('archive');

        return (
          <>
            {canView && (
              <>
                <Divider type="vertical" />
                <Link to={`${routes.INTERVENTIONS}/show/${record?._id}`}>
                  <EyeOutlined style={{ fontSize: iconSize }} />
                </Link>
              </>
            )}
            {canDelete && (
              <>
                <Divider type="vertical" />
                <Popconfirm
                  title={t('datatable.column.action.delete.title')}
                  okText={t('datatable.column.action.delete.ok')}
                  okButtonProps={{ type: 'danger' }}
                  cancelText={t('datatable.column.action.delete.cancel')}
                  onConfirm={() => deleteResource(record?._id)}
                  icon={<WarningOutlined />}
                >
                  <ContainerOutlined
                    style={{ color: '#b51010', fontSize: iconSize }}
                    type="delete"
                  />
                </Popconfirm>
              </>
            )}
          </>
        );
      }
    }
  ];
};
