import { Input } from 'antd';

const { TextArea } = Input;

const useFields = (commentSelected) => {
  const fields = [
    {
      name: ['comment'],
      rules: [
        {
          required: true
        }
      ],
      initialValue: commentSelected,
      input: <TextArea rows={5} />
    }
  ];

  return {
    fields
  };
};

export default useFields;
