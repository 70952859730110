import PropTypes from 'prop-types';
import { Card, Typography, Table, Col, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import { PieChart, Pie, Cell, ResponsiveContainer, Legend } from 'recharts';
import { useColumns } from './rejectionsColumns';
import { graphcolors } from '../../../utils/constants/tagColors';

/**
 * Renders customized label for PieChart segments.
 *
 * @param {Object} props - Properties passed to the customized label component.
 * @param {number} props.cx - X-coordinate of the center of the PieChart.
 * @param {number} props.cy - Y-coordinate of the center of the PieChart.
 * @param {number} props.midAngle - Mid angle of the PieChart segment.
 * @param {number} props.outerRadius - Outer radius of the PieChart segment.
 * @param {number} props.percent - Percentage value of the PieChart segment.
 * @param {number} props.value - Value of the PieChart segment.
 * @returns {JSX.Element} Rendered customized label component
 */
export const RejectionsAnalysis = ({ rejectionsAnalysis, rejectionTypes }) => {
  const { t } = useTranslation();
  const columns = useColumns(rejectionTypes);

  const renderCustomizedLabel = ({
    cx,
    cy,
    midAngle,
    outerRadius,
    percent,
    value
  }) => {
    const RADIAN = Math.PI / 180;
    const radius = outerRadius + 20;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    const percentage = (percent * 100).toFixed(2);

    return (
      <text
        x={x}
        y={y}
        fill="#333"
        textAnchor={x > cx ? 'start' : 'end'}
        dominantBaseline="central"
      >
        {`${percentage}% (${value})`}
      </text>
    );
  };

  return (
    <Card title={t('customers.stats.rejections_analysis')}>
      <Row style={{ overflowX: 'auto' }}>
        <Table dataSource={rejectionsAnalysis?.dataAsArray} columns={columns} />
      </Row>

      <Row gutter={[16, 16]} style={{ marginTop: 16 }}>
        <Col xs={24} xl={12}>
          <Typography.Title level={5}>
            {t('customers.stats.rejection_type')}
          </Typography.Title>
          <ResponsiveContainer height={400}>
            <PieChart>
              <Pie
                data={rejectionsAnalysis?.rejectionTypesWithTotal}
                innerRadius={60}
                outerRadius={80}
                fill="#8884d8"
                paddingAngle={5}
                dataKey="total"
                labelLine
                label={renderCustomizedLabel}
              >
                {rejectionsAnalysis?.rejectionTypesWithTotal?.map(
                  (action, index) => (
                    <Cell
                      key={`cell-${action?.title}`}
                      fill={graphcolors[index % graphcolors.length]}
                    />
                  )
                )}
              </Pie>
              <Legend
                layout="vertical"
                align="right"
                verticalAlign="middle"
                payload={rejectionsAnalysis?.rejectionTypesWithTotal?.map(
                  (action, index) => ({
                    value: action.title,
                    type: 'square',
                    color: graphcolors[index % graphcolors.length]
                  })
                )}
              />
            </PieChart>
          </ResponsiveContainer>
        </Col>
        <Col xs={24} xl={12}>
          <Typography.Title level={5}>
            {t('customers.stats.rejection_oprator')}
          </Typography.Title>
          <ResponsiveContainer height={300}>
            <PieChart>
              <Pie
                data={rejectionsAnalysis?.dataAsArray}
                innerRadius={60}
                outerRadius={80}
                fill="#8884d8"
                paddingAngle={5}
                dataKey="total"
                labelLine
                label={renderCustomizedLabel}
              >
                {rejectionsAnalysis?.dataAsArray?.map((action, index) => (
                  <Cell
                    key={`cell-${action?.title}`}
                    fill={graphcolors[index % graphcolors.length]}
                  />
                ))}
              </Pie>
              <Legend
                layout="vertical"
                align="right"
                verticalAlign="middle"
                payload={rejectionsAnalysis?.dataAsArray?.map(
                  (action, index) => ({
                    value: action.operator_code,
                    type: 'square',
                    color: graphcolors[index % graphcolors.length]
                  })
                )}
              />
            </PieChart>
          </ResponsiveContainer>
        </Col>
      </Row>
    </Card>
  );
};

RejectionsAnalysis.propTypes = {
  rejectionsAnalysis: PropTypes.shape(PropTypes.arrayOf(PropTypes.shape({}))),
  rejectionTypes: PropTypes.arrayOf(PropTypes.shape())
};

RejectionsAnalysis.defaultProps = {
  rejectionsAnalysis: {},
  rejectionTypes: []
};
