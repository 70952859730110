import { Route, Routes } from 'react-router-dom';
import { ListQuotes } from './ListQuotes';
import { CreateUpdateQuote } from './CreateUpdateQuote';
import { ShowQuote } from './ShowQuote';
import { Exception } from '../../components';
import { QuoteContextProvider } from './QuoteContext';

export const QuoteRouter = () => (
  <QuoteContextProvider>
    <Routes>
      <Route path="/create" element={<CreateUpdateQuote purpose="create" />} />
      <Route path="/edit/:id" element={<CreateUpdateQuote purpose="edit" />} />
      <Route path="/show/:id" element={<ShowQuote />} />
      <Route index element={<ListQuotes />} />
      <Route path="*" element={<Exception />} />
    </Routes>
  </QuoteContextProvider>
);
