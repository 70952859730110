import { Route, Routes } from 'react-router-dom';
import { subRoutes } from '../../utils/constants/adminRoutes';
import { ListReconciliationStatements } from './ReconciliationStatements/ListReconciliationStatements';
import { ListRejections } from './Rejections/ListRejections';
import { Exception } from '../../components';
import { CustomerAnalysisContextProvider } from './CustomerAnalysisContext';

export const CustomerAnalysisRouter = () => (
  <CustomerAnalysisContextProvider>
    <Routes>
      <Route
        path={`${subRoutes.CUSTOMER_ANALYSIS.REJECTION}/*`}
        element={<ListRejections />}
      />
      <Route
        path={`${subRoutes.CUSTOMER_ANALYSIS.RECONCILIATION_STATEMENT}/*`}
        element={<ListReconciliationStatements />}
      />
      <Route path="*" element={<Exception />} />
    </Routes>
  </CustomerAnalysisContextProvider>
);
