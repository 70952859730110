import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { Tag, Flex, Tooltip, Row, Col } from 'antd';
import { QuestionCircleOutlined, EditOutlined } from '@ant-design/icons';
import ServiceContext from './ServiceContext';
import { ServicesStatusColor } from '../../utils/constants/tagColors';
import { routes } from '../../utils/constants/adminRoutes';
import { formatValue } from '../../utils/formatNumber';

export const useColumns = (openModalAdjustedHours) => {
  const { t } = useTranslation();
  const { serviceEnums, leaders, gtps } = ServiceContext();
  return [
    {
      title: t('services.columns.type'),
      key: 'type',
      dataIndex: ['type'],
      render: (type) => t(`services.columns.enums.${type || 'N/A'}`),
      sorter: true
    },
    {
      title: t('services.columns.status'),
      key: 'status',
      dataIndex: ['status'],
      render: (status) => (
        <Tag color={ServicesStatusColor[status]}>
          {t(`services.columns.enums.${status || 'N/A'}`, {
            defaultValue: status
          })}
        </Tag>
      ),
      filters: serviceEnums?.status?.map((p) => ({
        text: (
          <Tag color={ServicesStatusColor[p]}>
            {t(`services.columns.enums.${p}`)}
          </Tag>
        ),
        value: p
      }))
    },
    {
      title: t('services.columns.amount'),
      key: 'amount',
      dataIndex: 'amount',
      render: (amount) => `${formatValue(amount, 'currency', true)} / h`
    },
    {
      title: t('services.columns.total_hours'),
      key: 'total_hours',
      dataIndex: 'total_hours',
      render: (total_hours, service) =>
        `${total_hours}h${t(
          `services.columns.enums.${service?.frequency}_short`,
          {
            defaultValue: service?.frequency
          }
        )}`,
      sorter: true
    },
    {
      title: t('services.columns.monthly_price_vat_included'),
      key: 'monthly_price_vat_included',
      dataIndex: 'monthly_price_vat_included',
      render: (monthly_price_vat_included) =>
        `${formatValue(monthly_price_vat_included, 'currency', true)}`
    },
    {
      title: t('services.columns.fee_discount'),
      key: 'fee_discount',
      dataIndex: 'fee_discount',
      render: (fee_discount) => (fee_discount ? `${fee_discount}%` : `0 %`),
      sorter: true
    },
    {
      title: (
        <Flex>
          <Tooltip title={t('services.columns.tooltip.theorical_diff')}>
            <QuestionCircleOutlined style={{ marginRight: 5 }} />
          </Tooltip>
          {t('services.columns.theorical_diff')}
        </Flex>
      ),
      key: 'theorical_diff',
      dataIndex: 'theorical_diff',
      render: (theorical_diff) => (
        <Tag color={theorical_diff < 0 ? 'red' : 'green'}>
          {theorical_diff ? `${theorical_diff}h` : `0h`}
        </Tag>
      )
    },
    {
      title: (
        <Flex>
          <Tooltip title={t('services.columns.tooltip.real_diff')}>
            <QuestionCircleOutlined style={{ marginRight: 5 }} />
          </Tooltip>
          {t('services.columns.real_diff')}
        </Flex>
      ),
      key: 'real_diff',
      dataIndex: 'real_diff',
      render: (real_diff) => (
        <Tag color={real_diff < 0 ? 'red' : 'green'}>
          {real_diff ? `${real_diff}h` : `0h`}
        </Tag>
      )
    },
    {
      title: t('services.columns.adjusted_hours'),
      key: 'adjusted_hours',
      dataIndex: 'adjusted_hours',
      render: (adjusted_hours, service) => (
        <Row align="middle" justify="space-between" style={{ width: '100%' }}>
          <Col>{adjusted_hours}</Col>
          <Col>
            <EditOutlined
              style={{ color: 'purple', cursor: 'pointer' }}
              onClick={() =>
                openModalAdjustedHours(adjusted_hours || 0, service._id)
              }
            />
          </Col>
        </Row>
      ),
      sorter: true
    },
    {
      title: t('services.columns.leader'),
      key: 'leader',
      dataIndex: 'leader',
      render: (leader) =>
        leader
          ? `${leader.first_name} ${
              leader?.usage_last_name || leader.last_name
            }`
          : '-',
      filters: (leaders || []).map((leader) => ({
        text: `${leader.first_name} ${
          leader?.usage_last_name || leader?.last_name || ''
        }`,
        value: leader._id
      }))
    },
    {
      title: t('services.columns.user_referent'),
      key: 'user_referent',
      dataIndex: 'user_referent',
      render: (user_referent) =>
        user_referent
          ? `${user_referent?.first_name} ${
              user_referent?.usage_last_name || user_referent?.last_name
            }`
          : '-',
      filters: (gtps || []).map((gtp) => ({
        text: `${gtp.first_name} ${
          gtp?.usage_last_name || gtp?.last_name || ''
        }`,
        value: gtp._id
      }))
    },
    {
      title: t('services.columns.periodicity'),
      key: 'periodicity',
      dataIndex: ['periodicity'],
      render: (periodicity) =>
        t(`services.columns.enums.${periodicity || 'N/A'}`, {
          defaultValue: periodicity
        }),
      sorter: true,
      filters: serviceEnums?.periodicities?.map((p) => ({
        text: t(`services.columns.enums.${p}`),
        value: p
      }))
    },
    {
      title: t('services.columns.accounting_account'),
      key: 'accounting_account',
      dataIndex: 'accounting_account',
      sorter: true
    },
    {
      title: t('contracts.columns.ec_th'),
      key: 'ec_th',
      dataIndex: 'ec_th'
    },
    {
      title: t('contracts.columns.em'),
      key: 'em',
      dataIndex: 'em'
    },
    {
      title: t('contracts.columns.em_catp'),
      key: 'em_catp',
      dataIndex: 'em_catp'
    },
    {
      title: t('contracts.columns.oem_ps'),
      key: 'contract',
      dataIndex: 'contract',
      render: (contract) =>
        contract?.customer?.outstanding_balance_target
          ? `${formatValue(
              contract?.customer?.outstanding_balance_target,
              'currency',
              true
            )}`
          : '-'
    },
    {
      title: t('services.columns.start_date'),
      key: 'start_date',
      render: (currentRow) =>
        currentRow.start_date &&
        dayjs(currentRow.start_date).format('DD/MM/YYYY'),
      sorter: true
    },
    {
      title: t('services.columns.contract'),
      key: 'contract',
      dataIndex: 'contract',
      render: (contract) => (
        <a
          href={`${`${routes.CONTRACT}`}/show/${contract?._id}`}
          target="_blank"
          rel="noopener noreferrer"
          aria-label="Voir le contrat"
        >
          {contract?.code}
        </a>
      )
    }
  ];
};
