import { useState } from 'react';
import dayjs from 'dayjs';
import { Modal } from 'antd';
import OccupancyChartContextProvider from '../OccupancyChartContext';
import { CreateUpdateContainer } from '../../../components/CreateUpdateContainer/CreateUpdateContainer';
import useFields from './fields';
import useInterventionFields from '../../interventions/interventionFields';

const InterventionModal = () => {
  const {
    isModalOpen,
    modalGtpInfo,
    modalServiceInfo,
    closeModal,
    purpose,
    psMicro,
    softwares,
    idFromOverlay,
    resource,
    date,
    editDate,
    setEditDate,
    setModalServiceInfo,
    interventionEnums,
    leadersAndGtp,
    customers,
    customerEnums,
    contracts,
    services,
    startWeek,
    formValues,
    setFormValues
  } = OccupancyChartContextProvider();
  const [hasRepetion, setHasRepetion] = useState(false);
  const [days, setDays] = useState([
    { name: 'monday', checked: false },
    { name: 'tuesday', checked: false },
    { name: 'wenesday', checked: false },
    { name: 'thursday', checked: false },
    { name: 'friday', checked: false },
    { name: 'saturday', checked: false },
    { name: 'sunday', checked: false }
  ]);

  const { fields, isFieldsLoading } = useFields(
    modalGtpInfo,
    modalServiceInfo,
    purpose,
    psMicro,
    softwares,
    setHasRepetion,
    hasRepetion,
    days,
    setDays,
    date,
    resource,
    editDate
  );

  const { interventionFields } = useInterventionFields(
    isFieldsLoading,
    interventionEnums,
    leadersAndGtp,
    customers,
    customerEnums,
    psMicro,
    softwares,
    contracts,
    services,
    startWeek,
    setHasRepetion,
    hasRepetion,
    days,
    setDays,
    formValues,
    editDate
  );

  const getCheckedDaysString = (d) =>
    (d || [])
      .filter((day) => day.checked)
      .map((day) => day.name)
      .join(', ');

  const config = {
    onGetResource: {
      setFields: (data) => {
        setEditDate(data?.scheduled_start_date);
        if (data.has_repetition) setHasRepetion(true);
        if (data?.service) {
          setModalServiceInfo(data.service);
        }
        return {
          ...data,
          period_start: data.period_start && dayjs(data.period_start),
          period_end: data.period_end && dayjs(data.period_end),
          service_start: data.service_start && dayjs(data.service_start),
          service_end: data.service_end && dayjs(data.service_end),
          scheduled_start_date:
            (date?.date || data.scheduled_start_date) &&
            dayjs(
              `${dayjs(date?.date || data.scheduled_start_date).format(
                'YYYY-MM-DD'
              )} ${
                date.hour || dayjs(data.scheduled_start_date).format('HH:mm')
              }`,
              'YYYY-MM-DD HH:mm'
            ),
          scheduled_end_date:
            (date?.date || data?.scheduled_time) &&
            dayjs(
              `${dayjs(date?.date || data.scheduled_start_date).format(
                'YYYY-MM-DD'
              )} ${
                date?.hour || dayjs(data.scheduled_end_date).format('HH:mm')
              }`,
              'YYYY-MM-DD HH:mm'
            ).add(date?.date ? data.scheduled_time : 0, 'hours')
        };
      }
    },
    onUpdateResource: {
      setBody: (data) => ({
        ...data,
        resource,
        status: 'PLANNED',
        ...(!hasRepetion
          ? {
              frequency: null,
              repetition_days: [],
              repetition_end_date: null,
              repetition_week: null
            }
          : {
              repetition_days: [getCheckedDaysString(days)]
            })
      })
    },
    onCreateResource: {
      setBody: (data) => ({
        ...data,
        period_start: dayjs(data.period_start)
          .startOf('day')
          .format('YYYY-MM-DDTHH:mm:ss.SSS[Z]'),
        period_end: dayjs(data.period_end).format('YYYY-MM-DDTHH:mm:ss.SSS[Z]'),
        scheduled_start_date: dayjs(data.period_start)
          .startOf('day')
          .format('YYYY-MM-DDTHH:mm:ss.SSS[Z]'),
        scheduled_end_date: dayjs(data.period_end).format(
          'YYYY-MM-DDTHH:mm:ss.SSS[Z]'
        ),
        ...(!hasRepetion
          ? {
              frequency: null,
              repetition_days: [],
              repetition_end_date: null
            }
          : {
              repetition_days: [getCheckedDaysString(days)]
            })
      })
    }
  };

  return (
    <Modal
      centered
      open={isModalOpen}
      onCancel={() => closeModal('cancel')}
      width={800}
      footer={false}
    >
      <CreateUpdateContainer
        fields={resource === 'interventions' ? interventionFields : fields}
        loadingFields={isFieldsLoading}
        purpose={purpose}
        baseUrl="interventions"
        resource="interventions"
        isOverlay
        customNavigate={closeModal}
        idFromOverlay={idFromOverlay}
        config={config}
        populate="service"
        setFormValues={setFormValues}
        needFormDependencies={resource === 'interventions'}
        drawer
      />
    </Modal>
  );
};

export default InterventionModal;
