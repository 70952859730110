import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { ContentCustom, PageHeaderCustom } from '../../../components';
import { CustomSpin } from '../../../components/CustomSpin/CustomSpin';
import OccupancyChartContextProvider from '../OccupancyChartContext';
import { CalendarHeader } from '../../../components/Calendar/WeeklyCalendar/CalendarHeader.tsx';
import CarouselWithCards from '../../../components/Carousel/CarouselWithCards';
import ListGtps from './ListGtps';
import InterventionModal from './InterventionModal';

/**
 * Component to manage occupancy planning for users.
 *
 * @component
 * @returns {JSX.Element} Rendered ManageOccupancy component
 */

export const ManageOccupancy = () => {
  const { t } = useTranslation();
  const {
    leaders,
    startWeek,
    setStartWeek,
    isModalOpen,
    setForceCalendarRefresh,
    forceCalendarRefresh,
    patchServiceValidate
  } = OccupancyChartContextProvider();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setIsLoading(false);
  }, [leaders]);

  return isLoading ? (
    <ContentCustom>
      <CustomSpin />
    </ContentCustom>
  ) : (
    <DndProvider backend={HTML5Backend}>
      {isModalOpen && <InterventionModal resource="occupancies" />}
      <ContentCustom>
        <PageHeaderCustom title={`${t('occupancies.title')}`} />
        <CalendarHeader
          startWeek={startWeek}
          setStartWeek={setStartWeek}
          ISOWeekName
          mode="week"
          switchModes={false}
          setForceRefresh={setForceCalendarRefresh}
          forceRefresh={forceCalendarRefresh}
        />
        <CarouselWithCards
          resourceName="services"
          modelResourceName="occupancies"
          extraQuery={`${
            startWeek
              ? `&start_date>=${dayjs(startWeek)
                  .add(1, 'day')
                  .startOf('day')
                  .format('YYYY-MM-DDTHH:mm:ss.SSS[Z]')}&end_date<=${dayjs(
                  startWeek
                )
                  .add(7, 'day')
                  .endOf('day')
                  .format('YYYY-MM-DDTHH:mm:ss.SSS[Z]')}`
              : ''
          }&status=ACTIVE`}
          populate="leader,user_referent.used_software,contract.customer"
          chunkSize={8}
          forceRefresh={forceCalendarRefresh}
          setForceRefresh={setForceCalendarRefresh}
          extraCarousel
          patchResource={patchServiceValidate}
        />
        <ListGtps forceCalendarRefresh={forceCalendarRefresh} />
      </ContentCustom>
    </DndProvider>
  );
};
