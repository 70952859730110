export const headers = [
  {
    label: 'position',
    key: 'employments'
  },
  {
    label: 'user.first_name',
    key: 'user.first_name'
  },
  {
    label: 'user.last_name',
    key: 'user.last_name'
  },
  {
    label: 'hebdo_hours',
    key: 'hebdo_hours'
  },
  {
    label: 'worked_hours',
    key: 'worked_hours'
  },
  {
    label: 'sup_hours',
    key: 'sup_hours'
  },
  {
    label: 'paid_absences',
    key: 'paid_absences'
  },
  {
    label: 'unpaid_absences',
    key: 'unpaid_absences'
  },
  {
    label: 'paid_vacations_taken',
    key: 'paid_vacations_taken'
  },
  {
    label: 'paid_vacations_left',
    key: 'paid_vacations_left'
  },
  {
    label: 'solidarity_hours_done',
    key: 'solidarity_hours_done'
  },
  {
    label: 'solidarity_hours_left',
    key: 'solidarity_hours_left'
  }
];
