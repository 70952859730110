/**
 * Custom hook for generating technique content data for displaying user technique details in a list format.
 *
 * @hook
 * @param {object} data - User data object
 * @returns {Array} Array of objects containing label, span, and content for each field
 */

export const useListTechniqueContent = (data = {}) => {
  const { macro_ps, used_software } = data;

  return [
    {
      label: 'users.form.macro_ps',
      content: macro_ps?.length && macro_ps.map((macro) => `${macro?.title}, `),
      span: 3
    },
    {
      label: 'users.form.used_software',
      content:
        used_software?.length &&
        used_software.map((software) => `${software.title}, `),
      span: 3
    }
  ];
};
