import { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { LineChart, Line, XAxis, YAxis, Tooltip } from 'recharts';
import PropTypes from 'prop-types';

const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    return (
      <div
        style={{
          padding: '0',
          backgroundColor: 'transparent',
          border: 'none'
        }}
      >
        <p style={{ margin: 0 }}>{`${label} : ${payload[0].value}%`}</p>
      </div>
    );
  }

  return null;
};

export const RejectionChart = ({ data }) => {
  const [chartData, setChartData] = useState([]);

  const formatData = () => {
    const formattedData = [];
    const sortedData = (data || []).sort(
      (a, b) => dayjs(a?.period_concerned) - dayjs(b?.period_concerned)
    );
    (sortedData || []).forEach((d) => {
      if (d?.reject_rate)
        formattedData.push({
          name: dayjs(d?.period_concerned).format('MM/YYYY'),
          valeur: Number(d?.reject_rate?.toFixed(0))
        });
    });
    setChartData(formattedData);
  };

  useEffect(() => {
    formatData();
  }, [data]);

  return (
    <>
      <LineChart width={100} height={100} data={chartData}>
        <XAxis dataKey="name" hide />
        <YAxis hide />
        <Tooltip content={<CustomTooltip />} />
        <Line
          type="linear"
          dataKey="valeur"
          stroke="#30972E"
          strokeWidth={8}
          dot={false}
          activeDot={{ r: 8 }}
        />
      </LineChart>
      <p
        style={{
          color: '#30972E',
          fontSize: 26,
          fontWeight: 'bold',
          marginLeft: 20
        }}
      >
        {`${
          chartData && chartData.length > 0
            ? chartData[chartData.length - 1].valeur
            : '0'
        }%`}
      </p>
    </>
  );
};

RejectionChart.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object, PropTypes.array])
};

RejectionChart.defaultProps = {
  data: undefined
};

CustomTooltip.propTypes = {
  active: PropTypes.bool,
  payload: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.number
    })
  ),
  label: PropTypes.string
};

CustomTooltip.defaultProps = {
  active: false,
  payload: [],
  label: null
};
