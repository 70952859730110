export const msalConfig = {
  auth: {
    // tenantId: 'fe34f1d9-946b-47ba-ae32-9c10d2ba5866', // Your tenant ID goes here
    clientId: 'c2ec45a5-9a69-4097-90c1-2ffaad9b211f', // Your client (application) ID goes here
    authority:
      'https://login.microsoftonline.com/fe34f1d9-946b-47ba-ae32-9c10d2ba5866', // Replace the last part with your tenant ID
    // redirectUri: 'http://localhost:3000/redirect/', // Dev URI // Must be the same in Azure AD portal, can be replace with an environment variable: process.env.REACT_APP_REDIRECT_URL
    redirectURI: 'https://app.tpa.strateg.in/redirect/', // Prod URI
    postLogoutRedirectUri: 'http://localhost:3000'
  },
  cache: {
    cacheLocation: 'sessionStorage', // Configures cache location. "sessionStorage" is more secure, but "localStorage" gives you SSO between tabs.
    storeAuthStateInCookie: false // Set this to "true" if you are having issues on IE11 or Edge
  }
};

export const loginRequest = {
  scopes: ['User.Read.All']
};
