import PropTypes from 'prop-types';
import {
  CheckOutlined,
  DeleteOutlined,
  EditOutlined,
  EyeOutlined
} from '@ant-design/icons';
import { Button, Flex, Popconfirm } from 'antd';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { routes } from '../../utils/constants/adminRoutes';
import { isAuthorized } from '../../utils/constants/authorizedMenu';
import useCustomerServiceContext from './CustomerServiceContext';

/**
 * ExtraButtons component is used to display additional buttons in the CustomerService show page (e.g. Processed, Unsee, Edit, Archive).
 *
 * @component
 * @param {object} customerService - The customerService object.
 * @param {string} title - The title of the button.
 * @param {object} user - The user object.
 * @returns {React.Component} - The ExtraButtons component.
 */
export const ExtraButtons = ({ customerService, title, user }) => {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const { pathname } = location;
  const isGtp = user?.role === 'users:USER';

  const hasAccess = (purpose) => isAuthorized(pathname, user?.role, purpose);

  const accessGtpCondition = () => {
    const isRecipient = customerService?.recipients?.some(
      (recipient) => recipient._id === user._id
    );
    const isCreator = customerService?.created_by?._id === user._id;
    if (!isGtp) return true;
    if (isRecipient || isCreator) return true;
    return false;
  };

  const { deleteResource, changeStatus, unsee } = useCustomerServiceContext();

  const { status, _id } = customerService;

  return (
    <Flex gap={8}>
      {hasAccess('edit') &&
      customerService?.recipients?.some(
        (recipient) => recipient._id === user?._id
      ) ? (
        <Popconfirm
          title={t(`customerServices.action.${title}.title`)}
          okText={t(`customerServices.action.${title}.ok`)}
          okButtonProps={{ type: 'danger' }}
          cancelText={t(`customerServices.action.${title}.cancel`)}
          onConfirm={() => changeStatus(_id, 'PROCESSED')}
          icon={<CheckOutlined />}
        >
          <Button
            type="primary"
            icon={<CheckOutlined size={24} />}
            disabled={title !== 'TO_PROCESSED'}
          >
            {t(`customerServices.form.enums.${title}`)}
          </Button>
        </Popconfirm>
      ) : null}
      <Button
        type="primary"
        icon={<EyeOutlined size={24} />}
        onClick={() => {
          unsee(_id);
          navigate(-1);
        }}
      >
        {t('customerServices.buttons.unsee')}
      </Button>
      {hasAccess('edit') && accessGtpCondition() && (
        <Link to={`${routes.CUSTOMERSERVICES}/edit/${_id}`}>
          <Button type="primary" icon={<EditOutlined size={24} />}>
            {t('customerServices.buttons.edit')}
          </Button>
        </Link>
      )}
      {status !== 'TO_PROCESSED' && hasAccess('archive') && (
        <Popconfirm
          title={t('customerServices.action.delete.title')}
          okText={t('customerServices.action.delete.ok')}
          okButtonProps={{ type: 'danger' }}
          cancelText={t('customerServices.action.delete.cancel')}
          onConfirm={() => deleteResource(_id)}
          icon={<DeleteOutlined />}
        >
          <Button type="link" danger icon={<DeleteOutlined size={24} />}>
            {t('customerServices.buttons.archive')}
          </Button>
        </Popconfirm>
      )}
    </Flex>
  );
};

ExtraButtons.propTypes = {
  customerService: PropTypes.shape({
    _id: PropTypes.string,
    status: PropTypes.string,
    recipients: PropTypes.arrayOf(PropTypes.shape({ _id: PropTypes.string })),
    created_by: PropTypes.shape({ _id: PropTypes.string })
  }),
  title: PropTypes.string,
  user: PropTypes.shape({
    _id: PropTypes.string,
    role: PropTypes.string
  })
};

ExtraButtons.defaultProps = {
  customerService: {},
  title: '',
  user: {}
};
