import { Route, Routes } from 'react-router-dom';
import { Exception } from '../../components';
import { ListQualityControl } from './ListQualityControl';
import { CreateUpdateQualityControl } from './CreateUpdateQualityControl';
import { QualityControlContextProvider } from './QualityControlContext';
import { ShowQualityControl } from './Show/ShowQualityControl';

/**
 * Router component for quality control management.
 *
 * @component
 * @returns {JSX.Element} The quality control router
 */
export const QualityControlRouter = () => (
  <QualityControlContextProvider>
    <Routes>
      <Route
        path="/create"
        element={<CreateUpdateQualityControl purpose="create" />}
      />
      <Route
        path="/edit/:id"
        element={<CreateUpdateQualityControl purpose="edit" />}
      />
      <Route index element={<ListQualityControl />} />
      <Route path="/show/:id" element={<ShowQualityControl />} />
      <Route path="*" element={<Exception />} />
    </Routes>
  </QualityControlContextProvider>
);
