import { useTranslation } from 'react-i18next';
import { Tag } from 'antd';
import { yesNoSwitch } from '../../../utils/constants/tagColors';
import { formatValue } from '../../../utils/formatNumber';

/**
 * Custom hook for generating health protections content data for displaying user health protections details in a list format.
 *
 * @hook
 * @param {object} data - User data object
 * @returns {Array} Array of objects containing label, span, and content for each field
 */

export const useListCommunicationMaterialContent = (data = {}) => {
  const { t } = useTranslation();

  const {
    right_of_publicity,
    parking_badge_number,
    parking_place_number,
    attribution_date,
    sharing_birthday_date,
    key_delivery,
    work_materials
  } = data;

  return [
    {
      label: 'users.form.right_of_publicity',
      content: (
        <Tag color={yesNoSwitch[right_of_publicity]}>
          {right_of_publicity ? t(`switch.${right_of_publicity}`) : 'N/A'}
        </Tag>
      ),
      span: 6
    },
    {
      label: 'users.form.parking_badge_number',
      content: parking_badge_number || '-',
      span: 6
    },
    {
      label: 'users.form.parking_place_number',
      content: parking_place_number || '-',
      span: 6
    },
    {
      label: 'users.form.attribution_date',
      content: attribution_date ? formatValue(attribution_date, 'date') : '-',
      span: 6
    },
    {
      label: 'users.form.sharing_birthday_date',
      content: (
        <Tag color={yesNoSwitch[sharing_birthday_date]}>
          {sharing_birthday_date ? t(`switch.${sharing_birthday_date}`) : 'N/A'}
        </Tag>
      ),
      span: 6
    },
    {
      label: 'users.form.key_delivery',
      content: key_delivery ? formatValue(key_delivery, 'date') : '-',
      span: 6
    },
    {
      label: 'users.form.work_materials',
      content:
        work_materials?.length &&
        work_materials.map(
          (material) => `${t(`users.materials.${material}`)}, `
        ),
      span: 6
    }
  ];
};
