import { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { LineChart, Line, XAxis, YAxis, Tooltip } from 'recharts';
import PropTypes from 'prop-types';
import { formatValue } from '../../../utils/formatNumber';

const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    return (
      <div
        style={{
          padding: '0',
          backgroundColor: 'transparent',
          border: 'none'
        }}
      >
        <p style={{ margin: 0 }}>{`${label} : ${payload[0].value}€`}</p>
      </div>
    );
  }

  return null;
};

export const OutstandingChart = ({ data }) => {
  const [chartData, setChartData] = useState([]);
  const formatData = () => {
    const countData = [
      { sum: 0, count: 0 },
      { sum: 0, count: 0 },
      { sum: 0, count: 0 },
      { sum: 0, count: 0 }
    ];
    const actualDate = dayjs();
    const actualMonth = dayjs().month();

    (data || []).forEach((d) => {
      const month = dayjs(d.date).month();
      const monthDiff = (actualMonth - month + 12) % 12;
      if (monthDiff <= 3) {
        countData[3 - monthDiff].sum += d.number;
        countData[3 - monthDiff].count += 1;
      }
    });

    const formattedData = countData.map((d, i) => {
      const monthDate = actualDate.subtract(3 - i, 'month');
      const monthName = monthDate.format('MM/YYYY');
      const valeur = d.count > 0 ? Math.round(d.sum / d.count) : 0;

      return {
        name: monthName,
        valeur,
        target: data[0]?.customer?.outstanding_balance_target
      };
    });

    setChartData(formattedData);
  };

  const determineColor = () => {
    const values = chartData.map((entry) => entry.valeur);
    const target = data[0]?.customer?.outstanding_balance_target;

    const belowTargetCount = values.filter((val) => val < target).length;
    const aboveTargetCount = values.filter((val) => val > target).length;
    if (belowTargetCount === values.length) {
      return '#30972E';
    }
    if (aboveTargetCount === values.length) {
      return '#FF0000';
    }
    if (belowTargetCount === 2 && aboveTargetCount === 2) {
      return '#FFA500';
    }
    return '#000000';
  };

  useEffect(() => {
    formatData();
  }, [data]);

  return (
    <>
      <LineChart width={100} height={100} data={chartData}>
        <XAxis dataKey="name" hide />
        <YAxis hide />
        <Tooltip content={<CustomTooltip />} />
        <Line
          type="linear"
          dataKey="valeur"
          stroke={determineColor()}
          strokeWidth={8}
          dot={false}
          activeDot={{ r: 8 }}
        />
        <Line
          type="linear"
          dataKey="target"
          stroke="#2E86C1"
          strokeWidth={4}
          dot={false}
          strokeDasharray="6 6"
        />
      </LineChart>
      <p
        style={{
          color: determineColor(),
          fontSize: 20,
          fontWeight: 'bold',
          marginLeft: 20
        }}
      >
        {`${
          chartData && chartData.length > 0
            ? `${formatValue(
                chartData[chartData.length - 1].valeur,
                'currency',
                true
              )}`
            : '0'
        }`}
      </p>
    </>
  );
};

OutstandingChart.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object, PropTypes.array])
};

OutstandingChart.defaultProps = {
  data: undefined
};

CustomTooltip.propTypes = {
  active: PropTypes.bool,
  payload: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.number
    })
  ),
  label: PropTypes.string
};

CustomTooltip.defaultProps = {
  active: false,
  payload: [],
  label: null
};
