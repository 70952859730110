import dayjs from 'dayjs';
import { DatePicker } from 'antd';
import { useTranslation } from 'react-i18next';
import QuittanceContextProvider from './QuittanceContext';
import { ListResource } from '../../components';
import { useColumns } from './columns';
import { headers } from './headers';
import { useFormatter } from '../../utils/formatter';
import StatusModal from './ShowCards/StatusModal';

const { RangePicker } = DatePicker;

/**
 * Component for listing quittances with filtering options.
 *
 * @component
 * @returns {JSX.Element} Rendered ListQuittances component
 */

export const ListQuittances = () => {
  const { t } = useTranslation();
  const { formattedData } = useFormatter('quittances');
  const {
    forceRefresh,
    selectedPeriods,
    setSelectedPeriods,
    isModalStatusOpen,
    closeStatusModal,
    customNavigate,
    buttonClicked,
    idFromOverlay,
    hasAccess
  } = QuittanceContextProvider();

  const columns = useColumns(hasAccess);

  const handleRangeChange = (dates) => {
    if (dates && dates.length === 2) {
      const startOfMonth = dates[0].startOf('month');
      const endOfMonth = dates[1].endOf('month');
      setSelectedPeriods([startOfMonth, endOfMonth]);
    } else {
      setSelectedPeriods(null);
    }
  };

  return (
    <>
      {isModalStatusOpen && (
        <StatusModal
          isModalOpen={isModalStatusOpen}
          closeModal={closeStatusModal}
          customNavigate={customNavigate}
          purpose="edit"
          buttonClicked={buttonClicked}
          idFromOverlay={idFromOverlay}
        />
      )}
      <ListResource
        resourceName="quittances"
        resourceModelName="Quittance"
        forceRefresh={forceRefresh}
        columns={columns}
        headers={headers}
        populate="customer,leader,sale,micro_ps,macro_ps"
        withImportButton={false}
        withCreateButtonText={t('buttons.create_custom.quittances')}
        extraEditCondition={(record) => record.from_scratch}
        customActionColumn
        formatter={formattedData}
        scroll={{ x: 2400 }}
        extraQuery={
          selectedPeriods?.length
            ? `transfer_date>=${dayjs(
                new Date(selectedPeriods[0]).setHours(0, 0, 0, 0)
              )
                .startOf('month')
                .toISOString()}&transfer_date<=${dayjs(
                new Date(selectedPeriods[1]).setHours(23, 59, 59, 999)
              ).toISOString()}`
            : ''
        }
        extraFilters={
          <RangePicker
            format="DD-MM-YYYY"
            picker="month"
            style={{ marginLeft: '20px', width: '270px' }}
            value={selectedPeriods}
            onChange={handleRangeChange}
            placeholder={[
              'Sélectionner le mois de début',
              'Sélectionner le mois de fin'
            ]}
          />
        }
      />
    </>
  );
};
