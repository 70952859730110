import { Col, Row } from 'antd';
import { ActionReminderCard } from './ActionReminderCard';
import { QualityControlCard } from './QualityControlCard';

/**
 * The ProductivityTab component displays productivity-related content.
 *
 * @component
 * @returns {JSX.Element} The JSX element representing the ProductivityTab component.
 */
export const ProductivityTab = () => (
  <Row gutter={[16, 16]}>
    <Col span={24}>
      <QualityControlCard />
    </Col>
    <Col span={24}>
      <ActionReminderCard />
    </Col>
  </Row>
);
