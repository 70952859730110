export const headers = [
  {
    label: 'user',
    key: 'user.first_name'
  },
  {
    label: 'user',
    key: 'user.last_name'
  },
  {
    label: 'role',
    key: 'user.role'
  },
  {
    label: 'trigram',
    key: 'user.trigram'
  },
  {
    label: 'type',
    key: 'type'
  },
  {
    label: 'date',
    key: 'date'
  },
  {
    label: 'startTime',
    key: 'startTime'
  },
  {
    label: 'endTime',
    key: 'endTime'
  },
  {
    label: 'status',
    key: 'status'
  },
  {
    label: 'comment',
    key: 'comment'
  }
];
