import { Input } from 'antd';

export const websitesFields = [
  {
    name: ['name'],
    key: 'name',
    input: <Input />
  },
  {
    name: ['url'],
    key: 'url',
    input: <Input />
  },
  {
    name: ['login'],
    key: 'login',
    input: <Input />
  },
  {
    name: ['password'],
    key: 'password',
    input: <Input />
  }
];
