import React, { createContext, useContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useAuthContext } from '../../contexts/AuthContext';
import { useErrorMessage } from '../../utils/errorMessage';

const UserContext = createContext({});

/**
 * Context provider component for managing user-related data.
 * This context provides various state values and methods related to users.
 *
 * @component
 * @param {object} props - React component props
 * @param {React.ReactNode} props.children - The child components that will have access to the context
 * @returns {React.ReactNode} Wrapped children with the user context
 */

export const UserContextProvider = ({ children }) => {
  const { dispatchAPI, user } = useAuthContext();
  const { message } = useErrorMessage();
  const [enums, setEnums] = useState({});
  const [levels, setLevels] = useState([]);
  const [users, setUsers] = useState([]);
  const [psMicro, setPsMicro] = useState();
  const [absenceVacation, setAbsenceVacation] = useState({});
  const [psMacro, setPsMacro] = useState();
  const [software, setSoftware] = useState();
  const [indentityCard, setIndentityCard] = useState([]);
  const [securityCard, setSecurityCard] = useState([]);
  const [rib, setRib] = useState([]);
  const [familyBook, setFamilyBook] = useState([]);
  const [others, setOthers] = useState([]);
  const [files, setFiles] = useState([]);
  const [formValues, setFormValues] = useState(null);
  const [refresh, setRefresh] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [companies, setCompanies] = useState([]);

  const getEnums = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/users/enums`
      });
      setEnums(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getLevels = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: 'employments/enums'
      });
      setLevels(data?.levels);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getUsers = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: '/employments?status=ACTIVE&populate=user'
      });
      setUsers(data.map((item) => item.user));
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getAbsenceVacation = async (id) => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/events/absence-vacation/paid-vacation/${id}`
      });
      setAbsenceVacation(data);
    } catch (e) {
      message(e);
    }
  };

  const getMicroPs = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/Psmicro?sort=title`
      });
      setPsMicro(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getMacroPs = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/Psmacro?sort=title`
      });
      setPsMacro(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getSoftwares = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/Software`
      });
      setSoftware(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getCompanies = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/customers?sort=ps_number`
      });
      setCompanies(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const uploadOrDeleteFile = async (file, id, type, key) => {
    setIsLoading(true);
    const formData = new FormData();
    if (type !== 'delete') formData.append(`${key}`, file);
    formData.append('values', JSON.stringify({ ...formValues }));
    try {
      await dispatchAPI('PATCH', {
        url: `/users/file/${id}?type=${type}&key=${key}&fileId=${file._id}`,
        body: formData
      });
    } catch (e) {
      message(e);
    }
    setRefresh(!refresh);
    setIsLoading(false);
  };

  useEffect(() => {
    (async () => {
      await getEnums();
      await getLevels();
      await getMicroPs();
      await getMacroPs();
      await getSoftwares();
      await getCompanies();
      await getUsers();
    })();
  }, []);

  return (
    <UserContext.Provider
      value={{
        enums,
        levels,
        psMicro,
        psMacro,
        software,
        indentityCard,
        setIndentityCard,
        securityCard,
        setSecurityCard,
        rib,
        setRib,
        familyBook,
        setFamilyBook,
        others,
        files,
        setOthers,
        setFiles,
        formValues,
        setFormValues,
        uploadOrDeleteFile,
        refresh,
        isLoading,
        companies,
        getAbsenceVacation,
        absenceVacation,
        users,
        user
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

UserContextProvider.propTypes = {
  children: PropTypes.element.isRequired
};

export default () => useContext(UserContext);
