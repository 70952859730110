import { useState } from 'react';
import { Flex, DatePicker, Select, Tag } from 'antd';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { ListResource } from '../../components';
import useArticleContext from './ArticleContext';
import { useAuthContext } from '../../contexts/AuthContext';
import { useColumns } from './columns';
import { newsStatus } from '../../utils/constants/tagColors';
import { useFormatter } from '../../utils/formatter';
import { headers } from './headers';

const { RangePicker } = DatePicker;
const { Option } = Select;

/**
 * A component for displaying a list of articles with filtering and search capabilities.
 *
 * @component
 * @returns {JSX.Element} The JSX element representing the list of articles.
 */

const ListArticles = () => {
  const { t } = useTranslation();
  const { isLoading, setRefresh, refresh, enums } = useArticleContext();
  const { user } = useAuthContext();
  const { formattedData } = useFormatter('news');
  const [dateRange, setDateRange] = useState([]);
  const [selectedPsMacro, setSelectedPsMacro] = useState();
  const [selectedPsMicro, setSelectedPsMicro] = useState();
  const [selectedStatus, setSelectedStatus] = useState();

  const onsearchPs = (input, option) =>
    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
  const onsearchstatus = (input, option) =>
    option.props['data-label'].toLowerCase().indexOf(input.toLowerCase()) >= 0;

  const columns = useColumns(user, enums);

  return (
    <ListResource
      resourceName="news"
      columns={columns}
      headers={headers}
      customActionColumn
      formatter={formattedData}
      populate="micro_ps,macro_ps,file"
      resourceModelName="New"
      editAction={false}
      forceRefresh={refresh}
      deleteAction
      showAction
      withCreateButtonText={t('buttons.create_custom.news')}
      withImportButton={false}
      withUploadButton={user.role.split(':')[1] !== 'USER'}
      duplicateAction
      extraSorter="-created_at"
      extraQuery={`${selectedStatus !== 'ARCHIVED' ? 'status!=ARCHIVED' : ''}${
        dateRange?.length
          ? `&created_at>=${dayjs(
              new Date(dateRange[0]).setHours(0, 0, 0, 0)
            ).toISOString()}&created_at<=${dayjs(
              new Date(dateRange[1]).setHours(23, 59, 59, 999)
            ).toISOString()}`
          : ''
      }${selectedPsMacro ? `&macro_ps._id=${selectedPsMacro}` : ''}${
        selectedPsMicro ? `&micro_ps._id=${selectedPsMicro}` : ''
      }${selectedStatus ? `&status=${selectedStatus}` : ''}`}
      flexWrap
      extraFilters={
        <Flex align="center" gap={16}>
          <RangePicker format="DD-MM-YYYY" onChange={setDateRange} />
          {user.role.split(':')[1] !== 'USER' ? (
            <Select
              style={{ width: 200 }}
              placeholder={t('news.placeholder.status')}
              showSearch
              allowClear
              loading={isLoading}
              filterOption={(input, option) => onsearchstatus(input, option)}
              onSelect={(value) => {
                setSelectedStatus(value);
                setRefresh(!refresh);
              }}
              onClear={() => {
                setSelectedStatus();
                setRefresh(!refresh);
              }}
            >
              {enums?.status?.map((status) => (
                <Option
                  key={status}
                  value={status}
                  data-label={t(`news.status.${status}`)}
                >
                  <Tag color={newsStatus[status]}>
                    {t(`news.status.${status}`)}
                  </Tag>
                </Option>
              ))}
            </Select>
          ) : null}
          <Select
            style={{ width: 200 }}
            placeholder={t('news.placeholder.macro_ps')}
            showSearch
            allowClear
            loading={isLoading}
            filterOption={(input, option) => onsearchPs(input, option)}
            onSelect={(value) => {
              setSelectedPsMacro(value);
              setRefresh(!refresh);
            }}
            onClear={() => {
              setSelectedPsMacro();
              setRefresh(!refresh);
            }}
          >
            {enums?.macro_ps?.map((ps) => (
              <Option key={ps._id} value={ps._id}>
                {ps.title}
              </Option>
            ))}
          </Select>
          <Select
            style={{ width: 200 }}
            placeholder={t('news.placeholder.micro_ps')}
            showSearch
            allowClear
            loading={isLoading}
            filterOption={(input, option) => onsearchPs(input, option)}
            onSelect={(value) => {
              setSelectedPsMicro(value);
              setRefresh(!refresh);
            }}
            onClear={() => {
              setSelectedPsMicro();
              setRefresh(!refresh);
            }}
          >
            {enums?.micro_ps?.map((ps) => (
              <Option key={ps._id} value={ps._id}>
                {ps.title}
              </Option>
            ))}
          </Select>
        </Flex>
      }
    />
  );
};

export default ListArticles;
