import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Menu, Row } from 'antd';
import { NavLink, useLocation } from 'react-router-dom';
import navMenuLogos from '../../utils/constants/navMenuLogos';
import { useHandleResize } from '../../utils/handleResize';
import {
  routes,
  subRoutes,
  pathSearches
} from '../../utils/constants/adminRoutes';
import { RemainingTasksCount } from './RemainingTasksCount';
import { useAuthContext } from '../../contexts/AuthContext';

export const AdminNavMenu = ({ setCollapsed }) => {
  const { t } = useTranslation();
  const location = useLocation();
  const { user } = useAuthContext();
  const { width } = useHandleResize();
  const path = location?.pathname || '/';
  const activeKeys = path
    ?.replace('/', '')
    .split('/')
    .map((p) => `/${p}`);

  const hideMenu = {
    BILLING: ['admins:ADMIN-RH'],
    TEMPLATE_DOCS: ['admins:ADMIN-RH'],
    MAILS: ['admins:ADMIN-RH', 'admins:ADMIN-ACCOUNTANT', 'admins:ADMIN-PROD'],
    SETTINGS: [
      'admins:ADMIN-RH',
      'admins:ADMIN-ACCOUNTANT',
      'admins:ADMIN-PROD'
    ],
    CUSTOMER_ANALYSIS: ['admins:ADMIN-RH'],
    FEES: ['admins:ADMIN-RH']
  };

  const hideSubMenu = {
    PAYMENTS: ['admins:ADMIN-PROD'],
    TRANSFERS: ['admins:ADMIN-PROD'],
    INTERNAL_GESTION: ['admins:ADMIN-PROD']
  };

  return (
    <Menu
      style={{
        padding: '12px 0',
        background: 'var(--clientColor)'
      }}
      selectedKeys={activeKeys}
      defaultOpenKeys={activeKeys}
      mode="inline"
      onSelect={width < 992 ? () => setCollapsed(true) : () => {}}
      items={Object.entries(routes).map(([menuItem, p]) => {
        if (hideMenu[menuItem] && hideMenu[menuItem].includes(user?.role))
          return null;
        return subRoutes[menuItem]
          ? {
              key: p,
              icon: navMenuLogos[menuItem],
              label: (
                <Row align="middle" justify="space-between">
                  {t(`basiclayout.menu.${menuItem.toLowerCase()}`)}
                  <RemainingTasksCount task={menuItem} />
                </Row>
              ),
              children: Object.entries(subRoutes[menuItem]).map(
                ([subMenuItem, subPath]) => {
                  if (
                    hideSubMenu[subMenuItem] &&
                    hideSubMenu[subMenuItem].includes(user?.role)
                  )
                    return null;
                  return {
                    key: subPath,
                    disabled: subPath === '',
                    label: (
                      <Row align="middle" justify="space-between">
                        <NavLink
                          to={`${p}${subPath}${
                            pathSearches[subMenuItem]
                              ? pathSearches[subMenuItem]
                              : ''
                          }`}
                        >
                          <span>
                            {t(
                              `basiclayout.submenu.${subMenuItem.toLowerCase()}`
                            )}
                          </span>
                        </NavLink>
                        <RemainingTasksCount task={subMenuItem} />
                      </Row>
                    )
                  };
                }
              )
            }
          : {
              key: p,
              disabled: p === '',
              label: (
                <NavLink
                  to={`${p}${
                    pathSearches[menuItem] ? pathSearches[menuItem] : ''
                  }`}
                >
                  {navMenuLogos[menuItem]}
                  <span>{t(`basiclayout.menu.${menuItem.toLowerCase()}`)}</span>
                </NavLink>
              )
            };
      })}
    />
  );
};

AdminNavMenu.propTypes = {
  setCollapsed: PropTypes.func.isRequired
};
