import { ListResource } from '../../components';
import { useColumns } from './columns';
import useQualityControlContext from './QualityControlContext';

/**
 * Component for listing quotes with filtering options.
 *
 * @component
 * @returns {JSX.Element} Rendered ListQuotes component
 */
export const ListQualityControl = () => {
  const { enums } = useQualityControlContext();

  const columns = useColumns(enums);
  return (
    <ListResource
      resourceName="quality"
      resourceModelName="Quality"
      populate="user.employments"
      columns={columns}
      withCreateButton={false}
    />
  );
};
