import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import { Modal } from 'antd';
import { CreateUpdateContainer } from '../../components/CreateUpdateContainer/CreateUpdateContainer';
import useFields from './fields';

const ReconcileCreditModal = ({
  isModalOpen,
  closeModal,
  idFromOverlay,
  customNavigate,
  purpose,
  quittance,
  enums,
  bankReconciliations,
  customers
}) => {
  const { t } = useTranslation();
  const [selectedType, setSelectedType] = useState('CLASSIC');
  const [selectedCustomer, setSelectedCustomer] = useState(null);

  const { fields, isFieldsLoading } = useFields(
    quittance,
    enums,
    purpose,
    bankReconciliations,
    customers,
    selectedType,
    setSelectedType,
    selectedCustomer,
    setSelectedCustomer
  );

  const config = {
    onGetResource: {
      setFields: (data) => {
        setSelectedType(data.type);
        setSelectedCustomer(data?.customer);
        return {
          ...data,
          operation_date: data?.operation_date && dayjs(data.operation_date),
          reconciliation_date:
            data?.reconciliation_date && dayjs(data.reconciliation_date)
        };
      }
    }
  };

  return (
    <Modal
      centered
      open={isModalOpen}
      onCancel={closeModal}
      title={t('payments.modal.title.reconciliation')}
      width={900}
      footer={false}
    >
      <CreateUpdateContainer
        fields={fields}
        loadingFields={isFieldsLoading}
        purpose={purpose}
        baseUrl="payments"
        resource="payments"
        config={config}
        withHeader={false}
        isOverlay
        customNavigate={customNavigate}
        idFromOverlay={idFromOverlay}
        drawer
      />
    </Modal>
  );
};

ReconcileCreditModal.propTypes = {
  isModalOpen: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  purpose: PropTypes.string,
  idFromOverlay: PropTypes.string,
  customNavigate: PropTypes.func,
  quittance: PropTypes.shape({}),
  enums: PropTypes.shape({}),
  bankReconciliations: PropTypes.arrayOf(PropTypes.shape({})),
  customers: PropTypes.arrayOf(PropTypes.shape({}))
};

ReconcileCreditModal.defaultProps = {
  idFromOverlay: null,
  customNavigate: undefined,
  purpose: null,
  quittance: {},
  enums: {},
  bankReconciliations: [],
  customers: null
};

export default ReconcileCreditModal;
