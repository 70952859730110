import { Route, Routes } from 'react-router-dom';
import { subRoutes } from '../../utils/constants/adminRoutes';
import { ManageOccupancy } from './occupancy/ManageOccupancy';
import { ManagePlanning } from './planning/ManagePlanning';
import { Exception } from '../../components';
import { OccupancyChartContextProvider } from './OccupancyChartContext';
import { ShowOccupancy } from './occupancy/ShowOccupancy';

export const OccupancyChartRouter = () => (
  <OccupancyChartContextProvider>
    <Routes>
      <Route
        path={`${subRoutes.OCCUPANCY_CHART.OCCUPANCY}/*`}
        element={<ManageOccupancy />}
      />
      <Route
        path={`${subRoutes.OCCUPANCY_CHART.PLANNING}/*`}
        element={<ManagePlanning />}
      />
      <Route
        path={`${subRoutes.OCCUPANCY_CHART.OCCUPANCY}/show/:id`}
        element={<ShowOccupancy />}
      />
      <Route path="*" element={<Exception />} />
    </Routes>
  </OccupancyChartContextProvider>
);
