import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Row, Spin, message as antMessage } from 'antd';
import { useAuthContext } from '../../contexts/AuthContext';
import { useErrorMessage } from '../../utils/errorMessage';
import { ContentCustom, PageHeaderCustom } from '../../components';
import { ShowPrincipalInfos } from './ShowUserCards/ShowPrincipalInfos';
import { ShowAdministrationInfos } from './ShowUserCards/ShowAdministrationInfos';
import { ShowEmploymentInfos } from './ShowUserCards/Employment/ShowEmploymentInfos';
import { ShowVacationsAndAbsences } from './ShowUserCards/VacationsAndAbsences/ShowVacationsAndAbsences';
import { ShowHealthProtections } from './ShowUserCards/ShowHealthProtections';
import { ShowDocuments } from './ShowUserCards/ShowDocuments';
import { ShowCommunicationMaterial } from './ShowUserCards/ShowCommunicationMaterial';
import { ShowTechnique } from './ShowUserCards/ShowTechnique';
import ExtraButtons from './ExtraButtons';

/**
 * Component for displaying detailed information about a user.
 *
 * @component
 * @returns {JSX.Element} Rendered ShowUser component
 */

export const ShowUser = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const { message } = useErrorMessage();
  const { user: connectedUser, dispatchAPI } = useAuthContext();
  const [isLoading, setIsLoading] = useState(false);
  const [user, setUser] = useState();
  const [forceRefresh, setForceRefresh] = useState(false);

  const isGuest = user?.role?.startsWith('guest');

  const getUser = useCallback(async () => {
    setIsLoading(true);
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/users/${id}?populate=macro_ps,used_software,company,files,employments.leader,indentity_card,social_security_card,rib,family_record_book,others`
      });
      setUser(data);
    } catch (e) {
      message(e);
    }
  }, [id]);

  const sendResetPasswordEmail = async () => {
    setIsLoading(true);
    try {
      await dispatchAPI('POST', {
        url: '/users/change-password/',
        body: { id }
      });
      antMessage.success(t('login.resetPwdEmailSent', { email: user?.email }));
      setIsLoading(false);
    } catch (e) {
      message(e);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    (async () => {
      await getUser();
      setIsLoading(false);
    })();
  }, [getUser, forceRefresh]);

  return isLoading ? (
    <ContentCustom>
      <Spin
        spinning
        size="large"
        style={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          marginLeft: '-16px',
          marginTop: '-17px'
        }}
      />
    </ContentCustom>
  ) : (
    <ContentCustom>
      {!isLoading && (
        <>
          <PageHeaderCustom
            title={`${user?.first_name} ${
              user?.usage_last_name || user?.last_name
            } - ${user?.trigram}`}
            extra={
              <ExtraButtons
                id={id}
                sendResetPasswordEmail={sendResetPasswordEmail}
                user={connectedUser}
              />
            }
          />
          <Row gutter={[24, 24]}>
            <ShowPrincipalInfos data={user} />
            {!isGuest && (
              <>
                <ShowEmploymentInfos data={user} />
                <ShowVacationsAndAbsences id={id} />
                <ShowAdministrationInfos data={user} />
                <ShowHealthProtections data={user} />
                <ShowDocuments
                  data={user}
                  forceRefresh={forceRefresh}
                  setForceRefresh={setForceRefresh}
                />
                <ShowCommunicationMaterial data={user} />
                <ShowTechnique data={user} />
              </>
            )}
          </Row>
        </>
      )}
    </ContentCustom>
  );
};
