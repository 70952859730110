import dayjs from 'dayjs';
import PropTypes from 'prop-types';
import { CreateUpdateContainer } from '../../components/CreateUpdateContainer/CreateUpdateContainer';
import useFields from './fields';
import QuittanceContextProvider from './QuittanceContext';

export const CreateUpdateQuittance = ({ purpose }) => {
  const { setFormValues } = QuittanceContextProvider();
  const { fields, isFieldsLoading } = useFields(purpose);

  const config = {
    onGetResource: {
      setFields: (data) => ({
        ...data,
        period_date: data?.period_date && dayjs(data?.period_date),
        transfer_date: data?.transfer_date && dayjs(data?.transfer_date)
      })
    },
    onCreateResource: {
      setBody: (data) => ({
        ...data,
        from_scratch: true,
        period_date: dayjs(data.period_date)
          .startOf('day')
          .format('YYYY-MM-DDTHH:mm:ss.SSS[Z]'),
        transfer_date: dayjs(data.transfer_date)
          .startOf('day')
          .format('YYYY-MM-DDTHH:mm:ss.SSS[Z]'),
        range_concerned: [
          dayjs(data.period_date)
            .startOf('month')
            .format('YYYY-MM-DDTHH:mm:ss.SSS[Z]'),
          dayjs(data.period_date)
            .endOf('month')
            .format('YYYY-MM-DDTHH:mm:ss.SSS[Z]')
        ]
      })
    }
  };

  return (
    <CreateUpdateContainer
      fields={fields}
      loadingFields={isFieldsLoading}
      purpose={purpose}
      baseUrl="quittances"
      resource="quittances"
      config={config}
      setFormValues={setFormValues}
      needFormDependencies
    />
  );
};

CreateUpdateQuittance.propTypes = {
  purpose: PropTypes.string
};

CreateUpdateQuittance.defaultProps = {
  purpose: 'create'
};
