/**
 * Validates whether a given string is a valid BIC (Bank Identifier Code).
 *
 * The BIC must adhere to the following format:
 * - 4 uppercase letters (institution code)
 * - 2 uppercase letters (country code)
 * - 2 alphanumeric characters (location code)
 * - An optional 3 alphanumeric characters (branch code)
 *
 * Example of a valid BIC: `BNPAFRPPXXX`
 *
 * @function
 * @param {string} bic - The BIC string to validate.
 * @returns {boolean} Returns `true` if the BIC is valid, otherwise `false`.
 *
 * @example
 * isValidBIC('BNPAFRPPXXX'); // Returns: true
 * isValidBIC('BNPAFRP');     // Returns: false
 */
export const isValidBIC = (bic) =>
  /^[A-Z]{4}[A-Z]{2}[A-Z0-9]{2}([A-Z0-9]{3})?$/.test(bic);

/**
 * Validates whether a given string is a valid French RIB (Relevé d'Identité Bancaire).
 *
 * The RIB must consist of the following:
 * - 5 digits for the bank code
 * - 5 digits for the branch code
 * - 11 digits for the account number
 * - 2 digits for the RIB key
 *
 * Example of a valid RIB: `1234512345123456789012`
 *
 * @function
 * @param {string} rib - The RIB string to validate.
 * @returns {boolean} Returns `true` if the RIB is valid, otherwise `false`.
 *
 * @example
 * isValidRIB('1234512345123456789012'); // Returns: true
 * isValidRIB('12345');                  // Returns: false
 */
export const isValidRIB = (rib) =>
  /^[0-9]{5}[0-9]{5}[A-Za-z0-9]{11}[0-9]{2}$/.test(rib);
