import PropTypes from 'prop-types';
import { Card, Empty, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import { useListContent } from './listContent';
import { DescriptionList } from '../../components';

/**
 * Component for displaying the quality control cards.
 *
 * @component
 * @param {Array} qualityControls - The quality controls to display
 * @returns {JSX.Element} The quality control cards component
 */
export const QualityControlsCards = ({ qualityControls }) => {
  const { t } = useTranslation();
  const listContent = useListContent(qualityControls);
  return (
    <Row gutter={[16, 16]} justify="center">
      {qualityControls.length ? (
        qualityControls.map((qualityControl) => (
          <Card
            title={t(
              `interventions.form.quality_control.categories.${qualityControl.category}`
            )}
            key={qualityControl?._id}
          >
            <DescriptionList
              data={listContent.filter(
                (f) => f.category === qualityControl.category
              )}
              translate
              columns={24}
            />
          </Card>
        ))
      ) : (
        <Empty />
      )}
    </Row>
  );
};

QualityControlsCards.propTypes = {
  qualityControls: PropTypes.arrayOf(PropTypes.shape({}))
};

QualityControlsCards.defaultProps = {
  qualityControls: []
};
