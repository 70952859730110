import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Row, Card, Flex, Col, Typography, Tag } from 'antd';
import { useParams } from 'react-router-dom';
import {
  ContentCustom,
  DescriptionList,
  PageHeaderCustom
} from '../../components/index';
import { useErrorMessage } from '../../utils/errorMessage';
import useCustomerServiceContext from './CustomerServiceContext';
import { customerServicesColor } from '../../utils/constants/tagColors';
import { formattedContent } from '../../utils/constants/modulesReactQuill';
import { getListContent } from './getListContent';
import { useAuthContext } from '../../contexts/AuthContext';
import { FilesManagement } from '../../components/FilesManagement';
import { ShowHistoric } from './ShowHistoric';
import { ExtraButtons } from './ExtraButtons';
import { CustomSpin } from '../../components/CustomSpin/CustomSpin';

/**
 * ShowCustomerService component is used to display the customer service details.
 *
 * @component
 * @returns {React.Component} - The ShowCustomerService component
 */
const ShowCustomerService = () => {
  const { user } = useAuthContext();
  const { id } = useParams();
  const { t } = useTranslation();
  const [listContent, setListContent] = useState([]);
  const { message } = useErrorMessage();
  const { getDataFromId, addSeen, refresh } = useCustomerServiceContext();
  const [customerService, setCustomerService] = useState(null);

  const getData = async () => {
    try {
      const data = await getDataFromId(id);
      if (data) {
        setCustomerService(data);
        setListContent(getListContent(t, data));
        if (!data?.read_by?.includes(user._id)) {
          await addSeen(id);
        }
      }
    } catch (e) {
      message(e);
    }
  };

  useEffect(() => {
    (async () => {
      await getData();
    })();
  }, [refresh]);

  return !customerService ? (
    <ContentCustom>
      <CustomSpin />
    </ContentCustom>
  ) : (
    <ContentCustom>
      <PageHeaderCustom
        title={
          <Flex align="center" gap={8}>
            {t(`customerServices.labels.title`)}
            {customerService?.name}
            <Tag color={customerServicesColor[customerService?.status]}>
              {t(`customerServices.form.enums.${customerService?.status}`)}
            </Tag>
          </Flex>
        }
        extra={
          <ExtraButtons
            customerService={customerService}
            title={customerService?.status}
            user={user}
          />
        }
      />
      <Row gutter={[24, 24]}>
        <Col span={24}>
          <Card title={t('customerServices.labels.information')}>
            <DescriptionList data={listContent || []} translate columns={20} />
          </Card>
        </Col>
        <Col xs={24} xl={12}>
          <Card title={t('customerServices.labels.description')}>
            <Typography.Text>
              {formattedContent(customerService?.description)}
            </Typography.Text>
          </Card>
        </Col>
        <Col xs={24} xl={12}>
          <Card title={t('customerServices.labels.comment')}>
            <Typography.Text>
              {customerService?.comments?.length
                ? (customerService?.comments || []).map((comment) => (
                    <p key={comment}>{formattedContent(comment.content)}</p>
                  ))
                : '-'}
            </Typography.Text>
          </Card>
        </Col>
        <ShowHistoric data={customerService} />
        <Col xs={24} xl={12}>
          <Card title={t('customerServices.labels.files')}>
            <FilesManagement
              purpose="show"
              documents={customerService?.files || []}
              forceRefresh={refresh}
              showTitle={false}
            />
          </Card>
        </Col>
      </Row>
    </ContentCustom>
  );
};

export default ShowCustomerService;
