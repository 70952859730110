import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { ListResource } from '../../../components';
import { useCustomerServicesColumns } from './columns/customerServicesColumns';
import { customerServicesHeaders } from './headers/customerServicesHeaders';
import { useFormatter } from '../../../utils/formatter';
import { useAuthContext } from '../../../contexts/AuthContext';
import { useErrorMessage } from '../../../utils/errorMessage';
import { showSuccessMessage } from '../../../utils/showSuccessMessage';

/**
 * Component for listing quotes with filtering options.
 *
 * @component
 * @returns {JSX.Element} Rendered ListQuotes component
 */
export const ListCustomerServices = ({ id }) => {
  const { formattedData } = useFormatter();
  const { dispatchAPI } = useAuthContext();
  const { t } = useTranslation();
  const { message } = useErrorMessage();
  const [customerServiceEnums, setCustomerServiceEnums] = useState({});
  const [users, setUsers] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [urgentCount, setUrgentCount] = useState();
  const [totalCount, setTotalCount] = useState();

  const changeColor = (rowStatus, rowDate) => {
    const currentDate = new Date();
    if (rowStatus === 'TO_PROCESSED') {
      if (rowDate < currentDate) {
        return 'red';
      }
      return 'green';
    }
    return '';
  };
  const getCustomerServiceEnums = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/customerservices/enums`
      });
      setCustomerServiceEnums(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };
  const deleteResource = async (idrsc) => {
    try {
      await dispatchAPI('DELETE', { url: `/customerservices/${idrsc}` });
      setRefresh(true);
      showSuccessMessage(t, 'customerservices', 'archive');
    } catch (e) {
      message(e);
    }
  };
  const getCustomerServices = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/customerservices?customer=${id}`
      });
      setTotalCount(data.length);
      let num = 0;
      data.forEach((item) => {
        if (item.status === 'TO_PROCESSED') {
          num += 1;
        }
      });
      setUrgentCount(num);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getUsers = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: '/users'
      });
      setUsers(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const columns = useCustomerServicesColumns(
    changeColor,
    urgentCount,
    totalCount,
    customerServiceEnums,
    users,
    deleteResource
  );

  useEffect(() => {
    (async () => {
      await getCustomerServices();
      await getCustomerServiceEnums();
      await getUsers();
    })();
  }, []);
  return (
    <ListResource
      resourceName="customerServices"
      contentCustomStyle={{ margin: 0, padding: 0 }}
      columns={columns}
      headers={customerServicesHeaders}
      onDoubleClickAction={false}
      customActionColumn
      forceRefresh={refresh}
      resourceModelName="CustomerServices"
      formatter={formattedData}
      withImportButton={false}
      withCreateButton={false}
      withUploadButton={false}
      populate="created_by,recipients,customer.macro_ps micro_ps leader"
      extraSorter="-completion_date"
      extraQuery={`customer=${id}`}
      extra
    />
  );
};

ListCustomerServices.propTypes = {
  id: PropTypes.string.isRequired
};
