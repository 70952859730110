import { Input, Select } from 'antd';
import { useTranslation } from 'react-i18next';
import useCustomerContext from '../CustomerContext';
import { selectFormat } from './utils';

const { Option } = Select;

/**
 * useFields is a custom hook that provides an array of field configurations for customer data input.
 *
 * @hook
 * @returns {Array<Object>} An array of field configurations.
 */

const useFields = () => {
  const { enums, businessIntroducer } = useCustomerContext();
  const { t } = useTranslation();

  return [
    {
      name: ['siret_number'],
      key: 'siret_number',
      input: <Input />
    },
    {
      name: ['business_introducer'],
      key: 'business_introducer',
      input: (
        <Select
          allowClear
          showSearch
          filterOption={(input, option) => onsearch(input, option)}
        >
          {Array.isArray(businessIntroducer) &&
            businessIntroducer.map((introducer) => (
              <Option key={introducer._id} value={introducer._id}>
                {introducer.title}
              </Option>
            ))}
        </Select>
      )
    },
    {
      name: ['business_introducer_id'],
      key: 'business_introducer_id',
      input: <Input />
    },
    {
      name: ['used_software'],
      key: 'used_software',
      rules: [{ required: true }],
      input: selectFormat(t, enums?.used_software, {
        optionsHaveID: true,
        optionsKey: 'title'
      })
    },
    {
      name: ['invoice_per_month'],
      key: 'invoice_per_month',
      input: <Input />
    },
    {
      name: ['rejection_rate'],
      key: 'rejection_rate',
      input: <Input />
    },
    {
      name: ['outstanding_third_party_number'],
      key: 'outstanding_third_party_number',
      input: <Input />
    },
    {
      name: ['third_party_payment_turnover'],
      key: 'third_party_payment_turnover',
      input: <Input />
    },
    {
      name: ['global_turnover'],
      key: 'global_turnover',
      input: <Input />
    },
    {
      name: ['link_to_opportunity'],
      key: 'link_to_opportunity',
      input: <Input />
    }
  ];
};

export default useFields;
