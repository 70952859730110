import { useState, useEffect, useCallback } from 'react';
import { Select, DatePicker } from 'antd';
import dayjs from 'dayjs';
import { useErrorMessage } from '../../../utils/errorMessage';
import { useAuthContext } from '../../../contexts/AuthContext';

const { Option } = Select;

const useFieldsStatus = (buttonClicked) => {
  const [isFieldsLoading, setIsFieldsLoading] = useState(true);
  const { dispatchAPI } = useAuthContext();
  const [templates, setTemplates] = useState([]);
  const { message } = useErrorMessage();

  const onsearch = (input, option) => {
    const result = option?.children.toLowerCase();
    return result.includes(input.toLowerCase());
  };

  const getTemplates = useCallback(async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/template-documents?status=VALIDATED&use_case=INVOICE`
      });
      const templatesInfo = data.map((item) => ({
        id: item._id,
        title: item.title
      }));
      setTemplates(templatesInfo);
    } catch (e) {
      message(e);
    }
  }, []);

  useEffect(() => {
    (async () => {
      await getTemplates();
      setIsFieldsLoading(false);
    })();
  }, []);

  const fields = [
    {
      name: ['use_case'],
      rules: [{ required: true }],
      tooltip: true,
      input: (
        <Select
          loading={isFieldsLoading}
          allowClear
          showSearch
          filterOption={(input, option) => onsearch(input, option)}
        >
          {templates?.map((item) => (
            <Option key={item.id} value={item.id}>
              {item.title}
            </Option>
          ))}
        </Select>
      )
    },
    {
      name: ['status'],
      initialValue: 'NOT_PAID',
      rules: [{ required: true }],
      hidden: true
    },
    {
      name: ['validation_date'],
      initialValue: dayjs(),
      rules: [{ required: true }],
      hidden: true,
      input: <DatePicker format="DD-MM-YYYY" />
    },
    {
      name: ['button_clicked'],
      initialValue: buttonClicked,
      hidden: true
    }
  ];

  return {
    fields,
    isFieldsLoading
  };
};

export default useFieldsStatus;
