import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { Tag, Divider, Popconfirm, Popover } from 'antd';

import {
  ContainerOutlined,
  EditOutlined,
  EyeOutlined,
  WarningOutlined
} from '@ant-design/icons';
import { Link, useLocation } from 'react-router-dom';
import { customerServicesColor } from '../../../../utils/constants/tagColors';
import { useAuthContext } from '../../../../contexts/AuthContext';
import { isAuthorized } from '../../../../utils/constants/authorizedMenu';
import { formatValue } from '../../../../utils/formatNumber';

export const useCustomerServicesColumns = (
  changeColor,
  urgentCount,
  totalCount,
  customerServiceEnums,
  users,
  deleteResource
) => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const { user: userconnected } = useAuthContext();
  const hasAccess = (purpose) =>
    isAuthorized(pathname, userconnected?.role, purpose);

  const iconSize = 18;

  return [
    {
      title: (
        <div>
          {t('customerServices.columns.status')}
          <Popover content={t('customerServices.columns.total')}>
            <Tag>{totalCount}</Tag>
          </Popover>
          <Popover content={t(`customerServices.columns.enums.TO_PROCESSED`)}>
            <Tag color="red">{urgentCount}</Tag>
          </Popover>
        </div>
      ),
      key: 'status',
      dataIndex: ['status'],
      render: (status) => (
        <Tag color={customerServicesColor[status]}>
          {t(`customerServices.columns.enums.${status || 'N/A'}`, {
            defaultValue: status
          })}
        </Tag>
      ),
      sorter: true
    },
    {
      title: t('customerServices.columns.created_by'),
      key: 'created_by',
      dataIndex: 'created_by',
      render: (user) =>
        user && user.first_name
          ? `${user.first_name} ${user?.usage_last_name || user.last_name}`
          : '-',
      sorter: true,
      filters: (users || []).map((user) => ({
        text: `${user?.first_name} ${user?.usage_last_name || user?.last_name}`,
        value: user._id
      }))
    },
    {
      title: t('customerServices.columns.recipients'),
      key: 'recipients',
      dataIndex: ['recipients'],
      render: (recipients) => (
        <Popover
          content={(recipients || []).map((recipient) => (
            <div style={{ display: 'flex', alignItems: 'baseline' }}>
              <p
                key={recipient._id}
                style={{ margin: '0', marginRight: '10px' }}
              >
                {recipient?.first_name}{' '}
                {recipient?.usage_last_name || recipient?.last_name}
              </p>
            </div>
          ))}
          title={t('customerServices.columns.recipients')}
        >
          <EyeOutlined style={{ fontSize: 18 }} />
        </Popover>
      ),
      sorter: true
    },
    {
      title: t('customerServices.columns.completion_date'),
      key: 'completion_date',
      render: (currentRow) => (
        <Tag
          color={changeColor(
            currentRow.status,
            dayjs(currentRow.completion_date)
          )}
        >
          {currentRow.completion_date &&
            formatValue(currentRow.completion_date, 'date')}
        </Tag>
      ),
      sorter: true
    },
    {
      title: t('customerServices.columns.origin'),
      key: 'origin',
      dataIndex: ['origin'],
      render: (origin) =>
        t(`customerServices.columns.enums.${origin || 'N/A'}`, {
          defaultValue: origin
        }),
      sorter: true,
      filters: customerServiceEnums?.origins?.map((p) => ({
        text: t(`customerServices.columns.enums.${p}`),
        value: p
      }))
    },
    {
      title: t('customerServices.columns.recorded_time'),
      key: 'recorded_time',
      dataIndex: ['recorded_time'],
      render: (recorded_time) =>
        t(`customerServices.columns.enums.${recorded_time || 'N/A'}`, {
          defaultValue: recorded_time
        }),
      sorter: true
    },
    {
      key: 'action',
      align: 'right',
      fixed: 'right',
      render: (record) => (
        <>
          <Divider type="vertical" />
          <Link to={`/customerServices/show/${record?._id}`}>
            <EyeOutlined style={{ fontSize: iconSize }} />
          </Link>
          <Divider type="vertical" />
          {hasAccess('edit') && (
            <Link to={`/customerServices/edit/${record?._id}`}>
              <EditOutlined style={{ fontSize: iconSize }} />
            </Link>
          )}
          {hasAccess('archive') && record?.status !== 'TO_PROCESSED' ? (
            <>
              <Divider type="vertical" />
              <Popconfirm
                title={t('datatable.column.action.delete.title')}
                okText={t('datatable.column.action.delete.ok')}
                okButtonProps={{ type: 'danger' }}
                cancelText={t('datatable.column.action.delete.cancel')}
                onConfirm={() => deleteResource(record?._id)}
                icon={<WarningOutlined />}
              >
                <ContainerOutlined
                  style={{ color: '#b51010', fontSize: iconSize }}
                  type="delete"
                />
              </Popconfirm>
            </>
          ) : null}
        </>
      )
    }
  ];
};
