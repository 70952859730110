import { Drawer, Typography, Input, Checkbox, Form } from 'antd';
import { RedoOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import useCustomerContext from '../CustomerContext';
import { useAuthContext } from '../../../contexts/AuthContext';
import { selectFormat } from '../CRUDFields/utils';
import { phoneNumberField } from '../../../utils/phoneNumberField';
import { CreateUpdateContainer } from '../../../components';
import { generateUniqueFakeMail } from '../../../utils/generateUniqueFakeMail';

/**
 * Component for rendering a drawer to add or update customer contact information.
 *
 * @component
 * @param {object} props - React component props
 * @param {string} props.purpose - Purpose of the contact form (e.g., 'add', 'edit')
 * @param {boolean} props.open - Whether the drawer is open or not
 * @param {Function} props.onClose - Function to close the drawer
 * @param {object} props.enums - Enumerated values for various options (like genders)
 * @param {string} props.id - ID of the customer associated with the contact
 * @param {string} props.itemId - ID of the contact item (if editing)
 * @returns {React.ReactNode} The contact drawer UI
 */

export const ContactDrawer = ({
  purpose,
  open,
  onClose,
  enums,
  id,
  itemId
}) => {
  const { t } = useTranslation();
  const { dispatchAPI } = useAuthContext();
  const { addContact } = useCustomerContext();
  const [form] = Form.useForm();
  const generateMail = async () => {
    const email = await generateUniqueFakeMail(dispatchAPI);
    form.setFields([
      {
        name: 'email',
        value: email
      }
    ]);
  };

  const fields = [
    {
      name: ['employment_role'],
      key: 'employment_role',
      input: selectFormat(
        t,
        enums?.employment_roles?.map((role) => ({
          _id: role,
          name: `users.employment_roles.${role}`
        })),
        { optionsHaveID: true, optionsKey: 'name' }
      ),
      rules: [{ required: true }]
    },
    {
      name: ['gender'],
      key: 'gender',
      input: selectFormat(
        t,
        enums?.genders?.map((gender) => ({
          _id: gender,
          name: `users.genders.${gender}`
        })),
        { optionsHaveID: true, optionsKey: 'name' }
      ),
      rules: [{ required: true }]
    },
    {
      name: ['last_name'],
      key: 'last_name',
      input: <Input />,
      rules: [{ required: true }]
    },
    {
      name: ['first_name'],
      key: 'first_name',
      input: <Input />,
      rules: [{ required: true }]
    },
    {
      name: ['email'],
      key: 'email',
      input: (
        <Input.Group compact>
          <Form.Item noStyle name={['email']}>
            <Input />
          </Form.Item>
          <Form.Item noStyle>
            <RedoOutlined onClick={() => generateMail()} />
          </Form.Item>
        </Input.Group>
      ),
      rules: [{ required: true }]
    },
    phoneNumberField,
    {
      name: ['report'],
      key: 'report',
      input: <Checkbox />,
      valuePropName: 'checked'
    }
  ];

  const handleClose = () => {
    onClose();
  };

  const handleSubmit = async (values) => {
    await addContact(purpose, id, values, itemId);
    handleClose();
  };

  return (
    <Drawer
      title={
        <Typography.Text
          style={{
            fontWeight: 300,
            fontSize: '1rem',
            color: 'var(--userColor)'
          }}
        >
          {t('customers.add.contact')}
        </Typography.Text>
      }
      width={700}
      open={open}
      onClose={handleClose}
      destroyOnClose
    >
      <CreateUpdateContainer
        layout="vertical"
        fields={fields}
        purpose={purpose}
        baseUrl="contacts"
        resource="contacts"
        withHeader={false}
        customSubmit={handleSubmit}
        isOverlay
        customNavigate={onClose}
        idFromOverlay={itemId}
        customFormInstance={form}
      />
    </Drawer>
  );
};

ContactDrawer.propTypes = {
  purpose: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  enums: PropTypes.arrayOf(PropTypes.string).isRequired,
  id: PropTypes.string.isRequired,
  itemId: PropTypes.string
};

ContactDrawer.defaultProps = {
  itemId: null
};
