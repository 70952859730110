import { Input, DatePicker, Select, Form } from 'antd';
import { useTranslation } from 'react-i18next';
import IBAN from 'iban';
import useCustomerContext from '../CustomerContext';
import { selectFormat } from './utils';
import { FilesManagement } from '../../../components/FilesManagement';
import { isValidRIB } from '../../../utils/RIBBICValidator';

const { RangePicker } = DatePicker;
const { Option } = Select;

const months = [
  '01',
  '02',
  '03',
  '04',
  '05',
  '06',
  '07',
  '08',
  '09',
  '10',
  '11',
  '12'
];
/**
 * useFields is a custom hook that provides an array of field configurations for customer data input.
 *
 * @hook
 * @returns {Array<Object>} An array of field configurations.
 */

const useFields = (
  pupose,
  customer,
  refresh,
  setRefresh,
  setFiles,
  setInfoSheet,
  selectedMonth,
  availableDays,
  selectedDay,
  setSelectedDay,
  handleMonthChange
) => {
  const { enums, businessIntroducer } = useCustomerContext();
  const { t } = useTranslation();
  const format = 'DD-MM-YYYY';

  const onsearch = (input, option) => {
    const result = option?.children.toLowerCase();
    return result.includes(input.toLowerCase());
  };

  return [
    {
      name: ['date_of_birth'],
      key: 'date_of_birth',
      input: <DatePicker format={format} />
    },
    {
      name: ['installation_date'],
      key: 'installation_date',
      input: <DatePicker format={format} />
    },
    {
      name: ['optimization_date'],
      key: 'optimization_date',
      input: <DatePicker format={format} />
    },
    {
      name: ['business_introducer'],
      key: 'business_introducer',
      input: (
        <Select
          allowClear
          showSearch
          filterOption={(input, option) => onsearch(input, option)}
        >
          {Array.isArray(businessIntroducer) &&
            businessIntroducer.map((introducer) => (
              <Option key={introducer._id} value={introducer._id}>
                {introducer.title}
              </Option>
            ))}
        </Select>
      )
    },
    {
      name: ['business_introducer_id'],
      key: 'business_introducer_id',
      input: <Input />
    },
    {
      name: ['sales'],
      key: 'sales',
      input: selectFormat(t, enums?.sales, {
        optionsHaveID: true,
        render: (option) =>
          `${option?.first_name} ${
            option?.usage_last_name || option?.last_name
          }`
      })
    },
    {
      name: ['leader'],
      key: 'leader',
      input: selectFormat(t, enums?.leaders, {
        optionsHaveID: true,
        render: (option) =>
          `${option?.first_name} ${
            option?.usage_last_name || option?.last_name
          }`
      }),
      rules: [{ required: true }]
    },
    {
      name: ['financial_statement'],
      key: 'financial_statement',
      input: (
        <Input.Group compact>
          <Form.Item
            key={['financial_statement', 'month']}
            name={['financial_statement', 'month']}
            initialValue={selectedMonth}
          >
            <Select
              style={{ width: 200 }}
              onChange={handleMonthChange}
              value={selectedMonth}
            >
              {months.map((month) => (
                <Option key={month} value={month}>
                  {t(`reconciliationStatements.months.${month}`)}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            key={['financial_statement', 'day']}
            name={['financial_statement', 'day']}
            initialValue={selectedDay}
          >
            <Select
              style={{ width: 80 }}
              value={selectedDay}
              onChange={setSelectedDay}
              disabled={!selectedMonth}
            >
              {availableDays.map((day) => (
                <Option key={day} value={day}>
                  {day}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Input.Group>
      )
    },
    {
      name: ['accountant'],
      key: 'accountant'
    },
    {
      name: ['accountant_mail'],
      key: 'accountantMail',
      rules: [{ required: false }, { type: 'email' }]
    },
    {
      name: ['siret_number'],
      key: 'siret_number',
      initialValue: '99999999999999',
      rules: [{ required: true, len: 14 }]
    },
    {
      name: ['iban_tp'],
      key: 'iban_tp',
      hasFeedback: IBAN,
      rules: [
        () => ({
          validator(_, value) {
            if (IBAN.isValid(value) || !value) return Promise.resolve();
            return Promise.reject(new Error('IBAN invalide'));
          }
        })
      ]
    },
    {
      name: ['rib_tp'],
      key: 'rib_tp',
      rules: [
        () => ({
          validator(_, value) {
            if (isValidRIB(value) || !value) return Promise.resolve();
            return Promise.reject(new Error('RIB invalide'));
          }
        })
      ]
    },
    {
      name: ['start_activity_date'],
      key: 'start_activity_date',
      input: <DatePicker format={format} />
    },
    {
      name: ['generic_product_producer'],
      key: 'generic_product_producer'
    },
    {
      name: ['distributor'],
      key: 'distributor'
    },
    {
      name: ['invoice_per_month'],
      key: 'invoice_per_month',
      input: <Input type="number" />
    },
    {
      name: ['third_party_payment_turnover'],
      key: 'third_party_payment_turnover',
      input: <Input type="number" />
    },
    {
      name: ['global_turnover'],
      key: 'global_turnover',
      input: <Input type="number" />
    },
    {
      name: ['third_party_payment_percent'],
      key: 'third_party_payment_percent',
      input: <Input type="number" />
    },
    {
      name: ['target_rejection_rate'],
      key: 'target_rejection_rate',
      input: <Input type="number" />
    },
    {
      name: ['outstanding_balance_target'],
      key: 'outstanding_balance_target',
      input: <Input type="number" />
    },
    {
      name: ['initial_balance'],
      key: 'initial_balance',
      input: <Input type="number" />
    },
    {
      name: ['ongoing_startup_phase'],
      key: 'ongoing_startup_phase',
      input: <RangePicker format={format} />
    },
    {
      name: ['start_of_balance_tracking'],
      key: 'start_of_balance_tracking',
      input: <DatePicker format={format} />
    },
    {
      name: ['info_sheet'],
      key: 'info_sheet',
      input: (
        <FilesManagement
          purpose={pupose}
          documents={customer?.info_sheet || []}
          setForceRefresh={setRefresh}
          forceRefresh={refresh}
          uploadURL="/customers/upload"
          setData={setInfoSheet}
          modelKey="info_sheet"
        />
      )
    },
    {
      name: ['files'],
      key: 'files',
      input: (
        <FilesManagement
          purpose={pupose}
          documents={customer?.files || []}
          setForceRefresh={setRefresh}
          forceRefresh={refresh}
          uploadURL="/customers/upload"
          setData={setFiles}
          modelKey="files"
        />
      )
    }
  ];
};

export default useFields;
