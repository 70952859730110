import { useState } from 'react';
import { Col, Card, Button } from 'antd';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useAuthContext } from '../../../contexts/AuthContext';
import { useErrorMessage } from '../../../utils/errorMessage';
import { Datatable } from '../../../components/DataTable/Datatable';
import { useColumns } from './detailsColumns';
import { AddIcon } from '../../../utils/constants/customIcons';
import DetailModal from './DetailModal';
import { showSuccessMessage } from '../../../utils/showSuccessMessage';

/**
 * Component for displaying quittance details with a Table.
 *
 * @component
 * @param {object} props - Component props
 * @param {object} props.data - Quittance data containing details
 * @returns {JSX.Element} Component JSX
 */

export const Details = ({
  data,
  customer,
  quittance,
  forceRefresh,
  setForceRefresh
}) => {
  const { t } = useTranslation();
  const { dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [purpose, setPurpose] = useState(null);
  const [detailToEdit, setDetailToEdit] = useState(null);

  const openModal = () => {
    setPurpose('create');
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setPurpose(null);
    setIsModalOpen(false);
    setDetailToEdit(null);
  };

  const customSubmit = () => {
    setPurpose(null);
    setIsModalOpen(false);
    setDetailToEdit(null);
    setForceRefresh(!forceRefresh);
  };

  const onEdit = (record) => {
    setPurpose('edit');
    setDetailToEdit(record);
    setIsModalOpen(true);
  };

  const onArchived = async (id) => {
    try {
      await dispatchAPI('DELETE', {
        url: `/quittances/detail/${id}?quittanceId=${quittance?._id}`
      });
      customSubmit();
      showSuccessMessage(t, 'quittances', 'update');
    } catch (e) {
      message(e);
    }
  };

  const columns = useColumns({ onEdit, onArchived }, quittance?.status);

  return (
    <>
      {isModalOpen && (
        <DetailModal
          isModalOpen={isModalOpen}
          closeModal={closeModal}
          detailToEdit={detailToEdit}
          purpose={purpose}
          customer={customer}
          quittanceId={quittance?._id}
          customSubmit={customSubmit}
        />
      )}
      <Col span={24}>
        <Card title={t('quittances.show.titles.details')}>
          {quittance?.status === 'IN_PROGRESS' && (
            <Button type="primary" onClick={openModal}>
              {`${t('buttons.create')}`} <AddIcon />
            </Button>
          )}
          {data?.length && (
            <Datatable
              resourceName="quittances"
              columns={columns}
              withCustomData
              datas={data}
              deleteAction={false}
              editAction={false}
              showAction={false}
              scroll={{ x: true }}
            />
          )}
        </Card>
      </Col>
    </>
  );
};

Details.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({})),
  customer: PropTypes.shape({}),
  quittance: PropTypes.shape({
    status: PropTypes.string,
    _id: PropTypes.string
  }),
  forceRefresh: PropTypes.bool.isRequired,
  setForceRefresh: PropTypes.func.isRequired
};

Details.defaultProps = {
  data: [],
  customer: null,
  quittance: null
};
