import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { Col, Row, Typography } from 'antd';
import { cardRenderer } from './ShowCustomer/CardRenderer';
import { DescriptionList } from '../../components/DescriptionList/DescriptionList';
import useGeneralInfos from './ShowCustomer/GeneralInfos';
import useComplementaryInfos from './ShowCustomer/ComplementaryInfos';
import useCustomerContext from './CustomerContext';
import { useContactContent } from './ShowCustomer/Identification';
import { FilesManagement } from '../../components/FilesManagement';
import { ContactDrawer } from './ShowCustomer/ContactDrawer';

/**
 * Component for displaying detailed information about a prospect.
 * @component
 * @returns {JSX.Element} JSX element representing the component
 */
export const ShowProspect = () => {
  const { id } = useParams();
  const { t } = useTranslation();
  const { customer, setRefresh, refresh, enums, changeContactsStatus } =
    useCustomerContext();
  const [isDrawerVisible, setIsDrawerVisible] = useState(false);
  const [purpose, setPurpose] = useState('create');
  const [itemId, setItemId] = useState(null);

  const generalInfos = useGeneralInfos(customer, t || {});
  const complementaryInfos = useComplementaryInfos(customer || {});

  const handleArchive = async (contactId) => {
    await changeContactsStatus(contactId, 'INACTIVE');
    setRefresh(!refresh);
  };

  return (
    <>
      {isDrawerVisible && (
        <ContactDrawer
          purpose={purpose}
          open={isDrawerVisible}
          onClose={() => {
            setItemId(null);
            setIsDrawerVisible(false);
          }}
          enums={enums}
          id={id}
          itemId={itemId}
        />
      )}
      {cardRenderer(
        <DescriptionList data={generalInfos || []} translate columns={20} />,
        'var(--userColor)',
        t('customers.prospect.titles.general_infos'),
        `Type PS Macro: ${customer?.macro_ps?.title || ''}`
      )}
      <br />
      {cardRenderer(
        <DescriptionList
          data={complementaryInfos || []}
          translate
          columns={20}
        />,
        'var(--userColor)',
        t('customers.prospect.titles.complementary_infos')
      )}
      <br />
      {cardRenderer(
        useContactContent(
          customer?.contacts?.filter(
            (contact) => contact.status === 'ACTIVE'
          ) || [],
          setIsDrawerVisible,
          setPurpose,
          setItemId,
          handleArchive
        ),
        'var(--userColor)',
        t('customers.form.contacts')
      )}
      <br />
      <Row justify="space-between">
        <Col span={12}>
          {cardRenderer(
            <FilesManagement
              purpose="edit"
              documents={customer?.files || []}
              setForceRefresh={setRefresh}
              forceRefresh={refresh}
              uploadURL="/customers/upload"
              modelKey="files"
              showTitle={false}
            />,
            'var(--userColor)',
            t('customers.form.files')
          )}
        </Col>
        <Col span={11}>
          {cardRenderer(
            <Typography.Text>
              {customer?.prospect_comment || '-'}
            </Typography.Text>,
            'var(--userColor)',
            t('customers.form.prospect_comment')
          )}
        </Col>
      </Row>
    </>
  );
};
