import PropTypes from 'prop-types';
import { Descriptions, Typography, Col } from 'antd';
import { useTranslation } from 'react-i18next';

export const DescriptionList = ({
  title,
  data,
  skipEmpty,
  translate,
  columns
}) => {
  const { t } = useTranslation();
  return (
    <div>
      <Descriptions title={title} column={columns}>
        {data.map(
          (item) =>
            (item.content !== '-' || !skipEmpty) && (
              <Descriptions.Item span={item.span || 1} key={item.label}>
                <Col>
                  {!item.noLabel && (
                    <Typography>
                      {item.label !== ' ' &&
                        `${translate ? t(`${item.label}`) : item.label}${
                          item.v ? ` ${item.v}` : ''
                        } :`}
                    </Typography>
                  )}
                  <Typography style={{ fontWeight: 700 }}>
                    {item.content || '-'}
                  </Typography>
                </Col>
              </Descriptions.Item>
            )
        )}
      </Descriptions>
    </div>
  );
};

DescriptionList.propTypes = {
  title: PropTypes.string,
  data: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  skipEmpty: PropTypes.bool,
  translate: PropTypes.bool,
  columns: PropTypes.number
};

DescriptionList.defaultProps = {
  title: null,
  skipEmpty: false,
  translate: false,
  columns: 3
};
