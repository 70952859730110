import { Tag, Flex } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import useCustomerContext from '../CustomerContext';
import { formatValue } from '../../../utils/formatNumber';

/**
 * useBillingContent is a custom hook that generates an array of content configurations for displaying billing-info customer data.
 *
 * @hook
 * @param {Object} data - Customer data object containing billing informations.
 * @param {Function} openOverPaymentModal - Function to open the modal for handling overpayment.
 * @returns {Array<Object>} An array of content configurations for displaying identification-related customer data.
 */
export const useBillingContent = (data, openOverPaymentModal) => {
  const { t } = useTranslation();
  const {
    payment_type,
    bank_reconciliation,
    rib,
    social_reason,
    vat,
    electronic_invoice,
    email_invoice,
    billing_address,
    overPayment
  } = data;
  const { hasAccess } = useCustomerContext();

  return [
    {
      label: 'customers.form.payment_type',
      span: 5,
      content: payment_type ? t(`quittances.form.enums.${payment_type}`) : '-'
    },
    {
      label: 'customers.form.bank_reconciliation',
      span: 15,
      content: bank_reconciliation?.name ? bank_reconciliation?.name : '-'
    },
    {
      label: 'customers.form.iban_customer',
      span: 5,
      content: rib?.iban ? formatValue(rib?.iban, 'iban') : '-'
    },
    {
      label: 'customers.form.bic_customer',
      span: 5,
      content: rib?.bic ? rib?.bic : '-'
    },
    {
      label: 'customers.form.rum_customer',
      span: 5,
      content: rib?.rum ? rib?.rum : '-'
    },
    {
      label: 'customers.form.social_reason',
      span: 5,
      content: social_reason || '-'
    },
    {
      label: 'customers.form.vat',
      span: 5,
      content: vat ? `${vat}%` : '-'
    },
    {
      label: 'customers.form.electronic_invoice',
      span: 5,
      content: <Tag>{electronic_invoice ? 'Oui' : 'Non'}</Tag>
    },
    {
      label: 'customers.form.email_invoice',
      span: 5,
      content: email_invoice || '-'
    },
    {
      label: 'quittances.show.billing_address',
      span: 5,
      content: `${billing_address?.street || '-'}
      ${billing_address?.postal_code || '-'}  ${billing_address?.city || '-'}`
    },
    {
      label: 'customers.form.overPayment',
      span: 5,
      content: (
        <Flex>
          {overPayment ? `${formatValue(overPayment, 'currency', true)}` : '0'}
          {hasAccess('edit') && (
            <EditOutlined
              style={{ color: 'purple', marginLeft: 10 }}
              onClick={openOverPaymentModal}
            />
          )}
        </Flex>
      )
    }
  ];
};
