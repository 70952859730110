import { useCallback, useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import { Button, Row, Popconfirm, Tag } from 'antd';
import { useTranslation } from 'react-i18next';
import {
  PlusOutlined,
  WarningOutlined,
  RedoOutlined,
  EditOutlined,
  ContainerOutlined,
  DownloadOutlined
} from '@ant-design/icons';
import { useAuthContext } from '../../contexts/AuthContext';
import { useErrorMessage } from '../../utils/errorMessage';
import { ContentCustom, PageHeaderCustom } from '../../components';
import { CustomSpin } from '../../components/CustomSpin/CustomSpin';
import QuittanceContextProvider from './QuittanceContext';
import { Informations } from './ShowCards/Informations';
import { Details } from './ShowCards/Details';
import { Historic } from './ShowCards/Historic';
import { Comment } from './ShowCards/Comment';
import { ListPayments } from './ShowCards/ListPayments';
import { routes, subRoutes } from '../../utils/constants/adminRoutes';
import { quittanceStatus } from '../../utils/constants/tagColors';
import PaymentModal from './ShowCards/PaymentModal';
import StatusModal from './ShowCards/StatusModal';

/**
 * Component for displaying detailed information about a quittance.
 *
 * @component
 * @returns {JSX.Element} Rendered ShowQuittance component
 */
export const ShowQuittance = () => {
  const { id } = useParams();
  const { t } = useTranslation();
  const { dispatchAPI, user } = useAuthContext();
  const { message } = useErrorMessage();
  const {
    quittance,
    setQuittance,
    onArchived,
    forceRefresh,
    setForceRefresh,
    isModalStatusOpen,
    closeStatusModal,
    customNavigate,
    buttonClicked,
    setButtonClicked,
    openModalStatus,
    isModalOpen,
    setIsModalOpen,
    condition,
    setCondition,
    idFromOverlay
  } = QuittanceContextProvider();
  const [isLoading, setIsLoading] = useState(false);
  const [enums, setEnums] = useState();
  const [bankReconciliations, setBankReconciliations] = useState(null);
  const [purpose, setPurpose] = useState(null);
  const isAdmin = user.role.split(':')[0] === 'admins';

  const getQuittance = useCallback(async () => {
    setIsLoading(true);
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/quittances/${id}?populate=customer.bank_reconciliation,leader,sale,micro_ps,macro_ps,contract,bank_reconciliation,historic.author`
      });
      setQuittance(data);
    } catch (e) {
      message(e);
    }
  }, [id]);

  const getEnums = async () => {
    try {
      const { data } = await dispatchAPI('GET', { url: '/payments/enums' });
      setEnums(data);
    } catch (e) {
      message(e);
    }
  };

  const getBankReconciliation = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/bankreconciliations`
      });
      setBankReconciliations(data);
    } catch (e) {
      message(e);
    }
  };

  const openModal = (cond) => {
    setPurpose('create');
    setCondition(cond);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setPurpose(null);
    setCondition(null);
    setIsModalOpen(false);
  };

  useEffect(() => {
    (async () => {
      await getQuittance();
      await getEnums();
      await getBankReconciliation();
      setIsLoading(false);
    })();
  }, [getQuittance, forceRefresh]);

  return isLoading ? (
    <ContentCustom>
      <CustomSpin />
    </ContentCustom>
  ) : (
    <>
      {isModalOpen && (
        <PaymentModal
          isModalOpen={isModalOpen}
          closeModal={closeModal}
          customNavigate={customNavigate}
          purpose={purpose}
          quittance={quittance}
          isCreditModal
          enums={enums}
          bankReconciliations={bankReconciliations}
          condition={condition}
        />
      )}
      {isModalStatusOpen && (
        <StatusModal
          isModalOpen={isModalStatusOpen}
          closeModal={closeStatusModal}
          customNavigate={customNavigate}
          purpose="edit"
          buttonClicked={buttonClicked}
          idFromOverlay={idFromOverlay}
        />
      )}
      <ContentCustom>
        <PageHeaderCustom
          title={
            <>
              {`${t(`quittances.title`)} N° ${quittance?.code}`}
              <Tag
                color={quittanceStatus[quittance?.status]}
                style={{ marginLeft: 5 }}
              >
                {t(`quittances.form.enums.${quittance?.status}`)}
              </Tag>
            </>
          }
          extra={
            <>
              {isAdmin && quittance?.status === 'IN_PROGRESS' && (
                <>
                  <Button
                    type="primary"
                    onClick={() => {
                      openModalStatus();
                      setButtonClicked('statut');
                    }}
                  >
                    <RedoOutlined />
                    {t('buttons.status')}
                  </Button>
                  <Link
                    to={`${routes.BILLING}${subRoutes.BILLING.QUITTANCES}/edit/${quittance?._id}`}
                  >
                    <Button type="primary">
                      <EditOutlined />
                      {`${t('buttons.edit')} `}
                    </Button>
                  </Link>
                </>
              )}
              <Button
                type="primary"
                onClick={() => {
                  openModalStatus();
                  setButtonClicked('download');
                }}
              >
                <DownloadOutlined />
                {t('buttons.download')}
              </Button>
              <Button type="primary" onClick={() => openModal('CREDIT')}>
                <PlusOutlined />
                {t('buttons.payment')}
              </Button>
              {isAdmin && (
                <Popconfirm
                  title={t('datatable.column.action.delete.title')}
                  okText={t('datatable.column.action.delete.ok')}
                  okButtonProps={{ type: 'danger' }}
                  cancelText={t('datatable.column.action.delete.cancel')}
                  onConfirm={() => onArchived(quittance?._id)}
                  icon={<WarningOutlined />}
                >
                  <Button
                    type="link"
                    danger
                    icon={<ContainerOutlined size={24} />}
                  >
                    {t('news.buttons.archive')}
                  </Button>
                </Popconfirm>
              )}
            </>
          }
        />
        {!isLoading && (
          <Row gutter={[24, 24]}>
            <Informations data={quittance} />
            <Details
              data={quittance?.details}
              customer={quittance?.customer}
              quittance={quittance}
              forceRefresh={forceRefresh}
              setForceRefresh={setForceRefresh}
            />
            <ListPayments
              quittance={quittance}
              forceRefresh={forceRefresh}
              setForceRefresh={setForceRefresh}
            />
            <Historic data={quittance} />
            <Comment data={quittance?.comment} />
          </Row>
        )}
      </ContentCustom>
    </>
  );
};
