import { useTranslation } from 'react-i18next';
import { formatValue } from '../../../utils/formatNumber';

/**
 * Custom hook for generating administration content data for displaying user administration details in a list format.
 *
 * @hook
 * @param {object} data - User data object
 * @returns {Array} Array of objects containing label, span, and content for each field
 */

export const useListAdministrationContent = (data = {}) => {
  const { t } = useTranslation();

  const {
    last_name,
    usage_last_name,
    date_of_birth,
    phone_number,
    address,
    personal_email,
    social_security_number,
    iban,
    family_status,
    children,
    emergency_contact
  } = data;
  return [
    {
      label: 'users.form.last_name',
      span: 6,
      content: last_name || '-'
    },
    {
      label: 'users.form.usage_last_name',
      span: 6,
      content: usage_last_name || '-'
    },
    {
      label: 'users.form.date_of_birth',
      span: 6,
      content: date_of_birth ? formatValue(date_of_birth, 'date') : '-'
    },
    {
      label: 'users.form.phone_number_full',
      span: 6,
      content:
        phone_number && phone_number.number ? (
          <div>
            {formatValue(
              `${phone_number?.country_code || ''}${
                phone_number?.number || ''
              }`,
              'phone'
            )}
          </div>
        ) : (
          '-'
        )
    },
    {
      label: 'users.form.address.address_full',
      span: 6,
      content: address ? (
        <div>
          {`${address?.number || ''} ${address?.street || ''}, ${
            address?.city || ''
          }, ${address?.additional || ''}, ${address?.postal_code || ''}, ${
            address?.city || ''
          }`}
        </div>
      ) : (
        '-'
      )
    },
    {
      label: 'users.form.personal_email',
      span: 6,
      content: personal_email || '-'
    },
    {
      label: 'users.form.social_security_number',
      span: 6,
      content: social_security_number || '-'
    },
    {
      label: 'users.form.iban',
      span: 6,
      content: iban || '-'
    },
    {
      label: 'users.form.family_status',
      span: 6,
      content: t(`users.familyStatus.${family_status}`) || '-'
    },
    ...(children?.length
      ? children.flatMap((child, index) => [
          {
            label: `users.form.children_v.name`,
            v: index + 1,
            span: 6,
            content: child?.name || '-'
          },
          {
            label: `users.form.children_v.dob`,
            v: index + 1,
            span: 6,
            content: formatValue(child?.dob, 'date') || '-'
          }
        ])
      : []),
    {
      label: ' ',
      span: 6,
      content: `${t('users.form.emergency.title')} :`
    },
    {
      label: 'users.form.emergency.person',
      span: 6,
      content: emergency_contact
        ? `${emergency_contact?.first_name} ${emergency_contact?.last_name}`
        : '-'
    },
    {
      label: 'users.form.emergency.phone_number',
      span: 6,
      content:
        emergency_contact && emergency_contact?.phone_number?.number ? (
          <div>
            {formatValue(
              `${emergency_contact?.phone_number?.country_code || ''}${
                emergency_contact?.phone_number?.number || ''
              }`,
              'phone'
            )}
          </div>
        ) : (
          '-'
        )
    }
  ];
};
