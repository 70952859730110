import { Col, Row, Card } from 'antd';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { DescriptionList } from '../../../components';
import { useListAdministrationContent } from './listAdministrationContent';

/**
 * Component for displaying administration information of a user in a card format.
 *
 * @component
 * @param {object} props - Component props
 * @param {object} props.data - User data containing administration details
 * @param {number} props.span - Number of columns the card should span
 * @returns {JSX.Element} Component JSX
 */

export const ShowAdministrationInfos = ({ data }) => {
  const { t } = useTranslation();
  const listContent = useListAdministrationContent(data);
  return (
    <Col span="24">
      <Card title={t('users.show.administration')}>
        <Row align="middle">
          <Col span={24}>
            <DescriptionList data={listContent} translate columns={24} />
          </Col>
        </Row>
      </Card>
    </Col>
  );
};

ShowAdministrationInfos.propTypes = {
  data: PropTypes.shape({})
};

ShowAdministrationInfos.defaultProps = {
  data: undefined
};
