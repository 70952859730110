import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Button, message } from 'antd';
import { useTranslation } from 'react-i18next';
import { loginRequest } from '../../authConfig';
import { fileToBase64 } from '../users/principalFields';
import { useAuthContext } from '../../contexts/AuthContext';

/**
 * Component for rendering a Microsoft login button.
 *
 * @component
 * @param {object} props - React component props
 * @param {boolean} props.isLoading - A boolean to indicate if the button is in a loading state
 * @param {object} props.instance - The MSAL instance
 * @returns {React.ReactNode} The Microsoft login button UI
 */
const MicrosoftLogin = ({ isLoading, instance }) => {
  const { t } = useTranslation();
  const { dispatchAPI } = useAuthContext();

  const isAuthenticated = instance.getActiveAccount();

  const callMsGraph = async (token) => {
    const headers = new Headers();
    headers.append('Authorization', `Bearer ${token}`);

    const response = await fetch(`https://graph.microsoft.com/v1.0/me`, {
      method: 'GET',
      headers
    });

    return response.json();
  };

  const getPhoto = async (token) => {
    const headers = new Headers();
    headers.append('Authorization', `Bearer ${token}`);

    const photo = await fetch(
      'https://graph.microsoft.com/v1.0/me/photo/$value',
      {
        method: 'GET',
        headers
      }
    );

    if (photo.status === 404) {
      return null;
    }

    return photo.blob();
  };

  useEffect(async () => {
    if (isAuthenticated) {
      try {
        const response = await instance.acquireTokenSilent({
          ...loginRequest,
          account: isAuthenticated
        });

        const userData = await callMsGraph(response.accessToken);
        const photo = await getPhoto(response.accessToken);
        const length = response.account.name?.split(' ')?.length;
        if (Object.keys(response).length > 1 && length > 1) {
          const first_name = response.account.name
            .split(' ')
            .slice(0, length - 1)
            .join(' ');
          const last_name = response.account.name.split(' ')[length - 1];
          const base64 = await fileToBase64(photo);
          await dispatchAPI('LOGIN/MICROSOFT', {
            body: {
              idTokenClaims: response.idTokenClaims,
              family_name: last_name,
              first_name,
              email: userData.mail,
              phone_number: userData.mobilePhone,
              photo: base64
            }
          });
        }
      } catch (error) {
        message('error', error);
      }
    }
  }, [isAuthenticated]);

  const handleLoginRedirect = () => {
    instance.loginRedirect(loginRequest);
  };

  return (
    <Button
      onClick={handleLoginRedirect}
      type="primary"
      htmlType="submit"
      block
      style={{ width: '90%' }}
      loading={isLoading}
    >
      {t('login.microsoft_connect')}
    </Button>
  );
};

export default MicrosoftLogin;

MicrosoftLogin.propTypes = {
  isLoading: PropTypes.bool,
  instance: PropTypes.shape({
    loginRedirect: PropTypes.func,
    getActiveAccount: PropTypes.func,
    acquireTokenSilent: PropTypes.func
  }).isRequired
};

MicrosoftLogin.defaultProps = {
  isLoading: undefined
};
