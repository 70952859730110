import { useTranslation } from 'react-i18next';
import { Typography } from 'antd';
import UserContextProvider from './UserContext';
import { useGenerateFormItem } from '../../utils/generateFormItem';
import usePrincipalFields from './principalFields';
import useAdministrationFields from './administrationFields';
import useHealthProtectionFields from './healthProtectionFields';
import useDocumentFields from './documentFields';
import useCommunicationFields from './communicationFields';
import useTechniqueFields from './techniqueFields';

/**
 * Custom hook for generating form fields organized by categories in a collapsible layout.
 *
 * @hook
 * @param {string} purpose - Purpose of the form (e.g., 'create', 'edit')
 * @returns {object} Object containing categories, base64, and loading status
 * @property {Array} categories - Array of category objects with label and children
 * @property {string} base64 - Base64 encoded image data for user photo
 * @property {boolean} isFieldsLoading - Loading status for field data
 */

const useCollapseByCategories = (purpose) => {
  const { t } = useTranslation();
  const { formValues } = UserContextProvider();
  const generateFields = useGenerateFormItem();
  const { principalFields, base64, isFieldsLoading } =
    usePrincipalFields(purpose);
  const { administrationFields } = useAdministrationFields();
  const { healthProtectionFields } = useHealthProtectionFields();
  const { documentFields } = useDocumentFields(purpose);
  const { communicationFields } = useCommunicationFields();
  const { techniqueFields } = useTechniqueFields();

  const categories = [
    {
      key: '1',
      label: (
        <Typography.Title
          level={4}
          style={{
            color: 'var(--userColor)',
            margin: 0
          }}
          strong={false}
        >
          {t('users.form.principal_informations')}
        </Typography.Title>
      ),
      children: principalFields.map((field) => generateFields('users', field))
    },
    ...(formValues?.role?.startsWith('guests')
      ? []
      : [
          {
            key: '2',
            label: (
              <Typography.Title
                level={4}
                style={{
                  color: 'var(--userColor)',
                  margin: 0
                }}
                strong={false}
              >
                {t('users.form.administration')}
              </Typography.Title>
            ),
            children: administrationFields.map((field) =>
              generateFields('users', field)
            )
          },
          {
            key: '3',
            label: (
              <Typography.Title
                level={4}
                style={{
                  color: 'var(--userColor)',
                  margin: 0
                }}
                strong={false}
              >
                {t('users.form.healt_protections')}
              </Typography.Title>
            ),
            children: healthProtectionFields.map((field) =>
              generateFields('users', field)
            )
          },
          {
            key: '4',
            label: (
              <Typography.Title
                level={4}
                style={{
                  color: 'var(--userColor)',
                  margin: 0
                }}
                strong={false}
              >
                {t('users.form.documents')}
              </Typography.Title>
            ),
            children: documentFields.map((field) =>
              generateFields('users', field)
            )
          },
          {
            key: '5',
            label: (
              <Typography.Title
                level={4}
                style={{
                  color: 'var(--userColor)',
                  margin: 0
                }}
                strong={false}
              >
                {t('users.form.communication_material')}
              </Typography.Title>
            ),
            children: communicationFields.map((field) =>
              generateFields('users', field)
            )
          },
          {
            key: '6',
            label: (
              <Typography.Title
                level={4}
                style={{
                  color: 'var(--userColor)',
                  margin: 0
                }}
                strong={false}
              >
                {t('users.form.technique')}
              </Typography.Title>
            ),
            children: techniqueFields.map((field) =>
              generateFields('users', field)
            )
          }
        ])
  ];

  return { categories, base64, isFieldsLoading };
};

export default useCollapseByCategories;
