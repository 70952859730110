import dayjs from 'dayjs';
import { accountingAccounts } from './constants/accountingAccounts';

export const formDependencies = async (
  dispatchAPI,
  message,
  value,
  formValues,
  resource,
  form,
  setFormValues,
  fields
) => {
  try {
    const {
      role,
      ps_number,
      services,
      details,
      micro_ps,
      user_referent,
      period_start,
      scheduled_start_date,
      period_date,
      total_hours_service,
      fee_discount,
      type,
      template,
      scheduled_time
    } = formValues;
    const indexService = value?.services?.findIndex((service) => !!service);
    const indexDiscount = value?.services?.[indexService]?.discounts?.findIndex(
      (discount) => !!discount
    );

    let keyName = value?.services
      ? Object.keys(value.services[indexService])[0]
      : Object.keys(value)[0];

    if (
      keyName === 'discounts' &&
      value?.services[indexService].discounts[indexDiscount]
    )
      keyName = Object.keys(
        value.services[indexService].discounts[indexDiscount]
      )[0];

    const isValueFeeDependencies = [
      'type',
      'frequency',
      'total_hours',
      'vat',
      'amount'
    ];

    let customer;
    const getCustomer = async (psNumber, id) => {
      try {
        const url = psNumber
          ? `/customers?ps_number=${psNumber}&populate=sales`
          : `/customers?_id=${id}&populate=sales`;
        const { data } = await dispatchAPI('GET', {
          url
        });
        return data;
      } catch (e) {
        if (e.response) message(e.response.status);
      }
      return true;
    };

    let serviceSelected;
    const getService = async (id) => {
      try {
        const { data } = await dispatchAPI('GET', {
          url: `/service?_id=${id}&populate=contract`
        });
        return data;
      } catch (e) {
        if (e.response) message(e.response.status);
      }
      return true;
    };

    const getFee = async (microPs, service) => {
      try {
        const { data } = await dispatchAPI('GET', {
          url: `/fees?micro_ps=${microPs}&service=${service}&status=ACTIVE`
        });
        return data;
      } catch (e) {
        if (e.response) message(e.response.status);
      }
      return true;
    };

    const getRejectionReason = async (idRejectionReason) => {
      try {
        const { data } = await dispatchAPI('GET', {
          url: `/rejectionreasons/${idRejectionReason}`
        });
        return data?.rejection_type;
      } catch (e) {
        if (e.response) message(e.response.status);
      }
      return true;
    };

    const getTemplateContent = async (
      modelName,
      templateId,
      resourceIds,
      query
    ) => {
      try {
        const { data } = await dispatchAPI('GET', {
          url: `/${modelName}/${templateId}/${resourceIds}?${query}`
        });
        return data;
      } catch (e) {
        if (e.response) message(e.response.status);
      }

      return true;
    };

    switch (resource) {
      case 'users':
        if (role?.startsWith('guests')) {
          setFormValues({ ...formValues, position: 'GUEST', employer: null });
          form.setFieldsValue({ position: 'GUEST', employer: null });
        }
        if (role === 'guests:GROUP') {
          const fieldsKeys = fields[0]?.map((field) => field.key);
          const keys = Object.keys(formValues).filter((key) =>
            fieldsKeys.includes(key)
          );
          const newFormValues = keys.reduce(
            (acc, key) => ({ ...acc, [key]: formValues[key] }),
            {}
          );
          setFormValues({
            ...newFormValues,
            position: 'GUEST',
            employer: null
          });
          form.setFieldsValue({
            ...newFormValues,
            position: 'GUEST',
            employer: null
          });
        }
        if (keyName === 'company') {
          customer = await getCustomer(null, formValues.company);
          setFormValues({
            ...formValues,
            micro_ps: customer[0].micro_ps
          });
          form.setFieldsValue({
            ...formValues,
            micro_ps: customer[0].micro_ps
          });
        }
        break;
      case 'quotes':
        customer = await getCustomer(ps_number);
        if (customer.length && keyName === 'ps_number') {
          setFormValues({
            ...formValues,
            status_customer: customer[0]?.status,
            company_name: customer[0]?.company_name,
            company_customer: customer[0]?.company,
            social_reason: customer[0]?.social_reason,
            micro_ps: customer[0]?.micro_ps,
            customer: customer[0]?._id
          });
          form.setFieldsValue({
            ...formValues,
            status_customer: customer[0]?.status,
            company_customer: customer[0]?.company,
            company_name: customer[0]?.company_name,
            micro_ps: customer[0]?.micro_ps,
            social_reason: customer[0]?.social_reason,
            customer: customer[0]?._id
          });
        }
        if (
          services?.length &&
          micro_ps &&
          isValueFeeDependencies.includes(keyName)
        ) {
          services.map(async (service, index) => {
            const fee = await getFee(micro_ps, service?.type);
            if (fee) {
              const updatedFormValues = formValues;
              if (services[index]) {
                const amount = fee[0]?.amount || 0;
                const discount = fee[0]?.discount || 0;
                const totalHours =
                  updatedFormValues.services[index]?.total_hours || 0;
                const frequencyMultiplier =
                  service?.frequency === 'WEEK' ? 4.33 : 1;
                const discountMultiplier = discount
                  ? (100 - discount) / 100
                  : 1;
                const vat = service?.vat || 0;

                let extraDiscount = 0;
                if (service?.discounts?.length) {
                  service?.discounts.forEach((dis) => {
                    extraDiscount += Number(dis?.amount) || 0;
                  });
                }
                const monthlyPrice =
                  amount *
                    totalHours *
                    frequencyMultiplier *
                    discountMultiplier -
                  extraDiscount;
                const totalDiscount =
                  amount * totalHours * frequencyMultiplier * (discount / 100) +
                  extraDiscount;
                const monthlyPriceWithVAT = monthlyPrice * (1 + vat / 100);

                updatedFormValues.services[index].amount =
                  Math.floor(amount * 100) / 100;
                updatedFormValues.services[index].fee_discount =
                  Math.floor(discount * 100) / 100 || 0;
                updatedFormValues.services[index].monthly_price =
                  Math.floor(monthlyPrice * 100) / 100;
                updatedFormValues.services[index].monthly_price_vat_included =
                  Math.floor(monthlyPriceWithVAT * 100) / 100;
                updatedFormValues.services[index].total_discounts =
                  Math.floor(totalDiscount * 100) / 100;
                updatedFormValues.services[index].vat = customer[0]?.vat;
              }
              setFormValues(updatedFormValues);
              form.setFieldsValue(updatedFormValues);
            }
          });
        }
        break;
      case 'interventions':
        if (keyName === 'ps_number' && ps_number) {
          customer = await getCustomer(ps_number);
          setFormValues({
            ...formValues,
            company_name: customer[0]?.company_name,
            micro_ps: customer[0]?.micro_ps,
            macro_ps: customer[0]?.macro_ps,
            customer: customer[0]?._id,
            used_softwares: customer[0]?.used_software
          });
          form.setFieldsValue({
            ...formValues,
            company_name: customer[0]?.company_name,
            micro_ps: customer[0]?.micro_ps,
            macro_ps: customer[0]?.macro_ps,
            customer: customer[0]?._id,
            used_softwares: customer[0]?.used_software
          });
        }
        if (keyName === 'user_referent') {
          setFormValues({
            ...formValues,
            user: user_referent
          });
          form.setFieldsValue({
            ...formValues,
            user: user_referent
          });
        }
        if (keyName === 'service') {
          serviceSelected = await getService(formValues?.service);
          setFormValues({
            ...formValues,
            contract_code: serviceSelected[0]?.contract?.code,
            service_start: dayjs(serviceSelected[0]?.start_date),
            service_end: serviceSelected[0]?.end_date
              ? dayjs(serviceSelected[0]?.end_date)
              : undefined,
            contract: serviceSelected[0]?.contract?._id
          });
          form.setFieldsValue({
            ...formValues,
            contract_code: serviceSelected[0]?.contract?.code,
            service_start: dayjs(serviceSelected[0]?.start_date),
            service_end: serviceSelected[0]?.end_date
              ? dayjs(serviceSelected[0]?.end_date)
              : undefined,
            contract: serviceSelected[0]?.contract?._id
          });
        }
        if (keyName === 'direct_intervention') {
          setFormValues({
            ...formValues,
            scheduled_start_date: dayjs(),
            scheduled_end_date: dayjs().add(30, 'minute')
          });
          form.setFieldsValue({
            ...formValues,
            scheduled_start_date: dayjs(),
            scheduled_end_date: dayjs().add(30, 'minute')
          });
        }
        if (keyName === 'period_start') {
          setFormValues({
            ...formValues,
            period_start: dayjs(period_start).startOf('day'),
            period_end: dayjs(period_start).endOf('day').add(6, 'day')
          });
          form.setFieldsValue({
            ...formValues,
            period_start: dayjs(period_start).startOf('day'),
            period_end: dayjs(period_start).endOf('day').add(6, 'day')
          });
        }
        if (scheduled_start_date && scheduled_time) {
          setFormValues({
            ...formValues,
            scheduled_end_date: dayjs(scheduled_start_date).add(
              scheduled_time,
              'hour'
            )
          });
          form.setFieldsValue({
            ...formValues,
            scheduled_end_date: dayjs(scheduled_start_date).add(
              scheduled_time,
              'hour'
            )
          });
        }
        break;
      case 'actions':
        if (keyName === 'rejection_reason') {
          const rejectionType = await getRejectionReason(
            formValues?.rejection_reason
          );
          setFormValues({
            ...formValues,
            rejection_type: rejectionType
          });
          form.setFieldsValue({
            ...formValues,
            rejection_type: rejectionType
          });
        }
        break;
      case 'mail':
        if (keyName === 'template' && template) {
          const queryParams = new URLSearchParams({
            gender_insured: formValues?.gender_insured,
            name_insured: formValues?.name_insured,
            full_address_insured: formValues?.full_address_insured,
            additional_address_insured: formValues?.additional_address_insured,
            postal_code_insured: formValues?.postal_code_insured,
            city_insured: formValues?.city_insured,
            country_insured: formValues?.country_insured,
            gender_beneficiary: formValues?.gender_beneficiary,
            name_beneficiary: formValues?.name_beneficiary,
            company: formValues?.company
          }).toString();
          const templateContent = await getTemplateContent(
            'Action',
            template,
            formValues?.fses_selected,
            queryParams
          );
          setFormValues({
            ...formValues,
            content: templateContent[0].content,
            footer: templateContent[0].footer
          });
          form.setFieldsValue({
            ...formValues,
            content: templateContent[0].content,
            footer: templateContent[0].footer
          });
        }
        break;
      case 'quittances':
        customer = await getCustomer(ps_number);
        if (
          customer.length &&
          (keyName === 'ps_number' || keyName === 'type')
        ) {
          setFormValues({
            ...formValues,
            customer: customer[0]._id,
            billing_address: {
              street: customer[0]?.billing_address?.street,
              postal_code: customer[0]?.billing_address?.postal_code,
              city: customer[0]?.billing_address?.city
            },
            email: customer[0]?.email,
            phone_number: {
              number: customer[0]?.phone_number?.number
            },
            company: customer[0]?.company,
            bank_reconciliation: customer[0]?.bank_reconciliation,
            payment_choice: customer[0]?.payment_type,
            taxes: customer[0]?.vat,
            micro_ps: customer[0].micro_ps,
            macro_ps: customer[0].macro_ps,
            title: `${customer[0]?.company}_INVOICE`
          });
          form.setFieldsValue({
            ...formValues,
            customer: customer[0]._id,
            billing_address: {
              street: customer[0]?.billing_address?.street,
              postal_code: customer[0]?.billing_address?.postal_code,
              city: customer[0]?.billing_address?.city
            },
            email: customer[0]?.email,
            phone_number: {
              number: customer[0]?.phone_number?.number
            },
            company: customer[0]?.company,
            bank_reconciliation: customer[0]?.bank_reconciliation,
            payment_choice: customer[0]?.payment_type,
            taxes: customer[0]?.vat,
            micro_ps: customer[0].micro_ps,
            macro_ps: customer[0].macro_ps,
            title: `${customer[0]?.company}_INVOICE`
          });
        }
        if (keyName === 'period_date') {
          setFormValues({
            ...formValues,
            transfer_date: dayjs(period_date).add(
              customer?.transfer_date ? customer?.transfer_date : 10,
              'day'
            )
          });
          form.setFieldsValue({
            ...formValues,
            transfer_date: dayjs(period_date).add(
              customer?.transfer_date ? customer?.transfer_date : 10,
              'day'
            )
          });
        }
        if (
          details?.length &&
          details?.[0]?.amount &&
          details?.[0]?.total_hours_service
        ) {
          let sumTotal = 0;
          let sumTotalWithTaxes = 0;
          let sumDiscounts = 0;
          const vat = customer[0]?.vat || 1;
          const updatedFormValues = formValues;
          details.map((detail, index) => {
            if (detail?.amount && detail?.total_hours_service) {
              const feeDiscount = Number(detail?.fee_discount) || 0;
              const total =
                (detail?.amount || 0) * (detail?.total_hours_service || 1) -
                feeDiscount;
              const totalWithTaxes = total * (1 + vat / 100);
              sumTotal += total;
              sumTotalWithTaxes += totalWithTaxes;
              sumDiscounts += feeDiscount;
              updatedFormValues.details[index].fee_discount = Number(
                feeDiscount.toFixed(2)
              );
              updatedFormValues.details[index].total = Number(total.toFixed(2));
              updatedFormValues.details[index].total_with_taxes = Number(
                totalWithTaxes.toFixed(2)
              );
              updatedFormValues.details[index].accounting_account =
                accountingAccounts[detail?.type][vat];
            }
            return updatedFormValues;
          });
          updatedFormValues.total = Number(sumTotal.toFixed(2));
          updatedFormValues.total_with_taxes = Number(
            sumTotalWithTaxes.toFixed(2)
          );
          updatedFormValues.total_discount = Number(sumDiscounts.toFixed(2));
          setFormValues(updatedFormValues);
          form.setFieldsValue(updatedFormValues);
        }
        if (formValues?.amount && total_hours_service) {
          const feeDiscount = Number(fee_discount) || 0;
          const vat = customer[0]?.vat || 1;
          const total =
            (formValues?.amount || 0) * (total_hours_service || 1) -
            feeDiscount;
          const totalWithTaxes = total * (1 + vat / 100);
          const updatedFormValues = formValues;
          updatedFormValues.fee_discount = Number(feeDiscount?.toFixed(2));
          updatedFormValues.total = Number(total.toFixed(2));
          updatedFormValues.total_with_taxes = Number(
            totalWithTaxes.toFixed(2)
          );
          updatedFormValues.accounting_account = accountingAccounts[type][vat];
          setFormValues(updatedFormValues);
          form.setFieldsValue(updatedFormValues);
        }
        break;
      case 'emails':
        if (keyName === 'template' && template) {
          const templateContent = await getTemplateContent(
            formValues.model_name,
            template,
            formValues.id
          );
          setFormValues({
            ...formValues,
            content: templateContent[0].content,
            footer: templateContent[0].footer
          });
          form.setFieldsValue({
            ...formValues,
            content: templateContent[0].content,
            footer: templateContent[0].footer
          });
        }
        break;
      default:
        break;
    }
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log(e);
  }
};
