// Main nav menuItems
export const routes = {
  HOME: '/',
  CUSTOMERS: '/customers',
  INTERVENTIONS: '/interventions'
};

// Main nav subMenuItems
export const subRoutes = {};

// Other routes
export const outOfNavRoutes = {
  LOGIN: '/login',
  PROFILE: '/profile',
  SETTINGS: '/settings',
  TEMPLATE_DOCS: '/template-docs'
};

// url search parameters
export const pathSearches = {};
