import PropTypes from 'prop-types';
import { ListResource } from '../../../components';
import { useColumns } from '../../quittances/columns';
import useCustomerContext from '../CustomerContext';

/**
 * Component for listing quittances with filtering options.
 *
 * @component
 * @returns {JSX.Element} Rendered ListQuittances component
 */

export const ListQuittances = ({ id, withCreateButton }) => {
  const { hasAccess } = useCustomerContext();
  const columns = useColumns(hasAccess);

  return (
    <ListResource
      resourceName="quittances"
      contentCustomStyle={{ margin: 0, padding: 0 }}
      resourceModelName="Quittance"
      columns={columns}
      populate="customer,leader,sale,micro_ps,macro_ps"
      onDoubleClickAction={false}
      withImportButton={false}
      customActionColumn
      extraQuery={`customer=${id}`}
      withPageHeader={false}
      withCreateButton={withCreateButton}
    />
  );
};

ListQuittances.propTypes = {
  id: PropTypes.string.isRequired,
  withCreateButton: PropTypes.bool
};

ListQuittances.defaultProps = {
  withCreateButton: true
};
