import { Tag, Popover, Flex, Typography } from 'antd';
import { EyeOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { customerStatus, userCompanies } from '../../utils/constants/tagColors';
import { formatValue } from '../../utils/formatNumber';

/**
 * Generates an array of column configuration objects for the customer list table.
 *
 * @hook
 * @param {object} enums - An object containing enumerations used for columns and filters.
 * @returns {Array} - An array of column configuration objects.
 */

export const useColumns = (enums, psMacro) => {
  const { t } = useTranslation();
  const { Text, Link } = Typography;

  const setColumnHeaderStyle = () => ({
    onHeaderCell: () => ({
      style: {
        whiteSpace: 'nowrap'
      }
    })
  });

  return [
    {
      title: t('customers.form.status'),
      key: 'status',
      dataIndex: ['status'],
      render: (status) => (
        <Tag color={customerStatus[status]}>
          {t(`customers.status.${status || 'N/A'}`, {
            defaultValue: status
          })}
        </Tag>
      ),
      sorter: true,
      ...setColumnHeaderStyle()
    },
    {
      title: t('customers.form.company'),
      key: 'company',
      dataIndex: ['company'],
      render: (company) => (
        <Tag color={userCompanies[company]}>
          {t(`employments.tags.${company}`)}
        </Tag>
      ),
      sorter: true,
      filters: enums?.employers?.map((p) => ({
        text: p,
        value: p
      })),
      ...setColumnHeaderStyle()
    },
    {
      title: t('customers.form.ps_number'),
      key: 'ps_number',
      dataIndex: ['ps_number'],
      sorter: true,
      ...setColumnHeaderStyle()
    },
    {
      title: t('customers.form.company_name'),
      key: 'company_name',
      dataIndex: ['company_name'],
      sorter: true,
      ...setColumnHeaderStyle()
    },
    {
      title: t('customers.form.contacts'),
      key: 'contacts',
      dataIndex: ['contacts'],
      render: (contacts) => (
        <Popover
          content={(contacts || []).map((contact) => (
            <Flex gap="middle" align="center" horizontal>
              <Text>{t(`users.genders.${contact?.gender}`)} </Text>
              <Text strong>
                {`${contact?.first_name || '-'} ${
                  contact?.usage_last_name || contact?.last_name || '-'
                } :`}
              </Text>
              <Link
                style={{ color: 'blue' }}
                href={`mailto:${contact?.email}`}
                target="_blank"
                rel="noopener noreferrer"
                aria-label="Voir le mail"
              >
                {contact?.email}
              </Link>
            </Flex>
          ))}
          title={t('quotes.columns.contacts')}
        >
          <EyeOutlined style={{ fontSize: 18 }} />
        </Popover>
      ),
      ...setColumnHeaderStyle()
    },
    {
      title: t('users.form.macro_ps'),
      key: 'macro_ps',
      dataIndex: ['macro_ps'],
      render: (macro_ps) => `${macro_ps?.title || '-'}`,
      filters: (psMacro || []).map((ps) => ({
        text: ps?.title,
        value: ps?._id
      })),
      sorter: true,
      ...setColumnHeaderStyle()
    },
    {
      title: t('customers.form.micro_ps'),
      key: 'micro_ps.title',
      dataIndex: ['micro_ps'],
      sorter: true,
      render: (micro_ps) => micro_ps?.title,
      ...setColumnHeaderStyle()
    },
    {
      title: t('customers.form.leader'),
      key: 'leader.first_name',
      dataIndex: ['leader'],
      render: (leader) =>
        leader
          ? `${leader.first_name} ${
              leader?.usage_last_name || leader.last_name
            }`
          : '-',
      sorter: true,
      ...setColumnHeaderStyle()
    },
    {
      title: t('customers.form.address.postal_code'),
      key: 'postal_code',
      dataIndex: ['address'],
      sorter: true,
      render: (address) => address?.postal_code || '',
      ...setColumnHeaderStyle()
    },
    {
      title: t('customers.form.address.city'),
      key: 'city',
      dataIndex: ['address'],
      sorter: true,
      render: (address) => address?.city || '',
      ...setColumnHeaderStyle()
    },
    {
      title: t('customers.form.address.label'),
      key: 'address',
      dataIndex: ['address'],
      render: (address) =>
        `${address?.number || ''} ${address?.street || ''} ${
          address?.additional || ''
        }`,
      ...setColumnHeaderStyle()
    },
    {
      title: t('customers.form.date_of_birth'),
      key: 'date_of_birth',
      dataIndex: ['date_of_birth'],
      sorter: true,
      render: (date_of_birth) =>
        date_of_birth ? formatValue(date_of_birth, 'date') : '-',
      ...setColumnHeaderStyle()
    },
    {
      title: t('customers.form.used_software'),
      key: 'used_software.title',
      dataIndex: ['used_software'],
      render: (used_software) => used_software?.map((p) => p.title).join(', '),
      sorter: true,
      ...setColumnHeaderStyle()
    },
    {
      title: t('customers.form.business_introducer'),
      key: 'business_introducer.title',
      dataIndex: ['business_introducer'],
      sorter: true,
      render: (business_introducer) =>
        business_introducer ? `${business_introducer.title}` : '-',
      ...setColumnHeaderStyle()
    }
  ];
};
