import { Row, Col } from 'antd';
import { useState, useEffect } from 'react';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { GraphCard } from '../../../components/GraphCard';
import { selectFormat } from '../CRUDFields/utils';
import { useAuthContext } from '../../../contexts/AuthContext';
import { useErrorMessage } from '../../../utils/errorMessage';
import { statsCharts } from './utilsStats';
import { RejectionsAnalysis } from './RejectionsAnalysis';
import { CustomSpin } from '../../../components/CustomSpin/CustomSpin';

/**
 * A component for displaying statistical data related to customer interactions and operations, such as
 * outstanding amounts, rejection rates, and more.
 *
 * @component
 * @param {Object} props - The component's props.
 * @param {string} props.id - Customer's id.
 * @returns {JSX.Element} The JSX element representing the statistical data display.
 */
export const Stats = ({ id, currentTab }) => {
  const { dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();
  const { t } = useTranslation();
  const [year, setYear] = useState(new Date().getFullYear());
  const [isLoading, setIsLoading] = useState(true);
  const [statsData, setStatsData] = useState([]);
  const [rejectionsAnalysis, setRejectionsAnalysis] = useState({});
  const [rejectionTypes, setRejectionTypes] = useState([]);
  const years = [];

  for (let i = 2023; i <= new Date().getFullYear(); i += 1) {
    years.push(i);
  }

  const fetchData = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/customers/stats/${id}?start_date>=${dayjs(new Date(year, 0, 1))
          .startOf('day')
          .toISOString()}&end_date<=${dayjs(new Date(year, 11, 31))
          .endOf('day')
          .toISOString()}`
      });
      setStatsData(data);
    } catch (error) {
      message(error);
    }
  };

  const getRejectionsAnalysis = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/actions/stats/${id}?created_at>=${dayjs(new Date(year, 0, 1))
          .startOf('day')
          .toISOString()}&created_at<=${dayjs(new Date(year, 11, 31))
          .endOf('day')
          .toISOString()}`
      });
      setRejectionsAnalysis(data);
    } catch (error) {
      message(error);
    }
  };

  const getRejectionTypes = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/rejectiontype`
      });
      setRejectionTypes(data);
    } catch (error) {
      message(error);
    }
  };

  useEffect(async () => {
    if (currentTab === '7') {
      setIsLoading(true);
      await fetchData();
      await getRejectionTypes();
      await getRejectionsAnalysis();
      setIsLoading(false);
    }
  }, [year, currentTab]);

  return (
    <>
      <Row style={{ marginBottom: 20 }}>
        {selectFormat(() => {}, years, {
          onChange: setYear,
          defaultValue: new Date().getFullYear()
        })}
      </Row>
      {!isLoading ? (
        <>
          <Row gutter={[24, 24]}>
            {statsCharts?.map((chart) => (
              <GraphCard
                key={chart.keyData}
                data={statsData}
                keyData={chart.keyData}
                average={chart?.average || chart?.passive_outstandings}
                span={12}
                title={t(`customers.stats.${chart.keyData}`)}
                color={chart.color}
              />
            ))}
          </Row>
          <Row gutter={[24, 24]} style={{ marginTop: 20 }}>
            <Col span={24}>
              <RejectionsAnalysis
                rejectionsAnalysis={rejectionsAnalysis}
                rejectionTypes={rejectionTypes}
              />
            </Col>
          </Row>
        </>
      ) : (
        <CustomSpin />
      )}
    </>
  );
};

Stats.propTypes = {
  id: PropTypes.string,
  currentTab: PropTypes.string
};

Stats.defaultProps = {
  id: undefined,
  currentTab: undefined
};
