import { Col, Card, Row, Tree } from 'antd';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { DescriptionList } from '../../../components';
import { useServiceContent } from './servicesContent';
import { optionsList } from '../../../utils/constants/optionsService';

/**
 * Component for displaying quote services.
 *
 * @component
 * @param {object} props - Component props
 * @param {object} props.data - Quote data containing services
 * @returns {JSX.Element} Component JSX
 */

export const ShowServices = ({
  data,
  openModalAdjustedHours,
  resourceName
}) => {
  const { t } = useTranslation();
  const psMacroTitle = data?.micro_ps?.macro_ps?.title;
  const sameOptions = ['Opticien', 'Audioprothésiste'];

  const getExcludedKeys = (type) => {
    const excludedKeys = [];
    if (type.startsWith('MANAGEMENT')) {
      if (sameOptions.includes(psMacroTitle))
        excludedKeys.push('synthesis', 'stats', 'reject_analysis');
      if (psMacroTitle === 'Dentaire')
        excludedKeys.push('reject_analysis', 'bank', 'evolution');
      if (!sameOptions.includes(psMacroTitle) && psMacroTitle !== 'Dentaire')
        excludedKeys.push('check', 'bank', 'evolution');
    }
    return excludedKeys;
  };

  return (
    <>
      {(data?.services || []).map((service) => (
        <Row gutter={[16, 16]} key={service?._id}>
          <Col xs={24} xxl={16} key={service._id}>
            <Card title={t(`quotes.tags.${service?.type}`)}>
              <Row align="middle">
                <DescriptionList
                  data={useServiceContent(
                    service,
                    openModalAdjustedHours,
                    resourceName
                  )}
                  translate
                  columns={24}
                />
              </Row>
            </Card>
          </Col>
          <Col xs={24} xxl={8}>
            <Card>
              <Tree
                showLine
                defaultExpandedKeys={['0-0-0']}
                treeData={(optionsList[service?.type?.split('_')[0]] || [])
                  .filter((f) => !getExcludedKeys(service?.type)?.includes(f))
                  .map((option, index) => ({
                    title: t(
                      `quotes.options.${service?.type?.split('_')[0]}.${option}`
                    ),
                    key: index,
                    children: []
                  }))}
              />
            </Card>
          </Col>
        </Row>
      ))}
    </>
  );
};

ShowServices.propTypes = {
  data: PropTypes.shape({
    services: PropTypes.arrayOf(PropTypes.shape({})),
    micro_ps: PropTypes.shape({
      macro_ps: PropTypes.shape({ title: PropTypes.string })
    })
  }),
  openModalAdjustedHours: PropTypes.func,
  resourceName: PropTypes.string
};

ShowServices.defaultProps = {
  data: undefined,
  resourceName: undefined,
  openModalAdjustedHours: null
};
