import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Col, Card, Spin } from 'antd';
import { useTranslation } from 'react-i18next';
import { useAuthContext } from '../../../contexts/AuthContext';
import { useErrorMessage } from '../../../utils/errorMessage';
import { ListResource } from '../../../components';
import { useColumns } from './paymentsColumns';
import { headers } from './headers';
import PaymentModal from './PaymentModal';
import { showSuccessMessage } from '../../../utils/showSuccessMessage';

/**
 * @component
 * @name ListPayments
 * @description Displays the list of payments
 * @returns {component} ListPayments component
 */
export const ListPayments = ({ quittance, forceRefresh, setForceRefresh }) => {
  const { t } = useTranslation();
  const { dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();
  const [isLoading, setIsLoading] = useState(false);
  const [enums, setEnums] = useState();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [purpose, setPurpose] = useState(null);
  const [idFromOverlay, setIdFromOverlay] = useState(null);
  const [bankReconciliations, setBankReconciliations] = useState(null);
  const [condition, setCondition] = useState(null);

  const openModal = (cond) => {
    setPurpose('create');
    setIdFromOverlay();
    setCondition(cond);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setPurpose(null);
    setCondition(null);
    setIsModalOpen(false);
  };

  const onEdit = (idPayment) => {
    setPurpose('edit');
    setIdFromOverlay(idPayment);
    setIsModalOpen(true);
  };

  const onRejected = async (idPayment) => {
    try {
      await dispatchAPI('DELETE', {
        url: `/payments/${idPayment}`
      });
      setForceRefresh(!forceRefresh);
      showSuccessMessage(t, 'payements', 'archive');
    } catch (error) {
      message(error);
    }
  };

  const columns = useColumns(enums, 'quittances', { onEdit, onRejected });

  const getEnums = async () => {
    try {
      const { data } = await dispatchAPI('GET', { url: '/payments/enums' });
      setEnums(data);
    } catch (e) {
      message(e);
    }
  };

  const getBankReconciliation = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/bankreconciliations`
      });
      setBankReconciliations(data);
    } catch (e) {
      message(e);
    }
  };

  const customNavigate = () => {
    setPurpose(null);
    setIdFromOverlay(null);
    setIsModalOpen(false);
    setForceRefresh(!forceRefresh);
  };

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      await getEnums();
      await getBankReconciliation();
      setIsLoading(false);
    })();
  }, []);

  return (
    <>
      {isModalOpen && (
        <PaymentModal
          isModalOpen={isModalOpen}
          closeModal={closeModal}
          idFromOverlay={idFromOverlay}
          customNavigate={customNavigate}
          purpose={purpose}
          quittance={quittance}
          isCreditModal={false}
          enums={enums}
          bankReconciliations={bankReconciliations}
          condition={condition}
        />
      )}
      <Col span={24}>
        <Card title={t('quittances.show.titles.payments')}>
          <Spin spinning={isLoading}>
            <ListResource
              resourceName="payments"
              populate="imported_by"
              columns={columns}
              headers={headers}
              resourceModelName="Payments"
              extraQuery={quittance?._id ? `quittance=${quittance?._id}` : ''}
              withImportButton={false}
              archivedButton={false}
              withPageHeader={false}
              withCreateButtonText={t('buttons.create_custom.payments')}
              showAction={false}
              editAction={false}
              deleteAction={false}
              onClickAdd={() => openModal('CLASSIC')}
              contentCustomStyle={{ margin: 0, padding: 0 }}
            />
          </Spin>
        </Card>
      </Col>
    </>
  );
};

ListPayments.propTypes = {
  quittance: PropTypes.shape({
    _id: PropTypes.string
  }),
  forceRefresh: PropTypes.bool.isRequired,
  setForceRefresh: PropTypes.func.isRequired
};

ListPayments.defaultProps = {
  quittance: undefined
};
