import React, { useMemo, useRef, useEffect } from 'react';
import JoditEditor from 'jodit-react';
import { useTranslation } from 'react-i18next';
import { modelKeyPopUp } from './CustomComponents/modelKeyPopUp';
import { pageBreakBeforeButton } from './CustomComponents/pageBreakBeforeButton';
import { pageBreakAfterButton } from './CustomComponents/pageBreakAfterButton';
import { createConfig } from './config';

/**
 * TemplateEditor component to render a rich text editor with customizable placeholders.
 *
 * @component
 *
 * @param {Object} props - The props for the component.
 * @param {string} props.pickedConfiguration - The configuration for fetching the tags.
 * @param {string} props.content - The initial content to be displayed in the editor.
 * @param {function} props.setContent - The function to update the content state.
 * @param {boolean} props.isSubmitting - State of the form when its submitted.
 * @param {string} props.objective - Objective of the editor (either one of header, body or footer).
 * @returns {JSX.Element} The TemplateEditor component.
 */
const TemplateEditor = ({
  tags,
  content,
  setContent,
  objective,
  changePowered
}) => {
  const editorRef = useRef(null);
  const { t } = useTranslation();

  const ModalKeyButton = modelKeyPopUp(tags, t);
  const pageBreakBefore = pageBreakBeforeButton();
  const pageBreakAfter = pageBreakAfterButton();

  const actualContent = content[objective];

  const config = createConfig([
    ModalKeyButton,
    pageBreakBefore,
    pageBreakAfter
  ]);

  useEffect(() => {
    changePowered();
  }, []);

  return useMemo(
    () => (
      <JoditEditor
        ref={editorRef}
        value={actualContent}
        config={config}
        onChange={(value) =>
          setContent((prev) => {
            const updatedState = { ...prev };
            updatedState[objective] = value;
            return updatedState;
          })
        }
      />
    ),
    [tags, objective]
  );
};

export default TemplateEditor;
