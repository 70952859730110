import { Route, Routes } from 'react-router-dom';
import { ListCustomerServices } from './ListCustomerServices';
import { CreateUpdateCustomerService } from './CreateUpdateCustomerSevice';
import { Exception } from '../../components';
import { CustomerServiceContextProvider } from './CustomerServiceContext';
import ShowCustomerService from './ShowCustomerService';

export const CustomerServiceRouter = () => (
  <CustomerServiceContextProvider>
    <Routes>
      <Route
        path="/create"
        element={<CreateUpdateCustomerService purpose="create" />}
      />
      <Route
        path="/edit/:id"
        element={<CreateUpdateCustomerService purpose="edit" />}
      />
      <Route path="/show/:id" element={<ShowCustomerService />} />
      <Route index element={<ListCustomerServices />} />
      <Route path="*" element={<Exception />} />
    </Routes>
  </CustomerServiceContextProvider>
);
