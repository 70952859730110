import { Tag, Divider, Button } from 'antd';
import { Link, useLocation } from 'react-router-dom';
import { EyeOutlined, EditOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { userEmployer } from '../../utils/constants/tagColors';
import { useAuthContext } from '../../contexts/AuthContext';
import { isAuthorized } from '../../utils/constants/authorizedMenu';

/**
 * Custom hook for generating table columns for displaying contract data.
 *
 * @hook
 * @param {object} enums - Enumerations data for contract properties
 * @param {Array} psMicro - List of micro ps data for popover
 * @param {Array} psMacro - List of macro ps data for popover
 * @param {Array} customerEnums - List of customerEnums data for popover
 * @returns {Array} Array of table column objects
 */
const iconSize = 18;

export const useColumns = (psMicro, psMacro, customerEnums, onEditAction) => {
  const { t } = useTranslation();
  const location = useLocation();
  const { user } = useAuthContext();
  const { pathname } = location;

  const hasAccess = (purpose) => isAuthorized(pathname, user?.role, purpose);

  return [
    {
      title: t('contracts.columns.company'),
      key: 'company',
      dataIndex: ['company'],
      render: (company) => (
        <Tag color={userEmployer[company]}>
          {t(`users.employers.${company || 'N/A'}`)}
        </Tag>
      ),
      sorter: true,
      filters: customerEnums?.companies?.map((c) => ({
        text: t(`users.employers.${c}`),
        value: c
      }))
    },
    {
      title: t('contracts.columns.ps_number'),
      key: 'ps_number',
      dataIndex: 'ps_number',
      sorter: true
    },
    {
      title: t('contracts.columns.company_name'),
      key: 'customer',
      dataIndex: 'customer',
      render: (customer) => (customer && customer?.company_name) || '-'
    },
    {
      title: t('contracts.columns.micro_ps'),
      dataIndex: 'micro_ps',
      key: 'micro_ps',
      sorter: true,
      render: (value) => value?.title,
      filterSearch: true,
      filters: psMicro
        ?.sort((a, b) => a.title.localeCompare(b.title))
        ?.map((ps) => ({
          text: ps.title,
          value: ps._id
        }))
    },
    {
      title: t('contracts.columns.macro_ps'),
      dataIndex: 'macro_ps',
      key: 'macro_ps',
      sorter: true,
      render: (value) => value?.title,
      filterSearch: true,
      filters: psMacro
        ?.sort((a, b) => a.title.localeCompare(b.title))
        ?.map((ps) => ({
          text: ps.title,
          value: ps._id
        }))
    },
    {
      title: t('contracts.columns.code'),
      key: 'code',
      dataIndex: 'code',
      sorter: true
    },
    {
      key: 'action',
      align: 'right',
      render: (record) => (
        <>
          {hasAccess('show_quittance') && (
            <>
              <Link to={`/contract/show/${record._id}`}>
                <EyeOutlined style={{ fontSize: iconSize }} />
              </Link>
              <Divider type="vertical" />
            </>
          )}
          {hasAccess('edit') && record?.status !== 'TERMINATED' && (
            <Button
              type="link"
              onClick={() => onEditAction(record)}
              className="no-padding"
            >
              <EditOutlined style={{ fontSize: iconSize }} />
            </Button>
          )}
        </>
      )
    }
  ];
};
