import { useCallback, useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { useParams, Link, useNavigate, useLocation } from 'react-router-dom';
import {
  Button,
  Divider,
  Row,
  Flex,
  Typography,
  message as antdMessage,
  Popconfirm
} from 'antd';
import { useTranslation } from 'react-i18next';
import {
  EditOutlined,
  EyeOutlined,
  SendOutlined,
  PrinterOutlined,
  ContainerOutlined,
  CheckOutlined,
  CloseOutlined,
  PlusOutlined,
  WarningOutlined
} from '@ant-design/icons';
import { useAuthContext } from '../../contexts/AuthContext';
import { useErrorMessage } from '../../utils/errorMessage';
import { ContentCustom, PageHeaderCustom } from '../../components';
import { CustomSpin } from '../../components/CustomSpin/CustomSpin';
import { routes } from '../../utils/constants/adminRoutes';
import { StepsCard } from './ShowCards/StepsCard';
import { ShowPrincipalInformations } from './ShowCards/ShowPrincipalInformations';
import { ShowHistoric } from './ShowCards/ShowHistoric';
import { ShowServices } from './ShowCards/ShowServices';
import { ShowComment } from './ShowCards/ShowComment';
import QuoteContextProvider from './QuoteContext';
import ModalConfirmationContract from './ModalConfirmationContract';
import RejectionModal from './RejectionModal';
import EmailModal from '../../components/EmailModal/EmailModal';
import { showSuccessMessage } from '../../utils/showSuccessMessage';
import { isAuthorized } from '../../utils/constants/authorizedMenu';

/**
 * Component for displaying detailed information about a quote.
 *
 * @component
 * @returns {JSX.Element} Rendered ShowQuote component
 */

export const ShowQuote = () => {
  const { id } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const { pathname } = location;
  const { message } = useErrorMessage();
  const { dispatchAPI, user } = useAuthContext();
  const { quoteEnums } = QuoteContextProvider();
  const [forceRefresh, setForceRefresh] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [quote, setQuote] = useState();
  const [leaders, setLeaders] = useState([]);
  const [usersGtp, setUsersGtp] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isRejectionModalOpen, setIsRejectionModalOpen] = useState(false);
  const [isEmailModalOpen, setIsEmailModalOpen] = useState(false);

  const hasAccess = (purpose) => isAuthorized(pathname, user?.role, purpose);

  const steps = (quoteEnums?.status || [])
    .filter((f) => {
      if (quote?.status === 'REFUSED')
        return (
          f !== 'ODM_VALID' &&
          f !== 'ODM_PENDING' &&
          f !== 'ODM_VALID' &&
          f !== 'ODM_INVALID' &&
          f !== 'ACCEPTED' &&
          f !== 'VALIDATE'
        );
      if (quote?.status === 'ODM_INVALID')
        return f !== 'ODM_VALID' && f !== 'REFUSED' && f !== 'VALIDATE';
      return f !== 'REFUSED' && f !== 'ODM_INVALID' && f !== 'VALIDATE';
    })
    .map((status) => status);

  const acceptValidations = ['QUOTE_SENT', 'ACCEPTED', 'ODM_PENDING'];
  const acceptModifications = ['CREATED', 'QUOTE_SENT'];
  const acceptRefusal = ['QUOTE_SENT', 'ODM_PENDING'];

  const getQuote = useCallback(async () => {
    setIsLoading(true);
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/quotes/${id}?populate=sale,micro_ps.macro_ps,services,customer.contacts,historic.author,file`
      });
      setQuote(data);
    } catch (e) {
      message(e);
    }
  }, [id]);

  const getLeaders = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: 'users?role=users:LEADER-USER&populate=employments'
      });
      const activeLeaders = data.filter((leader) => {
        if (leader?.employments?.length > 0)
          (leader?.employments || []).some(
            (employment) => employment.status === 'ACTIVE'
          );
        return leader;
      });
      setLeaders(activeLeaders);
    } catch (error) {
      message(error);
    }
  };

  const getUsersGtp = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: 'users?role=users:USER&populate=employments'
      });
      const activeGtp = data.filter((gtp) => {
        if (gtp?.employments?.length > 0)
          (gtp?.employments || []).some(
            (employment) => employment.status === 'ACTIVE'
          );
        return gtp;
      });
      setUsersGtp(activeGtp);
    } catch (error) {
      message(error);
    }
  };

  const deleteQuote = async () => {
    try {
      await dispatchAPI('DELETE', { url: `/quotes/${id}` });
      navigate(-1);
      showSuccessMessage(t, 'quotes', 'archive');
    } catch (e) {
      message(e);
    }
  };

  const patchStatus = async (status, type, extraValues) => {
    try {
      let nextStatus = steps[steps.indexOf(status) + 1];
      if (type === 'refused')
        nextStatus = status === 'QUOTE_SENT' ? 'REFUSED' : 'ODM_INVALID';
      await dispatchAPI('PATCH', {
        url: `/quotes/${id}`,
        body: {
          ...(type === 'refused' ? { ...extraValues } : {}),
          status: nextStatus,
          ...(nextStatus === 'ODM_VALID' ? { odm_valid: dayjs() } : {})
        }
      });
      setForceRefresh(forceRefresh + 1);
      setIsRejectionModalOpen(false);
      antdMessage.success(t('messages.success.patch_status'));
    } catch (e) {
      message(e);
    }
  };

  const postContract = async (values) => {
    try {
      const { data } = await dispatchAPI('POST', {
        url: '/contracts',
        body: { ...quote, values }
      });
      setIsModalOpen(false);
      navigate({ pathname: `/contract/show/${data._id}` });
      showSuccessMessage(t, 'contracts', 'create');
    } catch (e) {
      message(e);
    }
  };

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = async (values) => {
    await postContract(values);
  };

  const closeEmailModal = (value) => {
    setIsEmailModalOpen(false);
    if (value !== 'cancel') setForceRefresh(!forceRefresh);
  };

  const printQuote = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/files/${quote?.file?._id}`,
        responseType: 'blob',
        headers: {
          type: 'blob'
        }
      });
      const blob = new Blob([data], {
        contentType: 'application/pdf'
      });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = `${quote?.file?.metadata?.originalName}.pdf`;
      a.click();
      antdMessage.success(t('messages.success.download'));
    } catch (e) {
      message(e);
    }
  };

  useEffect(() => {
    (async () => {
      await getQuote();
      await getLeaders();
      await getUsersGtp();
      setIsLoading(false);
    })();
  }, [getQuote, forceRefresh]);

  return isLoading ? (
    <ContentCustom>
      <CustomSpin />
    </ContentCustom>
  ) : (
    <>
      {isRejectionModalOpen && (
        <RejectionModal
          setIsRejectionModalOpen={setIsRejectionModalOpen}
          isRejectionModalOpen={isRejectionModalOpen}
          quoteEnums={quoteEnums}
          patchStatus={patchStatus}
          quote={quote}
        />
      )}
      {isModalOpen && (
        <ModalConfirmationContract
          closeModal={closeModal}
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
          services={quote?.services}
          leaders={leaders}
          usersGtp={usersGtp}
        />
      )}
      {isEmailModalOpen && (
        <EmailModal
          isModalOpen={isEmailModalOpen}
          closeModal={closeEmailModal}
          contacts={
            quote?.customer?.contacts?.filter((f) => f.status === 'ACTIVE') ||
            []
          }
          baseUrl={`emails?type=quote&id=${quote?._id}`}
          extraQuery="status=VALIDATED"
          company={t(`employments.tags.${quote?.customer?.company}`)}
          subject={`[TPA] - Votre devis N°${quote.code}`}
          useCaseFilter="QUOTE"
          successMessageUrl="emails"
          modelName="Quote"
        />
      )}
      <ContentCustom>
        <PageHeaderCustom
          title={`${t('quotes.show.title')} ${quote?.code}`}
          extra={
            <Flex gap={8}>
              <Button
                type="primary"
                onClick={printQuote}
                disabled={!quote?.file}
              >
                <PrinterOutlined />
                {`${t(`quotes.show.buttons.show_quote`)} `}
              </Button>
              {hasAccess('action') && quote?.status === 'CREATED' && (
                <Button
                  type="primary"
                  onClick={() => setIsEmailModalOpen(true)}
                >
                  <SendOutlined />
                  {`${t(`quotes.show.buttons.send_mail_quote`)} `}
                </Button>
              )}
              {hasAccess('action') &&
                acceptValidations.includes(quote?.status) && (
                  <Popconfirm
                    title={t('datatable.column.action.validate_action.title')}
                    okText={t('datatable.column.action.validate_action.ok')}
                    okButtonProps={{ type: 'danger' }}
                    cancelText={t(
                      'datatable.column.action.validate_action.cancel'
                    )}
                    onConfirm={() => patchStatus(quote?.status, 'accepted')}
                    icon={<WarningOutlined />}
                  >
                    <Button type="primary">
                      <CheckOutlined />
                      {`${t(`quotes.show.tags_steps.${quote?.status}`)} `}
                    </Button>
                  </Popconfirm>
                )}
              {hasAccess('action') && acceptRefusal.includes(quote?.status) && (
                <Button
                  type="primary"
                  danger
                  onClick={() => setIsRejectionModalOpen(true)}
                >
                  <CloseOutlined />
                  {`${t(
                    `quotes.tags.${
                      quote?.status === 'QUOTE_SENT' ? 'REFUSED' : 'ODM_INVALID'
                    }`
                  )} `}
                </Button>
              )}
              {hasAccess('action') && quote?.status === 'ODM_VALID' && (
                <Button type="primary" onClick={openModal}>
                  <PlusOutlined />
                  {t('quotes.show.buttons.contract')}
                </Button>
              )}
              <Link
                to={{
                  pathname: `${routes.CUSTOMERS}/show/${quote?.customer._id}`
                }}
              >
                <Button type="primary">
                  <EyeOutlined />
                  {`${t(
                    `quotes.show.buttons.show${
                      quote?.customer?.status === 'PROSPECT'
                        ? '_PROSPECT'
                        : '_ACTIVE'
                    }`
                  )} `}
                </Button>
              </Link>
              {hasAccess('edit') &&
                acceptModifications.includes(quote?.status) && (
                  <Link to={{ pathname: `${routes.QUOTE}/edit/${id}` }}>
                    <Button type="primary">
                      <EditOutlined />
                      {`${t('buttons.edit')} `}
                    </Button>
                  </Link>
                )}
              {hasAccess('archive') && (
                <Popconfirm
                  title={t('datatable.column.action.delete.title')}
                  okText={t('datatable.column.action.delete.ok')}
                  okButtonProps={{ type: 'danger' }}
                  cancelText={t('datatable.column.action.delete.cancel')}
                  onConfirm={deleteQuote}
                  icon={<WarningOutlined />}
                >
                  <Button type="primary" danger>
                    <ContainerOutlined
                      style={{
                        color: '#ffff',
                        fontSize: 18
                      }}
                      type="delete"
                    />
                    {`${t(`quotes.show.buttons.archived`)} `}
                  </Button>
                </Popconfirm>
              )}
            </Flex>
          }
        />

        {!isLoading && (
          <Flex gap={16} vertical>
            <StepsCard data={quote} steps={steps} />
            <ShowPrincipalInformations data={quote} />

            <Divider>
              <Typography.Title
                level={4}
                style={{
                  color: 'var(--userColor)',
                  margin: 0
                }}
                strong={false}
              >
                {t('quotes.show.titles.services')}
              </Typography.Title>
            </Divider>
            <ShowServices
              data={quote}
              openModalAdjustedHours={null}
              resourceName="quote"
            />
            <Divider>
              <Typography.Title
                level={4}
                style={{
                  color: 'var(--userColor)',
                  margin: 0
                }}
                strong={false}
              >
                {t('quotes.show.titles.options')}
              </Typography.Title>
            </Divider>
            <Row gutter={[16, 16]}>
              <ShowHistoric data={quote} />
              <ShowComment data={quote} />
            </Row>
          </Flex>
        )}
      </ContentCustom>
    </>
  );
};
