import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Modal, Divider, Form, Row, Button } from 'antd';
import { CloseOutlined, CheckOutlined } from '@ant-design/icons';
import { useAuthContext } from '../../../contexts/AuthContext';
import { useErrorMessage } from '../../../utils/errorMessage';
import {
  formItemLayout,
  tailFormItemLayout
} from '../../../utils/constants/formLayout';
import { formDependencies } from '../../../utils/formDependencies';
import { useGenerateFormItem } from '../../../utils/generateFormItem';
import { useServiceFields } from './serviceFields';
import { showSuccessMessage } from '../../../utils/showSuccessMessage';

const DetailModal = ({
  isModalOpen,
  closeModal,
  detailToEdit,
  purpose,
  customer,
  quittanceId,
  customSubmit
}) => {
  const { t } = useTranslation();
  const { dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();
  const generateFields = useGenerateFormItem();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [formValues, setFormValues] = useState(null);
  const [form] = Form.useForm();

  const { fields, isFieldsLoading } = useServiceFields(customer, quittanceId);

  const handleChange = (values) => {
    const formData = form.getFieldsValue();
    setFormValues({ ...formData });
    formDependencies(
      dispatchAPI,
      message,
      values,
      formData,
      'quittances',
      form,
      setFormValues,
      t
    );
  };

  const updateService = async (body) => {
    setIsSubmitting(true);
    try {
      await dispatchAPI('PATCH', {
        url: `/quittances/detail/${formValues?._id}`,
        body
      });
      customSubmit();
      showSuccessMessage(t, 'quittances', 'update');
    } catch (e) {
      setIsSubmitting(false);
      message(e);
    }
  };

  const handleSubmit = async (values) => {
    await updateService(values);
  };

  useEffect(() => {
    if (detailToEdit && !isFieldsLoading) {
      form.setFieldsValue({ ...detailToEdit, taxes: customer?.vat });
      setFormValues({ ...detailToEdit, taxes: customer?.vat });
    }
  }, [detailToEdit, isFieldsLoading]);

  return (
    <Modal
      centered
      open={isModalOpen}
      onCancel={closeModal}
      title={`${t(`quittances.services.form.title.${purpose}`)}`}
      width={1100}
      footer={false}
    >
      <Divider />
      <Form
        {...formItemLayout}
        onFinish={handleSubmit}
        scrollToFirstError={{ block: 'center', behavior: 'smooth' }}
        onValuesChange={handleChange}
        form={form}
      >
        {fields.map((field) => generateFields('quittances', field))}
        <Form.Item {...tailFormItemLayout}>
          <Row justify="end">
            <Button
              style={{ margin: '0 8px' }}
              type="link"
              danger
              onClick={closeModal}
            >
              {`${t('buttons.cancel')} `}
              <CloseOutlined />
            </Button>
            <Button
              type="primary"
              htmlType="submit"
              loading={isSubmitting}
              disabled={isSubmitting}
            >
              {`${t('buttons.save')} `}
              <CheckOutlined />
            </Button>
          </Row>
        </Form.Item>
      </Form>
      <Divider />
    </Modal>
  );
};

DetailModal.propTypes = {
  isModalOpen: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  idFromOverlay: PropTypes.string,
  purpose: PropTypes.string,
  customer: PropTypes.shape({
    vat: PropTypes.number
  }),
  quittanceId: PropTypes.string,
  customSubmit: PropTypes.func.isRequired,
  detailToEdit: PropTypes.shape({
    _id: PropTypes.string
  })
};

DetailModal.defaultProps = {
  idFromOverlay: null,
  purpose: null,
  customer: {},
  quittanceId: null,
  detailToEdit: null
};

export default DetailModal;
