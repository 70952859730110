import { useTranslation } from 'react-i18next';
import { Tag } from 'antd';

import {
  qualityControlStatus,
  userCompanies
} from '../../../utils/constants/tagColors';

/**
 * Custom hook for generating columns data for displaying quality control informations in a list format.
 *
 * @hook
 * @param {object} enums - Enumerations data for quality control statuses
 * @returns {Array} Array of objects containing title, key, dataIndex, render, sorter, and filter for each column
 */
export const useColumns = (enums) => {
  const { t } = useTranslation();

  const getActiveContract = (contracts) =>
    contracts?.find((employment) => employment.status === 'ACTIVE');

  return [
    {
      title: t('quality.columns.collaborator'),
      key: 'collaborator',
      dataIndex: ['user'],
      render: (collaborator) =>
        `${collaborator?.first_name} ${
          collaborator?.usage_last_name?.toUpperCase() ||
          collaborator?.last_name?.toUpperCase()
        }`,
      sorter: true
    },
    {
      title: t('quality.columns.position'),
      key: 'position',
      dataIndex: ['user', 'employments'],
      render: (employments) => {
        const activeContract = getActiveContract(employments);
        return (
          <Tag color={userCompanies[activeContract?.company]}>
            {t(`employments.tags.${activeContract?.company}`)}
          </Tag>
        );
      },
      sorter: true,
      filters: enums?.positions?.map((l) => ({
        text: t(`employments.columns.statuses.${l}`),
        value: l
      }))
    },
    {
      title: t('quality.columns.job_requirement'),
      key: 'job_requirement',
      dataIndex: ['user', 'employments'],
      render: (employments) => getActiveContract(employments)?.level,
      sorter: true,
      filters: enums?.levels?.map((l) => ({
        text: t(`employments.columns.${l}`),
        value: l
      }))
    },
    {
      title: t('quality.columns.status'),
      key: 'quality_status',
      dataIndex: ['status'],
      render: (status) => (
        <Tag color={qualityControlStatus[status]}>
          {t(`quality.columns.statuses.${status}`)}
        </Tag>
      ),
      sorter: true,
      filters: enums?.status?.map((s) => ({
        text: t(`quality.columns.statuses.${s}`),
        value: s
      }))
    }
  ];
};
