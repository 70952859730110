import PropTypes from 'prop-types';
import { ColorPicker } from 'antd';

/**
 * Component for selecting an assessment.
 *
 * @component
 * @param {number} value - The value of the selected color
 * @param {function} onChange - The function to call when the color is changed
 * @param {boolean} isGrade - Whether the color is used to compute grades
 * @returns {JSX.Element} The color selector component
 */
export const ColorSelector = ({ value, onChange, isGrade }) => {
  const colors = ['#ffffff', '#30972e', '#f8ac18', '#ff0003', '#000000'];
  const grades = ['#000000', '#ff0003', '#f8ac18', '#30972e'];

  return (
    <ColorPicker
      size="small"
      trigger="none"
      value={(isGrade ? grades : colors)[value]}
      disabled={!onChange}
      onClick={() =>
        onChange && onChange((value + 1) % (isGrade ? grades : colors).length)
      }
    />
  );
};

ColorSelector.propTypes = {
  value: PropTypes.number,
  onChange: PropTypes.func,
  isGrade: PropTypes.bool
};

ColorSelector.defaultProps = {
  value: 0,
  onChange: null,
  isGrade: false
};
