import { useState } from 'react';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { DatePicker } from 'antd';
import { ListResource } from '../../components';
import { useColumns } from './columns';
import { headers } from './headers';
import QuoteContextProvider from './QuoteContext';
import { useFormatter } from '../../utils/formatter';
import { useAuthContext } from '../../contexts/AuthContext';

const { RangePicker } = DatePicker;

/**
 * Component for listing quotes with filtering options.
 *
 * @component
 * @returns {JSX.Element} Rendered ListQuotes component
 */

export const ListQuotes = () => {
  const { t } = useTranslation();
  const { user } = useAuthContext();
  const { quoteEnums, customerEnums, psMicro, sales } = QuoteContextProvider();
  const { formattedData } = useFormatter('quotes');
  const columns = useColumns(quoteEnums, customerEnums, psMicro, sales);
  const [filterDate, setFilterDate] = useState([]);

  return (
    <ListResource
      resourceName="quotes"
      columns={columns}
      withCreateButtonText={t('buttons.create_custom.quotes')}
      headers={user?.role.startsWith('admins:') && headers}
      populate="sale,micro_ps,services,customer.contacts"
      resourceModelName="Quote"
      withImportButton={false}
      extraEditCondition={(record) =>
        ['CREATED', 'QUOTE_SENT'].includes(record.status)
      }
      formatter={formattedData}
      extraQuery={
        filterDate?.length
          ? `created_at>=${dayjs(
              new Date(filterDate[0]).setHours(0, 0, 0, 0)
            ).toISOString()}&created_at<=${dayjs(
              new Date(filterDate[1]).setHours(23, 59, 59, 999)
            ).toISOString()}`
          : ''
      }
      extra
      extraFilters={
        <RangePicker
          format="DD-MM-YYYY"
          style={{ marginLeft: 16 }}
          onChange={setFilterDate}
        />
      }
      scroll={{ x: 2000 }}
    />
  );
};
