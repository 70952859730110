import { useState } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { useFields } from './fields';
import { CreateUpdateContainer } from '../../components/CreateUpdateContainer/CreateUpdateContainer';
import { useAuthContext } from '../../contexts/AuthContext';
import useArticleContext from './ArticleContext';
import { useErrorMessage } from '../../utils/errorMessage';

/**
 * A component for creating or updating articles with a specified purpose.
 *
 * @component
 * @param {Object} props - The component's props.
 * @param {string} props.purpose - The purpose of the article (e.g., 'create' or 'update').
 * @returns {JSX.Element} The JSX element representing the create/update article component.
 */

const CreateUpdateArticle = ({ purpose }) => {
  const { dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();
  const { id } = useParams();
  const { enums } = useArticleContext();
  const [content, setContent] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [fileList, setFileList] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [psMacro, setPsMacro] = useState([]);
  const [psMicro, setPsMicro] = useState([]);

  const uploadOrDeleteFile = async (file, localId, type, key) => {
    setIsLoading(true);
    const formData = new FormData();
    formData.append('values', JSON.stringify({ file: [file._id] }));
    if (type !== 'delete') formData.append(`${key}`, file);
    try {
      await dispatchAPI('PATCH', {
        url: `/news/file/${localId}?type=${type}&key=${key}&fileId=${file._id}`,
        body: formData
      });
    } catch (e) {
      message(e);
    }
    setRefresh(!refresh);
    setIsLoading(false);
  };

  const fields = useFields({
    enums,
    content,
    setContent,
    purpose,
    uploadOrDeleteFile,
    isLoading,
    id,
    fileList,
    setFileList,
    psMacro,
    setPsMacro,
    psMicro,
    setPsMicro
  });

  const config = {
    onGetResource: {
      setFields: (data) => {
        setFileList(data.file || []);
        setPsMacro(data.macro_ps);
        setPsMicro(data.micro_ps);
        return {
          ...data,
          content
        };
      }
    },
    onUpdateResource: {
      setBody: (data) => ({
        ...data,
        content
      })
    },
    onCreateResource: {
      setBody: (data) => {
        const formData = new FormData();
        formData.append('values', JSON.stringify({ ...data }));
        if (fileList) formData.append('file', fileList[0]);
        return formData;
      }
    }
  };

  return (
    <CreateUpdateContainer
      fields={fields}
      purpose={purpose}
      baseUrl="news"
      resource="news"
      populate="file"
      config={config}
      refresh={refresh}
    />
  );
};

CreateUpdateArticle.propTypes = {
  purpose: PropTypes.string.isRequired
};

export default CreateUpdateArticle;
