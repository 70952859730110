import PropTypes from 'prop-types';
import { Button, Flex, Popconfirm } from 'antd';
import { Link, useLocation } from 'react-router-dom';
import {
  CheckOutlined,
  ContainerOutlined,
  EditOutlined,
  WarningOutlined
} from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { routes } from '../../../utils/constants/adminRoutes';
import { ClosureModal } from './ClosureModal';
import useQualityControlContext from '../QualityControlContext';
import { isAuthorized } from '../../../utils/constants/authorizedMenu';

/**
 * Component for displaying extra buttons for a quality control (close, edit, archive)
 *
 * @component
 * @param {string} id - The id of the quality control
 * @param {function} closeQualityControl - The function to close the quality control
 * @param {string} status - The status of the quality control
 * @returns {JSX.Element} The extra buttons component
 */
export const ExtraButtons = ({
  id,
  closeQualityControl,
  status,
  qualityControl
}) => {
  const { t } = useTranslation();
  const location = useLocation();
  const { pathname } = location;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { archiveQualityControl, user } = useQualityControlContext();
  const myOwnQC = user?._id === qualityControl?.user?._id;

  const hasAccess = (purpose) => isAuthorized(pathname, user?.role, purpose);

  return (
    <Flex gap={8}>
      <ClosureModal
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        closeQualityControl={closeQualityControl}
      />
      {status === 'ON_GOING' && (
        <>
          {hasAccess('close') && (
            <Button
              type="primary"
              icon={<CheckOutlined />}
              onClick={() => setIsModalOpen(true)}
            >
              {t('quality.close')}
            </Button>
          )}
          {hasAccess('edit') && !myOwnQC && (
            <Link to={{ pathname: `${routes.QUALITY_CONTROL}/edit/${id}` }}>
              <Button type="primary">
                {`${t('buttons.edit')} `}
                <EditOutlined />
              </Button>
            </Link>
          )}
        </>
      )}
      {status !== 'ARCHIVED' && hasAccess('archive') && (
        <Popconfirm
          title={t('datatable.column.action.delete.title')}
          okText={t('datatable.column.action.delete.ok')}
          okButtonProps={{ type: 'danger' }}
          cancelText={t('datatable.column.action.delete.cancel')}
          onConfirm={() => archiveQualityControl(id)}
          icon={<WarningOutlined />}
        >
          <Button
            danger
            type="link"
            icon={
              <ContainerOutlined
                style={{ color: 'var(--errorColor)', fontSize: 14 }}
                type="delete"
              />
            }
          >
            {t(`quality.archive`)}
          </Button>
        </Popconfirm>
      )}
    </Flex>
  );
};

ExtraButtons.propTypes = {
  id: PropTypes.string.isRequired,
  closeQualityControl: PropTypes.func,
  status: PropTypes.string,
  qualityControl: PropTypes.shape({
    user: PropTypes.shape({
      _id: PropTypes.string
    })
  })
};

ExtraButtons.defaultProps = {
  closeQualityControl: () => {},
  status: '',
  qualityControl: null
};
