import { ColorSelector } from '../../ColorSelector';
import { formatValue } from '../../../../utils/formatNumber';

/**
 * Custom hook to get the list content for the productivities
 *
 * @hook
 * @param {object} productivities - Productivities data object
 * @returns {Array} Array of objects containing label, span, and content for each field
 */
export const useListContent = (productivities) => [
  ...(productivities?.flatMap((p) => [
    {
      label: 'quality.form.productivities.average',
      span: 12,
      content: p?.average,
      id: p._id
    },
    {
      label: 'quality.form.productivities.range_concerned',
      span: 12,
      content:
        p?.range_concerned?.length === 2
          ? `${formatValue(p?.range_concerned[0], 'date')} - ${formatValue(
              p?.range_concerned[1],
              'date'
            )}`
          : '-',
      id: p._id
    },
    {
      label: 'quality.form.productivities.assessment',
      span: 24,
      content: <ColorSelector value={p?.assessment} isGrade />,
      id: p._id
    }
  ]) || [])
];
