import { useEffect } from 'react';
import {
  BrowserRouter,
  Route,
  Routes,
  Navigate,
  useLocation
} from 'react-router-dom';
import PropTypes from 'prop-types';
import { outOfNavRoutes, routes } from '../utils/constants/adminRoutes';
import { Login } from '../routes/login';
import { Home } from '../routes/home/Home';
import { Exception } from '../components';
import { useAuthContext } from '../contexts/AuthContext';
import { GuestLayout } from '../components/layouts/GuestLayout';
import { ProfileRouter } from '../routes/profile/ProfileRouter';
import { Settings } from '../routes/settings/settings';
import { UserTheme } from '../assets/styles/Theme/UserTheme';
import { InterventionRouter } from '../routes/interventions/InterventionRouter';
import { CustomerRouterGuest } from '../routes/customers/CustomerRouterGuest';

const RequireAuth = ({ isValid }) => {
  const location = useLocation();

  if (!isValid) {
    return Navigate({
      to: {
        pathname: outOfNavRoutes.LOGIN
      },
      state: { from: location }
    });
  }

  return <GuestLayout />;
};

RequireAuth.propTypes = {
  isValid: PropTypes.bool.isRequired
};

export const GuestRouter = () => {
  const { isValid } = useAuthContext();

  useEffect(() => {
    const body = document.body;
    if (body) {
      body.className = 'user';
    }
  }, []);

  return (
    <BrowserRouter>
      <UserTheme />
      <Routes>
        <Route
          path={`${outOfNavRoutes.LOGIN}/:purpose/:token`}
          element={<Login />}
        />
        <Route path={outOfNavRoutes.LOGIN} element={<Login />} />
        <Route path={`${outOfNavRoutes.REDIRECT}/*`} element={<Login />} />
        <Route element={<RequireAuth isValid={isValid} />}>
          <Route
            path={`${outOfNavRoutes.PROFILE}/*`}
            element={<ProfileRouter />}
          />
          <Route path={outOfNavRoutes.SETTINGS} element={<Settings />} />
          <Route path={routes.HOME} element={<Home />} />
          <Route
            path={`${routes.CUSTOMERS}/*`}
            element={<CustomerRouterGuest />}
          />
          <Route
            path={`${routes.INTERVENTIONS}/*`}
            element={<InterventionRouter />}
          />
          <Route path="*" element={<Exception />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
};
