import PropTypes from 'prop-types';
import { Card, Col } from 'antd';
import { useTranslation } from 'react-i18next';

export const Comment = ({ comment }) => {
  const { t } = useTranslation();

  return (
    <Col xs={24} lg={12}>
      <Card title={t('quittances.show.titles.comment')}>
        <p>{comment || 'Aucun commentaire'}</p>
      </Card>
    </Col>
  );
};

Comment.propTypes = {
  comment: PropTypes.arrayOf(PropTypes.string)
};

Comment.defaultProps = {
  comment: undefined
};
