import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Drawer } from 'antd';
import { CreateUpdateContainer } from '../../components/CreateUpdateContainer/CreateUpdateContainer';
import useFields from './fields';
import useCalendarContext from './CalendarContext';

const EventDrawer = ({
  showDrawer,
  setShowDrawer,
  users,
  purpose,
  closeOverlay,
  idFromOverlay,
  fileList,
  setFileList
}) => {
  const { t } = useTranslation();
  const { fields } = useFields(
    users,
    purpose,
    setFileList,
    fileList,
    idFromOverlay
  );

  const { isLoadingEnums, setType, type } = useCalendarContext();

  const config = {
    onCreateResource: {
      setBody: (data) => {
        const formData = new FormData();
        formData.append('values', JSON.stringify({ ...data, type }));
        if (fileList) formData.append('file', fileList[0]);
        return formData;
      }
    }
  };

  return (
    <Drawer
      centered
      title={t(`calendar.titles.${purpose}`)}
      open={showDrawer}
      onClose={() => {
        setType(null);
        setShowDrawer(false);
      }}
      width={700}
      footer={false}
    >
      <CreateUpdateContainer
        fields={fields}
        loadingFields={isLoadingEnums}
        purpose={purpose}
        baseUrl="events"
        resource="events"
        isOverlay
        customNavigate={closeOverlay}
        withHeader={false}
        idFromOverlay={idFromOverlay}
        config={config}
        layout="vertical"
        drawer
      />
    </Drawer>
  );
};

EventDrawer.propTypes = {
  showDrawer: PropTypes.bool.isRequired,
  setShowDrawer: PropTypes.func.isRequired,
  users: PropTypes.arrayOf(PropTypes.shape({})),
  purpose: PropTypes.string,
  closeOverlay: PropTypes.func.isRequired,
  idFromOverlay: PropTypes.string,
  fileList: PropTypes.shape({}),
  setFileList: PropTypes.func
};

EventDrawer.defaultProps = {
  users: null,
  purpose: null,
  idFromOverlay: null,
  fileList: null,
  setFileList: null
};

export default EventDrawer;
