import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import { Modal } from 'antd';
import { CreateUpdateContainer } from '../../../components/CreateUpdateContainer/CreateUpdateContainer';
import useFields from './fields';

const PaymentModal = ({
  isModalOpen,
  closeModal,
  idFromOverlay,
  customNavigate,
  purpose,
  quittance,
  enums,
  bankReconciliations,
  customers,
  condition
}) => {
  const { t } = useTranslation();
  const [selectedType, setSelectedType] = useState('CLASSIC');
  const [selectedCustomer, setSelectedCustomer] = useState(null);

  const { fields, isFieldsLoading } = useFields(
    quittance,
    enums,
    purpose,
    bankReconciliations,
    customers,
    selectedType,
    setSelectedType,
    selectedCustomer,
    setSelectedCustomer,
    condition
  );

  const config = {
    onGetResource: {
      setFields: (data) => {
        setSelectedType(data.type);
        setSelectedCustomer(data?.customer);
        return {
          ...data,
          operation_date: data.operation_date && dayjs(data.operation_date)
        };
      }
    },
    onUpdateResource: {
      setBody: (data) => ({
        ...data,
        micro_ps: selectedCustomer.micro_ps,
        macro_ps: selectedCustomer.macro_ps
      })
    },
    onCreateResource: {
      setBody: (data) => ({
        ...data,
        micro_ps: selectedCustomer.micro_ps,
        macro_ps: selectedCustomer.macro_ps
      })
    }
  };

  return (
    <Modal
      centered
      open={isModalOpen}
      onCancel={closeModal}
      title={`${t(
        `payments.modal.title.${purpose}${
          selectedType === 'CREDIT' ? '_credit' : ''
        }`
      )}`}
      width={900}
      footer={false}
    >
      <CreateUpdateContainer
        layout="vertical"
        fields={fields}
        loadingFields={isFieldsLoading}
        purpose={purpose}
        baseUrl="payments"
        resource="payments"
        config={config}
        withHeader={false}
        isOverlay
        customNavigate={customNavigate}
        idFromOverlay={idFromOverlay}
        drawer
      />
    </Modal>
  );
};

PaymentModal.propTypes = {
  isModalOpen: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  purpose: PropTypes.string,
  idFromOverlay: PropTypes.string,
  customNavigate: PropTypes.func,
  quittance: PropTypes.shape({}),
  condition: PropTypes.string,
  enums: PropTypes.shape({}),
  bankReconciliations: PropTypes.arrayOf(PropTypes.shape({})),
  customers: PropTypes.arrayOf(PropTypes.shape({}))
};

PaymentModal.defaultProps = {
  idFromOverlay: null,
  customNavigate: undefined,
  purpose: null,
  quittance: {},
  condition: null,
  enums: {},
  bankReconciliations: [],
  customers: null
};

export default PaymentModal;
