import { useState, useEffect, useRef } from 'react';
import { Select, Switch, Form, Input, Button, Divider } from 'antd';
import { CloseCircleOutlined } from '@ant-design/icons';
import JoditEditor from 'jodit-react';
import { useTranslation } from 'react-i18next';

const { Option } = Select;

const useFields = (
  actions,
  selectedRowKeys,
  setContent,
  setFooter,
  footer,
  content,
  templates,
  isLoading,
  useCaseFilter,
  contacts,
  interventionId,
  formValues,
  customerEnums,
  company,
  emailType,
  setEmailType
) => {
  const { t } = useTranslation();
  const [fseNumbersSelected, setFseNumbersSelected] = useState([]);
  const [isFieldsLoading, setIsFieldsLoading] = useState(true);

  const onsearch = (input, option) => {
    const result = option?.children.toLowerCase();
    return result.includes(input.toLowerCase());
  };

  useEffect(() => {
    if (actions && selectedRowKeys?.length > 0) {
      const filteredActionIds = actions
        .filter((action) => selectedRowKeys.includes(action._id))
        .map((action) => action._id);
      setFseNumbersSelected(filteredActionIds);
      setIsFieldsLoading(false);
    }

    const statusBar = document.getElementsByClassName('jodit-status-bar');

    for (let i = 0; i < statusBar.length; i += 1) {
      statusBar[i].style.display = 'none';
    }
  }, [actions]);

  const editorRef = useRef(null);

  const fields = [
    ...(fseNumbersSelected?.length > 0
      ? [
          {
            name: ['fses_selected'],
            rules: [
              {
                required: true
              }
            ],
            initialValue: fseNumbersSelected,
            input: (
              <Select
                loading={isFieldsLoading}
                mode="multiple"
                allowClear
                filterOption={(input, option) => onsearch(input, option)}
              >
                {(actions || []).map((action) => (
                  <Option key={action._id} value={action._id}>
                    {action?.new_fse_number || action?.fse_number}
                  </Option>
                ))}
              </Select>
            )
          }
        ]
      : []),
    {
      name: ['mail_or_email'],
      input: (
        <Switch
          checkedChildren={t('switch.email')}
          unCheckedChildren={t('switch.mail')}
          onChange={() => setEmailType(!emailType)}
        />
      )
    },
    ...(emailType
      ? [
          {
            name: ['extra_contacts'],
            input: (
              <Form.List name="extra_contacts" initialValue={[{}]}>
                {(extra_contacts, { add, remove }) => (
                  <>
                    {extra_contacts.map((field, index) => (
                      <div key={field.name}>
                        <Form.Item
                          {...field}
                          key={[field.name, 'email']}
                          name={[field.name, 'email']}
                          label={t('emails.form.extra.email')}
                          rules={[{ required: true }]}
                        >
                          <Input type="email" />
                        </Form.Item>
                        {index > 0 && (
                          <CloseCircleOutlined
                            style={{
                              position: 'absolute',
                              top: '5px',
                              right: '5px',
                              fontSize: '16px',
                              color: 'red',
                              cursor: 'pointer'
                            }}
                            onClick={() => remove(field.name)}
                          />
                        )}
                        <Form.Item
                          {...field}
                          key={[field.name, 'first_name']}
                          name={[field.name, 'first_name']}
                          label={t('emails.form.extra.first_name')}
                          rules={[{ required: true }]}
                        >
                          <Input />
                        </Form.Item>
                        <Form.Item
                          {...field}
                          key={[field.name, 'last_name']}
                          name={[field.name, 'last_name']}
                          label={t('emails.form.extra.last_name')}
                          rules={[{ required: true }]}
                        >
                          <Input />
                        </Form.Item>
                        <Divider />
                      </div>
                    ))}
                    <Form.Item>
                      <Button type="primary" onClick={() => add()}>
                        {t('emails.form.extra.add')}
                      </Button>
                    </Form.Item>
                  </>
                )}
              </Form.List>
            )
          },
          {
            name: ['to'],
            input: (
              <Select
                loading={isLoading}
                mode="multiple"
                allowClear
                showSearch
                filterOption={(input, option) => onsearch(input, option)}
              >
                {(contacts || [])
                  .filter((f) => f.email)
                  .map((contact) => (
                    <Option key={contact?._id} value={contact?._id}>
                      {`${contact?.first_name} ${contact?.last_name} (${contact?.email})`}
                    </Option>
                  ))}
              </Select>
            )
          }
        ]
      : []),
    {
      name: ['gender_insured'],
      rules: [
        {
          required: true
        }
      ],
      input: (
        <Select loading={isFieldsLoading}>
          {['Madame', 'Monsieur'].map((gender) => (
            <Option key={gender} value={gender}>
              {gender}
            </Option>
          ))}
        </Select>
      )
    },
    {
      name: ['name_insured'],
      rules: [
        {
          required: true
        }
      ]
    },
    {
      name: ['full_address_insured'],
      rules: [
        {
          required: true
        }
      ]
    },
    {
      name: ['additional_address_insured']
    },
    {
      name: ['postal_code_insured'],
      rules: [
        {
          required: true
        }
      ]
    },
    {
      name: ['city_insured'],
      rules: [
        {
          required: true
        }
      ]
    },
    {
      name: ['country_insured'],
      rules: [
        {
          required: true
        }
      ]
    },
    {
      name: ['gender_beneficiary'],
      rules: [
        {
          required: true
        }
      ],
      input: (
        <Select loading={isFieldsLoading}>
          {['Madame', 'Monsieur'].map((gender) => (
            <Option key={gender} value={gender}>
              {gender}
            </Option>
          ))}
        </Select>
      )
    },
    {
      name: ['name_beneficiary'],
      rules: [
        {
          required: true
        }
      ]
    },
    {
      name: ['mailTypes'],
      rules: [
        {
          required: true
        }
      ],
      input: (
        <Select
          loading={isFieldsLoading}
          allowClear
          showSearch
          filterOption={(input, option) => onsearch(input, option)}
        >
          {['INSURED', 'PAYER_ORGANIZATION', 'CUSTOMER'].map((type) => (
            <Option key={type} value={type}>
              {t(`mail.form.enums.${type}`)}
            </Option>
          ))}
        </Select>
      )
    },
    {
      name: ['company'],
      rules: [{ required: true }],
      initialValue: company,
      input: (
        <Select
          loading={isLoading}
          allowClear
          showSearch
          filterOption={(input, option) => onsearch(input, option)}
          disabled
        >
          {(customerEnums?.companies || []).map((comp) => (
            <Option key={comp} value={comp}>
              {t(`employments.tags.${comp}`)}
            </Option>
          ))}
        </Select>
      )
    },
    {
      name: ['template'],
      rules: [{ required: true }],
      input: (
        <Select
          loading={isLoading}
          disabled={
            !formValues?.name_insured ||
            !formValues?.name_beneficiary ||
            !formValues?.company ||
            !formValues?.gender_insured ||
            !formValues?.full_address_insured ||
            !formValues?.postal_code_insured ||
            !formValues?.city_insured ||
            !formValues?.country_insured ||
            !formValues?.gender_beneficiary
          }
          allowClear
          showSearch
          filterOption={(input, option) => onsearch(input, option)}
          onClear={() => {
            setContent('');
            setFooter('');
          }}
        >
          {(templates || [])
            .filter(
              (f) =>
                f.use_case === useCaseFilter &&
                f?.mailTypes?.includes(formValues?.mailTypes)
            )
            .map((template) => (
              <Option key={template._id} value={template._id}>
                {template?.title}
              </Option>
            ))}
        </Select>
      )
    },
    {
      name: ['content'],
      rules: [{ required: true }],
      input: (
        <JoditEditor
          ref={editorRef}
          placeholder="Commencez votre texte..."
          value={content}
          onChange={setContent}
          style={{ height: '300px', marginBottom: '20px', minWidth: 640 }}
        />
      )
    },
    {
      name: ['footer'],
      rules: [{ required: true }],
      input: (
        <JoditEditor
          ref={editorRef}
          placeholder="Commencez votre texte..."
          value={footer}
          onChange={setFooter}
          style={{ height: '300px', marginBottom: '20px', minWidth: 640 }}
          config={{
            readonly: true,
            toolbar: false
          }}
        />
      )
    },
    {
      name: ['interventionId'],
      initialValue: interventionId,
      hidden: true
    }
  ];

  return {
    fields,
    isFieldsLoading
  };
};

export default useFields;
