import { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { Button, Flex, Tag, Modal } from 'antd';
import {
  DesktopOutlined,
  PlayCircleOutlined,
  RightOutlined,
  PlusCircleOutlined,
  SendOutlined
} from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import ImailLogo from '../../assets/images/iMail.png';
import { PageHeaderCustom } from '../../components';
import InterventionContext from './InterventionContext';
import { routes } from '../../utils/constants/adminRoutes';

/**
 * PageHeader component
 *
 * @component
 * @param {Object} props - The props of the component
 * @param {Object} props.intervention - The intervention object.
 * @param {Object} props.interventionsStatus - The intervention status object.
 * @param {Function} props.patchIntervention - The patchIntervention function.
 * @param {Function} props.patchPauses - The patchPauses function.
 * @param {Function} props.setIsQualityControlOpen - The setIsQualityControlOpen function.
 * @param {String} props.id - The intervention id.
 * @returns {React.Component} The PageHeader component
 */
const PageHeader = ({
  intervention,
  interventionsStatus,
  startIntervention,
  patchPauses,
  setIsQualityControlOpen,
  id,
  hasAccess
}) => {
  const { t } = useTranslation();
  const { openInNewTab, sendReport, user } = InterventionContext();

  const availableStatusStartButton = ['PLANNED', 'SUSPENDED', 'OPEN'];

  const isMounted = useRef(true);

  useEffect(
    () => () => {
      isMounted.current = false;
    },
    []
  );

  const handleConfirmSend = (CancelBtn) => (
    <>
      <Button
        onClick={() => {
          Modal.destroyAll();
          const body = {
            customer: intervention?.customer?._id,
            link: `interventions/show/${id}`
          };
          sendReport(body);
        }}
      >
        {t('interventions.form.modal.send.button')}
      </Button>
      <CancelBtn />
    </>
  );

  return (
    <PageHeaderCustom
      title={
        <span>
          {`${t('interventions.show.title')} N° ${intervention?.code} - ${
            intervention?.company_name
          } `}
          <Tag color={interventionsStatus[intervention?.status]}>
            {t(`interventions.form.enums.${intervention?.status}`)}
          </Tag>
        </span>
      }
      extra={
        <Flex gap={8}>
          {hasAccess('link') && (
            <>
              <Button
                type="link"
                onClick={() =>
                  openInNewTab('https://tpa.imail.solutions/imail')
                }
              >
                <img alt="ImailLogo" src={ImailLogo} />
              </Button>
              <Button
                type="primary"
                onClick={() => openInNewTab('https://alyss.screenconnect.com')}
              >
                <DesktopOutlined />
              </Button>
            </>
          )}
          {!user.role.startsWith('guests') &&
            availableStatusStartButton.includes(intervention?.status) &&
            (intervention?.pauses?.length &&
            intervention?.pauses?.[intervention.pauses.length - 1]
              ?.start_time &&
            !intervention?.pauses?.[intervention.pauses.length - 1]
              ?.end_time ? (
              <Button type="primary" danger onClick={() => patchPauses(id)}>
                <PlayCircleOutlined style={{ fontSize: 18 }} />
                {`${t(`interventions.show.buttons.start`)} `}
              </Button>
            ) : (
              <Button
                type="primary"
                onClick={() =>
                  !intervention?.start_date
                    ? startIntervention(id)
                    : patchPauses(id)
                }
              >
                <RightOutlined style={{ fontSize: 18 }} />
                {`${t(
                  `interventions.show.buttons.${
                    !intervention?.start_date ? 'start_intervention' : 'stop'
                  }`
                )} `}
              </Button>
            ))}
          {intervention?.status === 'CLOSED' && hasAccess('create') && (
            <>
              <Button
                type="primary"
                onClick={() => setIsQualityControlOpen(true)}
              >
                <PlusCircleOutlined />
                {t(`interventions.show.buttons.control`)}
              </Button>
              <Button
                type="primary"
                onClick={() => {
                  Modal.confirm({
                    title: t('interventions.form.modal.send.title'),
                    content: t('interventions.form.modal.send.confirm'),
                    centered: true,
                    maskClosable: false,
                    footer: (_, { CancelBtn }) => handleConfirmSend(CancelBtn)
                  });
                }}
              >
                <SendOutlined />
                {t(`interventions.show.buttons.send`)}
              </Button>
            </>
          )}

          {intervention?.status === 'CLOSED' &&
            user.role === 'guests:GUEST' && (
              <Link to={`${routes.INTERVENTIONS}/show/${id}`}>
                <Button type="primary">{t(`buttons.download`)}</Button>
              </Link>
            )}
        </Flex>
      }
    />
  );
};

export default PageHeader;

PageHeader.propTypes = {
  intervention: PropTypes.shape({
    code: PropTypes.string,
    company_name: PropTypes.string,
    status: PropTypes.string,
    customer: PropTypes.shape({
      _id: PropTypes.string.isRequired
    }),
    pauses: PropTypes.arrayOf(
      PropTypes.shape({
        start_time: PropTypes.string,
        end_time: PropTypes.string
      })
    ),
    start_date: PropTypes.string
  }),
  interventionsStatus: PropTypes.shape({}).isRequired,
  startIntervention: PropTypes.func.isRequired,
  patchPauses: PropTypes.func.isRequired,
  setIsQualityControlOpen: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  hasAccess: PropTypes.func.isRequired
};

PageHeader.defaultProps = {
  intervention: null
};
