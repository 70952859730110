import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  useCallback
} from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import { message as antdMessage } from 'antd';
import { useTranslation } from 'react-i18next';
import duration from 'dayjs/plugin/duration';
import { useAuthContext } from '../../contexts/AuthContext';
import { useErrorMessage } from '../../utils/errorMessage';
import { showSuccessMessage } from '../../utils/showSuccessMessage';
import { routes } from '../../utils/constants/adminRoutes';

dayjs.extend(duration);

const InterventionContext = createContext({});

/**
 * Context provider component for managing intervention-related data.
 * This context provides various state values and methods related to interventions.
 *
 * @component
 * @param {object} props - React component props
 * @param {React.ReactNode} props.children - The child components that will have access to the context
 * @returns {React.ReactNode} Wrapped children with the quote context
 */
export const InterventionContextProvider = ({ children }) => {
  const { t } = useTranslation();
  const { dispatchAPI, user } = useAuthContext();
  const { message } = useErrorMessage();
  const navigate = useNavigate();
  const [intervention, setIntervention] = useState(null);
  const [actions, setActions] = useState([]);
  const [macroPs, setMacroPs] = useState({});
  const [forceRefresh, setForceRefresh] = useState(false);
  const [interventionEnums, setInterventionEnums] = useState({});
  const [actionEnums, setActionEnums] = useState({});
  const [leadersAndGtp, setLeadersAndGtp] = useState([]);
  const [isFieldsLoading, setIsFieldsLoading] = useState(true);
  const [psMicro, setPsMicro] = useState([]);
  const [contacts, setContacts] = useState([]);
  const [psMacro, setPsMacro] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [customerEnums, setCustomerEnums] = useState({});
  const [contracts, setContracts] = useState([]);
  const [services, setServices] = useState([]);
  const [organizations, setOrganizations] = useState([]);
  const [tasks, setTasks] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [formValues, setFormValues] = useState(null);
  const [softwares, setSoftwares] = useState([]);
  const [customerComments, setCustomerComments] = useState(null);
  const [addComment, setAddComment] = useState(false);
  const [newComment, setNewComment] = useState(null);
  const [rejections, setRejections] = useState([]);
  const [outstandings, setOutstandings] = useState([]);
  const [firstOutstanding, setFirstOutstanding] = useState(null);
  const [listActions, setListActions] = useState(null);
  const [enums, setEnums] = useState();
  const [qualityControlFiles, setQualityControlFiles] = useState({});
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [forceActionsRefresh, setForceActionsRefresh] = useState(false);
  const [isMailModalOpen, setIsMailModalOpen] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [outstandingPurpose, setOutstandingPurpose] = useState('create');
  const [isOutstandingModalOpen, setIsOutstandingModalOpen] = useState(false);
  const [forceWebSitesRefresh, setForceWebSitesRefresh] = useState(false);
  const [isActionReminder, setIsActionReminder] = useState(false);
  const [actionPurpose, setActionPurpose] = useState(null);
  const [idActionFromOverlay, setIdActionFromOverlay] = useState(null);
  const [isActionModalOpen, setIsActionModalOpen] = useState(false);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [type, setType] = useState('');
  const [isWebsiteModalOpen, setIsWebsiteModalOpen] = useState(false);
  const disabledAction = intervention?.status !== 'OPEN';
  const [rejectionTypes, setRejectionTypes] = useState([]);
  const [rejectionReasons, setRejectionReasons] = useState([]);
  const [template, setTemplates] = useState([]);
  const [isEmailModalOpen, setIsEmailModalOpen] = useState(false);

  const getTemplates = useCallback(async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/template-documents?status=VALIDATED&use_case=ACTION`
      });
      const templatesInfo = data.map((item) => ({
        id: item._id,
        title: item?.title
      }));
      setTemplates(templatesInfo);
    } catch (e) {
      message(e);
    }
  }, []);

  const getInterventionEnums = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/interventions/enums`
      });
      setInterventionEnums(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getSoftwares = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/Software`
      });
      setSoftwares(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getRejectionTypes = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/rejectiontype`
      });
      setRejectionTypes(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getRejectionReasons = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/rejectionreason?sort=title`
      });
      setRejectionReasons(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getActionEnums = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/actions/enums`
      });
      setActionEnums(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getEnums = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/interventions/enums`
      });
      setEnums(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getMicroPs = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/Psmicro?sort=title`
      });
      setPsMicro(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getMacroPs = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/Psmacro?sort=title`
      });
      setPsMacro(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getTasksType = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/Task`
      });
      setTasks(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getListActions = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/Listaction?sort=title`
      });
      setListActions(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getLeadersAndGtp = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `users?role=users:LEADER-USER,users:USER&populate=employments&noFilterByRole=${true}`
      });
      const activeLeadersAndGtp = data.filter(
        (leader) => leader.employments && leader.employments.status === 'ACTIVE'
      );

      setLeadersAndGtp(activeLeadersAndGtp);
    } catch (error) {
      message(error);
    }
  };

  const getCustomers = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: '/customers?sort=ps_number&populate=contacts'
      });
      setCustomers(data);
      const updatedContacts = [];
      data.forEach((item) => {
        item.contacts.forEach((contact) => {
          const updatedContact = {
            ...contact,
            company_name: item.company_name,
            ps_number: item.ps_number
          };
          updatedContacts.push(updatedContact);
        });
      });
      setContacts(updatedContacts);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getCustomerEnums = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/customers/enums`
      });
      setCustomerEnums(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getContracts = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/contracts`
      });
      setContracts(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
    return true;
  };

  const getServices = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/service?status=ACTIVE&populate=contract`
      });
      setServices(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
    return true;
  };

  const getOrganizations = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/organizations?sort=name`
      });
      setOrganizations(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
    return true;
  };

  const patchPauses = async (id) => {
    try {
      await dispatchAPI('PATCH', {
        url: `/interventions/pause/${id}`,
        body: { status: intervention?.status }
      });
      setForceRefresh(!forceRefresh);
      showSuccessMessage(
        t,
        'interventions',
        intervention?.status === 'SUSPENDED' ? 'open' : 'pause'
      );
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const patchIntervention = async (id, body) => {
    try {
      await dispatchAPI('PATCH', {
        url: `/interventions/${id}`,
        body
      });
      setForceRefresh(!forceRefresh);
      showSuccessMessage(t, 'interventions', 'update');
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const startIntervention = async (id) => {
    try {
      await dispatchAPI('PATCH', {
        url: `/interventions/start/${id}`
      });
      setForceRefresh(!forceRefresh);
      showSuccessMessage(t, 'interventions', 'update');
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const closeIntervention = async (id, body) => {
    try {
      await dispatchAPI('PATCH', {
        url: `/interventions/closed/${id}`,
        body
      });
      setForceRefresh(!forceRefresh);
      showSuccessMessage(t, 'interventions', 'update');
      return true;
    } catch (e) {
      if (e.response) message(e.response.status);
      return false;
    }
  };

  const sendReport = async (body) => {
    try {
      await dispatchAPI('POST', {
        url: '/interventions/send-report',
        body
      });
      showSuccessMessage(t, 'report', 'send');
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const deleteFile = async (fileID) => {
    try {
      await dispatchAPI(`DELETE`, {
        url: `interventions/${intervention?._id}?fileId=${fileID}`
      });
      setForceRefresh(!forceRefresh);
      showSuccessMessage(t, 'interventions', 'archive');
    } catch (error) {
      message(error);
    }
  };

  const patchComment = async (id) => {
    try {
      const newCustomerComments = [
        ...customerComments,
        { title: newComment, date: dayjs() }
      ];
      await dispatchAPI('PATCH', {
        url: `/customers/${id}`,
        body: { customer_comments: newCustomerComments }
      });
      setCustomerComments(newCustomerComments);
      setAddComment(false);
      setNewComment(null);
      showSuccessMessage(t, 'comments', 'update');
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const calculateInitialTimer = () => {
    if (intervention?.start_date) {
      const totalPauseDuration =
        intervention?.pauses?.reduce((total, pause) => {
          const start = dayjs(pause.start_time);
          const end = dayjs(pause.end_time);
          return total + end.diff(start);
        }, 0) || 0;

      const totalDurationSinceStart = dayjs().diff(
        dayjs(intervention.start_date)
      );
      const effectiveDuration = totalDurationSinceStart - totalPauseDuration;
      return dayjs.duration(effectiveDuration);
    }
    return dayjs.duration(0);
  };

  const openInNewTab = (url) => {
    const newWindow = window.open(url, '_blank');
    if (newWindow) newWindow.focus();
  };

  const [timer, setTimer] = useState(calculateInitialTimer);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = (data) => {
    setIsModalOpen(false);
    setForceRefresh(!forceRefresh);
    if (data && data?._id && data?.direct_intervention) {
      navigate(`${routes.INTERVENTIONS}/show/${data?._id}`);
    }
  };

  const getRejections = async () => {
    const start = dayjs().subtract(4, 'month').startOf('month').toISOString();
    const end = dayjs().subtract(1, 'month').endOf('month').toISOString();
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/rejections?customer=${intervention?.customer?._id}&period_concerned>=${start}&period_concerned<=${end}`
      });
      setRejections(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getOutstandings = async () => {
    const start = dayjs().subtract(4, 'month').startOf('month').toISOString();
    const end = dayjs().endOf('day').toISOString();
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/outstandings?populate=customer&customer=${intervention?.customer?._id}&date>=${start}&date<=${end}`
      });
      setOutstandings(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getFirstOutstanding = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/outstandings?customer=${intervention?.customer?._id}&sort=date&limit=1`
      });
      setFirstOutstanding(data[0]);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
    return true;
  };

  const getPsMacro = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/Psmacro/${intervention?.customer?.macro_ps}`
      });
      setMacroPs(data);
    } catch (e) {
      message(e);
    }
  };

  const getActions = useCallback(async () => {
    try {
      if (intervention?.customer?._id) {
        const { data } = await dispatchAPI('GET', {
          url: `/actions?customer=${intervention?.customer?._id}&type=INVOICE&populate=rejection_reason rejection_type issuer_organization action created_by`
        });
        setActions(data);
      }
    } catch (e) {
      message(e);
    }
  }, [intervention]);

  const onCancelEmailSent = async (idActionReminder) => {
    try {
      if (disabledAction) {
        antdMessage.warning(
          t(`interventions.show.error.${intervention?.status}`)
        );
        return;
      }
      await dispatchAPI('PATCH', {
        url: `/actions/${idActionReminder}`,
        body: { email_sent: false }
      });
      setForceActionsRefresh(!forceActionsRefresh);
      showSuccessMessage(t, 'cancel', 'effect');
    } catch (error) {
      message(error);
    }
  };

  const onArchived = async (idAction) => {
    if (disabledAction) {
      antdMessage.warning(
        t(`interventions.show.error.${intervention?.status}`)
      );
      return;
    }
    try {
      await dispatchAPI('PATCH', {
        url: `/actions/${idAction}`,
        body: { status: 'ARCHIVED' }
      });
      setForceActionsRefresh(!forceActionsRefresh);
      showSuccessMessage(t, 'actions', 'archive');
    } catch (error) {
      message(error);
    }
  };

  const openMailModal = () => {
    if (disabledAction) {
      antdMessage.warning(
        t(`interventions.show.error.${intervention?.status}`)
      );
      return;
    }
    setIsMailModalOpen(true);
  };

  const closeMailModal = () => {
    setIsMailModalOpen(false);
  };

  const customNavigateMail = () => {
    if (disabledAction) {
      antdMessage.warning(
        t(`interventions.show.error.${intervention?.status}`)
      );
      return;
    }
    setIsMailModalOpen(false);
    setSelectedRowKeys([]);
    setForceActionsRefresh(!forceActionsRefresh);
  };

  const openOutstandingModal = (purp) => {
    setOutstandingPurpose(purp);
    setIsOutstandingModalOpen(true);
  };

  const closeOutstandingModal = () => {
    setIsOutstandingModalOpen(false);
    setOutstandingPurpose(null);
  };

  const customNavigateOutstanding = () => {
    if (disabledAction) {
      antdMessage.warning(
        t(`interventions.show.error.${intervention?.status}`)
      );
      return;
    }
    setIsOutstandingModalOpen(false);
    setOutstandingPurpose(null);
    setForceActionsRefresh(!forceActionsRefresh);
  };

  const refresh = () => {
    setForceWebSitesRefresh(!forceWebSitesRefresh);
  };

  const onEdit = (idAction, reminder) => {
    if (disabledAction) {
      antdMessage.warning(
        t(`interventions.show.error.${intervention?.status}`)
      );
      return;
    }
    if (reminder) setIsActionReminder(true);
    setActionPurpose('edit');
    setIdActionFromOverlay(idAction);
    setIsActionModalOpen(true);
  };

  const openDrawer = (resource) => {
    if (disabledAction) {
      antdMessage.warning(
        t(`interventions.show.error.${intervention?.status}`)
      );
      return;
    }
    setType(resource);
    setIsDrawerOpen(true);
  };

  const closeDrawer = () => {
    setType('');
    setIsDrawerOpen(false);
  };

  const openActionModal = () => {
    if (disabledAction) {
      antdMessage.warning(
        t(`interventions.show.error.${intervention?.status}`)
      );
      return;
    }
    setActionPurpose('create');
    setIsActionModalOpen(true);
  };

  const closeActionModal = () => {
    setActionPurpose(null);
    setIdActionFromOverlay(null);
    setIsActionModalOpen(false);
  };

  const customNavigateAction = () => {
    setActionPurpose(null);
    setIdActionFromOverlay(null);
    setIsActionModalOpen(false);
    setForceActionsRefresh(!forceActionsRefresh);
  };
  const handleCloseIntervention = async () => {
    try {
      const isClosed = await closeIntervention(intervention?._id, {
        status: 'CLOSED',
        end_date: dayjs(),
        start_date: dayjs(intervention?.start_date),
        customer: intervention?.customer?._id,
        direct_intervention: intervention?.direct_intervention
      });
      if (isClosed) {
        setIsEmailModalOpen(true);
      }
    } catch (e) {
      message(e);
    }
  };

  const openModalShow = () => {
    if (disabledAction) {
      antdMessage.warning(
        t(`interventions.show.error.${intervention?.status}`)
      );
      return;
    }
    setIsWebsiteModalOpen(true);
  };

  const closeModalShow = () => {
    setIsWebsiteModalOpen(false);
  };

  const deleteResource = async (id) => {
    try {
      await dispatchAPI('DELETE', {
        url: `/interventions/delete/${id}`,
        body: { status: 'ARCHIVED' }
      });
      setForceRefresh(true);
      showSuccessMessage(t, 'interventions', 'archive');
    } catch (e) {
      message(e);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      const promises = [
        getInterventionEnums(),
        getActionEnums(),
        getLeadersAndGtp(),
        getMicroPs(),
        getMacroPs(),
        getInterventionEnums(),
        getCustomers(),
        getCustomerEnums(),
        getContracts(),
        getServices(),
        getOrganizations(),
        getTasksType(),
        getSoftwares(),
        getListActions(),
        getEnums(),
        getRejectionTypes(),
        getRejectionReasons(),
        getTemplates()
      ];
      await Promise.all(promises);
      setIsFieldsLoading(false);
    };
    fetchData();
  }, []);

  return (
    <InterventionContext.Provider
      value={{
        intervention,
        setIntervention,
        interventionEnums,
        leadersAndGtp,
        isFieldsLoading,
        psMicro,
        psMacro,
        customers,
        customerEnums,
        contracts,
        services,
        setForceRefresh,
        forceRefresh,
        patchPauses,
        patchIntervention,
        actionEnums,
        actions,
        setActions,
        organizations,
        macroPs,
        setMacroPs,
        tasks,
        deleteFile,
        calculateInitialTimer,
        setTimer,
        timer,
        isModalOpen,
        openModal,
        closeModal,
        formValues,
        setFormValues,
        softwares,
        setCustomerComments,
        customerComments,
        setAddComment,
        addComment,
        patchComment,
        setNewComment,
        rejections,
        setRejections,
        setOutstandings,
        outstandings,
        firstOutstanding,
        setFirstOutstanding,
        listActions,
        openInNewTab,
        enums,
        qualityControlFiles,
        setQualityControlFiles,
        selectedCategories,
        setSelectedCategories,
        sendReport,
        getRejections,
        getOutstandings,
        getFirstOutstanding,
        getPsMacro,
        getActions,
        disabledAction,
        forceActionsRefresh,
        setForceActionsRefresh,
        onCancelEmailSent,
        onArchived,
        openMailModal,
        isMailModalOpen,
        closeMailModal,
        customNavigateMail,
        selectedRowKeys,
        openOutstandingModal,
        outstandingPurpose,
        isOutstandingModalOpen,
        closeOutstandingModal,
        customNavigateOutstanding,
        refresh,
        forceWebSitesRefresh,
        onEdit,
        isActionReminder,
        actionPurpose,
        setActionPurpose,
        setSelectedRowKeys,
        idActionFromOverlay,
        setIdActionFromOverlay,
        isActionModalOpen,
        setIsActionModalOpen,
        openDrawer,
        closeDrawer,
        openActionModal,
        closeActionModal,
        customNavigateAction,
        isDrawerOpen,
        type,
        rejectionTypes,
        rejectionReasons,
        handleCloseIntervention,
        openModalShow,
        closeModalShow,
        isWebsiteModalOpen,
        deleteResource,
        user,
        template,
        startIntervention,
        contacts,
        isEmailModalOpen,
        setIsEmailModalOpen
      }}
    >
      {children}
    </InterventionContext.Provider>
  );
};

InterventionContextProvider.propTypes = {
  children: PropTypes.element.isRequired
};

export default () => useContext(InterventionContext);
