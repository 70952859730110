import { Tag, Table, Row, Flex, Button } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { useContactsColumns } from './columns/contactsColumns';
import { useAuthContext } from '../../../contexts/AuthContext';
import useCustomerContext from '../CustomerContext';
import { formatValue } from '../../../utils/formatNumber';

/**
 * useIdentificationContent is a custom hook that generates an array of content configurations for displaying identification-related customer data.
 *
 * @hook
 * @param {Object} data - Customer data object containing identification-related information.
 * @returns {Array<Object>} An array of content configurations for displaying identification-related customer data.
 */

export const useIdentificationContent = (data, t) => {
  const {
    company = '',
    ps_number = '',
    micro_ps = {},
    company_name = '',
    phone_number = {},
    email = '',
    address = {},
    type_ps_id = ''
  } = data || {};

  return [
    {
      label: 'customers.form.company',
      span: 5,
      content: t(`employments.tags.${company}`) || '-'
    },
    {
      label: 'customers.form.ps_number',
      span: 5,
      content: ps_number ? <Tag>{ps_number}</Tag> : '-'
    },
    {
      label: 'customers.form.micro_ps',
      span: 5,
      content: micro_ps?.title || '-'
    },
    {
      label: 'customers.form.company_name',
      span: 5,
      content: company_name || '-'
    },
    {
      label: 'customers.form.phone_number.number',
      span: 5,
      content: phone_number?.number
        ? formatValue(
            `${`${phone_number?.country_code}`}${phone_number?.number}`,
            'phone'
          )
        : '-'
    },
    {
      label: 'customers.form.email',
      span: 5,
      content: email || '-'
    },
    {
      label: 'customers.form.address.label',
      span: 10,
      content: `${address?.number || '-'} ${address?.street || ''} ${
        address?.aditional || ''
      } ${address?.postal_code || ''} ${address?.city || ''}`
    },
    {
      label: 'customers.form.type_ps_id',
      span: 5,
      content: type_ps_id || '-'
    }
  ];
};

/**
 * useContactContent is a custom hook that generates a table component for displaying contact-related customer data.
 *
 * @function
 * @param {Array<Object>} data - An array of objects containing contact-related information about customers.
 * @param {Function} setModalVisible - Function to set the visibility of the contact drawer.
 * @param {Function} setPurpose - Function to set the purpose of the contact drawer.
 * @param {Function} setItemId - Function to set the ID of the contact item.
 * @param {Function} handleArchive - Function to archive a contact item.
 * @returns {React.ReactNode} A Table component displaying contact-related customer data.
 */

export const useContactContent = (
  data,
  setModalVisible,
  setPurpose,
  setItemId,
  handleArchive
) => {
  const { t } = useTranslation();
  const { user } = useAuthContext();
  const { hasAccess } = useCustomerContext();

  const columns = useContactsColumns(
    {
      setModalVisible,
      setPurpose,
      setItemId,
      handleArchive,
      user
    },
    hasAccess
  );
  return (
    <Flex vertical gap={9}>
      {user.role.split(':')[1] !== 'USER' && hasAccess('create') && (
        <Row justify="end">
          <Button
            type="primary"
            onClick={() => {
              setModalVisible(true);
              setPurpose('create');
            }}
          >
            <PlusOutlined />
            {t('buttons.create')}
          </Button>
        </Row>
      )}
      <Table dataSource={data} columns={columns} scroll={{ x: true }} />
    </Flex>
  );
};
