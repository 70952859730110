import { Tag } from 'antd';
import dayjs from 'dayjs';
import {
  userPositions,
  userEmployer,
  levelEmployees,
  yesNoSwitch
} from '../../../../utils/constants/tagColors';
import { formatValue } from '../../../../utils/formatNumber';

const listContent = (
  {
    company,
    contract_type,
    position,
    job_requirement,
    is_admin,
    is_leader,
    leader,
    start_date,
    end_date,
    work_hours,
    monday_morning,
    monday_afternoon,
    tuesday_morning,
    tuesday_afternoon,
    wednesday_morning,
    wednesday_afternoon,
    thursday_morning,
    thursday_afternoon,
    friday_morning,
    friday_afternoon
  },
  t
) => {
  const displayWeekPlanning = (morning, afternoon) => {
    let stringDateMorning = '';
    let stringDateAfternoon = '';
    if (morning?.length > 0) {
      stringDateMorning = `${dayjs(morning[0])
        .format('HH:mm')
        ?.replace(':', 'h')}-${dayjs(morning[1])
        .format('HH:mm')
        ?.replace(':', 'h')}`;
    }
    if (afternoon?.length > 0) {
      stringDateAfternoon = `${dayjs(afternoon[0])
        .format('HH:mm')
        ?.replace(':', 'h')}-${dayjs(afternoon[1])
        .format('HH:mm')
        ?.replace(':', 'h')}`;
    }
    return `${stringDateMorning}${
      morning?.length > 0 && afternoon?.length > 0 ? ' / ' : ''
    }${stringDateAfternoon}`;
  };

  return [
    {
      label: 'employments.form.company',
      content: company && (
        <Tag color={userEmployer[company]}>
          {t(`users.employers.${company}`)}
        </Tag>
      ),
      span: 2
    },
    {
      label: 'employments.form.contract_type',
      content: contract_type && contract_type,
      span: 2
    },
    {
      label: 'employments.form.position',
      content: position && (
        <Tag color={userPositions[position]}>
          {t(`employments.tags.${position}`)}
        </Tag>
      ),
      span: 3
    },
    {
      label: 'employments.form.job_requirement',
      content: job_requirement && (
        <Tag color={levelEmployees[job_requirement]}>
          {t(`employments.columns.${job_requirement}`)}
        </Tag>
      ),
      span: 2
    },
    {
      label: 'employments.form.is_admin',
      content: (
        <Tag color={yesNoSwitch[is_admin]}>
          {is_admin ? t(`switch.${is_admin}`) : 'N/A'}
        </Tag>
      ),
      span: 2
    },
    {
      label: 'employments.form.is_leader',
      content: (
        <Tag color={yesNoSwitch[is_leader]}>
          {is_leader ? t(`switch.${is_leader}`) : 'N/A'}
        </Tag>
      ),
      span: 2
    },
    {
      label: 'employments.form.leader',
      content: leader
        ? `${leader.first_name} ${leader?.usage_last_name || leader?.last_name}`
        : '_',
      span: 2
    },
    {
      label: 'employments.form.start_date',
      content: start_date && formatValue(start_date, 'date'),
      span: 2
    },
    {
      label: 'employments.form.end_date',
      content: end_date ? formatValue(end_date, 'date') : '_',
      span: 2
    },
    {
      label: 'employments.form.work_hours',
      content: work_hours && `${work_hours}h00`,
      span: 3
    },
    {
      noLabel: true,
      content: (
        <p style={{ fontSize: 18, marginTop: 20 }}>
          {t('employments.form.hours_per_week')}
        </p>
      ),
      span: 3
    },
    {
      label: 'employments.form.monday',
      content: displayWeekPlanning(monday_morning, monday_afternoon),
      span: 3
    },
    {
      label: 'employments.form.tuesday',
      content: displayWeekPlanning(tuesday_morning, tuesday_afternoon),
      span: 3
    },
    {
      label: 'employments.form.wednesday',
      content: displayWeekPlanning(wednesday_morning, wednesday_afternoon),
      span: 3
    },
    {
      label: 'employments.form.thursday',
      content: displayWeekPlanning(thursday_morning, thursday_afternoon),
      span: 3
    },
    {
      label: 'employments.form.friday',
      content: displayWeekPlanning(friday_morning, friday_afternoon),
      span: 3
    }
  ];
};

export default listContent;
