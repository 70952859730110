import { Button, Divider, Popconfirm } from 'antd';
import {
  EditOutlined,
  ContainerOutlined,
  WarningOutlined
} from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { formatValue } from '../../../../utils/formatNumber';

export const useContactsColumns = (options, hasAccess) => {
  const { t } = useTranslation();
  return [
    {
      title: t('contacts.form.employment_role'),
      dataIndex: 'employment_role',
      key: 'employment_role',
      render: (employment_role) =>
        employment_role ? t(`users.employment_roles.${employment_role}`) : ''
    },
    {
      title: t('customers.form.gender'),
      dataIndex: 'gender',
      key: 'gender',
      render: (gender) => t(`users.genders.${gender}`),
      sorter: true
    },
    {
      title: t('customers.form.last_name'),
      dataIndex: ['last_name'],
      key: 'last_name',
      sorter: true
    },
    {
      title: t('customers.form.first_name'),
      dataIndex: 'first_name',
      key: 'first_name',
      sorter: true
    },
    {
      title: t('customers.form.email'),
      dataIndex: 'email',
      key: 'email',
      sorter: true
    },
    {
      title: t('customers.form.phone_number.number'),
      dataIndex: 'phone_number',
      key: 'phone_number',
      render: (phone_number) =>
        formatValue(
          `${phone_number?.country_code || ''}${phone_number?.number || ''}`,
          'phone'
        ),
      sorter: true
    },
    {
      title: t('customers.form.report'),
      dataIndex: 'report',
      key: 'report',
      render: (report) => (report ? t(`customers.form.${report}`) : '')
    },
    {
      key: 'action',
      align: 'right',
      render: (record) =>
        options.user.role.split(':')[1] !== 'USER' && (
          <>
            {hasAccess('edit_contact') && (
              <>
                <Divider type="vertical" />
                <Button
                  type="ghost"
                  onClick={() => {
                    options.setPurpose('edit');
                    options.setItemId(record._id);
                    options.setModalVisible(true);
                  }}
                >
                  <EditOutlined style={{ fontSize: 18 }} />
                </Button>
              </>
            )}
            {hasAccess('archive_contact') && (
              <>
                <Divider type="vertical" />
                <Popconfirm
                  title={t('datatable.column.action.delete.title')}
                  okText={t('datatable.column.action.delete.ok')}
                  okButtonProps={{ type: 'danger' }}
                  cancelText={t('datatable.column.action.delete.cancel')}
                  onConfirm={() => options.handleArchive(record._id)}
                  icon={<WarningOutlined />}
                >
                  <ContainerOutlined style={{ fontSize: 18, color: 'red' }} />
                </Popconfirm>
              </>
            )}
          </>
        )
    }
  ];
};
