import { Row, Col, Typography } from 'antd';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';
import { eventsColor } from '../../utils/constants/tagColors';

export const DayFormat = ({
  day,
  workHours,
  hasWorkHours,
  isSpecialDay,
  weeksTotal,
  specialDay
}) => {
  let backgroundColor = 'white';
  if (isSpecialDay) backgroundColor = eventsColor[specialDay[0].type];
  if (day.day() === 0 || day.day() === 6) backgroundColor = 'lightgrey';

  const currentWeek = weeksTotal?.find((w) => w.week === dayjs(day).week());
  const hours = currentWeek?.hours || '';
  const minutes = currentWeek?.minutes || '';

  return (
    <>
      <Row
        style={{
          background:
            specialDay[0]?.status === 'PENDING'
              ? `repeating-linear-gradient(45deg, ${backgroundColor}, ${backgroundColor} 10px, transparent 10px, transparent 20px)`
              : backgroundColor,
          border: '1px solid #f0f0f0',
          borderRadius: '2px',
          padding: '2px',
          margin: '2px',
          flexWrap: 'nowrap'
        }}
      >
        <Col span={12}>
          <Typography.Text>{day.format('DD dd')}</Typography.Text>
        </Col>
        <Col span={12}>
          {hasWorkHours ? (
            <Typography.Text>
              {workHours
                ?.filter((wh) => wh.date.includes(day.format('YYYY-MM-DD')))
                .map((wh) => (
                  <Typography.Text>
                    {`${String(Math.floor(wh.total_seconds / 3600)).padStart(
                      2,
                      '0'
                    )}H${String(
                      Math.floor((wh.total_seconds % 3600) / 60)
                    ).padStart(2, '0')}`}
                  </Typography.Text>
                ))}
            </Typography.Text>
          ) : null}
        </Col>
      </Row>
      {day.day() === 0 ? (
        <Row
          style={{
            backgroundColor: 'grey',
            border: '1px solid #f0f0f0',
            borderRadius: '5px',
            padding: '5px',
            margin: '5px'
          }}
        >
          <Col span={10}>
            <Typography.Text
              style={{ color: 'white' }}
            >{`S${day.week()}`}</Typography.Text>
          </Col>
          <Col span={14}>
            <Typography.Text style={{ color: 'white' }}>
              {`${String(hours).padStart(2, '0')}H${String(minutes).padStart(
                2,
                '0'
              )}`}
            </Typography.Text>
          </Col>
        </Row>
      ) : null}
    </>
  );
};

DayFormat.propTypes = {
  day: PropTypes.shape().isRequired,
  workHours: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  hasWorkHours: PropTypes.bool.isRequired,
  isSpecialDay: PropTypes.bool.isRequired,
  weeksTotal: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  specialDay: PropTypes.arrayOf(PropTypes.shape())
};

DayFormat.defaultProps = {
  specialDay: []
};
