import { useTranslation } from 'react-i18next';
import { EyeOutlined } from '@ant-design/icons';
import { Button, Tag } from 'antd';
import { userPositions } from '../../../utils/constants/tagColors';
import { routes, subRoutes } from '../../../utils/constants/adminRoutes';
import { formatValue } from '../../../utils/formatNumber';

/**
 * Custom hook for generating columns data for displaying absences longer than 72 hours.
 *
 * @hook
 * @returns {Array} Array of objects containing title, key, dataIndex, render, sorter, and filter for each column
 */
export const useColumns = () => {
  const { t } = useTranslation();

  const getActiveContract = (contracts) => {
    const activeContract = contracts?.find(
      (employment) => employment.status === 'ACTIVE'
    );
    return activeContract;
  };

  return [
    {
      title: t('home.administrative.columns.position'),
      key: 'position',
      dataIndex: ['user', 'employments'],
      render: (employments) => {
        const activeContractPosition = getActiveContract(employments);
        return (
          <Tag color={userPositions[activeContractPosition?.position]}>
            {t(`users.positions.${activeContractPosition?.position || 'N/A'}`)}
          </Tag>
        );
      }
    },
    {
      title: t('home.administrative.columns.collaborator'),
      key: 'user',
      dataIndex: ['user'],
      sorter: true,
      render: (user) =>
        `${user?.first_name} ${user?.usage_last_name || user?.last_name}`
    },
    {
      title: t('home.administrative.columns.start_date'),
      key: 'startDate',
      dataIndex: ['startTime'],
      sorter: true,
      render: (startTime) => formatValue(startTime, 'date')
    },
    {
      title: t('home.administrative.columns.start_hour'),
      key: 'startHour',
      dataIndex: ['startTime'],
      sorter: true,
      render: (startTime) => formatValue(startTime, 'time')
    },
    {
      title: t('home.administrative.columns.end_date'),
      key: 'endDate',
      dataIndex: ['endTime'],
      sorter: true,
      render: (endTime) => formatValue(endTime, 'date')
    },
    {
      title: t('home.administrative.columns.end_hour'),
      key: 'endHour',
      dataIndex: ['endTime'],
      sorter: true,
      render: (endTime) => formatValue(endTime, 'time')
    },
    {
      title: t('home.administrative.columns.make_up_time'),
      key: 'makeUpTime',
      dataIndex: 'makeUpTime',
      sorter: true,
      render: (makeUpTime) => t(`general.${makeUpTime ? 'YES' : 'NO'}`)
    },
    {
      title: t('home.administrative.columns.reason'),
      key: 'reason',
      dataIndex: 'reason',
      sorter: true
    },
    {
      title: t('home.administrative.columns.comment'),
      key: 'comment',
      dataIndex: ['comment'],
      sorter: true
    },
    {
      key: 'id',
      align: 'right',
      dataIndex: '_id',
      render: (id) => (
        <Button type="link" className="event-btn first">
          <a
            href={`${routes.RH}${subRoutes.RH.ABSENCE_VACATION}/show/${id}`}
            target="_blank"
            rel="noopener noreferrer"
            aria-label="Voir l'intervention"
          >
            <EyeOutlined style={{ fontSize: '18px' }} />
          </a>
        </Button>
      )
    }
  ];
};
