import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { Select, Typography, Flex } from 'antd';
import { ListResource } from '../../../components';
import { useColumns } from './columns';
import { headers } from './headers';
import { useFormatter } from '../../../utils/formatter';
import CustomerAnalysisContext from '../CustomerAnalysisContext';
import EmailModal from '../../../components/EmailModal/EmailModal';
import { useAuthContext } from '../../../contexts/AuthContext';

const { Option } = Select;

export const ListReconciliationStatements = () => {
  const { formattedData } = useFormatter('reconciliationStatements');
  const { t } = useTranslation();
  const { user } = useAuthContext();
  const [selectedMonth, setSelectedMonth] = useState('');
  const [selectedYear, setSelectedYear] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [monthOptions, setMonthOptions] = useState([]);
  const [isEmailModalOpen, setIsEmailModalOpen] = useState(false);
  const [currentRecord, setCurrentRecord] = useState(null);
  const {
    psMicro,
    users,
    leaders,
    reconcilitionStatementEnums,
    refresh,
    editReconciliation,
    customers
  } = CustomerAnalysisContext();
  const openEmailModal = (record) => {
    setCurrentRecord(record);
    setIsEmailModalOpen(true);
  };

  const closeEmailModal = (value) => {
    if (value !== 'cancel') editReconciliation(currentRecord, 'SEND');
    setIsEmailModalOpen(false);
    setCurrentRecord(null);
  };

  const columns = useColumns(
    psMicro,
    users,
    leaders,
    reconcilitionStatementEnums,
    editReconciliation,
    openEmailModal,
    customers
  );

  const generateMonthOptions = (year) => {
    const currentYear = dayjs().year();
    const currentMonth = dayjs().month() + 1;
    const months = dayjs.months();

    if (year === String(currentYear)) {
      return months.slice(0, currentMonth - 1).map((month, index) => (
        <Option
          key={String(index + 1).padStart(2, '0')}
          value={String(index + 1).padStart(2, '0')}
        >
          {t(
            `reconciliationStatements.months.${String(index + 1).padStart(
              2,
              '0'
            )}`
          )}
        </Option>
      ));
    }
    return months.map((month, index) => (
      <Option
        key={String(index + 1).padStart(2, '0')}
        value={String(index + 1).padStart(2, '0')}
      >
        {t(
          `reconciliationStatements.months.${String(index + 1).padStart(
            2,
            '0'
          )}`
        )}
      </Option>
    ));
  };

  const generateYearOptions = () => {
    const currentYear = dayjs().year();
    const years = Array.from({ length: 11 }, (_, i) => currentYear - i);
    return years.map((year) => (
      <Option key={year} value={String(year)}>
        {year}
      </Option>
    ));
  };

  useEffect(() => {
    const currentYear = dayjs().year();
    const currentMonth = dayjs().month();
    const previousMonth = currentMonth === 0 ? 11 : currentMonth - 1;
    const yearOfPreviousMonth =
      currentMonth === 0 ? currentYear - 1 : currentYear;

    setSelectedYear(String(yearOfPreviousMonth));
    setSelectedMonth(String(previousMonth + 1).padStart(2, '0'));
  }, []);

  useEffect(() => {
    setMonthOptions(generateMonthOptions(selectedYear));
  }, [selectedYear, t]);

  useEffect(() => {
    if (selectedYear && selectedMonth) {
      const newStartDate = dayjs(`${selectedYear}/${selectedMonth}/15`)
        .startOf('day')
        .format('YYYY-MM-DD HH:mm:ss');
      const newEndDate = dayjs(`${selectedYear}/${selectedMonth}/15`)
        .endOf('day')
        .format('YYYY-MM-DD HH:mm:ss');
      setStartDate(newStartDate);
      setEndDate(newEndDate);
    }
  }, [selectedMonth, selectedYear]);

  return (
    <>
      <ListResource
        resourceName="reconciliationStatements"
        columns={columns}
        headers={headers}
        customActionColumn
        forceRefresh={refresh}
        resourceModelName="ReconciliationStatements"
        formatter={formattedData}
        withImportButton={false}
        withUploadButton={user.role.split(':')[1] !== 'USER'}
        withCreateButton={false}
        onDoubleClickAction={false}
        populate="done_by,leader,micro_ps,customer.contacts"
        extraQuery={`period_concerned>=${startDate || ''}&period_concerned<=${
          endDate || ''
        }`}
        extra
        extraFilters={
          <>
            <Flex align="center" gap={4} wrap>
              <Typography style={{ marginLeft: '15px', alignSelf: 'center' }}>
                {t('reconciliationStatements.select.year')} :
              </Typography>
              <Select
                allowClear
                placeholder={t(
                  'reconciliationStatements.select.placeholder_year'
                )}
                onChange={(value) => setSelectedYear(value)}
                onClear={() => {
                  setSelectedYear(null);
                }}
                value={selectedYear}
                style={{ width: '180px', marginLeft: '15px' }}
              >
                {generateYearOptions()}
              </Select>
            </Flex>
            <Flex align="center" gap={4} wrap>
              <Typography style={{ marginLeft: '15px', alignSelf: 'center' }}>
                {t('reconciliationStatements.select.month')} :
              </Typography>
              <Select
                allowClear
                placeholder={t(
                  'reconciliationStatements.select.placeholder_month'
                )}
                value={selectedMonth}
                disabled={!selectedYear}
                onChange={(value) => {
                  setSelectedMonth(value);
                }}
                onClear={() => {
                  setSelectedMonth(null);
                }}
                style={{ width: '180px', marginLeft: '15px' }}
              >
                {monthOptions}
              </Select>
            </Flex>
          </>
        }
      />
      {isEmailModalOpen && currentRecord && (
        <EmailModal
          isModalOpen={isEmailModalOpen}
          closeModal={closeEmailModal}
          contacts={currentRecord?.customer?.contacts || []}
          baseUrl={`emails?type=reconciliation&id=${currentRecord?._id}`}
          extraQuery="status=VALIDATED"
          company={t(`employments.tags.${currentRecord?.customer?.company}`)}
          subject={`[${
            currentRecord?.customer?.company
          }] - Etat de rapprochement de ${t(
            `reconciliationStatements.months.${selectedMonth}`
          )} ${selectedYear}`}
          useCaseFilter="RECONCILIATION"
          successMessageUrl="emails"
          modelName="Reconciliationstatement"
          idModal={currentRecord?._id}
        />
      )}
    </>
  );
};
