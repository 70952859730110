import { useState } from 'react';
import dayjs from 'dayjs';
import { Modal } from 'antd';
import InterventionContext from './InterventionContext';
import { CreateUpdateContainer } from '../../components/CreateUpdateContainer/CreateUpdateContainer';
import useInteventionFields from './interventionFields';

const CreateUpdateInterventionModal = () => {
  const {
    isModalOpen,
    isFieldsLoading,
    closeModal,
    interventionEnums,
    leadersAndGtp,
    customers,
    customerEnums,
    psMicro,
    softwares,
    services,
    formValues,
    setFormValues,
    user
  } = InterventionContext();
  const [hasRepetion, setHasRepetion] = useState(false);
  const [isDirectIntervention, setIsDirectIntervention] = useState(
    user?.role === 'users:USER'
  );
  const [days, setDays] = useState([
    { name: 'monday', checked: false },
    { name: 'tuesday', checked: false },
    { name: 'wenesday', checked: false },
    { name: 'thursday', checked: false },
    { name: 'friday', checked: false },
    { name: 'saturday', checked: false },
    { name: 'sunday', checked: false }
  ]);

  const startOfWeek = dayjs().startOf('week').subtract(1, 'day');

  const { interventionFields } = useInteventionFields(
    isFieldsLoading,
    interventionEnums,
    leadersAndGtp,
    customers,
    customerEnums,
    psMicro,
    softwares,
    services,
    startOfWeek,
    setHasRepetion,
    hasRepetion,
    days,
    setDays,
    formValues,
    user,
    true,
    isDirectIntervention,
    setIsDirectIntervention
  );

  const getCheckedDaysString = (d) =>
    d
      .filter((day) => day.checked)
      .map((day) => day.name)
      .join(', ');

  const config = {
    onGetResource: {
      setFields: (data) => ({
        ...data,
        period_start: data.period_start && dayjs(data.period_start),
        period_end: data.period_end && dayjs(data.period_end),
        service_start: data.service_start && dayjs(data.service_start),
        service_end: data.service_end && dayjs(data.service_end)
      })
    },
    onUpdateResource: {
      setBody: (data) => ({
        ...data
      })
    },
    onCreateResource: {
      setBody: (data) => ({
        ...data,
        ...(isDirectIntervention ? { status: 'PLANNED' } : {}),
        period_start: dayjs(data.period_start)
          .startOf('day')
          .format('YYYY-MM-DDTHH:mm:ss.SSS[Z]'),
        period_end: dayjs(data.period_end).format('YYYY-MM-DDTHH:mm:ss.SSS[Z]'),
        scheduled_start_date: !data?.scheduled_start_date
          ? dayjs(data.period_start)
              .startOf('day')
              .format('YYYY-MM-DDTHH:mm:ss.SSS[Z]')
          : data?.scheduled_start_date,
        scheduled_end_date: !data?.scheduled_end_date
          ? dayjs(data.period_end).format('YYYY-MM-DDTHH:mm:ss.SSS[Z]')
          : data?.scheduled_end_date,
        ...(!hasRepetion
          ? {
              frequency: null,
              repetition_days: [],
              repetition_end_date: null
            }
          : {
              repetition_days: [getCheckedDaysString(days)]
            }),
        ...(isDirectIntervention ? { scheduled_time: 0.5 } : {})
      })
    }
  };

  return (
    <Modal
      centered
      open={isModalOpen}
      onCancel={closeModal}
      width={800}
      footer={false}
    >
      <CreateUpdateContainer
        fields={interventionFields}
        loadingFields={isFieldsLoading}
        purpose="create"
        baseUrl="interventions"
        resource="interventions"
        isOverlay
        customNavigate={closeModal}
        config={config}
        populate="service"
        setFormValues={setFormValues}
        needFormDependencies
        submitLabel={isDirectIntervention ? 'buttons.open' : 'buttons.save'}
      />
    </Modal>
  );
};

export default CreateUpdateInterventionModal;
