import { Button } from 'antd';
import PropTypes from 'prop-types';

/**
 * A button component for work-related actions.
 *
 * @component
 * @param {Object} props - The component's props.
 * @param {boolean} props.isActive - Indicates if the button is in an active state.
 * @param {Function} props.onClick - The function to be called when the button is clicked.
 * @param {ReactNode} props.icon - The icon to be displayed on the button.
 * @param {string} props.text - The text to be displayed on the button.
 * @returns {JSX.Element} JSX element representing the work button.
 */

export const WorkButton = ({ isActive, onClick, icon, text }) => (
  <Button
    icon={icon}
    style={{
      width: '100%',
      color: isActive ? 'white' : '#32B6B2',
      backgroundColor: isActive ? '#32B6B2' : '',
      borderColor: '#32B6B2'
    }}
    onClick={onClick}
  >
    {text}
  </Button>
);

WorkButton.propTypes = {
  isActive: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  icon: PropTypes.element,
  text: PropTypes.string
};

WorkButton.defaultProps = {
  icon: null,
  text: ''
};
