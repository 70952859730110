// Main nav menuItems
export const routes = {
  HOME: '/',
  CUSTOMERS: '/customers',
  INTERVENTIONS: '/interventions',
  CUSTOMERSERVICES: '/customerServices',
  CALENDAR: '/calendar',
  ACTUALITIES: '/actualities',
  QUALITY_CONTROL: '/quality_control',
  OCCUPANCY_CHART: '/occupancy_chart',
  USERS: '/users',
  BILLING: '/billing',
  RH: '/rh',
  QUOTE: '/quote',
  SERVICES: '/services',
  CONTRACT: '/contract',
  FEES: '/fees',
  CUSTOMER_ANALYSIS: '/customer-analysis',
  STATISTICS: '/statistics',
  MAILS: '/mail',
  TEMPLATE_DOCS: '/template-docs',
  SETTINGS: '/settings'
};

// Main nav subMenuItems
export const subRoutes = {
  RH: {
    ABSENCE_VACATION: '/absence-vacation',
    INTERNAL_GESTION: '/internal-gestion'
  },
  CUSTOMER_ANALYSIS: {
    REJECTION: '/rejection',
    RECONCILIATION_STATEMENT: '/reconciliation-statement'
  },
  OCCUPANCY_CHART: {
    OCCUPANCY: '/occupancy',
    PLANNING: '/planning'
  },
  SETTINGS: {
    CONFIGURATIONS: '/configurations'
  },
  BILLING: {
    QUITTANCES: '/quittances',
    PAYMENTS: '/payments',
    TRANSFERS: '/transfers'
  }
};

// Other routes
export const outOfNavRoutes = {
  LOGIN: '/login',
  REDIRECT: '/redirect',
  PROFILE: '/profile',
  SETTINGS: '/settings',
  TEMPLATE_DOCS: '/template-docs'
};

// url search parameters
export const pathSearches = {
  HOME: '?f=quality_status=ON_GOING__',
  MAILS: '?s=-created_at',
  QUOTE: '?s=-created_at',
  SERVICES: '?s=-start_date',
  INTERVENTIONS: '?s=-start_date'
};
