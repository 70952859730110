import PhoneNumberInput from '../components/FormItems/PhoneNumberInput';

/**
 * Configuration object for rendering a phone number input field.
 * The input field includes a country code selection dropdown and a phone number input.
 *
 * @type {Object}
 * @property {string} label - The label text for the phone number input field.
 * @property {Array} name - An array representing the form field name path.
 * @property {JSX.Element} input - The JSX element containing the phone number input field.
 */

export const phoneNumberField = {
  label: 'phone_number.number',
  name: ['phone_number'],
  input: <PhoneNumberInput />
};
