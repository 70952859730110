import { useState, useEffect } from 'react';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
  Modal,
  Form,
  Select,
  Typography,
  DatePicker,
  Input,
  Button,
  Row
} from 'antd';
import { CloseOutlined, CheckOutlined } from '@ant-design/icons';
import QuoteContextProvider from './QuoteContext';

const { Option } = Select;
const { TextArea } = Input;

const ModalConfirmationContract = ({
  closeModal,
  services,
  setIsModalOpen,
  isModalOpen,
  leaders,
  usersGtp,
  comment
}) => {
  const { t } = useTranslation();
  const [isFieldsLoading, setIsFieldsLoading] = useState(true);
  const { serviceEnums } = QuoteContextProvider();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [form] = Form.useForm();
  const format = 'DD-MM-YYYY';

  const onsearch = (input, option) => {
    const result = option?.children.toLowerCase();
    return result.includes(input.toLowerCase());
  };

  const handleSubmit = async (values) => {
    setIsSubmitting(true);
    const formattedValues = {
      ...values,
      services: (values?.services || []).map((service) => ({
        ...service,
        user_referent: values.user_referent,
        leader: values.leader
      }))
    };
    await closeModal(formattedValues);
    setIsSubmitting(false);
  };

  useEffect(() => {
    if (serviceEnums) setIsFieldsLoading(false);
  }, []);

  useEffect(() => {
    if (services && services.length > 0) {
      const initialValues = services.map((service) => ({
        type: service.type,
        start_date: dayjs(service.start_date),
        ...(service.end_date && { end_date: dayjs(service.end_date) })
      }));
      form.setFieldsValue({
        services: initialValues,
        user_referent:
          services && services.length > 0 && services[0]?.user_referent,
        leader: services && services.length > 0 && services[0]?.leader,
        comment
      });
    }
  }, [services]);

  return (
    <Modal
      centered
      title={
        <Typography.Title
          level={4}
          style={{
            fontWeight: 300,
            textUnderlineOffset: '8px',
            color: 'var(--userColor)',
            marginBottom: 30
          }}
          underline
          strong={false}
        >
          {t('quotes.modal.title')}
        </Typography.Title>
      }
      footer={false}
      open={isModalOpen}
      onConfirm={closeModal}
      onCancel={() => {
        setIsModalOpen(false);
      }}
      width={600}
    >
      <Form form={form} onFinish={handleSubmit}>
        <Form.List name="services">
          {(fields) => (
            <>
              {fields.map((field) => (
                <div key={field.key}>
                  <Form.Item
                    {...field}
                    name={[field.name, '_id']}
                    label={`${t('quotes.form.service._id')}`}
                    initialValue={services[field.name]?._id}
                    rules={[{ required: true }]}
                    hidden
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    {...field}
                    name={[field.name, 'type']}
                    label={`${t('quotes.form.service.type')} ${field.name + 1}`}
                    initialValue={services[field.name]?.type}
                    rules={[{ required: true }]}
                  >
                    <Select
                      allowClear
                      showSearch
                      loading={isFieldsLoading}
                      disabled
                    >
                      {serviceEnums?.types?.map((type) => (
                        <Option key={type} value={type}>
                          {t(`quotes.tags.${type}`)}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                  <Form.Item
                    {...field}
                    name={[field.name, 'start_date']}
                    label={t('quotes.form.service.start_date')}
                    initialValue={dayjs(services[field.name]?.start_date)}
                    rules={[{ required: true }]}
                  >
                    <DatePicker
                      format={format}
                      disabledDate={(current) =>
                        current && current.endOf('day') < dayjs().endOf('day')
                      }
                    />
                  </Form.Item>

                  {services[field.name]?.end_date && (
                    <Form.Item
                      {...field}
                      name={[field.name, 'end_date']}
                      label={t('quotes.form.service.end_date')}
                      initialValue={dayjs(services[field.name]?.end_date)}
                      rules={[{ required: true }]}
                    >
                      <DatePicker
                        format={format}
                        disabledDate={(current) =>
                          current && current.endOf('day') < dayjs().endOf('day')
                        }
                      />
                    </Form.Item>
                  )}
                </div>
              ))}
            </>
          )}
        </Form.List>
        <Form.Item
          name={['leader']}
          label={`${t('quotes.form.leader')}`}
          rules={[{ required: true }]}
        >
          <Select
            allowClear
            showSearch
            filterOption={(input, option) => onsearch(input, option)}
          >
            {(leaders || []).map((leader) => (
              <Option key={leader._id} value={leader._id}>
                {`${leader.first_name} ${
                  leader?.usage_last_name || leader?.last_name
                }`}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          name={['user_referent']}
          label={`${t('quotes.form.user_referent')}`}
          rules={[{ required: true }]}
        >
          <Select
            allowClear
            showSearch
            filterOption={(input, option) => onsearch(input, option)}
          >
            {(usersGtp || []).map((user) => (
              <Option key={user._id} value={user._id}>
                {`${user.first_name} ${
                  user?.usage_last_name || user?.last_name
                }`}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item name={['comment']} label={`${t('quotes.form.comment')}`}>
          <TextArea rows={5} />
        </Form.Item>
        <Form.Item>
          <Row justify="end">
            <Button
              style={{ margin: '0 8px' }}
              type="link"
              danger
              onClick={() => setIsModalOpen(false)}
            >
              {`${t('buttons.cancel')} `}
              <CloseOutlined />
            </Button>
            <Button
              type="primary"
              htmlType="submit"
              loading={isSubmitting}
              disabled={isSubmitting}
            >
              {`${t('buttons.validate')} `}
              <CheckOutlined />
            </Button>
          </Row>
        </Form.Item>
      </Form>
    </Modal>
  );
};

ModalConfirmationContract.propTypes = {
  closeModal: PropTypes.func.isRequired,
  setIsModalOpen: PropTypes.func.isRequired,
  services: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string,
      start_date: PropTypes.string,
      end_date: PropTypes.string,
      user_referent: PropTypes.string,
      leader: PropTypes.string
    })
  ),
  leaders: PropTypes.arrayOf({}),
  usersGtp: PropTypes.arrayOf({}),
  isModalOpen: PropTypes.bool.isRequired,
  comment: PropTypes.string
};

ModalConfirmationContract.defaultProps = {
  services: [],
  leaders: [],
  usersGtp: [],
  comment: null
};

export default ModalConfirmationContract;
