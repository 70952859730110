import { createContext, useContext, useEffect, useState } from 'react';
import { message } from 'antd';
import jwt_decode from 'jwt-decode';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { useMsal } from '@azure/msal-react';
import { useStateWithLocalStorage } from '../utils';

export const AuthContext = createContext({ isValid: false });
const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: { 'Content-Type': 'application/json' }
});

export const AuthContextProvider = ({ children }) => {
  const { t } = useTranslation();
  const { instance } = useMsal();
  const [user, setUser] = useStateWithLocalStorage('user', {
    first_name: 'John',
    last_name: 'Doe',
    role: 'admins:ADMIN'
  });
  const [token, setToken] = useStateWithLocalStorage('token');
  const [refreshToken, setRefreshToken] =
    useStateWithLocalStorage('refresh_token');
  const [remember, setRememberMe] = useStateWithLocalStorage(
    'remember_me',
    !!refreshToken
  );
  const [isValid, setIsValid] = useState(!!refreshToken || !!token);
  const [pendingTasksTray, setPendingTasksTray] = useStateWithLocalStorage(
    'pendingTasksTray',
    []
  );
  const [workHours, setWorkHours] = useState([]);

  const setSession = (accessToken) => {
    if (accessToken) {
      setToken(accessToken);
      setIsValid(true);
      axiosInstance.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
    } else {
      setToken(null);
      setRefreshToken(null);
      setUser(null);
      setIsValid(false);
      delete axiosInstance.defaults.headers.common.Authorization;
      message.warning(t('login.expiredSession'));
    }
  };

  const loginAPI = async (email, password, rememberMe) => {
    const result = await axiosInstance.get('/login', {
      auth: {
        username: email,
        password
      }
    });
    setUser(result.data.user);
    setRememberMe(rememberMe);
    if (rememberMe) setRefreshToken(result.data.refresh_token);
    setSession(result.data.token);
    return result;
  };

  const microsftLoginAPI = async (body) => {
    const result = await axiosInstance.post('/login/microsoft', body);
    setUser(result.data.user);
    setRememberMe(true);
    setRefreshToken(result.data.refresh_token);
    setSession(result.data.token);
    return result;
  };

  const isTokenExpired = () => {
    const decodedToken = jwt_decode(token);
    const currentTime = Date.now() / 1000;
    if (Number(decodedToken.exp) < Number(currentTime)) {
      setToken(null);
      return true;
    }
    return false;
  };

  const handleLogoutRedirect = () => {
    instance.setActiveAccount(null);
  };

  const logout = () => {
    setSession(null);
    setUser(null);
    handleLogoutRedirect();
  };

  const isTokenValid = async () => {
    if ((!token || isTokenExpired()) && !refreshToken) {
      setSession(null);
      return false;
    }
    try {
      if (remember && refreshToken && !token) {
        const result = await axiosInstance.post('/token/refresh', {
          refresh_token: refreshToken
        });
        setToken(result.data.newToken);
        return { newToken: result.data.newToken };
      }
    } catch (e) {
      message.warning(t('login.expiredSession'));
      setSession(null);
      return false;
    }
    if (!isValid) {
      setIsValid(true);
    }
    return true;
  };

  useEffect(() => {
    (async () => {
      await isTokenValid();
    })();
  }, []);

  const fetchAPI = async (
    url,
    method = 'GET',
    body = undefined,
    responseType = 'json',
    cancelToken = undefined,
    headers = undefined
  ) => {
    const { newToken } = await isTokenValid();
    if (
      ['POST', 'PATCH', 'DELETE'].includes(method) &&
      !window.navigator.onLine
    ) {
      setPendingTasksTray([
        ...(pendingTasksTray || []),
        ...[{ url, method, body }]
      ]);
      return new Response();
    }
    const result = await axiosInstance({
      url,
      method,
      responseType,
      cancelToken,
      data: body,
      headers: {
        Authorization: `Bearer ${newToken || token}`,
        ...headers
      }
    });
    return result;
  };

  const dispatchAPI = (type, options) => {
    switch (type) {
      case 'LOGIN':
        return loginAPI(options.email, options.password, options.rememberMe);
      case 'LOGIN/MICROSOFT':
        return microsftLoginAPI(options.body);
      case 'LOGOUT':
        return logout();
      case 'GET':
        return fetchAPI(
          options.url,
          'GET',
          null,
          options.responseType,
          options.cancelToken,
          options.headers
        );
      case 'DELETE':
        return fetchAPI(options.url, 'DELETE');
      case 'POST':
        return fetchAPI(options.url, type, options.body, options.responseType);
      case 'PATCH':
        return fetchAPI(options.url, type, options.body);
      default:
        throw new Error('Unknown dispatchAPI type!');
    }
  };

  return (
    <AuthContext.Provider
      value={{
        user,
        setUser,
        token,
        isValid,
        dispatchAPI,
        remember,
        pendingTasksTray,
        setPendingTasksTray,
        workHours,
        setWorkHours
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuthContext = () => {
  const context = useContext(AuthContext);
  if (context === undefined)
    throw new Error('Context must be used within a context provider');
  return context;
};
