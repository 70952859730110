import dayjs from 'dayjs';

export const calculateHoursPerMonth = (data) => {
  const {
    monday_morning,
    monday_afternoon,
    tuesday_morning,
    tuesday_afternoon,
    wednesday_morning,
    wednesday_afternoon,
    thursday_morning,
    thursday_afternoon,
    friday_morning,
    friday_afternoon
  } = data;
  let total = 0;
  [
    monday_morning,
    monday_afternoon,
    tuesday_morning,
    tuesday_afternoon,
    wednesday_morning,
    wednesday_afternoon,
    thursday_morning,
    thursday_afternoon,
    friday_morning,
    friday_afternoon
  ].map((day) => {
    if (day) total += dayjs(day[1]).diff(dayjs(day[0]), 'hour');
    return total;
  });
  return total * 4.33;
};
