import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Card, Col, Row } from 'antd';
import { EyeOutlined } from '@ant-design/icons';
import { useListContent } from './listContent';
import { GradeBar } from '../GradeBar';
import { DescriptionList } from '../../../../components';

/**
 * InterventionsCard component that shows the interventions information of a specific company
 *
 * @component
 * @param {array} interventions - Interventions array
 * @return {component} - InterventionsCard component
 */
export const InterventionsCard = ({ interventions }) => {
  const listContent = useListContent(interventions[0]);
  return (
    <Card
      title={`PS n°${interventions[0]?.customer?.ps_number} - ${interventions[0]?.customer?.company_name}`}
    >
      <Row>
        <Col span={14}>
          <DescriptionList data={listContent} translate columns={24} />
        </Col>
        <Col span={10}>
          <Col>
            {interventions.map((intervention) => (
              <GradeBar
                title={
                  <Row justify="space-between">
                    {`Intervention n°${intervention.code} - `}
                    <Link to={`/interventions/show/${intervention._id}`}>
                      <EyeOutlined style={{ fontSize: 18 }} />
                    </Link>
                  </Row>
                }
                grade={intervention.grade}
                key={intervention._id}
              />
            ))}
          </Col>
        </Col>
      </Row>
    </Card>
  );
};

InterventionsCard.propTypes = {
  interventions: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string,
      code: PropTypes.string,
      grade: PropTypes.number,
      customer: PropTypes.shape({
        ps_number: PropTypes.string,
        company_name: PropTypes.string
      })
    })
  )
};

InterventionsCard.defaultProps = {
  interventions: []
};
