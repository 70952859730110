import { Col, Card } from 'antd';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { DescriptionList } from '../../../components';
import { useListContent } from './listContent';

/**
 * Component for displaying quittance informations.
 *
 * @component
 * @param {object} props - Component props
 * @param {object} props.data - Quittance data containing principal details
 * @returns {JSX.Element} Component JSX
 */

export const Informations = ({ data }) => {
  const { t } = useTranslation();
  const listContent = useListContent(data, t);

  return (
    <>
      <Col span={8}>
        <Card title={t('quittances.show.titles.customer')}>
          <DescriptionList
            data={listContent.filter((f) => f.type === 'customer')}
            translate
            columns={24}
          />
        </Card>
      </Col>
      <Col span={8}>
        <Card title={t('quittances.show.titles.quittance')}>
          <DescriptionList
            data={listContent.filter((f) => f.type === 'quittance')}
            translate
            columns={24}
          />
        </Card>
      </Col>
      <Col span={8}>
        <Card title={t('quittances.show.titles.total')}>
          <DescriptionList
            data={listContent.filter((f) => f.type === 'total')}
            translate
            columns={24}
          />
        </Card>
      </Col>
    </>
  );
};

Informations.propTypes = {
  data: PropTypes.shape({
    customer: PropTypes.shape({})
  })
};

Informations.defaultProps = {
  data: {}
};
