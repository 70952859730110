import { Tag } from 'antd';
import moment from 'moment';
import { formatValue } from '../../../utils/formatNumber';

/**
 * Generates a list of content items for displaying details about an absence or vacation event.
 *
 * @hook
 * @param {Function} t - The translation function used for internationalization.
 * @param {Object} absenceVacation - An object containing properties related to the absence or vacation event.
 * @returns {Object[]} An array of content items, each with a label, span, and content.
 */

export const getListContent = (t, absenceVacation) => {
  const {
    type,
    user,
    start_date,
    end_date,
    startTime,
    endTime,
    paidLeaveInAdvance,
    waiveExtraTimeOff,
    total_cp_1,
    total_cp_2,
    reason
  } = absenceVacation;

  const listContent = [
    {
      label: t('events.form.user_function'),
      span: 6,
      content: (
        <Tag>{t(`users.positions.${user?.role.split(':')[1] || ''}`)}</Tag>
      )
    },
    {
      label: t('events.form.user'),
      span: 6,
      content: `${user.first_name} ${
        user?.usage_last_name || user?.last_name || ''
      }`
    },
    {
      label: t('events.form.start_date'),
      span: 6,
      content: `${formatValue(start_date, 'date')} ${formatValue(
        startTime,
        'time'
      )}`
    },
    {
      label: t('events.form.end_date'),
      span: 6,
      content: `${formatValue(end_date, 'date')} ${formatValue(
        endTime,
        'time'
      )}`
    },
    ...(type === 'PAID_VACATION'
      ? [
          {
            label: t('events.form.paidLeaveInAdvance'),
            span: 12,
            content: (
              <Tag>
                {paidLeaveInAdvance ? t('general.YES') : t('general.NO')}
              </Tag>
            )
          }
        ]
      : [
          {
            label: t('events.form.makeUpTime'),
            span: 6,
            content: (
              <Tag>
                {paidLeaveInAdvance ? t('general.YES') : t('general.NO')}
              </Tag>
            )
          },
          {
            label: t('events.form.number_hours'),
            span: 6,
            content: `${
              moment(end_date).diff(moment(start_date), 'days') > 0
                ? `${moment(end_date).diff(moment(start_date), 'days')} ${t(
                    'events.diffs.days'
                  )}`
                : ''
            } ${moment(endTime).hour() - moment(startTime).hour()} ${t(
              'events.diffs.hours'
            )}`
          }
        ]),
    ...(type === 'PAID_VACATION'
      ? [
          {
            label: t('events.form.waived'),
            span: 12,
            content: (
              <Tag>
                {waiveExtraTimeOff ? t('general.YES') : t('general.NO')}
              </Tag>
            )
          },
          {
            label: t('events.form.acquiredCP'),
            span: 6,
            content: '-' // TODO : add acquiredCP
          },
          {
            label: t('events.form.takenCP'),
            span: 6,
            content: moment(end_date).diff(moment(start_date), 'days') + 1 // TODO : add takenCP
          },
          {
            label: t('vacations.columns.total_cp_1'),
            span: 6,
            content: total_cp_1?.total || '-'
          },
          {
            label: t('vacations.columns.total_cp_2'),
            span: 6,
            content: total_cp_2?.total || '-'
          }
        ]
      : [
          {
            label: t('events.form.reason'),
            span: 6,
            content: reason
          }
        ])
  ];

  return listContent;
};
