import { message } from 'antd';

export const generateUniqueFakeMail = async (dispatchAPI) => {
  try {
    const { data } = await dispatchAPI('GET', {
      url: `/emails/generate`
    });
    return data;
  } catch (error) {
    if (error.message) message.error(error.message);
  }
  return null;
};
