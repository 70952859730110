import { useTranslation } from 'react-i18next';
import { useEffect, useState, useRef } from 'react';
import dayjs from 'dayjs';
import { Col, Form, Row, Select, Switch, Typography } from 'antd';
import { ListResource } from '../../../components';
import { useColumns } from './columns';
import { headers } from './headers';
import { useFormatter } from '../../../utils/formatter';
import CustomerAnalysisContext from '../CustomerAnalysisContext';
import { useAuthContext } from '../../../contexts/AuthContext';

const { Option } = Select;

export const ListRejections = () => {
  const { formattedData } = useFormatter();
  const { t } = useTranslation();
  const { user } = useAuthContext();
  const [form] = Form.useForm();
  const [selectedMonth, setSelectedMonth] = useState('');
  const [selectedYear, setSelectedYear] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [monthOptions, setMonthOptions] = useState([]);
  const [seeAll, setSeeAll] = useState(false);
  const [editing, setEditing] = useState({
    rejectNumber: null,
    rejectPart: null
  });
  const inputRefRejectNumber = useRef(null);
  const inputRefRejectPart = useRef(null);

  const {
    psMicro,
    leaders,
    refresh,
    totalCount,
    getTotalCount,
    customers,
    editRejection
  } = CustomerAnalysisContext();

  const handleEditRejectNumber = (record) => {
    setEditing((prev) => ({
      ...prev,
      rejectNumber: record._id
    }));
  };

  const handleEditRejectPart = (record) => {
    setEditing((prev) => ({
      ...prev,
      rejectPart: record._id
    }));
  };

  const handleEditCancel = () => {
    setEditing({
      rejectNumber: null,
      rejectPart: null
    });
    form.setFieldsValue({
      reject_number: '',
      reject_rate: '',
      reject_part: ''
    });
  };

  const save = async (record) => {
    let values = form.getFieldsValue();
    const reject_rate =
      (Number(
        values.reject_number ? values.reject_number : record.reject_number
      ) /
        Number(values.reject_part ? values.reject_part : record.reject_part)) *
      100;
    if (!Number.isNaN(reject_rate))
      form.setFieldsValue({
        reject_rate: Math.floor(reject_rate)
      });

    values = form.getFieldsValue();
    await editRejection(editing.rejectNumber || editing.rejectPart, values);
    handleEditCancel();
  };

  const columns = useColumns(
    psMicro,
    leaders,
    totalCount,
    customers,
    handleEditRejectPart,
    handleEditRejectNumber,
    editing,
    save,
    handleEditCancel,
    inputRefRejectNumber,
    inputRefRejectPart
  );

  const generateMonthOptions = (year) => {
    const currentYear = dayjs().year();
    const currentMonth = dayjs().month() + 1;
    const months = dayjs.months();

    if (year === String(currentYear)) {
      return months.slice(0, currentMonth - 1).map((month, index) => (
        <Option
          key={String(index + 1).padStart(2, '0')}
          value={String(index + 1).padStart(2, '0')}
        >
          {t(`rejections.months.${String(index + 1).padStart(2, '0')}`)}
        </Option>
      ));
    }
    return months.map((month, index) => (
      <Option
        key={String(index + 1).padStart(2, '0')}
        value={String(index + 1).padStart(2, '0')}
      >
        {t(`rejections.months.${String(index + 1).padStart(2, '0')}`)}
      </Option>
    ));
  };

  const generateYearOptions = () => {
    const currentYear = dayjs().year();
    const years = Array.from({ length: 11 }, (_, i) => currentYear - i);
    return years.map((year) => (
      <Option key={year} value={String(year)}>
        {year}
      </Option>
    ));
  };

  useEffect(() => {
    const currentYear = dayjs().year();
    const currentMonth = dayjs().month();
    const previousMonth = currentMonth === 0 ? 11 : currentMonth - 1;
    const yearOfPreviousMonth =
      currentMonth === 0 ? currentYear - 1 : currentYear;

    setSelectedYear(String(yearOfPreviousMonth));
    setSelectedMonth(String(previousMonth + 1).padStart(2, '0'));
  }, []);

  useEffect(() => {
    setMonthOptions(generateMonthOptions(selectedYear));
  }, [selectedYear, t]);

  useEffect(() => {
    if (editing.rejectNumber !== null) {
      inputRefRejectNumber.current.focus();
    }
  }, [editing.rejectNumber]);

  useEffect(() => {
    if (editing.rejectPart !== null) {
      inputRefRejectPart.current.focus();
    }
  }, [editing.rejectPart]);

  useEffect(() => {
    if (selectedYear && selectedMonth) {
      const newStartDate = dayjs(`${selectedYear}/${selectedMonth}/15`)
        .startOf('day')
        .format('YYYY-MM-DD HH:mm:ss');
      const newEndDate = dayjs(`${selectedYear}/${selectedMonth}/15`)
        .endOf('day')
        .format('YYYY-MM-DD HH:mm:ss');
      setStartDate(newStartDate);
      setEndDate(newEndDate);
      getTotalCount(newStartDate, newEndDate);
    }
  }, [selectedMonth, selectedYear]);

  return (
    <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
      <Form form={form} style={{ flex: '1 0 auto', height: '100%' }}>
        <Row style={{ height: '100%' }}>
          <Col
            span={24}
            style={{ display: 'flex', flexDirection: 'column', height: '100%' }}
          >
            <ListResource
              flexWrap
              resourceName="rejections"
              columns={columns}
              headers={headers}
              customActionColumn
              forceRefresh={refresh}
              resourceModelName="Rejections"
              formatter={formattedData}
              withImportButton={false}
              withUploadButton={user.role.split(':')[1] !== 'USER'}
              withCreateButton={false}
              onDoubleClickAction={false}
              populate="customer,leader,micro_ps"
              extraQuery={`period_concerned>=${
                startDate || ''
              }&period_concerned<=${endDate || ''}${
                seeAll ? '' : '&reject_rate=null'
              }`}
              extraButtons={
                <Row align="middle">
                  <Switch
                    size="large"
                    checkedChildren={t('rejections.buttons.see_to_do')}
                    unCheckedChildren={t('rejections.buttons.see_all')}
                    checked={seeAll}
                    onChange={setSeeAll}
                  />
                </Row>
              }
              extra
              extraFilters={
                <>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: '4px'
                    }}
                  >
                    <Typography
                      style={{ marginLeft: '15px', alignSelf: 'center' }}
                    >
                      {t('rejections.select.year')} :
                    </Typography>
                    <Select
                      allowClear
                      placeholder={t(
                        'reconciliationStatements.select.placeholder_year'
                      )}
                      onChange={(value) => setSelectedYear(value)}
                      onClear={() => {
                        setSelectedYear(null);
                      }}
                      value={selectedYear}
                      style={{ width: '180px', marginLeft: '15px' }}
                    >
                      {generateYearOptions()}
                    </Select>
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: '4px'
                    }}
                  >
                    <Typography
                      style={{ marginLeft: '15px', alignSelf: 'center' }}
                    >
                      {t('rejections.select.month')} :
                    </Typography>
                    <Select
                      allowClear
                      placeholder={t(
                        'reconciliationStatements.select.placeholder_month'
                      )}
                      value={selectedMonth}
                      disabled={!selectedYear}
                      onChange={(value) => {
                        setSelectedMonth(value);
                      }}
                      onClear={() => {
                        setSelectedMonth(null);
                      }}
                      style={{ width: '180px', marginLeft: '15px' }}
                    >
                      {monthOptions}
                    </Select>
                  </div>
                </>
              }
              style={{ flex: '1', overflow: 'auto' }}
            />
          </Col>
        </Row>
      </Form>
    </div>
  );
};
