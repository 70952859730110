// Main nav menuItems
export const routes = {
  HOME: '/',
  CUSTOMERS: '/customers',
  INTERVENTIONS: '/interventions'
};

// Main nav subMenuItems
export const subRoutes = {};

// Other routes
export const outOfNavRoutes = {
  LOGIN: '/login',
  REDIRECT: '/redirect',
  PROFILE: '/profile',
  SETTINGS: '/settings'
};

// url search parameters
export const pathSearches = {};
