import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Card, Row, Col } from 'antd';
import {
  LineChart,
  PieChart,
  Line,
  Pie,
  Cell,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer
} from 'recharts';
import StatisticsContextProvider from './StatisticsContext';
import { graphcolors } from '../../utils/constants/tagColors';
import { CustomSpin } from '../../components/CustomSpin/CustomSpin';

/**
 * Customized label component for the LineChart.
 * @module CustomizedLabel
 * @param {Object} props - Props passed to the customized label component.
 * @param {number} props.x - X coordinate of the label.
 * @param {number} props.y - Y coordinate of the label.
 * @param {string} props.stroke - Stroke color of the label.
 * @param {(number|string)} props.value - Value of the label.
 * @returns {JSX.Element} React component
 */

const CustomizedLabel = ({ x, y, stroke, value }) => (
  <text x={x} y={y} dy={-4} fill={stroke} fontSize={10} textAnchor="middle">
    {value}
  </text>
);

/**
 * Customized axis tick component for the LineChart.
 * @module CustomizedAxisTick
 * @param {Object} props - Props passed to the customized axis tick component.
 * @param {number} props.x - X coordinate of the tick.
 * @param {number} props.y - Y coordinate of the tick.
 * @param {Object} props.payload - Tick payload containing value.
 * @param {string} props.payload.value - Value of the tick, representing a month.
 * @returns {JSX.Element} React component
 */

const CustomizedAxisTick = ({ x, y, payload }) => {
  const { t } = useTranslation();
  return (
    <g transform={`translate(${x},${y})`}>
      <text
        x={0}
        y={0}
        dy={16}
        textAnchor="end"
        fill="#666"
        transform="rotate(-35)"
      >
        {t(`statistics.months.${payload.value}`)}
      </text>
    </g>
  );
};

/**
 * Customized label renderer for the PieChart.
 * @module renderCustomizedLabel
 * @param {Object} props - Props passed to the label renderer function.
 * @param {number} props.cx - X coordinate of the center of the pie.
 * @param {number} props.cy - Y coordinate of the center of the pie.
 * @param {number} props.midAngle - Mid angle of the pie segment.
 * @param {number} props.outerRadius - Outer radius of the pie.
 * @param {number} props.percent - Percentage of the pie segment.
 * @param {string} props.name - Name of the pie segment.
 * @param {number} props.value - Value of the pie segment.
 * @returns {JSX.Element} React component
 */

const renderCustomizedLabel = ({
  cx,
  cy,
  midAngle,
  outerRadius,
  percent,
  name,
  value
}) => {
  const RADIAN = Math.PI / 180;
  const radius = outerRadius + 20;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  const percentage = (percent * 100).toFixed(2);

  return (
    <text
      x={x}
      y={y}
      fill="#333"
      textAnchor={x > cx ? 'start' : 'end'}
      dominantBaseline="central"
    >
      {`${name} (${value}, ${percentage}%)`}
    </text>
  );
};

/**
 * React component for rendering PS (Problem Solving) statistics chart.
 * @module ChartPs
 * @returns {JSX.Element} React component
 */

export const ChartPs = () => {
  const { t } = useTranslation();
  const { chartDatas, isChartsLoading } = StatisticsContextProvider();

  if (isChartsLoading) {
    return <CustomSpin />;
  }

  return (
    <>
      <Row gutter={[16, 16]}>
        <Col xs={24} xl={12}>
          <Card title={t('statistics.titles.fse')}>
            <ResponsiveContainer height={300}>
              <LineChart data={chartDatas?.updatedChartsData}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Line
                  type="monotone"
                  dataKey="actions_per_hour"
                  stroke="#8884d8"
                  name={t('statistics.gtp.actions_per_hour')}
                />
                <Line
                  type="monotone"
                  dataKey="average"
                  stroke="red"
                  name={t('statistics.gtp.average')}
                />
                <Line
                  type="monotone"
                  dataKey="objective"
                  stroke="orange"
                  name={t('statistics.gtp.objective')}
                />
              </LineChart>
            </ResponsiveContainer>
          </Card>
        </Col>
        <Col xs={24} xl={12}>
          <Card title={t('statistics.titles.total_actions')}>
            <ResponsiveContainer height={300}>
              <LineChart data={chartDatas?.updatedChartsData}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Line
                  type="monotone"
                  dataKey="total_actions"
                  stroke="#8884d8"
                  name={t('statistics.gtp.total_actions')}
                />
              </LineChart>
            </ResponsiveContainer>
          </Card>
        </Col>
      </Row>
      <Row gutter={[16, 16]} style={{ marginTop: 16 }}>
        <Col xs={24} xl={12}>
          <Card title={t('statistics.titles.intervention_hours')}>
            <ResponsiveContainer height={300}>
              <LineChart data={chartDatas?.updatedChartsData}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Line
                  type="monotone"
                  dataKey="total_interventions"
                  stroke="#8884d8"
                  name={t('statistics.gtp.total_interventions')}
                />
              </LineChart>
            </ResponsiveContainer>
          </Card>
        </Col>
        <Col xs={24} xl={12}>
          <Card title={t('statistics.titles.action_types')}>
            <ResponsiveContainer height={300}>
              <PieChart>
                <Pie
                  data={chartDatas?.filteredListActions}
                  innerRadius={60}
                  outerRadius={80}
                  fill="#8884d8"
                  paddingAngle={5}
                  dataKey="value"
                  labelLine
                  label={renderCustomizedLabel}
                >
                  {chartDatas?.filteredListActions?.map((action, index) => (
                    <Cell
                      key={`cell-${action?.title}`}
                      fill={graphcolors[index % graphcolors.length]}
                    />
                  ))}
                </Pie>
              </PieChart>
            </ResponsiveContainer>
          </Card>
        </Col>
      </Row>
    </>
  );
};

CustomizedLabel.propTypes = {
  x: PropTypes.number.isRequired,
  y: PropTypes.number.isRequired,
  stroke: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired
};

CustomizedAxisTick.propTypes = {
  x: PropTypes.number.isRequired,
  y: PropTypes.number.isRequired,
  payload: PropTypes.shape({
    value: PropTypes.string.isRequired
  }).isRequired
};
