import React from 'react';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Modal, Row, Col, Divider } from 'antd';
import { WarningOutlined } from '@ant-design/icons';

const ModalConflict = ({ open, setIsModalConflictOpen, conflicts }) => {
  const { t } = useTranslation();

  return (
    <Modal
      centered
      open={open}
      onCancel={() => {
        setIsModalConflictOpen(false);
      }}
      width={700}
      footer={false}
    >
      <Row justify="center">
        <Col
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}
        >
          <WarningOutlined style={{ color: 'orange', marginRight: 5 }} />
          <span style={{ color: 'red', fontSize: 16 }}>
            {t(
              `messages.warning.conflict${conflicts[0]?.length > 1 ? 's' : ''}`
            )}
          </span>
        </Col>
        {conflicts.map((conflictArray, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <React.Fragment key={index}>
            <Divider orientation="left">
              {dayjs(conflictArray[0].event.start).format('DD/MM/YYYY')}
            </Divider>
            {conflictArray.map((c) => (
              <React.Fragment key={c.event.evStart}>
                <Col span={24}>
                  {`L'évènement "${t(
                    `events.form.enums.${c?.event?.type}`
                  )}" de ${dayjs(c?.event?.start).format('HH:mm')} à ${dayjs(
                    c?.event?.end
                  ).format('HH:mm')} entre en conflit avec l'évènement "${t(
                    `events.form.enums.${c?.conflict?.type}`
                  )}" de ${dayjs(c?.conflict?.evStart).format(
                    'HH:mm'
                  )} à ${dayjs(c?.conflict?.evEnd).format('HH:mm')}`}
                </Col>
              </React.Fragment>
            ))}
          </React.Fragment>
        ))}
      </Row>
    </Modal>
  );
};

ModalConflict.propTypes = {
  open: PropTypes.bool.isRequired,
  setIsModalConflictOpen: PropTypes.func.isRequired,
  conflicts: PropTypes.arrayOf(
    PropTypes.shape({
      length: PropTypes.number
    })
  )
};

ModalConflict.defaultProps = {
  conflicts: []
};

export default ModalConflict;
