import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { CreateUpdateContainer } from '../../components';
import { useAuthContext } from '../../contexts/AuthContext';
import { useErrorMessage } from '../../utils/errorMessage';
import useFields from './fields';

/**
 * Component for creating or updating transfers.
 * It provides a form for inputting transfer details, handling both creation and update operations.
 *
 * @param {object} props - The properties passed to the component.
 * @param {string} props.purpose - The purpose of the form, either 'create' or 'update', to dictate the form behavior.
 * @returns {React.Component} - The CreateUpdateTransfer component.
 */
const CreateUpdateTransfer = ({ purpose }) => {
  const { message } = useErrorMessage();
  const { dispatchAPI } = useAuthContext();
  const [isLoading, setIsLoading] = useState(false);
  const [selectedMonth, setSelectedMonth] = useState(null);
  const [bankReconciliations, setBankReconciliations] = useState(null);
  const [selectedBankReconciliation, setSelectedBankReconciliation] =
    useState(null);
  const [transferDates, setTransferDates] = useState(null);
  const [levyDate, setLevyDate] = useState(null);

  const fields = useFields(
    isLoading,
    setSelectedMonth,
    selectedMonth,
    bankReconciliations,
    transferDates,
    setSelectedBankReconciliation,
    setLevyDate
  );

  const getBankReconciliation = async () => {
    setIsLoading(true);
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/bankreconciliations`
      });
      setBankReconciliations(data);
    } catch (e) {
      message(e);
    }
    setIsLoading(false);
  };

  const getTransferDate = async () => {
    setIsLoading(true);
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/quittances/month?month=${selectedMonth}&bank_reconciliation=${selectedBankReconciliation}`
      });
      setTransferDates(data);
    } catch (e) {
      message(e);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    (async () => {
      await getBankReconciliation();
    })();
  }, []);

  useEffect(() => {
    (async () => {
      if (selectedMonth) await getTransferDate();
    })();
  }, [selectedMonth, selectedBankReconciliation]);

  const config = {
    onCreateResource: {
      setBody: (data) => ({
        ...data,
        rejected: levyDate?.children.includes('REJETÉE')
      })
    },
    onGetResource: {
      setFields: (v) => ({
        ...v,
        levy_date: v.levy_date && moment(v.levy_date)
      })
    }
  };

  return (
    <CreateUpdateContainer
      resource="transfers"
      fields={fields.filter((field) => field.purpose.includes(purpose))}
      purpose={purpose}
      baseUrl="transfer"
      config={config}
    />
  );
};

CreateUpdateTransfer.propTypes = {
  purpose: PropTypes.string.isRequired
};

export default CreateUpdateTransfer;
