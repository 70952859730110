import { Input, Select } from 'antd';

const { TextArea } = Input;

/**
 * Hook for the closure fields.
 *
 * @hook
 *
 * @param {string} purpose - The purpose of the closure
 * @param {function} setPurpose - The purpose setter
 * @param {Object} enums - The enums object with the closure purposes and levels
 * @param {Object} t - i18n translate function
 * @returns {Object} The closure fields
 * @returns {Array} closureFields - Array of closure fields
 */
const useClosureFields = (purpose, setPurpose, enums, t) => {
  const closureFields = [
    {
      name: ['closure', 'type'],
      input: (
        <Select style={{ minWidth: 200 }} onChange={setPurpose}>
          {enums?.closure_purposes?.map((closure_purpose) => (
            <Select.Option key={closure_purpose}>
              {t(`quality.form.closure.${closure_purpose}`)}
            </Select.Option>
          ))}
        </Select>
      ),
      translate: true
    },
    ...(purpose === enums?.closure_purposes[0]
      ? [
          {
            name: ['closure', 'qualification_to_be_validated'],
            input: (
              <Select>
                {enums?.levels?.map((level) => (
                  <Select.Option key={level}>
                    {t(`employments.tags.${level}`)}
                  </Select.Option>
                ))}
              </Select>
            ),
            translate: true
          }
        ]
      : []),
    {
      name: ['closure', 'comment'],
      input: <TextArea rows={4} />,
      translate: true
    }
  ];

  return { closureFields };
};

export default useClosureFields;
