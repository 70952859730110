import PropTypes from 'prop-types';
import { Progress } from 'antd';

/**
 * Component for displaying a grade of a quality control.
 *
 * @component
 * @param {JSX.Element} title - The title of the grade bar
 * @param {number} grade - The grade to display
 * @returns {JSX.Element} The grade bar component
 */
export const GradeBar = ({ title, grade }) => {
  const displayText = grade === 100 ? '100 %' : `${grade} %`;
  return (
    <>
      <span>{title}</span>
      <Progress percent={grade} format={() => displayText} />
    </>
  );
};

GradeBar.propTypes = {
  title: PropTypes.node,
  grade: PropTypes.number
};

GradeBar.defaultProps = {
  title: '',
  grade: 0
};
